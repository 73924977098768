export enum BulkSelectorDataType {
    Entity = '[BulkSelectorDataType] entity',
    Device = '[BulkSelectorDataType] device'
}

export namespace BulkSelectorDataType {
    export const members = [BulkSelectorDataType.Entity, BulkSelectorDataType.Device];

    export function toString(type: BulkSelectorDataType): string {
        switch (type) {
            case BulkSelectorDataType.Entity:
                return 'Entity';
            case BulkSelectorDataType.Device:
                return 'Device';
            default:
                return '';
        }
    }

    export function toPluralString(type: BulkSelectorDataType): string {
        switch (type) {
            case BulkSelectorDataType.Entity:
                return 'Entities';
            case BulkSelectorDataType.Device:
                return 'Devices';
            default:
                return '';
        }
    }
}
