export interface IInstallContact {
    name: string;
    phone: string;
    email: string;
}

export class InstallContact implements IInstallContact {
    public name: string = '';
    public phone: string = '';
    public email: string = '';

    constructor(defaults?: Partial<IInstallContact>) {
        if (!!defaults) {
            this.name = defaults.name || this.name;
            this.phone = defaults.phone || this.phone;
            this.email = defaults.email || this.email;
        }
    }
}
