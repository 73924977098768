enum TroubleMode {
    Off = 'off',
    Standard = 'standard',
    Detailed = 'detailed'
}

namespace TroubleMode {
    export const asArray: TroubleMode[] = [
        TroubleMode.Off,
        TroubleMode.Standard,
        TroubleMode.Detailed
    ];
}

export { TroubleMode };
