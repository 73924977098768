import { Validators } from '@angular/forms';
import { ISettings, SettingsKeyMap } from '@libs/iso/core';
import { InheritanceFormControl } from '@libs/web/forms/models/form-controls/InheritanceFormControl';
import { AbstractDynamicSettingsSectionFormGroup } from '@libs/web/forms/models/form-groups/AbstractDynamicSettingsSectionFormGroup';

/**
 * A pre-configured FormGroup for shared settings between both devices and installs, including
 * SNMP settings, which can be changed at all three levels:
 * Install, Device and Entity.
 * @See FormGroup5
 */
export class SharedDynamicSettingsSectionFormGroup extends AbstractDynamicSettingsSectionFormGroup {
    /**
     * @param {ISettings} settings current state of form
     * @param {ISettings} parentSettings fills empty form fields with inherited default values
     * @return {SharedDynamicSettingsSectionFormGroup} new instance
     * @see FormGroup.constructor
     */
    constructor(settings: Partial<ISettings>, parentSettings: ISettings) {
        super({
            // Device Discovery
            [SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery
                .Retry]: new InheritanceFormControl(
                settings?.deviceDiscovery?.advancedDiscovery?.retry,
                parentSettings.deviceDiscovery.advancedDiscovery.retry,
                [Validators.required]
            ),
            [SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery
                .Timeout]: new InheritanceFormControl(
                settings?.deviceDiscovery?.advancedDiscovery?.timeout,
                parentSettings.deviceDiscovery.advancedDiscovery?.timeout,
                [Validators.required]
            ),

            // SNMP
            [SettingsKeyMap.SNMP.Community]: new InheritanceFormControl(
                settings?.snmp?.community,
                parentSettings.snmp.community
            ),
            [SettingsKeyMap.SNMP.Communities]: new InheritanceFormControl(
                settings?.snmp?.communities,
                parentSettings.snmp.communities
            ),
            [SettingsKeyMap.SNMP.Version]: new InheritanceFormControl(
                settings?.snmp?.version,
                parentSettings.snmp.version
            ),

            [SettingsKeyMap.SNMP.V3Username]: new InheritanceFormControl(
                settings?.snmp?.v3Username,
                parentSettings.snmp.v3Username
            ),
            [SettingsKeyMap.SNMP.V3AuthPassword]: new InheritanceFormControl(
                settings?.snmp?.v3AuthPassword,
                parentSettings.snmp.v3AuthPassword
            ),
            [SettingsKeyMap.SNMP.V3PrivacyPassword]: new InheritanceFormControl(
                settings?.snmp?.v3PrivacyPassword,
                parentSettings.snmp.v3PrivacyPassword
            ),
            [SettingsKeyMap.SNMP.V3PrivacyProtocol]: new InheritanceFormControl(
                settings?.snmp?.v3PrivacyProtocol,
                parentSettings.snmp.v3PrivacyProtocol
            ),
            [SettingsKeyMap.SNMP.V3Algorithm]: new InheritanceFormControl(
                settings?.snmp?.v3Algorithm,
                parentSettings.snmp.v3Algorithm
            ),
            [SettingsKeyMap.SNMP.V3Context]: new InheritanceFormControl(
                settings?.snmp?.v3Context,
                parentSettings.snmp.v3Context
            ),

            // Auto-fulfillment
            [SettingsKeyMap.AutoFulfillment.SuppliesNetwork.Enabled]: new InheritanceFormControl(
                settings?.autoFulfillment?.suppliesNetwork?.enabled,
                parentSettings.autoFulfillment.suppliesNetwork.enabled
            )
        });
    }
}
