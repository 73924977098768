<mat-card>
    <section>
        <div class="mat-card-description">
            <p i18n>
                Choose a date range to view history.
            </p>
        </div>
    </section>

    <section [formGroup]='tableParamsFormGroup'>
        <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="sd" placeholder="Start date" i18n-placeholder formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="sd"></mat-datepicker-toggle>
            <mat-datepicker #sd disabled="false"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="ed" placeholder="End date" i18n-placeholder formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="ed"></mat-datepicker-toggle>
            <mat-datepicker #ed disabled="false"></mat-datepicker>
        </mat-form-field>
<!--        <button mat-flat-button color="default" (click)="updatePageQueryParams()">-->
<!--            <ptkr-btn-preloader *ngIf="loadingResults | async"></ptkr-btn-preloader>-->
<!--            Search-->
<!--        </button>-->
        <span style='float: right;'>
            <mat-slide-toggle formControlName='includeAncestors' i18n>
                Show ancestor history
            </mat-slide-toggle>
        </span>
    </section>
    <div class="device-history-tab">
        <table
            mat-table
            matSort
            class="g-table"
            [dataSource]="dataSource"
            *ngIf="(currentWindowSize$ | async) !== WindowSize.Mobile"
        >
            <!-- <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
              <button
                  *ngIf="selection.hasValue()"
                  mat-button
                  class="multi-select-options"
                  [matMenuTriggerFor]="bulkResolutionStatusMenu">
                  <mat-checkbox
                      (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                  <span class="drop-down-indicator">
                      <i class="fas fa-sort-down"></i>
                  </span>
              </button>
              <mat-checkbox
                  *ngIf="selection.isEmpty()"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let audit">
              <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(audit) : null"
                  [checked]="selection.isSelected(audit)">
              </mat-checkbox>
          </td>
      </ng-container> -->
      <!--
            <ng-container matColumnDef="dtTm">
                <th mat-header-cell mat-sort-header="dtTm" *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let audit">
                    {{ audit.createdDate | date: 'M/d/yy, h:mm:ss a' }}
                </td>
            </ng-container>
        -->
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef i18n>Date</th>
                <td mat-cell *matCellDef="let audit">
                    {{ audit?.createdDate | date: 'M/d/yy, h:mm:ss a' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef i18n>Source</th>
                <td mat-cell *matCellDef='let audit'>
                    {{ audit.descriptor === undefined ? '--' : audit.descriptor }}
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef i18n="@@type">Type</th>
                <!-- <td mat-cell *matCellDef="let audit"> {{ EventType.toString(audit.type) }} </td> -->
                <td mat-cell *matCellDef="let audit">
                    <ng-container *ngIf="audit.type === undefined" i18n>No data</ng-container>
                    <ng-container *ngIf="audit.type !== undefined">{{ AuditLogType.toString(audit.type) }}</ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef i18n="@@message">Message</th>
                <td mat-cell *matCellDef="let audit" class="max-w-[500px[] break-all">
                    <ng-container *ngIf='!!audit.message && isArray(audit.message); else notArray'>
                        <ng-container *ngIf='audit.message.length > 1; else oneElement'>
                        <ul>
                            <li *ngFor='let line of audit.message'>
                                {{ line }}
                            </li>
                        </ul>
                        </ng-container>
                    </ng-container>
                    <ng-template #notArray>
                        <div>
                            {{ audit.message }}
                        </div>
                    </ng-template>
                    <ng-template #oneElement>
                        <div>
                            {{ audit.message[0] }}
                        </div>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="requestedby">
            <th mat-header-cell *matHeaderCellDef i18n>Requested by</th>
            <td mat-cell *matCellDef="let audit"> {{ requesterName(audit) | async }} </td>
        </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [class.critical]="false"
            ></tr>
        </table>

        <p *ngIf="dataSource.data?.length < 1" i18n>No History</p>

        <div *ngIf="(currentWindowSize$ | async) === WindowSize.Mobile">
            <div>Not supported on mobile.</div>
        </div>
    </div>
</mat-card>
