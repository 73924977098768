import { Device } from '@libs/iso/core/models/device/Device';

export interface IDevicesByInstalls {
    [key: string]: Array<string>;
}

/**
 * This data structure is used to group arrays of device IDs by sets of install keys. It provides
 * helper functions for adding devices one at a time or in a group and builds the relationship between
 * devices an installs automatically.
 */
export class DevicesByInstallsBuilder {
    private data: IDevicesByInstalls = {};
    constructor(devices?: Array<Device>) {
        if (devices) {
            for (const device of devices) {
                this.addDevices(device);
            }
        }
    }
    public addDevices(devices: Device | Device[]): void {
        if (!Array.isArray(devices)) {
            devices = [devices];
        }
        devices.forEach(device => {
            device.installKey.forEach(key => {
                if (!(key in this.data)) {
                    this.data[key] = [];
                }
                this.data[key].push(<string>device._id);
            });
        });
    }
    public devicesByInstalls(): IDevicesByInstalls {
        return this.data;
    }
}
