import { Injectable } from '@angular/core';
import { HttpService, Get, Query, Adapter, Put, Path, Body, Post, Delete } from '@libs/web/http';
import { Observable, of } from 'rxjs';
import {
    PaginatedList,
    ReportScheduledWithMetadata,
    ReportScheduled,
    IReportScheduledExport
} from '@libs/iso/core';
import { GlobalStore } from '@app/state/store';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@app/core/services/notification.service';
import { catchError, retry } from 'rxjs/operators';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class ReportScheduledService extends HttpService {
    constructor(
        protected _http: HttpClient,
        private _store: Store<GlobalStore>,
        private _notificationService: NotificationService
    ) {
        super(_http);
    }

    @Get('/reportScheduled/list')
    @Adapter(
        (res): PaginatedList<ReportScheduledWithMetadata> => {
            const list = new PaginatedList<ReportScheduledWithMetadata>();
            const docs = Array.isArray(res.docs) ? res.docs : [];
            list.docs = docs.map(d => new ReportScheduledWithMetadata(d));
            list.totalDocs = res.totalDocs || 0;
            return list;
        }
    )
    public getScheduledReportList(
        @Query('entityKey') entityKey: string,
        @Query('reportKey') reportKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean
    ): Observable<PaginatedList<ReportScheduledWithMetadata>> {
        return null;
    }

    @Put('/reportScheduled/{id}')
    private _updateScheduledReport(
        @Path('id') id: string,
        @Body('entityKey') entityKey: string,
        @Body('userKey') userKey: string,
        @Body('reportKey') reportKey: string,
        @Body('enabled') enabled: boolean,
        @Body('export') exportOptions: IReportScheduledExport,
        @Body('schedule') schedule: string,
        @Body('timezone') timezone: string
    ): Observable<void> {
        return null;
    }

    public updateScheduledReport(scheduled: ReportScheduled): Observable<void> {
        return this._updateScheduledReport(
            <string>scheduled._id,
            <string>scheduled.entityKey,
            <string>scheduled.userKey,
            <string>scheduled.reportKey,
            scheduled.enabled,
            scheduled.export,
            scheduled.schedule,
            scheduled.timezone
        );
    }

    @Post('/reportScheduled')
    private _createScheduledReport(
        @Body('entityKey') entityKey: string,
        @Body('userKey') userKey: string,
        @Body('reportKey') reportKey: string,
        @Body('enabled') enabled: boolean,
        @Body('export') exportOptions: IReportScheduledExport,
        @Body('schedule') schedule: string,
        @Body('timezone') timezone: string
    ): Observable<void> {
        return null;
    }

    public createScheduledReport(scheduled: ReportScheduled): Observable<void> {
        return this._createScheduledReport(
            <string>scheduled.entityKey,
            <string>scheduled.userKey,
            <string>scheduled.reportKey,
            scheduled.enabled,
            scheduled.export,
            scheduled.schedule,
            scheduled.timezone
        );
    }

    @Delete('/reportScheduled/{id}')
    private _deleteScheduledReport(@Path('id') id: string): Observable<void> {
        return null;
    }

    public deleteScheduledReport(id: string): Observable<void> {
        return this._deleteScheduledReport(id).pipe(
            retry(3),
            catchError(err => {
                this._notificationService.error(err.statusText);
                return of(null);
            })
        );
    }
}
