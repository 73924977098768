<form style='display: flex; flex-direction: column;'>
    <div style='display: flex; justify-content: flex-end; width: 100%; margin-bottom: 1em;'>
        <ng-container *ngIf='hasSupplies(); else noSupplies'>
            <button mat-flat-button color='accent'
                    [matMenuTriggerFor]='addSupplyMenu'
                    [disabled]='!supplies' i18n>
                Add supply
            </button>
        </ng-container>
        <ng-template #noSupplies>
            <div matTooltip='No supplies detected, has this device uploaded a meter read recently?' i18n-matTooltip
                 [matTooltipShowDelay]='500'>
                <button mat-flat-button color='disabled' disabled i18n>
                    Add supply
                </button>
            </div>
        </ng-template>
    </div>
    <table
        mat-table
        multiTemplateDataRows
        class='g-table expandable-rows'
        [dataSource]='dataSource'
    >
        <ng-container matColumnDef='supply'>
            <th mat-header-cell *matHeaderCellDef i18n>Supply</th>
            <td mat-cell *matCellDef='let row'>{{ getSupplyName(row['alert']['path']) | titlecase }}</td>
        </ng-container>
        <ng-container matColumnDef='skipsRemaining'>
            <th mat-header-cell *matHeaderCellDef i18n>Skips remaining</th>
            <td mat-cell *matCellDef='let row'>
                <ptkr-editable-field [value]='row.alert.skipsRemaining'
                                     (changed$)='updateAlert(row.alert, $event)'
                                     type='number'>
                </ptkr-editable-field>
            </td>
        </ng-container>
        <ng-container matColumnDef='lastSkip'>
            <th mat-header-cell *matHeaderCellDef i18n>Last skip</th>
            <td mat-cell *matCellDef='let row'>{{ (getLastSkip(row['alert']) | date:'short') || '--' }}</td>
        </ng-container>
        <ng-container matColumnDef='actions'>
            <th mat-header-cell *matHeaderCellDef></th>
            <td
                mat-cell
                *matCellDef='let row'
                (click)='$event.stopPropagation(); selectedAlert = row.alert'
                [matMenuTriggerFor]='alertMenu'
            >
                    <span style='margin-bottom: 10px'>
                        <mat-icon>more_vert</mat-icon>
                    </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef='columns' class='bordered left right'></tr>
        <tr
            mat-row
            *matRowDef='let row; columns: columns'
        ></tr>
    </table>
</form>
<mat-menu #alertMenu='matMenu' class='no-max-width' [overlapTrigger]='false'>
    <ng-container *ngIf='selectedAlert'>
        <button (click)='openSkipAlertHistory(selectedAlert)' mat-menu-item i18n>
            View historical skips
        </button>
        <button (click)='openDeleteModal(selectedAlert)' mat-menu-item i18n="@@delete">Delete</button>
    </ng-container>
</mat-menu>
<mat-menu #addSupplyMenu='matMenu' class='no-max-width' style='max-height: 5em;'>
    <!-- For each supply in a meter read with both a type and color -->
    <!-- supplyOptions should be { path: string, name: string }[] -->
    <ng-container *ngFor='let supply of getSuppliesMinusSkipped()'>
        <button (click)='createSkipAlert(supply["path"])' mat-menu-item>{{ supply['displayName'] | titlecase }}</button>
    </ng-container>
    <ng-container *ngFor='let supply of getSuppliesOnlySkipped()'>
        <button mat-menu-item disabled>{{ supply['displayName'] | titlecase }}</button>
    </ng-container>
</mat-menu>
