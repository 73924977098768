import { IBaseModel, BaseModel } from '@libs/iso/core/models/BaseModel';

export interface ICartShipment extends IBaseModel {
    date: Date;
    trackingNumber: string;
}

export class CartShipment extends BaseModel implements ICartShipment {
    public date: Date;
    public trackingNumber: string;

    constructor(defaults?: Partial<ICartShipment>) {
        super(defaults);
        if (defaults) {
            this.date = defaults.date || this.date;
            this.trackingNumber = defaults.trackingNumber || this.trackingNumber;
        }
    }
}
