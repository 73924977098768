export interface IShippingMethodModel {
    type: string;
    price: string;
    deliveryDate: Date;
}
interface ShippingMethodConstructorParams extends IShippingMethodModel {}

export class ShippingMethod implements IShippingMethodModel {
    public type: string = '';
    public price: string = '';
    public deliveryDate: Date;

    constructor(params?: Partial<ShippingMethodConstructorParams>) {
        if (!!params) {
            this.type = params.type || this.type;
            this.price = params.price || this.price;
            this.deliveryDate = params.deliveryDate || this.deliveryDate;
        }
    }
}
