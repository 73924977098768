import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum MessageBarColors {
    Default= '#d3d3d3'
}

@Component({
    selector: 'ptkr-message-bar',
    templateUrl: './message-bar.component.html',
    styleUrls: ['./message-bar.component.scss']
})
export class MessageBarComponent implements OnInit {
    @Input() public color: string = MessageBarColors.Default;
    @Input() public icon: string = 'info';
    @Output() private dismiss: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    public dismissMessage(): void {
        this.dismiss.emit();
    }
}
