import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'ptkr-inline-meter-name-card',
    templateUrl: './inline-meter-name-card.component.html',
    styleUrls: ['./inline-meter-name-card.component.scss']
})
export class InlineMeterNameCardComponent {
    @Input()
    public index: number = -1;

    @Input()
    public name: string;

    @Input()
    public format: string;

    @Input()
    public disabled: boolean = false;

    constructor() {}

    public displayIndex(): string {
        if (this.index === 0) {
            return 'PRIMARY';
        }
        return `#${this.index + 1}`;
    }
}
