import { ObjectId } from 'bson';
import { BaseModel, IBaseModel } from '@libs/iso/core/models/BaseModel';
import { JobDestinationTypes, JobOriginTypes } from '@libs/iso/core/enums/Job';
import { JobBatchState, JobBatchStatus } from '@libs/iso/core/enums/JobBatchStatus';
import { CategoricalDistributionElement } from '@libs/iso/core/helpers/calculation';
import * as moment from 'moment';

export enum JobBatchType {
    BulkUpgrade = 'bulkUpgrade'
}

export enum ModuleVersions {
    dca = 'dca',
    ptpmonitor = 'ptpmonitor',
    ptpwatcher = 'ptpwatcher'
}

export interface IJobBatch extends IBaseModel {
    entityKey: ObjectId | string;
    userKey: ObjectId | string;
    // Targets is an array of install keys
    targets: Array<ObjectId | string>;

    // The index of the state corresponds to the index of the target indicating the
    // state of each target
    states: JobBatchState[];

    //  The type of job that should be scheduled.
    type: JobBatchType;
    // The number of times the scheduler should attempt to retry a failed job on a
    // specific install.
    retries: number;
    // The number of jobs that can be in a non-terminal state at any given time
    concurrency: number;

    // lease refers to the date at which this batch can be taken over by another
    // upgrader. If an upgrader is working on the lease, it will attempt to renew
    // the lease repeatedly. If for some reason the upgrader dies and is unable to
    // renew the lease and continue the batch, the batch can be taken over by a
    // new upgrader after the lease expires (after the lease is before the current time)
    lease: string | moment.Moment;

    jobName: string;
    jobDescription: string;
    destination: JobDestinationTypes;
    origin: JobOriginTypes;
    metadata: JobBatchMetadata;
    batchStatus: JobBatchState;
    // The modules and version to upgrade an install to, note that the architecture
    // does not need to be in the version as it is inferred based on the target.
    modVersions: { [key in ModuleVersions]: string };
}

interface JobBatchMetadata {
    user: {
        username: string;
        firstname: string;
        lastname: string;
    };
    entity: {
        name: string;
    };
}

export enum JobBatchSortBy {
    State
}

export class JobBatch extends BaseModel implements IJobBatch {
    public entityKey: ObjectId | string;
    public userKey: ObjectId | string;
    public concurrency: number = 1;
    public jobName: string;
    public jobDescription: string;
    public retries: number = 3;
    public targets: Array<ObjectId | string>;
    public lease: string | moment.Moment;
    public type: JobBatchType;
    public states: JobBatchState[];
    public batchStatus: JobBatchState = JobBatchState.Pending;
    public destination: JobDestinationTypes = JobDestinationTypes.Install;
    public metadata: JobBatchMetadata;
    public origin: JobOriginTypes = JobOriginTypes.WebAdmin;
    public modVersions: { [key in ModuleVersions]: string };

    constructor(params?: IJobBatch) {
        super(params);
        if (!!params) {
            this.entityKey = params.entityKey;
            this.userKey = params.userKey;
            this.batchStatus = params.batchStatus ? params.batchStatus : this.batchStatus;
            this.concurrency = params.concurrency ? params.concurrency : this.concurrency;
            this.destination = params.destination ? params.destination : this.destination;
            this.jobDescription = params.jobDescription
                ? params.jobDescription
                : this.jobDescription;
            this.jobName = params.jobName;
            this.metadata = params.metadata;
            this.modVersions = params.modVersions;
            this.origin = params.origin ? params.origin : this.origin;
            this.retries = params.retries ? params.retries : this.retries;
            this.states = params.states;
            this.targets = params.targets;
            this.type = params.type;
            this.lease = moment(params.lease);
        }
    }

    public sortBy(sortBy: JobBatchSortBy): JobBatch {
        interface Sortable {
            target: ObjectId | string;
            state: JobBatchState;
        }
        const items: Sortable[] = [];
        for (let i = 0; i < this.states.length; i++) {
            items.push({
                target: this.targets[i],
                state: this.states[i]
            });
        }
        items.sort((a: Sortable, b: Sortable): number => (a < b ? 1 : -1));
        this.targets = items.map(s => s.target);
        this.states = items.map(s => s.state);
        return this;
    }

    public categoricalDistributionElementsByState(): CategoricalDistributionElement[] {
        const byStates: { [key: number]: number } = {};
        for (let i = 0; i < this.states.length; i++) {
            const k = this.states[i];
            if (!(k in byStates)) {
                byStates[k] = 1;
            } else {
                byStates[k]++;
            }
        }
        const categorized = Object.keys(byStates).map(
            key =>
                ({
                    category: key,
                    value: byStates[key],
                    color: JobBatchStatus.toColor(Number(key))
                } as CategoricalDistributionElement)
        );
        return categorized.sort((a, b) => (Number(a.category) < Number(b.category) ? 1 : -1));
    }
}
