import { ComponentFactoryResolver, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { HighlightRuleGroup } from '@libs/iso/core';
import { InlineLogHintComponent } from '@app/shared/components';

@Directive({ selector: '[ptkrLogHint]' })
export class LogHintDirective {
    private _ruleGroup: HighlightRuleGroup;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    @Input() public set ptkrLogHint(ruleGroup: HighlightRuleGroup) {
        this._ruleGroup = ruleGroup;
        this.viewContainer.createEmbeddedView(this.templateRef);
    }

    @Input() public set ptkrLogHintLine(value: string) {
        this._updateHint(value);
    }

    private _updateHint(value): void {
        // todo: we may need to clear the view container at the point that this needs to become dynamic
        this._ruleGroup.hints(value).forEach(h => {
            const ref = this.viewContainer.createComponent<InlineLogHintComponent>(
                this.componentFactoryResolver.resolveComponentFactory(InlineLogHintComponent)
            );
            ref.instance.hintHTML = h;
        });
    }
}
