import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmMultipleModalConfig } from '@app/models';

@Component({
    selector: 'ptkr-confirm-multiple-modal',
    templateUrl: './confirm-multiple-modal.component.html',
    styleUrls: ['./confirm-multiple-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmMultipleModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ConfirmMultipleModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmMultipleModalConfig
    ) {}

    ngOnInit(): void {}
}
