import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { JobService } from '@app/src/app/core/services/job.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ptkr-job-log-viewer-modal',
    templateUrl: './job-log-viewer-modal.component.html',
    styleUrls: ['./job-log-viewer-modal.component.css']
})
export class JobLogViewerModalComponent implements OnInit {
    public logs$: BehaviorSubject<Array<string>> = new BehaviorSubject(null);

    constructor(
        public dialogRef: MatDialogRef<JobLogViewerModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {id: string},
        public _jobService: JobService,
    ) {}

    ngOnInit(): void {
        this._jobService.getJobLogs(this.data.id).pipe(
            map((res: Array<{history: {message: string, timestamp: string}}>) => res.map(item => item.history.message))
        ).subscribe(d => this.logs$.next(d));
    }
}
