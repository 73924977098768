<div class="container">
    <form [formGroup]="updateInstallSoftwareUpgradesForm">
        <div [ngClass]="{disabled: disabledUpgradeForm}">
            <div class="form-element-margin">
                <div class="label">
                    <ng-container i18n>Data Collection Agent</ng-container> 
                    <a matTooltip="Choose a version of the Data Collection Agent you'd like to deploy to this installation" i18n-matTooltip><small i18n="@@whatsThis">What's this?</small></a>
                </div>
                <mat-form-field appearance="outline" floatLabel="never" class="smallInput">
                    <mat-select [disabled]="disabledUpgradeForm" (selectionChange)="changeModuleVersion($event, 'dcaVersion')">
                        <mat-option
                            *ngFor="let version of dcaVersion"
                            [value]="version.url"
                            >{{ version.url }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-element-margin">
                <div class="label">
                    ptpmonitor
                    <a matTooltip="Choose a version of ptpmonitor you'd like to deploy to this installation" i18n-matTooltip><small i18n="@@whatsThis">What's this?</small></a>
                </div>
                <mat-form-field appearance="outline" floatLabel="never" class="smallInput">
                    <mat-select [disabled]="disabledUpgradeForm" (selectionChange)="changeModuleVersion($event, 'ptpmonitorVersion')">
                        <mat-option
                            *ngFor="let version of ptpmonitorVersion"
                            [value]="version.url"
                            >{{ version.url }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-element-margin">
                <div class="label">
                    ptpwatcher
                    <a matTooltip="Choose a version of ptpwatcher you'd like to deploy to this installation" i18n-matTooltip><small i18n="@@whatsThis">What's this?</small></a>
                </div>
                <mat-form-field appearance="outline" floatLabel="never" class="smallInput">
                    <mat-select [disabled]="disabledUpgradeForm" (selectionChange)="changeModuleVersion($event, 'ptpwatcherVersion')">
                        <mat-option
                            *ngFor="let version of ptpwatcherVersion"
                            [value]="version.url"
                            >{{ version.url }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-element-margin">
                <div class="label">
                    web-dgt
                    <a matTooltip="Choose a version of the web-dgt you'd like to deploy to this installation" i18n-matTooltip><small i18n="@@whatsThis">What's this?</small></a>
                </div>
                <mat-form-field appearance="outline" floatLabel="never" class="smallInput">
                    <mat-select [disabled]="disabledUpgradeForm" (selectionChange)="changeModuleVersion($event, 'webDgtVersion')">
                        <mat-option
                            *ngFor="let version of webDgtVersion"
                            [value]="version.url"
                        >{{ version.url }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-element-margin margin-bottom-10">
            <button mat-flat-button [disabled]="disabledUpgradeButton" color="warn" style="width: 100%;" (click)="upgradeSoftware()" i18n>Upgrade software</button>
        </div>
    </form>
</div>
