import { Item } from '@libs/iso/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ItemService } from '@app/services';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class ItemByIdResolver implements Resolve<Item> {
    constructor(private _itemService: ItemService, private _store: Store<GlobalStore>) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Item> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        return this._itemService.getBaseItemDetails(route.params['itemId']);
    }
}
