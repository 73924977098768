<mat-form-field appearance='outline' class='notification-chip-list'>
    <mat-chip-list #overwritableStringList ngDefaultControl>
        <ng-container *ngIf='!!inheritedStrings && !isOverwriting'>
            <mat-chip
                *ngFor='let str of inheritedStrings'
                class='inherited'
                (removed)='removeChip(str)'
            >
                {{ str }}
                <span class='inherited-chip'>INHERITED</span>
                <mat-icon matChipRemove *ngIf='!disabled' (click)='!disabled && this.overwrite.emit()'>
                    cancel
                </mat-icon>
            </mat-chip>
        </ng-container>
        <ng-container *ngIf='!!value'>
            <mat-chip
                *ngFor='let email of value'
                color='primary'
                (removed)='removeChip(email)'
            >
                {{ email }}
                <mat-icon matChipRemove *ngIf='!disabled'>
                    cancel
                </mat-icon>
            </mat-chip>
        </ng-container>
        <input
            [type]='inputElementType'
            #overwritableStringInput
            [disabled]='disabled'
            [matChipInputFor]='overwritableStringList'
            [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
            [matChipInputAddOnBlur]='true'
            (matChipInputTokenEnd)='addChip($event)'
            (blur)='addChip(overwritableStringInput.value)'
        />
    </mat-chip-list>
</mat-form-field>
