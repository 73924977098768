import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as Plot from '@observablehq/plot';

@Component({
    selector: 'ptkr-observable-plot',
    templateUrl: './observable-plot.component.html',
    styleUrls: ['./observable-plot.component.scss']
})
export class ObservablePlotComponent {
    @ViewChild('chart', {static: true}) private chart: ElementRef;
    @Input() data: any[];
    @Input() set options(options: Plot.PlotOptions) {
        this.createChart(options);
    }

    constructor() {}

    createChart(options: Plot.PlotOptions): void {
        const chartElement = this.chart?.nativeElement;
        if (!chartElement) {
            return;
        }
        chartElement.innerHTML = '';
        const plot = Plot.plot(options);
        chartElement.appendChild(plot);
    }
}
