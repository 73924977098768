enum ItemPricingBulkUpdateFieldType {
    CustomCost = 'customCost',
    Hide = 'hide'
}

namespace ItemPricingBulkUpdateFieldType {
    export function getTypes(): ItemPricingBulkUpdateFieldType[] {
        return [ItemPricingBulkUpdateFieldType.CustomCost, ItemPricingBulkUpdateFieldType.Hide];
    }
}

export { ItemPricingBulkUpdateFieldType };
