import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'ptkr-confirm-deletion',
    templateUrl: './confirm-deletion.component.html',
    styleUrls: ['./confirm-deletion.component.scss']
})
export class ConfirmDeletionComponent {
    @Input()
    public confirmationText: string = null;
    @Output()
    public isMatch: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    public onDelConfirmKeyup(val: string): void {
        console.log(val, this.confirmationText);
        if (val === this.confirmationText) {
            this.isMatch.emit(true);
        } else {
            this.isMatch.emit(false);
        }
    }
}
