<!-- <p>Box style</p>
<div class="container">
    <ptkr-multi-chip
        *ngFor="let label of labels"
        [name]="label.name"
        [value]="label.value"
    ></ptkr-multi-chip>
</div>

<p>List style</p>
<div class="list">
    <div *ngFor="let label of labels">
        <ptkr-multi-chip [name]="label.name" [value]="label.value"></ptkr-multi-chip>
    </div>
</div> -->

<!-- Spencer 9/8/20 - The html above can be used in combination with the scss to create cool
                      representation of this label data. Disabled, but left here for future use.
                    -->

<div class="editor-container">
    <div class="table-container">
        <div style="margin-bottom: 32px">
            <button mat-flat-button (click)="addLabel()" i18n>Add label</button>
        </div>
        <table
            mat-table
            class="g-table"
            [dataSource]="labelsValue"
            id="label-table"
            *ngIf="labelsValue.length > 0; else noLabels"
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef i18n="@@name">Name</th>
                <td mat-cell *matCellDef="let data">
                    <span class="ellepsis" matTooltipShowDelay="500" [matTooltip]="data.name">{{
                        data.name || ''
                    }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef i18n="@@value">Value</th>
                <td mat-cell *matCellDef="let data">
                    <span class="ellepsis" matTooltipShowDelay="500" [matTooltip]="data.value">{{
                        data.value || ''
                    }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let data">
                    <span class="edit" (click)="editLabel(labelsValue.indexOf(data))">
                        <i class="fas fa-pencil-alt"></i>
                    </span>
                    <span class="delete" (click)="deleteLabel(labelsValue.indexOf(data))">
                        <i class="fas fa-trash-alt"></i>
                    </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['name', 'value', 'actions']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['name', 'value', 'actions']"></tr>
        </table>

        <ng-template #noLabels>
            <h2 class="empty-table" i18n>No labels (yet).</h2>
        </ng-template>
    </div>
</div>

<div class="action-buttons" style="margin-top: 32px">
    <button mat-flat-button (click)="close()" *ngIf="showCancelButton" i18n="@@cancel">Cancel</button>
    <button mat-flat-button color="accent" (click)="save()" i18n>Save settings</button>
</div>
