import { BaseDoc, BaseDocModel } from '@libs/iso/core';

export interface RoleModel extends BaseDocModel {
    name: string;
}

interface RoleConstructorParams extends RoleModel {}

export class Role extends BaseDoc implements RoleModel {
    public name: string = '';

    constructor(params?: Partial<RoleConstructorParams>) {
        super(params);
        if (!!params) {
            this.name = params.name || this.name;
        }
    }
}
