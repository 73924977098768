import { QueryEngineSchemaColumnType } from '@libs/iso/core';

export interface IQueryEngineSchemaItem {
    table: string;
    column: string;
    type: QueryEngineSchemaColumnType;
}

export class QueryEngineSchemaItem implements IQueryEngineSchemaItem {
    public table: string;
    public column: string;
    public type: QueryEngineSchemaColumnType;

    constructor(params: Partial<IQueryEngineSchemaItem>) {
        if (!!params) {
            this.table = params.table;
            this.column = params.column;
            try {
                this.type = QueryEngineSchemaColumnType.fromString(params.type);
            } catch (error) {
                console.error(error);
                this.type = QueryEngineSchemaColumnType.Unknown;
            }
        }
    }
}
