import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceService } from '@app/services';
import { Device } from '@libs/iso/core';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class DeviceByIdResolver implements Resolve<Device> {
    constructor(private _deviceService: DeviceService, private _store: Store<GlobalStore>) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Device> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        const deviceId = route.params['deviceId'] || '';
        return this._deviceService.getDeviceById(deviceId);
    }
}
