<div class='field-group'>
    <div class='form-field-header'>
        <label class='field-label' [class.required]='required'>
            {{ label }} <a *ngIf='!!hint' [matTooltip]='hint' [matTooltipShowDelay]='500' i18n="@@whatsThis">What's this?</a>
        </label>
        <ng-content select='div.ptkr-field-group-actions'></ng-content>
        <ng-content select='ptkr-inheritance-reset-button'></ng-content>
    </div>
    <div class='field'>
        <ng-content select='mat-checkbox'></ng-content>
        <ng-content select='mat-form-field'></ng-content>
        <ng-content select='mat-radio-group'></ng-content>
        <ng-content select='mat-slider'></ng-content>
        <ng-content select='mat-slide-toggle'></ng-content>
        <ng-content select='ptkr-field-group'></ng-content>
        <ng-content select='button'></ng-content>
        <ng-content select='label'></ng-content>
        <ng-content select='div'></ng-content>
        <ng-content select='ptkr-entity-picker'></ng-content>
        <ng-content select='ptkr-bmp-editor'></ng-content>
        <ng-content select='p'></ng-content>
        <ng-content select='ptkr-cron-editor'></ng-content>
        <ng-content select='ptkr-overwritable-email-array'></ng-content>
        <ng-content select='ptkr-overwritable-string-array'></ng-content>
        <ng-content select='ptkr-string-array-field'></ng-content>
        <ng-content select='ptkr-skip-alert-control'></ng-content>
        <ng-content select='ptkr-ip-address-input'></ng-content>
        <ng-content select='ptkr-timezone-picker'></ng-content>
        <ng-content select='ptkr-device-dynamic-groups-setting'></ng-content>
    </div>
</div>
