import { AppEffects } from './app.effects';
import { AuthEffects } from './auth.effects';
import { CartEffects } from './cart.effects';
import { UserEffects } from './user.effects';
import { EntityEffects } from './entity.effects';
import { EntitiesEffects } from './entities.effects';

export const effects = [
    AppEffects,
    AuthEffects,
    CartEffects,
    UserEffects,
    EntityEffects,
    EntitiesEffects
];
