import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Column } from '@libs/iso/core/helpers/columns';

@Component({
    selector: 'ptkr-edit-columns-modal',
    templateUrl: './edit-columns-modal.component.html',
    styleUrls: ['./edit-columns-modal.component.scss']
})
export class EditColumnsModalComponent {
    public unfilteredColumns: Array<Column & { index: number }> = [];
    public availableColumns: Array<Column & { index: number }> = [];
    public selectedColumns: Array<number> = [];
    public filterString: string = '';

    constructor(
        private dialogRef: MatDialogRef<EditColumnsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { columns: Array<Column>; selected: Array<number> }
    ) {
        for (let i = 0; i < data.columns.length; i++) {
            this.unfilteredColumns.push({ ...data.columns[i], index: i });
        }
        this.availableColumns = this.unfilteredColumns;
        this.selectedColumns = data.selected || this.selectedColumns;
    }

    public filter(): void {
        this.availableColumns = this.unfilteredColumns.filter(c =>
            c.name.toLowerCase().includes(this.filterString.toLocaleLowerCase())
        );
    }

    public updateSelection(event: MatCheckboxChange, c: Column & { index: number }): void {
        event.checked ? this.add(c) : this.remove(c);
    }

    public add(c: Column & { index: number }): void {
        this.selectedColumns.push(c.index);
    }

    public remove(c: Column & { index: number }): void {
        this.selectedColumns.splice(this.selectedColumns.indexOf(c.index), 1);
    }

    public drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.selectedColumns, event.previousIndex, event.currentIndex);
    }

    // Used to initialize the value of the checkboxes.
    public isChecked(c: Column & { index: number }): boolean {
        return this.selectedColumns.includes(c.index);
    }

    public cancel(): void {
        this.dialogRef.close({
            do: 'nothing',
            columns: []
        });
    }

    public confirm(): void {
        this.dialogRef.close({
            do: 'update',
            columns: this.selectedColumns
        });
    }
}
