import { Injectable } from '@angular/core';
import { BulkResetModalConfigEstimate } from '@libs/web/forms/models/overlay/BulkResetModalConfig';
import {
    HttpService,
    Get,
    Body,
    Query,
    Put,
    Path,
    Post,
    ResponseType,
    Adapter,
    MapClass
} from '@libs/web/http';
import { Observable } from 'rxjs';
import {
    PaginatedList,
    Event,
    EntityTree,
    PagesLostReportType,
    PagesLostDataType,
    HistoricalMeterRead,
    ManagedDeviceCountByProperty,
    Reporting,
    EntityHierarchy,
    InstallCountByProperty,
    InstallPropertyType,
    IBinnedValue,
    ISettings,
    EntityAddress,
    Status,
    DynamicSettingsKeyType, ISettingsFormSubmission, IAuditLogItem
} from '@libs/iso/core';
import { Entity, User } from '@app/models';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';

@Injectable()
export class EntityService extends HttpService {
    constructor(protected _http: HttpClient, private _store: Store<GlobalStore>) {
        super(_http);
    }

    // Spencer 7/9/2020 - This is specifically for the super table.
    @Get('/entity/list')
    public getNewList(
        @Query('entityId') entityKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('columns') columns?: Array<number>
    ): Observable<any> {
        return null;
    }

    @Get('/entity/children')
    public children(@Query('parentEntityKey') parentEntityKey: string): Observable<Entity[]> {
        return null;
    }

    @Get('/entity/searchChildren')
    public searchChildren(
        @Query('parentEntityKey') parentEntityKey: string,
        @Query('name') name: string
    ): Observable<Entity[]> {
        return null;
    }

    @Get('/entity/searchDescendants')
    public searchDescendants(
        @Query('name') name: string,
        @Query('limit') limit: number
    ): Observable<any[]> {
        return null;
    }

    @Get('/entity/breadcrumb')
    public breadcrumb(@Query('entityKey') entityKey: string): Observable<Entity[]> {
        return null;
    }

    @Get('/entity/{id}')
    @MapClass(Entity)
    public getEntityDetails(@Path('id') id: string): Observable<Entity> {
        return null;
    }

    @Get('/entity/{id}/history')
    public getEntityHistory(
        @Path('id') id: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('includeAncestors') includeAncestors: boolean
    ): Observable<IAuditLogItem[]> {
        return null;
    }

    @Put('/entity/{id}/contactInfo')
    public updateEntityContactInfo(
        @Path('id') id: string,
        @Body('status') status: Status,
        @Body('name') name: string,
        @Body('addresses') addresses: EntityAddress[],
        @Body('parentEntityKey') parentEntityKey: string,
        @Body('dealer') dealer: { notes: string },
        @Body('isDealer') isDealer: boolean
    ): Observable<any> {
        return null;
    }

    @Put('/entity/labels')
    public updateEntityLabels(
        @Body('id') id: string,
        @Body('labels') labels: { [key: string]: string }
    ): Observable<any> {
        return null;
    }

    @Post('/entity/remove')
    public removeEntity(@Body('id') id: string): Observable<any> {
        return null;
    }

    @Post('/entity/create', ResponseType.Text)
    public createEntity(@Body() params: Partial<Entity>): Observable<string> {
        return null;
    }

    @Get('/entity/{id}/topAlerts')
    // tslint:disable-next-line:ter-prefer-arrow-callback
    @Adapter(function(res): { count: number; docs: Event[] } {
        const docs = Array.isArray(res.docs) ? res.docs : [];
        return {
            count: res.count || 0,
            docs: docs.map(d => new Event(d))
        };
    })
    public getTopAlerts(
        @Path('id') entityId: string,
        @Query('type') type: string,
        @Query('limit') limit: number,
        @Query('count') count: boolean,
        @Query('includeChildren') includeChildren?: boolean
    ): Observable<{ docs: Event[]; count: number }> {
        return null;
    }

    @Post('/entity/hierarchy', ResponseType.Text)
    public saveHierarchyChanges(@Body() params: any): Observable<string> {
        return null;
    }

    @Get('/entity/{id}/alerts')
    // tslint:disable-next-line:ter-prefer-arrow-callback
    @Adapter(function(res): PaginatedList<Event> {
        const docs = Array.isArray(res.docs) ? res.docs : [];
        const list = new PaginatedList<Event>();
        list.totalDocs = res.totalDocs;
        list.urls = res.urls;
        list.docs = docs.map(d => new Event(d));
        return list;
    })
    public getEntityAlerts(
        @Path('id') entityId: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeSubEntities') includeChildren?: boolean,
        @Query('includeResolvedAlerts') includeResolved?: boolean,
        @Query('serviceAlertsOnly') serviceAlertsOnly?: boolean,
        @Query('searchTerm') searchTerm?: string,
    ): Observable<PaginatedList<Event>> {
        return null;
    }

    @Get('/entity/{id}/reportingDevices')
    public getReportingDevices(@Path('id') entityId: string): Observable<Reporting> {
        return null;
    }

    @Get('/entity/{id}/reportingInstalls')
    public getReportingInstalls(@Path('id') entityId: string): Observable<Reporting> {
        return null;
    }

    @Get('/entity/{id}/reportingEntities')
    public getReportingEntities(@Path('id') entityId: string): Observable<Reporting> {
        return null;
    }

    @Get('/entity/{id}/getRecursiveEverything')
    // tslint:disable-next-line:ter-prefer-arrow-callback
    @Adapter(function(res): EntityTree {
        return new EntityTree(res.root);
    })
    public getRecursiveEverything(
        @Path('id') entityId: string,
        @Query('type') type?: string
    ): Observable<EntityTree> {
        return null;
    }

    @Get('/entity/pagesLostByEntity')
    public pagesLostByEntity(
        @Query('entityId') entityId: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('reportType') reportType: PagesLostReportType,
        @Query('dataType') dataType: PagesLostDataType,
        @Query('includeChildren') includeChildren: boolean
    ): Observable<any> {
        return null;
    }

    @Get('/entity/pagesLostByDevice')
    public pagesLostByDevice(
        @Query('entityId') entityId: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('reportType') reportType: PagesLostReportType,
        @Query('dataType') dataType: PagesLostDataType
    ): Observable<any> {
        return null;
    }

    @Get('/entity/{id}/salesRep')
    @MapClass(User)
    public getSalesRep(@Path('id') entityId: string): Observable<User> {
        return null;
    }

    @Get('/entity/{id}/meterReadExportCsv', ResponseType.Blob)
    public getCurrentMeterReadsForChildrenFlattened(
        @Path('id') entityKey: string,
        @Query('includeChildren') includeChildren: boolean,
        @Query('searchTerm') searchTerm?: string,
        @Query('excludeDisabled') excludeDisabled: boolean = true,
        @Query('excludeDroppedOff') excludeDroppedOff: boolean = true
    ): Observable<any> {
        return null;
    }

    @Get('/entity/{id}/bulkDeviceUpdateCsv', ResponseType.Blob)
    public getDeviceBulkUpdateCsv(
        @Path('id') entityKey: string,
        @Query('bool') localOnly: boolean,
        @Query('includeChildren') includeChildren: boolean,
        @Query('searchTerm') searchTerm?: string,
        @Query('excludeDisabled') excludeDisabled: boolean = true,
        @Query('excludeDroppedOff') excludeDroppedOff: boolean = true
    ): Observable<any> {
        return null;
    }

    @Get('/entity/{id}/alertsExportCsv', ResponseType.Blob)
    public exportAlertsCSV(
        @Path('id') entityKey: string,
        @Query('includeChildren') includeChildren: boolean,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string
    ): Observable<any> {
        return null;
    }

    @Get('/entity/{entityId}/meterViewerList')
    public getMeterViewerList(
        @Query('entityId') entityId: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('columns') columns?: Array<number>,
        @Query('isBillingReport') isBillingReport?: boolean | string,
        @Query('hideBilled') hideBilled?: boolean | string
    ): Observable<any> {
        return null;
    }

    /**
     * returns estimate of affects of bulk reset.
     * @param {string} entityId  Id of entity the children of which will be
     *                           reset theoretically.
     * @param {string} setting path to setting that would be reset.
     * @return {Observable<BulkResetModalConfigEstimate>} Number of entities
     *                           devices and installs that will be affected.
     */
    @Get('/entity/{entityId}/bulkReset/estimate')
    public estimateBulkReset(
        @Path('entityId') entityId: string,
        @Query('setting') setting: string
    ): Observable<BulkResetModalConfigEstimate> {
        return null;
    }

    /**
     * Executes the bulk reset of the given setting for all children under the
     * given entity. This does NOT include the given entity's setting itself.
     * @param {string} entityId  Id of entity the children of which will be
     *                           reset.
     * @param {string} setting  The setting that will be reset.
     * @return {Observable<any>}  Nothing.
     */
    @Get('/entity/{entityId}/bulkReset/run')
    public runBulkReset(
        @Path('entityId') entityId: string,
        @Query('setting') setting: string
    ): Observable<any> {
        return null;
    }

    @Put('/entity/{entityId}/meterViewerList/bill')
    public setToBilled(
        @Query('entityId') entityId: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('searchTerm') searchTerm?: string,
        @Query('columns') columns?: Array<number>
    ): Observable<any> {
        return null;
    }

    @Get('/entity/{entityId}/meterViewerCsv', ResponseType.Blob)
    public getMeterViewerCsv(
        @Query('entityId') entityId: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('columns') columns?: Array<number>,
        @Query('isBillingReport') isBillingReport?: boolean | string,
        @Query('hideBilled') hideBilled?: boolean | string
    ): Observable<any> {
        return null;
    }

    @Get('/entity/{id}/historicalMeterReads')
    public getHistoricalMeterReads(
        @Path('id') entityId: string,
        @Body('devices') devices: Array<string>,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string
    ): Observable<Array<HistoricalMeterRead>> {
        return null;
    }

    @Get('/entity/{entityId}/listMeters')
    public getMeterReadList(
        @Query('entityId') entityId: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('columns') columns?: Array<number>
    ): Observable<any> {
        return null;
    }

    @Get('/entity/{entityId}/meterCsv', ResponseType.Blob)
    public getMeterReadCSV(
        @Query('entityId') entityId: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('columns') columns?: Array<number>
    ): Observable<any> {
        return null;
    }

    @Get('/entity/{id}/expirationDate')
    public getExpirationDate(@Path('id') entityId: string): Observable<Date> {
        return null;
    }

    @Get('/entity/{id}/managedDeviceCountByProperty')
    @MapClass(ManagedDeviceCountByProperty)
    public getManagedDeviceCountByProperty(
        @Path('id') entityKey: string,
        @Query('property') property
    ): Observable<ManagedDeviceCountByProperty> {
        return null;
    }

    @Get('/entity/{id}/installCountByProperty')
    @MapClass(InstallCountByProperty)
    public getInstallCountByProperty(
        @Path('id') entityKey: string,
        @Query('property') property: InstallPropertyType
    ): Observable<InstallCountByProperty> {
        return null;
    }

    @Get('/entity/{id}/installCountBinnedByLastCheckIn')
    public getInstallCountBinnedByLastCheckIn(
        @Path('id') entityKey: string
    ): Observable<Array<IBinnedValue>> {
        return null;
    }

    @Get('/entity/{id}/hierarchySize', ResponseType.Text)
    @Adapter((res: string): number => +res)
    public getHierarchySize(@Path('id') entityKey: string): Observable<number> {
        return null;
    }

    @Get('/entity/{id}/eventsSinceBeginningOfMonth', ResponseType.Text)
    @Adapter((res: string): number => +res)
    public getEventCountSinceBeginningOfMonth(@Path('id') entityKey: string): Observable<number> {
        return null;
    }

    @Get('/entity/{id}/hierarchy')
    @MapClass(EntityHierarchy)
    public getHierarchy(@Path('id') entityKey: string): Observable<EntityHierarchy> {
        return null;
    }

    // DYNAMIC SETTINGS
    @Get('/entity/{id}/settingsForm')
    public settingsGetFormData(
        @Path('id') id: string
    ): Observable<{ settings: ISettings; inheritance: ISettings }> {
        return null;
    }

    /**
     * Resolve settings for an entity
     * @param {string} id  entity to resolve settings for
     * @returns {Observable<ISettings>}  resolved settings object
     */
    @Get('/entity/{id}/settings')
    public settingsResolveOne(@Path('id') id: string): Observable<ISettings> {
        return null;
    }

    /**
     * Resolve settings for several entities simultaneously
     * @param {string[]} ids  entities to resolve settings for
     * @param {DynamicSettingsKeyType[]} settings  If array is non-empty, will only return listed settings
     * @returns {Object[]} List of id-settings pairs corresponding to the ids in 'ids'
     */
    @Post('/entity/settings')
    public settingsResolveMany(
        @Body('ids') ids: string[],
        @Body('settings') settings: DynamicSettingsKeyType[] = []
    ): Observable<{ _id: string; settings: ISettings }[]> {
        return null;
    }

    /**
     * Finds entity in DB matching 'id', and replaces contents of settings property with 'updates'.
     * @param {string} id  device to update
     * @param {object} updates  list of settings key-value pairs
     * @returns {Observable} status information resulting from DB operation (should we do this?)
     */
    @Put('/entity/{id}/settings')
    public settingsSet(
        @Path('id') id: string,
        @Body('updates') updates: ISettingsFormSubmission
    ): Observable<any> {
        return null;
    }

    // END DYNAMIC SETTINGS
}
