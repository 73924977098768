export interface IDeviceSkipAlertHistoryItem {
    timestamp: Date;
    skipsRemaining: number;
    comment: string;
}

export class DeviceSkipAlertHistoryItem implements IDeviceSkipAlertHistoryItem {
    public timestamp: Date = new Date();
    public skipsRemaining: number = 0;
    public comment: string = '';

    constructor(params?: Partial<IDeviceSkipAlertHistoryItem>) {
        if (!!params) {
            this.timestamp = new Date(params.timestamp) ?? this.timestamp;
            this.skipsRemaining = params.skipsRemaining ?? this.skipsRemaining;
            this.comment = params.comment ?? this.comment;
        }
    }

}
