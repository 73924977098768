import { AddAchComponent } from './add-ach/add-ach.component';
import { AddCreditCardComponent } from './add-credit-card/add-credit-card.component';
import { CompatibleOrderTableComponent } from './compatible-order-table/compatible-order-table.component';
import { ComponentAutocompleteComponent } from './component-autocomplete/component-autocomplete.component';
import { DevicesReportingChartComponent } from './devices-reporting-chart/devices-reporting-chart.component';
import { IncludeChildrenToggleComponent } from './include-children-toggle/include-children-toggle.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PartColorComponent } from './part-color/part-color.component';
import { SalesRepInfoComponent } from './sales-rep-info/sales-rep-info.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { NewSecuredHeaderComponent as SecuredHeaderComponent } from './new-secured-header/new-secured-header.component';
import { NewSecuredSidebarComponent as SecuredSidebarComponent } from './new-secured-sidebar/new-secured-sidebar.component';
import { NewSecuredFooterComponent as SecuredFooterComponent } from './new-secured-footer/new-secured-footer.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { TimeFrameComponent } from './time-frame/time-frame.component';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { BlockHeaderComponent } from './block-header/block-header.component';
import { PagesThisMonthComponent } from './pages-this-month/pages-this-month.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { UserPasswordComponent } from './user-password/user-password.component';
import { UserProfileFieldsComponent } from './user-profile-fields/user-profile-fields.component';
import { UserRoleComponent } from './user-role/user-role.component';
import { EntityItemComponent } from './entity-item/entity-item.component';
import { AddShippingAddressComponent } from './add-shipping-address/add-shipping-address.component';
import { SelectShippingAddressComponent } from './select-shipping-address/select-shipping-address.component';
import { SelectShippingMethodComponent } from './select-shipping-method/select-shipping-method.component';
import { SelectPaymentMethodComponent } from './select-payment-method/select-payment-method.component';
import { SalesOrderComponent } from './sales-order/salesOrder.component';
import { DeviceStartingPageCountsComponent } from './device-starting-page-counts/device-starting-page-counts.component';
import { DeviceStartingPageCountsSectionComponent } from './device-starting-page-counts-section/device-starting-page-counts-section.component';
import { HistoryTabComponent } from './history-tab/history-tab.component';
import { EntityPickerComponent } from './entity-picker/entity-picker.component';
import { DataTableComponent } from './data-table/data-table.component';
import { PaginationPanelComponent } from './pagination-panel/pagination-panel.component';
import { SuperTablePageComponent } from './super-table-page/super-table-page.component';
import { LabelEditorComponent } from './label-viewer/label-editor.component';
import { MultiChipComponent } from './multi-chip/multi-chip.component';
import { DataListComponent } from './data-list/data-list.component';
import { BulkAddInstallIprangeComponent } from './bulk-add-install-iprange/bulk-add-install-iprange.component';
import { DeviceDataGatheringSettingsSectionComponent } from './device-data-gathering-settings-section/device-data-gathering-settings-section.component';
import { MeterReadValueCardComponent } from './meter-read-value-card/meter-read-value-card.component';
import { CounterPickerComponent } from './counter-picker/counter-picker.component';
import { formComponents } from './forms';
import { MeterFormatPillComponent } from './meter-format-pill/meter-format-pill.component';
import { MessageBarComponent } from './message-bar/message-bar.component';
import { BtnPreloaderComponent } from './btn-preloader/btn-preloader.component';
import { InlineMeterNameCardComponent } from './inline-meter-name-card/inline-meter-name-card.component';
import { DcaSoftwareUpgradeComponent } from './dca-software-upgrade/dca-software-upgrade.component';
import { InstallHealthcheckComponent } from './install-healthcheck/install-healthcheck.component';
import { ChipsComponent } from './chips/chips.component';
import { ChipDialogComponent } from './chips/chip-dialog/chip-dialog.component';
import { InstallHealthcheckV2Component } from './install-healthcheck-v2/install-healthcheck-v2.component';
import { MeterFormatChipsComponent } from './meter-format-chips/meter-format-chips.component';
import { MeterSuppliesChipsComponent } from './meter-supplies-chips/meter-supplies-chips.component';
import { WelcomeDcaSetupComponent } from './welcome-dca-setup/welcome-dca-setup.component';
import { JobListTableComponent } from './job-list-table/job-list-table.component';
import { ConcatinatedJobLogsTableComponent } from './concatinated-job-logs-table/concatinated-job-logs-table.component';
import { ConfirmDeletionComponent } from './confirm-deletion/confirm-deletion.component';
import { MeterKeyPickerComponent } from './meter-key-picker/meter-key-picker.component';
import { DgiVersionSelectorComponent } from './dgi-version-selector/dgi-version-selector.component';
import { CategoricalDistributionInlineComponent } from '@app/shared/components/categorical-distribution-inline/component';
import { IdQuickCopyButtonComponent } from './id-quick-copy-button/id-quick-copy-button.component';
import { JobLogViewerComponent } from './job-log-viewer/job-log-viewer.component';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { InlineLogHintComponent } from './inline-log-hint/inline-log-hint.component';
import { InstallReportingDevicesComponent } from './install-reporting-devices/install-reporting-devices.component';
import { ReportingDevicesDistributionComponent } from './reporting-devices-distribution/reporting-devices-distribution.component';
import { InstallListTableViewerComponent } from './install-list-table-viewer/install-list-table-viewer.component';
import { DeviceListTableViewerComponent } from './device-list-table-viewer/device-list-table-viewer.component';
import { SNMPDumpFileListComponent } from './snmpdump-file-list/snmpdump-file-list.component';
import { JobListTableViewerComponent } from './job-list-table-viewer/job-list-table-viewer.component';
import { NamedMatDividerComponent } from './named-mat-divider/named-mat-divider.component';
import { BannerComponent } from './banner/banner.component';
import { QueryEngineInterfaceComponent } from './query-engine-interface/query-engine-interface.component';
import { EmptyTableIndicatorComponent } from './empty-table-indicator/empty-table-indicator.component';
import { TimezonePickerComponent } from './timezone-picker/timezone-picker.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { DownloadInstallerImageComponent } from './download-installer-image/download-installer-image.component';
import { ObservablePlotComponent } from './observable-plot/observable-plot.component';
import { ExportSeparatorSelectComponent } from './export-separator-select/export-separator-select.component';


export const components = [
    ...formComponents,
    AddAchComponent,
    AddCreditCardComponent,
    CompatibleOrderTableComponent,
    ComponentAutocompleteComponent,
    DevicesReportingChartComponent,
    IncludeChildrenToggleComponent,
    InvoiceComponent,
    PageHeaderComponent,
    PartColorComponent,
    SalesRepInfoComponent,
    SearchBarComponent,
    SecuredHeaderComponent,
    SecuredSidebarComponent,
    SecuredFooterComponent,
    StatusIndicatorComponent,
    TimeFrameComponent,
    TreeNodeComponent,
    BlockHeaderComponent,
    PagesThisMonthComponent,
    BreadcrumbComponent,
    UserPasswordComponent,
    UserProfileFieldsComponent,
    UserRoleComponent,
    EntityItemComponent,
    AddShippingAddressComponent,
    SelectShippingAddressComponent,
    SelectShippingMethodComponent,
    SelectPaymentMethodComponent,
    DeviceStartingPageCountsComponent,
    DeviceStartingPageCountsSectionComponent,
    SalesOrderComponent,
    HistoryTabComponent,
    EntityPickerComponent,
    DataTableComponent,
    PaginationPanelComponent,
    SuperTablePageComponent,
    LabelEditorComponent,
    MultiChipComponent,
    DataListComponent,
    BulkAddInstallIprangeComponent,
    DeviceDataGatheringSettingsSectionComponent,
    MeterReadValueCardComponent,
    CounterPickerComponent,
    MeterFormatPillComponent,
    MessageBarComponent,
    BtnPreloaderComponent,
    InlineMeterNameCardComponent,
    DcaSoftwareUpgradeComponent,
    MeterFormatChipsComponent,
    ChipsComponent,
    ChipDialogComponent,
    MeterSuppliesChipsComponent,
    WelcomeDcaSetupComponent,
    ConfirmDeletionComponent,
    MeterKeyPickerComponent,
    InstallHealthcheckComponent,
    InstallHealthcheckV2Component,
    JobListTableComponent,
    ConcatinatedJobLogsTableComponent,
    DgiVersionSelectorComponent,
    CategoricalDistributionInlineComponent,
    IdQuickCopyButtonComponent,
    ActionBarComponent,
    InlineLogHintComponent,
    InstallReportingDevicesComponent,
    ReportingDevicesDistributionComponent,
    InstallListTableViewerComponent,
    JobLogViewerComponent,
    DeviceListTableViewerComponent,
    SNMPDumpFileListComponent,
    JobListTableViewerComponent,
    NamedMatDividerComponent,
    BannerComponent,
    NamedMatDividerComponent,
    QueryEngineInterfaceComponent,
    EmptyTableIndicatorComponent,
    TimezonePickerComponent,
    LanguageSelectorComponent,
    DownloadInstallerImageComponent,
    ObservablePlotComponent,
    ExportSeparatorSelectComponent
];

export {
    AddAchComponent,
    AddCreditCardComponent,
    CompatibleOrderTableComponent,
    ComponentAutocompleteComponent,
    DevicesReportingChartComponent,
    IncludeChildrenToggleComponent,
    InvoiceComponent,
    PageHeaderComponent,
    PartColorComponent,
    SalesRepInfoComponent,
    SearchBarComponent,
    SecuredHeaderComponent,
    SecuredSidebarComponent,
    SecuredFooterComponent,
    StatusIndicatorComponent,
    TimeFrameComponent,
    TreeNodeComponent,
    BlockHeaderComponent,
    PagesThisMonthComponent,
    BreadcrumbComponent,
    UserPasswordComponent,
    UserProfileFieldsComponent,
    UserRoleComponent,
    EntityItemComponent,
    DeviceStartingPageCountsComponent,
    DeviceStartingPageCountsSectionComponent,
    SalesOrderComponent,
    EntityPickerComponent,
    PaginationPanelComponent,
    SuperTablePageComponent,
    LabelEditorComponent as LabelViewerComponent,
    DataListComponent,
    BulkAddInstallIprangeComponent,
    AddShippingAddressComponent,
    DcaSoftwareUpgradeComponent,
    InstallHealthcheckComponent,
    ChipsComponent,
    ChipDialogComponent,
    InstallHealthcheckV2Component,
    BtnPreloaderComponent,
    MeterFormatChipsComponent,
    MeterSuppliesChipsComponent,
    WelcomeDcaSetupComponent,
    MessageBarComponent,
    JobListTableComponent,
    ConcatinatedJobLogsTableComponent,
    HistoryTabComponent,
    ConfirmDeletionComponent,
    DataTableComponent,
    MeterReadValueCardComponent,
    CounterPickerComponent,
    MultiChipComponent,
    MeterFormatPillComponent,
    InlineMeterNameCardComponent,
    MeterKeyPickerComponent,
    DgiVersionSelectorComponent,
    CategoricalDistributionInlineComponent,
    IdQuickCopyButtonComponent,
    ActionBarComponent,
    InlineLogHintComponent,
    InstallReportingDevicesComponent,
    ReportingDevicesDistributionComponent,
    InstallListTableViewerComponent,
    JobLogViewerComponent,
    DeviceListTableViewerComponent,
    SNMPDumpFileListComponent,
    JobListTableViewerComponent,
    NamedMatDividerComponent,
    BannerComponent,
    QueryEngineInterfaceComponent,
    EmptyTableIndicatorComponent,
    TimezonePickerComponent,
    LanguageSelectorComponent,
    DownloadInstallerImageComponent,
    ObservablePlotComponent,
    ExportSeparatorSelectComponent
};
