import { GraphType } from '../../enums';
import { ColumnType } from '../../types';

export interface IDeviceReport {
    graphType: GraphType;
    xTitle?: string;
    yTitle?: string;
    columns: {
        [key: string]: ColumnType;
    };
    data: any[][];
}

export class DeviceReport implements IDeviceReport {
    public graphType: GraphType = GraphType.Line;
    public xTitle?: string;
    public yTitle?: string;
    public columns: { [key: string]: ColumnType } = {};
    public data: any[][] = [];

    constructor(defaults?: Partial<IDeviceReport>) {
        if (defaults) {
            this.graphType = defaults.graphType || this.graphType;
            if (defaults.xTitle) {
                this.xTitle = defaults.xTitle;
            }
            if (defaults.yTitle) {
                this.yTitle = defaults.yTitle;
            }
            this.columns = defaults.columns || this.columns;
            this.data = defaults.data || this.data;
        }
    }
}
