<ng-container *ngIf="windowSize$ | async as windowSize">
    <div class="secured-container" *ngIf="windowSize === WindowSize.Desktop">
        <ptkr-secured-header
            [entity]="entity$ | async"
            [user]="user$ | async"
            [alertNotificationCount]="alertCount"
            [serviceNotificationCount]="serviceCount"
            (changeEntity)="changeEntity($event)"
            (toggleEntityDropdown)="toggleEntityDropdown()"
            (toggleServiceNotificationsDropdown)="toggleServiceDropdown($event)"
            (toggleProfileDropdown)="toggleViewProfileDropdown($event)">
        </ptkr-secured-header>
        <div class="secured-main-content">
            <ptkr-secured-sidebar
                [user]="user$ | async"
                [activeLink]="activeLink"
                (navigate)="navigate($event)"
                (openJobs)="openJobs()">
            </ptkr-secured-sidebar>
            <div class="content">
                <div class="exit-impersonation" *ngIf="isImpersonating$ | async">
                    <span (click)="endImpersonation()">
                        <i class="far fa-sign-out"></i>
                        Stop impersonating
                    </span>
                </div>
                <div cdkScrollable class="content-container">
                    <router-outlet></router-outlet>
    <!--                <div class="fade-wrapper">-->
    <!--                    <div class="fade"></div>-->
    <!--                </div>-->
                </div>
                <ptkr-secured-footer></ptkr-secured-footer>
            </div>
        </div>
    </div>
    <div class="secured-container-mobile" *ngIf="windowSize === WindowSize.Mobile || windowSize === WindowSize.Tablet">
        <mat-sidenav-container>
            <mat-sidenav #sidenav mode="push">
                <ptkr-secured-sidebar
                    [user]="user$ | async"
                    [activeLink]="activeLink"
                    (navigate)="navigate($event)">
                </ptkr-secured-sidebar>
            </mat-sidenav>
            <mat-sidenav-content>
                <ptkr-secured-header
                    [entity]="entity$ | async"
                    [user]="user$ | async"
                    [alertNotificationCount]="alertCount"
                    [serviceNotificationCount]="serviceCount"
                    (changeEntity)="changeEntity($event)"
                    (toggleEntityDropdown)="toggleEntityDropdown()"
                    (toggleServiceNotificationsDropdown)="toggleServiceDropdown($event)"
                    (toggleProfileDropdown)="toggleViewProfileDropdown($event)"
                    (toggleMenu)="toggleMenu()">
                </ptkr-secured-header>
                <div class="content">
                    <div class="exit-impersonation" *ngIf="isImpersonating$ | async">
                        <span (click)="endImpersonation()">
                            <i class="far fa-sign-out"></i>
                            Stop impersonating
                        </span>
                    </div>
                    <div
                        class="content-container"
                        [class.is-impersonating]="isImpersonating$ | async">
                        <router-outlet></router-outlet>
                    </div>
                    <ptkr-secured-footer></ptkr-secured-footer>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</ng-container>
<div class="global-spinner" *ngIf="(showLoading$ | async) === true">
    <mat-spinner></mat-spinner>
</div>
