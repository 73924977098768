import { Injectable } from '@angular/core';
import { NotificationService } from '@app/core/services/notification.service';
import { PartialObserver } from 'rxjs';

/**
 * FormMessageService provides handy tools for showing popups and snackbars for our dynamic settings
 * forms.
 */
@Injectable({
    providedIn: 'root'
})
export class FormMessageService {
    /**
     * defaultObserver is set up when this server is first constructed so that it can
     * provide a quick way to provide a message emitter like many of our form controls with an
     * observer. It automatically calls 'showMessage' every time a value is pushed to it via 'next'.
     * @see showMessage
     */
    public defaultObserver: PartialObserver<{ type: string; message: string }>;

    constructor(private _notificationService: NotificationService) {
        this.defaultObserver = {
            next: ({ type, message }): void => this.showMessage(type, message)
        };
    }

    /**
     * showMessage makes a snackbar appear with the styling matching 'type' and containing the
     * message from 'message'.
     * @param {string} type the style of snackbar to use
     * @param {string} message the contents of the snackbar
     * @returns {void}
     */
    public showMessage(type: string, message: string): void {
        switch (type) {
            case 'error': {
                this._notificationService.error(message);
                break;
            }
            default: {
                this._notificationService.info(message);
            }
        }
    }
}
