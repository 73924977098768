<div class="editor-container">
    <div class="table-container">
        <table
            mat-table
            class="g-table"
            [dataSource]="getCounterData()"
            id="label-table"
            *ngIf="showTable(); else noMeters"
        >
            <ng-container matColumnDef="meter">
                <th mat-header-cell class="cell -left" *matHeaderCellDef>
                    Meter
                </th>
                <td mat-cell class="cell -left" *matCellDef="let data">
                    {{ data.displayName || 'Unknown' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="preferences">
                <th mat-header-cell *matHeaderCellDef>
                    Preferences
                </th>
                <td mat-cell *matCellDef="let data">
                    <span *ngIf="!isDevice || data.preferences.length <= 1" matTooltipShowDelay="500" class="alt-container">
                        <ng-container *ngFor="let d of data.preferences">
                            <div class="meter-container">
                                <ptkr-inline-meter-name-card
                                    class="alt-meters"
                                    [index]="
                                        data.preferences.length !== 1
                                            ? data.preferences.indexOf(d)
                                            : undefined
                                    "
                                    [name]="displayBillingMeter(d.format, d.meter)"
                                    [format]="d.format"
                                    [matTooltip]="displayBillingMeter(d.format, d.meter)"
                                    [disabled]="isDevice && data.preferences.length !== 1"
                                ></ptkr-inline-meter-name-card>
                            </div>
                        </ng-container>
                    </span>
                    <p class="manual-preference" *ngIf="isDevice && data.preferences.length > 1">
                        This device has not collected a matching meter yet based on your inherited
                        preferences,
                        <a
                            class="inline-link"
                            (click)="
                                manuallySetPreference(
                                    findCounterPrefs(data, currentSelection)
                                )
                            "
                            >manually set device preference?</a
                        >
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell class="cell -right" *matHeaderCellDef>
                    <span class="add-meter-button" (click)="addMeter()"
                        >Add Meter <i class="far fa-plus"></i
                    ></span>
                </th>
                <td mat-cell class="cell -right" *matCellDef="let data">
                    <span
                        class="edit"
                        (click)="editMeter(findCounterPrefs(data, currentSelection))"
                    >
                        <i class="fas fa-pencil-alt"></i>
                    </span>
                    <span
                        class="delete"
                        (click)="deleteMeter(findCounterPrefs(data, currentSelection))"
                    >
                        <i class="fas fa-trash-alt"></i>
                    </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['meter', 'preferences', 'actions']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['meter', 'preferences', 'actions']"></tr>
        </table>

        <ng-template #noMeters>
            <h2 class="empty-table">No billing meters set</h2>
            <div class="center-this-div">
                <span class="add-meter-button center-label" (click)="addMeter()"
                    >Add Meter <i class="far fa-plus"></i
                ></span>
            </div>
        </ng-template>
    </div>
</div>
