<h2>Notifications</h2>
<div
    *ngFor="let n of data.notifications"
    [class.critical]="isCritical(n)">
    <div>
        <div class="title">
            <span> {{ EventType.notificationTitle(n.type) }} Alert </span>
            <span *ngIf="!isCritical(n)" class="non-critical status">
                ({{ EventResolutionStatus.toString(n.resolutionStatus) }})
            </span>
            <span *ngIf="isCritical(n)" class="critical status">
                <i class="fas fa-bell"></i>
            </span>
        </div>
        <p *ngIf="isCritical(n)">{{ n.description }} (<span (click)="order(n)">Order now</span>)</p>
        <p *ngIf="!isCritical(n)">{{ n.description }}</p>
        <p class="date-time">{{ n.dtTm | date: 'M/d/yy, h:mm:ss a' }}</p>
    </div>
    <div>
        <span *ngIf="!!n.acknowledged && !!n.acknowledged.note" (click)="$event.stopPropagation(); viewNote(n)">
            <i class="fas fa-comment-alt"></i>
        </span>
    </div>
    <hr>
</div>

<a id="cy-notification-overlay-component-view-all-alerts-btn" (click)="viewAllAlerts()" mat-button color="primary">View all alerts</a>
