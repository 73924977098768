import { ISettings, SettingsKeyMap } from '@libs/iso/core';
import { InheritanceFormControl } from '@libs/web/forms/models/form-controls/InheritanceFormControl';
import { AbstractDynamicSettingsSectionFormGroup } from '@libs/web/forms/models/form-groups/AbstractDynamicSettingsSectionFormGroup';

/**
 * A pre-configured FormGroup for install and entity general info settings. Not to be confused with
 * device non-inherited settings.
 * @see FormGroup
 */
export class GeneralInfoDynamicSettingsSectionFormGroup extends AbstractDynamicSettingsSectionFormGroup {
    /**
     * @param {ISettings} settings current state of form
     * @param {ISettings} parentSettings fills empty form fields with inherited default values
     * @return {GeneralInfoDynamicSettingsSectionFormGroup} new instance
     * @see FormGroup.constructor
     */
    constructor(settings: Partial<ISettings>, parentSettings: ISettings) {
        super({
            // Onsite Contact
            [SettingsKeyMap.GeneralInformation.OnsiteContact.Email]: new InheritanceFormControl(
                settings?.generalInformation?.onsiteContact?.email,
                parentSettings.generalInformation.onsiteContact.email
            ),
            [SettingsKeyMap.GeneralInformation.OnsiteContact.Phone]: new InheritanceFormControl(
                settings?.generalInformation?.onsiteContact?.phone,
                parentSettings.generalInformation.onsiteContact.phone
            ),
            [SettingsKeyMap.GeneralInformation.OnsiteContact.Name]: new InheritanceFormControl(
                settings?.generalInformation?.onsiteContact?.name,
                parentSettings.generalInformation.onsiteContact.name
            ),
        });
    }
}
