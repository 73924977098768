enum PaymentMethodType {
    Card = '[PaymentMethodType] card',
    ACH = '[PaymentMethodType] ACH',
    NET10 = '[PaymentMethodType] NET10',
    ALL = 'ALL'
}

namespace PaymentMethodType {
    export const asArray: PaymentMethodType[] = [
        PaymentMethodType.Card,
        PaymentMethodType.ACH,
        PaymentMethodType.NET10
    ];

    export function convertStripeType(stripeStatus: string): PaymentMethodType {
        switch (stripeStatus) {
            case 'card':
                return PaymentMethodType.Card;
            case 'bank_account':
                return PaymentMethodType.ACH;
            default:
                return null;
        }
    }

    export function convertQueryType(stripeStatus: string): PaymentMethodType {
        switch (stripeStatus.toLowerCase()) {
            case 'card':
                return PaymentMethodType.Card;
            case 'ach':
                return PaymentMethodType.ACH;
            case 'all':
                return PaymentMethodType.ALL;
            default:
                return null;
        }
    }

    export function toString(type: PaymentMethodType): string {
        switch (type) {
            case PaymentMethodType.ACH:
                return 'ACH';
            case PaymentMethodType.Card:
                return 'Card';
            case PaymentMethodType.ALL:
                return 'All';
            default:
                return '';
        }
    }
}

export { PaymentMethodType };
