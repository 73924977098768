<section>
    <div class="heading">Bulk Device Import</div>
    <div class="subheading">
        Use <a href="https://www.cdn.printtrackerpro.com/misc/BulkDiscoveryTemplate.csv" target="_blank">this template</a> to import a list of devices and the associated metadata
        that should be applied to each device.
    </div>
</section>

<mat-accordion>
    <mat-expansion-panel [expanded]="state.panel1Open">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Step 1: Import CSV
            </mat-panel-title>
            <mat-panel-description>
                Import a csv with the ip addresses of your printers
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
            <span class="h3">Import CSV</span>
            <br />
            <small>Select a csv to import</small>
        </div>
        <div class="csv-import">
            <div class="form-group">
                <input type="file" (change)="onFileSelect($event.target)" name="myfile" />
            </div>
            <!--            <button mat-flat-button>-->
            <!--                <mat-icon>cloud_upload</mat-icon>-->
            <!--                Upload-->
            <!--            </button>-->
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="state.panel2Open">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Step 2: Apply
            </mat-panel-title>
            <mat-panel-description>
                Check the data and apply the import
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="notification-banner-container" *ngIf="duplicates > 0">
            <div class="notification-banner">
                <mat-icon matTooltip="Duplicates identified by ip address">info</mat-icon>
                {{ duplicates }} duplicates removed
            </div>
        </div>

        <div class="p6n-table-extended-scroll-container">
            <div class="table-wrapper">
                <table class="p6n-table p6n-table-pagination p6n-checkboxed-table">
                    <thead>
                        <tr>
                            <th>IP Address</th>
                            <th>Asset ID</th>
                            <th>Location</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let device of csvData | async">
                            <td>{{ device.ipAddress }}</td>
                            <td>{{ device.assetId }}</td>
                            <td>{{ device.location }}</td>
                            <td>
                                <span *ngIf="device?.notes?.length > 0">...</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button mat-flat-button (click)="applyImport()">
                <!--                <mat-icon>cloud_download</mat-icon>-->
                Import
            </button>
        </div>
    </mat-expansion-panel>
</mat-accordion>
