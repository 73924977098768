import { Component } from '@angular/core';
import { DeviceNonInheritedSettingsFormGroup } from '@libs/web/forms';
import { AbstractSettingsSectionComponent } from '@libs/web/forms/components/settings-sections/abstract-settings-section/abstract-settings-section.component';

@Component({
    selector: 'ptkr-device-non-inherited-settings-section',
    templateUrl: './device-non-inherited-settings-section.component.html',
    styleUrls: ['./device-non-inherited-settings-section.component.scss']
})
export class DeviceNonInheritedSettingsSectionComponent
    extends AbstractSettingsSectionComponent<DeviceNonInheritedSettingsFormGroup> {
}
