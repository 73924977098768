import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { InheritanceFormControl } from '@libs/web/forms/models/form-controls/InheritanceFormControl';
import * as _ from 'lodash';

export class EmailArrayFormControl extends InheritanceFormControl {
    public overwrite: boolean = false;

    constructor(
        formState: any = null,
        inheritedState: any,
        validatorOrOps?: ValidatorFn | AbstractControlOptions | ValidatorFn[],
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
    ) {
        super(formState, inheritedState, validatorOrOps, asyncValidator);
        if (formState === null || formState === undefined) {
            this.revert();
        } else {
            // Options parameter is preventing this update from being treated like a user input.
            this.setValue(this.value, { onlySelf: true, emitEvent: false });
        }
    }

    public revert(): void {
        this._resetValue(null);
        this.overwrite = false;
    }

    /**
     * This provides the value that should be uploaded into the database.
     * For an EmailArrayFormControl such as this, the 'overwrite:' prefix is added to an arbitrary
     * email address if 'overwrite' is true and there is at least one email in the array.
     */
    public get childValue(): any {
        // deep clone the array so we don't change the state of the setting by accident
        let out: string[] = _.cloneDeep(this.value);
        // add overwrite directive if appropriate
        if (this.overwrite) {
            if (!out) {
                out = [];
            }
            out.push('overwrite:');
        }
        return out;
    }

    /**
     * Wraps parent's setValue to filter for 'overwrite:' syntax and remove it.
     * @see FormControl.setValue
     * @param {any} value  new control value
     * @param {Object} options  options
     * @returns {void}
     */
    public setValue(
        value: any,
        options: {
            onlySelf?: boolean;
            emitEvent?: boolean;
            emitModelToViewChange?: boolean;
            emitViewToModelChange?: boolean;
        } = {}
    ): void {
        return super.setValue(
            value === null || value === undefined
            ? null
            : value.filter((email: string) => {
                if (email === 'overwrite:') {
                    this.overwrite = true;
                    return false;
                }
                return true;
            }).map((email: string) => {
                if (email === null || email === undefined) {
                    return email;
                }
                if (email.startsWith('overwrite:')) {
                    this.overwrite = true;
                    return email.slice(10);
                }
                return email;
            }),
            options
        );
    }
}
