import { Entity } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityService } from '@app/services';
import { fromEntity } from '@app/state/selectors';
import { Store, select } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class EntityByIdResolver implements Resolve<Entity> {
    constructor(private _entityService: EntityService, private _store: Store<GlobalStore>) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Entity> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        return this._store.pipe(
            select(fromEntity.currentEntity),
            take(1),
            tap(entity => { })
        );
    }
}
