import '@angular/localize/init';

enum EventResolutionStatus {
    // New = '[EventResolutionStatus] new',
    // InProgress = '[EventResolutionStatus] in progress',
    // Resolved = '[EventResolutionStatus] resolved'

    // Dca doesn't add them in the above format, so...
    New = 'New',
    InProgress = 'In Progress',
    Resolved = 'Resolved'
}

namespace EventResolutionStatus {
    export const types: EventResolutionStatus[] = [
        EventResolutionStatus.New,
        EventResolutionStatus.InProgress,
        EventResolutionStatus.Resolved
    ];

    export function toString(type: EventResolutionStatus): string {
        switch (type) {
            case EventResolutionStatus.New:
                return $localize`New`;
            case EventResolutionStatus.InProgress:
                return $localize`In progress`;
            case EventResolutionStatus.Resolved:
                return $localize`Resolved`;
            default:
                return '';
        }
    }
}

export { EventResolutionStatus };
