<div [ngSwitch]="browser">
    <img src="/assets/download-installer-chrome.png" *ngSwitchDefault/>
    <img src="/assets/download-installer-firefox.png" *ngSwitchCase="Browser.Firefox"/>
    <img src="/assets/download-installer-edge.png" *ngSwitchCase="Browser.Edge"/>
    <ng-container *ngSwitchCase="Browser.Safari">
        <img src="/assets/download-installer-safari-part-1.png"/>
        <div class="m-t-10">
            <img src="/assets/download-installer-safari-part-2.png"/>
        </div>
    </ng-container>
</div>
