import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { SNMPAlgorithm } from '@libs/iso/core/enums/SNMPAlgorithm';
import { SNMPPrivacyProtocol } from '@libs/iso/core/enums/SNMPPrivacyProtocol';
import { AbstractSettingsSectionComponent } from '@libs/web/forms/components/settings-sections/abstract-settings-section/abstract-settings-section.component';
import { SharedDynamicSettingsSectionFormGroup } from '@libs/web/forms/models/form-groups/SharedDynamicSettingsSectionFormGroup';

@Component({
    selector: 'ptkr-shared-settings-section',
    templateUrl: './shared-dynamic-settings-section.component.html',
    styleUrls: ['./shared-dynamic-settings-section.component.scss']
})
export class SharedDynamicSettingsSectionComponent extends AbstractSettingsSectionComponent<
    SharedDynamicSettingsSectionFormGroup
> {
    @Input() public isDevice: boolean = false;

    public SNMPAlgorithm: typeof SNMPAlgorithm = SNMPAlgorithm;
    public SNMPPrivacyProtocol: typeof SNMPPrivacyProtocol = SNMPPrivacyProtocol;
    public separatorKeysCodes: any = [ENTER, COMMA, SEMICOLON, TAB];

    public console: typeof console = console;

    constructor(private _cd: ChangeDetectorRef) {
        super();
    }
}
