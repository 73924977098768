enum InvoiceStatus {
    Completed = 'Completed',
    Paid = 'Paid',
    Open = 'Open',
    Pending = 'Pending',
    Closed = 'Closed',
    Balanced = 'Balanced'
}

namespace InvoiceStatus {
    export function toString(type: InvoiceStatus): string {
        switch (type) {
            case InvoiceStatus.Completed:
                return 'Completed';
            case InvoiceStatus.Paid:
                return 'Paid';
            case InvoiceStatus.Open:
                return 'Open';
            case InvoiceStatus.Pending:
                return 'Pending';
            case InvoiceStatus.Closed:
                return 'Closed';
            case InvoiceStatus.Balanced:
                return 'Balanced';
            default:
                return 'N/A';
        }
    }

    export const asArray: InvoiceStatus[] = [
        InvoiceStatus.Completed,
        InvoiceStatus.Paid,
        InvoiceStatus.Open,
        InvoiceStatus.Pending,
        InvoiceStatus.Closed,
        InvoiceStatus.Balanced
    ];
}

export { InvoiceStatus };
