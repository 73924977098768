import { Injectable, Injector, ElementRef } from '@angular/core';
import {
    Overlay,
    OverlayRef,
    GlobalPositionStrategy,
    OverlayConfig,
    FlexibleConnectedPositionStrategy,
    PositionStrategy,
    ConnectedPosition
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { IDeviceListTableQueryParams } from '@app/core/models/TableConfiguration';
import {
    ProfileOverlayComponent,
    NotificationOverlayComponent,
    SelectEntityOverlayComponent,
    ConfirmModalComponent,
    ConfirmMultipleModalComponent,
    DeviceAddManuallyModalComponent,
    OrderTonerOverlayComponent,
    EventNoteComponent,
    ViewInvoiceComponent,
    BulkEntityItemsComponent,
    VerifyAchComponent,
    ShippingAddressModalComponent,
    PaymentMethodModalComponent,
    CheckoutOverlayComponent,
    InputPromptComponent,
    WarrantyOverlayComponent,
    JobsModalComponent,
    ConfirmSettingsModalComponent,
    PostDownloadModalComponent,
    WelcomeDcaSetupOverlayComponent,
    IpSnmpDumpModalComponent,
    InputModalWithContentComponent,
    RestartJobConfirmationComponent,
    DGIApplyAndTestConfirmationComponent,
    JobLogViewerModalComponent,
    EditReportScheduleModalComponent,
    ReportScheduledEventsModalComponent,
    DeviceMeterHistoryQueryModalComponent
} from '@app/shared/overlays';
import {
    ProfileOverlayConfig,
    constants,
    NotificationOverlayConfig,
    SelectEntityOverlayConfig,
    selectEntityOverlayDefaultConfig,
    SelectEntityOverlayRef,
    ConfirmModalConfig,
    confirmModalDefaultConfig,
    ConfirmMultipleModalConfig,
    confirmMultipleModalDefaultConfig,
    DeviceAddManuallyModalConfig,
    deviceAddManuallyModalDefaultConfig,
    BulkEntityItemsConfig,
} from '@app/models';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IDeviceSkipAlert } from '@libs/iso/core/models/device/DeviceSkipAlert';
import { SkipAlertsHistoryModalComponent } from '@libs/web/forms/overlays';
import { RouterService } from './router.service';
import {
    Cart,
    Item,
    Install,
    ReportScheduled,
    Report,
    ReportScheduledEvent
} from '@libs/iso/core';
import { BulkDeviceImportComponent } from '@app/shared/overlays/bulk-device-import/bulk-device-import.component';
import { BulkDeviceUpdateComponent } from '@app/shared/overlays/bulk-device-update/bulk-device-update.component';
import { DeleteEntityModalComponent } from '@app/shared/overlays/delete-entity-modal/delete-entity-modal.component';
import { ObjectId } from 'mongodb';
import { Column } from '@libs/iso/core/helpers/columns';
import { EditColumnsModalComponent } from '@app/shared/overlays/edit-columns-modal/edit-columns-modal.component';
import { LabelEditorModalComponent } from '@app/shared/overlays/label-editor-modal/label-editor-modal.component';

@Injectable()
export class OverlayService {
    constructor(
        private _overlay: Overlay,
        private _injector: Injector,
        private _dialog: MatDialog,
        private _routerService: RouterService
    ) {}

    public openPaymentMethodModal(): MatDialogRef<PaymentMethodModalComponent> {
        return this._dialog.open(PaymentMethodModalComponent, {
            panelClass: 'add-payment-method-modal-panel'
        });
    }

    public openShippingAddressModal(): MatDialogRef<ShippingAddressModalComponent> {
        return this._dialog.open(ShippingAddressModalComponent, {
            panelClass: 'add-shipping-address-modal-panel'
        });
    }

    public openInvoiceModal(invoice: any): MatDialogRef<ViewInvoiceComponent> {
        return this._dialog.open(ViewInvoiceComponent, {
            panelClass: 'view-invoice-modal-panel',
            data: invoice
        });
    }

    public openInputPromptOverlay(config: {
        type: 'text' | 'email';
        placeholder: string;
    }): MatDialogRef<InputPromptComponent> {
        return this._dialog.open(InputPromptComponent, {
            data: config
        });
    }

    public openInputModalWithContent(config: {
        type: 'text' | 'email';
        placeholder: string;
        config: ConfirmModalConfig;
    }): MatDialogRef<InputModalWithContentComponent> {
        return this._dialog.open(InputModalWithContentComponent, {
            data: config,
            maxWidth: '500px'
        });
    }

    public openConfirmationModal(
        config: ConfirmModalConfig = {}
    ): MatDialogRef<ConfirmModalComponent> {
        const confirmConfig: ConfirmModalConfig = { ...confirmModalDefaultConfig, ...config };
        return this._dialog.open(ConfirmModalComponent, {
            data: confirmConfig
        });
    }

    public openConfirmMultipleModal(
        config: ConfirmMultipleModalConfig = {}
    ): MatDialogRef<ConfirmMultipleModalComponent> {
        const confirmMultipleConfig: ConfirmMultipleModalConfig = {
            ...confirmMultipleModalDefaultConfig,
            ...config
        };
        return this._dialog.open(ConfirmMultipleModalComponent, {
            data: confirmMultipleConfig
        });
    }

    public openDeviceAddManuallyModal(
        config: DeviceAddManuallyModalConfig = {}
    ): MatDialogRef<DeviceAddManuallyModalComponent> {
        const deviceAddManuallyConfig: DeviceAddManuallyModalConfig = {
            ...deviceAddManuallyModalDefaultConfig,
            ...config
        };
        console.log(deviceAddManuallyConfig);
        return this._dialog.open(DeviceAddManuallyModalComponent, {
            data: deviceAddManuallyConfig
        });
    }

    public openOrderTonerOverlay(
        defaultItem: Item = null
    ): MatDialogRef<OrderTonerOverlayComponent> {
        return this._dialog.open(OrderTonerOverlayComponent, {
            data: {
                defaultItem: defaultItem
            },
            panelClass: 'order-toner-overlay-panel',
            autoFocus: !!defaultItem === false
        });
    }

    public openBulkEntityItemsOverlay(
        settings: BulkEntityItemsConfig
    ): MatDialogRef<BulkEntityItemsComponent> {
        return this._dialog.open(BulkEntityItemsComponent, {
            data: settings,
            panelClass: 'bulk-settings-overlay'
        });
    }

    public verifyACHOverlay(): MatDialogRef<VerifyAchComponent> {
        return this._dialog.open(VerifyAchComponent);
    }

    public openCheckoutOverlay(cart: Cart): MatDialogRef<CheckoutOverlayComponent> {
        return this._dialog.open(CheckoutOverlayComponent, {
            data: {
                cart: cart
            },
            panelClass: 'checkout-overlay'
        });
    }

    public openEventNoteOverlay(
        currentNote: string,
        writePermissions: boolean
    ): MatDialogRef<EventNoteComponent> {
        return this._dialog.open(EventNoteComponent, {
            data: {
                note: currentNote,
                writeEnabled: writePermissions
            }
        });
    }

    public openWarrantyOverlay(): MatDialogRef<WarrantyOverlayComponent> {
        return this._dialog.open(WarrantyOverlayComponent, {
            data: {}
        });
    }

    public openJobsOverlay(): MatDialogRef<JobsModalComponent> {
        return this._dialog.open(JobsModalComponent, {
            data: {}
        });
    }

    public openBulkDeviceImportOverlay(install: Install): MatDialogRef<BulkDeviceImportComponent> {
        return this._dialog.open(BulkDeviceImportComponent, {
            data: {
                install: install
            }
        });
    }

    public openBulkDeviceUpdateOverlay(tableParams: IDeviceListTableQueryParams): MatDialogRef<BulkDeviceUpdateComponent> {
        return this._dialog.open(BulkDeviceUpdateComponent, {
            data: { tableParams: tableParams }
        });
    }

    public openWelcomeDcaSetupOverlay(): MatDialogRef<WelcomeDcaSetupOverlayComponent> {
        return this._dialog.open(WelcomeDcaSetupOverlayComponent, {
            disableClose: true,
            data: {},
        });
    }

    public openProfileDropdownOverlay(
        connectedElement: ElementRef,
        config: ProfileOverlayConfig = {}
    ): MatDialogRef<ProfileOverlayComponent> {
        return this._dialog.open(ProfileOverlayComponent, {
            data: {
                elementRef: connectedElement
            },
            minWidth: 128,
            scrollStrategy: this._overlay.scrollStrategies.noop()
        });
        // const profileConfig: ProfileOverlayConfig = { ...profileOverlayDefaultConfig, ...config };
        // const positionStrategy = this._getNewHeaderPositioningStrategy(connectedElement, [
        //     {
        //         originX: 'start',
        //         originY: 'bottom',
        //         overlayX: 'start',
        //         overlayY: 'top',
        //         offsetY: 12
        //     }
        // ]);
        // const overlayRef = this._createOverlay(
        //     profileConfig,
        //     this._overlay.scrollStrategies.noop(),
        //     positionStrategy
        // );
        // const dialogRef = new ProfileOverlayRef(overlayRef);
        // overlayRef.backdropClick().subscribe(() => dialogRef.close());
        // const injectionTokens = new WeakMap();
        // injectionTokens.set(ProfileOverlayRef, dialogRef);
        // const portalInjector = new PortalInjector(this._injector, injectionTokens);
        // const dropdownPortal = new ComponentPortal<ProfileOverlayComponent>(
        //     ProfileOverlayComponent,
        //     null,
        //     portalInjector
        // );
        // overlayRef.attach(dropdownPortal);
        // return dialogRef;
    }

    public openNotificationOverlay(
        connectedElement: ElementRef,
        config: NotificationOverlayConfig = {}
    ): MatDialogRef<NotificationOverlayComponent> {
        return this._dialog.open(NotificationOverlayComponent, {
            data: {
                ...config,
                elementRef: connectedElement
            },
            minWidth: 384,
            scrollStrategy: this._overlay.scrollStrategies.noop()
        });
    }

    public openSelectEntityOverlay(config: SelectEntityOverlayConfig): SelectEntityOverlayRef {
        // console.log(selectEntityOverlayDefaultConfig);
        // console.log(config);
        const selectEntityConfig = { ...selectEntityOverlayDefaultConfig, ...config };
        const positionStrategy = this._getHeaderPositioningStrategy();
        const overlayRef = this._createOverlay(
            selectEntityConfig,
            this._overlay.scrollStrategies.block(),
            positionStrategy
        );
        const dialogRef = new SelectEntityOverlayRef(overlayRef, this._routerService);
        overlayRef.backdropClick().subscribe(() => dialogRef.close());
        const injectionTokens = new WeakMap();
        injectionTokens.set(SelectEntityOverlayRef, dialogRef);
        // injectionTokens.set(SELECT_ENTITY_OVERLAY_DATA, selectEntityConfig.availableEntities);
        const portalInjector = new PortalInjector(this._injector, injectionTokens);
        const dropdownPortal = new ComponentPortal<SelectEntityOverlayComponent>(
            SelectEntityOverlayComponent,
            null,
            portalInjector
        );
        overlayRef.attach(dropdownPortal);
        return dialogRef;
    }

    public openConfirmSettingsModal(userEmail: string): MatDialogRef<ConfirmSettingsModalComponent> {
        return this._dialog.open(
            ConfirmSettingsModalComponent,
            {
                data: {
                    userEmail: userEmail
                }
            }
        );
    }

    public openPostDownloadModal(): MatDialogRef<PostDownloadModalComponent> {
        return this._dialog.open(PostDownloadModalComponent, {
            maxWidth: '550px'
        });
    }

    public openDeleteEntityModal(entity: {
        name: string;
        _id: string | ObjectId;
    }): MatDialogRef<DeleteEntityModalComponent> {
        return this._dialog.open(DeleteEntityModalComponent, {
            data: {
                entity: {
                    name: entity.name,
                    _id: entity._id.toString()
                }
            }
        });
    }

    public openIpSnmpDumpModal(): MatDialogRef<IpSnmpDumpModalComponent> {
        return this._dialog.open(IpSnmpDumpModalComponent, {
            data: {}
        });
    }

    public openEditColumnsModal(
        columns: Array<Column>,
        selected: Array<number>
    ): MatDialogRef<EditColumnsModalComponent> {
        return this._dialog.open(EditColumnsModalComponent, {
            data: {
                columns: columns,
                selected: selected
            }
        });
    }

    public openEditLabelsModal(labels: {
        [key: string]: string;
    }): MatDialogRef<LabelEditorModalComponent> {
        return this._dialog.open(LabelEditorModalComponent, {
            data: {
                labels: labels
            }
        });
    }

    public restartDCAConfirmationModal(): MatDialogRef<RestartJobConfirmationComponent> {
        return this._dialog.open(RestartJobConfirmationComponent, { maxWidth: '350px' });
    }

    public openDGIApplyAndTestConfirmationModal(): MatDialogRef<
        DGIApplyAndTestConfirmationComponent
    > {
        return this._dialog.open(DGIApplyAndTestConfirmationComponent, {
            maxWidth: '350px'
        });
    }

    public openJobLogViewer(id: string): MatDialogRef<JobLogViewerModalComponent> {
        return this._dialog.open(JobLogViewerModalComponent, {
            data: { id },
            maxWidth: '900px'
        });
    }

    public openEditReportSchedule(
        report: Report,
        schedule?: ReportScheduled
    ): MatDialogRef<EditReportScheduleModalComponent> {
        return this._dialog.open<EditReportScheduleModalComponent, any, ReportScheduled>(
            EditReportScheduleModalComponent,
            {
                data: { report, schedule },
                minWidth: '650px'
            }
        );
    }

    public openReportScheduledEventsModal(
        events: ReportScheduledEvent[]
    ): MatDialogRef<ReportScheduledEventsModalComponent> {
        return this._dialog.open<ReportScheduledEventsModalComponent, any, ReportScheduled>(
            ReportScheduledEventsModalComponent,
            {
                data: { events },
                minWidth: '600px'
            }
        );
    }

    public openDeviceMeterHistoryQueryModal(
        deviceId: string,
        serialNum: string
    ): MatDialogRef<DeviceMeterHistoryQueryModalComponent> {
        return this._dialog.open<DeviceMeterHistoryQueryModalComponent>(
            DeviceMeterHistoryQueryModalComponent,
            {
                data: { deviceId, serialNum },
                width: '98vw',
                height: '98vh'
            }
        );
    }

    public openSkipAlertsHistoryModal(
        displayName: string,
        skipAlert: IDeviceSkipAlert
    ): MatDialogRef<SkipAlertsHistoryModalComponent> {
        return this._dialog.open<SkipAlertsHistoryModalComponent>(
            SkipAlertsHistoryModalComponent,
            {
                data: {
                    displayName: displayName,
                    skipAlert: skipAlert
                },
                minWidth: '600px'
            }
        );
    }

    private _createOverlay(
        config: any,
        scrollStrategy: any,
        positionStrategy: PositionStrategy
    ): OverlayRef {
        const overlayConfig: OverlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy,
            positionStrategy
        });
        return this._overlay.create(overlayConfig);
    }

    private _getHeaderPositioningStrategy(): GlobalPositionStrategy {
        const heightOffset: string = window
            ? window.innerWidth < constants.breakpoints.mobile.portrait
                ? constants.securedHeaderProfileBarHeightPx
                : constants.securedHeaderHeightPx
            : constants.securedHeaderHeightPx;
        const centerOverlay: boolean = window
            ? window.innerWidth < constants.breakpoints.mobile.portrait
                ? true
                : false
            : false;
        let positionStrategy: GlobalPositionStrategy = this._overlay
            .position()
            .global()
            .top(heightOffset);
        if (centerOverlay) {
            positionStrategy = positionStrategy.centerHorizontally();
        } else {
            positionStrategy = positionStrategy.left();
        }
        return positionStrategy;
    }

    private _getNewHeaderPositioningStrategy(
        connectedElement: ElementRef,
        positions: ConnectedPosition[] = [
            {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
                offsetY: 12
            }
        ]
    ): FlexibleConnectedPositionStrategy {
        const viewportMargin = 20;
        const positionStrategy: FlexibleConnectedPositionStrategy = this._overlay
            .position()
            .flexibleConnectedTo(connectedElement)
            .withViewportMargin(viewportMargin)
            .withPositions(positions);
        return positionStrategy;
    }
}
