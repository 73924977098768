import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Invoice, InvoiceItem } from '@libs/iso/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'ptkr-sales-order',
    templateUrl: './salesOrder.component.html',
    styleUrls: ['./salesOrder.component.scss']
})
export class SalesOrderComponent implements OnInit {
    private _salesOrder: Invoice;
    @Input()
    public isPrinting: boolean;
    @Input()
    public set salesOrder(value: Invoice) {
        if (!this.dataSource) {
            this.dataSource = new MatTableDataSource<InvoiceItem>(value.invoiceItems);
        } else {
            this.dataSource.data = value.invoiceItems;
        }
        this._salesOrder = value;
    }
    public get invoice(): Invoice {
        return this._salesOrder;
    }
    @Output()
    public print: EventEmitter<Invoice> = new EventEmitter<Invoice>();
    // @Output() public email: EventEmitter<Invoice> = new EventEmitter<Invoice>();

    public displayedColumns: string[] = ['description', 'cost', 'qty', 'total'];
    public dataSource: MatTableDataSource<InvoiceItem>;
    constructor() {}

    ngOnInit(): void {}

    public printInvoice(): void {
        this.print.emit(this.invoice);
    }

    // public sendInvoice(): void {
    //     this.email.emit(this.invoice);
    // }
}
