import { Component, Input } from '@angular/core';
import { NewJob } from '@libs/iso/core/models';
import { JobStatusTypes, Permission } from '@libs/iso/core';
import { RtsService } from '@app/core/services/rts.service';
import { JobService } from '@app/core/services/job.service';

@Component({
    selector: 'ptkr-job',
    templateUrl: './job.component.html',
    styleUrls: ['./job.component.scss']
})
export class JobComponent {
    @Input('job')
    public job: NewJob;
    public Permission: typeof Permission = Permission;
    public objectKeys: Object = Object.keys;

    constructor(
        private _rtsService: RtsService,
        private _jobService: JobService
    ) {}

    public labelClass(status: JobStatusTypes): string {
        switch (status) {
            case JobStatusTypes.Sent:
                return JobStatusTypes.Sent;
            case JobStatusTypes.Received:
                return JobStatusTypes.Received;
            case JobStatusTypes.Running:
                return JobStatusTypes.Running;
            case JobStatusTypes.Rescheduled:
                return JobStatusTypes.Rescheduled;
            case JobStatusTypes.Stopped:
                return JobStatusTypes.Stopped;
            case JobStatusTypes.Failed:
                return JobStatusTypes.Failed;
            case JobStatusTypes.Completed:
                return JobStatusTypes.Completed;
            default:
                return 'default';
        }
    }

    public async deleteJob(jobId: any): Promise<void> {
        await this._jobService.deleteJobsById([jobId]).subscribe();
    }

    public canDeleteJob(status: JobStatusTypes): boolean {
        switch (status) {
            case JobStatusTypes.Completed:
                return false;
            case JobStatusTypes.Stopped:
                return false;
            case JobStatusTypes.Failed:
                return false;
            case JobStatusTypes.Sent:
                return false;
            default:
                return true;
        }
    }
}
