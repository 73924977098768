<!-- TODO: New styling using ptkr-form-group -->
<ng-container *ngIf='!!formGroup; else noFormGroup'>
    <form [formGroup]='formGroup'>
        <mat-accordion>
            <mat-expansion-panel *ngIf='!isInstall'>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Install Alerts
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Toggle alerts for installs; set email contacts
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <ptkr-field-group
                        label='New install alerts'
                        hint='Enables alerts that are sent when a new install is detected'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.Alerts.Install.New.Enabled'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <div class='mat-toggle-container' style='position: relative;'>
                            <mat-slide-toggle class='toggle-field-infix m-r-5' id='device-volumeRecurring-toggle'
                                              [formControlName]='SettingsKeyMap.Alerts.Install.New.Enabled'>
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class='slide-toggle-trigger-overlay' matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <div class='-indent' *ngIf='getFieldValue(SettingsKeyMap.Alerts.Install.New.Enabled)'>
                        <ptkr-field-group
                            label='New install alert emails'
                            hint='New install alert emails will be sent to these addresses'
                            i18n-label
                            i18n-hint>
                            <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.Alerts.Install.New.Emails'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <ptkr-overwritable-email-array
                                [formControlName]='SettingsKeyMap.Alerts.Install.New.Emails'
                                [inheritedEmails]='getField(SettingsKeyMap.Alerts.Install.New.Emails).inheritedState'
                                [isOverwriting]='getField(SettingsKeyMap.Alerts.Install.New.Emails).overwrite'
                                [notifications]='notifications'
                                (overwrite)='setOverwrite(SettingsKeyMap.Alerts.Install.New.Emails)'>
                            </ptkr-overwritable-email-array>
                        </ptkr-field-group>
                    </div>
                </section>

                <section>
                    <ptkr-field-group
                            label='Stale install'
                            hint="Sends an alert when an install hasn't been seen in a while"
                            i18n-label
                            i18n-hint>
                        <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.Alerts.Install.Stale.Enabled'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <div class='mat-toggle-container' style='position: relative;'>
                            <mat-slide-toggle class='toggle-field-infix m-r-5'
                                              [formControlName]='SettingsKeyMap.Alerts.Install.Stale.Enabled'>
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class='slide-toggle-trigger-overlay' matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <div class='-indent' *ngIf='getFieldValue(SettingsKeyMap.Alerts.Install.Stale.Enabled)'>
                        <ptkr-field-group
                                label='Alert emails'
                                hint='Which emails should this alert be sent to?'
                                i18n-label
                                i18n-hint>
                            <ptkr-inheritance-reset-button
                                    [settingName]='SettingsKeyMap.Alerts.Install.Stale.Emails'
                                    [showDropdown]='showDropdown'
                                    (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <ptkr-overwritable-email-array
                                    [formControlName]='SettingsKeyMap.Alerts.Install.Stale.Emails'
                                    [inheritedEmails]='getField(SettingsKeyMap.Alerts.Install.Stale.Emails).inheritedState'
                                    [isOverwriting]='getField(SettingsKeyMap.Alerts.Install.Stale.Emails).overwrite'
                                    [notifications]='notifications'
                                    (overwrite)='setOverwrite(SettingsKeyMap.Alerts.Install.Stale.Emails)'>
                            </ptkr-overwritable-email-array>
                        </ptkr-field-group>

                        <ptkr-field-group
                                label='Stale days'
                                hint="How many days does an install have to be offline before it's considered 'stale'?"
                                i18n-label
                                i18n-hint>
                            <ptkr-inheritance-reset-button
                                    [settingName]='SettingsKeyMap.Alerts.Install.Stale.StaleDays'
                                    [showDropdown]='showDropdown'
                                    (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <mat-form-field appearance='outline'>
                                <input
                                        matInput
                                        type='number'
                                        [formControlName]='SettingsKeyMap.Alerts.Install.Stale.StaleDays'
                                        min=1
                                />
                            </mat-form-field>
                        </ptkr-field-group>
                    </div>
                </section>

                <section>
                    <ptkr-field-group
                            label='Stale install back online'
                            hint='Sends an alert when a stale install re-connects'
                            i18n-label
                            i18n-hint>
                        <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.Alerts.Install.StaleBackOnline.Enabled'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <div class='mat-toggle-container' style='position: relative;'>
                            <mat-slide-toggle class='toggle-field-infix m-r-5'
                                              [formControlName]='SettingsKeyMap.Alerts.Install.StaleBackOnline.Enabled'>
                                    <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class='slide-toggle-trigger-overlay' matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <div class='-indent' *ngIf='getFieldValue(SettingsKeyMap.Alerts.Install.StaleBackOnline.Enabled)'>
                        <ptkr-field-group
                                label='Alert emails'
                                hint='Which emails should this alert be sent to?'
                                i18n-label
                                i18n-hint>
                            <ptkr-inheritance-reset-button
                                    [settingName]='SettingsKeyMap.Alerts.Install.StaleBackOnline.Emails'
                                    [showDropdown]='showDropdown'
                                    (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <ptkr-overwritable-email-array
                                    [formControlName]='SettingsKeyMap.Alerts.Install.StaleBackOnline.Emails'
                                    [inheritedEmails]='getField(SettingsKeyMap.Alerts.Install.StaleBackOnline.Emails).inheritedState'
                                    [isOverwriting]='getField(SettingsKeyMap.Alerts.Install.StaleBackOnline.Emails).overwrite'
                                    [notifications]='notifications'
                                    (overwrite)='setOverwrite(SettingsKeyMap.Alerts.Install.StaleBackOnline.Emails)'>
                            </ptkr-overwritable-email-array>
                        </ptkr-field-group>

                        <ptkr-field-group
                                label='Stale days'
                                hint="How many days does an install have to be stale before we'll send a 'back online' alert?"
                                i18n-label
                                i18n-hint>
                            <ptkr-inheritance-reset-button
                                    [settingName]='SettingsKeyMap.Alerts.Install.StaleBackOnline.StaleDays'
                                    [showDropdown]='showDropdown'
                                    (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <mat-form-field appearance='outline'>
                                <input
                                        matInput
                                        type='number'
                                        [formControlName]='SettingsKeyMap.Alerts.Install.StaleBackOnline.StaleDays'
                                        min=2
                                />
                            </mat-form-field>
                        </ptkr-field-group>
                    </div>
                </section>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Data Collection Agent
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Time and frequency of meter reads
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <ptkr-field-group
                        label='Check for alert frequency (in minutes)'
                        hint='How often would you like the Data Collection Agent to check printers for supply and toner
                          levels? (minimum 60 minutes)'
                        i18n-label
                        i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.Alerts.Toner.CheckFrequencyMinutes'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <input
                                matInput
                                type='number'
                                min=60
                                max=10080
                                [formControlName]='SettingsKeyMap.Alerts.Toner.CheckFrequencyMinutes'
                            />
                        </mat-form-field>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='Start daily meter read upload'
                        hint='The time range the data collection agent will first attempt to upload a meter. If no meter read is uploaded during that window, it will continue to try every hour until a meter read is successfully uploaded.'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.UploadTime.DayPart'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'
                        ></ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <mat-select [formControlName]='SettingsKeyMap.UploadTime.DayPart'>
                                <mat-option
                                    *ngFor='let o of SettingsTimeFrame.timesAsArray'
                                    [value]='o'
                                >{{ SettingsTimeFrame.toString(o) }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='Scan for new device on days'
                        hint='Choose which days you would like the Data Collection Agent to scan your networks for new
                              devices'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.DeviceDiscovery.AutoScanForNewDevices.DaysOfWeek'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <!-- TODO: make this mat-checkbox into its own component or leverage one if it already exists -->
                        <mat-checkbox
                            *ngFor='let o of DayOfWeek.asArray'
                            [value]='o'
                            [checked]='isWeekdayChecked(o)'
                            [disabled]='
                                getField(
                                    SettingsKeyMap.DeviceDiscovery
                                        .AutoScanForNewDevices.DaysOfWeek
                                ).disabled
                            '
                            (change)='
                                checkboxArrayHandler(
                                    $event,
                                    o,
                                    SettingsKeyMap.DeviceDiscovery
                                        .AutoScanForNewDevices.DaysOfWeek
                                )
                            '
                        >
                            {{ DayOfWeek.toString(o) }}
                        </mat-checkbox>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='Real-time connection'
                        hint='Enabling this setting allows the DCA to get jobs instantly'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.Rts.Enabled'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <div class='mat-toggle-container' style='position: relative;'>
                            <mat-slide-toggle class='toggle-field-infix m-r-5' id='install-rts-toggle'
                                              [formControlName]='SettingsKeyMap.Rts.Enabled'>
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class='slide-toggle-trigger-overlay' matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                    <ng-container *ngIf='isDaemon'> <!-- Can't access shared module from here; would be bad design to do so -->
                        <ptkr-field-group
                            label='Disable Auto-Upgrade (Daemon Only)'
                            hint='Enabling this will prevent auto-updates'
                            i18n-label
                            i18n-hint>
                            <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.Upgrade.Auto.Disabled'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <div class='mat-toggle-container' style='position: relative;'>
                                <mat-slide-toggle class='toggle-field-infix m-r-5' id='auto-upgrade-disable-toggle'
                                                  [formControlName]='SettingsKeyMap.Upgrade.Auto.Disabled'>
                                    <ng-container i18n="@@enabled">Enabled</ng-container>
                                    <div class='slide-toggle-trigger-overlay' matRipple></div>
                                </mat-slide-toggle>
                            </div>
                        </ptkr-field-group>
                        <ptkr-field-group
                            label='Concurrency (Daemon Only)'
                            i18n-label>
                            <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.DataGathering.Concurrency'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <mat-form-field>
                                <input matInput [formControlName]='SettingsKeyMap.DataGathering.Concurrency'
                                       type='number' min='1' max='50' />
                            </mat-form-field>
                        </ptkr-field-group>
                        <ptkr-field-group
                            label='Feature Flags (Daemon Only)'
                            i18n-label>
                            <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.FeatureFlags'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <ptkr-overwritable-string-array
                                [formControlName]='SettingsKeyMap.FeatureFlags'
                                [inheritedStrings]='getField(SettingsKeyMap.FeatureFlags).inheritedState'
                                [isOverwriting]='getField(SettingsKeyMap.FeatureFlags).overwrite'
                                [notifications]='notifications'
                                (overwrite)='setOverwrite(SettingsKeyMap.FeatureFlags)'>
                            </ptkr-overwritable-string-array>
                        </ptkr-field-group>
                        <ptkr-field-group
                                label='HP SDS Integration (Daemon Only)'
                                hint='This setting controls whether the HP SDS agent is installed and managed through Print Tracker'
                                i18n-label
                                i18n-hint>
                            <ptkr-inheritance-reset-button
                                    [settingName]='SettingsKeyMap.Sds.Enabled'
                                    [showDropdown]='showDropdown'
                                    (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <div class='mat-toggle-container' style='position: relative;'>
                                <mat-slide-toggle class='toggle-field-infix m-r-5'
                                                  [formControlName]='SettingsKeyMap.Sds.Enabled'>
                                    <ng-container i18n="@@enabled">Enabled</ng-container>
                                    <div class='slide-toggle-trigger-overlay' matRipple></div>
                                </mat-slide-toggle>
                            </div>
                        </ptkr-field-group>
                    </ng-container>
                </section>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Network discovery
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Determines where to scan for devices
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <ptkr-field-group
                        label='Max hops'
                        hint='When running the default automatic network scan, the Data Collection Agent will search
                              within this number of hops'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.DeviceDiscovery.AutoScan.Hops'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <mat-select
                                [formControlName]='SettingsKeyMap.DeviceDiscovery.AutoScan.Hops'>
                                <mat-option [value]='1'>1</mat-option>
                                <mat-option [value]='2'>2</mat-option>
                                <mat-option [value]='3'>3</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='IP addresses to scan'
                        hint='When the Data Collection Agent scans with default settings, it will scan these IP
                              addresses'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.DeviceDiscovery.IPAddress'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <ptkr-ip-address-input placeholder='e.g. 10.0.0.1, 10.0.0.*, 10.0.0.1-254, or 10.0.0.0/24'
                                               i18n-placeholder
                                               [formControlName]='SettingsKeyMap.DeviceDiscovery.IPAddress'
                                               validationType='include'>
                        </ptkr-ip-address-input>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='IP addresses to skip'
                        hint='When the Data Collection Agent scans with default settings, it will skip these IP addresses'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.DeviceDiscovery.ExcludeIPAddress'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <ptkr-ip-address-input placeholder='e.g. 10.0.0.1' i18n-placeholder
                                               [formControlName]='SettingsKeyMap.DeviceDiscovery.ExcludeIPAddress'
                                               validationType='exclude'>
                        </ptkr-ip-address-input>
                    </ptkr-field-group>

                    <!--Ping First-->
                    <ptkr-field-group
                            label='Ping devices first'
                            hint='Pings the device prior to attempting an SNMP connection. This can be helpful for some devices that may go to sleep.'
                            i18n-label
                            i18n-hint>
                        <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery.PingFirst'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <div class='mat-toggle-container' style='position: relative;'>
                            <mat-slide-toggle class='toggle-field-infix m-r-5'
                                              [formControlName]='SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery.PingFirst'>
                                <ng-container i18n="@@enabled">Enabled</ng-container>
                                <div class='slide-toggle-trigger-overlay' matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>

                    <!--Scan Workers-->
                    <ptkr-field-group
                            label='Scan workers'
                            hint='The number of workers to use when scanning devices. Increasing this number can speed up the scan process at the cost of increasing resource usage on the host.'
                            i18n-label
                            i18n-hint
                    >
                        <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery.Workers'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <input
                                    matInput
                                    type='number'
                                    min=50
                                    max=255
                                    [formControlName]='SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery.Workers'
                            />
                        </mat-form-field>
                    </ptkr-field-group>
                </section>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>Remote Technician</mat-panel-title>
                    <mat-panel-description i18n>Enable or disable Remote Technician</mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <ptkr-field-group
                        label='Remote Technician'
                        hint='Enabling this allows specific users remote connection to devices under this
                        install via a secure proxy. When disabled, no Remote Technician connections will
                        reach your network at all.'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.RemoteTechnician.Enabled'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <div class='mat-toggle-container' style='position: relative;'>
                            <mat-slide-toggle class='toggle-field-infix m-r-5' id='remote-technician-toggle'
                                              [formControlName]='SettingsKeyMap.RemoteTechnician.Enabled'>
                                <ng-container i18n="@@enable">Enable</ng-container>
                                <div class='slide-toggle-trigger-overlay' matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </ptkr-field-group>
                </section>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</ng-container>
<ng-template #noFormGroup>
    <h2 style='color: gray;' i18n>Loading...</h2>
</ng-template>
