export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday
}

export namespace DayOfWeek {
    export function toString(type: DayOfWeek): string {
        switch (type) {
            case DayOfWeek.Sunday:
                return 'Sunday';
            case DayOfWeek.Monday:
                return 'Monday';
            case DayOfWeek.Tuesday:
                return 'Tuesday';
            case DayOfWeek.Wednesday:
                return 'Wednesday';
            case DayOfWeek.Thursday:
                return 'Thursday';
            case DayOfWeek.Friday:
                return 'Friday';
            case DayOfWeek.Saturday:
                return 'Saturday';
            default:
                return '';
        }
    }

    export const asArray: DayOfWeek[] = [
        DayOfWeek.Sunday,
        DayOfWeek.Monday,
        DayOfWeek.Tuesday,
        DayOfWeek.Wednesday,
        DayOfWeek.Thursday,
        DayOfWeek.Friday,
        DayOfWeek.Saturday
    ];
}
