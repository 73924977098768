import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GlobalStore } from '@app/state/store';
import { timezoneNames } from '@libs/iso/core/constants';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'ptkr-timezone-picker',
    templateUrl: './timezone-picker.component.html',
    styleUrls: ['./timezone-picker.component.scss']
})
export class TimezonePickerComponent implements OnInit, AfterViewInit {
    @ViewChild('tzInput', { static: false }) private _tzInput: ElementRef;
    // If set, this text will display even if a default tz is picked
    @Input()
    public placeholder: string = null;

    // If set, tz will display when no chip is present.
    @Input()
    private defaultTimeZone: any = null;

    @Output()
    private timeZone: Subject<any> = new Subject<any>();

    public controller: FormGroup = new FormGroup({
        tz: new FormControl('')
    });
    public names: string[] = [];
    public locked: boolean = false;

    private _controlSub: Subscription;
    private _suggestions: Array<any> = timezoneNames.map((value: string): any => ({ name: value }));

    constructor(private _store: Store<GlobalStore>, private _cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        // Update entities when textbox value changes.
        this._controlSub = this.controller.controls['tz'].valueChanges
            .pipe(debounceTime(400))
            .subscribe(val => {
                if (!!val) {
                    this._suggestions = timezoneNames
                        .filter((value: string): boolean =>
                            value.toLowerCase().includes(val.toLowerCase())
                        )
                        .map((value: string): any => ({ name: value }));
                    this._cd.detectChanges();
                }
            });
    }

    ngAfterViewInit(): void {
        // emit default tz, to deal with that cornercase.
        if (this.defaultTimeZone) {
            this.timeZone.next(this.defaultTimeZone);
        }
    }

    ngOnDestroy(): void {
        this._controlSub.unsubscribe();
    }

    public onSuggestionSelected(tz: any): void {
        this.names = [tz.name];
        this.locked = true;
        this._tzInput.nativeElement.value = '';
        this.timeZone.next(tz);
    }

    public removeChip(): void {
        this.names = [];
        this.locked = false;
        this.timeZone.next(this.defaultTimeZone); // Which is null, if unspecified
    }
}
