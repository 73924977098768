import { MeterPath } from '../../types';

interface IMeterOffset {
    value: number;
}

export interface IBillingMeterPreference {
    displayName: string;
    preferences: Array<MeterPath>;
    offset?: IMeterOffset;
}

export class BillingMeterPreference implements IBillingMeterPreference {
    public displayName: string = '';
    public preferences: Array<MeterPath> = [];
    public offset?: IMeterOffset;

    constructor(params?: Partial<IBillingMeterPreference>) {
        if (!!params) {
            this.displayName = params.displayName ? params.displayName : this.displayName;
            this.preferences = params.preferences ? params.preferences : this.preferences;
            if (!!params.offset) {
                this.offset = params.offset;
            }
        }
    }
}
