<div *ngIf="breadcrumb.length > 0">
    <ng-container *ngFor="let e of breadcrumb; let i = index">
        <span
            class="parent"
            [class.clickable]="_clickable(i)"
            (click)="_clickable(i) ? select.emit(e) : null"
            >{{ displayFn(e) }}</span
        >
        <ng-container *ngIf="_clickable(i)">
            &nbsp;/&nbsp;
        </ng-container>
    </ng-container>
</div>
