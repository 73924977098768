import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DeviceService, EntityService, InstallService, AlertService } from '@app/services';
import { catchError, take, map } from 'rxjs/operators';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { TableDataList } from '@app/core/models/TableDataList';
import { Device, Entity, Install, Event, MeterRead, HistoricalMeterRead } from '@libs/iso/core';
import {
    SuperTableParams,
    superTableParamDefaults,
    parseColumns,
    SuperTableCollection
} from '@app/core/models/SuperTableParams';

@Injectable()
export class SuperTableResolver
    implements Resolve<{ tableDataList: TableDataList; params: SuperTableParams }> {
    constructor(
        private _deviceService: DeviceService,
        private _store: Store<GlobalStore>,
        private _entityService: EntityService,
        private _installService: InstallService,
        private _alertService: AlertService
    ) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<{ tableDataList: TableDataList; params: SuperTableParams }> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let entityId: string = '';
        this._store.pipe(select(fromEntity.entityId), take(1)).subscribe(id => (entityId = id));
        const initQParams = route.queryParams;
        const params: Partial<SuperTableParams> = {
            searchTerm: initQParams.searchTerm || superTableParamDefaults.searchTerm,
            sort: initQParams.sort || superTableParamDefaults.sort,
            sortOrder: initQParams.sortOrder || superTableParamDefaults.sortOrder,
            page: coerceNumberProperty(initQParams.page) || superTableParamDefaults.page,
            limit:
                coerceNumberProperty(initQParams.limit, superTableParamDefaults.limit) ||
                superTableParamDefaults.limit,
            includeChildren:
                coerceBooleanProperty(initQParams.includeChildren) ||
                superTableParamDefaults.includeChildren,
            columns: parseColumns(initQParams.columns),
            collection:
                route.data.collection || // overrides params if possible
                initQParams.collection ||
                superTableParamDefaults.collection,
            startDate: initQParams.startDate || superTableParamDefaults.startDate,
            endDate: !!initQParams.endDate
                ? this._correctEndDate(initQParams.endDate).toISOString()
                : superTableParamDefaults.endDate,
            isBillingReport: initQParams.isBillingReport || superTableParamDefaults.isBillingReport,
            hideBilled: initQParams.hideBilled || superTableParamDefaults.hideBilled
        };

        switch (params.collection) {
            case 'device':
                return this._getData(entityId, params, this._deviceService, Device);
            case 'entity':
                return this._getData(entityId, params, this._entityService, Entity);
            case 'install':
                return this._getData(entityId, params, this._installService, Install);
            case 'alert':
                return this._getData(entityId, params, this._alertService, Event);
            case 'meterRead':
                return this._getMeterReadData(entityId, params);
            case 'meterViewer':
                return this._getMeterViewerData(entityId, params);
            default: {
                return of({ tableDataList: new TableDataList(), params: superTableParamDefaults });
            }
        }
    }

    private _defaultStartDate(): string {
        return new Date(
            new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
            ).valueOf() -
                1000 * 60 * 60 * 24 * 30
        ).toISOString();
    }

    private _defaultEndDate(): string {
        return new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            23,
            59,
            59,
            999
        ).toISOString(); // Note that this sets it to universal time so prompt will show '06:59:59.999'; we want this.
    }

    /**
     * Sets time on end-date to 23:59:59:999
     * @param {Date} endDate: endDate from user
     * @returns {Date} corrected date
     */
    private _correctEndDate(endDate: Date | string): Date {
        const date: Date = new Date(endDate);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    }

    private _getData(
        entityId: string,
        params: Partial<SuperTableParams>,
        service: any,
        model: any
    ): any {
        const finalParams: SuperTableParams = {
            collection: SuperTableCollection.meterViewer,
            startDate: params.startDate || this._defaultStartDate(),
            endDate: params.endDate || this._defaultEndDate(),
            searchTerm: params.searchTerm,
            page: params.page || superTableParamDefaults.page,
            limit: params.limit || superTableParamDefaults.limit,
            sort: params.sort,
            sortOrder: params.sortOrder,
            includeChildren: params.includeChildren || superTableParamDefaults.includeChildren,
            columns: params.columns || [0, 2]
        };
        return service
            .getNewList(
                entityId,
                finalParams.searchTerm,
                finalParams.page,
                finalParams.limit,
                finalParams.sort,
                finalParams.sortOrder as number,
                finalParams.includeChildren,
                finalParams.columns
            )
            .pipe(
                catchError(err => {
                    console.error('caught error', err);
                    return of(new TableDataList());
                }),
                map(res => ({
                    tableDataList: new TableDataList({
                        ...(res as object),
                        model: model,
                        columns: finalParams.columns
                    }),
                    params: finalParams
                }))
            );
    }

    private _getMeterReadData(
        entityId: string,
        params: Partial<SuperTableParams>,
        service: any = this._entityService,
        model: any = MeterRead
    ): any {
        // this.testPrintColumnNames(params.columns);
        return service
            .getMeterReadList(
                entityId,
                params.searchTerm,
                params.page,
                params.limit,
                params.sort,
                params.sortOrder as number,
                params.includeChildren,
                params.columns || superTableParamDefaults.columns
            )
            .pipe(
                catchError(err => {
                    console.error('caught error', err);
                    return of(new TableDataList());
                }),
                map(
                    res =>
                        new TableDataList({
                            ...(res as object),
                            model: model,
                            columns: params.columns
                        })
                )
            );
    }

    private _getMeterViewerData(entityId: string, params: Partial<SuperTableParams>): any {
        const defaultCols = [2, 8, 5, 3, 294, 295, 296, 487, 488, 489, 720];
        const finalParams: SuperTableParams = {
            collection: SuperTableCollection.meterViewer,
            startDate: params.startDate || this._defaultStartDate(),
            endDate: params.endDate || this._defaultEndDate(),
            searchTerm: params.searchTerm,
            page: params.page || superTableParamDefaults.page,
            limit: params.limit || superTableParamDefaults.limit,
            sort: params.sort,
            sortOrder: params.sortOrder,
            includeChildren: params.includeChildren || superTableParamDefaults.includeChildren,
            // Spencer 12/3/20 - Below is a great example of why it would be a good idea to replace column numbers with a name based mechanism.
            // Not only is this hard to read, it also will need adjusting every time we add / change columns.
            // Fortunately you can generate a list like this by selecting columns in the app and copying what it places in the resulting url.
            // So at least it works in the meantime.
            columns:
                params.columns || (params.isBillingReport ? [523, ...defaultCols] : defaultCols),
            isBillingReport: params.isBillingReport || superTableParamDefaults.isBillingReport,
            hideBilled: params.hideBilled || superTableParamDefaults.hideBilled
        };
        return this._entityService
            .getMeterViewerList(
                entityId,
                finalParams.startDate,
                finalParams.endDate,
                finalParams.searchTerm,
                finalParams.page,
                finalParams.limit,
                finalParams.sort,
                finalParams.sortOrder as number,
                finalParams.includeChildren,
                finalParams.columns,
                finalParams.isBillingReport,
                finalParams.hideBilled
            )
            .pipe(
                catchError(err => {
                    console.error('caught error', err);
                    return of(new TableDataList());
                }),
                map(res => ({
                    tableDataList: new TableDataList({
                        ...(res as object),
                        model: HistoricalMeterRead,
                        columns: finalParams.columns
                    }),
                    params: finalParams
                }))
            );
    }

    // public testPrintColumnNames(A: Array<number>): void {
    //     console.log(A.map(e => MeterRead.columns[e].key));
    // }
}
