import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ptkr-dgi-apply-and-test-confirmation',
    templateUrl: './dgi-apply-and-test-confirmation.component.html',
    styleUrls: ['./dgi-apply-and-test-confirmation.component.css']
})
export class DGIApplyAndTestConfirmationComponent implements OnInit {
    public useTempMeterRead: boolean = true;
    public uploadTestbox: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DGIApplyAndTestConfirmationComponent>,
    ) {}

    ngOnInit(): void {}
}
