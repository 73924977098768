import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromAuth, fromEntity, fromUser } from '@app/state/selectors';
import { EntityService, RouterService } from '@app/services';
import { datadogRum } from '@datadog/browser-rum';
import { take } from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivate {
    private _authenticated: boolean = false;

    constructor(
        private _store: Store<GlobalStore>,
        private _router: Router,
        private _routerService: RouterService,
        private _entityService: EntityService,
    ) {
        this._store.pipe(select(fromAuth.isAuthenticated)).subscribe(authenticated => {
            this._authenticated = authenticated;
        });

        // Datadog troubleshooting information
        // this._store.pipe(select(fromUser.currentUser), take(1)).subscribe(user => {
        //     this._entityService.getEntityDetails(user.entity.entityKey).pipe(take(1)).subscribe(entity => {
        //         datadogRum.setUser({
        //             id: user.id,
        //             name: `${user.firstName} ${user.lastName}`,
        //             email: user.email,
        //             entity: entity.name,
        //             entityKey: entity._id,
        //             evaluation: entity.expiresOn != null
        //         });
        //     });
        // });
    }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this._authenticated) {
            this._routerService.setLoginRedirect(state.url);
            this._router.navigate(['/auth/login']);
            return false;
        }
        return true;
    }
}
