<div class="container">
    <h2 i18n="@@welcome">Welcome</h2>
    <p i18n>
        We're excited to have you with us at Print Tracker. This wizard will walk you through
        getting set up for the first time. If you'd like to quit this setup process, click the close
        button below
    </p>

    <section>
        <mat-vertical-stepper [linear]="true" #stepper>
            <mat-step [stepControl]="">
                <ng-template matStepLabel><span i18n>Step 1: Download</span></ng-template>
                <p i18n>
                    In order for Print Tracker to collect data from the devices on your network, you'll need to
                    install a data collection agent on your computer.
                </p>
                <button mat-flat-button color="primary" (click)="downloadNow(); stepper.next()" i18n="@@download">
                    Download
                </button>
            </mat-step>
            <mat-step [stepControl]="">
                <ng-template matStepLabel><span i18n>Step 2: Install</span></ng-template>
                <p i18n>
                    Now that you've downloaded the Print Tracker data collection agent, make sure
                    that you've installed it.
                </p>
                <ptkr-download-installer-image></ptkr-download-installer-image>
                <div class="m-t-10">
                    <button mat-flat-button color="primary" (click)="stepper.next()" i18n>
                        I have installed Print Tracker
                    </button>
                </div>
            </mat-step>

            <mat-step [stepControl]="">
                <ng-template matStepLabel><span i18n>Step 3: Register</span></ng-template>
                <p i18n>
                    Once you've installed Print Tracker, a registration screen should have popped up. <a target="_blank" href="http://localhost:1301/">Click here</a> if a registration screen did not pop up. Copy and paste this datalink code into the registration page.
                </p>
                <div class="datalink-code-container-2">{{(entity$ | async)._id}}</div>
                <img src="assets/download-installer-register.png" width="100%"/>
                <div class="m-t-10">
                    <button mat-flat-button color="primary" (click)="stepper.next()" i18n>
                        I have registered Print Tracker
                    </button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel><span i18n>Step 4: Scan for devices</span></ng-template>
                <div class="kv-list m-t-10">
                    <div class="kv-list-item-group">
                        <div class="kv-list-item">
                            <div class="kv-list-key" i18n>Installation</div>
                            <div class="kv-list-values no-padding-top-bottom">
                                <ptkr-btn-preloader
                                    class="loader"
                                    *ngIf="!this.install"
                                ></ptkr-btn-preloader>
                                <mat-icon class="online pull-left" *ngIf="this.install"
                                    >check_circle</mat-icon
                                >
                            </div>
                        </div>
                        <div class="kv-list-item">
                            <div class="kv-list-key" i18n>Started scan</div>
                            <div class="kv-list-values no-padding-top-bottom">
                                <ptkr-btn-preloader
                                    class="loader"
                                    *ngIf="!this.initJob"
                                ></ptkr-btn-preloader>
                                <mat-icon class="online pull-left" *ngIf="this.initJob"
                                    >check_circle</mat-icon
                                >
                            </div>
                        </div>
                        <div class="kv-list-item">
                            <div class="kv-list-key" i18n>Finished scan</div>
                            <div class="kv-list-values no-padding-top-bottom">
                                <ptkr-btn-preloader
                                    class="loader"
                                    *ngIf="
                                        !this.initJob ||
                                        (this?.initJob?.jobStatus?.type !==
                                            jobStatusTypes.Completed &&
                                            this?.initJob?.jobStatus?.type !==
                                                jobStatusTypes.Failed)
                                    "
                                ></ptkr-btn-preloader>
                                <mat-icon
                                    class="online pull-left"
                                    *ngIf="
                                        this?.initJob?.jobStatus?.type ===
                                            jobStatusTypes.Completed ||
                                        this?.initJob?.jobStatus?.type === jobStatusTypes.Failed
                                    "
                                    >check_circle</mat-icon
                                >
                            </div>
                        </div>
                        <div class="kv-list-item">
                            <div class="kv-list-key" i18n>Discovered devices</div>
                            <div class="kv-list-values no-padding-top-bottom">
                                <ptkr-btn-preloader
                                    class="loader"
                                    *ngIf="
                                        this?.initJob?.jobStatus?.type !==
                                            jobStatusTypes.Completed &&
                                        this?.initJob?.jobStatus?.type !== jobStatusTypes.Failed &&
                                        this.discoveredDevices.length <= 0
                                    "
                                ></ptkr-btn-preloader>
                                <mat-icon
                                    class="online pull-left"
                                    *ngIf="this.discoveredDevices.length > 0"
                                    >check_circle</mat-icon
                                >
                                <mat-icon
                                    class="offline pull-left"
                                    *ngIf="
                                        (this?.initJob?.jobStatus?.type ===
                                            jobStatusTypes.Completed ||
                                            this?.initJob?.jobStatus?.type ===
                                                jobStatusTypes.Failed) &&
                                        this.discoveredDevices.length <= 0
                                    "
                                    >error</mat-icon
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <button class="m-t-10" mat-flat-button (click)="openDocumentation()" color="primary" i18n>
                    Need help? View documentation
                </button>

                <table mat-table class="g-table m-t-10" [dataSource]="dataSource">
                    <ng-container matColumnDef="model">
                        <th mat-header-cell *matHeaderCellDef i18n="@@device">Device</th>
                        <td mat-cell *matCellDef="let device">{{ device.model }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ipAddr">
                        <th mat-header-cell *matHeaderCellDef i18n="@@ipaddress">
                            IP address
                        </th>
                        <td mat-cell *matCellDef="let device">{{ device.ipAddr }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row class="hover" *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <!--                <div>-->
                <!--                    <button mat-button matStepperPrevious>Back</button>-->
                <!--                    <button mat-button (click)="stepper.reset()">Reset</button>-->
                <!--                </div>-->
            </mat-step>
        </mat-vertical-stepper>
    </section>
</div>
