<h1>DataLink code</h1>

<p>
    After you install the DCA, you will be asked for a DataLink code. Use the
    code below to have your data linked to <b>{{entityName}}</b>.
</p>

<h2> {{entityKey}} </h2>

<div class="action-buttons">
    <button [disabled]="buttonDisabled" mat-flat-button color="accent" (click)="close()">Done</button>
</div>
