export enum TimeSeriesValueType {
    String = '[TimeSeriesValueType] String',
    Decimal = '[TimeSeriesValueType] Decimal',
    Bool = '[TimeSeriesValueType] Bool',
    Integer = '[TimeSeriesValueType] Integer',
    Date = '[TimeSeriesValueType] Date'
}

export namespace TimeSeriesValueType {
    export function toMongoConversionFunction(type: TimeSeriesValueType): string | null {
        switch (type) {
            case TimeSeriesValueType.String:
                return '$toString';
            case TimeSeriesValueType.Decimal:
                return '$toDecimal';
            case TimeSeriesValueType.Bool:
                return '$toBool';
            case TimeSeriesValueType.Integer:
                return '$toInt';
            case TimeSeriesValueType.Date:
                return '$toDate';
            default:
                return null;
        }
    }
}
