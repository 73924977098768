<div mat-dialog-content>
    <ptkr-field-group [label]="data.placeholder">
        <mat-form-field floatLabel="never" appearance="outline">
            <input matInput [type]="data.type" [formControl]="inputControl">
            <mat-error [ptkrErrorControl]="inputControl"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
</div>
<div mat-dialog-actions align="center">
    <button mat-flat-button color="default" (click)="dialogRef.close(undefined)">Cancel</button>
    <button mat-flat-button color="primary" (click)="confirmData(inputControl)">Confirm</button>
</div>
