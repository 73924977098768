import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BulkResetModalConfig } from '@libs/web/forms';

@Component({
    selector: 'ptkr-bulk-reset-modal',
    templateUrl: './bulk-reset-modal.component.html',
    styleUrls: [ './bulk-reset-modal.component.scss' ]
})
export class BulkResetModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<BulkResetModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BulkResetModalConfig
    ) {}

    /**
     * API calls to figure out how many documents we will be updating.
     * @return {void} nothing
     */
    ngOnInit(): void {

    }

    /**
     * Cancel condition: We close and do nothing.
     * @return {void} nothing
     */
    public cancel(): void {
        this.dialogRef.close(false);
    }

    /**
     * Confirmation condition: We close and run the API request.
     * @return {void} nothing
     */
    public confirm(): void {
        this.dialogRef.close(true);
    }
}

