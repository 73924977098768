import { PageCountType } from '@libs/iso/core/enums/PageCountType';

class CanonNumberedMeter {
    constructor(
        public canonNumberedMeter: number,
        public format: PageCountType,
        public meter: string
    ) {}
}

export class CanonNumberedMeterMapping {
    public mappings: CanonNumberedMeter[] = [
        new CanonNumberedMeter(101, PageCountType.life, 'total'),
        new CanonNumberedMeter(102, PageCountType.equiv, 'total'),
        new CanonNumberedMeter(105, PageCountType.life, 'tier3Total'),
        new CanonNumberedMeter(106, PageCountType.equiv, 'tier3Total'),
        new CanonNumberedMeter(108, PageCountType.life, 'totalBlack'),
        new CanonNumberedMeter(109, PageCountType.equiv, 'totalBlack'),
        new CanonNumberedMeter(118, PageCountType.life, 'tier1Total'),
        new CanonNumberedMeter(119, PageCountType.equiv, 'tier1Total'),
        new CanonNumberedMeter(124, PageCountType.equiv, 'totalColor'),
        new CanonNumberedMeter(125, PageCountType.life, 'totalColor'),
        new CanonNumberedMeter(201, PageCountType.life, 'totalCopies'),
        new CanonNumberedMeter(202, PageCountType.equiv, 'totalCopies'),
        new CanonNumberedMeter(217, PageCountType.life, 'tier3Copies'),
        new CanonNumberedMeter(218, PageCountType.equiv, 'tier3Copies'),
        new CanonNumberedMeter(219, PageCountType.life, 'tier1Copies'),
        new CanonNumberedMeter(220, PageCountType.equiv, 'tier1Copies'),
        new CanonNumberedMeter(221, PageCountType.life, 'totalCopiesBlack'),
        new CanonNumberedMeter(222, PageCountType.equiv, 'totalCopiesBlack'),
        new CanonNumberedMeter(231, PageCountType.equiv, 'totalCopiesColor'),
        new CanonNumberedMeter(232, PageCountType.life, 'totalCopiesColor'),
        new CanonNumberedMeter(301, PageCountType.life, 'totalPrints'),
        new CanonNumberedMeter(302, PageCountType.equiv, 'totalPrints'),
        new CanonNumberedMeter(309, PageCountType.life, 'tier3Prints'),
        new CanonNumberedMeter(310, PageCountType.equiv, 'tier3Prints'),
        new CanonNumberedMeter(311, PageCountType.life, 'tier1Prints'),
        new CanonNumberedMeter(312, PageCountType.equiv, 'tier1Prints'),
        new CanonNumberedMeter(313, PageCountType.life, 'totalPrintsBlack'),
        new CanonNumberedMeter(314, PageCountType.equiv, 'totalPrintsBlack'),
        new CanonNumberedMeter(323, PageCountType.equiv, 'totalPrintsColor'),
        new CanonNumberedMeter(324, PageCountType.life, 'totalPrintsColor'),
        new CanonNumberedMeter(501, PageCountType.life, 'totalScans'),
        new CanonNumberedMeter(502, PageCountType.equiv, 'totalScans'),
        new CanonNumberedMeter(701, PageCountType.life, 'totalFaxes'),
        new CanonNumberedMeter(702, PageCountType.equiv, 'totalFaxes'),
        new CanonNumberedMeter(705, PageCountType.life, 'tier3Faxes'),
        new CanonNumberedMeter(706, PageCountType.equiv, 'tier3Faxes'),
        new CanonNumberedMeter(709, PageCountType.life, 'totalFaxesBlack'),
        new CanonNumberedMeter(710, PageCountType.equiv, 'totalFaxesBlack')
    ];

    public getCanonMetersForFormats(formats: PageCountType[], meter: string): number[] | null {
        const mappings = this.mappings
            .filter(m => m.meter === meter)
            .filter(m => formats.indexOf(m.format) >= 0)
            .sort((a, b) => (formats.indexOf(a.format) < formats.indexOf(b.format) ? -1 : 1))
            .map(a => a.canonNumberedMeter);
        if (mappings.length === 0) {
            return null;
        }
        return mappings;
    }
}
