import {
    SettingsTimeFrame,
    SNMPAlgorithm,
    SNMPPrivacyProtocol,
    UpdateKeyMap,
    UpdateKeyType
} from '@libs/iso/core/enums';
import {
    BillingPreferencesCountersUpdate,
    BooleanSettingsUpdate,
    DeviceSkipAlertUpdate,
    EmailArraySettingsUpdate,
    EntityDynamicDeviceGroupsUpdate,
    FloatSettingsUpdate,
    IntegerArraySettingsUpdate,
    IntegerSettingsUpdate,
    InvalidSettingsUpdate,
    ISODateSettingsUpdate,
    ObjectIdSettingsUpdate,
    SettingUpdate,
    StringArraySettingsUpdate,
    StringEnumSettingsUpdate,
    StringSettingsUpdate
} from './update-handlers';

export class SettingsUpdateFactory {
    /**
     * Generates SettingsUpdateType objects
     * @param {Object} updates to validate
     * @returns {Array<SettingUpdate>} array of SettingsUpdateType objects
     */
    public static validateUpdates(
        updates: Partial<{ [S in UpdateKeyType]: any }>
    ): Array<SettingUpdate> {
        const validatedUpdates: Array<SettingUpdate> = [];
        for (const uKey in updates) {
            if (updates.hasOwnProperty(uKey)) {
                const update = SettingsUpdateFactory._validateUpdate(
                    uKey as UpdateKeyType,
                    updates[uKey]
                );
                validatedUpdates.push(update);
            }
        }
        return validatedUpdates;
    }

    private static _validateUpdate(key: UpdateKeyType, value: any): SettingUpdate {
        switch (key) {
            case UpdateKeyMap.Entity.IsDealer:
            case UpdateKeyMap.Settings.DeviceDiscovery.AutoScan.Use:
            case UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.Use:
            case UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.PingFirst:
            case UpdateKeyMap.Settings.Alerts.Toner.PrematureToggle:
            case UpdateKeyMap.Settings.Alerts.Toner.ReplacementToggle:
            case UpdateKeyMap.Settings.Alerts.Toner.AlertToggle:
            case UpdateKeyMap.Settings.Alerts.Toner.SuspectedAlertToggle:
            case UpdateKeyMap.Settings.Alerts.Toner.SeparateColorThreshold:
            case UpdateKeyMap.Settings.Alerts.Service.Type.NotReachable:
            case UpdateKeyMap.Settings.Alerts.Service.Type.DoorOpen:
            case UpdateKeyMap.Settings.Alerts.Service.Type.NoToner:
            case UpdateKeyMap.Settings.Alerts.Service.Type.SupplyMissing:
            case UpdateKeyMap.Settings.Alerts.Service.Type.ServiceRequested:
            case UpdateKeyMap.Settings.Alerts.Service.Type.OutOfPaper:
            case UpdateKeyMap.Settings.Alerts.Service.Type.MaintKit:
            case UpdateKeyMap.Settings.Alerts.Service.Misfeed.Enabled:
            case UpdateKeyMap.Settings.Alerts.Device.New.Enabled:
            case UpdateKeyMap.Settings.Alerts.Device.Offline.Enabled:
            case UpdateKeyMap.Settings.Alerts.Volume.Enabled:
            case UpdateKeyMap.Settings.Alerts.Volume.Recurring:
            case UpdateKeyMap.Settings.Alerts.UploadMetersOnAlert:
            case UpdateKeyMap.Settings.Rts.Enabled:
            case UpdateKeyMap.Settings.RemoteTechnician.Enabled:
            case UpdateKeyMap.Settings.DataGathering.TrackingStatus.Enabled:
            case UpdateKeyMap.Settings.Upgrade.Auto.Disabled:
            case UpdateKeyMap.Settings.BillingPreferences.Schedule.Enabled:
            case UpdateKeyMap.Settings.AutoFulfillment.SuppliesNetwork.Enabled:
            case UpdateKeyMap.Settings.Alerts.Install.New.Enabled:
            case UpdateKeyMap.Settings.Alerts.Install.Stale.Enabled:
            case UpdateKeyMap.Settings.Alerts.Install.StaleBackOnline.Enabled:
            case UpdateKeyMap.Settings.Distributor.RejectMetersFromInactiveEntities:
            case UpdateKeyMap.Settings.Integrations.EAutomate.SalesQuote.Enabled:
            case UpdateKeyMap.Settings.Sds.Enabled:
                return new BooleanSettingsUpdate(key, value);
            case UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.Retry:
            case UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.Timeout:
                return new FloatSettingsUpdate(key, value);
            case UpdateKeyMap.Settings.DeviceDiscovery.AutoScanForNewDevices.DaysOfWeek:
                return new IntegerArraySettingsUpdate(key, value, { min: 0, max: 6 });
            case UpdateKeyMap.Settings.MeterFlags.TesseractVerbosity:
                return new IntegerSettingsUpdate(key, value, { min: 1, max: 10 });
            case UpdateKeyMap.Settings.DataGathering.Concurrency:
                return new IntegerSettingsUpdate(key, value, { min: 1, max: 50 });
            case UpdateKeyMap.Settings.Alerts.Install.StaleBackOnline.StaleDays:
                return new IntegerSettingsUpdate(key, value, { min: 2 });
            case UpdateKeyMap.Settings.Alerts.Install.Stale.StaleDays:
                return new IntegerSettingsUpdate(key, value, { min: 1 });
            case UpdateKeyMap.Settings.Alerts.Toner.CheckFrequencyMinutes:
                return new IntegerSettingsUpdate(key, value, { min: 60, max: 10080 /* 1 week */ });
            case UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.Workers:
                return new IntegerSettingsUpdate(key, value, { min: 10, max: 255 });
            case UpdateKeyMap.Settings.DeviceDiscovery.AutoScan.Hops:
            case UpdateKeyMap.Settings.Alerts.Toner.DaysToDepletion:
            case UpdateKeyMap.Settings.Alerts.Toner.PctRemaining:
            case UpdateKeyMap.Settings.Alerts.Toner.ColorPctRemaining:
            case UpdateKeyMap.Settings.Alerts.Service.MaintKitPctRemaining:
            case UpdateKeyMap.Settings.Alerts.Service.CheckFrequencyMinutes:
            case UpdateKeyMap.Settings.Alerts.Volume.Beginning:
            case UpdateKeyMap.Settings.Alerts.Volume.Frequency:
            case UpdateKeyMap.Settings.Supplies.Consolidation.WithinDaysRemaining:
            case UpdateKeyMap.Settings.Supplies.Consolidation.WithinPercentRemaining:
                return new IntegerSettingsUpdate(key, value);
            case UpdateKeyMap.Settings.DeviceDiscovery.IPAddress:
            case UpdateKeyMap.Settings.DeviceDiscovery.ExcludeIPAddress:
            case UpdateKeyMap.Settings.FeatureFlags:
            case UpdateKeyMap.Settings.SNMP.Communities:
                return new StringArraySettingsUpdate(key, value);
            case UpdateKeyMap.Entity.Notes:
            case UpdateKeyMap.Settings.PageCountType:
            case UpdateKeyMap.Settings.Alerts.Service.Text.ServiceProvider:
            case UpdateKeyMap.Settings.Alerts.Service.Text.Alert:
            case UpdateKeyMap.Settings.MeterFlags.FlagArray:
            case UpdateKeyMap.Settings.DataGathering.Schedules.Meter:
            case UpdateKeyMap.Settings.Upgrade.Auto.Schedule:
            case UpdateKeyMap.Settings.BillingPreferences.Schedule.Expression:
            case UpdateKeyMap.Device.AssetId:
            case UpdateKeyMap.Device.Note:
            case UpdateKeyMap.Device.Location:
            case UpdateKeyMap.Device.CustomLocation:
            case UpdateKeyMap.Settings.BillingPreferences.Schedule.Timezone:
            case UpdateKeyMap.Settings.DataGathering.EmbeddedWebpage.Credentials.Username:
            case UpdateKeyMap.Settings.DataGathering.EmbeddedWebpage.Credentials.Password:
                return new StringSettingsUpdate(key, value);
            case UpdateKeyMap.Device.CustomSerialNum: // Special validator that allows nulls through
                return new StringSettingsUpdate(key, value, {
                    validatorFn: (val: any): boolean => val === null || typeof val === 'string'
                });
            case UpdateKeyMap.Settings.Alerts.Toner.Emails.Premature:
            case UpdateKeyMap.Settings.Alerts.Toner.Emails.Replacement:
            case UpdateKeyMap.Settings.Alerts.Toner.Emails.Alert:
            case UpdateKeyMap.Settings.Alerts.Service.Emails.ServiceProvider:
            case UpdateKeyMap.Settings.Alerts.Service.Emails.Alert:
            case UpdateKeyMap.Settings.Alerts.Device.New.Emails:
            case UpdateKeyMap.Settings.Alerts.Device.Offline.Emails:
            case UpdateKeyMap.Settings.Alerts.Volume.Emails:
            case UpdateKeyMap.Settings.Alerts.Install.New.Emails:
            case UpdateKeyMap.Settings.Alerts.Install.Stale.Emails:
            case UpdateKeyMap.Settings.Alerts.Install.StaleBackOnline.Emails:
                return new EmailArraySettingsUpdate(key, value);
            // Enums
            case UpdateKeyMap.Settings.UploadTime.DayPart: // Enum
                return new StringEnumSettingsUpdate(key, value, {
                    values: SettingsTimeFrame.timesAsArray
                });
            case UpdateKeyMap.Settings.UploadTime.TimeOfDay: // Date
                return new ISODateSettingsUpdate(key, value);
            case UpdateKeyMap.Settings.SNMP.Community:
            case UpdateKeyMap.Settings.SNMP.V3Username:
            case UpdateKeyMap.Settings.SNMP.V3AuthPassword:
            case UpdateKeyMap.Settings.SNMP.V3PrivacyPassword:
            case UpdateKeyMap.Settings.SNMP.V3Context:
            case UpdateKeyMap.Settings.GeneralInformation.OnsiteContact.Email:
            case UpdateKeyMap.Settings.GeneralInformation.OnsiteContact.Phone:
            case UpdateKeyMap.Settings.GeneralInformation.OnsiteContact.Name:
                return new StringSettingsUpdate(key, value);
            case UpdateKeyMap.Settings.SNMP.V3Algorithm:
                return new StringEnumSettingsUpdate(key, value, {
                    values: SNMPAlgorithm.asArray
                });
            case UpdateKeyMap.Settings.SNMP.V3PrivacyProtocol:
                return new StringEnumSettingsUpdate(key, value, {
                    values: SNMPPrivacyProtocol.asArray
                });
            case UpdateKeyMap.Settings.SNMP.Version:
                return new IntegerSettingsUpdate(key, value);
            case UpdateKeyMap.Settings.DataGathering.Dgi.Key:
                return new ObjectIdSettingsUpdate(key, value);
            case UpdateKeyMap.Settings.BillingPreferences.Counters:
                return new BillingPreferencesCountersUpdate(key, value);
            case UpdateKeyMap.Device.SkipAlerts:
                return new DeviceSkipAlertUpdate(key, value);
            case UpdateKeyMap.Entity.ExpiresOn:
                return new ISODateSettingsUpdate(key, value, {}, true);
            case UpdateKeyMap.Entity.DynamicDeviceGroups:
                return new EntityDynamicDeviceGroupsUpdate(key, value);
            default:
                return new InvalidSettingsUpdate(key, value);
        }
    }
}
