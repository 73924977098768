import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Causes circular depencies if imported with @services
import { NotificationService } from '@app/core/services/notification.service';
import { InvoiceService } from '@app/core/services/invoice.service';
import { Invoice } from '@libs/iso/core/models';

@Component({
    selector: 'ptkr-view-invoice',
    templateUrl: './view-invoice.component.html',
    styleUrls: ['./view-invoice.component.scss']
})
export class ViewInvoiceComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ViewInvoiceComponent>,
        private _invoiceService: InvoiceService,
        private _notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: Invoice
    ) {}

    ngOnInit(): void {}

    public onPrint($event): void {
        if ($event.invoiceNumber) {
            this._invoiceService.getInvoiceSignature($event.invoiceNumber).subscribe(
                res => {
                    const url = '/invoice/' + $event.invoiceNumber + '/print?sig=' + res;
                    window.open(url, '_blank');
                },
                error => {
                    this._notificationService.error(error.statusText);
                }
            );
        } else {
            this._invoiceService.getSalesOrderSignature($event.salesOrderNumber).subscribe(
                res => {
                    const url =
                        '/invoice/' + $event.salesOrderNumber + '/print?type=salesOrder&sig=' + res;
                    window.open(url, '_blank');
                },
                error => {
                    this._notificationService.error(error.statusText);
                }
            );
        }
    }
    public onEmail($event): void {
        this._invoiceService.sendInvoiceEmail($event.invoiceNumber).subscribe(
            res => {
                this._notificationService.success('Invoice Resent');
            },
            error => {
                this._notificationService.error(error.statusText);
            }
        );
    }
}
