// tslint:disable:no-magic-numbers
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ViewChild,
    Inject,
    ChangeDetectorRef
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { StepComponent } from '@app/shared/overlays/StepComponent';
import { EntityTree, EntityTreeNode, ItemWithEntityPricing } from '@libs/iso/core';
import { Observable, forkJoin, of } from 'rxjs';
import { BulkEntityItemsConfig, WindowSize } from '@app/models';
import { EntityService } from '@app/core/services/entity.service';
import { NotificationService } from '@app/core/services/notification.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { delay } from 'rxjs/operators';
import { ItemService } from '@app/core/services/item.service';
import { WindowSizeService } from '@app/core/services/window-size.service';

interface Step {
    label: string;
    isComplete: boolean;
    nextText: string;
    cancelText: string;
    next: () => void;
    cancel: () => void;
    complete: () => void;
}

@Component({
    selector: 'ptkr-bulk-entity-items',
    templateUrl: './bulk-entity-items.component.html',
    styleUrls: ['./bulk-entity-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkEntityItemsComponent implements OnInit {
    @ViewChild('stepper', {static: true})
    public stepper: MatStepper;
    @ViewChild('itemsStep', {static: true})
    public itemsStep: StepComponent;
    @ViewChild('treeStep', {static: true})
    public treeStep: StepComponent;
    @ViewChild('confirmStep', {static: true})
    public confirmStep: StepComponent;

    public currentStep: number = 0;
    public steps: Step[] = [
        this._createStep(0, {
            label: 'Select Settings',
            nextText: 'Next',
            cancelText: 'Cancel',
            cancel: (): any => this.dialogRef.close(),
            next: (): any => {
                const valid = this.itemsStep.validate();
                if (valid === true) {
                    this.steps[0].isComplete = true;
                } else if (valid === null) {
                    this._notificationService.error('At least one selection is required!');
                    this.steps[0].isComplete = false;
                } else {
                    this._notificationService.error('Error with the form data.');
                    this.steps[0].isComplete = false;
                }
                if (this.steps[0].isComplete) {
                    this._nextStep();
                }
            }
        }),
        this._createStep(1, {
            label: 'Select Entities',
            nextText: 'Next',
            cancelText: 'Back',
            next: (): any => {
                if (this.treeStep.validate()) {
                    this.steps[1].isComplete = true;
                } else {
                    this._notificationService.error('At least one entity must be selected!');
                    this.steps[1].isComplete = false;
                }
                if (this.steps[1].isComplete === true) {
                    this._nextStep();
                }
            }
        }),
        this._createStep(2, {
            label: 'Confirm',
            nextText: 'Apply Changes',
            cancelText: 'Back',
            next: (): any => {
                this.steps[2].isComplete = true;
                this._nextStep();
                this._applyChanges();
            }
        }),
        this._createStep(3, {
            label: 'Apply Changes',
            next: (): any => {
                this.steps[3].isComplete = true;
                this._nextStep();
            }
        }),
        this._createStep(4, {
            label: 'Done',
            nextText: 'Close',
            next: (): any => {
                this.dialogRef.close();
            }
        })
    ];
    public treeNode$: Observable<EntityTree> = this._entityService.getRecursiveEverything(
        this.data.entity._id,
        'entity'
    );
    public itemsToUpdate: ItemWithEntityPricing[] = [];
    public windowSize$: Observable<WindowSize>;
    public WindowSize: typeof WindowSize = WindowSize;
    public changesSuccessful: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<BulkEntityItemsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BulkEntityItemsConfig,
        private _entityService: EntityService,
        private _itemService: ItemService,
        private _notificationService: NotificationService,
        private _cd: ChangeDetectorRef,
        private _windowService: WindowSizeService
    ) {}

    ngOnInit(): void {
        this.windowSize$ = this._windowService.windowSize;
        console.log('data', this.data);
        this.itemsToUpdate = this.data.items;
    }

    public selectionChange(event: StepperSelectionEvent): void {
        for (let i = this.steps.length - 1; i >= event.selectedIndex; i--) {
            this.steps[i].isComplete = false;
        }
        this.currentStep = event.selectedIndex;
    }

    public canEdit(): boolean {
        // tslint:disable:no-magic-numbers
        return (
            this.steps[1].isComplete === false ||
            this.steps[2].isComplete === false ||
            this.steps[3].isComplete === false
        );
    }

    private _applyChanges(): void {
        const settingsToApply = this.itemsStep.getValue();
        const nodes: EntityTreeNode[] = this.treeStep.getValue();
        const entityIds = nodes.reduce(
            (acc, next) => (next.type === 'entity' ? [...acc, next.id] : acc),
            []
        );
        console.log('settings to apply', settingsToApply);
        const delayObs = of({}).pipe(delay(2000));
        forkJoin([
            delayObs,
            this._itemService.bulkEntityItemUpdates(entityIds, settingsToApply)
        ]).subscribe(
            res => {
                console.log('changes complete');
                this.steps[3].next();
            },
            error => {
                console.log('error', error);
                this.changesSuccessful = false;
                this._notificationService.error(error);
                this.steps[3].next();
            }
        );
    }

    private _createStep(index: number, step: Partial<Step> = {}): Step {
        const newStep = {
            label: '',
            isComplete: false,
            nextText: null,
            cancelText: null,
            next: (): any => {
                this._nextStep();
            },
            cancel: (): any => this._previousStep(),
            complete: (): any =>
                (this.steps[index] = this._createStep(index, { ...step, isComplete: true })),
            ...step
        };
        return newStep;
    }

    private _nextStep(): void {
        if (this.currentStep < this.steps.length - 1) {
            this.currentStep++;
            this._cd.detectChanges();
            this.stepper.selectedIndex = this.currentStep;
        }
    }

    private _previousStep(): void {
        if (this.currentStep > 0) {
            this.currentStep--;
            this._cd.detectChanges();
            this.stepper.previous();
        }
    }
}
