export enum DevicePropertyType {
    Make,
    Model,
    IsLocal
}

export namespace DevicePropertyType {
    export function toDBProperty(type: DevicePropertyType): string {
        switch (type) {
            case DevicePropertyType.Make:
                return 'make';
            case DevicePropertyType.Model:
                return 'model';
            case DevicePropertyType.IsLocal:
                return 'isLocal';
            default:
                throw new Error(`device property type ${type} unknown`);
        }
    }
}
