import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IBulkDeviceImport, Install } from '@libs/iso/core/models';
import { InstallService } from '../../../core/services/install.service';
import { NotificationService } from '@app/src/app/core/services/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity, fromUser } from '@app/state/selectors';
import { Entity, User } from '@app/models';

@Component({
    selector: 'ptkr-bulk-device-import',
    templateUrl: './bulk-device-import.component.html',
    styleUrls: ['./bulk-device-import.component.scss']
})
export class BulkDeviceImportComponent implements OnInit {
    public csvContent: string;
    public csvData: Observable<Array<IBulkDeviceImport>> = new Observable();
    public state: any = {
        panel1Open: true,
        panel2Open: false
    };
    public duplicates: number = 0;
    public validatedDevices: Array<any> = [];

    public entity: Entity;
    public user: User;

    constructor(
        private _cd: ChangeDetectorRef,
        private _installService: InstallService,
        private _notificationService: NotificationService,
        private _store: Store<GlobalStore>,
        public dialogRef: MatDialogRef<BulkDeviceImportComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { install: Install }
    ) {}

    ngOnInit(): void {
        this._store
            .pipe(select(fromEntity.currentEntity))
            .subscribe(entity => (this.entity = new Entity(entity)));
        this._store.pipe(select(fromUser.currentUser)).subscribe(user => (this.user = user));
    }

    public onFileSelect(input: HTMLInputElement): void {
        const files = input.files;
        const content = this.csvContent;

        if (files && files.length) {
            const fileToRead = files[0];

            const fileReader = new FileReader();
            fileReader.onload = (event: any): void => {
                this.csvContent = event.target.result;

                const lines = this.csvContent.split('\n');
                const result = [];
                let headers = lines[0].split(',');

                // clean up the headers and remove any whitespace
                headers = headers.map(h => h.trim());

                for (let i = 1; i < lines.length; i++) {
                    const obj = {};

                    // split on the commas but ignore commas that are inside double quotes
                    let currentline = lines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

                    // replace the surrounding quotes when we extract the final value
                    currentline = currentline.map(l => l.replace(/"/g, ''));

                    for (let j = 0; j < headers.length; j++) {
                        obj[headers[j]] = currentline[j];
                    }

                    result.push(obj);
                }

                this._handleCsvData(result);
            };

            fileReader.readAsText(fileToRead, 'UTF-8');
        }
    }

    private _serializeData(device: IBulkDeviceImport): boolean {
        return device.ipAddress !== '';
    }

    private _filterUnique(item: any, array: Array<any>, field: string): boolean {
        let unique: boolean = true;

        for (let i = 0; i < array.length; i++) {
            if (array[i][field] === item[field]) {
                unique = false;
                this.duplicates++;
            }
        }

        return unique;
    }

    private _handleCsvData(data: Array<any>): void {
        console.log(data);
        this.state.panel1Open = false;
        this.state.panel2Open = true;

        this.csvData = new Observable((subscriber): void => {
            for (let i = 0; i < data.length; i++) {
                if (
                    this._serializeData(data[i]) &&
                    this._filterUnique(data[i], this.validatedDevices, 'ipAddress')
                ) {
                    this.validatedDevices.push(data[i]);
                }
            }

            subscriber.next(this.validatedDevices);
        });

        this._cd.detectChanges();
    }

    public applyImport(): void {
        console.log(this.validatedDevices);
        this._installService
            .bulkDeviceImport(
                this.data.install._id.toString(),
                this.entity._id,
                this.user._id,
                this.validatedDevices
            )
            .subscribe(
                res => {
                    this._notificationService.success('Started bulk import process');
                    this.dialogRef.close();
                },
                err => this._notificationService.error(err)
            );
    }
}
