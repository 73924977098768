
<div class="review">
    <h2 class="summary-label">Purchase Details</h2>
    <div class="summary">
        <div class="group info" *ngIf="shippingAddress">
            <label><strong>Ship to:</strong></label>
            <div class="address-value">
                <span>{{ shippingAddress.attn }}</span>
                <span>{{ shippingAddress.address1 }}</span>
                <span>{{ shippingAddress.address2 }}</span>
                <span>{{ shippingAddress.city }}, {{ shippingAddress.stateOrProvince }} {{ shippingAddress.zipOrPostCode }} </span>
            </div>
        </div>
        <div class="group total" *ngIf="cart.isWarranty === true">
            <label>Total</label>
            <span class="value">{{ 0 | currency: 'USD' }}</span>
        </div>
        <div class="group sub-total" *ngIf="!cart.isWarranty">
            <label>Subtotal</label>
            <span class="value">{{ subtotal | currency:'USD' }}</span>
        </div>
        <div class="group estimated-taxes" *ngIf="!cart.isWarranty">
            <label>Estimated taxes</label>
            <span class="value">{{ taxes | currency:'USD' }}</span>
        </div>
        <div class="group shipping" *ngIf="shippingMethod">
            <label>Shipping</label>
            <span class="value">{{ +shippingMethod.price | currency:'USD' }}</span>
        </div>
        <div class="group total" *ngIf="shippingMethod">
            <label>Total</label>
            <span class="value">{{ (subtotal + taxes + +shippingMethod.price) | currency:'USD' }}</span>
        </div>
        <div class="group payment" *ngIf="paymentMethod">
            <label><strong>Payment Method:</strong></label>
            <div class="payment-value">
                {{ PaymentMethodType.toString(paymentMethod.type) }} ending {{ paymentMethod.last4 }}
            </div>
        </div>
    </div>
    <h2 class="items-label">Cart Items</h2>
    <div class="item" *ngFor="let item of cart.items">
        <div class="group">
            <label>Part Number</label>
            <span>{{ item.partNumber ? item.partNumber : '--' }}</span>
        </div>
        <div class="group">
            <label>Type</label>
            <span>{{ item.type ? ItemType.toString(item.type) : '--' }}</span>
        </div>
        <div class="group">
            <label>Color</label>
            <span>
                <ptkr-part-color [color]="item.color"></ptkr-part-color>
            </span>
        </div>
        <div class="group">
            <label>Yield</label>
            <span>{{ item.yield ? item.yield : '--' }}</span>
        </div>
        <div class="group">
            <label>Class</label>
            <span>{{ item.class ? item.class : '--' }}</span>
        </div>
        <div class="group">
            <label>Price</label>
            <span *ngIf="!cart.isWarranty">{{ item.cost | currency:'USD'}}</span>
            <span *ngIf="cart.isWarranty === true">{{ 0 | currency:'USD'}}</span>
        </div>
        <div class="group">
            <label>Qty</label>
            <span>{{ item.qty }}</span>
        </div>
    </div>
</div>
