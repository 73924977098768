export interface IBillingPreferencesSchedule {
    enabled: boolean;
    expression: string;
    timezone: string;
}

export class BillingPreferencesSchedule implements IBillingPreferencesSchedule {
    public enabled: boolean = false;
    public expression: string = '0 12 L 1/1 ?';
    public timezone: string = '';

    constructor(params?: Partial<IBillingPreferencesSchedule>) {
        if (!!params) {
            this.enabled = params.enabled ? params.enabled : this.enabled;
            this.expression = params.expression ? params.expression : this.expression;
            this.timezone = params.timezone ? params.timezone : this.timezone;
        }
    }
}
