import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ReportService } from '@app/services';
import { catchError, take } from 'rxjs/operators';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { PaginatedList, ReportWithMetadata } from '@libs/iso/core';

@Injectable()
export class ReportListResolver implements Resolve<PaginatedList<ReportWithMetadata>> {
    constructor(private _reportService: ReportService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<PaginatedList<ReportWithMetadata>> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let entityId: string = '';
        this._store.pipe(select(fromEntity.entityId), take(1)).subscribe(id => (entityId = id));
        const initQParams = route.queryParams;
        const defaultLimit = 30;
        const params = {
            searchTerm: initQParams.searchTerm || '',
            sortBy: initQParams.sortBy || '',
            sortOrder: initQParams.sortOrder || '',
            page: coerceNumberProperty(initQParams.page) || 1,
            limit: coerceNumberProperty(initQParams.limit) || defaultLimit,
            includeChildren: coerceBooleanProperty(initQParams.includeChildren) || false
        };
        return this._reportService
            .getReportList(
                entityId,
                params.searchTerm,
                params.page,
                params.limit,
                params.sortBy,
                params.sortOrder as number,
                params.includeChildren
            )
            .pipe(
                catchError(err => {
                    console.error('caught error', err);
                    return of(new PaginatedList<ReportWithMetadata>());
                })
            );
    }
}
