import { ISettings } from '@libs/iso/core';
import { ObjectId } from 'bson';

/**
 * Pairs settings with corresponding documents. Documents with matching settings objects will receive the property 'resolvedSettings'. Documents with no
 * match will not have this property.
 * @param {T[]} documents  Array of documents in need of resolved settings
 * @param {Object} settings  Array of settings along with the id in need
 * @returns {void}  Side effects are the assignment of settings to documents
 */
export function addResolvedSettingsToDocuments<T extends { _id: string | ObjectId }>(
    documents: T[], settings: { _id: string, settings: Partial<ISettings> }[]): void {
    documents.forEach((doc: T): void => {
        const index: number = settings.findIndex(
            (value: { _id: string, settings: Partial<ISettings> }) =>
                doc._id.toString() === value._id);
        if (index !== -1) {
            Object.assign(doc, {
                resolvedSettings: settings[index].settings
            });
        }
    });
}
