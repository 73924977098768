import { AuthStore, initAuthState } from '../store';
import {
    AuthActions,
    AuthActionsType,
    LoginAction,
    LogoutAction,
    LoadPermissionsAction,
    ClearPermissionsAction,
    StartImpersonationAction,
    StopImpersonationAction,
    RestoreLoginAction,
    CompleteLoginAction,
    RestoreImpersonationAction
} from '../actions/auth.actions';

class AuthHandlers {
    public static handleLogin(state: AuthStore, action: LoginAction): AuthStore {
        return {
            ...state,
            token: action.payload,
            isImpersonating: false
        };
    }

    public static handleRestoreLogin(state: AuthStore, action: RestoreLoginAction): AuthStore {
        return {
            ...state,
            token: action.payload.token,
            isImpersonating: false,
            initializerResolve: action.payload.resolveFn
        };
    }

    public static handleCompleteLogin(state: AuthStore, action: CompleteLoginAction): AuthStore {
        return state;
    }

    public static handleLogout(state: AuthStore, action: LogoutAction): AuthStore {
        return {
            ...state,
            token: '',
            isImpersonating: false
        };
    }

    public static handleLoadPermissions(
        state: AuthStore,
        action: LoadPermissionsAction
    ): AuthStore {
        return {
            ...state,
            permissions: action.payload
        };
    }

    public static handleClearPermissions(
        state: AuthStore,
        action: ClearPermissionsAction
    ): AuthStore {
        return {
            ...state,
            permissions: []
        };
    }

    public static handleStartImpersonation(
        state: AuthStore,
        action: StartImpersonationAction
    ): AuthStore {
        return {
            ...state,
            token: action.payload,
            isImpersonating: true
        };
    }

    public static handleRestoreImpersonation(
        state: AuthStore,
        action: RestoreImpersonationAction
    ): AuthStore {
        return {
            ...state,
            token: action.payload.token,
            isImpersonating: true,
            initializerResolve: action.payload.resolveFn
        };
    }

    public static handleStopImpersonation(
        state: AuthStore,
        action: StopImpersonationAction
    ): AuthStore {
        return {
            ...state,
            token: action.payload,
            isImpersonating: false
        };
    }
}

export function authReducer(state: AuthStore = initAuthState, action: AuthActionsType): AuthStore {
    switch (action.type) {
        case AuthActions.Login:
            return AuthHandlers.handleLogin(state, action);
        case AuthActions.RestoreLogin:
            return AuthHandlers.handleRestoreLogin(state, action);
        case AuthActions.CompleteLogin:
            return AuthHandlers.handleCompleteLogin(state, action);
        case AuthActions.Logout:
            return AuthHandlers.handleLogout(state, action);
        case AuthActions.LoadPermissions:
            return AuthHandlers.handleLoadPermissions(state, action);
        case AuthActions.ClearPermissions:
            return AuthHandlers.handleClearPermissions(state, action);
        case AuthActions.StartImpersonation:
            return AuthHandlers.handleStartImpersonation(state, action);
        case AuthActions.RestoreImpersonation:
            return AuthHandlers.handleRestoreImpersonation(state, action);
        case AuthActions.StopImpersonation:
            return AuthHandlers.handleStopImpersonation(state, action);
        default:
            return state;
    }
}
