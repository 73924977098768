import { Injectable } from '@angular/core';
import { InstallService } from '@app/core/services/install.service';
import { InstallNetworkTopology, InstallNetworkTopologyEndpoint } from '@libs/iso/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { DefaultTableQueryParams, ITableQueryParams } from '@app/models';

@Injectable({
    providedIn: 'root'
})
export class InstallNetworkTopologyService {
    public topology$: BehaviorSubject<InstallNetworkTopology> = new BehaviorSubject(null);

    /**
     * We only store endpoints in MongoDB that have responded successfully in some way (ping, snmp,
     * etc), however in the UI here it may be helpful to see all endpoints that were scanned. We
     * can __extrapolate__ this by calculating IP addresses from networks. For example, we may have
     * scanned the network 10.0.0.0/24 which can be translated to 255 IP addresses ranging from
     * 10.0.0.1-10.0.0.255.
     *
     * Turning this on or off is optional because of the computational overhead locally of doing this
     * on sites that are scanning lots of subnets.
     */
    public extrapolate$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * This query is applied when we are deriving endpointsTable$ and allows us to filter, sort and
     * limit what is piped out of that observable.
     */
    public query$: BehaviorSubject<ITableQueryParams> = new BehaviorSubject(
        DefaultTableQueryParams
    );

    /**
     * This observable is used to convert an install network topology object into a set of endpoints
     * and also checks to see if we should be extrapolating all endpoints based on the topology's
     * networks array.
     */
    public endpoints$: Observable<Array<InstallNetworkTopologyEndpoint>> = this.topology$.pipe(
        filter(v => v != null),
        switchMap((v: InstallNetworkTopology) => combineLatest([this.extrapolate$, of(v)])),
        map(([extrapolate, v]) => (extrapolate ? v.endpointsFromNetworks() : v.endpoints))
    );

    /**
     * This observable is used to feed a table showing the endpoints. This observable is filtered
     * based on calls to setQuery.
     */
    public endpointsTable$: Observable<Array<InstallNetworkTopologyEndpoint>> = this.query$.pipe(
        switchMap(q =>
            this.endpoints$.pipe(
                // I'm actually quite proud of this (I have yet to benchmark it). The only changes that
                // might need to be made here are to the queryFilter's field accessor. Basically, just
                // access the fields you want to be able to filter on, modify them however you like and
                // the queryFilter function will take care of performing the actual filter on the results.
                //
                // Another thing to keep in mind is that the order here is critical. We need to filter,
                // then sort, and then limit. If we limit before filter, then we filter on a subset rather
                // than the whole set. Maybe sort and filter are interchangeable...
                map(queryFilter(q, v => [v.addr, v.names.join(' ')])),
                map(querySort(q)),
                map(queryLimit(q))
            )
        )
    );
    private installId: string;

    constructor(private _installService: InstallService) {}

    /**
     * Initializes this service for the provided install and loads the most recent network topology
     * results.
     * @param {string} installId - The ID of the install that we want to load topology results for
     * @returns {void}
     */
    public init(installId: string): void {
        this.installId = installId;
        this.reload();
    }

    /**
     * Reloads the install network topology results using the API. This is useful for getting the most
     * up-to-date results.
     * @returns {void}
     */
    public reload(): void {
        this._installService
            .getNetworkTopology(this.installId)
            .subscribe(topology => this.topology$.next(topology));
    }

    /**
     * Completes the topology$ observable
     * @returns {void}
     */
    public cleanup(): void {
        this.query$.next(DefaultTableQueryParams);
        this.extrapolate$.next(false);
    }

    /**
     * Determines whether we extrapolate all scanned endpoints using the networks array of the topology
     * results. For more details on this, see the comment for extrapolate$.
     * @param {boolean} extrapolate - Whether the endpoints should be extrapolated from the networks
     * @returns {void}
     */
    public setExtrapolate(extrapolate: boolean): void {
        this.extrapolate$.next(extrapolate);
    }

    /**
     * Sets the query used to filter endpointsTable$.
     * @param {ITableQueryParams} q - The query parameters used to filter, sort and limit the table
     * @returns {void}
     */
    public setQuery(q: ITableQueryParams): void {
        this.query$.next(q);
    }
}

// Implementers of this type can be passed directly into rxjs map operators to modify the output
// stream of results (T[]).
type IteratorModifier<T> = (v: T[]) => T[];

// tslint:disable-next-line:valid-jsdoc
/**
 * Returns an IteratorModifier<T> that can filter the results in the iterator.
 * @param {ITableQueryParams} q - The query parameters used to configure this modifier
 * @param {(T) => string[]} fieldAccessor - A function that returns a list of values that could
 * be filtered on.
 * @returns {IteratorModifier<T>} - A function that can be passed into an rxjs map operator
 */
function queryFilter<T>(q: ITableQueryParams, fieldAccessor: (T) => string[]): IteratorModifier<T> {
    return (v): T[] =>
        q.searchTerm && q.searchTerm.length > 0
            ? v.filter(e => fieldAccessor(e).filter(f => f.includes(q.searchTerm)).length > 0)
            : v;
}

/**
 * Returns an IteratorModifier<T> sorts the values in the iterator.
 * @param {ITableQueryParams} q - The query parameters used to configure this modifier
 * @returns {IteratorModifier<T>} - A function that can be passed into an rxjs map operator
 */
function querySort<T>(q: ITableQueryParams): IteratorModifier<T> {
    return (v): T[] =>
        q.sortBy.length > 0
            ? v.sort((a, b) => (a[q.sortBy] < b[q.sortBy] ? -q.sortOrder : q.sortOrder))
            : v;
}

/**
 * Returns an IteratorModifier<T> that can limit the results in the iterator.
 * @param {ITableQueryParams} q - The query parameters used to configure this modifier
 * @returns {IteratorModifier<T>} - A function that can be passed into an rxjs map operator
 */
function queryLimit<T>(q: ITableQueryParams): IteratorModifier<T> {
    return (v): T[] => v.slice((q.page - 1) * q.limit, (q.page - 1) * q.limit + q.limit);
}
