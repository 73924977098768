import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { passwordValidators } from '@libs/web/forms';
import { CustomValidators } from 'ng2-validation';
import { UpdatePassword } from '@app/models';

@Component({
    selector: 'ptkr-user-password',
    templateUrl: './user-password.component.html',
    styleUrls: ['./user-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPasswordComponent implements OnInit {
    @Input()
    public buttonLabel: string = $localize`:@@saveChanges:Save changes`;
    @Input()
    public requireCurrentPassword: boolean = true;
    @Output()
    public formComplete: EventEmitter<UpdatePassword> = new EventEmitter<UpdatePassword>();

    public passwordForm: FormGroup;

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        const newPassCtrl = new FormControl('', [Validators.required, ...passwordValidators]);
        this.passwordForm = this._fb.group({
            currentPassword: ['', this.requireCurrentPassword ? Validators.required : []],
            newPassword: newPassCtrl,
            confirmPassword: ['', [Validators.required, CustomValidators.equalTo(newPassCtrl)]]
        });
    }

    public savePassword({ valid, value }: { valid: boolean; value: UpdatePassword }): void {
        if (!valid) {
            return;
        }
        this.formComplete.emit(value);
    }
}
