import { AbstractControl, Validators } from '@angular/forms';
import { ISettings, SettingsKeyMap } from '@libs/iso/core';
import { SettingsUpdateType } from '@libs/iso/core/models/SettingsFormSubmission';
import { EmailArrayFormControl } from '@libs/web/forms/models/form-controls/EmailArrayFormControl';
import { InheritanceFormControl } from '@libs/web/forms/models/form-controls/InheritanceFormControl';
import { AbstractDynamicSettingsSectionFormGroup } from '@libs/web/forms/models/form-groups/AbstractDynamicSettingsSectionFormGroup';

/**
 * A pre-configured FormGroup for inherited device settings
 * @see FormGroup
 */
export class DeviceDynamicSettingsSectionFormGroup extends AbstractDynamicSettingsSectionFormGroup {
    /**
     * @param {ISettings} settings current state of form
     * @param {ISettings} parentSettings fills empty form fields with inherited default values
     * @return {DeviceDynamicSettingsSectionFormGroup} new instance
     * @see FormGroup.constructor
     */
    constructor(settings: Partial<ISettings>, parentSettings: ISettings) {
        super({
            // Alerts
            [SettingsKeyMap.Alerts.Toner.AlertToggle]: new InheritanceFormControl(
                settings?.alerts?.toner?.alertToggle,
                parentSettings.alerts.toner.alertToggle
            ),
            [SettingsKeyMap.Alerts.Toner.SuspectedAlertToggle]: new InheritanceFormControl(
                settings?.alerts?.toner?.suspectedAlertToggle,
                parentSettings.alerts.toner.suspectedAlertToggle
            ),
            [SettingsKeyMap.Alerts.Toner.Emails.Alert]: new EmailArrayFormControl(
                settings?.alerts?.toner?.emails?.alert,
                parentSettings.alerts.toner.emails.alert
            ),
            [SettingsKeyMap.Alerts.Toner
                .PctRemaining]: new InheritanceFormControl(
                settings?.alerts?.toner?.pctRemaining,
                parentSettings.alerts.toner.pctRemaining,
                [Validators.required, Validators.min(0), Validators.max(100)]
            ),
            [SettingsKeyMap.Alerts.Toner
                .ColorPctRemaining]: new InheritanceFormControl(
                settings?.alerts?.toner?.colorPctRemaining,
                parentSettings.alerts.toner.colorPctRemaining,
                [Validators.required, Validators.min(0), Validators.max(100)]
            ),
            [SettingsKeyMap.Alerts.Toner.SeparateColorThreshold]: new InheritanceFormControl(
                settings?.alerts?.toner?.separateColorThresholdToggle,
                parentSettings.alerts.toner.separateColorThresholdToggle
            ),
            [SettingsKeyMap.Alerts.Toner.ReplacementToggle]: new InheritanceFormControl(
                settings?.alerts?.toner?.replacementToggle,
                parentSettings.alerts.toner.replacementToggle
            ),
            [SettingsKeyMap.Alerts.Toner.Emails.Replacement]: new EmailArrayFormControl(
                settings?.alerts?.toner?.emails?.replacement,
                parentSettings.alerts.toner.emails.replacement
            ),
            [SettingsKeyMap.Alerts.Toner.PrematureToggle]: new InheritanceFormControl(
                settings?.alerts?.toner?.prematureToggle,
                parentSettings.alerts.toner.prematureToggle
            ),
            [SettingsKeyMap.Alerts.Toner.Emails.Premature]: new EmailArrayFormControl(
                settings?.alerts?.toner?.emails?.premature,
                parentSettings.alerts.toner.emails.premature
            ),

            [SettingsKeyMap.Alerts.Service.Type.NotReachable]: new InheritanceFormControl(
                settings?.alerts?.service?.type?.notReachable,
                parentSettings.alerts.service.type.notReachable
            ),

            [SettingsKeyMap.Alerts.Service.Type.MaintKit]: new InheritanceFormControl(
                settings?.alerts?.service?.type?.maintKit,
                parentSettings.alerts.service.type.maintKit
            ),
            [SettingsKeyMap.Alerts.Service.Type.ServiceRequested]: new InheritanceFormControl(
                settings?.alerts?.service?.type?.serviceRequested,
                parentSettings.alerts.service.type.serviceRequested
            ),
            [SettingsKeyMap.Alerts.Service.Misfeed.Enabled]: new InheritanceFormControl(
                settings?.alerts?.service?.misfeed?.enabled,
                parentSettings.alerts.service.misfeed.enabled
            ),
            [SettingsKeyMap.Alerts.Service
                .MaintKitPctRemaining]: new InheritanceFormControl(
                settings?.alerts?.service?.maintKitPctRemaining,
                parentSettings.alerts.service.maintKitPctRemaining,
                [Validators.required, Validators.min(0), Validators.max(0)]
            ),

            [SettingsKeyMap.Alerts.Service.Emails.Alert]: new EmailArrayFormControl(
                settings?.alerts?.service?.emails?.alert,
                parentSettings.alerts.service.emails.alert
            ),

            [SettingsKeyMap.Alerts.Volume.Enabled]: new InheritanceFormControl(
                settings?.alerts?.volume?.enabled,
                parentSettings.alerts.volume.enabled
            ),
            // This adjusts the value to what the user expects to see, this offset is undone
            // by 'DeviceSettingsSectionFormGroup.extractOverwrittenValues' before sending updates
            // to the database.
            [SettingsKeyMap.Alerts.Volume.Beginning]: new InheritanceFormControl(
                settings?.alerts?.volume?.beginning !== undefined
                    ? settings?.alerts?.volume?.beginning +
                      (settings?.alerts?.volume?.frequency ??
                          parentSettings.alerts.volume.frequency)
                    : undefined,
                parentSettings.alerts.volume.beginning + parentSettings.alerts.volume.frequency
            ),
            [SettingsKeyMap.Alerts.Volume.Recurring]: new InheritanceFormControl(
                settings?.alerts?.volume?.recurring,
                parentSettings.alerts.volume.recurring
            ),
            [SettingsKeyMap.Alerts.Volume.Frequency]: new InheritanceFormControl(
                settings?.alerts?.volume?.frequency,
                parentSettings.alerts.volume.frequency
            ),
            [SettingsKeyMap.Alerts.Volume.Emails]: new EmailArrayFormControl(
                settings?.alerts?.volume?.emails,
                parentSettings.alerts.volume.emails
            ),

            // Billing
            [SettingsKeyMap.BillingPreferences.Counters]: new InheritanceFormControl(
                settings?.billingPreferences?.counters,
                parentSettings.billingPreferences.counters
            ),
            [SettingsKeyMap.BillingPreferences.Schedule.Enabled]: new InheritanceFormControl(
                settings?.billingPreferences?.schedule?.enabled,
                parentSettings?.billingPreferences?.schedule?.enabled
            ),
            [SettingsKeyMap.BillingPreferences.Schedule.Expression]: new InheritanceFormControl(
                settings?.billingPreferences?.schedule?.expression,
                parentSettings.billingPreferences.schedule.expression
            ),
            [SettingsKeyMap.BillingPreferences.Schedule.Timezone]: new InheritanceFormControl(
                settings?.billingPreferences?.schedule?.timezone,
                parentSettings.billingPreferences.schedule.timezone
            ),

            // Auto-fulfillment
            [SettingsKeyMap.AutoFulfillment.SuppliesNetwork.Enabled]: new InheritanceFormControl(
                settings?.autoFulfillment?.suppliesNetwork?.enabled,
                parentSettings.autoFulfillment.suppliesNetwork.enabled
            ),

            // Data Gathering (credentials)
            [SettingsKeyMap.DataGathering.EmbeddedWebpage.Credentials
                .Username]: new InheritanceFormControl(
                settings?.dataGathering?.embeddedWebpage?.credentials?.username,
                parentSettings.dataGathering.embeddedWebpage.credentials.username
            ),
            [SettingsKeyMap.DataGathering.EmbeddedWebpage.Credentials
                .Password]: new InheritanceFormControl(
                settings?.dataGathering?.embeddedWebpage?.credentials?.password,
                parentSettings.dataGathering.embeddedWebpage.credentials.password
            ),

            // Integration settings
            [SettingsKeyMap.Integrations.EAutomate.SalesQuote.Enabled]: new InheritanceFormControl(
                settings?.integrations?.eautomate?.salesQuote?.enabled,
                parentSettings.integrations.eautomate.salesQuote.enabled
            ),
            [SettingsKeyMap.Integrations.EAutomate.SalesQuote.Enabled]: new InheritanceFormControl(
                settings?.integrations?.eautomate?.salesQuote?.enabled,
                parentSettings.integrations.eautomate.salesQuote.enabled
            ),
            [SettingsKeyMap.Supplies.Consolidation.WithinPercentRemaining]: new InheritanceFormControl(
                settings?.supplies?.consolidation?.withinPercentRemaining,
                parentSettings.supplies.consolidation.withinPercentRemaining
            ),
            [SettingsKeyMap.Supplies.Consolidation.WithinDaysRemaining]: new InheritanceFormControl(
                settings?.supplies?.consolidation?.withinDaysRemaining,
                parentSettings.supplies.consolidation.withinDaysRemaining
            ),
        });
    }

    public extractOverwrittenValues(): SettingsUpdateType {
        // Volume alerts supports one-time volume alerts at the specified future total page
        // count. In order for this to work, the frequency must be 0 when the setting is saved,
        // i.e. when a user disables recurring, we must automatically ensure that
        // alerts.volume.frequency gets set to 0.
        const frequencyControl: AbstractControl = this.controls[
            SettingsKeyMap.Alerts.Volume.Frequency
        ];
        if (this.controls[SettingsKeyMap.Alerts.Volume.Recurring].value === false) {
            frequencyControl.setValue(0);
        }

        // Run normal parent function logic
        const res: SettingsUpdateType = super.extractOverwrittenValues();

        // The UI allows user to specify the page count when the next alert should fire, however
        // the settings object requires that we specify a beginning and frequency value. In order to
        // make this work, the UI needs to set 'alerts.volume.beginning' to be
        // 'alerts.volume.beginning' minus 'next alert at'.
        const nextAlertAt = res.set[SettingsKeyMap.Alerts.Volume.Beginning];
        if (nextAlertAt !== undefined && nextAlertAt !== null) {
            res.set[SettingsKeyMap.Alerts.Volume.Beginning] = nextAlertAt - frequencyControl.value;
        }
        return res;
    }
}
