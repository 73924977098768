import { BaseDoc, BaseDocModel } from '@libs/iso/core';
import { EntityStats } from '@app/models';

export interface EntityStatsListModel extends BaseDocModel {
    parent: EntityStats;
    entityStatsList: EntityStats[];
    data: any;
    filtered: any;
    totalPages: number;
}

interface EntityStatsListConstructorParams extends EntityStatsListModel {}

export class EntityStatsList extends BaseDoc implements EntityStatsListModel {
    public parent: EntityStats = null;
    public entityStatsList: EntityStats[] = [];
    public data: any = null;
    public filtered: any = null;
    public totalPages: number = 0;

    constructor(params?: Partial<EntityStatsListConstructorParams>) {
        // console.log(params);
        super();
        if (!!params) {
            this.parent = params.parent || this.parent;
            this.entityStatsList = params.entityStatsList || this.entityStatsList;
            this.data = params.data || this.data;
            this.filtered = params.filtered || this.filtered;
            this.totalPages = params.totalPages || this.totalPages;
        }
    }
}
