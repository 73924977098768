<h2 mat-dialog-header>Bulk Reset Children</h2>
<div mat-dialog-content>
    <p>This will reset this setting's value for:</p>
    <!-- 'n' will be replaced with a number
         based on an API request calculating
         number of affected children
    -->
    <ul>
        <li><em>{{data.affected.devices}}</em> Devices</li>
        <li><em>{{data.affected.installs}}</em> Installs</li>
        <li><em>{{data.affected.entities}}</em> Entities</li>
    </ul>
    <p>All these children will inherit from this entity.</p>
</div>
<div mat-dialog-actions style='float:right'>
    <button mat-button (click)='cancel()'>Cancel</button>
    <button mat-flat-button color='primary' (click)='confirm()'>Confirm</button>
</div>
