<ptkr-action-bar
    [config]="actionBar"
    (refresh)="refresh.emit()"
></ptkr-action-bar>
<table class="g-table" mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="entityName">
        <th mat-header-cell *matHeaderCellDef>Entity</th>
        <td mat-cell *matCellDef="let file">{{file.entityName}}</td>
    </ng-container>
    <ng-container matColumnDef="installMachineName">
        <th mat-header-cell *matHeaderCellDef>Install</th>
        <td mat-cell *matCellDef="let file">{{file.installMachineName}}</td>
    </ng-container>
    <ng-container matColumnDef="deviceKey">
        <th mat-header-cell *matHeaderCellDef>Device</th>
        <td mat-cell *matCellDef="let file">
            <ptkr-id-quick-copy-button
                prefix=""
                [display]="file.deviceKey | slice:-6"
                [value]="file.deviceKey"
            ></ptkr-id-quick-copy-button>
        </td>
    </ng-container>
    <ng-container matColumnDef="itemMakeModel">
        <th mat-header-cell *matHeaderCellDef>Item</th>
        <td mat-cell *matCellDef="let file">{{file.itemMakeModel}}</td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Created Date</th>
        <td mat-cell *matCellDef="let file">{{file.createdDate | amTimeAgo}} ({{file.createdDate | date:'medium'}})</td>
    </ng-container>
    <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let file">{{formatFileSize(file.size)}}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th class="align-right" mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="align-right" mat-cell *matCellDef="let file">
            <mat-icon [matMenuTriggerFor]="actions" [matMenuTriggerData]="file" style="cursor: pointer;">more_vert</mat-icon>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let device; columns: displayedColumns;"></tr>
</table>

<mat-menu #actions="matMenu">
    <ng-template matMenuContent let-url="url" let-name="name">
        <button mat-menu-item (click)="download(url)">
            <mat-icon>download</mat-icon>
            <span>Download</span>
        </button>
        <button mat-menu-item (click)="delete.emit(name)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
        </button>
    </ng-template>
</mat-menu>
