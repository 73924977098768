import { InvoiceLineItemModel, InvoiceLineItem } from './InvoiceLineItem';
import { BaseAddressModel, BaseAddress } from '../BaseAddress';
import { InvoiceItemModel, InvoiceItem } from './InvoiceItem';
import { ObjectID } from 'bson';

export interface InvoiceModel extends InvoiceLineItemModel {
    entityKey: ObjectID | string;
    entityAddress: BaseAddressModel;
    entityContact: {
        name: string;
        phone: string;
        email: string;
    };
    invoiceItems: InvoiceItemModel[];
    subtotal: number;
    tax: number;
    total: number;
    amountPaid: number;
    amountDue: number;
    onBehalfOfAcuId: string;
    onBehalfOf: string;
}

interface InvoiceConstructorParams extends InvoiceModel {}

export class Invoice extends InvoiceLineItem implements InvoiceModel {
    public entityKey: ObjectID | string;
    public entityAddress: BaseAddress = new BaseAddress();
    public entityContact: {
        name: string;
        phone: string;
        email: string;
    } = {
        name: '',
        phone: '',
        email: ''
    };
    public invoiceItems: InvoiceItem[] = [];
    public subtotal: number = 0;
    public tax: number = 0;
    public total: number = 0;
    public amountPaid: number = 0;
    public amountDue: number = 0;
    public onBehalfOfAcuId: string;
    public onBehalfOf: string;

    constructor(params?: Partial<InvoiceConstructorParams>) {
        super(params);
        if (!!params) {
            if (!!params.entityAddress) {
                this.entityAddress = new BaseAddress(params.entityAddress);
            }
            if (!!params.entityContact) {
                this.entityContact.name = params.entityContact.name || this.entityContact.name;
                this.entityContact.phone = params.entityContact.phone || this.entityContact.phone;
            }
            if (Array.isArray(params.invoiceItems)) {
                for (const i of params.invoiceItems) {
                    this.invoiceItems.push(new InvoiceItem(i));
                }
            }
            this.subtotal = params.subtotal || this.subtotal;
            this.tax = params.tax || this.tax;
            this.total = params.total || this.total;
            this.amountPaid = params.amountPaid || this.amountPaid;
            this.amountDue = params.amountDue || this.amountDue;
            this.onBehalfOf = params.onBehalfOf || this.onBehalfOf;
        }
    }
}
