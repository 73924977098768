<div mat-dialog-header class="header">
    <h3>Restart Data Collection Agent</h3>
</div>
<div mat-dialog-content class="content">
    <p>Sending this job will restart the data collection agent. You can optionally force this job to run immediately. If you're unsure, <strong>do not</strong> force this job.</p>
    <mat-slide-toggle [(ngModel)]="standalone">Force</mat-slide-toggle>
</div>
<div class="action-buttons" style="float: right;" mat-dialog-actions>
    <button mat-button color="accent" (click)="dialogRef.close({result: false})">Cancel</button>
    <button mat-button color="accent" (click)="dialogRef.close({result: true, standalone: standalone})">Send</button>
</div>
