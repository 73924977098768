import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import '@angular/localize/init';
import { loadTranslations } from '@angular/localize';
import { default as axios } from 'axios';
import LocalStorageService from '@app/core/services/local-storage.service';
import { LanguageSelectorService } from '@app/core/services/language-selector.service';
import * as moment from 'moment';

const oldConsoleLog = console.log;
const oldConsoleWarn = console.warn;
const oldConsoleError = console.error;
const consoleOverride = (): void => {};
window['console']['ptkrDisable'] = (): void => {
    window['console']['log'] = consoleOverride;
    window['console']['warn'] = consoleOverride;
    window['console']['error'] = consoleOverride;
};
window['console']['ptkrEnable'] = (): void => {
    window['console']['log'] = oldConsoleLog;
    window['console']['warn'] = oldConsoleWarn;
    window['console']['error'] = oldConsoleError;
};

if (environment.production) {
    enableProdMode();
    window['console']['ptkrDisable']();
}


/*****       LANGUAGE SELECTION    *****/
let language = localStorage ? localStorage.getItem(LocalStorageService.Key.Language) : null;

if (!language) {
    const browserLang = navigator ? navigator.language : null;
    language = browserLang ? browserLang.substring(0, 2) : null;
    if (language
            && localStorage
            && LanguageSelectorService.availableLanguages.find(availableLanguage => language === availableLanguage.code)) {
        localStorage.setItem(LocalStorageService.Key.Language, language);
    }
}

if (language
        && language !== LanguageSelectorService.availableLanguages[0].code // Not default language
        && LanguageSelectorService.availableLanguages.find(availableLanguage => language === availableLanguage.code)) {
    // fetch resources for runtime translations.
    axios.get(`assets/i18n/messages.${language}.json`)
        .then(response => response.data)
        .then(result => {
            // Load Angular translations
            loadTranslations(result.translations);

            // Set moment js locale
            moment.locale(language);
        })
        .finally(() => {
            platformBrowserDynamic()
                .bootstrapModule(AppModule)
                .catch(err => console.log(err));
        });
} else {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(err => console.log(err));
}
