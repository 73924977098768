<ng-container *ngIf="!!formGroup; else noFormGroup">
    <form [formGroup]="formGroup">
        <mat-accordion>
            <mat-expansion-panel class="general-info-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Dealer
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Dealer status and account expiration
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ptkr-field-group label="Dealer" i18n-label>
                    <div class="field-container">
                        <div
                            class="mat-toggle-container"
                            style="position: relative; margin-bottom: 16px"
                        >
                            <mat-slide-toggle
                                class="toggle-field-infix"
                                id="entity-is-dealer-toggle"
                                formControlName="isDealer"
                            >
                                <ng-container i18n>This entity is a dealer</ng-container>
                                <div class="slide-toggle-trigger-overlay" matRipple></div>
                            </mat-slide-toggle>
                        </div>
                    </div>
                </ptkr-field-group>
                <ng-container *ngIf="formGroup.value.isDealer">
                    <ptkr-field-group label="Account status" i18n-label>
                        <mat-form-field
                            floatLabel="never"
                            appearance="outline"
                            class="status-select"
                        >
                            <mat-select
                                [ngModel]="accountStatus"
                                [ngModelOptions]="{ standalone: true }"
                                (ngModelChange)="changeAccountStatus($event)"
                            >
                                <mat-option [value]="DealerAccountStatus.Active" i18n
                                    >Active</mat-option
                                >
                                <mat-option [value]="DealerAccountStatus.Evaluating" i18n
                                    >Evaluating</mat-option
                                >
                                <mat-option [value]="DealerAccountStatus.Suspended" i18n
                                    >Suspended</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </ptkr-field-group>

                    <ptkr-field-group
                        label="Access expiration"
                        i18n-label
                        *ngIf="accountStatus !== DealerAccountStatus.Active"
                    >
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                [matDatepicker]="picker"
                                placeholder="Select date"
                                i18n-placeholder
                                formControlName="expiresOn"
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </ptkr-field-group>
                </ng-container>
                <ptkr-field-group label="Reject meters from inactive accounts" i18n-label>
                    <ptkr-inheritance-reset-button
                        [settingName]="SettingsKeyMap.Distributor.RejectMetersFromInactiveEntities"
                        [showDropdown]="showDropdown"
                        (bulkReset)="runBulkReset.emit($event)"
                    >
                    </ptkr-inheritance-reset-button>
                    <mat-slide-toggle
                        class="toggle-field-infix"
                        formControlName="distributor.rejectMetersFromInactiveEntities"
                    >
                        <ng-container i18n>Enabled</ng-container>
                        <div class="slide-toggle-trigger-overlay" matRipple></div>
                    </mat-slide-toggle>
                </ptkr-field-group>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</ng-container>
<ng-template #noFormGroup>
    <div>
        <h2 style="color: gray;" i18n>Loading...</h2>
    </div>
</ng-template>
