import { getTableConfigurationFromStorage } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EntityService } from '@app/services';
import { catchError, take } from 'rxjs/operators';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { PaginatedList, Event } from '@libs/iso/core';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { IDeviceAlertsListTableQueryParams } from '@app/core/models/TableConfiguration';
import * as moment from 'moment/moment';

@Injectable()
export class DeviceAlertsResolver implements Resolve<PaginatedList<Event>> {
    constructor(private _entityService: EntityService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<PaginatedList<Event>> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let entityId: string = '';
        this._store.pipe(select(fromEntity.entityId), take(1)).subscribe(id => (entityId = id));

        // When the resolver first runs without any query parameters, we want to default to the locally
        // saved table config parameters. We can get these from the local storage.
        const storedParams = getTableConfigurationFromStorage<IDeviceAlertsListTableQueryParams>(
            'deviceAlertsList'
        );

        const initQParams = route.queryParams;
        const defaultLimit = 30;
        const params = {
            startDate:
                initQParams.startDate ??
                moment()
                    .subtract(90, 'days')
                    .toISOString(),
            endDate: initQParams.endDate ?? moment().toISOString(),
            searchTerm: initQParams.searchTerm,
            sortBy: initQParams.sortBy ?? 'dtTm',
            sortOrder: coerceNumberProperty(initQParams.sortOrder) as 1 | -1,
            page: coerceNumberProperty(initQParams.page) || 1,
            limit: coerceNumberProperty(initQParams.limit) || defaultLimit,
            includeChildren: initQParams.includeChildren
                ? coerceBooleanProperty(initQParams.includeChildren)
                : coerceBooleanProperty(storedParams?.includeChildren),
            includeResolved: initQParams.includeResolved
                ? coerceBooleanProperty(initQParams.includeResolved)
                : coerceBooleanProperty(storedParams?.includeResolved),
            serviceAlertsOnly: initQParams.serviceAlertsOnly
                ? coerceBooleanProperty(initQParams.serviceAlertsOnly)
                : coerceBooleanProperty(storedParams?.serviceAlertsOnly)
        };
        return this._entityService
            .getEntityAlerts(
                entityId,
                params.startDate,
                params.endDate,
                params.page,
                params.limit,
                params.sortBy,
                params.sortOrder as number,
                params.includeChildren,
                params.includeResolved,
                params.serviceAlertsOnly,
                params.searchTerm
            )
            .pipe(
                catchError(err => {
                    console.error('caught error', err);
                    return of(new PaginatedList<Event>());
                })
            );
    }
}
