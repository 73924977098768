import { ObjectID } from 'bson';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Status } from './Status';

type False = '0';
type True = '1';

type If<C extends True | False, Then, Else> = { '0': Else; '1': Then }[C];

type Diff<T extends string | number | symbol, U extends string> = ({ [P in T]: P } &
    { [P in U]: never } & { [x: string]: never })[T];

type X<T> = Diff<keyof T, keyof Object>;

type Is<T, U> = (Record<X<T & U>, False> & Record<any, True>)[Diff<X<T>, X<U>>];

// prettier-ignore
type Unpacked<T> =
    T extends (infer U)[] ? U :
    T extends (...args: any[]) => infer V ? V :
    T extends Promise<infer W> ? W :
    T;

// prettier-ignore
export type Server<T> = {
    [P in keyof T]: If<
        Is<Extract<T[P], ObjectID | Moment | Array<ObjectID> | Array<Moment>> & (() => { }), () => { }>,
        If<
            Is<T[P] & (() => { }), () => { }>,
            T[P] extends null ?
                T[P] :
                T[P] extends Object ?
                    (T[P] extends Function ?
                        T[P] :
                        Server<T[P]>) :
                    T[P],
            If<
                Is<T[P] & Function, Function>,
                T[P],
                If<
                    Is<Unpacked<T[P]>, boolean>,
                    T[P],
                    If<
                        Is<Unpacked<T[P]>, string>,
                        T[P],
                        If<
                            Is<Unpacked<T[P]>, number>,
                            T[P],
                            Server<T[P]>
                        >
                    >
                >
            >
        >,
        If<
            Is<T[P], string | ObjectID>,
            ObjectID,
            If<
                Is<T[P] & Function, Function>,
                T[P],
                If<
                    Is<T[P], Date | Moment>,
                    Date,
                    If<
                        Is<Extract<ObjectID & Moment, Unpacked<T[P]>>, void>,
                        T[P],
                        If<
                            Is<Unpacked<T[P]>, (ObjectID | string)>,
                            Array<ObjectID>,
                            If<
                                Is<Unpacked<T[P]>, (Date | Moment)>,
                                Array<Date>,
                                Server<T[P]>
                            >
                        >
                    >
                >
            >
        >
    >
};

// prettier-ignore
export type Client<T> = {
    [P in keyof T]: If<
        Is<Extract<T[P], ObjectID | Moment | Array<ObjectID> | Array<Moment>> & (() => { }), () => { }>,
        If<
            Is<T[P] & (() => { }), () => { }>,
            T[P] extends null ?
                T[P] :
                T[P] extends Object ?
                    (T[P] extends Function ?
                        T[P] :
                        Client<T[P]>) :
                    T[P],
            If<
                Is<T[P] & Function, Function>,
                T[P],
                If<
                    Is<Unpacked<T[P]>, boolean>,
                    T[P],
                    If<
                        Is<Unpacked<T[P]>, string>,
                        T[P],
                        If<
                            Is<Unpacked<T[P]>, number>,
                            T[P],
                            Client<T[P]>
                        >
                    >
                >
            >
        >,
        If<
            Is<T[P], string | ObjectID>,
            string,
            If<
                Is<T[P] & Function, Function>,
                T[P],
                If<
                    Is<T[P], Date | Moment>,
                    Moment,
                    If<
                        Is<Extract<ObjectID & Moment, Unpacked<T[P]>>, void>,
                        T[P],
                        If<
                            Is<Unpacked<T[P]>, (ObjectID | string)>,
                            Array<string>,
                            If<
                                Is<Unpacked<T[P]>, (Date | Moment)>,
                                Array<Moment>,
                                Client<T[P]>
                            >
                        >
                    >
                >
            >
        >
    >
};

export class DocFactory {
    public static toServer<T>(this: new (params?: any) => T, params?: Partial<T>): Server<T> {
        let doc = DocFactory._mapFieldsToObjectId<T>(new this(params));
        doc = DocFactory._mapFieldsToJsDate<T>(doc as any);
        return doc;
    }

    public static toClient<T>(this: new (params?: any) => T, params?: Partial<T>): Client<T> {
        let doc = DocFactory._mapObjectIdsToString<T>(new this(params));
        doc = DocFactory._mapFieldsToMoment<T>(doc as any);
        return doc;
    }

    private static _mapFieldsToObjectId<T>(obj: T): Server<T> {
        if (!!obj) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (typeof obj[key] === 'string') {
                        let updatedField;
                        try {
                            updatedField = new ObjectID((obj[key] as any) as string);
                        } catch (e) {
                            updatedField = obj[key];
                        }
                        obj[key] = updatedField;
                    } else if (obj[key] instanceof ObjectID) {
                        // Do Nothing!
                    } else if (Array.isArray(obj[key])) {
                        if (!!obj[key]) {
                            obj[key] = DocFactory._mapFieldsToObjectId(obj[key]) as any;
                        }
                    } else if (!!obj[key] && obj[key] === Object(obj[key])) {
                        obj[key] = DocFactory._mapFieldsToObjectId(obj[key]) as any;
                    }
                }
            }
            return (obj as any) as Server<T>;
        }
    }

    private static _mapFieldsToJsDate<T>(obj: T): Server<T> {
        if (!!obj) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (obj[key] instanceof moment) {
                        let updatedField;
                        try {
                            updatedField = new Date(((obj[key] as any) as Moment).valueOf());
                        } catch (e) {
                            updatedField = obj[key];
                        }
                        obj[key] = updatedField;
                    } else if (obj[key] instanceof Date) {
                        // Do Nothing!
                    } else if (Array.isArray(obj[key])) {
                        if (!!obj[key]) {
                            obj[key] = DocFactory._mapFieldsToJsDate(obj[key]) as any;
                        }
                    } else if (!!obj[key] && obj[key] === Object(obj[key])) {
                        obj[key] = DocFactory._mapFieldsToJsDate(obj[key]) as any;
                    }
                }
            }
        }
        return (obj as any) as Server<T>;
    }

    private static _mapObjectIdsToString<T>(obj: T): Client<T> {
        if (!!obj) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (obj[key] instanceof ObjectID) {
                        let updatedField;
                        try {
                            updatedField = ((obj[key] as any) as ObjectID).toHexString();
                        } catch (e) {
                            updatedField = obj[key];
                        }
                        obj[key] = updatedField;
                    } else if (Array.isArray(obj[key])) {
                        if (!!obj[key]) {
                            obj[key] = DocFactory._mapObjectIdsToString(obj[key]) as any;
                        }
                    } else if (!!obj[key] && obj[key] === Object(obj[key])) {
                        obj[key] = DocFactory._mapObjectIdsToString(obj[key]) as any;
                    }
                }
            }
            return (obj as any) as Client<T>;
        }
    }

    private static _mapFieldsToMoment<T>(obj: T): Client<T> {
        if (!!obj) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (obj[key] instanceof Date) {
                        let updatedField;
                        try {
                            updatedField = moment(((obj[key] as any) as Date).getTime());
                        } catch (e) {
                            updatedField = obj[key];
                        }
                        obj[key] = updatedField;
                    } else if (obj[key] instanceof moment) {
                        // DO NOTHING!!!
                    } else if (Array.isArray(obj[key])) {
                        if (!!obj[key]) {
                            obj[key] = DocFactory._mapFieldsToMoment(obj[key]) as any;
                        }
                    } else if (!!obj[key] && obj[key] === Object(obj[key])) {
                        obj[key] = DocFactory._mapFieldsToMoment(obj[key]) as any;
                    }
                }
            }
            return (obj as any) as Client<T>;
        }
    }
}

// TEST CASES

class SubDoc extends DocFactory {
    public subAny: any;
    public subId: ObjectID | string;
    public subId2: string | ObjectID;
    public subDate: Date | Moment;
    public subDate2: Moment | Date;
    public subString: string;
    public subNumber: number;
    public subType: Status;
    public subBoolean: boolean;
    public subArray: Array<any>;
    public subArray2: any[];
    public subArray3: Array<number>;
    public subArray4: Array<string>;
    public subObject: Object;
    public subObject2: {};
    public subObjectWithId: {
        id: ObjectID | string;
    };
    public subObjectWithId2: {
        [key: string]: ObjectID | string;
    };
    public subObjectWithDate: {
        date: Date | Moment;
    };
    public subObjectWithDate2: {
        [key: string]: Date | Moment;
    };
    public subObjectComplex: {
        id: ObjectID | string;
        date: Date | Moment;
    };
    public subObjectComplex2: {
        date: Date | Moment;
        id: ObjectID | string;
        anotherId: ObjectID | string;
        anotherDate: Date | Moment;
    };
    public subIdArray: Array<ObjectID> | Array<string[]>;
    public subIdArray2: ObjectID[] | string[];
    public subDateArray: Array<Date> | Array<Moment>;
    public subDateArray2: Date[] | Moment[];
    public subVoid: void;
    public subNull: null;
    public subUndefined: undefined;
    public subNever: never;
    public subTuple: [string, number];
    public subTupleId: [ObjectID | string, any];
    public subTupleDate: [Date | Moment, any];
    public subTupleComplex: [ObjectID | string, Date | Moment];
    public subTupleComplex2: [Date | Moment, ObjectID | string];
    public subFunction(input: any): any {
        return input;
    }
    public subArrowFunction: Function = (input: any): any => input;

    constructor() {
        super();
    }
}

class BaseDoc extends DocFactory {
    public baseAny: any;
    public baseId: ObjectID | string;
    public baseId2: string | ObjectID;
    public baseDate: Date | Moment;
    public baseDate2: Moment | Date;
    public baseString: string;
    public baseNumber: number;
    public baseType: Status;
    public baseBoolean: boolean;
    public baseArray: Array<any>;
    public baseArray2: any[];
    public baseArray3: Array<number>;
    public baseArray4: Array<string>;
    public baseObject: Object;
    public baseObject2: {};
    public baseObjectWithId: {
        id: ObjectID | string;
    };
    public baseObjectWithId2: {
        [key: string]: ObjectID | string;
    };
    public baseObjectWithDate: {
        date: Date | Moment;
    };
    public baseObjectWithDate2: {
        [key: string]: Date | Moment;
    };
    public baseObjectComplex: {
        id: ObjectID | string;
        date: Date | Moment;
    };
    public baseObjectComplex2: {
        date: Date | Moment;
        id: ObjectID | string;
        anotherId: ObjectID | string;
        anotherDate: Date | Moment;
    };
    public baseIdArray: Array<ObjectID> | Array<string>;
    public baseIdArray2: ObjectID[] | string[];
    public baseDateArray: Array<Date> | Array<Moment>;
    public baseDateArray2: Date[] | Moment[];
    public baseVoid: void;
    public baseNull: null;
    public baseUndefined: undefined;
    public baseNever: never;
    public baseTuple: [string, number];
    public baseTupleId: [ObjectID | string, any];
    public baseTupleDate: [Date | Moment, any];
    public baseTupleComplex: [ObjectID | string, Date | Moment];
    public baseTupleComplex2: [Date | Moment, ObjectID | string];
    public baseSubDoc: SubDoc;
    public baseFunction(input: any): any {
        return input;
    }
    public baseArrowFunction: Function = (input: any): any => input;
}

class ExtendedDoc extends BaseDoc {
    public extendedAny: any;
    public extendedId: ObjectID | string;
    public extendedId2: string | ObjectID;
    public extendedDate: Date | Moment;
    public extendedDate2: Moment | Date;
    public extendedString: string;
    public extendedNumber: number;
    public extendedType: Status;
    public extendedBoolean: boolean;
    public extendedArray: Array<any>;
    public extendedArray2: any[];
    public extendedArray3: Array<number>;
    public extendedArray4: Array<string>;
    public extendedObject: Object;
    public extendedObject2: {};
    public extendedObjectWithId: {
        id: ObjectID | string;
    };
    public extendedObjectWithId2: {
        [key: string]: ObjectID | string;
    };
    public extendedObjectWithDate: {
        date: Date | Moment;
    };
    public extendedObjectWithDate2: {
        [key: string]: Date | Moment;
    };
    public extendedObjectComplex: {
        id: ObjectID | string;
        date: Date | Moment;
    };
    public extendedObjectComplex2: {
        date: Date | Moment;
        id: ObjectID | string;
        anotherId: ObjectID | string;
        anotherDate: Date | Moment;
    };
    public extendedIdArray: Array<ObjectID> | Array<string[]>;
    public extendedIdArray2: ObjectID[] | string[];
    public extendedDateArray: Array<Date> | Array<Moment>;
    public extendedDateArray2: Date[] | Moment[];
    public extendedVoid: void;
    public extendedNull: null;
    public extendedUndefined: undefined;
    public extendedNever: never;
    public extendedTuple: [string, number];
    public extendedTupleId: [ObjectID | string, any];
    public extendedTupleDate: [Date | Moment, any];
    public extendedTupleComplex: [ObjectID | string, Date | Moment];
    public extendedTupleComplex2: [Date | Moment, ObjectID | string];
    public extendedSubDoc: SubDoc;
    public extendedFunction(input: any): any {
        return input;
    }
    public extendedArrowFunction: Function = (input: any): any => input;
}

class DeepDoc extends DocFactory {
    public simpleId: ObjectID | string = '';
    public simpleDate: Date | Moment = null;
    public singleArrayId: Array<ObjectID> | Array<string> = [];
    public singleArrayDate: Array<Date> | Array<Moment> = [];
    public singleObject: {
        simpleId: ObjectID | string;
        simpleDate: Date | Moment;
    } = {
        simpleId: '',
        simpleDate: null
    };
    public recursiveDoc: Partial<DeepDoc>;
    public recursiveArray: Array<DeepDoc> = [];

    constructor(params?: Partial<DeepDoc>) {
        super();
        if (!!params) {
            this.simpleId = params.simpleId || this.simpleId;
            this.simpleDate = params.simpleDate || this.simpleDate;
            if (Array.isArray(params.singleArrayId)) {
                this.singleArrayId = params.singleArrayId;
            }
            if (Array.isArray(params.singleArrayDate)) {
                this.singleArrayDate = params.singleArrayDate;
            }
            if (!!params.singleObject) {
                this.singleObject.simpleId =
                    params.singleObject.simpleId || this.singleObject.simpleId;
                this.singleObject.simpleDate =
                    params.singleObject.simpleDate || this.singleObject.simpleDate;
            }
            if (!!params.recursiveDoc) {
                this.recursiveDoc = new DeepDoc(params.recursiveDoc);
            }
            if (Array.isArray(params.recursiveArray)) {
                for (const r of params.recursiveArray) {
                    this.recursiveArray.push(new DeepDoc(r));
                }
            }
        }
    }
}

class Temp {
    public func(): void {
        const baseDoc = new BaseDoc();
        const baseDocFunc = baseDoc.baseFunction;
        const baseDocId = baseDoc.baseId;
        const something = BaseDoc.toServer();
        const somethingId = something.baseId;
        const extended = ExtendedDoc.toServer();
        const test = something.baseSubDoc;
        const somethingClient = BaseDoc.toClient();

        const aStringObjectId: string = '5b05e4213cc564fe3ab7bfcc';
        const anObjectId: ObjectID = new ObjectID('5b05e4213cc564fe3ab7bfcc');
        const aJsDate = new Date();
        const aMomentDate = moment();

        const deepDoc = new DeepDoc({
            recursiveDoc: new DeepDoc({
                recursiveDoc: new DeepDoc({
                    recursiveDoc: new DeepDoc({
                        recursiveDoc: new DeepDoc({
                            recursiveDoc: {
                                simpleId: aStringObjectId,
                                simpleDate: aJsDate,
                                singleArrayDate: [aJsDate, aJsDate, aJsDate],
                                singleArrayId: [aStringObjectId, aStringObjectId, aStringObjectId],
                                singleObject: {
                                    simpleId: aStringObjectId,
                                    simpleDate: aJsDate
                                },
                                recursiveDoc: new DeepDoc({
                                    simpleId: anObjectId,
                                    simpleDate: aMomentDate,
                                    singleArrayDate: [aMomentDate, aMomentDate, aMomentDate],
                                    singleArrayId: [anObjectId, anObjectId, anObjectId],
                                    singleObject: {
                                        simpleId: anObjectId,
                                        simpleDate: aJsDate
                                    }
                                }),
                                recursiveArray: [
                                    new DeepDoc(),
                                    new DeepDoc(),
                                    new DeepDoc({
                                        simpleId: aStringObjectId,
                                        simpleDate: aJsDate
                                    }),
                                    new DeepDoc({
                                        simpleId: anObjectId,
                                        simpleDate: aMomentDate
                                    })
                                ]
                            }
                        })
                    })
                })
            })
        });
        const serverDeepDoc = DeepDoc.toServer(deepDoc);
        const clientDeepDoc = DeepDoc.toClient(deepDoc);

        // checks
        console.log('server', serverDeepDoc);
        console.log('client', clientDeepDoc);
    }
}
