import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewEncapsulation, ElementRef } from '@angular/core';
import { NotificationOverlayRef, NOTIFICATION_OVERLAY_DATA } from '@app/models';
import { Event, EventType, EventResolutionStatus } from '@libs/iso/core';
import { RouterService } from '@app/core/services/router.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { EventNoteComponent } from '@app/shared/overlays/event-note/event-note.component';

@Component({
    selector: 'ptkr-notification-overlay',
    templateUrl: './notification-overlay.component.html',
    styleUrls: ['./notification-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationOverlayComponent implements OnInit {
    public EventType: typeof EventType = EventType;
    public EventResolutionStatus: typeof EventResolutionStatus = EventResolutionStatus;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<NotificationOverlayComponent>,
        private _routerService: RouterService,
        private _dialog: MatDialog
    ) {}

    ngOnInit(): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig();
        const rect = this.data.elementRef.getBoundingClientRect();
        console.log(rect);
        dialogConfig.position = { right: '64px', top: '64px' };
        this.dialogRef.updatePosition(dialogConfig.position);
    }

    public isCritical(instance: Event): boolean {
        return (
            instance.resolutionStatus === EventResolutionStatus.New &&
            EventType.criticalAlerts.indexOf(instance.type) !== -1
        );
    }

    public viewAllAlerts(): void {
        this._routerService.navigate('/devices/alerts');
        this.dialogRef.close();
    }

    // Because this component is opened by the overlay service, it cannot reference the overlay
    // service to be able to open the note component. To accomodate this, we must create the
    // note overlay manually.
    public viewNote(event: Event): void {
        this._dialog.open(EventNoteComponent, {
            data: {
                note: event.acknowledged.note,
                // From the notification bar we want to have editing a note disabled always.
                writeEnabled: false
            }
        });
    }

    public order(event: Event): void {
        this._routerService.navigate('/devices/list', {
            queryParams: {
                deviceId: event.deviceKey,
                tab: 'order'
            }
        });
        this.dialogRef.close();
    }
}
