import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PartialObserver } from 'rxjs';

@Component({
    selector: 'ptkr-email-array-chip-input',
    templateUrl: './email-array-chip-input.component.html',
    styleUrls: ['./email-array-chip-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: EmailArrayChipInputComponent,
            multi: true
        }
    ]
})
export class EmailArrayChipInputComponent implements ControlValueAccessor {
    @Input() public emails: string[];
    @Input() public label: string = 'Email addresses';
    @Input() public notifications: PartialObserver<any>;

    @Output() public overwrite: EventEmitter<void> = new EventEmitter<void>();

    public disabled: boolean;
    public value: string[];

    public separatorKeysCodes: any = [ENTER, COMMA, SEMICOLON, TAB];

    private _onChange: any = () => {};
    private _onTouched: any = () => {};

    constructor() {}

    public writeValue(obj: unknown): void {
        // Every time it is updated, a new array is recorded. This makes change detection easy as
        // well as making the inheritance reset button work more effectively, as this update will
        // make the controller 'dirty'.
        if (!!obj && Array.isArray(obj)) {
            this.value = [...(obj as any[])];
        } else {
            this.value = undefined;
        }
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public addChip(event: any): void {
        const element = event.input;
        const value = event.value;

        if (event.input.validity.valid) {
            if (value.trim() !== '') {
                // make sure the email doesn't already exist
                if (this.value.filter(v => v === event.value).length === 0) {
                    this.writeValue([...(this.value ?? []), event.value]);
                    this._onChange(this.value);
                    element.value = '';
                }
            }
        } else {
            if (this.notifications != null) {
                this.notifications.next({ type: 'error', message: 'Please enter a valid email' });
            }
        }
    }

    public removeChip(event: string): void {
        if (!this.value) {
            this.writeValue([]);
            this._onChange([]);
        }
        const i = this.value.findIndex((v: string): boolean => event === v);
        if (i !== -1) {
            this.value.splice(i, 1);
            this.writeValue([...this.value]);
            this._onChange(this.value);
        } else {
            this.writeValue([...this.value]);
            this._onChange(this.value);
        }
        this.touch();
    }

    public touch(): void {
        this._onTouched();
    }
}
