enum PagesByMonthReportType {
    Device = 'device',
    Entity = 'entity'
}

namespace PagesByMonthReportType {
    export function toString(type: PagesByMonthReportType): string {
        switch (type) {
            case PagesByMonthReportType.Device:
                return 'device';
            case PagesByMonthReportType.Entity:
                return 'entity';
            default:
                return '';
        }
    }
}

export { PagesByMonthReportType };
