import { createSelector } from '@ngrx/store';
import { selectAuth } from '../store/global.store';
import { AuthStore } from '../store/auth.store';

export const fromAuth = {
    token: createSelector(selectAuth, (store: AuthStore) => store.token),
    isAuthenticated: createSelector(selectAuth, (store: AuthStore) => store.token !== ''),
    isImpersonating: createSelector(selectAuth, (store: AuthStore) => store.isImpersonating),
    userPermissions: createSelector(selectAuth, (store: AuthStore) => store.permissions),
    resolveFn: createSelector(selectAuth, (store: AuthStore) => store.initializerResolve)
};
