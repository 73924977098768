import { IEvent, Event } from './Event';
import { Device } from '../device/Device';
import { Item } from '../item/Item';

export interface IEventDetailed extends IEvent {
    device: Device;
    item: Item;
    entityName: string;
}

export class EventDetailed extends Event implements IEventDetailed {
    public device: Device = null;
    public item: Item = null;
    public entityName: string = '';

    constructor(params?: Partial<EventDetailed>) {
        super(params);
        if (!!params) {
            this.entityName = params.entityName || this.entityName;
            if (!!params.device) {
                this.device = new Device(params.device);
            }
            if (!!params.item) {
                this.item = new Item(params.item);
            }
        }
    }
}
