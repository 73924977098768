export enum ConfigurationType {
    Small,
    InstallList,
    Medium,
    Large
}

export interface IPaginationOptions {
    pageSizes: number[];
}

export class PaginationOptions implements IPaginationOptions {
    public pageSizes: number[] = [15, 30, 50, 100];
    constructor(props?: Partial<IPaginationOptions>) {
        if (!props) {
            return;
        }
        this.pageSizes = props.pageSizes || this.pageSizes;
    }
}

export interface IPaginationCurrentState {
    pageIndex?: number;
    limit?: number;
}

export class PaginationCurrentState implements IPaginationCurrentState {
    public limit: number = 15;
    public pageIndex: number = 1;
    constructor(props?: Partial<IPaginationCurrentState>) {
        if (!props) {
            return;
        }
        this.limit = props.limit || this.limit;
        this.pageIndex = props.pageIndex || this.pageIndex;
    }
}

export interface IPaginationConfiguration {
    options: IPaginationOptions;
    currentState: IPaginationCurrentState;
}

export class PaginationConfiguration implements IPaginationConfiguration {
    public currentState: IPaginationCurrentState = new PaginationCurrentState();
    public options: IPaginationOptions = new PaginationOptions();

    constructor(props?: Partial<PaginationConfiguration>) {
        if (!props) {
            return;
        }
        this.currentState = new PaginationCurrentState(props.currentState) ?? this.currentState;
        this.options = new PaginationOptions(props.options) ?? this.options;
    }

    public setPageSize(size: number): void {
        this.currentState.limit = size;
    }

    public setPageIndex(index: number): void {
        this.currentState.pageIndex = index;
    }

    public setConfigurationType(type: ConfigurationType): void {
        switch (type) {
            case ConfigurationType.Small:
                this.options.pageSizes = [5, 10, 15, 30, 50];
                this.currentState.limit = 5;
                break;
            case ConfigurationType.InstallList:
                this.options.pageSizes = [15, 30, 50, 75];
                this.currentState.limit = 20;
                break;
            case ConfigurationType.Medium:
                this.options.pageSizes = [15, 30, 50, 100];
                this.currentState.limit = 30;
                break;
            case ConfigurationType.Large:
                this.options.pageSizes = [30, 50, 100, 150, 200];
                this.currentState.limit = 30;
                break;
            default:
                return;
        }
    }
}
