import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OverlayService } from '@app/services';

@Component({
    selector: 'ptkr-welcome-dca-setup-overlay',
    templateUrl: './welcome-dca-setup-overlay.component.html',
    styleUrls: ['./welcome-dca-setup-overlay.component.scss']
})
export class WelcomeDcaSetupOverlayComponent implements OnInit {
    private installRegistered: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<WelcomeDcaSetupOverlayComponent>,
        public overlayService: OverlayService,
    ) {}

    ngOnInit(): void {}

    public close(): void {
        // If the customer was able to register an agent, we don't need to warn them about prematurely
        // closing the overlay.
        if (this.installRegistered){
            this.dialogRef.close();
            return;
        }
        this.overlayService.openConfirmationModal({
            title: $localize`Are you sure?`,
            content: $localize`Print Tracker is pretty empty if you don't register an agent and scan for devices.`,
            secondaryContent: $localize`If you skip this process now, you will be able to register an agent and scan for devices later.`
        }).afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close();
            }
        });
    }

    public handleInstallRegistered(): void {
        this.installRegistered = true;
    }
}
