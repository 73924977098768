import { SeriesOption } from 'echarts';
import { PageCountTypes } from '@libs/iso/core/enums/PageCountTypes';
import {
    newPageCountTimeSeriesSelector,
    TimeSeriesSelector
} from '@libs/iso/core/models/meterRead/TimeSeriesSelector';
import { TimeSeries } from '@libs/iso/core/models/meterRead/TimeSeries';
import { TimeSeriesBinType } from '@libs/iso/core/enums/TimeSeriesBinType';

export interface IPageCountDisplayableTimeSeries {
    displayName: string;
    type: PageCountTypes;
    volume?: boolean;
    enabledOnInit?: boolean;
    options?: SeriesOption;
    bin?: TimeSeriesBinType;
}

export class PageCountDisplayableTimeSeries implements IPageCountDisplayableTimeSeries {
    public displayName: string;
    public type: PageCountTypes;
    public volume: boolean = true;
    public enabledOnInit: boolean = true;
    public options: SeriesOption = {};
    public selector: TimeSeriesSelector;

    constructor(params: IPageCountDisplayableTimeSeries) {
        this.displayName = params.displayName;
        this.type = params.type;
        if (params.volume != null) {
            this.volume = params.volume;
        }
        if (params.enabledOnInit != null) {
            this.enabledOnInit = params.enabledOnInit;
        }
        this.options = params.options || this.options;
        this.selector = newPageCountTimeSeriesSelector('default', this.type);
    }

    public buildSeriesFromTimeSeries(data: TimeSeries): SeriesOption {
        return this.buildSeriesFromArray(data.series[this.selector?.name]?.timeSeries);
    }

    /**
     * Builds an echarts SeriesOption based on the provided time-series data, and the bin type settings.
     * @param {TimeSeries} ts - The time-series dataset.
     * @param {TimeSeriesBinType} bin - The bin configuration.
     * @returns {SeriesOption} - An echarts SeriesOption used to display the charts.
     */
    public buildSeriesFromArray(
        ts: TimeSeries,
        bin: TimeSeriesBinType = TimeSeriesBinType.None
    ): SeriesOption {
        // If the data is supposed to be a volume calculation rather than the raw data points
        // then calculate the volume between each data point.
        let series = ts.series;
        if (this.volume) {
            series = TimeSeries.volume(series);
        }
        if (bin !== TimeSeriesBinType.None) {
            series = TimeSeries.bin(bin, series, this.volume);
        }
        return <SeriesOption>{
            name: this.displayName,
            type: 'bar',
            stack: PageCountTypes.toGroupName(this.type),
            itemStyle: {
                color: PageCountTypes.toChartableColor(this.type)
            },
            // tslint:disable:ter-indent
            data: TimeSeries.chartable(series, (date: Date) =>
                TimeSeriesBinType.binnedTimestampString(bin, date)
            ),
            yAxisIndex: 0,
            ...this.options
        };
    }
}

export class PageCountDisplayableTimeSeriesGroup {
    public items: Array<PageCountDisplayableTimeSeries> = [];
    constructor(...items: Array<PageCountDisplayableTimeSeries>) {
        if (Array.isArray(items) && items.length > 0) {
            items.forEach(a => this.items.push(a));
        }
    }
    public getSeriesNames(): string[] {
        return this.items.map(a => a.displayName);
    }

    // tslint:disable-next-line:valid-jsdoc
    /**
     * Gets a dictionary of all the time series that have enabledOnInit set to true. The returned
     * format is designed to be consumed by https://echarts.apache.org/en/option.html#legend.selected.
     * @returns {{ [key: string]: boolean }} - A dictionary lookup of all the PageCountDisplayableTimeSeries
     * with a boolean representing whether they should be enabled.
     */
    public getEnabledOnInit(): { [key: string]: boolean } {
        const out = {};
        for (const item of this.items) {
            out[item.displayName] = item.enabledOnInit;
        }
        return out;
    }
}
