<mat-form-field (blur)='onAccept()'
                (click)='onEdit()'
                appearance='outline'
                class='editable-field-form-field'>
    <input (blur)='onAccept()'
           (keyup.enter)='onAccept()'
           (keyup.escape)='onCancel()'
           (keyup.tab)='onAccept()'
           [formControl]='control'
           [type]='type'
           matInput
    />
    <mat-icon
        matSuffix>
        edit
    </mat-icon>
</mat-form-field>
