import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ptkr-delete-label-modal',
    templateUrl: './delete-label-modal.component.html',
    styleUrls: ['./delete-label-modal.component.scss']
})
export class DeleteLabelModalComponent implements OnInit {
    public name: string = '';
    public value: string = '';

    constructor(
        public dialogRef: MatDialogRef<DeleteLabelModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: {
            name: string;
            value: string;
        }
    ) {}

    ngOnInit(): void {
        this.name = this.dialogData.name;
        this.value = this.dialogData.value;
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public delete(): void {
        this.dialogRef.close(true);
    }
}
