import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'ptkr-secured-footer',
    templateUrl: './new-secured-footer.component.html',
    styleUrls: ['./new-secured-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewSecuredFooterComponent implements OnInit {
    public currentYear: number;

    constructor() {}

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
    }
}
