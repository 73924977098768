<div mat-dialog-content class="content">
    <h2>Verify ACH Account</h2>
    <p>Verification is done via two small deposits into the bank account that will be automatically sent.
        These deposits will take 1-2 days to appear on your online statement. The statement description for
        these deposits will be <strong>AMNTS:</strong> and then the values of the two micro deposits that were sent.
    </p>
    <p>Please enter those two amounts into the fields below as whole numbers (ie. if the amount is $0.11,
        then enter the number 11). You have a maximum of 10 (ten) attempts to successfully
        enter the amounts before the ACH account will be locked.
    </p>
    <ptkr-field-group label="Amount 1">
        <mat-form-field floatLabel="never">
            <input matInput type="number" #amount1 min="0" step="1" [formControl]="amnt1">
            <mat-error [ptkrErrorControl]="amnt1"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Amount 2">
        <mat-form-field floatLabel="never">
            <input matInput type="number" #amount2 min="0" step="1" [formControl]="amnt2">
            <mat-error [ptkrErrorControl]="amnt2"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button color='accent' mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="verify(amount1.value, amount2.value)">Verify</button>
</div>
