import { Injectable, inject } from '@angular/core';
import { HttpService, Get, MapClass } from '@libs/web/http';
import { Role } from '@app/models';
import { Observable } from 'rxjs';

@Injectable()
export class RoleService extends HttpService {
    @Get('/role/available')
    @MapClass(Role)
    public getAvailableRoles(): Observable<Role[]> {
        return null;
    }
}
