import { Component, EventEmitter, Output } from '@angular/core';
import { ISettingsFormSubmission } from '@libs/iso/core';
import { SettingsFormGroups } from '@libs/web/forms/models';

/**
 * Parent of all Settings panels that update many values for a device, install or entity. Defines
 * that these settings panels all have a 'submitted' event for when users click the submission
 * button of the form, and enforces that these panels use a standardized submission data format.
 * This abstract class does not concern itself with how children acquire the data necessary to view
 * and modify settings, only how it delivers data back to the API.
 */
@Component({
    selector: 'ptkr-abstract-settings-form',
    template: ''
})
export abstract class AbstractSettingsFormComponent {
    @Output() public submitted: EventEmitter<ISettingsFormSubmission> =
        new EventEmitter<ISettingsFormSubmission>();

    public formGroups: SettingsFormGroups = new SettingsFormGroups();

    public submitChanges(): void {
        this.submitted.emit(this.formGroups.toSettingsFormSubmission());
    }
}
