import { appReducer } from './app.reducer';
import { authReducer } from './auth.reducer';
import { cartReducer } from './cart.reducer';
import { userReducer } from './user.reducer';
import { entityReducer } from './entity.reducer';
import { entitiesReducer } from './entities.reducer';

export const reducers = {
    app: appReducer,
    auth: authReducer,
    cart: cartReducer,
    user: userReducer,
    entity: entityReducer,
    entities: entitiesReducer
};
