import { Order } from '@app/models';
import { Cart } from '@libs/iso/core';

export interface CartStore {
    salesTax: number;
    shipping: number;
    selectedCarts: Cart[];
    order: Order;
    inProgress: boolean;
    taxErrorMessage: string;
}

export const initCartState: CartStore = {
    salesTax: 0,
    shipping: 0,
    selectedCarts: [],
    order: new Order(),
    inProgress: false,
    taxErrorMessage: ''
};
