import { IBaseModel, BaseModel } from '@libs/iso/core/models/BaseModel';
import { ItemType } from '@libs/iso/core/enums/ItemType';
import { ObjectID } from 'bson';
import { IItemPartNumbers, ItemPartNumbers } from '@libs/iso/core/models/item/ItemPartNumbers';
import {
    IItemVenderReference,
    ItemVenderReference
} from '@libs/iso/core/models/item/ItemVenderReference';
import { IItemPrintingInfo } from '@libs/iso/core/models/item/ItemPrintingInfo';
import { QualityLevel } from '@libs/iso/core/enums/QualityLevel';

export interface IItem extends IBaseModel {
    acuId: string;
    type: ItemType;
    make: string;
    model: string;
    description: string;
    partNumbers: IItemPartNumbers;
    vendor: IItemVenderReference;
    actualCost: number;
    standardCost: number;
    OEMPrice: number;
    qualityLevel: number | QualityLevel;
    printing?: IItemPrintingInfo;
    compatibleItems: (ObjectID | string)[];
    incompatibleItems: (ObjectID | string)[];
    status: number;
    price: number;
    sellable: boolean;
}

export class Item extends BaseModel implements IItem {
    public acuId: string = null;
    public type: ItemType = ItemType.Device;
    public make: string = '';
    public model: string = '';
    public description: string = '';
    public partNumbers: IItemPartNumbers = new ItemPartNumbers();
    public vendor: IItemVenderReference = new ItemVenderReference();
    public actualCost: number = 0;
    public standardCost: number = 0;
    public OEMPrice: number = 0;
    public qualityLevel: number | QualityLevel = 0;
    public printing?: IItemPrintingInfo = null;
    public compatibleItems: (string | ObjectID)[] = [];
    public incompatibleItems: (string | ObjectID)[] = [];
    public status: number = 1;
    public price: number = null;
    public sellable: boolean = false;

    constructor(defaults?: Partial<IItem>) {
        super(defaults);
        if (defaults) {
            this.acuId = defaults.acuId || this.acuId;
            this.type = defaults.type || this.type;
            this.make = defaults.make || this.make;
            this.model = defaults.model || this.model;
            this.description = defaults.description || this.description;
            this.partNumbers = defaults.partNumbers || this.partNumbers;
            this.vendor = defaults.vendor || this.vendor;
            this.actualCost = defaults.actualCost || this.actualCost;
            this.standardCost = defaults.standardCost || this.standardCost;
            this.OEMPrice = defaults.OEMPrice || this.OEMPrice;
            this.qualityLevel = defaults.qualityLevel || this.qualityLevel;
            this.printing = defaults.printing || this.printing;
            this.compatibleItems = defaults.compatibleItems || this.compatibleItems;
            this.incompatibleItems = defaults.incompatibleItems || this.incompatibleItems;
            this.status = defaults.status || this.status;
            this.price = defaults.price || this.price;
            this.sellable = defaults.sellable || this.sellable;
        }
    }
}
