import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';
import { Status, ContactPhoneType } from '@libs/iso/core';
import { timezoneNames } from '@libs/iso/core/constants';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { User, WindowSize } from '@app/models';
import { CustomValidators } from 'ng2-validation';
import { Subject } from 'rxjs';

interface FormValue {
    status: Status;
    firstName: string;
    lastName: string;
    email: string;
    officeNumber: string;
    cellNumber: string;
    receiveSMS: boolean;
    timeZone: string;
}

@Component({
    selector: 'ptkr-user-profile-fields',
    templateUrl: './user-profile-fields.component.html',
    styleUrls: ['./user-profile-fields.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileFieldsComponent implements OnInit, OnDestroy {
    @Input()
    public user: User = new User();
    @Input()
    public buttonLabel: string = $localize`:@@saveChanges:Save changes`;
    @Output()
    public formComplete: EventEmitter<User> = new EventEmitter<User>();

    private _ngUnsub: Subject<void> = new Subject<void>();

    public Status: typeof Status = Status;
    public WindowSize: typeof WindowSize = WindowSize;
    public userProfileForm: FormGroup;

    public timezoneNames: typeof timezoneNames = timezoneNames;

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        const userOfficePhone: {
            type: ContactPhoneType;
            number: string;
        } = this.user.contact.phone.find(p => p.type === ContactPhoneType.Office);
        const userCellPhone: {
            type: ContactPhoneType;
            number: string;
        } = this.user.contact.phone.find(p => p.type === ContactPhoneType.Cell);
        this.userProfileForm = this._fb.group({
            status: [this.user.status || Status.Active, Validators.required],
            firstName: [this.user.firstName, Validators.required],
            lastName: [this.user.lastName, Validators.required],
            email: [this.user.email, [Validators.required, CustomValidators.email]],
            officeNumber: [
                userOfficePhone ? userOfficePhone.number : '',
                [
                    Validators.required
                ]
            ],
            cellNumber: [
                userCellPhone ? userCellPhone.number : '',
                [
                    (control: AbstractControl): { [key: string]: boolean } => {
                        if (control.value !== '') {
                            const actualVal = (control.value as string).replace('_', '');
                            if (actualVal.length !== 14) {
                                return {
                                    phoneMask: true
                                };
                            }
                        }
                        return null;
                    }
                ]
            ],
            receiveSMS: [this.user.contact.receiveSMS || false],
            timeZone: [this.user.preferredTimeZone]
        });
    }

    ngOnDestroy(): void {
        this._ngUnsub.next();
        this._ngUnsub.complete();
        this._ngUnsub = undefined;
    }

    public saveUserForm({ valid, value }: { valid: boolean; value: FormValue }): void {
        if (!valid) {
            return;
        }
        const user = new User({
            ...this.user,
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            status: value.status,
            preferredTimeZone: value.timeZone,
        });
        user.contact.receiveSMS = value.receiveSMS;
        const officePhoneIndex = user.contact.phone.findIndex(
            p => p.type === ContactPhoneType.Office
        );
        const cellPhoneIndex = user.contact.phone.findIndex(p => p.type === ContactPhoneType.Cell);
        if (officePhoneIndex > -1) {
            user.contact.phone[officePhoneIndex].number = value.officeNumber.slice(0, 14);
        } else {
            user.contact.phone.push({
                type: ContactPhoneType.Office,
                number: value.officeNumber.slice(0, 14)
            });
        }
        if (cellPhoneIndex > -1) {
            user.contact.phone[cellPhoneIndex].number = value.cellNumber.slice(0, 14);
        } else {
            user.contact.phone.push({
                type: ContactPhoneType.Cell,
                number: value.cellNumber.slice(0, 14)
            });
        }
        this.formComplete.emit(user);
    }

    public onTimeZoneUpdate(event: { name: string }): void {
        this.userProfileForm.get('timeZone').setValue(event?.name);
    }
}
