<mat-card>
    <h2>Reporting devices this month</h2>
    <div class="chart">
        <!-- <div
                ptkrGoogleChart
                [chart-type]="chartType"
                [chart-data]="chartData"
                [chart-columns]="chartColumns"
                [chart-options]="chartOptions">
            </div> -->
        <div class="chart-title">
            Devices reporting data in <strong>{{ monthName }}</strong>
        </div>
        <div class="chart-value">{{ percentReporting | number: '1.0-1' }}%</div>
        <div class="chart-wrapper">
            <ngx-charts-pie-chart
                [results]="chartData"
                [doughnut]="true"
                [customColors]="customColors"
                [tooltipText]="labelFormatter"
            ></ngx-charts-pie-chart>
        </div>
        <div class="chart-footer">
            {{ devicesReporting }} of {{ totalDevices }}<br />devices reported
        </div>
    </div>
</mat-card>
