import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export let RIAFOX_HTTP_WEB_SERVER_URL = new InjectionToken<string>('riafox.http.url.webserver');
export let RIAFOX_HTTP_URL_IGNORE_CASES = new InjectionToken<RegExp>('riafox.http.url.ignorecases');

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
    constructor(
        @Inject(RIAFOX_HTTP_WEB_SERVER_URL) private _webServer: string = '',
        @Inject(RIAFOX_HTTP_URL_IGNORE_CASES) private _ignoreCases: RegExp = null
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = req.url;
        if (
            req.url.indexOf('http') === -1 && // If the http protocol already exists in the url, don't append another
            req.url.indexOf('assets/') !== 0 && // If the first characters are assets/ then we are trying to access locally
            this._webServer !== '' // Verify that we really do have something for the webserver
        ) {
            if (!!this._ignoreCases && this._ignoreCases instanceof RegExp) {
                // If ignore cases is not null then the user passed in a value
                if (!this._ignoreCases.test(url)) {
                    // Only apply the webserver url if it does not match the ignore cases
                    url = this._webServer + req.url;
                }
            } else {
                url = this._webServer + req.url;
            }
        }
        const urlReq = req.clone({ url: url });
        return next.handle(urlReq);
    }
}
