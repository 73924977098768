<div class="container">
    <h1>Jobs</h1>

    <p>Jobs allow you to send commands to your Data Collection Agent (DCA). <span *ngIf="jobsExist()">These are jobs
            that you've sent already or that have been started by your DCA.
            To
            learn more about jobs <a>click here</a>.</span></p>
    <div class="clearfix">
        <h4 style="float: left">Your jobs</h4>
        <button style="float: right" mat-button (click)="deleteJobs(jobs)" color="warn" matTooltip="Clear all jobs from your jobs list">Dismiss all</button>
        <button style="float: right" mat-button (click)="refreshData()" color="warn" matTooltip="Gets jobs">Refresh jobs</button>
    </div>
</div>
<mat-dialog-content>
    <div class="container">
        <div>
<!--            <p *ngIf="!jobs | async">There's nothing to see here at the moment. Want to-->
<!--                <a href="javascript:;">start a new job?</a>-->
<!--            </p>-->
            <mat-accordion>
                <ptkr-job *ngFor="let job of jobs" [job]="job"></ptkr-job>
            </mat-accordion>
        </div>

        <!-- <div [hidden]="automaticJobs.length == 0">
                <h2>Automatic jobs</h2>
                <mat-accordion>
                    <ptkr-job *ngFor="let job of automaticJobs" [job]="job"></ptkr-job>
                </mat-accordion>
            </div> -->
    </div>
</mat-dialog-content>

<div class="container">
    <div style="margin-top: 24px;" class="action-buttons">
        <button mat-flat-button color="accent" (click)="close()">Done</button>
    </div>
</div>
