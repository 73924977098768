import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionBarConfiguration } from '@app/shared/components/action-bar/action-bar.component';
import { NewJob } from '@libs/iso/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { OverlayService } from '@app/services';
import { TableConfiguration } from '@app/models';

@Component({
    selector: 'ptkr-job-list-table-viewer',
    templateUrl: './job-list-table-viewer.component.html',
    styleUrls: ['./job-list-table-viewer.component.scss']
})
export class JobListTableViewerComponent implements OnInit {
    @Input() public data$: Observable<Array<NewJob>> = new BehaviorSubject([]);

    // The table configuration that manages the filter, sort, and pagination
    @Input() public tc: TableConfiguration = new TableConfiguration();

    // The columns that should be displayed in the job list
    @Input() public displayedColumns: Array<string> = [
        'name',
        'user',
        'modifiedDate',
        'status',
        'actions'
    ];

    // Indicates that the data provider refresh the data source
    @Output() public reload: EventEmitter<void> = new EventEmitter();

    // Maintains the data source used to feed the jobs table
    public dataSource: MatTableDataSource<NewJob> = new MatTableDataSource<NewJob>([]);

    // The action bar configuration for the jobs table that should provide a filter for us
    public jobsActionBar: ActionBarConfiguration = {
        showRefresh: true,
        showFilter: true,
        filterSuffix: 'jobs'
    };

    constructor(private _overlayService: OverlayService) {}

    ngOnInit(): void {
        this.data$.subscribe(res =>
            !!res ? (this.dataSource = new MatTableDataSource(res)) : null
        );
    }

    public viewLogs(id: string): void {
        this._overlayService
            .openJobLogViewer(id)
            .afterClosed()
            .subscribe();
    }
}
