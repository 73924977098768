enum PasswordStatus {
    Valid,
    NotMatch,
    DealerInactive,
    DealerDoesNotExist
}

namespace PasswordStatus {
    export function toString(type: PasswordStatus): string {
        switch (type) {
            case PasswordStatus.Valid:
                return 'Valid Password';
            case PasswordStatus.NotMatch:
                return 'Does not match';
            case PasswordStatus.DealerInactive:
                return 'Entity is Inactive';
            case PasswordStatus.DealerDoesNotExist:
                return 'Entity code does not exist';
            default:
                return '';
        }
    }

    export const asArray: PasswordStatus[] = [
        PasswordStatus.Valid,
        PasswordStatus.NotMatch,
        PasswordStatus.DealerInactive,
        PasswordStatus.DealerDoesNotExist
    ];
}

export { PasswordStatus };
