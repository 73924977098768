import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { GlobalStore } from '@app/state/store';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { TableDataList } from '@app/core/models/TableDataList';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import {
    OverlayService,
    RouterService,
    DeviceService,
    NotificationService,
    EntityService
} from '@app/services';
import {
    SuperTableParams,
    superTableParamDefaults,
    superTablePageSizes,
    SuperTableCollection
} from '@app/core/models/SuperTableParams';
import { fromEntity } from '@app/state/selectors';
import { take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'ptkr-super-table-page',
    templateUrl: './super-table-page.component.html',
    styleUrls: ['./super-table-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush // Will significantly improve performance.
})
export class SuperTablePageComponent implements OnInit {
    public collections: Array<string> = [
        SuperTableCollection.device,
        SuperTableCollection.install,
        SuperTableCollection.entity,
        SuperTableCollection.event,
        SuperTableCollection.meterRead,
        SuperTableCollection.meterViewer
    ];
    public currentCollection: string = this.collections[0];

    public defaultPageSize: number = superTableParamDefaults.limit;
    public pageSizeOptions: Array<number> = superTablePageSizes;

    public tableData: TableDataList = new TableDataList(); // Data in the table
    public tableParams: SuperTableParams = superTableParamDefaults;

    public entityId: string = '';
    public newStartDate: Date = null;
    public newEndDate: Date = null;
    public billingReportDate: Date = new Date();
    public isBillingReport: boolean = false;
    public filter: string = '';

    public title: string = '';
    public collectionIsHardcoded: boolean = false;
    public tableActions: Array<{
        name: string;
        action: (tableParams: SuperTableParams, row: any) => void;
    }> = [];

    public hideBilled: boolean;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _store: Store<GlobalStore>,
        private _overlayService: OverlayService,
        private _routerService: RouterService,
        private _cd: ChangeDetectorRef,
        private _deviceService: DeviceService,
        private _entityService: EntityService,
        private _notificationService: NotificationService,
        private _router: Router,
        private _title: Title
    ) {}

    ngOnInit(): void {
        this._activatedRoute.data.subscribe(data => {
            this.tableData = data['tableDataList'].tableDataList;
            this.tableParams = data['tableDataList'].params;
            this.title = data['title'] || '';
            this.collectionIsHardcoded = data['collection'] != null;
            this.tableActions = data['actions'] || [];

            this._store
                .pipe(select(fromEntity.currentEntity), take(1))
                .subscribe(entity => this._title.setTitle(`${this.title} | ${entity.name}`));

            // Set collection select box accordingly.
            for (const c of this.collections) {
                if (c === this.tableParams.collection) {
                    this.currentCollection = c;
                    break;
                }
            }

            // Set date range if applicable.
            if (this.tableParams.startDate && this.tableParams.endDate) {
                this.newStartDate = new Date(this.tableParams.startDate);
                this.newEndDate = new Date(this.tableParams.endDate);
            }
            this.isBillingReport =
                this.tableParams.isBillingReport === 'true' ||
                this.tableParams.isBillingReport === true
                    ? true
                    : false;
            this.filter = this.tableParams.searchTerm;

            this._cd.detectChanges();
            this._store.dispatch(new ToggleGlobalSpinnerAction(false));
        });

        this._store.pipe(select(fromEntity.entityId)).subscribe(id => (this.entityId = id));

        this._activatedRoute.queryParams.subscribe(params => {
            // Only execute this when there are no params => when the route is loaded for the first time or is reloaded
            if (Object.keys(params).length === 0) {
                this._updateTableQueryParams({});
            }
        });
    }

    // --- PAGINATOR -----------------------------------------------------------
    public onPageChange(event: any): void {
        this._updateTableQueryParams({ page: event });
    }

    public onPageSizeChange(event: any): void {
        this._updateTableQueryParams({ limit: event });
    }

    // --- INCLUDE CHILDREN TOGGLE ---------------------------------------------
    public toggleIncludeChildren(event: boolean): void {
        this._updateTableQueryParams({ includeChildren: event });
    }

    // --- EDIT COLUMNS --------------------------------------------------------
    public editColumns(): void {
        const overlay = this._overlayService.openEditColumnsModal(
            this.tableData.model.columns,
            this.tableParams.columns
        );
        overlay.afterClosed().subscribe(res => {
            if (res && res.do && res.do === 'update') {
                this._updateTableQueryParams({ columns: res.columns });
            }
        });
    }

    // --- COLLECTION PICKER ---------------------------------------------------
    public changeCollection(event: MatSelectChange): void {
        this._updateTableQueryParams({
            collection: event.value,
            columns: superTableParamDefaults.columns
        });
    }

    // --- DATE PICKER ---------------------------------------------------------
    public pickDates(): void {
        if (!this.isBillingReport && (!this.newStartDate || !this.newEndDate)) {
            this._notificationService.error($localize`Choose a Date Range.`);
            return;
        }

        this.newStartDate = new Date(this.newStartDate);
        this.newEndDate = new Date(this.newEndDate);
        // We need the date to be exactly the *end* of that day.
        this.newEndDate.setHours(23, 59, 59, 999);
        this._updateTableQueryParams({
            startDate: this.isBillingReport
                ? this.billingReportDate.toISOString()
                : this.newStartDate.toISOString(),
            endDate: this.newEndDate.toISOString(),
            isBillingReport: this.isBillingReport
        });
    }

    // --- SEARCH BAR ----------------------------------------------------------
    public search(event: string): void {
        this._updateTableQueryParams({});
    }

    // --- SORT BY COLUMN ------------------------------------------------------
    public onUpdateSort(event: Sort): void {
        this._updateTableQueryParams({ sort: event.active, sortOrder: event.direction });
    }

    // --- MISC TEMPLATE HELPER FUNCTIONS --------------------------------------
    public getCollectionName(
        value: SuperTableCollection,
        plural: boolean = false,
        capitalized: boolean = false
    ): string {
        return SuperTableCollection.toString(value, plural, capitalized);
    }

    public getIsBillingReport(): boolean {
        return this.isBillingReport;
    }

    // --- EXPORT TO CSV -------------------------------------------------------
    public exportCSV(): void {
        switch (this.currentCollection) {
            case 'device':
                return this._exportCSV(this._deviceService);
            case 'install':
                return;
            case 'entity':
                return;
            case 'event':
                return;
            case 'meterRead':
                return this._exportCSVMeterReads();
            case 'meterViewer':
                return this._exportCSVMeterViewer();
            default:
                return; // Does nothing.
        }
    }

    private _exportCSV(service: any): void {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let order;
        switch (this.tableParams.sortOrder) {
            case 'asc':
            case 1:
                order = 1;
                break;
            case 'desc':
            case -1:
                order = -1;
                break;
            default:
                order = 0;
        }

        service
            .exportToCSV(
                this.entityId,
                this.tableParams.searchTerm,
                0,
                0,
                this.tableParams.sort,
                order,
                this.tableParams.includeChildren,
                this.tableParams.columns
            )
            .subscribe(
                res => {
                    this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    const blob = new Blob([res], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.download = `${SuperTableCollection.toString(
                        SuperTableCollection[this.tableParams.collection],
                        true
                    )}-for-entity-${this.entityId}_${new Date().toISOString()}.csv`;
                    link.href = url;
                    link.click();
                },
                err => {
                    this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    this._notificationService.error(err.statusText);
                }
            );
    }

    private _exportCSVMeterReads(): void {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let order;
        switch (this.tableParams.sortOrder) {
            case 'asc':
            case 1:
                order = 1;
                break;
            case 'desc':
            case -1:
                order = -1;
                break;
            default:
                order = 0;
        }

        this._entityService
            .getMeterReadCSV(
                this.entityId,
                this.tableParams.searchTerm,
                0,
                0,
                this.tableParams.sort,
                order,
                this.tableParams.includeChildren,
                this.tableParams.columns
            )
            .subscribe(
                res => {
                    this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    const blob = new Blob([res], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.download = `${SuperTableCollection.toString(
                        SuperTableCollection[this.tableParams.collection],
                        true
                    )}-for-entity-${this.entityId}_${new Date().toISOString()}.csv`;
                    link.href = url;
                    link.click();
                },
                err => {
                    this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    this._notificationService.error(err.statusText);
                }
            );
    }

    private _exportCSVMeterViewer(): void {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let order;
        switch (this.tableParams.sortOrder) {
            case 'asc':
            case 1:
                order = 1;
                break;
            case 'desc':
            case -1:
                order = -1;
                break;
            default:
                order = 0;
        }

        this._entityService
            .getMeterViewerCsv(
                this.entityId,
                this.tableParams.startDate,
                this.tableParams.endDate,
                this.tableParams.searchTerm,
                0,
                0,
                this.tableParams.sort,
                order,
                this.tableParams.includeChildren,
                this.tableParams.columns,
                this.tableParams.isBillingReport,
                this.tableParams.hideBilled
            )
            .subscribe(
                res => {
                    this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    const blob = new Blob([res], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.download = `${
                        this.tableParams.isBillingReport
                            ? 'BillingReport'
                            : SuperTableCollection.toString(
                                  SuperTableCollection[this.tableParams.collection],
                                  true
                              )
                    }-for-entity-${this.entityId}_${new Date().toISOString()}.csv`;
                    link.href = url;
                    link.click();
                },
                err => {
                    this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    this._notificationService.error(err.statusText);
                }
            );
    }

    // --- ACTIONS -------------------------------------------------------------
    public onActionClicked(event: { name: string; row: any }): void {
        this.tableActions[event.name](this.tableParams, event.row);
    }

    public getActionNames(): Array<string> {
        return Object.keys(this.tableActions);
    }

    // --- QUERY UPDATE FUNC ---------------------------------------------------
    private async _updateTableQueryParams(updates: Partial<SuperTableParams>): Promise<void> {
        const params: any = {
            ...this.tableParams,
            page: 1, // Sets page to 1 unless updates specifies otherwise.
            ...{
                isBillingReport: this.isBillingReport,
                hideBilled: this.hideBilled,
                startDate: this.isBillingReport
                    ? this.billingReportDate.toISOString()
                    : this.newStartDate.toISOString(),
                endDate: this.newEndDate.toISOString(),
                searchTerm: this.filter
            },
            ...updates
        };
        this._refresh(params);
    }

    // Specifically sets whatever query is currently being viewed as billed, including other pages
    // of that query. Does not take into account changes made to search bar or date ranges
    // (by design, as we want to change what the user is currently looking at, not some other stuff)
    public async setAllAsBilled(): Promise<void> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        // We use table params instead of any newer values (i.e. 'billingReportDate') because
        // we want the button to work off of what the user currently sees not any settings they may
        // have messed with prior to pressing the button.
        await this._entityService.setToBilled(
            this.entityId,
            this.tableParams.startDate,
            this.tableParams.endDate,
            this.tableParams.includeChildren,
            this.tableParams.searchTerm,
            this.tableParams.columns
        ).subscribe();
        this._refresh({ ...this.tableParams });
    }

    private _refresh(params: any): void {
        switch (params.sortOrder) {
            case 'asc':
            case 1:
                params.sortOrder = 1;
                break;
            case 'desc':
            case -1:
                params.sortOrder = -1;
                break;
            default:
                params.sortOrder = undefined;
        }
        params.columns = JSON.stringify(params.columns);
        this._routerService.fullNavigate(this._router.url.split('?')[0], { queryParams: params });
    }
}
