import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'ptkr-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent implements OnInit {
    @Input()
    public title: string = '';
    @Input()
    public subTitle: string = '';

    constructor() {}

    ngOnInit(): void {}
}
