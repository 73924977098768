import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { InstallService } from '@app/services';
import { Install } from '@libs/iso/core';

@Injectable()
export class InstallByIdResolver implements Resolve<Install> {
    constructor(private _installsService: InstallService, private _store: Store<GlobalStore>) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Install> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        const installId = route.params['installId'] || '';
        return this._installsService.getInstall(installId);
    }
}
