enum PageCountType {
    default = '[PageCountType] default',
    life = '[PageCountType] life',
    equiv = '[PageCountType] equiv',
    nonEquiv = '[PageCountType] non-equiv',
    sqFt = '[PageCountType] sqFt',
    lnFt = '[PageCountType] lnFt',
    sqMt = '[PageCountType] sqMt',
    lnMt = '[PageCountType] lnMt',
    lnKm = '[PageCountType] lnKm',
    sqIn = '[PageCountType] sqIn',
    lnIn = '[PageCountType] lnIn',
    sqCm = '[PageCountType] sqCm',
    lnCm = '[PageCountType] lnCm',
    pages = '[PageCountType] pages',
    totalAreaClick = '[PageCountType] totalAreaClick',
    centimeters = '[PageCountType] centimeters',
    scans = '[PageCountType] scans',
    faxes = '[pageCountType] faxes',
    canon = '[pageCountType] canon',
    engine = '[pageCountType] engine',
    ml = '[pageCountType] ml',
    dev = '[pageCountType] dev',
    unknown = '[PageCountType] unknown'
}

// meter types
namespace PageCountType {
    export function toDbString(type: PageCountType): string {
        switch (type) {
            case PageCountType.default:
                return 'default';
            case PageCountType.life:
                return 'life';
            case PageCountType.equiv:
                return 'equiv';
            case PageCountType.nonEquiv:
                return 'non-equiv';
            case PageCountType.sqFt:
                return 'sqFt';
            case PageCountType.lnFt:
                return 'lnFt';
            case PageCountType.sqMt:
                return 'sqMt';
            case PageCountType.lnMt:
                return 'lnMt';
            case PageCountType.lnKm:
                return 'lnKm';
            case PageCountType.sqIn:
                return 'sqIn';
            case PageCountType.lnIn:
                return 'lnIn';
            case PageCountType.sqCm:
                return 'sqCm';
            case PageCountType.lnCm:
                return 'lnCm';
            case PageCountType.pages:
                return 'pages';
            case PageCountType.totalAreaClick:
                return 'totalAreaClick';
            case PageCountType.centimeters:
                return 'centimeters';
            case PageCountType.scans:
                return 'scans';
            case PageCountType.faxes:
                return 'faxes';
            case PageCountType.canon:
                return 'canon';
            case PageCountType.engine:
                return 'engine';
            case PageCountType.ml:
                return 'ml';
            case PageCountType.dev:
                return 'dev';
            default:
                return 'unknown';
        }
    }

    export function toDisplayString(type: PageCountType): string {
        switch (type) {
            case PageCountType.default:
                return 'Default';
            case PageCountType.life:
                return 'Life';
            case PageCountType.equiv:
                return 'Equivalent';
            case PageCountType.nonEquiv:
                return 'Non-Equivalent';
            case PageCountType.sqFt:
                return 'Square-Feet';
            case PageCountType.lnFt:
                return 'Linear-Feet';
            case PageCountType.sqMt:
                return 'Square-Meter';
            case PageCountType.lnMt:
                return 'Linear-Meter';
            case PageCountType.lnKm:
                return 'Linear-Kilometer';
            case PageCountType.sqIn:
                return 'Square-Inch';
            case PageCountType.lnIn:
                return 'Linear-Inch';
            case PageCountType.sqCm:
                return 'Square-Centimeter';
            case PageCountType.lnCm:
                return 'Linear-Centimeter';
            case PageCountType.pages:
                return 'Pages';
            case PageCountType.totalAreaClick:
                return 'Total Area Click';
            case PageCountType.centimeters:
                return 'Centimeters';
            case PageCountType.scans:
                return 'Scans';
            case PageCountType.faxes:
                return 'Faxes';
            case PageCountType.canon:
                return 'Canon';
            case PageCountType.engine:
                return 'Engine';
            case PageCountType.ml:
                return 'Milliliters';
            case PageCountType.dev:
                return 'Development';
            default:
                return 'Unknown';
        }
    }

    export function toShorthand(type: PageCountType): string {
        switch (type) {
            case PageCountType.default:
                return 'DEFAULT';
            case PageCountType.life:
                return 'LIFE';
            case PageCountType.equiv:
                return 'EQUIV';
            case PageCountType.nonEquiv:
                return 'NON-EQUIV';
            case PageCountType.sqFt:
                return 'SQ-FT';
            case PageCountType.lnFt:
                return 'LN-FT';
            case PageCountType.sqMt:
                return 'SQ-MTR';
            case PageCountType.lnMt:
                return 'LN-MTR';
            case PageCountType.lnKm:
                return 'LN-LM';
            case PageCountType.sqIn:
                return 'SQ-IN';
            case PageCountType.lnIn:
                return 'LN-IN';
            case PageCountType.sqCm:
                return 'SQ-CM';
            case PageCountType.lnCm:
                return 'LN-CM';
            case PageCountType.pages:
                return 'PAGES';
            case PageCountType.totalAreaClick:
                return 'TOTAL-AREA-CLICK';
            case PageCountType.centimeters:
                return 'CM';
            case PageCountType.scans:
                return 'SCANS';
            case PageCountType.faxes:
                return 'FAXES';
            case PageCountType.canon:
                return 'CANON';
            case PageCountType.engine:
                return 'ENGINE';
            case PageCountType.ml:
                return 'ML';
            case PageCountType.dev:
                return 'DEV';
            default:
                return '????';
        }
    }

    export function fromString(str: string): PageCountType {
        switch (str) {
            case 'default':
                return PageCountType.default;
            case 'life':
                return PageCountType.life;
            case 'equiv':
                return PageCountType.equiv;
            case 'non-equiv':
                return PageCountType.nonEquiv;
            case 'sqFt':
                return PageCountType.sqFt;
            case 'lnFt':
                return PageCountType.lnFt;
            case 'sqMt':
                return PageCountType.sqMt;
            case 'lnMt':
                return PageCountType.lnMt;
            case 'lnKm':
                return PageCountType.lnKm;
            case 'sqIn':
                return PageCountType.sqIn;
            case 'lnIn':
                return PageCountType.lnIn;
            case 'sqCm':
                return PageCountType.sqCm;
            case 'lnCm':
                return PageCountType.lnCm;
            case 'pages':
                return PageCountType.pages;
            case 'totalAreaClick':
                return PageCountType.totalAreaClick;
            case 'centimeters':
                return PageCountType.centimeters;
            case 'scans':
                return PageCountType.scans;
            case 'faxes':
                return PageCountType.faxes;
            case 'canon':
                return PageCountType.canon;
            case 'engine':
                return PageCountType.engine;
            case 'milliliters':
            case 'ml': // just in case
                return PageCountType.ml;
            case 'dev':
                return PageCountType.dev;
            default:
                return PageCountType.unknown;
        }
    }
}

enum PageCountMeterType {
    total = '[PageCountCounterType] total',
    totalBlack = '[PageCountCounterType] totalBlack',
    totalColor = '[PageCountCounterType] totalColor',
    totalPrints = '[PageCountCounterType] totalPrints',
    totalPrintsBlack = '[PageCountCounterType] totalPrintsBlack',
    totalPrintsColor = '[PageCountCounterType] totalPrintsColor',
    totalCopies = '[PageCountCounterType] totalCopies',
    totalCopiesBlack = '[PageCountCounterType] totalCopiesBlack',
    totalCopiesColor = '[PageCountCounterType] totalCopiesColor',
    totalFaxes = '[PageCountCounterType] totalFaxes',
    totalScans = '[PageCountCounterType] totalScans',
    unknown = '[PageCountCounterType] unknown'
}

namespace PageCountMeterType {
    export function toDbString(counter: PageCountMeterType): string {
        switch (counter) {
            case PageCountMeterType.total:
                return 'total';
            case PageCountMeterType.totalBlack:
                return 'totalBlack';
            case PageCountMeterType.totalColor:
                return 'totalColor';
            case PageCountMeterType.totalPrints:
                return 'totalPrints';
            case PageCountMeterType.totalPrintsBlack:
                return 'totalPrintsBlack';
            case PageCountMeterType.totalPrintsColor:
                return 'totalPrintsColor';
            case PageCountMeterType.totalCopies:
                return 'totalCopies';
            case PageCountMeterType.totalCopiesBlack:
                return 'totalCopiesBlack';
            case PageCountMeterType.totalCopiesColor:
                return 'totalCopiesColor';
            case PageCountMeterType.totalFaxes:
                return 'totalFaxes';
            case PageCountMeterType.totalScans:
                return 'totalScans';
            default:
                return 'unknown';
        }
    }

    // Spencer Fleming 9/15/20 - Usually these values have display names in the DB. But if we're not
    // accessing the DB, these can be used in their place.
    export function toDisplayString(counter: PageCountMeterType): string {
        switch (counter) {
            case PageCountMeterType.total:
                return 'Total';
            case PageCountMeterType.totalBlack:
                return 'Total Black';
            case PageCountMeterType.totalColor:
                return 'Total Color';
            case PageCountMeterType.totalPrints:
                return 'Total Prints';
            case PageCountMeterType.totalPrintsBlack:
                return 'Prints Black';
            case PageCountMeterType.totalPrintsColor:
                return 'Prints Color';
            case PageCountMeterType.totalCopies:
                return 'Total Copies';
            case PageCountMeterType.totalCopiesBlack:
                return 'Copies Black';
            case PageCountMeterType.totalCopiesColor:
                return 'Copies Color';
            case PageCountMeterType.totalFaxes:
                return 'Total Faxes';
            case PageCountMeterType.totalScans:
                return 'Total Scans';
            default:
                return 'Unknown';
        }
    }

    export function fromString(str: string): PageCountMeterType {
        switch (str) {
            case 'total':
                return PageCountMeterType.total;
            case 'totalBlack':
                return PageCountMeterType.totalBlack;
            case 'totalColor':
                return PageCountMeterType.totalColor;
            case 'totalPrints':
                return PageCountMeterType.totalPrints;
            case 'printsBlack':
            case 'totalPrintsBlack':
                return PageCountMeterType.totalPrintsBlack;
            case 'printsColor':
            case 'totalPrintsColor':
                return PageCountMeterType.totalPrintsColor;
            case 'totalCopies':
                return PageCountMeterType.totalCopies;
            case 'copiesBlack':
            case 'totalCopiesBlack':
                return PageCountMeterType.totalCopiesBlack;
            case 'copiesColor':
            case 'totalCopiesColor':
                return PageCountMeterType.totalCopiesColor;
            case 'totalFaxes':
                return PageCountMeterType.totalFaxes;
            case 'totalScans':
                return PageCountMeterType.totalScans;
            default:
                return PageCountMeterType.unknown;
        }
    }
}

export { PageCountType, PageCountMeterType };
