import { Injectable } from '@angular/core';
import { DeviceList } from '@app/models';
import {
    Device, DeviceHealthCheck, DeviceSupply, DynamicSettingsKeyType, EventList,
    EventResolutionStatus, ISettings, ISettingsFormSubmission, MeterRead, PaginatedList,
    TimeSeries,
    TimeSeriesSelector
} from '@libs/iso/core';
import { ITimeSeries } from '@libs/iso/core/models/meterRead/TimeSeries';
import {
    Adapter, Body, Get, HttpService,
    MapClass, Path,
    Post, Put, Query, ResponseType
} from '@libs/web/http';
import { Observable } from 'rxjs';

@Injectable()
export class DeviceService extends HttpService {
    @Get('/device')
    @MapClass(DeviceList)
    public getDeviceList(
        @Query('entityId') entityId: string,
        @Query('deviceId') deviceId?: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('excludeDisabled') excludeDisabled: boolean = false,
        @Query('excludeDroppedOff') excludeDroppedOff: boolean = false,
        @Query('localOnly') localOnly: boolean = false
    ): Observable<DeviceList> {
        return null;
    }

    @Get('/device/listCount')
    public getDeviceListCount(
        @Query('entityId') entityId: string,
        @Query('deviceId') deviceId?: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('excludeDisabled') excludeDisabled: boolean = false,
        @Query('excludeDroppedOff') excludeDroppedOff: boolean = false,
        @Query('localOnly') localOnly: boolean = false
    ): Observable<number> {
        return null;
    }

    @Get('/device/new')
    @MapClass(DeviceList)
    public getNewDeviceList(
        @Query('entityId') entityId: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean
    ): Observable<PaginatedList<Device>> {
        return null;
    }

    @Get('/device/proxyLink', ResponseType.Text)
    public getProxyLink(): Observable<string> {
        return null;
    }

    // Spencer 7/9/2020 - Service made specifically for the supertable.
    @Get('/device/list')
    public getNewList(
        @Query('entityId') entityKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('columns') columns?: Array<number>
    ): Observable<any> {
        return null;
    }

    @Get('/device/csv', ResponseType.Blob)
    public exportToCSV(
        @Query('entityId') entityKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('columns') columns?: Array<number>
    ): Observable<any> {
        return null;
    }

    @Get('/device/getDevicesByInstallKey')
    public getDevicesByInstallKey(@Query('id') installKey: string): Observable<Array<Device>> {
        return null;
    }

    @Get('/device/{id}')
    @MapClass(Device)
    public getDeviceById(@Path('id') deviceId: string): Observable<Device> {
        return null;
    }

    @Post('/device/{id}/proxyAuth', ResponseType.Text)
    public getProxyAuth(
        @Path('id') id: string,
        @Body('job') job: string,
        @Body('secret') secret: string
    ): Observable<string> {
        return null;
    }

    // DYNAMIC SETTINGS
    @Get('/device/{id}/settingsForm')
    public settingsGetFormData(
        @Path('id') id: string
    ): Observable<{
        settings: ISettings;
        inheritance: ISettings;
        pageCount: number;
        lastRead: Date;
    }> {
        return null;
    }

    /**
     * Resolve settings for a device
     * @param {string} id  device to resolve settings for
     * @returns {Observable<ISettings>}  resolved settings object
     */
    @Get('/device/{id}/settings')
    public settingsResolveOne(@Path('id') id: string): Observable<ISettings> {
        return null;
    }

    /**
     * Resolve settings for several devices simultaneously
     * @param {string[]} ids  devices to resolve settings for
     * @param {DynamicSettingsKeyType[]} settings  if non-empty, only these settings will be gathered
     * @returns {Object[]} List of id-settings pairs corresponding to the ids in 'ids'
     */
    @Post('/device/settings')
    public settingsResolveMany(
        @Body('ids') ids: string[],
        @Body('settings') settings: DynamicSettingsKeyType[] = []
    ): Observable<{ _id: string; settings: ISettings }[]> {
        return null;
    }

    /**
     * Finds device in DB matching 'id', and replaces contents of settings property with 'updates'.
     * @param {string} id  device to update
     * @param {object} updates  list of settings key-value pairs
     * @returns {Observable} status information resulting from DB operation (should we do this?)
     */
    @Put('/device/{id}/settings')
    public settingsSet(
        @Path('id') id: string,
        @Body('updates') updates: ISettingsFormSubmission
    ): Observable<any> {
        return null;
    }

    /**
     * Used by dgi builder to set DGI keys
     * @param {string[]} deviceKeys - devices to update
     * @param {string} dgiKey - new dgi key
     * @returns {Object} nothing important
     */
    @Put('/device/setDgiKey')
    public setDgiKey(
        @Body('deviceKeys') deviceKeys: string[],
        @Body('dgiKey') dgiKey: string
    ): Observable<any> {
        return null;
    }

    // END DYNAMIC SETTINGS

    @Put('/device/bulk/enable', ResponseType.Json)
    public bulkEnable(@Body('ids') ids: string[]): Observable<{ [key: string]: Array<string> }> {
        return null;
    }

    @Put('/device/bulk/disable', ResponseType.Json)
    public bulkDisable(@Body('ids') ids: string[]): Observable<{ [key: string]: Array<string> }> {
        return null;
    }

    @Put('/device/bulk/remove')
    public bulkRemove(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Put('/device/bulk/resetsettings')
    public bulkResetSettings(
        @Body('ids') ids: string[],
        @Query('dryRun') dryRun: boolean
    ): Observable<{ affectedCount: number }> {
        return null;
    }

    @Get('/device/{id}/alerts')
    @MapClass(EventList)
    public getDeviceAlerts(
        @Path('id') deviceId: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('resolutionStatus') resolutionStatus?: EventResolutionStatus
    ): Observable<any> {
        return null;
    }

    @Get('/device/{id}/history')
    public getDeviceHistory(
        @Path('id') id: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('includeAncestors') includeAncestors: boolean
    ): Observable<any> {
        return null;
    }

    @Get('/device/{id}/healthChecks')
    @MapClass(DeviceHealthCheck)
    public getDeviceHealthChecks(
        @Path('id') id: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string
    ): Observable<Array<DeviceHealthCheck>> {
        return null;
    }

    @Get('/device/{id}/export/history', ResponseType.Blob)
    public exportDeviceHistory(
        @Path('id') deviceId: string,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<any> {
        return null;
    }

    @Post('/device/installEmail')
    public requestInstallEmail(
        @Body('emails') emails: string[],
        @Body('entityKey') entityKey: string,
        @Body('entityName') entityName: string,
        @Body('emailsCc') cc: string[],
        @Body('customEmailBody') customEmailBody?: string
    ): Observable<any> {
        return null;
    }

    @Post('/device/{id}/update')
    public updateDevice(
        @Path('id') deviceId: string,
        @Body('update') updateObj: Partial<Device>
    ): Observable<any> {
        return null;
    }

    @Post('/device/{id}/labels')
    public updateLabels(
        @Path('id') deviceId: string,
        @Body('labels') updateObj: { labels: { [key: string]: string } }
    ): Observable<any> {
        return null;
    }

    @Get('/device/{id}/meterHistory')
    public getMeterHistoryByDeviceKeyAndDateRange(
        @Path('id') deviceId: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string
    ): Observable<Array<MeterRead>> {
        return null;
    }

    @Get('/device/getDevicesCsvByEntity', ResponseType.Blob)
    public getDevicesCsvByEntity(
        @Query('entityId') entityId: string,
        @Query('deviceId') deviceId?: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('excludeDisabled') excludeDisabled: boolean = false,
        @Query('excludeDroppedOff') excludeDroppedOff: boolean = false,
        @Query('localOnly') localOnly: boolean = false
    ): Observable<any> {
        return null;
    }

    @Put('/device/bulkUpdate')
    public bulkDeviceUpdate(@Body('devices') devices: Array<Partial<Device>>): Observable<any> {
        return null;
    }

    @Put('/device/{id}/setStartingPageCounts')
    public setStartingPageCounts(
        @Path('id') deviceKey: string,
        @Body('entityKey') entityKey: string,
        @Body('installKey') installKey: string,
        @Body('total') total: number,
        @Body('totalBlack') totalBlack: number,
        @Body('totalColor') totalColor: number
    ): Observable<any> {
        return null;
    }

    @Get('/device/{id}/countSimilarDevices', ResponseType.Text)
    public countSimilarDevices(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Get('/device/{id}/similarDevices')
    public getSimilarDevices(
        @Path('id') id: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<any> {
        return null;
    }

    @Get('/device/{id}/mostRecentMeterRead')
    public getMostRecentMeterRead(@Path('id') id: string): Observable<MeterRead> {
        return null;
    }

    @Get('/device/{id}/mostRecentTempMeterRead')
    public getMostRecentMeterTempRead(@Path('id') id: string): Observable<MeterRead> {
        return null;
    }

    // Returns an array of devices that would be duplicates if the install (and it's devices) were moved
    @Post('/device/move/validateInstallMove')
    public validateInstallMove(
        @Body('installKeys') installKeys: string[],
        @Body('destinationEntityKey') destinationEntityKey: string
    ): Observable<Array<Device>> {
        return null;
    }

    @Post('/device/{id}/timeSeriesForSelectors')
    @Adapter((res: { [key: string]: ITimeSeries }): { [key: string]: TimeSeries } => {
        const out: { [key: string]: TimeSeries } = {};
        for (const series of Object.keys(res)) {
            out[series] = new TimeSeries(res[series]);
        }
        return out;
    })
    public getTimeSeriesForSelectors(
        @Path('id') deviceKey: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Body('selectors') selectors: Array<TimeSeriesSelector>
    ): Observable<{ [key: string]: TimeSeries }> {
        return null;
    }

    @Post('/device/{id}/evaluate', ResponseType.Text)
    public evaluate(
        @Path('id') deviceKey: string,
        @Body('evaluate') evaluate: string
    ): Observable<any> {
        return null;
    }

    @Get('/device/{id}/supplies')
    public getSupplies(
        @Path('id') id: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<PaginatedList<DeviceSupply>> {
        return null;
    }

    @Get('/device/{id}/supplies/latestReplaced')
    public getLatestReplacedSupplies(
        @Path('id') id: string,
    ): Observable<Array<DeviceSupply>> {
        return null;
    }
}
