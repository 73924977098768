import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { SelectEntityOverlayRef } from '@app/models';
import { Entity } from '@app/models';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { Observable, Subscription, ReplaySubject } from 'rxjs';
import { RequestEntityChangeAction } from '@app/state/actions';
import { Permission } from '@libs/iso/core';
import { SearchBarComponent } from '../../components/search-bar/search-bar.component';
import { NotificationService } from '@app/core/services/notification.service';
import { EntityService } from '@app/core/services/entity.service';

@Component({
    selector: 'ptkr-select-entity-overlay',
    templateUrl: './select-entity-overlay.component.html',
    styleUrls: ['./select-entity-overlay.component.scss']
})
export class SelectEntityOverlayComponent implements OnInit, OnDestroy {
    private _searchBar: SearchBarComponent;
    private _searchSub: Subscription;
    @ViewChild(SearchBarComponent, {static: true})
    public set searchBar(value: SearchBarComponent) {
        if (!!value) {
            this._searchBar = value;
            if (!!this._searchSub) {
                this._searchSub.unsubscribe();
            }
            this._searchSub = this._searchBar.textChanged
                .pipe(
                    debounceTime(350),
                    distinctUntilChanged()
                )
                .subscribe(newVal => {
                    this.search(newVal);
                });
        }
    }
    public get searchBar(): SearchBarComponent {
        return this._searchBar;
    }

    private _entityResults: ReplaySubject<any> = new ReplaySubject<any>();

    public currentEntity: Entity = new Entity();
    public entityResults$: Observable<any> = this._entityResults.asObservable();
    public searched: boolean = false;
    public Permission: typeof Permission = Permission;

    constructor(
        private _store: Store<GlobalStore>,
        public overlayRef: SelectEntityOverlayRef,
        private _notificationService: NotificationService,
        private _entityService: EntityService
    ) {}

    ngOnInit(): void {
        this.resetEntityResults();
    }

    ngOnDestroy(): void {
        if (!!this._searchSub) {
            this._searchSub.unsubscribe();
        }
    }

    public setFocus(entity: { _id: string; name: string }): void {
        this._store.dispatch(new RequestEntityChangeAction(entity._id));
        this.searched = false;
    }

    public selectParent(entity: Entity): void {
        if (entity.breadcrumb.length > 0) {
            this.setFocus(entity.breadcrumb[entity.breadcrumb.length - 1]);
        }
    }

    public search(event: string): void {
        if (event.length === 0) {
            this.searched = false;
            this.resetEntityResults();
        } else if (event.length < 3) {
            this._notificationService.error($localize`Minimum of 3 characters required!`);
        } else {
            this.searched = true;
            this._entityService.searchChildren(this.currentEntity._id, event).subscribe(res => {
                this._entityResults.next(res);
            });
        }
    }

    public resetEntityResults() {
        this._store
            .pipe(
                select(fromEntity.currentEntity),
                tap(e => {
                    this.currentEntity = e;
                    this.searched = false;
                    if (!!this.searchBar) {
                        this.searchBar.reset();
                    }
                }),
                map(e => e.children)
            )
            .subscribe(children => this._entityResults.next(children));
    }
}
