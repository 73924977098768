// The ItemType enum is used mainly with the Items collection, but also Accumatica collection
//    If you modify this, be sure we do not need to keep the changes in sync with the SupplyType.ts
enum ItemType {
    Device = 'device',
    Ink = 'ink',
    LicenseFee = 'license fee',
    Micr = 'micr',
    Toner = 'toner',
    Drum = 'drum',
    Fuser = 'fuser',
    Transfer = 'transfer',
    MaintKit = 'maintenance kit',
    Misc = 'misc'
}

namespace ItemType {
    export function toString(type: ItemType): string {
        switch (type) {
            case ItemType.Device:
                return 'Device';
            case ItemType.Ink:
                return 'Ink';
            case ItemType.LicenseFee:
                return 'License Fee';
            case ItemType.Micr:
                return 'Micr';
            case ItemType.Toner:
                return 'Toner';
            case ItemType.Drum:
                return 'Drum';
            case ItemType.Fuser:
                return 'Fuser';
            case ItemType.Transfer:
                return 'Transfer';
            case ItemType.MaintKit:
                return 'Maintenance Kit';
            case ItemType.Misc:
                return 'Miscellaneous';
            default:
                return '';
        }
    }

    export function toAcumaticaType(type: ItemType): string {
        switch (type) {
            case ItemType.Device:
                return 'DEVICES'; // Yes plural
            case ItemType.Ink:
                return 'INK';
            case ItemType.LicenseFee:
                return 'MISC'; // ?
            case ItemType.Micr:
                return 'MICR';
            case ItemType.Toner:
                return 'TONER';
            case ItemType.Drum:
                return 'DRUM';
            case ItemType.Fuser:
                return 'FUSER';
            case ItemType.Transfer:
                return 'MISC';
            case ItemType.MaintKit:
                return 'MAINTKIT';
            case ItemType.Misc:
                return 'MISC';
            default:
                return '';
        }
    }

    export function asArray(): ItemType[] {
        return [
            ItemType.Device,
            ItemType.Ink,
            ItemType.LicenseFee,
            ItemType.Micr,
            ItemType.Toner,
            ItemType.Drum,
            ItemType.Fuser,
            ItemType.Transfer,
            ItemType.MaintKit
        ];
    }
}

export { ItemType };
