export interface ProfileOverlayConfig {
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
}

export const profileOverlayDefaultConfig: ProfileOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'profile-backdrop',
    panelClass: 'profile-overlay-panel'
};
