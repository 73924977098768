import { Injectable } from '@angular/core';
import { HttpService, Post, Body, MapClass, Get, Query, ResponseType } from '@libs/web/http';
import { combineLatest, Observable, of } from 'rxjs';
import {
    ExportDelimiter,
    QueryEngineOrderedResult,
    QueryEngineReportType,
    QueryEngineResult,
    QueryEngineStatus,
    QueryEngineTableSchema
} from '@libs/iso/core';
import { QueryEngineRequestWithHeaders } from '@libs/iso/core/models/queryEngine/QueryEngineRequest';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { GlobalStore } from '@app/state/store/global.store';
import { select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { fromEntity, fromUser } from '@app/state/selectors';

/**
 * This service contains a lot of private functions and then wrappers associated with those private
 * functions. The idea behind this approach is that for verbosity's sake we don't want to have to call
 * a serviceFunction(..., ..., ..., ...) with all the extra parameters. Instead, we pass a single
 * instance of a class to the public function, and the public function breaks out each parameter for
 * the private functions that decorate the HTTP request parameters.
 */
@Injectable()
export class QueryEngineService extends HttpService {
    constructor(
        protected _http: HttpClient,
        private _store: Store<GlobalStore>,
        private _notificationService: NotificationService
    ) {
        super(_http);
    }

    @Get('/queryEngine/status')
    @MapClass(QueryEngineStatus)
    private _getStatus(
        @Query('entityKey') entityKey: string,
        @Query('userKey') userKey: string,
        @Body('query') query: string,
        @Body('includeChildren') includeChildren: boolean,
        @Body('reportType') reportType: QueryEngineReportType,
        @Body('sampleSize') sampleSize: number = 0
    ): Observable<QueryEngineStatus> {
        return null;
    }

    /**
     * Returns the query engine status for a provided query engine request. The query engine will either
     * be running or not based on the entity id and the report type.
     * @param {QueryEngineRequestWithHeaders} request - The query engine request
     * @returns {Observable<QueryEngineStatus>} - The query engine status
     */
    public getStatus(request: QueryEngineRequestWithHeaders): Observable<QueryEngineStatus> {
        return this._getStatus(
            request.entityKey,
            request.userKey,
            request.query,
            request.includeChildren,
            request.reportType,
            request.sampleSize
        );
    }

    @Post('/queryEngine/query')
    @MapClass(QueryEngineResult)
    private _query<T>(
        @Query('entityKey') entityKey: string,
        @Query('userKey') userKey: string,
        @Body('query') query: string,
        @Body('includeChildren') includeChildren: boolean,
        @Body('reportType') reportType: QueryEngineReportType,
        @Body('ordered') ordered: boolean = false,
        @Body('sampleSize') sampleSize: number = 0,
        @Body('payload') payload?: any
    ): Observable<QueryEngineResult<T>> {
        return null;
    }

    /**
     * Runs an arbitrary query against a query engine. A query engine is created if it does not exist
     * which may result in some additional runtime on the first query.
     * @param {QueryEngineRequestWithHeaders} request - The query request to run against the query engine
     * @returns {Observable<QueryEngineResult<T>>} - The query results with performance metrics.
     */
    public query<T>(request: QueryEngineRequestWithHeaders): Observable<QueryEngineResult<T>> {
        return this._query<T>(
            request.entityKey,
            request.userKey,
            request.query,
            request.includeChildren,
            request.reportType,
            request.ordered,
            request.sampleSize,
            request.payload
        );
    }

    public queryCurrent<T>(
        request: Partial<QueryEngineRequestWithHeaders>
    ): Observable<QueryEngineResult<T> | QueryEngineOrderedResult<T>> {
        return combineLatest([
            this._store.pipe(select(fromEntity.entityId), take(1)),
            this._store.pipe(select(fromUser.userId), take(1))
        ]).pipe(
            switchMap(([entityId, userId]) =>
                this._query<T>(
                    entityId,
                    userId,
                    request.query,
                    request.includeChildren,
                    request.reportType,
                    request.ordered,
                    request.sampleSize,
                    request.payload
                )
            )
        );
    }

    @Post('/queryEngine/shutdown')
    @MapClass(QueryEngineResult)
    private _shutdown(
        @Query('entityKey') entityKey: string,
        @Query('userKey') userKey: string,
        @Body('query') query: string,
        @Body('includeChildren') includeChildren: boolean,
        @Body('reportType') reportType: QueryEngineReportType,
        @Body('ordered') ordered: boolean = false,
        @Body('sampleSize') sampleSize: number = 0,
        @Body('payload') payload?: any
    ): Observable<void> {
        return null;
    }

    /**
     * Requests that a running query engine that is compatible with the provided request be shut down
     * @param {Partial<QueryEngineRequestWithHeaders>} request - The query engine request used to
     * identify which query engine should be shut down.
     * @returns {Observable<void>} - An observable for the completion of the shutdown
     */
    public shutdownCurrent(request: Partial<QueryEngineRequestWithHeaders>): Observable<void> {
        return combineLatest([
            this._store.pipe(select(fromEntity.entityId), take(1)),
            this._store.pipe(select(fromUser.userId), take(1))
        ]).pipe(
            switchMap(([entityId, userId]) =>
                this._shutdown(
                    entityId,
                    userId,
                    request.query,
                    request.includeChildren,
                    request.reportType,
                    request.ordered,
                    request.sampleSize,
                    request.payload
                )
            ),
            catchError(err => {
                this._notificationService.error(err.statusText);
                return of(err);
            })
        );
    }

    @Post('/queryEngine/download', ResponseType.Blob)
    private _download(
        @Query('entityKey') entityKey: string,
        @Query('userKey') userKey: string,
        @Body('query') query: string,
        @Body('includeChildren') includeChildren: boolean,
        @Body('reportType') reportType: QueryEngineReportType,
        @Body('ordered') ordered: boolean = false,
        @Body('sampleSize') sampleSize: number = 0,
        @Body('delimiter') delimiter: ExportDelimiter = ExportDelimiter.Comma,
        @Body('payload') payload?: any
    ): Observable<any> {
        return null;
    }

    /**
     * Exports the provided query engine request to CSV
     * @param {QueryEngineRequestWithHeaders} request - The query engine request
     * @returns {Observable} - An empty observable
     */
    public download(
        request: QueryEngineRequestWithHeaders & { delimiter: ExportDelimiter }
    ): Observable<any> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        return this._download(
            request.entityKey,
            request.userKey,
            request.query,
            request.includeChildren,
            request.reportType,
            request.ordered,
            request.sampleSize,
            request.delimiter,
            request.payload
        ).pipe(
            map(res => {
                this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                const blob = new Blob([res], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.download = `${request.reportType}-for-entity-${
                    request.entityKey
                }_${new Date().toISOString()}.csv`;
                link.href = url;
                link.click();
            }),
            catchError(err => {
                this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                this._notificationService.error(err.statusText);
                return of(null);
            })
        );
    }

    /**
     * Downloads the provided query engine request based on the currently selected entity.
     * @param {Partial<QueryEngineRequestWithHeaders>} request - A query engine request containing
     * at least all the required fields except for entityKey and userKey which are added by this
     * function.
     * @returns {Observable<any>} - A subcription to the completion of this process
     */
    public downloadCurrent(
        request: Omit<QueryEngineRequestWithHeaders, 'entityKey' | 'userKey' | 'sampleSize'> & {
            delimiter: ExportDelimiter;
        }
    ): Observable<any> {
        return combineLatest([
            this._store.pipe(select(fromEntity.entityId), take(1)),
            this._store.pipe(select(fromUser.userId), take(1))
        ]).pipe(
            switchMap(([entityId, userId]) =>
                this.download({
                    ...request,
                    entityKey: entityId,
                    userKey: userId,
                    sampleSize: 0
                })
            )
        );
    }
}
