<!--The billing meter preference chip itself-->
<mat-chip
    [removable]="true"
    [selectable]="false"
    [matMenuTriggerFor]="counterOptions"
    (removed)="removed.emit(counter.key)"
>
    <div class="spacer">{{counter.displayName}}</div>
    <div class="spacer canon-numbered-meter" *ngIf="showCanonMappings$ | async">{{getPrintableCanonMeters()}}</div>
    <div class="label offset-label" *ngIf="counter.offset">
        <span *ngIf="counter.offset > 0">+</span>
        {{counter.offset | number}}
    </div>
    <mat-icon matChipRemove>cancel</mat-icon>
</mat-chip>

<!--Pop-up menu for configuring counter options-->
<mat-menu #counterOptions="matMenu">
    <div class="notification-banner-container" *ngIf="!offsetEnabled">
        <div class="notification-banner">
            <small i18n>Offsets are configured for specific devices.</small>
        </div>
    </div>
    <button mat-menu-item
        *ngIf="!counter.offset"
        #editOffsetTrigger="matMenuTrigger"
        [matMenuTriggerFor]="editOffset"
        [disabled]="!offsetEnabled"
        i18n
    >Add offset</button>
    <button mat-menu-item
        *ngIf="counter.offset"
        #editOffsetTrigger="matMenuTrigger"
        [matMenuTriggerFor]="editOffset"
        [disabled]="!offsetEnabled"
        i18n
    >Edit offset</button>
    <button mat-menu-item
        (click)="removeOffset()"
        *ngIf="counter.offset"
        [disabled]="!offsetEnabled"
        i18n
    >Remove offset</button>
</mat-menu>

<!--Pop-up menu for configuring the counter's offset-->
<mat-menu #editOffset="matMenu" [hasBackdrop]="false">
    <mat-form-field id="offset" appearance="outline" (click)="$event.stopPropagation()">
        <mat-label i18n>Offset</mat-label>
        <input matInput type="number"
               autocomplete="off"
               placeholder="Press 'enter' to confirm"
               i18n-placeholder
               [ngModel]="counter.offset"
               (keyup.enter)="changeOffset($event.target.value)"
               (keyup.tab)="changeOffset($event.target.value)">
        <button mat-button *ngIf="counter.offset" matSuffix mat-icon-button aria-label="Clear" (click)="removeOffset()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <div class="hint" i18n>
        An offset is a number that is added to or subtracted from this counter's value in every meter read. It can be used to adjust for circumstances where the device counters were affected by a hardware reset.
    </div>
</mat-menu>
