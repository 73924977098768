import { Component, OnInit, Input, ChangeDetectorRef, Output, AfterViewInit, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { EntityService } from '@app/services';
import { debounceTime } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Entity } from '../../../../../../libs/iso/core';

@Component({
    selector: 'ptkr-entity-picker',
    templateUrl: './entity-picker.component.html',
    styleUrls: ['./entity-picker.component.scss']
})
export class EntityPickerComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('entityInput', { static: false }) private _entityInput: ElementRef;
    // If set, this text will display even if a default entity is picked
    @Input()
    public placeholder: string = null;

    // If set, entity will display when no chip is present.
    @Input()
    private defaultEntity: any = null;

    @Input()
    private selectedEntity: Entity;

    @Output()
    private entity: Subject<any>;

    public controller: FormGroup = new FormGroup({
        entity: new FormControl('')
    });
    public names: string[] = [];
    public locked: boolean = false;

    private _controlSub: Subscription;
    private _limit: number = 5;
    private _suggestions: Array<any>;

    constructor(
        private _entityService: EntityService,
        private _cd: ChangeDetectorRef
    ) {
        this.entity = new Subject<any>();
    }

    ngOnInit(): void {
        // Update entities when textbox value changes.
        this._controlSub = this.controller.controls['entity'].valueChanges
            .pipe(debounceTime(400))
            .subscribe(val => {
                if (!!val && val.length >= 3) {
                    this._entityService
                        .searchDescendants(val, this._limit)
                        .subscribe(res => {
                            const newEntityList = [];
                            res.forEach(entity => {
                                newEntityList.push({
                                    _id: entity.id,
                                    name: entity.name,
                                    breadcrumb: this._makeBreadcrumb(entity.name, entity.breadcrumb)
                                });
                            });
                            this._suggestions = newEntityList;
                            this._cd.detectChanges();
                        });
                }
            });
    }

    ngAfterViewInit(): void {
        // emit default entity, to deal with that cornercase.
        if (this.defaultEntity) {
            this.entity.next(this.defaultEntity);
        }
    }

    ngOnDestroy(): void {
        this._controlSub.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['selectedEntity'] && this.selectedEntity) {
            this.onSuggestionSelected(this.selectedEntity);
        }
    }

    public onSuggestionSelected(entity: any): void {
        this.names = [entity.name];
        this.locked = true;
        if(this._entityInput) {
            this._entityInput.nativeElement.value = '';
        }
        this.entity.next(entity);
    }

    private _makeBreadcrumb(name: string, genealogy: Array<string>): string {
        let breadcrumb = '';
        genealogy.forEach(entity => {
            breadcrumb = breadcrumb.concat(entity + ' > ');
        });
        breadcrumb = breadcrumb.concat(name);
        return breadcrumb;
    }

    public removeChip(): void {
        this.names = [];
        this.locked = false;
        this.entity.next(this.defaultEntity); // Which is null, if unspecified
    }
}
