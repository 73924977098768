<div mat-dialog-header class="header">
    <h2>{{ data.title }}</h2>
</div>
<div mat-dialog-content class="content">
    <p>{{ data.content }}</p>
    <p *ngIf="data.secondaryContent">{{ data.secondaryContent }}</p>
</div>
<div mat-dialog-actions class="actions">
    <button mat-flat-button color="primary" (click)="dialogRef.close('confirm1')">{{ data.buttons.confirm1 }}</button>
    <button mat-flat-button color="primary" (click)="dialogRef.close('confirm2')">{{ data.buttons.confirm2 }}</button>
    <button mat-flat-button color="accent" (click)="dialogRef.close('cancel')">{{ data.buttons.cancel }}</button>
</div>
