import { Item } from '@libs/iso/core/models';

export interface DeviceAddManuallyModalConfig {
    itemList?: Item[];
    panelClass?: string;
}

export const deviceAddManuallyModalDefaultConfig: DeviceAddManuallyModalConfig = {
    itemList: new Array<Item>(),
    panelClass: 'add-device-manually-overlay-panel'
};
