
<div *ngIf="node" class="tree-node-wrapper clickCheatEntities" droppable [dropScope]="okayToDrop" (onDrop)="onItemDrop($event)">
    <span (click)="expand()" style="cursor: pointer;" class="clickCheatEntities" [ngClass]="node.loaded && node.children.length == 0 ? 'no-children' : ''">
        <mat-icon *ngIf="node.expanded && (!node.loaded || node.children.length > 0)" class="clickCheatEntities">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="!node.expanded && (!node.loaded || node.children.length > 0)" class="clickCheatEntities">keyboard_arrow_right</mat-icon>
        <span style="display:inline-block; width: 24px;" *ngIf="node.loaded && node.children.length == 0">&nbsp;</span>
    </span>
    <span *ngIf="node" (click)="click()" style="cursor: pointer; position: relative; top: -5px" class="h2 lightweight clickCheatEntities">
        {{ node.label }} &nbsp;
        <mat-progress-spinner diameter="15" *ngIf="node.loading" class="spinner clickCheatEntities" color="accent" mode="indeterminate"></mat-progress-spinner>
    </span>

    <ul class="node-children clickCheatEntities" *ngIf="node.expanded">
        <li *ngFor="let child of node.children" class="clickCheatEntities" draggable [dragData]="child" (onDragStart)="onDragStart($event)" (onDragEnd)="onDragEnd($event)">
            <div>
                <ptkr-tree-node [node]="child" class="clickCheatEntities" (nodeClick)="childClick($event)" (nodeExpand)="childExpand($event)" (nodeMove)="childMove($event)"></ptkr-tree-node>
            </div>
        </li>
    </ul>
</div>