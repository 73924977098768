import { Injectable } from '@angular/core';
import {
    HttpService,
    Get,
    Query,
    Path,
    MapClass,
    Put,
    Body,
    Post,
    Adapter,
    ResponseType
} from '@libs/web/http';
import { Observable } from 'rxjs';
import { InvoiceStatus, Invoice, InvoiceLineItem, PaginatedList } from '@libs/iso/core';

@Injectable()
export class InvoiceService extends HttpService {
    @Get('/invoice')
    // tslint:disable-next-line:ter-prefer-arrow-callback
    @Adapter(function(res): PaginatedList<InvoiceLineItem> {
        const list = new PaginatedList<InvoiceLineItem>();
        list.totalDocs = res.totalDocs;
        if (Array.isArray(res.docs)) {
            for (const d of res.docs) {
                list.docs.push(new InvoiceLineItem(d));
            }
        }
        return list;
    })
    public getInvoiceList(
        @Query('searchTerm') searchTerm: string,
        @Query('invoiceStatus') invoiceStatus: InvoiceStatus,
        @Query('page') page: number,
        @Query('limit') limit: number,
        @Query('sortBy') sort: string,
        @Query('sortOrder') sortOrder: number,
        @Query('includeSubEntities') includeSubEntities: boolean,
        @Query('entityId') entityId: string
    ): Observable<PaginatedList<InvoiceLineItem>> {
        return null;
    }

    @Get('/invoice/salesOrder/{id}')
    @MapClass(Invoice)
    public getSalesOrderById(
        @Path('id') id: string,
        @Query('sig') sig: string
    ): Observable<Invoice> {
        return null;
    }

    @Get('/invoice/{id}')
    @MapClass(Invoice)
    public getInvoiceById(@Path('id') id: string, @Query('sig') sig: string): Observable<Invoice> {
        return null;
    }

    @Get('/invoice/{id}/signature', ResponseType.Text)
    public getInvoiceSignature(@Path('id') id: string): Observable<string> {
        return null;
    }

    @Get('/invoice/salesOrder/{id}/signature', ResponseType.Text)
    public getSalesOrderSignature(@Path('id') id: string): Observable<string> {
        return null;
    }

    @Put('/invoice/{id}/status')
    public updateInvoiceStatusById(
        @Path('id') id: string,
        @Body('status') status: InvoiceStatus
    ): Observable<{ success: boolean }> {
        return null;
    }

    @Put('invoice/bulk/status')
    public updateInvoiceStatusByIdList(
        @Body('ids') ids: string[],
        @Body('status') status: InvoiceStatus
    ): Observable<{ success: boolean }> {
        return null;
    }

    @Post('/invoice/{id}/resend')
    public sendInvoiceEmail(@Path('id') id: string): Observable<any> {
        return null;
    }
}
