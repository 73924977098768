import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';

export interface Counter {
    displayName: string;
    key: string;
    offset?: number;
    // If this counter supports canon numbered meters, then each of those numbers will be provided here
    canonNumberedMeters?: number[];
}

@Component({
    selector: 'ptkr-bmp-counter-chip',
    templateUrl: './bmp-counter-chip.component.html',
    styleUrls: ['./bmp-counter-chip.component.scss']
})
export class BMPCounterChipComponent implements OnInit {
    @ViewChild(MatMenuTrigger) public editOffsetTrigger: MatMenuTrigger;
    @ViewChild(MatMenuTrigger) public counterOptionsTrigger: MatMenuTrigger;

    @Input() public counter: Counter;

    @Input()
    public set showCanonMappings(show: boolean) {
        this.showCanonMappings$.next(show);
    }

    @Input() public offsetEnabled: boolean = false;

    @Output() public removed: EventEmitter<string> = new EventEmitter<string>();

    @Output() public offsetUpdated: EventEmitter<Counter> = new EventEmitter<Counter>();

    public showCanonMappings$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    public showOffsetActions: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    public addOffset(offset: number): void {
        this.counter.offset = offset;
        this.onUpdateOffset();
    }

    public removeOffset(): void {
        this.counter.offset = null;
        this.onUpdateOffset();
    }

    public getPrintableCanonMeters(): string {
        let str = '';
        const canons = this.counter.canonNumberedMeters;
        if (!canons) {
            return;
        }
        for (let i = 0; i < canons.length; i++) {
            // if we're on the last canon meter
            if (i === canons.length - 1) {
                str = str + canons[i];
            } else {
                str = str + canons[i] + ', ';
            }
        }
        return str;
    }

    public changeOffset(event: any): void {
        if (event == null) {
            return;
        }
        this.counter.offset = event;
        this.editOffsetTrigger.closeMenu();
        this.onUpdateOffset();
    }

    public onUpdateOffset(): void {
        this.offsetUpdated.emit(this.counter);
    }
}
