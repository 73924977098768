import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'ptkr-filter-select-control',
    templateUrl: './filter-select-control.component.html',
    styleUrls: ['./filter-select-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FilterSelectControlComponent),
            multi: true
        }
    ]
})
export class FilterSelectControlComponent<T extends object> implements ControlValueAccessor {
    @Input()
    public optionContent: PolymorpheusContent<T>;

    @Input()
    public noSelectionContent: PolymorpheusContent<never>;

    @Input()
    public options: Array<T>;

    @Input()
    public disabled: boolean = false;

    @Input()
    public filterText: string = '';
    @Output()
    public filterTextChange: EventEmitter<string> = new EventEmitter<string>();

    public get showDropdown(): boolean {
        return this._showDropdown;
    }
    public set showDropdown(value: boolean) {
        if (!value) {
            this._onTouched();
        }
        this._showDropdown = value;
    }
    private _showDropdown: boolean = false;
    public selection: T;

    constructor() {}

    public onOptionClick(event: T): void {
        this.selection = event;
        this.showDropdown = false;
        this._onChange(event);
    }

    public onFilterTextChange(event: string): void {
        this.filterTextChange.emit(event);
    }

    // Control Value Accessor Functions
    private _onChange: (_: any) => any = () => {};
    private _onTouched: () => any = () => {};

    public writeValue(obj: T): void {
        this.selection = obj;
    }

    public registerOnChange(fn: (_: any) => any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => any): void {
        this._onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
