import { AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

/**
 * InheritanceFormControl extends FormControl to allow each control to keep track of a value
 * representing the parent's value for this control, as well as whether this control is currently
 * inheriting that value or not.
 * @see FormControl
 */
export class InheritanceFormControl extends FormControl {
    /**
     * The reset buttons subscribe to this behavior subject to determine
     * what state to be in, if possible.
     */
    public $inherited: BehaviorSubject<boolean>;

    /**
     * This is the state that is inherited when no value is specified for this control.
     * Under the hood, when the control is reverted, we assign it this value-- But we know that
     * when it's time to collect data for submission, that we will provide 'undefined' instead
     * of the inherited state value.
     * As soon as the controls' value gets modified from this state, it returns to the natural
     * state where we provide the control's value when requested for submission.
     */
    public readonly inheritedState: any;

    // Simple way to track state behind-the-scenes.
    private _reverting: boolean;
    private readonly _wasInherited: boolean;

    /**
     * @param {any} formState state of the control
     * @param {any} inheritedState state the control would be if value was inherited
     * @param {ValidatorFn | AbstractControlOptions | ValidatorFn[]} validatorOrOps from formControl
     * @param {AsyncValidatorFn | AsyncValidatorFn[]} asyncValidator from formControl
     * @returns {InheritanceFormControl} new instance
     * @see FormControl.constructor
     */
    constructor(
        formState: any = null,
        inheritedState: any,
        validatorOrOps?: ValidatorFn | AbstractControlOptions | ValidatorFn[],
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
    ) {
        // Calculate initial state (inherited or not inherited)
        const inherited: boolean = formState === null || formState === undefined;
        if (inherited) {
            if (Array.isArray(inheritedState)) {
                // This keeps the inherited state array from being modified by the control.
                formState = [...inheritedState];
            } else {
                formState = inheritedState;
            }
        }
        super(formState, validatorOrOps, asyncValidator);
        this.inheritedState = inheritedState;
        this.$inherited = new BehaviorSubject(inherited);
        this._reverting = false;
        this._wasInherited = inherited;
        this.valueChanges.subscribe(() => {
            if (this._reverting) {
                this.$inherited.next(true);
                this._reverting = false;
            } else {
                this.$inherited.next(false);
            }
        });
    }

    public static isInheritanceFormControl(control: any): control is InheritanceFormControl {
        return (
            typeof control === 'object' &&
            typeof (control as InheritanceFormControl).revert === 'function'
        );
    }

    /**
     * Reset buttons call this when clicked, ideally.
     * @returns {void}
     */
    public revert(): void {
        this._resetValue();
    }

    /**
     * This provides the value that should be uploaded into the database
     */
    public get childValue(): any {
        return this.value;
    }

    protected _resetValue(resetTo: any = this.inheritedState): void {
        this._reverting = true;
        if (Array.isArray(resetTo)) {
            this.setValue([...resetTo]);
        } else {
            this.setValue(resetTo);
        }
        if (this._wasInherited) {
            this.markAsPristine();
        } else {
            this.markAsDirty();
        }
    }
}
