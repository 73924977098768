import { User } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '@app/services';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class UserByIdResolver implements Resolve<User> {
    constructor(private _userService: UserService, private _store: Store<GlobalStore>) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        return this._userService.getDetails(route.params['userId']);
    }
}
