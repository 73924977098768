import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxStripeModule } from 'ngx-stripe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core/core.module';
import { APP_INITIALIZER } from '@angular/core';
import { Store } from '@ngrx/store';
import { initializer } from '@app/core/initializers/initializer';
import { AuthService, UserService, StorageService, StripeService } from '@app/services';
import { environment } from '@app/env/environment';
import { NgxEchartsModule } from 'ngx-echarts';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { datadogRum } from '@datadog/browser-rum';
import * as echarts from 'echarts';
import { CookieModule } from 'ngx-cookie';

const monacoConfig: NgxMonacoEditorConfig = {
    baseUrl: './assets',
    defaultOptions: { scrollBeyondLastLine: true },
    onMonacoLoad: (): void => {
        (window as any).monaco.editor.setTheme('vs');
    }
};

// datadogRum.init({
//     applicationId: '456e837c-afc9-49b0-a5e9-5197e0fb6cf3',
//     clientToken: 'puba549622b247189928e8dd72970240499',
//     site: 'datadoghq.com',
//     service: 'webadmin',
//     // Support env one we're building the correct environment in the docker image
//     // env: environment.production ? 'prod' : 'dev',
//     env: 'prod',
//     // Specify a version number to identify the deployed version of your application in Datadog
//     // version: '1.0.0',
//     sessionSampleRate: 100,
//     trackUserInteractions: true,
//     defaultPrivacyLevel: 'allow',
//     traceSampleRate: 0,
// });
// datadogRum.startSessionReplayRecording();

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        NgxStripeModule.forRoot(environment.stripeKey),
        NgxEchartsModule.forRoot({
            echarts
        }),
        MonacoEditorModule.forRoot(monacoConfig),
        CookieModule.forRoot({
            domain: getCookieDomain(),
            path: '/',
            secure: false
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            deps: [AuthService, UserService, Store, StorageService, StripeService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

/**
 * Returns the following given:
 * app.printtrackerpro.com => printtrackerpro.com
 * app.dev.printtrackerpro.com => dev.printtrackerpro.com
 *
 * which allows us to share cookies between different subdomains in the same environment like
 * app.printtrackerpro.com => app-v2.printtrackerpro.com
 * app.dev.printtrackerpro.com => app-v2.dev.printtrackerpro.com
 *
 * @returns {string} The cookie domain
 */
function getCookieDomain(): string {
    if (window.location.hostname === 'localhost') {
        return window.location.hostname;
    }
    return /(dev\.)?printtrackerpro\.com/i.exec(window.location.host)[0];
}
