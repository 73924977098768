<form [formGroup]="controller">
    <mat-form-field appearance="outline">
        <mat-icon matPrefix style='opacity:0.24'>search</mat-icon>
        <mat-chip-list #chips>
            <mat-chip class="chip" *ngFor="let name of names" color="primary" (removed)="removeChip()">
                {{ name }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <div class="inputwrapper" *ngIf="!locked">
            <input matInput #entityInput [matAutocomplete]="auto" formControlName="entity" [placeholder]="placeholder || defaultEntity?.name || ''">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                    class="hierarchyautofill"
                    *ngFor="let entity of this._suggestions"
                    (onSelectionChange)="onSuggestionSelected(entity)"
                    [value]="'aaaaaa'"
                    matTooltip="{{entity.breadcrumb}}"
                    matTooltipShowDelay=1000>
                    <div class="row-container">
                        <div class="left">
                            <span><b>{{ entity.name }}</b></span><br>
                            <span><small>{{ entity.breadcrumb }}</small></span>
                        </div>
                        <div class="right">
                            <div class="label"><small>{{entity._id | slice:-6}}</small></div>
                        </div>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>
        <ng-content select="[mat-error]"></ng-content>
    </mat-form-field>
</form>
