import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { OverlayService } from '@app/services';
import { DeviceSkipAlert, IDeviceSkipAlert } from '@libs/iso/core/models/device/DeviceSkipAlert';
import { DeviceSkipAlertHistoryItem } from '@libs/iso/core/models/device/DeviceSkipAlertHistoryItem';
import { IMeterReadSupply } from '@libs/iso/core/models/meterRead/MeterReadSupply';

@Component({
    selector: 'ptkr-skip-alert-control',
    templateUrl: './skip-alert-control.component.html',
    styleUrls: ['./skip-alert-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SkipAlertControlComponent,
            multi: true
        }
    ]
})
export class SkipAlertControlComponent implements ControlValueAccessor {
    /**
     * This should be provided a MeterReadSupply object from a device's most recent meter
     * read, which is usually stored in 'device.currentMeterRead'.
     * @see IMeterReadSupply
     */
    @Input()
    public get supplies(): IMeterReadSupply {
        return this._supplies;
    }

    public set supplies(value: IMeterReadSupply) {
        this._supplies = value;
        this.selectedAlert = undefined;
        this._supplyMap = SkipAlertControlComponent._createSupplyNameMap(value);
    }

    public value: IDeviceSkipAlert[] = [];
    public dataSource: MatTableDataSource<{ alert: IDeviceSkipAlert }> = new MatTableDataSource<{
        alert: IDeviceSkipAlert;
        control: FormControl;
    }>();
    public selectedAlert: IDeviceSkipAlert;
    public readonly columns: string[] = ['supply', 'skipsRemaining', 'lastSkip', 'actions'];

    public get supplyMap(): { path: string; displayName: string }[] {
        return this._supplyMap;
    }

    /**
     * Value is generated whenever the supplies angular input is updated. Contains pairs of
     * paths and displayNames, and uses the path as a displayName if no displayName is present.
     * @see _createSupplyNameMap
     * @see supplies
     */
    private _supplyMap: { path: string; displayName: string }[] = [];
    private _supplies: IMeterReadSupply;

    /**
     * Maps all paths to supply types in 'supplies' to their respective display names, with the path being
     * used when no displayName is present.
     * @private
     * @param {IMeterReadSupply} supplies                  supplies info from a meter read
     * @returns {{ path: string, displayName: string }[]}  mapping of paths to supply values to displayNames
     */
    private static _createSupplyNameMap(
        supplies: IMeterReadSupply
    ): { path: string; displayName: string }[] {
        const supplyMap: { path: string; displayName: string }[] = [];
        for (const i in supplies) {
            if (supplies.hasOwnProperty(i)) {
                if (
                    supplies[i].hasOwnProperty('type') &&
                    supplies[i].hasOwnProperty('color') &&
                    !!supplies[i].color?.value &&
                    !!supplies[i].type?.value
                ) {
                    supplyMap.push({
                        path: 'supplies.' + i,
                        displayName:
                            supplies[i]?.desc?.value
                            || (supplies[i].color.value + ' ' + supplies[i].type.value)
                    });
                }
            }
        }
        return supplyMap;
    }

    /**
     * Used to generate and update table data.
     * @private
     * @param {IDeviceSkipAlert[]} value  data from which to derive a new DataSource object
     * @returns {object} a new data object for the MatTable
     */
    private static _createTable(value: IDeviceSkipAlert[]): { alert: IDeviceSkipAlert }[] {
        const tableData: { alert: IDeviceSkipAlert }[] = [];
        for (let i = 0; i < value.length; i++) {
            const row: { alert: IDeviceSkipAlert } = {
                alert: value[i]
            };
            tableData.push(row);
        }
        return tableData;
    }

    constructor(private _overlayService: OverlayService, private _cd: ChangeDetectorRef) {}

    private _onChange: (obj: any) => void = () => {};
    private _onTouched: () => void = () => {};

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public writeValue(obj: any): void {
        this._setValue(obj);
    }

    public getSupplyName(path: string): string {
        return this._supplyMap.find(
            (v: { path: string; displayName: string }): boolean => v.path === path
        )?.displayName;
    }

    public getLastSkip(alert: IDeviceSkipAlert): Date {
        return alert.lastSkip > -1 ? alert.history[alert.lastSkip]?.timestamp : undefined;
    }

    public openSkipAlertHistory(alert: IDeviceSkipAlert): void {
        this._overlayService.openSkipAlertsHistoryModal(this.getSupplyName(alert.path), alert);
    }

    public hasSupplies(): boolean {
        return !!this.supplies && Object.keys(this.supplies).length > 0;
    }

    public openDeleteModal(alert: IDeviceSkipAlert): void {
        this._overlayService
            .openConfirmationModal({
                title: 'Delete skip alert"',
                content: `This will delete the skip alert for "${this.getSupplyName(alert.path)}". This cannot be undone.`,
                secondaryContent: 'Do you wish to continue?',
                buttons: {
                    confirm: 'Yes, delete it',
                    cancel: 'Cancel'
                }
            })
            .afterClosed()
            .subscribe(res => {
                if (res === true) {
                    const i: number = this.value.indexOf(alert);
                    if (i !== -1) {
                        this.value.splice(i, 1);
                        this._setValue([...this.value], true);
                    }
                }
            });
    }

    public createSkipAlert(path: string): void {
        this._setValue([...this.value, new DeviceSkipAlert({ path: path })], true);
    }

    public updateAlert(alert: IDeviceSkipAlert, value: string): void {
        const index: number = this.value.findIndex(v => v === alert);
        if (index !== -1) {
            const valAsNumber = parseInt(value);
            this.value[index].skipsRemaining = valAsNumber;
            this.value[index].skips = valAsNumber;
            // When the form is submitted, this value will be added to the actual history
            // and then deleted. This is so there is only one recorded change to the skip alerts.
            (this.value[index] as any).tempHistory = new DeviceSkipAlertHistoryItem({
                timestamp: new Date(),
                skipsRemaining: this.value[index].skipsRemaining,
                comment: 'Changed skips remaining'
            });
            this._setValue([...this.value], true);
            console.log(this.value[index]);
        }
    }

    /**
     * Gets a supply list where the supplies that already have skip alerts are removed, for the
     * add supply button.
     * @returns {({ path: string, displayName: string })}  supply list
     */
    public getSuppliesMinusSkipped(): { path: string; displayName: string }[] {
        // Filters out any supplies whose paths match the path of any alert in the skip alerts.
        return this.supplyMap.filter(supply => !this.value.find(alert => alert.path === supply.path));
    }

    /**
     * Gets a supply list only including supplies that are included in skip alerts for the add
     * supply button. These buttons will be disabled.
     * @returns {({ path: string, displayName: string })} supply list
     */
    public getSuppliesOnlySkipped(): { path: string; displayName: string }[] {
        return this.supplyMap.filter(supply => !!this.value.find(alert => alert.path === supply.path));
    }

    private _setValue(obj: any, triggerOnChanged: boolean = false): void {
        if (Array.isArray(obj)) {
            this.value = [];
            obj.forEach(v => {
                const alert = new DeviceSkipAlert(v);
                if (!!v.tempHistory) {
                    (alert as any).tempHistory = v.tempHistory;
                }
                this.value.push(alert);
            });
            this.dataSource.data = SkipAlertControlComponent._createTable(this.value);
            if (triggerOnChanged) {
                this._onChange(this.value);
                this._onTouched();
            }
            this._cd.detectChanges();
        }
    }
}
