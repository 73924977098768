enum TermsType {
    Net10 = '[TermsType] net10',
    CC = '[TermsType] cc',
    ACH = '[TermsType] ach'
}

namespace TermsType {
    export function toString(type: TermsType): string {
        switch (type) {
            case TermsType.Net10:
                return 'Net10';
            case TermsType.CC:
                return 'Cash Account';
            case TermsType.ACH:
                return 'ACH';
            default:
                return '';
        }
    }

    export const types: TermsType[] = [TermsType.Net10, TermsType.CC, TermsType.ACH];
}

export { TermsType };
