export interface ConfirmModalConfig {
    title?: string;
    content?: string;
    secondaryContent?: string;
    buttons?: {
        confirm?: string;
        cancel?: string;
    };
}

export const confirmModalDefaultConfig: ConfirmModalConfig = {
    title: $localize`Confirm Action`,
    content: $localize`Please confirm your action.`,
    secondaryContent: $localize`Do you wish to continue?`,
    buttons: {
        confirm: $localize`:@@confirm:Confirm`,
        cancel: $localize`:@@cancel:Cancel`
    }
};
