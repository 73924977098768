export interface IEventEmailedStatus {
    addresses: string[];
    errors: string;
    lastAttempt: Date;
}

export class EventEmailedStatus implements IEventEmailedStatus {
    public addresses: string[];
    public errors: string;
    public lastAttempt: Date;

    constructor(params?: Partial<IEventEmailedStatus>) {
        if (params) {
            this.addresses = params.addresses || this.addresses;
            this.errors = params.errors || this.errors;
            this.lastAttempt = params.lastAttempt || this.lastAttempt;
        }
    }
}
