enum HighlightType {
    Info = 'info',
    Warning = 'warning',
    Error = 'error'
}

// HighlightRule connects a set of regex expressions (matchers) to a type and a hint. The type is
// used for inline highlighting by the logHighlight pipe and the hint is used by the ptkrLogHint
// structural directive for displaying inline hints in the logs.
export class HighlightRule {
    public type: HighlightType = HighlightType.Info;
    public hint?: string;
    public matchers: Array<string> = [];
    constructor(type?: HighlightType, matchers?: Array<string>, hint?: string) {
        this.type = type || this.type;
        this.hint = hint;
        this.matchers = matchers || this.matchers;
    }

    // build constructs a regex for all the matchers and returns the hint and template func used
    // for highlighting with inline spans.
    public build(): { hint: string; regex: RegExp; templateFunc: (match: string) => string } {
        let expr = '';
        for (let i = 0; i < this.matchers.length; i++) {
            expr = `${expr}${((): string => (expr.length === 0 ? '' : '|'))()}(${
                this.matchers[i]
            })`;
        }
        return {
            hint: this.hint,
            regex: new RegExp(expr),
            templateFunc: (match: string): string =>
                `<span class="log-line highlight ${this.type}">${match}</span>`
        };
    }
}

// HighlightRuleGroup is a collection of HighlightRules. Strings can be applied against this
// rule group to get hints or highlighting strings.
export class HighlightRuleGroup {
    public rules: Array<HighlightRule> = [];
    constructor(rules: Array<HighlightRule>) {
        this.rules = rules || this.rules;
    }
    // Applies the highlight rules to the provided string, returning the modified string
    // with HTML containing the rule replacements
    public apply(value: string): string {
        const c = this.rules.map(rule => rule.build());
        c.forEach(r => (value = value.replace(r.regex, r.templateFunc)));
        return value;
    }

    // Returns an array of hints for the provided value based on the rule group
    public hints(value: string): Array<string> {
        const c = this.rules.map(rule => rule.build());
        return c.map(r => (r.regex.test(value) ? r.hint : '')).filter(v => v !== '' && v !== null);
    }
}

// DefaultHighlightRuleGroup is the highlight rule group used by default for highlighting job logs
export const DefaultHighlightRuleGroup = new HighlightRuleGroup([
    new HighlightRule(
        HighlightType.Error,
        [
            "level=error msg=\"version '[>=0-9.]+' does not meet constraint '[>=0-9.]+'\"",
            "executing task '[a-zA-Z]+': no task registered with name '[a-zA-Z]+'",
            'cannot load .*: undefined: [a-zA-Z_]+ \\(did you mean .*\\)'
        ],
        `
        <h3 class="log-line hint-header error">Out of date</h3>
        <span>It looks like this DCA is out of date.</span>
        <br><br>
        <span><div class="tag solution">Solution</div> Upgrade this DCA to the latest version.</span>
    `
    ),
    new HighlightRule(
        HighlightType.Error,
        [
            "task 'createProxy' failed: \\[PROXY-CREATE\\] verifying device '[a-zA-Z0-9]*': mac '[a-zA-Z0-9-]*' != '[a-zA-Z0-9-]*'"
        ],
        `
        <h3 class="log-line hint-header error">Device Verification Failed</h3>
        <span>It looks like the device at this IP address either does not have a mac address or the mac address
        that it has is unexpected. In order to prevent unauthorized access to unintended targets, the DCA will
        verify that the target for the proxy is the same device that the client is expected to connect to, if
        the verification fails, the proxy connection is not permitted.</span>
        <br><br>
        <span>This can be caused by (1) the device moving IP addresses, (2) we were unable to get a MAC address
        via SNMP or using the ARP cache.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Re-scan the network to see if the device changed IP addresses.</li>
            <li>Retry the job.</li>
        </ul>
        `
    ),
    new HighlightRule(
        HighlightType.Error,
        [
            "task 'upgradeSoftware' failed: failed to download file '[a-zA-Z0-9\\./:-]+' from '[a-zA-Z0-9\\./:-]+' with checksum '[a-zA-Z0-9]+': " +
                'failed to download file: download step: Checksums did not match'
        ],
        `
        <h3 class="log-line hint-header error">Upgrade Checksum Mismatch</h3>
        <span>It looks like the software upgrade failed because the file checksums didn't match. To learn more about what a checksum is
        and why it needs to match, see <a href="https://www.howtogeek.com/363735/what-is-a-checksum-and-why-should-you-care/">what is a checksum and why
        should you care?</a></span>
        <br><br>
        <span>Checksum mismatch errors can be caused by a couple of reasons, (1) the file was corrupted in network transfer, (2) the file was maliciously
        modified by a third party in transit.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Retry the upgrade without duplicating the job. Upgrade the DCA from the install settings view.</li>
            <li>Wait for an hour before retrying.</li>
        </ul>
        `
    ),
    new HighlightRule(
        HighlightType.Error,
        ['task \'upgradeSoftware\' failed: should have upgraded by now but didn\'t'],
        `
        <h3 class="log-line hint-header error">Software Upgrade Timeout</h3>
        <span>In order for a self-upgrade to finish successfully, the DCA must kill and restart itself. This error indicates that the DCA
        didn't successfully kill itself and that the upgrade was either partially completed or not completed at all.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>If the upgrade was partially completed (files were swapped but DCA failed to restart), a simple restart, followed by a fingerprint upload
            should verify whether the DCA was upgraded.</li>
            <li>Retry the job.</li>
        </ul>
        `
    ),
    new HighlightRule(
        HighlightType.Error,
        [
            'task \'upgradeSoftware\' failed: failed to upgrade self: rename [a-zA-Z0-9\\\\:\\s.()]+: Access is denied.'
        ],
        `
        <h3 class="log-line hint-header error">Software Upgrade File Access Denied</h3>
        <span>It looks like we were unable to successfully apply the update. This error is caused by an operation failure during the process described
        <a href="https://pkg.go.dev/github.com/inconshreveable/go-update#Apply">here</a>.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Restart the DCA, then retry.</li>
        </ul>`
    ),
    new HighlightRule(
        HighlightType.Error,
        [
            "task 'upgradeSoftware' failed: moving files into place: moving file '[a-zA-Z0-9.\\\\/]+' to backup '[a-zA-Z0-9.\\\\/]+': rename " +
                '[a-zA-Z0-9.\\\\/]+ [a-zA-Z0-9.\\\\/]+: The process cannot access the file because it is being used by another process'
        ],
        `
        <h3 class="log-line hint-header error">Software Upgrade File in Use</h3>
        <span>It looks like we were unable to successfully apply the update. This error is caused by a file access error when creating backups of each
        file being replaced. This file access error is caused by the file being in use by another process.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Restart the DCA, then retry.</li>
        </ul>
        `
    ),
    new HighlightRule(
        HighlightType.Error,
        ['grpc: the client connection is closing'],
        `
        <h3 class="log-line hint-header error">Network Connection Error</h3>
        <span>This looks like a generic connection error. Generally these errors are resolved over time.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Retry this job after a few minutes.</li>
        </ul>
        `
    ),
    new HighlightRule(
        HighlightType.Error,
        [
            'task \'uploadMeterReads\' failed.* wsarecv: An existing connection was forcibly closed by the remote host.'
        ],
        `
        <h3 class="log-line hint-header error">Firewall Error</h3>
        <span>This looks like a connection error related to firewall rules. <a href="https://stackoverflow.com/a/60269990">This error</a> is most commonly seen
        inside corporate networks.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Add <code>*.printtrackerpro.com</code> to a firewall whitelist.</li>
            <li>Explicitly add <code>dcam.printtrackerpro.com</code>, <code>csr.printtrackerpro.com</code>, and <code>hc.printtrackerpro.com</code> to a
            firewall whitelist.</li>
        </ul>
        `
    ),
    new HighlightRule(
        HighlightType.Error,
        ['creating DAG from instructions: unknown reference \'.*\''],
        `
        <h3 class="log-line hint-header error">DAG: Unknown Reference</h3>
        <span>The DGI DAG determines the order in which to evaluate instructions. Evaluation order matters in situations where one instruction depends on the
        output of another instruction. This error indicates that a DGI instruction depends on another DGI instruction that doesn't exist in the DGI.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Add the missing reference to the DGI.</li>
            <li>Remove the missing reference from the referencing instruction.</li>
        </ul>
        `
    ),
    new HighlightRule(
        HighlightType.Error,
        ['DAG contains cyclical references'],
        `
        <h3 class="log-line hint-header error">DAG: Circular Reference</h3>
        <span>The DGI DAG determines the order in which to evaluate instructions. Evaluation order matters in situations where one instruction depends on the
        output of another instruction. This error indicates that a DGI instruction (A) depends on another DGI instruction (B) that depends on the first (A)
        resulting in a <code><strong>A -> B -> A</strong></code> relationship.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Remove the circular references from the DGI instructions.</li>
        </ul>
        `
    ),
    new HighlightRule(
        HighlightType.Warning,
        [
            "connecting to target ([0-9\\.])+: couldn't connect to ip '([0-9\\.])+': request timeout \\(after [0-9]+ retries\\)",
            'failed to connect to device, is it online?'
        ],
        `
        <h3 class="log-line hint-header warning">SNMP Connection Failure</h3>
        <span>It looks like we were unable to connect to the device via SNMP. This could be caused by (1) the device
        is turned off, (2) SNMP is disabled, or (3) the device has changed IP addresses.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Increase the device's timeout and retry settings.</li>
            <li>The device may have moved IP addresses, try running a device scan. You can scan the entire network,
            or you can try scanning just this IP address.</li>
        </ul>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        [
            "task 'uploadMeterReads' failed: finding device '[a-zA-Z0-9]*': that item was not found in the databa?se"
        ],
        `
        <h3 class="log-line hint-header warning">Unrecognized Device</h3>
        <span>It looks like the device key specified in this job is not recognized by the DCA. This can happen when a bulk upload meter reads job is
        sent to multiple DCAs. Generally this is not a critical error, and the device is most likely being tracked by a different DCA at this entity.</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ["meter responded with 'status': map[status:FAILED]"],
        `
        <h3 class="log-line hint-header warning">Tesseract Collection Error</h3>
        <span>It looks like the the tesseract produced a <code>{"status":"FAILED"}</code> meter.</span>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Refer to the <code>DGProc.log</code> file for more details.</li>
        </ul>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['device SN: .*, meter SN: .*: non-matching device and meter serial numbers'],
        `
        <h3 class="log-line hint-header warning">Meter Serial Number Mismatch</h3>
        <span>It looks like the serial number of the collected meter does not match the serial number of a device. If a meter read is collected with a serial
        number, the meter serial number must also match the device serial number. This is a preventative measure to ensure that if devices move around the
        network, meters for one device are not reported to a different device.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Ensure the serial number is being correctly captured in the DGI.</li>
        </ul>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['marshal: unable to parse OID: strconv\\.Atoi: parsing \\\\?".*\\\\?": invalid syntax'],
        `
        <h3 class="log-line hint-header warning">Using Named OID</h3>
        <span>It looks like the DGI instructions contain named OIDs. Named OIDs look like <code>printmib.prtMarkerSupplies.prtMarkerSuppliesTable</code>. The
        DCA does not know how to interpret this named OID it must be converted into a numbered OID.</span>
        <br><br>
        <span><div class="tag solution">Solution</div> Convert the named OID (i.e. <code>printmib.prtMarkerSupplies.prtMarkerSuppliesTable</code>) to a numbered
        OID (i.e. <code>.1.2.3</code>).</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ["create evaluatable expression for instruction'.*': Invalid token: '::'"],
        `
        <h3 class="log-line hint-header warning">Mismatched Instruction Type</h3>
        <span>It looks like the DGI instruction has a type of <code>calc</code> but you're referencing a namespaced function that is meant to be used in
        instructions with a type of <code>func</code>.</span>
        <br><br>
        <span><div class="tag solution">Solution</div> Change the instruction block's <code>type</code> property to <code>func</code>.</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['expected func as first token'],
        `
        <h3 class="log-line hint-header warning">Invalid Instruction Syntax</h3>
        <span>It looks like the DGI instruction contains an unexpected value based on the instruction type.</span>
        <br><br>
        <span><div class="tag solution">Solution</div> Ensure that the instruction <code>type</code> property is correct for the instruction.</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['strings::decode_hex: decoding hex to string: encoding/hex: invalid byte'],
        `
        <h3 class="log-line hint-header warning">Invalid Byte Decoding Hex</h3>
        <span>It looks like the function is expecting a hex string but got something else.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Ensure the value is a valid hex string.</li>
            <li>Ensure the value has not already been decoded from hex to string.</li>
        </ul>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['invalid literal with base 10:\\s?[0-9.]*'],
        `
        <h3 class="log-line hint-header warning">Starlark: Invalid Coercion of Float to Int</h3>
        <span>This error indicates that you're attempting to convert a float to an int using the built-in <code>int</code> function. For example, the following
        syntax is invalid: <code>int(1.5)</code>.</span>
        <br><br>
        <span><div class="tag solution">Solution</div> Change the function from <code>int</code> to <code>float</code>.</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['executing instruction: .* undefined: [a-zA-Z_.]+'],
        `
        <h3 class="log-line hint-header warning">Starlark: Undefined Symbol</h3>
        <span>This error indicates that you're attempting to call a function or access a symbol that is undefined.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Verify the name of the function or symbol.</li>
            <li>Verify that you're loading any required modules.</li>
        </ul>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ["got [a-zA-Z]+ literal, want ','"],
        `
        <h3 class="log-line hint-header warning">Starlark: Syntax Error</h3>
        <span>Are you missing a comma?</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['unexpected \'\\)\'', 'unexpected \'\\)\''],
        `
        <h3 class="log-line hint-header warning">Starlark: Syntax Error</h3>
        <span>Do you have an extra parenthesis?</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['got \'.\', want \'\\)\''],
        `
        <h3 class="log-line hint-header warning">Starlark: Syntax Error</h3>
        <span>Are you missing a parenthesis?</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['got \'.\', want \',\''],
        `
        <h3 class="log-line hint-header warning">Starlark: Syntax Error</h3>
        <span>Are you missing a comma?</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ["unable to extract value for path '[a-zA-Z.0-9]+' and allowZero is disabled"],
        `
        <h3 class="log-line hint-header warning">Starlark: Null Variable Reference</h3>
        <span>This error indicates that you're attempting to access the value of a variable and the value is null (Starlark <code>None</code>).</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>Verify that the variable is being evaluated correctly.</li>
            <li>If (and only if) the value is an optional value (in other words, not all devices that use this particular
            DGI, report this value), you may consider using the following syntax to load the optional variable:
            <code>vars_load('pageCounts.life.total', allowZero=True, type='int')</code></li>
        </ul>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['context deadline exceeded'],
        `
        <h3 class="log-line hint-header warning">Timeout</h3>
        <span>It looks like the something timed out. Refer the context of this error to understand what could have caused it.</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        [
            'saved browser context not found, \\(has it been initialized with a call to SetBrowserContext yet\\?\\)'
        ],
        `
        <h3 class="log-line hint-header warning">Starlark: Browser Not Initialized</h3>
        <span>It looks like the we're attempting to evaluate an instruction that requires a browser but a browser context hasn't been created yet.</span>
        <br><br>
        <span><div class="tag solution">Solution</div> Start a new browser context with <code>load('web', 'web'); web.start_shared_context()</code>.</span>
    `
    ),
    new HighlightRule(
        HighlightType.Warning,
        ['device did not have a corresponding response in rpc call'],
        `
        <h3 class="log-line hint-header warning">Unable to Create/Update Device</h3>
        <span>It looks like the we're unable to create or update this discovered device. This is usually a result of (1) not being able to collect a UMacID or
        a serial number, or (2) multiple devices already exist at this entity with the same UMacID or serial number.</span>
        <br><br>
        <span><div class="tag solution">Solution</div> Contact Print Tracker Support.</span>
    `
    ),
    new HighlightRule(
        HighlightType.Info,
        ['uploading 0 meter reads'],
        `
        <h3 class="log-line hint-header info">No Meters Uploaded</h3>
        <span>In some cases, meter reads are not uploaded after they're collected because of a counter drop. A counter drop occurs when a counter was previously
        higher than it's current value. In order to verify that the counter is accurately lower than it used to be, we require that before uploading any meters,
        the current counter value is higher than the previous three counter values.</span>
        <br><br>
        <div class="tag suggestion">Suggestions</div>
        <ul>
            <li>If you're sure the counter drop is expected, retry this job four times.</li>
        </ul>
    `
    ),
    new HighlightRule(HighlightType.Info, ['finished ?[a-zA-Z0-9]* task'], null),
    new HighlightRule(HighlightType.Error, ['error|failure|unable'], null),
    new HighlightRule(HighlightType.Warning, ['warning|non-critical'], null)
]);
