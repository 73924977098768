import { PaginationLinks } from './PaginationLinks';

export interface IPaginatedList<T> {
    docs: T[];
    totalDocs: number;
    urls: PaginationLinks;
}

export class PaginatedList<T> implements IPaginatedList<T> {
    public docs: T[];
    public totalDocs: number;
    public urls: PaginationLinks;
}
