<ng-container *ngIf='!!formGroup; else noFormGroup'>
    <form [formGroup]='formGroup'>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        SNMP
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        SNMP access, compatibility and authentication
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <ptkr-field-group
                        label='Max retries'
                        hint="When we are having trouble communicating with a device, this is the number of times we'll
                              try to recontact the device. When you're having trouble finding all of your devices, try
                              adjusting this setting."
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery.Retry'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <input
                                matInput
                                type='number'
                                [formControlName]='SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery.Retry'
                                min='1'
                            />
                        </mat-form-field>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='Timeout (in milliseconds)'
                        hint="Adjusting the timeout will determine how long we should wait before moving on from one IP
                              address to another. When you're having trouble finding all of your devices, try adjusting
                              this setting."
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery.Timeout'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <input
                                matInput
                                type='number'
                                [formControlName]='SettingsKeyMap.DeviceDiscovery.AdvancedDiscovery.Timeout'
                                min='1'
                            />
                        </mat-form-field>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='Communities'
                        hint="This is like a user ID or password that allows access to a router's
                              or other device's statistics"
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.SNMP.Communities'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <ptkr-string-array-field
                            [formControlName]='SettingsKeyMap.SNMP.Communities'
                        ></ptkr-string-array-field>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='Version'
                        hint='The version of SNMP to use'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.SNMP.Version'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <mat-select [formControlName]='SettingsKeyMap.SNMP.Version'>
                                <mat-option [value]='1'>1</mat-option>
                                <mat-option [value]='2'>2</mat-option>
                                <mat-option [value]='3'>3</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ptkr-field-group>
                    <div class='-indent'>
                        <ptkr-field-group
                            label='Username'
                            i18n-label
                            [hidden]='getFieldValue(SettingsKeyMap.SNMP.Version) !== 3'>
                            <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.SNMP.V3Username'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <mat-form-field appearance='outline'>
                                <input matInput [formControlName]='SettingsKeyMap.SNMP.V3Username' />
                            </mat-form-field>
                        </ptkr-field-group>
                        <ptkr-field-group
                            label='Authentication Password'
                            i18n-label
                            [hidden]='getFieldValue(SettingsKeyMap.SNMP.Version) !== 3'>
                            <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.SNMP.V3AuthPassword'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <mat-form-field appearance='outline'>
                                <input matInput
                                       [formControlName]='SettingsKeyMap.SNMP.V3AuthPassword' />
                            </mat-form-field>
                        </ptkr-field-group>
                        <ptkr-field-group
                            label='Privacy password'
                            i18n-label
                            [hidden]='getFieldValue(SettingsKeyMap.SNMP.Version) !== 3'>
                            <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.SNMP.V3PrivacyPassword'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <mat-form-field appearance='outline'>
                                <input matInput
                                       [formControlName]='SettingsKeyMap.SNMP.V3PrivacyPassword' />
                            </mat-form-field>
                        </ptkr-field-group>
                        <ptkr-field-group
                                label='Privacy protocol' i18n-label
                                [hidden]='getFieldValue(SettingsKeyMap.SNMP.Version) !== 3'>
                            <ptkr-inheritance-reset-button
                                    [settingName]='SettingsKeyMap.SNMP.V3PrivacyProtocol'
                                    [showDropdown]='showDropdown'
                                    (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <mat-form-field appearance='outline'>
                                <mat-select [formControlName]='SettingsKeyMap.SNMP.V3PrivacyProtocol'>
                                    <ng-container *ngFor="let value of SNMPPrivacyProtocol.asArray">
                                        <mat-option [value]='value'>{{SNMPPrivacyProtocol.toString(value)}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </ptkr-field-group>
                        <ptkr-field-group
                            label='Algorithm' i18n-label
                            [hidden]='getFieldValue(SettingsKeyMap.SNMP.Version) !== 3'>
                            <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.SNMP.V3Algorithm'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <mat-form-field appearance='outline'>
                                <mat-select [formControlName]='SettingsKeyMap.SNMP.V3Algorithm'>
                                    <mat-option [value]='SNMPAlgorithm.MD5'>MD5</mat-option>
                                    <mat-option [value]='SNMPAlgorithm.SHA'>SHA</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ptkr-field-group>
                        <ptkr-field-group
                            label='Context' i18n-label
                            [hidden]='getFieldValue(SettingsKeyMap.SNMP.Version) !== 3'>
                            <ptkr-inheritance-reset-button
                                [settingName]='SettingsKeyMap.SNMP.V3Context'
                                [showDropdown]='showDropdown'
                                (bulkReset)='runBulkReset.emit($event)'>
                            </ptkr-inheritance-reset-button>
                            <mat-form-field appearance='outline'>
                                <input matInput [formControlName]='SettingsKeyMap.SNMP.V3Context' />
                            </mat-form-field>
                        </ptkr-field-group>
                    </div>
                </section>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</ng-container>
<ng-template #noFormGroup>
    <div>
        <h2 style='color: gray;' i18n>Loading...</h2>
    </div>
</ng-template>
