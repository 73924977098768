<div class="event-note" mat-dialog-content>
    <mat-form-field floatLabel="never">
        <textarea matInput #noteText [disabled]="!data.writeEnabled" placeholder="Add a note (optional)">{{ data.note }}</textarea>
    </mat-form-field>
</div>
<div class="action-buttons">
    <button *ngIf="data.writeEnabled; else closeButton" mat-button color="accent" (click)="dialogRef.close(false)">Cancel</button>
    <ng-template #closeButton>
        <button mat-flat-button color="accent" (click)="dialogRef.close(false)">Close</button>
    </ng-template>
    <button mat-flat-button *ngIf="data.writeEnabled"  color="accent" (click)="saveNote(noteText.value)">Save</button>
</div>
