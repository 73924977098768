import { Action } from '@ngrx/store';
import { User, UserModel } from '@app/models';

export enum UserActions {
    LoadUser = '[user] load user',
    LoginUser = '[user] login user',
    ClearUser = '[user] clear user',
    UpdateUser = '[user] update user'
}

export class LoadUserAction implements Action {
    public readonly type: UserActions.LoadUser = UserActions.LoadUser;
    public payload: User = null;
    constructor(_user: User) {
        this.payload = _user;
    }
}

export class LoginUserAction implements Action {
    public readonly type: UserActions.LoginUser = UserActions.LoginUser;
    public payload: User = null;
    constructor(_user: User) {
        this.payload = _user;
    }
}

export class ClearUserAction implements Action {
    public readonly type: UserActions.ClearUser = UserActions.ClearUser;
}

export class UpdateUserAction implements Action {
    public readonly type: UserActions.UpdateUser = UserActions.UpdateUser;
    public payload: User | Partial<UserModel> = null;
    constructor(_user: User | Partial<UserModel>) {
        this.payload = _user;
    }
}

export type UserActionsType = LoadUserAction | ClearUserAction | UpdateUserAction | LoginUserAction;
