import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Install, Task, TaskTypes, JobDestinationTypes, SortDirection } from '@libs/iso/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { InstallService } from '../../../core/services/install.service';
import { Store, select } from '@ngrx/store';
import { GlobalStore, EntityStore, UserStore } from '@app/state/store';
import { fromEntity, fromUser } from '@app/state/selectors';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { NotificationService } from '@app/core/services/notification.service';
import { OverlayService } from '@app/core/services/overlay.service';
import { IClientJobDto } from '@server/library/dto/rts/ClientJobDto';
import { RtsService } from '@app/services';
import * as moment from 'moment';

@Component({
    selector: 'ptkr-bulk-add-install-iprange',
    templateUrl: './bulk-add-install-iprange.component.html',
    styleUrls: ['./bulk-add-install-iprange.component.scss']
})
export class BulkAddInstallIprangeComponent implements OnInit {
    public data: Array<Partial<Install>>;
    public cols: Array<{
        name: string;
        key: keyof Partial<Install>;
        display?: (string) => string;
    }> = [
        { name: 'Machine', key: 'machine' },
        { name: 'Entity name', key: 'entityName' },
        {
            name: 'Last check-in',
            key: 'lastCheckInDtTm',
            display: (value) => moment(value).fromNow()
        }
    ];
    public paginatorLength: number = 0;
    public paginatorSize: number = 15;
    public paginatorSizeOptions: Array<number> = [15, 30, 50, 100];
    public paginatorPageIndex: number = 1;
    public selectedData: Set<string> = new Set<string>();
    public everythingIsSelected: boolean = false;

    private _inc: Array<string>;
    private _exc: Array<string>;
    private _checked: boolean;
    private _entity: EntityStore;
    private _user: UserStore;
    private _meters: boolean;

    constructor(
        public dialogRef: MatDialogRef<BulkAddInstallIprangeComponent>,
        private _rtsService: RtsService,
        private _installService: InstallService,
        private _store: Store<GlobalStore>,
        private _notificationService: NotificationService,
        private _overlayService: OverlayService,
        private _cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: {
            inc: Array<string>;
            exc: Array<string>;
            uploadMeters: boolean;
        }
    ) {}

    public ngOnInit(): void {
        this._inc = this.dialogData.inc;
        this._exc = this.dialogData.exc;
        this._meters = this.dialogData.uploadMeters;
        this._store
            .pipe(select(fromEntity.currentEntity))
            .subscribe(entity => (this._entity = entity));
        this._store.pipe(select(fromUser.currentUser)).subscribe(user => (this._user = user));
        this._getData();
    }

    public onCurrentPageChange(event: number): void {
        this.paginatorPageIndex = event;
        this._getData();
    }

    public onPageSizeChange(event: number): void {
        this.paginatorSize = event;
        this._getData();
    }

    public onCheckboxChange(event: MatCheckboxChange): void {
        this._checked = event.checked;
    }

    private _getData(): void {
        setTimeout(() => this._store.dispatch(new ToggleGlobalSpinnerAction(true)));
        this._installService
            .getInstalls(
                this._entity._id,
                undefined,
                this.paginatorPageIndex,
                this.paginatorSize,
                'lastCheckInDtTm',
                -1,
                false,
                true
            )
            .subscribe(
                res => {
                    this.data = res.docs;
                    this.paginatorLength = res.totalDocs;
                    setTimeout(() => this._store.dispatch(new ToggleGlobalSpinnerAction(false)));
                    // If there is only one total install and we're still on the first page, use simple UI
                    if (this.paginatorLength === 1 && this.paginatorPageIndex === 1) {
                        this.selectedData = new Set<string>([this.data[0]._id.toString()]);
                    }
                },
                err => {
                    setTimeout(() => this._store.dispatch(new ToggleGlobalSpinnerAction(false)));
                    this._notificationService.error('Query for installs failed, please try again.');
                }
            );
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

    public openConfirmationPanel(): void {
        let content: string = '';
        if (this.everythingIsSelected) {
            content =
                'This will add the chosen IPs and subnets to all the installs of this entity.';
        } else {
            content = `This will add the chosen IPs and subnets to ${this.selectedData.size} installs.`;
        }

        if (this._checked) {
            content = content.concat(
                "\nThe IPs and subnets will be added to these installs' regular scan routines."
            );
        }

        this._overlayService
            .openConfirmationModal({
                title: 'Confirm Settings Change',
                content: content,
                secondaryContent:
                    'Confirm to continue and begin scan, or ' +
                    'cancel to close without any scans or changes.'
            })
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    /** Construct a job from the form params */
                    const scanDevicesTask = new Task({
                        type: TaskTypes.ScanDevices,
                        payload: {
                            workerCount: null,
                            timeout: null,
                            retries: null,
                            port: null,
                            snmpVersion: null,
                            pingFirst: null,
                            adHoc:
                                !!this._inc || !!this._exc
                                    ? {
                                          // Null if neither has anything.
                                          includeIpAddresses: this._inc,
                                          excludeIpAddresses: this._exc
                                      }
                                    : null
                        }
                    });

                    const uploadMetersTask = new Task({
                        type: TaskTypes.UploadMeterReads,
                        payload: null
                    });

                    const params: Partial<IClientJobDto> = {
                        entityKey: this._entity._id,
                        installKey: this.everythingIsSelected
                            ? [this._entity._id.toString()]
                            : [...this.selectedData],
                        userKey: this._user._id,
                        metadata: {
                            user: {
                                username: this._user.email,
                                firstname: this._user.firstName,
                                lastname: this._user.lastName
                            },
                            entity: {
                                name: this._entity.name
                            }
                        },
                        jobName: 'Custom Scan',
                        jobDescription:
                            "This scan was created by clicking the 'Add device' button on app.printtrackerpro.com",
                        destination: this.everythingIsSelected
                            ? JobDestinationTypes.Entity
                            : JobDestinationTypes.Install,
                        tasks: [
                            new Task({
                                type: TaskTypes.UpdateSettings
                            })
                        ]
                    };

                    /** Add the tasks to the job (params) */
                    params.tasks.push(scanDevicesTask);
                    if (this._meters) {
                        params.tasks.push(uploadMetersTask);
                    }

                    /** Call the service to send the job to the server */
                    this._rtsService
                        .createJob(
                            params.entityKey,
                            params.installKey,
                            params.userKey,
                            params.metadata,
                            params.jobName,
                            params.jobDescription,
                            params.destination,
                            params.tasks
                        )
                        .subscribe((r): void => {
                            /** This does not imply a successful job, this means the job was issued to the broker */
                            this._notificationService.success(
                                'Scanning for devices on all Data Collection Agents'
                            );
                        });

                    if (this._checked) {
                        this._installService
                            .addIpsToInstalls(
                                [...this.selectedData],
                                this._entity._id,
                                this.everythingIsSelected,
                                false,
                                this._inc,
                                this._exc
                            )
                            .subscribe({
                                next: (r: any): void => {
                                    this._getIds((ids: string[]) =>
                                        this._rtsService
                                            .createJob(
                                                params.entityKey,
                                                ids,
                                                params.userKey,
                                                params.metadata,
                                                'Update Installation Settings',
                                                'This job was created because a user edited and saved installation settings.',
                                                JobDestinationTypes.Install,
                                                [
                                                    new Task({
                                                        type: TaskTypes.UpdateSettings,
                                                        payload: {
                                                            allDevices: false,
                                                            devices: [],
                                                            includeInstallSettings: true
                                                        }
                                                    })
                                                ]
                                            )
                                            .subscribe((r1): void => {
                                                /** This does not imply a successful job, this means the job was issued to the broker */
                                                this._notificationService.success(
                                                    'Settings updated'
                                                );
                                            })
                                    );
                                },
                                error: (err: any): void => {
                                    this._notificationService.error('Something went wrong');
                                }
                            });
                    }
                }
            });

        this.closeModal();
    }

    private _getIds(then: (ids: string[]) => void): void {
        if (this.everythingIsSelected) {
            this._installService.getIdsByEntity(this._entity._id).subscribe(res => {
                then([...res]);
            });
        } else {
            then([...this.selectedData]);
        }
    }
}
