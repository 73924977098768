import { BACKSPACE, C, COMMA, DELETE, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import {
    Component,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef,
    HostListener
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationService } from '@app/services';

/**
 * Item tracks whether a value is selected and allows us to easily select or unselect a value.
 */
class Item<T> {
    public selected: boolean = false;
    public value: T;
    constructor(value: T) {
        this.value = value;
    }

    public select(): this {
        this.selected = true;
        return this;
    }

    public unselect(): this {
        this.selected = false;
        return this;
    }
}

@Component({
    selector: 'ptkr-string-array-field',
    templateUrl: './string-array-field.component.html',
    styleUrls: ['./string-array-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: StringArrayFieldComponent,
            multi: true
        }
    ]
})
export class StringArrayFieldComponent implements ControlValueAccessor {

    public get value(): string[] {
        return this._value.map(v => v.value);
    }
    public set value(val: string[]) {
        this._value = val.map(v => new Item(v));
        this._onChange(val);
        this._onTouched();
    }

    constructor(
        private _notificationService: NotificationService,
        private _cd: ChangeDetectorRef
    ) {}
    public disabled: boolean = false;
    /**
     * If this function returns false, the item will not be added to
     * the list. Triggering notifications within this function may be helpful
     * for guiding the user.
     */
    @Input() public validationFunction: {
        func: (item: string) => boolean | string | null;
    } = { func: (): boolean => true };

    @Input() public placeholder: string;

    public readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, TAB];
    private _value: Item<string>[] = [];

    private static _copy(value: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    private _onChange: any = () => {};
    private _onTouched: any = () => {};

    ngOnInit(): void {}

    public getItems(): Item<string>[] {
        return this._value;
    }

    @HostListener('keydown', ['$event'])
    public onKeyPress($event: KeyboardEvent): void {
        // Automatically copy items to clipboard.
        if (($event.ctrlKey || $event.metaKey) && $event.keyCode === C) {
            StringArrayFieldComponent._copy(this._value.map(v => v.value).join(','));
            this._notificationService.success('Copied!');
        }

        // Remove selected items
        if ($event.keyCode === BACKSPACE || $event.keyCode === DELETE) {
            this._value.filter(v => v.selected).forEach(v => this.removeItem(v));
        }
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public writeValue(obj: string[]): void {
        this._value = obj.map(v => new Item(v));
        this._cd.markForCheck();
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Adds the value of event e to the internal data structure. This function will also handle
     * long strings of values separated by comma and even remove quoted values.
     * @param {any} e - The input event
     * @returns {void}
     */
    public add(e: any): void {
        const input = e.input;
        const values = e.value
            .split(',')
            .map(v => v.trim())
            .map(v => v.replace(/"/g, ''));
        for (const value of values) {
            if ((value || '').trim()) {
                const valid: boolean | string | null = this.validationFunction.func(value);
                if (typeof valid === 'string') {
                    this._notificationService.error(valid);
                } else if (valid === true || valid === null) {
                    if (this.valueIdx(value) >= 0) {
                        continue;
                    }
                    this._value.push(new Item(value));
                    if (input) {
                        input.value = '';
                    }
                } else { // Should only be false at this point
                    this._notificationService.error('Invalid format');
                }
            }
        }
        if (values.length > 0) {
            this._onChange(this.value);
        }
    }

    public remove(event: any): void {
        return this.removeItem(event);
    }

    private removeItem(item: Item<string>): void {
        const index = this.valueIdx(item.value);
        if (index >= 0) {
            this._value.splice(index, 1);
        }
        this._onChange(this.value);
    }

    private valueIdx(value: string): number {
        return this._value.findIndex(v => v.value === value);
    }
}
