import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'ptkr-block-header',
    templateUrl: './block-header.component.html',
    styleUrls: ['./block-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockHeaderComponent implements OnInit {
    @Input()
    public title: string = '';

    constructor() {}

    ngOnInit(): void {}
}
