import { Component, OnInit } from '@angular/core';
import { AbstractSettingsSectionComponent } from '@libs/web/forms/components/settings-sections/abstract-settings-section/abstract-settings-section.component';
import { DealerAccountStatus } from '../../../../../iso/core/enums/DealerAccountStatus';
import { EntitySettingsFormGroup } from '../../../models/EntitySettingsFormGroup';

@Component({
    selector: 'ptkr-entity-settings-section',
    templateUrl: './entity-settings-section.component.html'
})
export class EntitySettingsSectionComponent
    extends AbstractSettingsSectionComponent<EntitySettingsFormGroup> implements OnInit{
        public DealerAccountStatus = DealerAccountStatus;
        public accountStatus: DealerAccountStatus | null;

        ngOnInit() {
            this.setAccountStatus(this.formGroup.controls['expiresOn'].value);
        }

        public changeAccountStatus(accountStatus: DealerAccountStatus) {
            this.accountStatus = accountStatus;
            if(this.accountStatus === DealerAccountStatus.Active) {
                this.formGroup.controls['expiresOn'].setValue(null);
                this.formGroup.controls['expiresOn'].markAsDirty();
            }
            if(this.accountStatus === DealerAccountStatus.Suspended) {
                this.formGroup.controls['expiresOn'].setValue(new Date());
                this.formGroup.controls['expiresOn'].markAsDirty();
            }
        }

        private setAccountStatus(expiresOn: Date) {
            if(expiresOn === null) {
                this.accountStatus = DealerAccountStatus.Active;
            } else {
                if(expiresOn.getTime() < new Date().getTime()) {
                    this.accountStatus = DealerAccountStatus.Suspended;
                } else {
                    this.accountStatus = DealerAccountStatus.Evaluating;
                }
            }
        }
}
