<div
    class="actions-bar"
    [ngClass]="{
        'no-right-padding': config.showSearchButton,
        'no-left-padding': config.showLeftButton
    }"
    style="display: flex"
>
    <div class="left-button-container" *ngIf="config.showLeftButton">
        <button class="left-button" color="accent" mat-flat-button (click)="leftButtonClicked.emit()">
            {{ config.leftButtonLabel }}
        </button>
    </div>
    <div class="" *ngIf="config.showFilter" style="flex: 1">
        <input
            class="filter"
            [(ngModel)]="config.initialFilter"
            [placeholder]="filterPlaceholder()"
            (keyup)="filterValueChanged($event)"
        />
    </div>
    <div>
        <div class="align-right" *ngIf="config.showSearchButton">
            <button class="search-button" mat-flat-button (click)="search()" i18n="@@search">Search</button>
        </div>
        <div class="align-right" *ngIf="config.showOptionsIcon && !!optionsMenu">
            <div class="bar-container">
                <mat-icon class="light-gray-icon" [matMenuTriggerFor]="optionsMenu"
                    >settings</mat-icon
                >
            </div>
        </div>
        <div class="align-right" *ngIf="config.showFilterOptionsIcon && !!filterOptionsMenu">
            <div class="bar-container">
                <mat-icon
                    class="light-gray-icon"
                    [matMenuTriggerFor]="filterOptionsMenu"
                    [matBadge]="filterOptionsIconBadge"
                    [matBadgeHidden]="filterOptionsIconBadge == null || filterOptionsIconBadge == 0"
                    matBadgeSize="small"
                    matBadgeColor="accent"
                    >filter_alt</mat-icon
                >
            </div>
        </div>
        <div class="align-right" *ngIf="config.showIncludeChildren">
            <div class="bar-container">
                <mat-slide-toggle
                    class="include-children-toggle"
                    [(ngModel)]="config.initialIncludeChildren"
                    (change)="includeChildrenToggleChanged($event)"
                    i18n
                    >Include children</mat-slide-toggle
                >
            </div>
        </div>
        <div class="align-right" *ngIf="config.showToggle">
            <div class="bar-container">
                <mat-slide-toggle
                    class="include-children-toggle"
                    (change)="toggleChanged.emit($event.checked)"
                    >{{ config.toggleLabel }}</mat-slide-toggle
                >
            </div>
        </div>
        <div class="align-right" *ngIf="config.showDateRangePicker">
            <div class="bar-container">
                <form [formGroup]="dateForm">
                    <div class="date-range-picker">
                            <mat-label *ngIf="config.dateRangePickerLabel">{{ config.dateRangePickerLabel }}</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate formControlName="startDate" placeholder="Start date" i18n-placeholder>
                                <input matEndDate formControlName="endDate" placeholder="End date" i18n-placeholder>
                            </mat-date-range-input>
                            <mat-icon class="light-gray-icon" (click)="picker.open()">date_range</mat-icon>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                    </div>
                </form>
            </div>
        </div>
        <div class="align-right" *ngIf="config.showRefresh">
            <mat-icon matTooltip="Refresh" i18n-matTooltip (click)="refresh.emit()">refresh</mat-icon>
        </div>
    </div>
</div>
