import { ShippingMethod, ShippingAddress, UserPaymentMethod } from '@libs/iso/core';

export interface OrderModel {
    entityName: string;
    subtotal: number;
    salesTax: number;
    payShipping: boolean;
    shippingMethod: ShippingMethod;
    shippingAddress: ShippingAddress;
    expectedArrivalDate: Date;
    totalPrice: number;
    paymentMethodId: string;
    paymentMethod: UserPaymentMethod;
    isWarranty: boolean;
}

interface OrderConstructorParams extends OrderModel {}

export class Order implements OrderModel {
    public entityName: string = '';
    public subtotal: number = 0;
    public salesTax: number = 0;
    public payShipping: boolean = true;
    public shippingMethod: ShippingMethod = null;
    public shippingAddress: ShippingAddress = null;
    public expectedArrivalDate: Date = null;
    public totalPrice: number = 0;
    public paymentMethodId: string = '';
    public paymentMethod: UserPaymentMethod = null;
    public isWarranty: boolean = false;

    constructor(params?: Partial<OrderConstructorParams>) {
        if (!!params) {
            this.entityName = params.entityName || this.entityName;
            this.subtotal = params.subtotal || this.subtotal;
            this.salesTax = params.salesTax || this.salesTax;
            this.isWarranty = params.isWarranty || this.isWarranty;
            this.payShipping =
                typeof params.payShipping === 'boolean' ? params.payShipping : this.payShipping;
            if (!!params.shippingMethod) {
                this.shippingMethod = new ShippingMethod(params.shippingMethod);
            }
            if (!!params.shippingAddress) {
                this.shippingAddress = new ShippingAddress(params.shippingAddress);
            }
            if (!!params.expectedArrivalDate) {
                this.expectedArrivalDate = new Date(params.expectedArrivalDate.getTime());
            }
            this.totalPrice = params.totalPrice || this.totalPrice;
            this.paymentMethodId = params.paymentMethodId || this.paymentMethodId;
            if (!!params.paymentMethod) {
                console.log('payment method', params.paymentMethod);
                this.paymentMethod = UserPaymentMethod.fromExisting(params.paymentMethod);
            }
        }
    }
}
