import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StringArrayFieldComponent } from '@libs/web/forms/components/string-array-field/string-array-field.component';
import { ValidationService } from '@app/services';

@Component({
    selector: 'ptkr-ip-address-input',
    templateUrl: '../string-array-field/string-array-field.component.html',
    styleUrls: ['../string-array-field/string-array-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: IpAddressInputComponent,
            multi: true
        }
    ]
})
export class IpAddressInputComponent extends StringArrayFieldComponent
    implements OnInit, ControlValueAccessor {
    @Input() public validationType: 'include' | 'exclude' = 'include';

    @Input() public placeholder: string;

    /**
     * If you'd like to customize the IP address validation behavior, you can override this function
     * which defaults to looking at validationType and using a generic ip address validator.
     */
    @Input() public validationFunction: {
        func: (item: string) => string | null;
    } = {
        func: (item): string | null =>
            this.validationType === 'exclude'
                ? ValidationService.ipAddress(item, 'exclude')
                : ValidationService.ipAddress(item, 'include')
    };
}
