import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InjectionToken, Inject, Injectable } from '@angular/core';
import { RiafoxHttpModule } from '../http.module';

export let RIAFOX_HTTP_AUTH_TOKEN_STREAM = new InjectionToken<Observable<string> | string>(
    'riafox.http.auth.tokenstream', {
        providedIn: RiafoxHttpModule,
        factory: (): string => ''
    }
);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private _token: string = '';

    constructor(
        @Inject(RIAFOX_HTTP_AUTH_TOKEN_STREAM) private _tokenStream: Observable<string> | string
    ) {
        if (!this._tokenStream) {
            console.error(this._tokenStream);
            throw new Error('Riafox Auth Interceptor: A token stream is required.');
        }
        if (typeof this._tokenStream === 'string') {
            this._token = this._tokenStream;
        } else {
            if (typeof this._tokenStream !== 'object') {
                console.error(this._tokenStream);
                throw new Error(
                    'Riafox Auth Interceptor: A token stream must be an instance of Observable.'
                );
            }
            this._tokenStream.subscribe(token => (this._token = token));
        }
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newOpts = { headers: req.headers };

        if (!!this._token) {
            newOpts.headers = req.headers.set('Authorization', 'Bearer ' + this._token);
        }
        req = req.clone(newOpts);
        return next.handle(req);
    }
}
