import { PaginationLinks } from '@libs/iso/core';
import { BaseModel, IBaseModel } from '@libs/iso/core/models/BaseModel';
import { Column } from '@libs/iso/core/helpers/columns';

export interface TableDataListModel {
    docs: any;
    totalDocs: number;
    urls: PaginationLinks;
    model: Column.Tabulatable;
    columns: Array<number>;
}

export class TableDataList implements TableDataListModel {
    // private static _tabulate(prependinator: string, flatinatee: object): object {
    //     const flattenedFlatinatee = {};
    //     Object.keys(flatinatee).forEach(key => {
    //         if (typeof flatinatee[key] === 'object') {
    //             Object.assign(flattenedFlatinatee, this._tabulate(key, flatinatee[key]));
    //         } else {
    //             flattenedFlatinatee[!!prependinator ? prependinator + '.' + key : key] =
    //                 flatinatee[key];
    //         }
    //     });
    //     return flattenedFlatinatee;
    // }

    public docs: Array<any> = [];
    public totalDocs: number = 0;
    public urls: PaginationLinks = new PaginationLinks();
    public model: Column.Tabulatable = { columns: [] };
    public columns: Array<number> = [];

    constructor(params?: Partial<TableDataListModel>) {
        if (!!params) {
            this.docs = params.docs || this.docs;
            this.totalDocs = params.totalDocs || this.totalDocs;
            this.urls = new PaginationLinks(params.urls);
            this.model = params.model || BaseModel;
            this.columns = params.columns || this.columns;
        }
    }

    // public tabulate(): Array<any> {
    //     return this.docs.map(doc => TableDataList._tabulate('', doc));
    // }
}
