<table class="g-table" mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="machine">
        <th mat-header-cell *matHeaderCellDef>Machine</th>
        <td mat-cell *matCellDef="let install">{{valueOrEmpty(install.machine)}}</td>
    </ng-container>
    <ng-container matColumnDef="versionDCA">
        <th mat-header-cell *matHeaderCellDef>Version</th>
        <td mat-cell *matCellDef="let install">{{valueOrEmpty(install.versionDCA)}}</td>
    </ng-container>
    <ng-container matColumnDef="lastCheckInDtTm">
        <th mat-header-cell *matHeaderCellDef>Last check-in</th>
        <td mat-cell *matCellDef="let install">{{install.lastCheckInDtTm | amTimeAgo}}</td>
    </ng-container>
    <ng-container matColumnDef="reportingDevices.percentage">
        <th mat-header-cell *matHeaderCellDef>Reporting devices</th>
        <td mat-cell *matCellDef="let install">
            <ptkr-install-reporting-devices [reportingDevices]="install.reportingDevices"></ptkr-install-reporting-devices>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let install; columns: displayedColumns;"></tr>
</table>
