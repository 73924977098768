import { DeviceList, ItemList } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ItemService } from '@app/services';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { ItemSummary, PaginatedList } from '@libs/iso/core';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ItemSummaryListResolver implements Resolve<PaginatedList<ItemSummary>> {
    constructor(private _itemService: ItemService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<PaginatedList<ItemSummary>> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        const initQParams = route.queryParams;
        const params = {
            searchTerm: initQParams.searchTerm || '',
            sort: initQParams.sortBy || '',
            sortOrder: initQParams.sortOrder,
            page: coerceNumberProperty(initQParams.page) || 1,
            limit: coerceNumberProperty(initQParams.limit) || 10
        };
        const empty = of(new PaginatedList<ItemSummary>());
        if (!params.searchTerm || params.searchTerm === '') {
            return empty;
        }
        return this._itemService
            .itemSearchSummary(
                params.searchTerm,
                params.page,
                params.limit,
                params.sort,
                params.sortOrder
            )
            .pipe(
                catchError(err => {
                    console.error('caught error', err);
                    return empty;
                })
            );
    }
}
