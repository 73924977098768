import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GlobalStore } from '@app/state/store';
import { fromCart } from '@app/state/selectors';
import { ClearCartAction } from '@app/state/actions';

@Injectable()
export class CheckoutProgressGuard implements CanDeactivate<any> {
    constructor(private _store: Store<GlobalStore>) {}

    public canDeactivate(): boolean {
        let checkoutActive: boolean = false;
        this._store
            .pipe(
                select(fromCart.checkoutActive),
                take(1)
            )
            .subscribe(hasChanges => (checkoutActive = hasChanges));

        if (checkoutActive) {
            const confirm: boolean = window.confirm(
                'If you leave now, your changes will not be saved'
            );
            if (confirm) {
                this._store.dispatch(new ClearCartAction());
                return true;
            }
            return false;
        }
        this._store.dispatch(new ClearCartAction());
        return true;
    }
}
