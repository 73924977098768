<div mat-dialog-content class="content">
    <h1>Order Toner</h1>
    <ptkr-component-autocomplete
        placeholder="Search for toner by toner ID or print type..."
        [defaultValue]="defaultValue"
        [results]="results$ | async"
        (select)="itemSelected($event)"
    >
    </ptkr-component-autocomplete>
    <div class="table-content" *ngIf="!!selectedItem">
        <mat-spinner *ngIf="lookupInProgress" diameter="40"></mat-spinner>
        <ng-container *ngIf="(compatibleItems$ | async) as items">
            <ptkr-compatible-order-table
                *ngIf="items.length > 0; else noItems"
                [items]="compatibleItems$ | async"
                (addToCart)="addItemToCart($event)"
                (viewCart)="viewCart()"
            >
            </ptkr-compatible-order-table>
        </ng-container>
        <ng-template #noItems>
            <h4>No Compatible Items Found</h4>
        </ng-template>
    </div>
</div>
