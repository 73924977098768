import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class StorageService {
    constructor(private cookieService: CookieService) {}

    private _tokenLocation: string = 'nxt-tok';

    public get authToken(): string {
        const token: string = localStorage.getItem(this._tokenLocation);
        if (!!token && token !== 'null') {
            return token;
        } else {
            localStorage.removeItem(this._tokenLocation);
            return null;
        }
    }
    public set authToken(value: string) {
        if (value === '' || value === null) {
            localStorage.removeItem(this._tokenLocation);
            this.cookieService.remove('nxt-token');
        } else {
            localStorage.setItem(this._tokenLocation, value);
            this.cookieService.put('nxt-token', value);
        }
    }

    private _entityLocation: string = 'entity';
    public get entityKey(): string {
        // const token: string = localStorage.getItem(this._entityLocation);
        // if (!!token && token !== 'null') {
        //     return token;
        // } else {
        //     localStorage.removeItem(this._entityLocation);
        //     return null;
        // }
        return null;
    }
    public set entityKey(value: string) {
        // if (value === '' || value === null) {
        //     localStorage.removeItem(this._entityLocation);
        // } else {
        //     localStorage.setItem(this._entityLocation, value);
        // }
    }
}
