import { IMeterReadValue, MeterReadValue } from './MeterReadValue';

export interface ISupplyMeterReadType {
    [key: string]: IMeterReadValue;
}

export class SupplyMeterReadType implements ISupplyMeterReadType {
    [key: string]: IMeterReadValue;

    constructor(defaults?: Partial<ISupplyMeterReadType>) {
        if (defaults) {
            for (const key in defaults) {
                this[key] = new MeterReadValue(defaults[key]);
            }
        }
    }
}
