<mat-select
    [value]="value"
    (valueChange)="onValueChange($event)"
    (blur)="onTouched()"
    [disabled]="disabled"
>
    <mat-option *ngFor="let option of ExportDelimiter.options" [value]="option">
        {{ ExportDelimiter.toDisplayName(option) }}
    </mat-option>
</mat-select>

