import { TableParams } from '.';
import { CollectionName } from '@libs/iso/core';

enum SuperTableCollection {
    device = 'device',
    install = 'install',
    entity = 'entity',
    meterRead = 'meterRead',
    event = 'alert',
    meterViewer = 'meterViewer'
}

const nameTable: { [key in SuperTableCollection]: Array<string> } = {
    device: ['device', 'Device', 'devices', 'Devices'],
    entity: ['entity', 'Entity', 'entities', 'Entities'],
    alert: ['alert', 'Alert', 'alerts', 'Alerts'],
    install: ['install', 'Install', 'installs', 'Installs'],
    meterRead: ['meter-read', 'Meter-read', 'meter-reads', 'Meter-reads'],
    meterViewer: ['meter', 'Meter', 'meters', 'Meters']
};

namespace SuperTableCollection {
    export function toString(
        value: SuperTableCollection,
        plural: boolean = false,
        capitalized: boolean = false
    ): string {
        const index = plural && capitalized ? 3 : plural ? 2 : capitalized ? 1 : 0;
        return nameTable[value][index];
    }
}

export { SuperTableCollection };

export interface SuperTableParams extends TableParams {
    columns: Array<number>;
    collection: SuperTableCollection;
    startDate?: string;
    endDate?: string;
    isBillingReport?: boolean | string;
    hideBilled?: boolean | string;
}

export const superTableParamDefaults: SuperTableParams = {
    searchTerm: undefined,
    sort: undefined,
    sortOrder: undefined,
    page: 1,
    limit: 25,
    includeChildren: false,
    columns: [0, 2],
    collection: SuperTableCollection.device,
    startDate: undefined,
    endDate: undefined,
    isBillingReport: false,
    hideBilled: false
};

export const superTablePageSizes: Array<number> = [15, 25, 50, 100, 200];

export function parseColumns(columns: string): Array<number> {
    let tmp;
    if (columns) {
        try {
            tmp = JSON.parse(columns);
        } catch (e) {
            console.error(e);
        }
    }
    return tmp;
}
