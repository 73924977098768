import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmModalConfig } from '@app/models';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { NotificationService } from '@app/core/services/notification.service';
import { ValidationService } from '@app/core/services/validation.service';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';

@Component({
    selector: 'ptkr-ip-snmp-dump-modal',
    templateUrl: './ip-snmp-dump-modal.component.html',
    styleUrls: ['./ip-snmp-dump-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IpSnmpDumpModalComponent implements OnInit {
    public formGroup: FormGroup;
    public readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, TAB];
    public ips: Array<string> = [];

    constructor(
        public dialogRef: MatDialogRef<IpSnmpDumpModalComponent>,
        private _fb: FormBuilder,
        private _notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmModalConfig
    ) {}

    ngOnInit(): void {
        this.formGroup = this._fb.group({
            includeIp: this.ips
        });
    }

    public close(submit: boolean): void {
        if (!submit) {
            this.dialogRef.close({ action: 'cancel' });
        } else {
            this.dialogRef.close({ action: 'send', ips: this.ips });
        }
    }

    public getFieldValue(name: string): any {
        return this.getField(name).value;
    }

    public getField(name: string): AbstractControl {
        return this.formGroup.controls[name];
    }

    public addSubnet(e: any): void {
        console.log(e);
        const input = e.input;
        const value = e.value;

        if ((value || '').trim()) {
            const invalid = ValidationService.ipAddress(value, 'include');
            if (invalid === null) {
                this.ips.push(value.trim());
                if (input) {
                    input.value = '';
                }
            } else {
                this._notificationService.error(invalid);
            }
        }

        console.log(this.ips);
    }

    public removeSubnet(addr: string): void {
        const index = this.ips.indexOf(addr);

        if (index >= 0) {
            this.ips.splice(index, 1);
        }
    }
}
