export enum BulkSelectorViewType {
    TreeView = '[BulkSelectorViewType] tree view',
    ListView = '[BulkSelectorViewType] list view'
}

export namespace BulkSelectorViewType {
    export const members = [BulkSelectorViewType.TreeView, BulkSelectorViewType.ListView];

    export function toString(type: BulkSelectorViewType): string {
        switch (type) {
            case BulkSelectorViewType.TreeView:
                return 'Tree View';
            case BulkSelectorViewType.ListView:
                return 'List View';
            default:
                return '';
        }
    }
}
