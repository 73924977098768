import { Injectable } from '@angular/core';
import {
    HttpService,
    Get,
    Query,
    Adapter,
    Body,
    Put,
    Path,
    Post,
    ResponseType,
    MapClass
} from '@libs/web/http';
import {
    PaginatedList,
    Install,
    IWithEntityName,
    BulkDeviceImport,
    GcsModuleVersion,
    ReportingDevices,
    IWithReportingDevices,
    ISettings,
    DynamicSettingsKeyType,
    ISettingsFormSubmission,
    DeviceCollectionResultWithDevice,
    DeviceCollectionResults,
    InstallNetworkTopology, WithReportingDeviceFacets
} from '@libs/iso/core';
import { Observable } from 'rxjs';
import { Device } from '@app/models';

@Injectable()
export class InstallService extends HttpService {
    @Get('/installs')
    // tslint:disable-next-line:ter-prefer-arrow-callback
    @Adapter(function(res): PaginatedList<Install & IWithEntityName> {
        const list = new PaginatedList<Install & IWithEntityName>();
        const docs = Array.isArray(res.docs) ? res.docs : [];
        list.docs = docs.map(d => new Install(d));
        list.totalDocs = res.totalDocs || 0;
        return list;
    })
    public getInstalls(
        @Query('entityKey') entityKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sort?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('excludeDisabled') excludeDisabled?: boolean,
        @Query('startDate') startDate?: string,
        @Query('endDate') endDate?: string
    ): Observable<PaginatedList<Install & IWithEntityName & WithReportingDeviceFacets>> {
        return null;
    }

    // Spencer 7/9/2020 - Service made specifically for the supertable.
    @Get('/installs/list')
    public getNewList(
        @Query('entityKey') entityKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('columns') columns?: Array<number>
    ): Observable<any> {
        return null;
    }

    @Get('/installs/getInstallByEntityAndDate')
    public getInstallByEntityAndDate(
        @Query('id') entityKey: string,
        @Query('date') afterDate: string
    ): Observable<Install> {
        return null;
    }

    @Get('/installs/{id}')
    @MapClass(Install)
    public getInstall(@Path('id') installId: string): Observable<any> {
        return null;
    }

    @Get('/installs/{id}/history')
    public getInstallHistory(
        @Path('id') id: string,
        @Query('startDate') startDate: string,
        @Query('endDate') endDate: string,
        @Query('includeAncestors') includeAncestors: boolean
    ): Observable<any> {
        return null;
    }

    @Get('/installs/newestDcaVersion')
    public getNewestDcaVersion(@Query('id') installKey: string): Observable<GcsModuleVersion> {
        return null;
    }

    @Get('/installs/{id}/troubleReports')
    public getTroubleReports(@Path('id') installKey: string): Observable<Array<any>> {
        return null;
    }

    @Put('/installs/{id}/labels')
    public updateLabels(
        @Path('id') installId: string,
        @Body('labels') labels: { [key: string]: string }
    ): Observable<any> {
        return null;
    }

    @Put('/installs/addIps')
    public addIpsToInstalls(
        @Body('ids') installIds: Array<string>,
        @Body('entityKey') entityKey: string,
        @Body('everythingSelected') everythingSelected: boolean,
        @Body('includeChildren') includeChildren: boolean,
        @Body('includeIps') includeIps: Array<string>,
        @Body('excludeIps') excludeIps: Array<string>
    ): Observable<any> {
        return null;
    }

    @Get('/installs/idsByEntity')
    public getIdsByEntity(
        @Query('id') entityKey: string,
        @Query('includeChildren') includeChildren?: boolean
    ): Observable<any> {
        return null;
    }

    @Put('/installs/{id}/bulkDeviceImport')
    public bulkDeviceImport(
        @Path('id') installKey: string,
        @Body('entityKey') entityKey: string,
        @Body('userKey') userKey: string,
        @Body('devices') devices: Array<BulkDeviceImport>
    ): Observable<any> {
        return null;
    }

    @Post('/installs/{id}/shutdown')
    public shutdown(@Path('id') installId: string): Observable<any> {
        return null;
    }

    @Post('/installs/{id}/uninstall')
    public uninstall(@Path('id') installId: string): Observable<any> {
        return null;
    }

    @Post('/installs/{id}/reactivate')
    public reactivate(@Path('id') installId: string): Observable<any> {
        return null;
    }

    @Get('/installs/moduleVersions')
    public getModuleVersions(@Query('module') module: string): Observable<any> {
        return null;
    }

    @Post('/installs/{id}/deactivate')
    public deactivate(@Path('id') installId: string): Observable<any> {
        return null;
    }

    @Post('/installs/mostRecentlyActive', ResponseType.Text)
    public getMostRecentlyActive(
        @Body('installKeys') ids: Array<string>,
        @Body('withRemoteTechnician') withRemoteTechnician: boolean
    ): Observable<any> {
        return null;
    }

    @MapClass(ReportingDevices)
    @Get('/installs/{id}/reportingDevices')
    public getReportingDevices(@Path('id') installId: string): Observable<ReportingDevices> {
        return null;
    }

    @Get('/installs/{id}/relatedByDevices')
    public relatedByDevices(
        @Path('id') installId: string
    ): Observable<Array<Install & IWithReportingDevices>> {
        return null;
    }

    @Post('/installs/count/devices', ResponseType.Text)
    public getDeviceCountByInstalls(@Body('ids') ids: Array<string>): Observable<number> {
        return null;
    }

    // DYNAMIC SETTINGS
    @Get('/installs/{id}/settingsForm')
    public settingsGetFormData(
        @Path('id') id: string
    ): Observable<{ settings: ISettings; inheritance: ISettings }> {
        return null;
    }

    /**
     * Resolve settings for an install
     * @param {string} id  install to resolve settings for
     * @returns {Observable<ISettings>}  resolved settings object
     */
    @Get('/installs/{id}/settings')
    public settingsResolveOne(@Path('id') id: string): Observable<ISettings> {
        return null;
    }

    /**
     * Resolve settings for several installs simultaneously
     * @param {string[]} ids  installs to resolve settings for
     * @param {SettingsKeyType[]} settings  if non-empty, will only gather these settings
     * @returns {Object[]} List of id-settings pairs corresponding to the ids in 'ids'
     */
    @Post('/installs/settings')
    public settingsResolveMany(
        @Body('ids') ids: string[],
        @Body('settings') settings: DynamicSettingsKeyType[] = []
    ): Observable<{ _id: string; settings: ISettings }[]> {
        return null;
    }

    /**
     * Finds install in DB matching 'id', and replaces contents of settings property with 'updates'.
     * @param {string} id  install to update
     * @param {object} updates  list of settings key-value pairs
     * @returns {Observable} status information resulting from DB operation (should we do this?)
     */
    @Put('/installs/{id}/settings')
    public settingsSet(
        @Path('id') id: string,
        @Body('updates') updates: ISettingsFormSubmission
    ): Observable<any> {
        return null;
    }

    // END DYNAMIC SETTINGS

    @Get('/installs/{id}/deviceCollectionResults')
    // tslint:disable-next-line:ter-prefer-arrow-callback
    @Adapter(function(res): PaginatedList<DeviceCollectionResultWithDevice> {
        const list = new PaginatedList<DeviceCollectionResultWithDevice>();
        const docs = Array.isArray(res.docs) ? res.docs : [];
        list.docs = docs.map(d => ({ ...new DeviceCollectionResults(d), ...new Device(d) }));
        list.totalDocs = res.totalDocs || 0;
        return list;
    })
    public getDeviceCollectionResults(
        @Path('id') id: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sort?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean
    ): Observable<PaginatedList<DeviceCollectionResultWithDevice>> {
        return null;
    }

    @Get('/installs/{id}/networkTopology')
    @MapClass(InstallNetworkTopology)
    public getNetworkTopology(@Path('id') id: string): Observable<InstallNetworkTopology> {
        return null;
    }

    @Post('/installs/bulk/enable')
    public bulkEnable(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }

    @Post('/installs/bulk/disable')
    public bulkDisable(@Body('ids') ids: string[]): Observable<any> {
        return null;
    }
}
