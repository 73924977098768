import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Cart, CartItem, ItemType } from '@libs/iso/core';

@Component({
    selector: 'ptkr-review-step',
    templateUrl: './review-step.component.html',
    styleUrls: ['./review-step.component.scss']
})
export class ReviewStepComponent implements OnInit {
    @Input()
    public cart: Cart;

    @Output()
    public removeItem: EventEmitter<CartItem> = new EventEmitter<CartItem>();
    // prettier-ignore
    @Output() public updateItem: EventEmitter<{ qty: number, item: CartItem }>
        = new EventEmitter<{ qty: number, item: CartItem }>();

    public ItemType: typeof ItemType = ItemType;

    constructor() {}

    ngOnInit(): void {}

    public updateItemQty(qty: number, item: CartItem): void {
        this.updateItem.emit({
            qty: qty,
            item: item
        });
    }
}
