import { Injectable } from '@angular/core';
import { HttpService, Get, Query } from '@libs/web/http';
import { Observable } from 'rxjs';
import { DGICandidate } from '@libs/iso/core';

@Injectable()
export class DGICandidateService extends HttpService {
    @Get('/dgiCandidate/byItem')
    public byItem(@Query('id') itemKey: string): Observable<DGICandidate> {
        return null;
    }
}
