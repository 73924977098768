<mat-card>
        <h2>Sales rep contact info</h2>
        <h3 class="sub-header">Have questions? Contact:</h3>
        <div class="user-info">
            <div class="profile-image">
                <i class="fas fa-user-circle"></i>
            </div>
            <div class="contact-info">
                <span class="name"><strong>{{ user.firstName }} {{ user.lastName }}</strong></span>
                <span class="email">{{ user.email }}</span>
                <span class="phone" *ngIf="phone">{{ phone }}</span>
            </div>
        </div>
</mat-card>
