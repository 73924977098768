import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { Subject } from 'rxjs';
import { ViewportRuler, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ptkr-dropdown-filter-molecule',
    templateUrl: './dropdown-filter-molecule.component.html',
    styleUrls: ['./dropdown-filter-molecule.component.scss']
})
export class DropdownFilterMoleculeComponent implements OnInit {
    // Nessiary in order to resize popup to match element of origin (#trigger)
    @ViewChild('selectControl', { static: true }) private _origin: ElementRef;

    @Input()
    public dropdownContent: PolymorpheusContent<never>;

    @Input()
    public selectedContent: PolymorpheusContent<never>;

    @Input()
    public filterTextPlaceholder: string = 'Search';

    @Input()
    public disabled: boolean = false;

    @Input()
    public showDropdown: boolean;
    @Output()
    public showDropdownChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public filterText: string;
    @Output()
    public filterTextChange: EventEmitter<string> = new EventEmitter<string>();

    protected readonly _destroy: Subject<void> = new Subject<void>();

    public originRect: ClientRect;

    constructor(private _viewportRuler: ViewportRuler, private _cd: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this._viewportRuler
            .change()
            .pipe(takeUntil(this._destroy))
            .subscribe(() => {
                if (this.showDropdown) {
                    this.originRect = this._origin.nativeElement.getBoundingClientRect();
                    this._cd.markForCheck();
                }
            });
    }

    public ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    public openIfClosed(event: Event): void {
        if (!this.showDropdown && !this.disabled) {
            this.originRect = this._origin.nativeElement.getBoundingClientRect();
            this.showDropdownChange.emit(true);
            event.stopPropagation();
        }
    }

    public closeIfOpened(): void {
        if (this.showDropdown) {
            this.showDropdownChange.emit(false);
        }
    }

    public onFilterInput(event: string): void {
        this.filterTextChange.emit(event);
    }
}
