import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@app/src/app/core/services/notification.service';
import { EntityService } from '@app/src/app/core/services/entity.service';
import { RouterService } from '@app/core/services/router.service';
import { GlobalStore } from '@app/state/store';
import { Store, select } from '@ngrx/store';
import { fromUser } from '@app/state/selectors';
import { RequestEntityChangeAction } from '@app/state/actions';

// NOTE: If we were to ever need another delete modal for something else, instead
// of making another one completely, just extract the common behavior from this one.
// Delete entity could become a wrapper for a 'delete component' that uses ngContent
// to decide the text displayed above the delete textbox and action buttons.

@Component({
    selector: 'ptkr-delete-entity-modal',
    templateUrl: './delete-entity-modal.component.html',
    styleUrls: ['./delete-entity-modal.component.scss']
})
export class DeleteEntityModalComponent {
    public entity: { name: string, _id: string };
    public delButDisabled: boolean = true;

    private directParentEntityId: string;

    constructor(
        private _dialogRef: MatDialogRef<DeleteEntityModalComponent>,
        private _cd: ChangeDetectorRef,
        private _entityService: EntityService,
        private _notificationService: NotificationService,
        private _routerService: RouterService,
        private _store: Store<GlobalStore>,
        @Inject(MAT_DIALOG_DATA)
        public data: { entity: { name: string, _id: string } }
    ) {
        this.entity = data.entity;

        // Get direct parent entity id
        this._entityService.getEntityDetails(this.entity._id).subscribe(entity => {
            const numberOfAncestors = entity.breadcrumb.length;
            this.directParentEntityId = entity.breadcrumb[numberOfAncestors - 1]._id;
        });
    }

    public setDelButDisabled(event: boolean): void {
        this.delButDisabled = !event;
        this._cd.detectChanges();
    }

    public close(): void {
        this._dialogRef.close();
    }

    public onConfirmation(): void {
        this._entityService.removeEntity(this.entity._id).subscribe(
            res => {
                this._dialogRef.close();
                this._store.dispatch(new RequestEntityChangeAction(this.directParentEntityId));
                this._routerService.fullNavigate(this._routerService.getParentEntityRedirect(this.directParentEntityId));
            },
            err => {
                this._dialogRef.close();
                this._notificationService.error(err);
            }
        );
    }

    public gotoMoveEntities(): void {
        this._dialogRef.close();
        this._routerService.navigate('/admin/hierarchy');
    }

    public gotoManageUsers(): void {
        this._dialogRef.close();
        this._routerService.navigate('/admin/users/list');
    }
}
