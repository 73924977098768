import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { UserActions, LoginUserAction, LoginEntityAction, UpdateUserAction, LogoutAction } from '../actions';
import { GlobalStore } from '@app/state/store';
import { fromUser } from '@app/state/selectors';
import { take } from 'rxjs/operators';
import { User, Entity } from '@app/models';
import { Store, select, Action } from '@ngrx/store';
import {
    StorageService,
    EntityService,
    NotificationService,
    RouterService,
    UserService
} from '@app/services';
import { of } from 'rxjs';

@Injectable()
export class UserEffects {
    constructor(
        private _actions$: Actions,
        private _storageService: StorageService,
        private _entityService: EntityService,
        private _userService: UserService,
        private _notificationService: NotificationService,
        private _store: Store<GlobalStore>,
        private _routerService: RouterService
    ) {}

    @Effect()
    public loginUser$: Observable<Action> = this._actions$.pipe(
        ofType(UserActions.LoginUser),
        map((action: LoginUserAction) => action.payload),
        switchMap((payload: User) => {
            // If router param entity Id exists, defer to that, else load from user
            const entityIdFromUrl = this._routerService.initUrlEntityId();
            if (entityIdFromUrl) {
                return of(entityIdFromUrl);
            } else {
                return this._store.pipe(select(fromUser.entityId), take(1));
            }
        }),
        switchMap(entityId => {
            // Spencer 6/16/2020 - This makes sure that we can log the user out
            // if something goes wrong; the alternative is they have to delete
            // their own token, or otherwise are left to their own devices to
            // get the website functioning again.
            const entityDetails = this._entityService.getEntityDetails(entityId);
            entityDetails.subscribe(res => {}, err => {
                this._store.dispatch(new LogoutAction());
            });
            return entityDetails;
        }),
        switchMap(entity => of(new LoginEntityAction(entity)))
    );

    @Effect()
    public updateUser$: Observable<{}> = this._actions$.pipe(
        ofType(UserActions.UpdateUser),
        map((action: UpdateUserAction) => action.payload),
        switchMap((payload: User) => this._userService.updateUser(payload)),
        switchMap(() => of())
    );
}
