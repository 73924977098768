import { EntityTreeNode } from './EntityTreeNode';

export interface IEntityTree {
    root: EntityTreeNode;
}

export class EntityTree implements IEntityTree, Iterable<EntityTreeNode> {
    constructor(public root: EntityTreeNode = null) {}

    public [Symbol.iterator](): Iterator<EntityTreeNode> {
        return this.breadthFirst();
    }

    // https://stackoverflow.com/a/33704700

    public *breadthFirst(): IterableIterator<EntityTreeNode> {
        const queue = [this.root];
        let n;

        while (queue.length > 0) {
            n = queue.shift();
            yield n;

            for (let i = 0; i < n.children.length; i++) {
                queue.push(n.children[i]);
            }
        }
    }

    public *depthFirst(): IterableIterator<EntityTreeNode> {
        const stack = [this.root];
        let n;

        while (stack.length > 0) {
            n = stack.pop();
            yield n;

            for (let i = n.children.length - 1; i >= 0; i--) {
                stack.push(n.children[i]);
            }
        }
    }
}
