<div class="flex-container flex-col border border-neutral-300">
    <div class="flex-child bg-neutral-100 border-b border-neutral-300 px-2">
        <span class="name">{{ name }}</span>
    </div>
    <div *ngIf="!!format" class="flex-container flex-row flex-child">
        <ptkr-meter-format-pill
            class="format-pill"
            [showDefaultAsBilling]="true"
            [format]="format"
        ></ptkr-meter-format-pill>
    </div>
    <div class="flex-child">
        <span class="font-bold p-2 text-2xl">
            {{ value }}
        </span>
    </div>
</div>
