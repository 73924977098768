<h2 mat-dialog-title><code>{{data.key}}</code></h2>
<mat-dialog-content>
<!--    <kd-text-input [text]="data.value"-->
<!--                   [readOnly]="true"-->
<!--                   mode="json"></kd-text-input>-->
    <span>Empty</span>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button
            color="primary"
            [mat-dialog-close]="true"
            i18n>Close</button>
</mat-dialog-actions>
