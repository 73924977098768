import { Action } from '@ngrx/store';

export enum AppActions {
    ToggleGlobalSpinner = '[app] toggle global spinner'
}

export class ToggleGlobalSpinnerAction implements Action {
    public readonly type: AppActions.ToggleGlobalSpinner = AppActions.ToggleGlobalSpinner;
    public payload: boolean = false;
    constructor(_visible: boolean) {
        this.payload = _visible;
    }
}

export type AppActionsType = ToggleGlobalSpinnerAction;
