import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity, fromUser } from '@app/state/selectors';
import { environment } from '@app/env/environment';

@Injectable()
export class RouterService {
    private _initEntityId: string = '';
    private _currentEntityId: string = '';
    private _currentUserEntityId: string = '';
    private _loginRedirectUrl: string = '';
    private _entityChangedRedirectUrl: string = '';
    private _initQueryParams: Object = {};

    constructor(private _router: Router, private _store: Store<GlobalStore>) {
        // initPath is either /entity/:entityId/... OR /entity/...
        const initPath: string = location.pathname;
        // splitPath[0] is always empty (path starts with '/')
        // splitPath[1] is always 'entity' if logged in
        // splitPath[2] is either an id (24 chars) or a path
        const queryParams: string = location.search.substring(1);
        if (!!queryParams) {
            this._initQueryParams = JSON.parse(
                '{"' + queryParams.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
                (key, value) => (key === '' ? value : decodeURIComponent(value))
            );
        }
        const allowedBaseUrls = environment.allowedBaseUrls;
        this._initEntityId = this.getEntityIdFromUrl(initPath);
        const splitPath: string[] = initPath.split('/');
        if (
            splitPath.length > 0 &&
            splitPath[1] &&
            allowedBaseUrls.indexOf(splitPath[1].toUpperCase()) > -1
        ) {
            this._loginRedirectUrl = initPath;
        }
        this._store
            .pipe(select(fromEntity.entityId))
            .subscribe(entityId => (this._currentEntityId = entityId));
        this._store
            .pipe(select(fromUser.entityId))
            .subscribe(entityId => (this._currentUserEntityId = entityId));
    }

    /**
     * Get the current entityId from a url
     *
     * @param {string} pathname location.pathname
     * @returns {string} entityId
     */
    public getEntityIdFromUrl(pathname: string): string {
        const idStringLength = 24;
        const idParamLocation = 2;
        const splitPath: string[] = pathname.split('/');
        if (
            splitPath.length > 0 &&
            splitPath[idParamLocation] &&
            splitPath[idParamLocation].length === idStringLength
        ) {
            return splitPath[idParamLocation];
        } else {
            return null;
        }
    }

    /**
     * This function returns the entity that exists in the URL at application startup
     * It is NOT accurate beyond application startup and should not be used otherwise.
     * @returns {string} entityId at application startup
     */
    public initUrlEntityId(): string {
        const id = this._initEntityId;
        this._initEntityId = '';
        return id;
    }

    public navigate(url: string, extras?: NavigationExtras): void {
        if (this._currentEntityId) {
            this.fullNavigate(`entity/${this._currentEntityId}${url}`, extras);
        } else {
            this.fullNavigate(`entity${url}`, extras);
        }
    }

    public fullNavigate(url: string, extras?: NavigationExtras): void {
        if (this._initQueryParams) {
            if (!!extras) {
                extras.queryParams = {
                    ...extras.queryParams,
                    ...this._initQueryParams
                };
            } else {
                extras = {
                    queryParams: this._initQueryParams
                };
            }
            this._initQueryParams = undefined;
        }
        this._router.navigate([url], extras);
    }

    /**
     * This function is used to notify the router service that the entity has changed.
     * It is called by the entity effects once an entity has been changed in the system
     * to redirect back to the entity dashboard or another route specified by the
     * setOneTimeEntityChangedRedirect method.
     * @returns {void}
     */
    public entityChanged(): void {
        let url = location.pathname.replace(
            this.getEntityIdFromUrl(location.pathname),
            this._currentEntityId
        );
        if (!!this._entityChangedRedirectUrl) {
            url = this._entityChangedRedirectUrl;
            this._entityChangedRedirectUrl = '';
        }
        if (url.indexOf('entity') === 1) {
            this.fullNavigate(url, { queryParamsHandling: 'preserve' });
        } else {
            this.navigate(url, { queryParamsHandling: 'preserve' });
        }
    }

    /**
     * This function sets a one time redirect that will be called after the entity is changed.
     * @param {string} redirect url to redirect ot
     * @returns {void}
     */
    public setOneTimeEntityChangedRedirect(redirect: string): void {
        this._entityChangedRedirectUrl = redirect;
    }

    public setLoginRedirect(url: string): void {
        this._loginRedirectUrl = url;
    }

    public getLoginRedirect(): string {
        if (this._loginRedirectUrl !== '') {
            const redirect = this._loginRedirectUrl;
            this._loginRedirectUrl = '';
            return redirect;
        }
        return this.getHomePageRedirect();
    }

    public getRegisterRedirect(): string {
        if (this._currentEntityId || this._currentUserEntityId) {
            return `/entity/${this._currentEntityId || this._currentUserEntityId}/home/dashboard`;
        } else {
            return '/entity/home/dashboard';
        }
    }

    public get404Redirect(): string {
        if (this._currentEntityId || this._currentUserEntityId) {
            return `/entity/${this._currentEntityId || this._currentUserEntityId}/home/dashboard`;
        } else {
            return '/entity/home/dashboard';
        }
    }

    public getHomePageRedirect(): string {
        if (this._currentEntityId || this._currentUserEntityId) {
            return `/entity/${this._currentEntityId || this._currentUserEntityId}/home/dashboard`;
        } else {
            return '/entity/home/dashboard';
        }
    }

    public getParentEntityRedirect(parentEntityId: string): string {
        return `/entity/${parentEntityId}/home/dashboard`;
    }
}
