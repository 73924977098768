import { Injectable } from '@angular/core';
import { LanguageSelector } from '../models/language-selector';

@Injectable()
export class LanguageSelectorService {
    // The list of available languages
    public static availableLanguages: LanguageSelector[] = [
        {
            code: 'en', // The default language should always be first!
            name: 'English'
        },
        {
            code: 'es',
            name: 'Español'
        },
        {
            code: 'pt',
            name: 'Portuguese'
        },
        {
            code: 'it',
            name: 'Italian'
        }
    ];
}
