import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { selectEntity } from '@app/state/store/global.store';
import { take } from 'rxjs/operators';

@Injectable()
export class WebadminV2Service {
    private static dev: boolean = /dev\.printtrackerpro.com|localhost|127\.0\.0\.1/i.test(
        window.location.hostname
    );
    private static basePath: string = `https://app-v2.${
        WebadminV2Service.dev ? 'dev.' : ''
    }printtrackerpro.com`;

    constructor(private _router: Router, private _store: Store<GlobalStore>) {}

    private open(path: string, newTab: boolean = true): void {
        window.open(`${WebadminV2Service.basePath}${path}`, newTab ? '_blank' : null);
    }

    public openHome(): void {
        this._store
            .select(selectEntity)
            .pipe(take(1))
            .subscribe(entity => {
                this.open(`/entity/${entity._id}/home`);
            });
    }

    public openDownloadOneClick(): void {
        this._store
            .select(selectEntity)
            .pipe(take(1))
            .subscribe(entity => {
                this.open(`/download?code=${entity._id}&name=${entity.name}`);
            });
    }

    public openDownload(): void {
        this.open('/download');
    }

    public openBilling(): void {
        this._store
            .select(selectEntity)
            .pipe(take(1))
            .subscribe(entity => {
                this.open(`/entity/${entity._id}/billing`);
            });
    }
}
