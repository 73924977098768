<div>
    <button
        *ptkrPermission="Permission.DaemonOnly"
        mat-button
        color="primary"
        type="button"
        (click)="goToV2()"
    >
        Print Tracker v2
    </button>
    <a
        id="cy-profile-overlay-component-profile-btn"
        mat-button
        color="primary"
        routerLink="/user/profile"
        (click)="overlayRef.close()"
        i18n
        >Profile</a
    >
    <button
        id="cy-profile-overlay-component-sign-out-btn"
        mat-button
        color="primary"
        type="button"
        (click)="logout()"
        i18n
    >
        Sign out
    </button>
</div>
