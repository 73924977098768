export enum JobOriginTypes {
    Dca = 'dca',
    WebAdmin = 'webadmin'
}

export enum JobDestinationTypes {
    Entity = 'entity',
    Install = 'install'
}

export enum JobStatusTypes {
    Sent = 'sent',
    Received = 'received',
    Running = 'running',
    Completed = 'completed',
    Failed = 'failed',
    Rescheduled = 'rescheduled',
    Stopped = 'stopped'
}
