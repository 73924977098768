import { ObjectId } from 'bson';
import { Status } from './Status';
import { DocFactory } from './DocFactory';
import { Column, colKeyOneProp } from '../helpers/columns';
import * as Moment from 'moment';

export interface IBaseModel {
    _id: ObjectId | string;
    status: Status;
    createdDate: Date | Moment.Moment;
    modifiedDate: Date | Moment.Moment;
    labels?: { [key: string]: string };
}

export class BaseModel extends DocFactory implements IBaseModel {
    public static columns: Array<Column> = [
        {
            name: 'Model Id',
            keys: colKeyOneProp('_id'),
            description: 'Model\'s unique Id.',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Status',
            keys: colKeyOneProp('status'),
            description: 'Status of the model-- Be it active or inactive.',
            display: Column.displayFunctions['']
        },
        {
            name: 'Date Created',
            keys: colKeyOneProp('createdDate'),
            description: 'Date that this data was first generated',
            display: Column.displayFunctions['date'],
            sortable: true
        },
        {
            name: 'Last Modified',
            keys: colKeyOneProp('modifiedDate'),
            description: 'Date that this data was last modified',
            display: Column.displayFunctions['date'],
            sortable: true
        },
        {
            name: 'Date Created (time ago)',
            keys: colKeyOneProp('createdDate'),
            description: 'Date that this data was first generated (presented as time passed since then to now)',
            display: Column.displayFunctions[''],
            amTimeAgo: true
        }
    ];

    public _id: ObjectId | string = null;
    public status: Status = Status.Active;
    public createdDate: Date | Moment.Moment = new Date();
    public modifiedDate: Date | Moment.Moment = new Date();
    public labels?: { [key: string]: string };

    constructor(defaults?: Partial<IBaseModel>) {
        super();
        if (!!defaults) {
            this._id = defaults._id ?? this._id;
            this.status = defaults.status ?? this.status;
            this.createdDate = defaults.createdDate ?? this.createdDate;
            this.modifiedDate = defaults.modifiedDate ?? this.modifiedDate;
            if (defaults.labels) {
                this.labels = defaults.labels;
            }
        }
    }
}
