import { ISettingsFormSubmission } from '@libs/iso/core';
import { SettingsFormSubmission } from '@libs/iso/core/models/SettingsFormSubmission';
import {
    DeviceDynamicSettingsSectionFormGroup,
    DeviceNonInheritedSettingsFormGroup, GeneralInfoDynamicSettingsSectionFormGroup,
    InstallDynamicSettingsSectionFormGroup,
    SharedDynamicSettingsSectionFormGroup
} from '@libs/web/forms';
import * as _ from 'lodash';

export interface ISettingsFormGroups {
    device?: DeviceNonInheritedSettingsFormGroup;
    install?: any; // InstallMutablePropertyFormGroup
    entity?: any; // EntityMutablePropertyFormGroup
    dynamic: {
        device?: DeviceDynamicSettingsSectionFormGroup;
        install?: InstallDynamicSettingsSectionFormGroup;
        shared?: SharedDynamicSettingsSectionFormGroup;
        generalInfo?: GeneralInfoDynamicSettingsSectionFormGroup;
    };
}

/**
 * SettingsForm components use this class to store FormGroup data. Not all SettingsForms use every
 * property in the class, but keeping to a standard format minimizes code duplication and makes
 * transferring the data to the API easier as our configuration panels grow more complex.
 * toSettingsForSubmission can be called to retrieve the current state of
 * the contained FormGroups.
 * @see toSettingsFormSubmission
 */
export class SettingsFormGroups implements ISettingsFormGroups {
    public device?: DeviceNonInheritedSettingsFormGroup;
    public install?: any; // InstallMutablePropertyFormGroup
    public entity?: any; // EntityMutablePropertyFormGroup
    public dynamic: {
        device?: DeviceDynamicSettingsSectionFormGroup;
        install?: InstallDynamicSettingsSectionFormGroup;
        shared?: SharedDynamicSettingsSectionFormGroup;
        generalInfo?: GeneralInfoDynamicSettingsSectionFormGroup;
    } = {};

    constructor(params?: Partial<ISettingsFormGroups>) {
        if (!!params) {
            this.device = params.device ?? undefined;
            this.install = params.install ?? undefined;
            this.entity = params.entity ?? undefined;
            if (!!params.dynamic) {
                this.dynamic.device = params.dynamic.device ?? undefined;
                this.dynamic.install = params.dynamic.install ?? undefined;
                this.dynamic.shared = params.dynamic.shared ?? undefined;
                this.dynamic.generalInfo = params.dynamic.generalInfo ?? undefined;
            }
        }
    }

    /**
     * Generates a ISettingsFormSubmission object using whatever data is currently available.
     * Used by SettingsForms when users click a submission button. It calls
     * 'extractOverwrittenValues' on every non-nullish FormGroup.
     * @see (AbstractSettingsFormGroup.extractOverwrittenValues)
     * @returns {ISettingsFormSubmission}  value of updated settings according to formGroups
     */
    public toSettingsFormSubmission(): ISettingsFormSubmission {
        const submission: SettingsFormSubmission = new SettingsFormSubmission();
        submission.add(this.device?.extractOverwrittenValues(), 'device');
        submission.add(this.install?.extractOverwrittenValues(), 'install');
        submission.add(this.entity?.extractOverwrittenValues(), 'entity');
        submission.add(
            _.merge(
                {},
                ...[
                    this.dynamic?.device?.extractOverwrittenValues(),
                    this.dynamic?.install?.extractOverwrittenValues(),
                    this.dynamic?.shared?.extractOverwrittenValues(),
                    this.dynamic?.generalInfo?.extractOverwrittenValues(),
                    this.entity?.extractOverwrittenValues({ settingsOnly: true})
                ]
            ),
            'settings'
        );
        return submission;
    }
}
