import { InjectionToken } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';
import { RouterService } from '@app/services';

export const SELECT_ENTITY_OVERLAY_DATA = new InjectionToken<any[]>('SELECT_ENTITY_OVERLAY_DATA');

export class SelectEntityOverlayRef {
    private _afterClosed$: Subject<void> = new Subject<void>();

    constructor(private _overlayRef: OverlayRef, private _routerService: RouterService) {}

    public close(): void {
        this._overlayRef.dispose();
        this._afterClosed$.next();
    }

    public afterClosed(): Observable<void> {
        return this._afterClosed$.asObservable();
    }

    public addNewEntity(): void {
        this._routerService.navigate('/admin/entity/add');
        this.close();
    }
}
