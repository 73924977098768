import { CategoricalDistributionElement } from '@libs/iso/core';

export type WithReportingDeviceFacets = {
    reportingDevices: IReportingDeviceFacet[];
};

export interface IReportingDeviceFacet {
    _id: any;
    count: number;
}

export interface IReportingDevices {
    percentage: number;
    reportingDevices: number;
    totalDevices: number;
}

export class ReportingDevices implements IReportingDevices {
    public percentage: number = 0;
    public reportingDevices: number = 0;
    public totalDevices: number = 0;

    constructor(defaults?: Partial<IReportingDevices>) {
        if (defaults) {
            this.percentage = defaults.percentage || this.percentage;
            this.reportingDevices = defaults.reportingDevices || this.reportingDevices;
            this.totalDevices = defaults.totalDevices || this.totalDevices;
        }
    }

    public toCategoricalDistributionElement(): CategoricalDistributionElement[] {
        return [{
            category: 'Reporting',
            value: this.reportingDevices,
            color: '#6EDD9D'
        }, {
            category: 'Not Reporting',
            value: this.totalDevices - this.reportingDevices,
            color: '#FF7474'
        }];
    }
}
