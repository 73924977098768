import { Component, Input } from '@angular/core';
import {
    CalculatedDistribution,
    CategoricalDistributionElement,
    CategoricalDistributor,
} from '@libs/iso/core';

@Component({
    selector: 'ptkr-categorical-distribution-inline',
    templateUrl: './component.html',
    styleUrls: ['./component.scss']
})
export class CategoricalDistributionInlineComponent {
    @Input() public set elements(elements: CategoricalDistributionElement[]) {
        if (!elements) {
            return;
        }
        this.distributions = new CategoricalDistributor(
            elements.filter(v => v.value !== 0)
        ).calculateDistributions();
    }

    @Input() public height: number = 8;
    public distributions: CalculatedDistribution[] = [];

    constructor() {}

    public getHeight(): string {
        return `${this.height}px`;
    }
}
