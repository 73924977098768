export const constants = {
    securedHeaderHeight: 60,
    securedHeaderHeightPx: '60px',
    securedHeaderProfileBarHeight: 52,
    securedHeaderProfileBarHeightPx: '52px',
    securedHeaderEntityBarHeightMobile: 62,
    securedHEaderEntityBarHeightMobilePx: '62px',
    securedSidenavTransitionTime: 0.22,
    securedSidenavTransitionTimeS: '0.22s',

    // Breakpoints from Angular Material: https://material.angular.io/cdk/layout/overview
    breakpoints: {
        mobile: {
            portrait: 599.98, // max-width
            landscape: 959.98 // max-width
        },
        tablet: {
            portrait: 600,    // min-width
            landscape: 960    // min-width
        },
        web: {
            portrait: 840,    // min-width
            landscape: 1280   // min-width
        }
    }
};
