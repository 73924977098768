import { BaseModel, IBaseModel } from '@libs/iso/core/models/BaseModel';
import { ObjectId } from 'bson';
import {
    IReportScheduledExport,
    ReportScheduledExport
} from '@libs/iso/core/models/reportScheduled/ReportScheduledExport';
import { ReportScheduledEvent, IReportScheduledEvent } from './ReportScheduledEvent';
import * as Moment from 'moment';

export interface IReportScheduled extends IBaseModel {
    reportKey: string | ObjectId;
    entityKey: string | ObjectId;
    userKey: string | ObjectId;
    enabled: boolean;
    schedule: string;
    timezone: string;
    export: IReportScheduledExport;
    nextScheduledTime: Date | Moment.Moment;
    events: IReportScheduledEvent[];
}

export class ReportScheduled extends BaseModel implements IReportScheduled {
    public reportKey: string | ObjectId;
    public entityKey: string | ObjectId;
    public userKey: string | ObjectId;
    public enabled: boolean = true;
    public schedule: string = '0 12 L 1/1 ?'; // noon on the last day of every month
    public timezone: string = null;
    public export: ReportScheduledExport = new ReportScheduledExport();
    public nextScheduledTime: Date | Moment.Moment = null; // the emailer will handle setting this, we should never be touching it
    public events: ReportScheduledEvent[] = [];

    constructor(params?: Partial<IReportScheduled>) {
        super(params);
        if (params != null) {
            this.reportKey = params.reportKey ?? this.reportKey;
            this.entityKey = params.entityKey ?? this.entityKey;
            this.userKey = params.userKey ?? this.userKey;
            this.enabled = params.enabled ?? this.enabled;
            this.schedule = params.schedule ?? this.schedule;
            this.timezone = params.timezone ?? this.timezone;
            this.export = new ReportScheduledExport(params.export) ?? this.export;
            this.nextScheduledTime =
                params.nextScheduledTime != null
                    ? Moment(params.nextScheduledTime)
                    : this.nextScheduledTime;
            this.events =
                params.events != null
                    ? params.events.map(e => new ReportScheduledEvent(e))
                    : this.events;
        }
    }
}

export class ReportScheduledWithMetadata extends ReportScheduled {
    public entityName: string;
    constructor(params: Partial<ReportScheduledWithMetadata>) {
        super(params);
        this.entityName = params.entityName;
    }
}
