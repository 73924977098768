<div class="desktop">
    <div class="secured-header border-b-4" [ngStyle]="{'border-color': headerBorderColor$ | async }">
        <div class="entities">
            <div class="breadcrumb">
                <ptkr-breadcrumb
                    [breadcrumb]="entity.breadcrumb"
                    (select)="changeEntity.emit($event)"
                ></ptkr-breadcrumb>
            </div>
            <div class="selected-entity">
                <h1
                    id="cy-new-secured-header-component-entity-selector-btn"
                    (click)="toggleEntityDropdown.emit()"
                >
                    {{ entity.name }}
                    <span class="arrow-icon">
                        <i class="fas fa-chevron-down"></i>
                    </span>
                </h1>
            </div>
        </div>
        <div class="search-bar">
            <ptkr-entity-picker
                placeholder="Search entities"
                i18n-placeholder
                [defaultEntity]="entity"
                (entity)="onEntitySearchbarSelection($event)"
            ></ptkr-entity-picker>
        </div>
        <div class="language-selector">
            <ptkr-language-selector></ptkr-language-selector>
        </div>
        <div class="icons">
            <!-- <span
                *ptkrPermission="Permission.OrderToner"
                class="icon cart"
                size="small"
                [matBadge]="truncateNumber(cartNotificationCount)"
                [matBadgeHidden]="cartNotificationCount < 1"
                matBadgeColor="warn"
                (click)="viewCart.emit()">
                <i class="fas fa-shopping-cart"></i>
            </span> -->
            <!--            <span-->
            <!--                id="cy-new-secured-header-component-alert-dropdown-btn"-->
            <!--                #alertRef-->
            <!--                class="icon alert"-->
            <!--                size="small"-->
            <!--                [matBadge]="truncateNumber(alertNotificationCount)"-->
            <!--                [matBadgeHidden]="alertNotificationCount < 1"-->
            <!--                matBadgeColor="alert"-->
            <!--                (click)="toggleAlertNotificationsDropdown.emit(alertRef)">-->
            <!--                <i class="fas fa-bell"></i>-->
            <!--            </span>-->
            <!--            <span-->
            <!--                #serviceRef-->
            <!--                class="icon service"-->
            <!--                size="small"-->
            <!--                [matBadge]="truncateNumber(serviceNotificationCount)"-->
            <!--                [matBadgeHidden]="serviceNotificationCount < 1"-->
            <!--                matBadgeColor="warn"-->
            <!--                (click)="toggleServiceNotificationsDropdown.emit(serviceRef)">-->
            <!--                <i class="fas fa-wrench"></i>-->
            <!--            </span>-->
            <span
                #profileRef
                id="cy-new-secured-header-component-user-dropdown-btn"
                class="user-name"
                (click)="toggleProfileDropdown.emit(profileRef)"
            >
                {{ user.firstName }} {{ user.lastName }}
                <span class="icon arrow">
                    <i class="fas fa-chevron-down"></i>
                </span>
            </span>
        </div>
    </div>
    <ptkr-message-bar (dismiss)="dismissWelcomeWizardPrompt()" *ngIf="showWelcomeWizardPrompt() | async" i18n>
        Is this your first time using Print Tracker? Download and get started by&nbsp;<a (click)="openWelcomeWizard()">clicking here</a>.
    </ptkr-message-bar>
</div>

<!-- -->

<div class="mobile">
    <div class="secured-header">
        <a color="accent" (click)="toggleMenu.emit()">
            <i style="color: #8e9caa;" class="fas fa-bars"></i>
        </a>
        <div class="icons">
            <!-- <span
                class="icon cart"
                size="small"
                [matBadge]="cartNotificationCount"
                [matBadgeHidden]="cartNotificationCount < 1"
                matBadgeColor="warn"
                (click)="viewCart.emit()">
                <i class="fas fa-shopping-cart"></i>
            </span> -->
            <!--            <span-->
            <!--                #alertRefMobile-->
            <!--                class="icon alert"-->
            <!--                size="small"-->
            <!--                [matBadge]="alertNotificationCount"-->
            <!--                [matBadgeHidden]="alertNotificationCount < 1"-->
            <!--                matBadgeColor="accent"-->
            <!--                (click)="toggleAlertNotificationsDropdown.emit(alertRefMobile)">-->
            <!--                <i class="fas fa-bell"></i>-->
            <!--            </span>-->
            <!--            <span-->
            <!--                #serviceRefMobile-->
            <!--                class="icon service"-->
            <!--                size="small"-->
            <!--                [matBadge]="serviceNotificationCount"-->
            <!--                [matBadgeHidden]="serviceNotificationCount < 1"-->
            <!--                matBadgeColor="accent"-->
            <!--                (click)="toggleServiceNotificationsDropdown.emit(serviceRefMobile)">-->
            <!--                <i class="fas fa-wrench"></i>-->
            <!--            </span>-->
            <span
                #profileRefMobile
                class="user-name"
                (click)="toggleProfileDropdown.emit(profileRefMobile)"
            >
                <span class="icon user">
                    <i class="fas fa-user"></i>
                </span>
            </span>
        </div>
        <div class="entities">
            <div class="breadcrumb">
                <ptkr-breadcrumb
                    [breadcrumb]="entity.breadcrumb"
                    (select)="changeEntity.emit($event)"
                ></ptkr-breadcrumb>
            </div>
            <div class="selected-entity">
                <h3 (click)="toggleEntityDropdown.emit()">
                    {{ entity.name }}
                </h3>
                <span class="arrow-icon" (click)="toggleEntityDropdown.emit()">
                    <i class="fas fa-chevron-down"></i>
                </span>
            </div>
        </div>
    </div>
    <ptkr-message-bar (dismiss)="dismissWelcomeWizardPrompt()" *ngIf="showWelcomeWizardPrompt() | async" i18n>
        Is this your first time using Print Tracker? Download and get started by&nbsp;<a (click)="openWelcomeWizard()">clicking here</a>.
    </ptkr-message-bar>
</div>
