import * as moment from 'moment';

export enum InstallDashboardDateBucket {
    UnderOneDay,
    OneToSevenDays,
    SevenToThirtyDays,
    OverThirtyDays
}

export namespace InstallDashboardDateBucket {
    export function fromString(str: string): InstallDashboardDateBucket | null {
        switch (str) {
            case '< 1 day':
                return InstallDashboardDateBucket.UnderOneDay;
            case '1-7 days':
                return InstallDashboardDateBucket.OneToSevenDays;
            case '7-30 days':
                return InstallDashboardDateBucket.SevenToThirtyDays;
            case '> 30 days':
                return InstallDashboardDateBucket.OverThirtyDays;
            default:
                return null;
        }
    }
    export function toDateRange(bucket: InstallDashboardDateBucket): [startDate: Date, endDate: Date] {
        switch (bucket) {
            case InstallDashboardDateBucket.UnderOneDay:
                return [moment().subtract('1', 'day').toDate(), null];
            case InstallDashboardDateBucket.OneToSevenDays:
                return [moment().subtract('7', 'day').toDate(), moment().subtract('1', 'day').toDate()];
            case InstallDashboardDateBucket.SevenToThirtyDays:
                return [moment().subtract('30', 'day').toDate(), moment().subtract('7', 'day').toDate()];
            case InstallDashboardDateBucket.OverThirtyDays:
                return [null, moment().subtract('30', 'day').toDate()];
            default:
                return [null, null];
        }
    }
}
