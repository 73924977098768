import { IQueryEngineSchemaItem, QueryEngineSchemaItem } from './QueryEngineSchemaItem';

export interface IQueryEngineResult<T> {
    recordset: Array<T>;
    performance: IQueryEngineResultPerformance;
    schema: Array<IQueryEngineSchemaItem>;
}

interface IQueryEngineResultPerformance {
    cached: boolean;
    queryStartTime: Date;
    queryEndTime: Date;
    queryDurationNs: number;
    decodingStartTime: Date;
    decodingEndTime: Date;
    decodingDurationNs: number;
    totalDurationNs: number;
    totalDurationStr: string;
    rowsReturned: number;
    recordsetBytes: number;
    recordsetBytesStr: string;
}

export class QueryEngineResultPerformance implements IQueryEngineResultPerformance {
    public cached: boolean;
    public decodingDurationNs: number;
    public decodingEndTime: Date;
    public decodingStartTime: Date;
    public queryDurationNs: number;
    public queryEndTime: Date;
    public queryStartTime: Date;
    public recordsetBytes: number;
    public recordsetBytesStr: string;
    public rowsReturned: number;
    public totalDurationNs: number;
    public totalDurationStr: string;
    constructor(params: IQueryEngineResultPerformance) {
        this.cached = params.cached;
        this.decodingDurationNs = params.decodingDurationNs;
        this.decodingEndTime = params.decodingEndTime;
        this.decodingStartTime = params.decodingStartTime;
        this.queryDurationNs = params.queryDurationNs;
        this.queryEndTime = params.queryEndTime;
        this.queryStartTime = params.queryStartTime;
        this.recordsetBytes = params.recordsetBytes;
        this.recordsetBytesStr = params.recordsetBytesStr;
        this.rowsReturned = params.rowsReturned;
        this.totalDurationNs = params.totalDurationNs;
        this.totalDurationStr = params.totalDurationStr;
    }
}

export class QueryEngineResult<T> implements IQueryEngineResult<T> {
    public performance: IQueryEngineResultPerformance;
    public recordset: Array<T>;
    public schema: Array<QueryEngineSchemaItem>;
    constructor(params?: IQueryEngineResult<T>) {
        if (params != null) {
            this.recordset = params.recordset;
            this.performance = new QueryEngineResultPerformance(params.performance);
            this.schema = params.schema.map(s => new QueryEngineSchemaItem(s));
        }
    }
}

// todo: make this type safe so that tuple[0] values must be keys in T
// type QueryEngineOrderedResultRecordset<T extends Object> = [key of T, any][][];

export class QueryEngineOrderedResult<T extends Object> {
    public performance: IQueryEngineResultPerformance;
    public recordset: [string, any][][];
    constructor(params?: Partial<QueryEngineOrderedResult<T>>) {
        if (params != null) {
            this.recordset = params.recordset;
            this.performance = new QueryEngineResultPerformance(params.performance);
        }
    }

    public hasNoRecords(): boolean {
        return (
            this.recordset == null || this.recordset.length === 0 || this.recordset[0].length === 0
        );
    }

    public orderedFields(): string[] {
        if (this.hasNoRecords()) {
            return [];
        }
        return this.recordset[0].map(r => r[0]);
    }

    public toObjects(): { [key: string]: any }[] {
        const out = [];
        for (const row of this.recordset) {
            const obj: { [key: string]: any } = {};
            for (const col of row) {
                obj[col[0]] = col[1];
            }
            out.push(obj);
        }
        return out;
    }
}
