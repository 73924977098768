import { BaseModel, IBaseModel } from '../BaseModel';
import { ObjectId } from 'bson';
import { IPageCountMeterRead, PageCountMeterRead } from './PageCountMeterRead';
import { Moment } from 'moment';
import { IMeterReadSupply, MeterReadSupply } from '@libs/iso/core/models/meterRead/MeterReadSupply';
import { Column, colKeyOneProp } from '@libs/iso/core/helpers/columns';
import { CollectionName, Color } from '../../enums';
import { TimeSeries } from '@libs/iso/core/models/meterRead/TimeSeries';
import {
    TimeSeriesSelector,
    newSupplyPercentRemainingTimeSeriesSelector
} from '@libs/iso/core/models/meterRead/TimeSeriesSelector';
import { LineSeriesOption, SeriesOption } from 'echarts';
import { MeterReadSupplyEntry } from '@libs/iso/core/models/meterRead/MeterReadSupplyEntry';

interface ISupplyWithDisplayableName {
    key: string;
    displayableName: string;
}

export type MeterReadOtherProperties = {[key: string]: {displayName: string, value: string}};

export interface IMeterRead extends IBaseModel {
    deviceKey: ObjectId | string;
    entityKey: ObjectId | string;
    installKey: ObjectId | string;
    readDtTm: Date | Moment;
    reportDtTm: Date | Moment;
    pageCounts: IPageCountMeterRead;
    supplies: IMeterReadSupply;
    avgFill: number;
    avgColorFill: number;
    dgiVersion: number;
    serialNum: string;
    ipAddr?: string;
    macAddr?: string;
    location?: string;
    lcdDisplay?: string;
    isLocal: boolean;
    other?: MeterReadOtherProperties;

    toDB(): {};
}

export class MeterRead extends BaseModel implements IMeterRead {
    public static columns: Array<Column> = [
        BaseModel.columns[0],
        BaseModel.columns[1],
        BaseModel.columns[3],
        {
            name: 'Date Read',
            keys: colKeyOneProp('readDtTm'),
            description: 'Date that this meter read was read from the device',
            display: Column.displayFunctions['date'],
            sortable: true
        },
        {
            name: 'Install key',
            keys: colKeyOneProp('installKey'),
            description: 'Key of install that read this meter',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Entity key',
            keys: colKeyOneProp('entityKey'),
            description: 'Key of the entity that read this meter',
            display: Column.displayFunctions[''],
            sortable: true
        },
        ...Column.extrapolate(MeterReadSupply.columns, [{ keyPrefix: 'supplies' }]),
        ...Column.extrapolate(PageCountMeterRead.columns, [{ keyPrefix: 'pageCounts' }])
    ];

    public static foreignKeys: Column.ForeignKeys = {
        [CollectionName.device]: 'deviceKey',
        [CollectionName.install]: 'installKey',
        [CollectionName.entity]: 'entityKey'
    };

    public deviceKey: string | ObjectId = null;
    public entityKey: string | ObjectId = null;
    public installKey: string | ObjectId = null;
    public readDtTm: Date | Moment = null;
    public reportDtTm: Date | Moment = null;
    public pageCounts: PageCountMeterRead = new PageCountMeterRead();
    public supplies: MeterReadSupply = new MeterReadSupply();
    public avgFill: number = null;
    public avgColorFill: number = null;
    public dgiVersion: number = null;
    public serialNum: string;
    public ipAddr?: string;
    public macAddr?: string;
    public location?: string;
    public lcdDisplay?: string;
    public isLocal: boolean;
    public other?: MeterReadOtherProperties = null;

    constructor(defaults?: Partial<IMeterRead>) {
        super(defaults);
        if (defaults) {
            this.deviceKey = defaults.deviceKey || this.deviceKey;
            this.entityKey = defaults.entityKey || this.entityKey;
            this.installKey = defaults.installKey || this.installKey;
            this.readDtTm = defaults.readDtTm || this.readDtTm;
            this.reportDtTm = defaults.reportDtTm || this.reportDtTm;
            this.pageCounts = new PageCountMeterRead(defaults.pageCounts) || this.pageCounts;
            this.supplies = new MeterReadSupply(defaults.supplies) || this.supplies;
            this.avgFill = defaults.avgFill || this.avgFill;
            this.avgColorFill = defaults.avgColorFill || this.avgColorFill;
            this.dgiVersion = defaults.dgiVersion || this.dgiVersion;
            this.serialNum = defaults.serialNum || this.serialNum;
            this.ipAddr = defaults.ipAddr || this.ipAddr;
            this.macAddr = defaults.macAddr || this.macAddr;
            this.location = defaults.location || this.location;
            this.lcdDisplay = defaults.lcdDisplay || this.lcdDisplay;
            this.isLocal = defaults.isLocal || this.isLocal;
            this.other = defaults.other || this.other;
        }
    }

    public toDB(): {} {
        delete this.modifiedDate;
        this.deviceKey = new ObjectId(this.deviceKey);
        this.entityKey = new ObjectId(this.entityKey);
        this.installKey = new ObjectId(this.installKey);
        return this;
    }

    public getSuppliesWithDisplayableNames(): Array<ISupplyWithDisplayableName> {
        const out: Array<ISupplyWithDisplayableName> = [];
        for (const key of Object.keys(this.supplies).sort()) {
            if (this.supplies[key].hasOwnProperty('displayableName')) {
                out.push({
                    key: key,
                    displayableName: this.supplies[key]?.['displayableName']?.value
                });
            }
        }
        return out;
    }

    public buildTimeSeriesSelectorsForDisplayableSupplies(): Array<TimeSeriesSelector> {
        return this.getSuppliesWithDisplayableNames().map(supply =>
            newSupplyPercentRemainingTimeSeriesSelector(supply.key)
        );
    }

    /**
     * Returns the pctRemaining from the first matching supply. This allows a caller to pass in
     * a spreaded-array of supply keys and get the percent remaining from the first matching supply
     * key. It should be used when the specific supply selected does not matter.
     * @param {string[]} supply - The supply key (i.e. blackToner, cyanDrum)
     * @returns {number | null} - The percentage of supply remaining or null if the supply or percent
     * remaining was not found.
     */
    public getPctRemaining(...supply: string[]): number | null {
        const supplies = supply.filter(s => this.supplies.hasOwnProperty(s)).map(s => this.supplies[s]);
        if (supplies.length === 0) {
            return null;
        }
        // Sort ascending and get the first value
        const levels = supplies.map(v => v.pctRemaining?.value).filter(v => v != null).map(v => +v).sort((a, b) => a - b);
        const value = levels.length > 0 ? levels[0] : null;
        if (value == null) {
            return null;
        }
        return +value;
    }

    public supplyEntries(): Array<MeterReadSupplyEntry> {
        const out: Array<MeterReadSupplyEntry> = [];
        for (const key of Object.keys(this.supplies)) {
            const supply = this.supplies[key];
            const entry: MeterReadSupplyEntry = { displayableName: '', type: '' };
            if (supply.hasOwnProperty('displayableName')) {
                entry.displayableName = supply.displayableName.value;
            }
            if (supply.hasOwnProperty('type')) {
                entry.type = supply.type.value;
            }
            if (supply.hasOwnProperty('color')) {
                entry.color = supply.color.value;
            }
            if (supply.hasOwnProperty('description')) {
                entry.description = supply.description.value;
            }
            if (supply.hasOwnProperty('serialNum')) {
                entry.serialNum = supply.serialNum.value;
            }
            if (supply.hasOwnProperty('serialNum')) {
                entry.serialNum = supply.serialNum.value;
            }
            if (supply.hasOwnProperty('partNum')) {
                entry.partNum = supply.partNum.value;
            }
            if (supply.hasOwnProperty('depletionDate')) {
                entry.depletionDate = new Date(supply.depletionDate.value);
            }
            if (supply.hasOwnProperty('pctRemaining')) {
                entry.pctRemaining = supply.pctRemaining.value;
            }
            if (supply.hasOwnProperty('estRemaining')) {
                entry.estRemaining = supply.estRemaining.value;
            }
            if (supply.hasOwnProperty('currentLevel')) {
                entry.currentLevel = supply.currentLevel.value;
            }
            if (supply.hasOwnProperty('maxLevel')) {
                entry.maxLevel = supply.maxLevel.value;
            }
            if (supply.hasOwnProperty('unit')) {
                entry.unit = supply.unit.value;
            }
            if (supply.hasOwnProperty('fillRate')) {
                entry.fillRate = <number>(<any>supply.fillRate.value);
            }
            out.push(entry);
        }
        return out;
    }

    public buildSupplyBurnDownChartData(
        data: { [key: string]: TimeSeries },
        alertLevel?: number,
        options?: LineSeriesOption
    ): SeriesOption[] {
        const out: Array<SeriesOption> = [];
        for (const supply of this.getSuppliesWithDisplayableNames()) {
            const color = Color.bgColorHex(this.supplies[supply.key]?.color?.value as Color);
            out.push({
                animation: false,
                name: supply.displayableName,
                type: 'line',
                smooth: true,
                itemStyle: {
                    color: color
                },
                lineStyle: {
                    color: color,
                    width: 1
                },
                emphasis: {
                    focus: 'none',
                    lineStyle: {
                        width: 1
                    }
                },
                data: data[supply.key].series.map(s => [s.timestamp, s.value]),
                ...options
            });
        }
        if (alertLevel) {
            out.push({
                animation: false,
                name: 'Toner Alert Level',
                type: 'line',
                itemStyle: {
                    color: 'red'
                },
                lineStyle: {
                    color: 'red'
                },
                markLine: {
                    label: {
                        color: 'red',
                        fontWeight: 'bold',
                        show: true,
                        formatter: 'Toner Alert Level - {c}'
                    },
                    silent: true,
                    symbol: [],
                    type: 'line',
                    itemStyle: {
                        color: 'red'
                    },
                    lineStyle: {
                        width: 1,
                        color: 'red',
                        type: 'dashed'
                    },
                    data: [
                        {
                            yAxis: alertLevel
                        }
                    ]
                },
                markArea: {
                    silent: true,
                    type: 'area',
                    itemStyle: {
                        color: 'red',
                        opacity: 0.1
                    },
                    data: [
                        [
                            {
                                yAxis: alertLevel
                            },
                            {
                                yAxis: 0
                            }
                        ]
                    ]
                }
            });
        }
        return out;
    }

    public getOtherPropertiesArray(): Array<{displayName: string, value: string}> {
        return this.other == null ? [] : Object.keys(this.other).map(key => ({
            displayName: this.other[key].displayName,
            value: this.other[key].value
        })).sort((a, b) => a.displayName.localeCompare(b.displayName));
    }
}
