<table
    mat-table
    matSort
    [dataSource]="dataSource"
    *ngIf="(currentWindowSize$ | async) !== WindowSize.Mobile"
>
    <ng-container matColumnDef="partNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortEnabled">
            Part number
        </th>
        <td mat-cell *matCellDef="let item">{{ item.partNumbers.vendorPartNumber }}</td>
    </ng-container>
    <ng-container matColumnDef="make">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortEnabled">
                Make
            </th>
            <td mat-cell *matCellDef="let item">{{ item.make }}</td>
        </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortEnabled">Type</th>
        <td mat-cell *matCellDef="let item">{{ ItemType.toString(item.type) }}</td>
    </ng-container>
    <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortEnabled">Color</th>
        <td mat-cell *matCellDef="let item">
            <ptkr-part-color [color]="item.printing.color"></ptkr-part-color>
        </td>
    </ng-container>
    <ng-container matColumnDef="yield">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortEnabled">Yield</th>
        <td mat-cell *matCellDef="let item">{{ item.printing.yield }}</td>
    </ng-container>
    <ng-container matColumnDef="class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortEnabled">Class</th>
        <td mat-cell *matCellDef="let item">{{ QualityLevel.toString(item.qualityLevel) }}</td>
    </ng-container>
    <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortEnabled">Price</th>
        <td mat-cell *matCellDef="let item">
            {{ item.entityPrice | currency: 'USD':'symbol-narrow' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th class="actions" mat-header-cell *matHeaderCellDef></th>
        <td class="actions" mat-cell *matCellDef="let item">
            <div
                class="action-container"
                *ngIf="item['addedToCart'] !== true && replacement !== true"
            >
                <mat-form-field floatLabel="never" color="accent" appearance="outline">
                    <input matInput type="number" min="0" #qty placeholder="Qty" />
                </mat-form-field>
                <button mat-flat-button color="accent" (click)="addItemToCart(item, qty.value)">
                    <i class="fas fa-shopping-cart"></i> Add to cart
                </button>
            </div>
            <div
                class="action-container"
                *ngIf="item['addedToCart'] === true && replacement !== true"
            >
                <button
                    mat-flat-button
                    color="accent"
                    class="view-cart"
                    (click)="viewCart.emit()"
                >
                    View Open Carts
                </button>
            </div>
            <div class="action-container" *ngIf="replacement === true">
                <button
                    mat-flat-button
                    color="accent"
                    class="view-cart"
                    (click)="replaceToner.emit(item)"
                >
                    Replace toner
                </button>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let event; columns: displayedColumns"></tr>
</table>

<div class="item-list mobile" *ngIf="(currentWindowSize$ | async) === WindowSize.Mobile">
    <mat-card *ngFor="let item of dataSource.data">
        <mat-card-content>
            <div class="group">
                <label>Part number</label> <span>{{ item.partNumbers.vendorPartNumber }}</span>
            </div>
            <div class="group">
                <label>Type</label> <span>{{ ItemType.toString(item.type) }}</span>
            </div>
            <div class="group">
                <label>Color</label>
                <span> <ptkr-part-color [color]="item.printing?.color"></ptkr-part-color> </span>
            </div>
            <div class="group">
                <label>Yield</label>
                <span>{{ !!item.printing ? item.printing.yield : '0' }} pages</span>
            </div>
            <div class="group">
                <label>Class</label> <span>{{ QualityLevel.toString(item.qualityLevel) }}</span>
            </div>
            <div class="group">
                <label>Price</label>
                <span>{{ item.entityPrice | currency: 'USD':'symbol-narrow' }}</span>
            </div>
            <div class="item-actions">
                <div class="action-container" *ngIf="item['addedToCart'] !== true">
                    <mat-form-field floatLabel="never" color="accent" appearance="outline">
                        <input matInput type="number" #qty placeholder="Qty" />
                    </mat-form-field>
                    <button
                        mat-flat-button
                        color="accent"
                        (click)="addItemToCart(item, qty.value)"
                    >
                        <i class="fas fa-shopping-cart"></i> Add to cart
                    </button>
                </div>
                <div class="action-container" *ngIf="item['addedToCart'] === true">
                    <p>Added to cart</p>
                    <button
                        mat-flat-button
                        color="accent"
                        class="view-cart"
                        (click)="viewCart.emit()"
                    >
                        View Open Carts
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
