<table class="g-table" mat-table matSort [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef width="20%">Timestamp</th>
        <td mat-cell *matCellDef="let entry" class="log-entry">{{ entry.timestamp }}</td>
    </ng-container>

    <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let entry" class="log-entry">{{ entry.message }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
