<div mat-dialog-header><h1>Confirm your settings first</h1></div>
<div mat-dialog-content style='overflow: visible'>
    <form [formGroup]='formGroup'>
        <ptkr-email-array-chip-input formControlName='to'
                                     [notifications]='formMessageService.defaultObserver'></ptkr-email-array-chip-input>
        <mat-error [ptkrErrorControl]="getControl('to')"></mat-error>
        <mat-slide-toggle formControlName='doCc' style='margin-bottom: 0.75em;'>
            Add CC to email
        </mat-slide-toggle>
        <ng-container *ngIf="getControlValue('doCc') === true">
            <ptkr-email-array-chip-input formControlName='cc'
                                         [notifications]='formMessageService.defaultObserver'></ptkr-email-array-chip-input>
            <mat-error [ptkrErrorControl]="getControl('cc')"></mat-error>
        </ng-container>

        <p>Customize the email message. A default message will be used if none is provided.</p>
        <mat-form-field floatLabel="never" appearance="outline">
            <textarea matInput [formControl]="getControl('customBody')" placeholder="Email body"></textarea>
            <mat-error [ptkrErrorControl]="getControl('customBody')"></mat-error>
        </mat-form-field>
    </form>
</div>
<div class='action-buttons'>
    <button (click)='cancel()' mat-button>Cancel</button>
    <button color='accent' (click)='sendEmail()' mat-flat-button>Send email</button>
</div>
