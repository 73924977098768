import { ObjectID } from 'bson';
import { WarrantyReasons } from '@libs/iso/core/enums';

export interface IItemWarranty {
    code: string;
    date: Date;
    userKey: ObjectID | string;
    qty: number;
    note: WarrantyReasons;
}

export class ItemWarranty implements IItemWarranty {
    public code: string;
    public date: Date;
    public userKey: ObjectID | string;
    public qty: number;
    public note: WarrantyReasons;

    constructor(defaults?: Partial<IItemWarranty>) {
        if (defaults) {
            this.code = defaults.code || this.code;
            this.date = defaults.date || this.date;
            this.userKey = defaults.userKey || this.userKey;
            this.qty = defaults.qty || this.qty;
            this.note = defaults.note || this.note;
        }
    }
}
