import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Install, IWithReportingDevices } from '@libs/iso/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'ptkr-install-list-table-viewer',
    templateUrl: './install-list-table-viewer.component.html',
    styleUrls: ['./install-list-table-viewer.component.scss']
})
export class InstallListTableViewerComponent implements OnInit {
    @Input() public installs$: Observable<Array<Install | (Install & IWithReportingDevices)>>;
    @Input() public displayedColumns: Array<string> = [
        'machine',
        'versionDCA',
        'lastCheckInDtTm',
        'reportingDevices.percentage'
    ];

    public dataSource: MatTableDataSource<Install | (Install & IWithReportingDevices)>;

    constructor(private _cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.installs$.subscribe(data => {
            this.dataSource = new MatTableDataSource<Install | (Install & IWithReportingDevices)>(
                data
            );
            this._cd.detectChanges();
        });
    }

    // Returns the value or a hyphen if the value is null or empty
    public valueOrEmpty(value: any): any {
        if (value === '' || value === null || value === undefined) {
            return '-';
        }
        return value;
    }
}
