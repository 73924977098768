enum InstallationMode {
    Evaluation,
    Active
}

namespace InstallationMode {
    export function toString(type: InstallationMode): string {
        switch (type) {
            case InstallationMode.Evaluation:
                return 'Evaluation';
            case InstallationMode.Active:
                return 'Active';
            default:
                return '';
        }
    }

    export const asArray: InstallationMode[] = [
        InstallationMode.Evaluation,
        InstallationMode.Active
    ];
}

export { InstallationMode };
