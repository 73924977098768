import { Component, Input, OnChanges } from '@angular/core';
import { Install } from '@libs/iso/core';
import { InstallHealthCheckMessage } from '@libs/iso/core/models/install/InstallHealthCheck';
import { Permission } from '@libs/iso/core';

@Component({
    selector: 'ptkr-install-healthcheck',
    templateUrl: './install-healthcheck.component.html',
    styleUrls: ['./install-healthcheck.component.scss']
})
export class InstallHealthcheckComponent implements OnChanges {

    @Input('install') public install: Install;

    public healthState: InstallHealthCheckMessage;
    public registration: InstallHealthCheckMessage;
    public taskEngine: InstallHealthCheckMessage;
    public rpcClient: InstallHealthCheckMessage;
    public dependencies: InstallHealthCheckMessage;
    // public jobStream: InstallHealthCheckMessage;

    public Permission: typeof Permission = Permission;

    constructor() {
    }

    ngOnChanges(): void {
        this.healthState = new InstallHealthCheckMessage(this.install.healthcheck, 'healthState');
        this.registration = new InstallHealthCheckMessage(this.install.healthcheck, 'registration');
        this.taskEngine = new InstallHealthCheckMessage(this.install.healthcheck, 'taskEngine');
        this.rpcClient = new InstallHealthCheckMessage(this.install.healthcheck, 'rpcClient');
        this.dependencies = new InstallHealthCheckMessage(this.install.healthcheck, 'dependencies');
        // this.jobStream = new InstallHealthCheckMessage(this.install.healthcheck.jobStream);
    }

    public isGoodIcon(a: InstallHealthCheckMessage): string {
        if (a.isValid() === true) {
            return 'check_circle';
        } else {
            return 'error';
        }
    }
}
