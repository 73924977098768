import { Injectable } from '@angular/core';
import { RtsService } from '@app/core/services/rts.service';
import {
    CheckSoftwareVersionTask,
    CollectAndUploadDGIsTask,
    CollectAndUploadTempMeterReadsTask,
    Entity,
    GcsModuleVersion,
    IDevicesByInstalls,
    JobDestinationTypes,
    Metadata,
    MoveToEntityTask,
    RequireVersionTask,
    RestartTask,
    StateCheckSyncDevicesTask,
    Task,
    TaskTypes,
    UpdateDGIsTask,
    UpdateGenericOIDsTask,
    UpdateJavascriptModulesTask,
    UpdateMakeDefinitionsTask,
    UpdateSettingsTask,
    UpdateStarlarkModulesTask,
    UpdateTECsTask,
    UpgradeChromiumTask,
    UploadDeviceSNMPDumpTask,
    UploadFingerprintTask,
    UploadMeterReadsTask
} from '@libs/iso/core';
import { select, Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity, fromUser } from '@app/state/selectors';
import { map, switchMap, take } from 'rxjs/operators';
import { User } from '@app/models';
import { forkJoin, Observable, zip } from 'rxjs';
import { InstallService } from './install.service';
import { NotificationService } from '@app/core/services/notification.service';
import { DeviceService } from '@app/core/services/device.service';
import { ProxyService } from '@app/core/services/proxy.service';
import { AdvertiseRoutesTask, NetworkTopologyScanTask } from '@libs/iso/core/models/job/Task';

@Injectable()
export class JobRunnerService {
    constructor(
        private _rtsService: RtsService,
        private _store: Store<GlobalStore>,
        private _installService: InstallService,
        private _deviceService: DeviceService,
        private _proxyService: ProxyService,
        private _notificationService: NotificationService
    ) {}

    // _metadata returns an observable containing both the user and entity information
    private _metadata(): Observable<Metadata> {
        return zip(
            this._store.pipe(select(fromEntity.currentEntity), take(1)),
            this._store.pipe(select(fromUser.currentUser), take(1))
        ).pipe(
            map(
                ([entity, user]) =>
                    new Metadata({
                        entity: {
                            name: entity.name,
                            id: entity._id
                        },
                        user: {
                            username: user.email,
                            firstname: user.firstName,
                            lastname: user.lastName,
                            id: user._id
                        }
                    })
            )
        );
    }

    // Exposes the private _metadata function. todo: this should be moved into a more abstract service like MetadataService
    public metadata(): Observable<Metadata> {
        return this._metadata();
    }

    // collectAndUploadDGIs sends a job to the DCA requesting that the most recently active
    // dca for the provided deviceKey upload a DGI candidate for the device.
    public collectAndUploadDGIs(deviceKey: string): Observable<any> {
        const jobName = 'Collect and Upload DGIs';
        const jobDescription = 'Uploads generated DGIs for devices.';
        return zip(
            this._metadata(),
            this._deviceService
                .getDeviceById(deviceKey)
                .pipe(
                    switchMap(device =>
                        this._installService.getMostRecentlyActive(device.installKey, false)
                    )
                )
        ).pipe(
            switchMap(([metadata, installKey]) =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    [installKey],
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [
                        new CollectAndUploadDGIsTask({
                            DeviceKeys: [deviceKey]
                        })
                    ]
                )
            )
        );
    }

    // collectAndUploadDGIs sends a job to the DCA requesting that the most recently active
    // dca for the provided deviceKey upload a DGI candidate for the device.
    // todo (spencer): let's use your algorithm here to create an optimal list of target installs
    //                 for the an array of device keys and send jobs to all of them.
    public uploadDeviceMeterRead(deviceKey: string): Observable<any> {
        const jobName = 'Upload Meter Reads';
        const jobDescription = 'Collects and uploads meter reads from devices.';
        return zip(
            this._metadata(),
            this._deviceService
                .getDeviceById(deviceKey)
                .pipe(
                    switchMap(device =>
                        this._installService.getMostRecentlyActive(device.installKey, false)
                    )
                )
        ).pipe(
            switchMap(([metadata, installKey]) =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    [installKey],
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [
                        new UploadMeterReadsTask({
                            deviceKeys: [deviceKey]
                        })
                    ]
                )
            )
        );
    }

    public updateDeviceSettings(deviceKey: string): Observable<any> {
        const jobName = 'Update Device Settings';
        const jobDescription = 'Updates settings for a device stored on a data collection agent.';
        return zip(this._metadata(), this._deviceService.getDeviceById(deviceKey)).pipe(
            switchMap(([metadata, device]) =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    device.installKey,
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [
                        new UpdateSettingsTask({
                            Devices: [deviceKey]
                        })
                    ]
                )
            )
        );
    }

    // Sends a job that syncs all device and install settings to the local data collection agents
    public syncSettings(): Observable<any> {
        const jobName = 'Sync Device Settings';
        const jobDescription = 'Syncs all device settings to the local data collection agent.';
        return this._metadata().pipe(
            switchMap(metadata =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    [],
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Entity,
                    [
                        new UpdateSettingsTask({
                            AllDevices: true,
                            IncludeInstallSettings: true,
                        })
                    ]
                )
            )
        );
    }

    /// Collects and uploads a testbox
    public collectTestbox(deviceKey: string): Observable<void> {
        return this.testDGIChanges(deviceKey, '', false, true, '>2.64.0');
    }

    // testDGIChanges is responsible for orchestrating a set of tasks that allow developers to
    // rapidly test changes to a DGI and view the resulting meter reads using the DGI builder.
    // Note: the device's settings should have been updated before us. This function is responsible
    // for orchestrating only the jobs and tasks required to test a DGI.
    public testDGIChanges(
        deviceKey: string,
        dgiKey: string,
        realMeterRead: boolean,
        collectTestbox: boolean,
        // Follows the constraint specs here https://github.com/hashicorp/go-version
        versionConstraint: string = '>=2.15.0',
    ): Observable<void> {
        const jobName = 'Test Data Gathering Instructions';
        const jobDescription =
            'Updates devices settings, data gathering modules, instructions, triggered' +
            ' event commands, make definitions, custom instruction modules, and uploads a temporary meter read.';
        let task: CollectAndUploadTempMeterReadsTask | UploadMeterReadsTask;
        if (!realMeterRead) {
            task = new CollectAndUploadTempMeterReadsTask({
                deviceKey: deviceKey,
                dgiKey: dgiKey,
                collectTestbox: collectTestbox,
            });
        } else {
            task = new UploadMeterReadsTask({
                deviceKeys: [deviceKey]
            });
        }
        return zip(
            this._metadata(),
            this._deviceService
                .getDeviceById(deviceKey)
                .pipe(
                    switchMap(device =>
                        this._installService.getMostRecentlyActive(device.installKey, false)
                    )
                )
        ).pipe(
            switchMap(([metadata, installKey]) =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    [installKey],
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [
                        new RequireVersionTask({
                            constraint: versionConstraint
                        }),
                        new UpdateSettingsTask({
                            Devices: [deviceKey]
                        }),
                        new UpdateStarlarkModulesTask(),
                        new UpdateJavascriptModulesTask(),
                        new UpdateGenericOIDsTask(),
                        new UpdateMakeDefinitionsTask(),
                        new UpdateDGIsTask({
                            DeviceKeys: [deviceKey]
                        }),
                        new UpdateTECsTask(),
                        task
                    ]
                )
            )
        );
    }

    // requestSNMPDump sends a job to the DCA requesting that the most recently active
    // dca for the provided deviceKey upload a DGI candidate for the device.
    public requestSNMPDump(deviceKey: string): Observable<any> {
        const jobName = 'Device SNMP Dump';
        const jobDescription = 'Uploads SNMP information for a device.';
        return zip(
            this._metadata(),
            // We're going to do some weird trickery here because we need to return the device
            // as well as the install key, even though we find both of those pieces of information
            // using separate observables.
            this._deviceService.getDeviceById(deviceKey).pipe(
                map(device => ({ device })),
                switchMap(obj =>
                    this._installService
                        .getMostRecentlyActive(obj.device.installKey, false)
                        .pipe(map(res => ({ ...obj, installKey: res })))
                )
            )
        ).pipe(
            switchMap(([metadata, data]) =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    [data.installKey],
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [
                        new UploadDeviceSNMPDumpTask({
                            deviceKey: data.device._id as string
                        })
                    ]
                )
            )
        );
    }

    // openDeviceWebpage attempts to open the device webpage using the remote technician tunnel
    public openDeviceWebpage(deviceKey: string): Observable<any> {
        return zip(this._metadata(), this._deviceService.getDeviceById(deviceKey)).pipe(
            switchMap(([metadata, device]) =>
                this._proxyService.tryStartProxy(
                    device._id as string,
                    device.installKey,
                    metadata.entity.id,
                    metadata.entity.name
                )
            )
        );
    }

    public updateDataCollectionModules(installKeys: Array<string>): Observable<any> {
        const jobName = 'Update Data Collection Modules';
        const jobDescription = 'Updates the modules used for data collection.';
        return this._metadata().pipe(
            switchMap(metadata =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    installKeys,
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [
                        new UpdateStarlarkModulesTask(),
                        new UpdateJavascriptModulesTask(),
                        new UpdateGenericOIDsTask(),
                        new UpdateMakeDefinitionsTask(),
                        new UpdateDGIsTask(),
                        new UpdateTECsTask()
                    ]
                )
            )
        );
    }

    // restartDCA sends a restart job to the provided list of installs. Optionally allows the caller
    // to send the job as a standalone job (meaning it's run outside of the dcas scheduler and runs
    // upon receiving).
    public restartDCA(installKeys: Array<string>, standalone?: boolean): Observable<any> {
        const jobName = 'Restart DCA';
        const jobDescription = 'Restarts the data collection agent';
        if (!standalone) {
            standalone = false;
        }
        return this._metadata().pipe(
            switchMap(metadata =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    installKeys,
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [new RestartTask({ standalone })]
                )
            )
        );
    }

    // Sends a job instructing a DCA to move itself, and all related documents to the provided entity
    // key. It's the responsibility of the caller to ensure that all the installKeys provided here are
    // the all of the installs that should be moved. This function will not do any post-analysis to
    // ensure that all installs that should be moved are being moved.
    public moveToEntity(installKeys: Array<string>, entityKey: string): Observable<void> {
        const jobName = 'Move to Entity';
        const jobDescription =
            'Moves the data collection agent, devices, and all related information' +
            ' from one entity to another';
        return this._metadata().pipe(
            switchMap(metadata =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    installKeys,
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [
                        new MoveToEntityTask({
                            entityKey: entityKey
                        })
                    ]
                )
            )
        );
    }

    // tslint:disable-next-line:valid-jsdoc
    /**
     * Disables the tracking status of each of the provided device keys for each of the provided install
     * keys. The format of the parameters is an object where each of the keys is a unique install key
     * and the value is an array of devices at that install key that should be disabled.
     * @param {[key: string]: Array<string>>} installAndDeviceKeys - An object containing the installs
     * and devices that should be updated
     * @returns {Observable<void>} - Make sure to subscribe to this.
     */
    public bulkDisableTrackingStatus(installAndDeviceKeys: IDevicesByInstalls): Observable<void[]> {
        const jobName = 'Update Tracking Status';
        const jobDescription =
            'Updates the tracking status of the device on the local data collection agent';
        return this._metadata().pipe(
            map(metadata =>
                Object.keys(installAndDeviceKeys).map<Observable<void>>(installKey =>
                    this._rtsService.createJob(
                        metadata.entity.id,
                        [installKey],
                        metadata.user.id,
                        metadata,
                        jobName,
                        jobDescription,
                        JobDestinationTypes.Install,
                        [
                            new UpdateSettingsTask({
                                Devices: installAndDeviceKeys[installKey]
                            })
                        ]
                    )
                )
            ),
            switchMap((observables: Observable<void>[]) => forkJoin(observables))
        );
    }

    // upgradeChromium sends an upgradeChromium job to the provided install keys. By default the DCA will
    // self-select the Chromium version to use unless over-ridden in the parameters here.
    public upgradeChromium(
        installKeys: string[],
        downloadUrl?: string,
        waitForLocksDuration?: string
    ): Observable<any> {
        const jobName = 'Upgrade Chromium';
        const jobDescription =
            'Upgrades Chromium, the built-in client for web-based data collection';
        return this._metadata().pipe(
            switchMap(metadata =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    installKeys,
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [
                        new UpgradeChromiumTask({ downloadUrl, waitForLocksDuration }),
                        new UploadFingerprintTask()
                    ]
                )
            )
        );
    }

    // Sends a network topology scan task to the DCA.
    public networkTopologyScan(
        installKey: string,
        networks: string[],
        exclude: string[]
    ): Observable<any> {
        const jobName = 'Network Topology Scan';
        const jobDescription =
            'Scans the network and returns topology statistics that can help identify devices';
        return this._metadata().pipe(
            switchMap(metadata =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    [installKey],
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [new NetworkTopologyScanTask({ networks, exclude })]
                )
            )
        );
    }

    // Sends a job to the DCA that checks all module software versions and upgrades them if needed
    public checkSoftwareVersion(installKeys: string[]): Observable<any> {
        const jobName = 'Check Software Version';
        const jobDescription =
            "Checks the current version of the install and upgrades if it's out of date";
        return this._metadata().pipe(
            switchMap(metadata =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    installKeys,
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [new CheckSoftwareVersionTask()]
                )
            )
        );
    }

    public syncRemovedDevices(installKeys: string[]): Observable<any> {
        const jobName = 'Sync Removed Devices';
        const jobDescription =
            "Checks to make sure the install's devices are in sync with the server";
        return this._metadata().pipe(
            switchMap(metadata =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    installKeys,
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [new StateCheckSyncDevicesTask()],
                )
            )
        );
    }

    public upgradeDca(version: GcsModuleVersion, installKeys: Array<string>): Observable<any> {
        // tslint:disable-next-line:typedef
        return new Observable(subscriber => {
            let entity: Entity;
            let user: User;

            this._store
                .pipe(select(fromEntity.currentEntity), take(1))
                .subscribe(e => (entity = e));
            this._store.pipe(select(fromUser.currentUser), take(1)).subscribe(u => (user = u));

            const softwareUpgradeTask = new Task({
                type: TaskTypes.UpgradeSoftware,
                payload: {
                    deleteFiles: [],
                    downloadFiles: [
                        {
                            filename: 'dca.exe',
                            url: version.url,
                            md5: version.md5
                        }
                    ],
                    replaceDCAWith: 'dca.exe'
                }
            });

            const params = {
                entityKey: entity._id,
                installKey: installKeys,
                userKey: user._id,
                metadata: {
                    user: {
                        username: user.email,
                        firstname: user.firstName,
                        lastname: user.lastName
                    },
                    entity: {
                        name: entity.name
                    }
                },
                jobName: 'Software Upgrade',
                jobDescription:
                    'This is a request for the Data Collection Agent to upgrade its software version',
                destination: JobDestinationTypes.Install,
                tasks: [softwareUpgradeTask]
            };

            this._rtsService
                .createJob(
                    params.entityKey,
                    params.installKey,
                    params.userKey,
                    params.metadata,
                    params.jobName,
                    params.jobDescription,
                    params.destination,
                    params.tasks
                )
                .subscribe(
                    () => {
                        subscriber.next();
                        subscriber.complete();
                    },
                    err => subscriber.error(err)
                );
        });
    }

    public tailscaleConnect(deviceKey: string, ipAddress: string): Observable<any> {
        const jobName = 'Tailscale connect';
        const jobDescription =
            'Opens a temporary Tailscale connection between the device and Print Tracker support team';
        return zip(
            this._metadata(),
            this._deviceService.getDeviceById(deviceKey).pipe(
                map(device => ({ device })),
                switchMap(obj =>
                    this._installService
                        .getMostRecentlyActive(obj.device.installKey, false)
                        .pipe(map(res => ({ ...obj, installKey: res })))
                )
            )
        ).pipe(
            switchMap(([metadata, data]) =>
                this._rtsService.createJob(
                    metadata.entity.id,
                    [data.installKey],
                    metadata.user.id,
                    metadata,
                    jobName,
                    jobDescription,
                    JobDestinationTypes.Install,
                    [
                        new AdvertiseRoutesTask({
                            add: [ipAddress]
                        })
                    ]
                )
            )
        );
    }
}
