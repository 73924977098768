// tslint:disable:no-magic-numbers
import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import {
    Cart,
    CartItem,
    UserPaymentMethod,
    ShippingMethod,
    ShippingAddress,
    OrderPlacedResponse
} from '@libs/iso/core';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { NotificationService } from '@app/core/services/notification.service';
import { StepComponent } from '@app/shared/overlays/StepComponent';
import { CartService } from '@app/core/services/cart.service';
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { Order } from '@app/models';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromUser } from '@app/state/selectors';

interface Step {
    label: string;
    isComplete: boolean;
    nextText: string;
    cancelText: string;
    next: () => void;
    cancel: () => void;
    complete: () => void;
}

@Component({
    selector: 'ptkr-checkout-overlay',
    templateUrl: './checkout-overlay.component.html',
    styleUrls: ['./checkout-overlay.component.scss']
})
export class CheckoutOverlayComponent implements OnInit {
    @ViewChild('stepper', {static: true})
    public stepper: MatStepper;
    @ViewChild('shippingStep', {static: true})
    public shippingStep: StepComponent;
    @ViewChild('paymentStep', {static: true})
    public paymentStep: StepComponent;

    private _spinnerActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public userEmail$: Observable<string> = this._store.pipe(
        select(fromUser.currentUser),
        map(u => u.email)
    );
    public spinnerActive$: Observable<boolean> = this._spinnerActive.asObservable();
    public currentStep: number = 0;
    public paymentMethod: UserPaymentMethod;
    public shippingMethod: ShippingMethod;
    public shippingAddress: ShippingAddress;
    public estTax$: Observable<number>;
    public orderRes: OrderPlacedResponse;
    public cart: Cart;
    public order: Order = new Order();
    public steps: Step[];

    constructor(
        public dialogRef: MatDialogRef<CheckoutOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { cart: Cart },
        private _cd: ChangeDetectorRef,
        private _notificationService: NotificationService,
        private _cartService: CartService,
        private _store: Store<GlobalStore>
    ) {
        this.cart = this.data.cart;
    }

    ngOnInit(): void {
        this.steps = [
            this._createStep(0, {
                label: 'Review Cart',
                nextText: 'Next',
                cancelText: 'Cancel',
                cancel: (): any => this.dialogRef.close(),
                next: (): any => {
                    this.steps[0].isComplete = true;
                    this._nextStep();
                }
            }),
            this._createStep(1, {
                label: 'Select Shipping',
                nextText: 'Next',
                cancelText: 'Back',
                next: (): any => {
                    if (this.shippingStep.validate()) {
                        const val = this.shippingStep.getValue();
                        this.shippingAddress = val.address;
                        this.shippingMethod = val.method;
                        if (!this.cart.isWarranty) {
                            this.estTax$ = this._cartService
                                .getEstimatedTax(
                                    [this.cart._id] as string[],
                                    this.shippingAddress,
                                    this.shippingMethod
                                )
                                .pipe(map(res => res.estTax));
                        }
                        this.order = new Order({
                            shippingAddress: this.shippingAddress,
                            shippingMethod: this.shippingMethod
                        });
                        this.steps[1].isComplete = true;
                        this._nextStep();
                    } else {
                        const val = this.shippingStep.getValue();
                        if (!val.address) {
                            this._notificationService.error('Invalid shipping address');
                        } else if (!val.method) {
                            this._notificationService.error('Invalid shipping method');
                        } else {
                            this._notificationService.error('Invalid shipping choices');
                        }
                    }
                }
            }),
            this._createStep(2, {
                label: 'Select Payment',
                nextText: 'Next',
                cancelText: 'Back',
                next: (): any => {
                    if (this.paymentStep.validate()) {
                        const val = this.paymentStep.getValue();
                        console.log('payment method', val);
                        this.paymentMethod = val;
                        this.steps[2].isComplete = true;
                        this._nextStep();
                    } else {
                        this._notificationService.error('Select a payment method');
                    }
                }
            }),
            this._createStep(3, {
                label: 'Confirm Purchase',
                nextText: 'Confirm',
                cancelText: 'Back',
                next: (): any => {
                    this.steps[3].isComplete = true;
                    this._purchase();
                }
            }),
            this._createStep(4, {
                label: 'Purchase Complete',
                nextText: 'Close',
                next: (): any => {
                    this.dialogRef.close();
                }
            })
        ];
    }

    public selectionChange(event: StepperSelectionEvent): void {
        for (let i = this.steps.length - 1; i >= event.selectedIndex; i--) {
            this.steps[i].isComplete = false;
        }
        this.currentStep = event.selectedIndex;
    }

    public canEdit(): boolean {
        return (
            this.steps[1].isComplete === false ||
            this.steps[2].isComplete === false ||
            this.steps[3].isComplete === false
        );
    }

    public removeItem(item: CartItem): void {
        const index = this.cart.items.findIndex(i => i.itemKey === item.itemKey);
        this.cart = new Cart({
            ...this.cart,
            items: [...this.cart.items.slice(0, index), ...this.cart.items.slice(index + 1)]
        });
        this._updateItem(0, item);
    }

    public updateItem({ qty, item }: { qty: number; item: CartItem }): void {
        const index = this.cart.items.findIndex(i => i.itemKey === item.itemKey);
        this.cart = new Cart({
            ...this.cart,
            items: [
                ...this.cart.items.slice(0, index),
                new CartItem({
                    ...item,
                    qty: qty
                }),
                ...this.cart.items.slice(index + 1)
            ]
        });
        this._updateItem(qty, item);
    }

    private _purchase(): void {
        this._spinnerActive.next(true);
        const order = new Order({
            paymentMethod: this.paymentMethod,
            paymentMethodId: this.paymentMethod.id,
            shippingAddress: this.shippingAddress,
            shippingMethod: this.shippingMethod
        });
        const delayObs = of({}).pipe(delay(2000));
        forkJoin([delayObs, this._cartService.placeOrder([this.cart], order)]).subscribe(
            res => {
                console.log('changes complete');
                this._spinnerActive.next(false);
                this.orderRes = res[1];
                this._nextStep();
            },
            error => {
                this._notificationService.error(error);
                this._spinnerActive.next(false);
            }
        );
    }

    private _updateItem(qty: number, item: CartItem): void {
        this._cartService
            .setItemQty(this.cart.entityKey as string, item.itemKey as string, qty)
            .subscribe(
                res => {
                    this._notificationService.success(`Item ${qty > 0 ? 'Updated' : 'Removed'}`);
                    this._cd.detectChanges();
                },
                error => {
                    this._notificationService.error(error);
                }
            );
    }

    private _createStep(index: number, step: Partial<Step> = {}): Step {
        const newStep = {
            label: '',
            isComplete: false,
            nextText: null,
            cancelText: null,
            next: (): any => {
                this._nextStep();
            },
            cancel: (): any => this._previousStep(),
            complete: (): any =>
                (this.steps[index] = this._createStep(index, { ...step, isComplete: true })),
            ...step
        };
        return newStep;
    }
    private _nextStep(): void {
        if (this.currentStep < this.steps.length - 1) {
            this.currentStep++;
            this._cd.detectChanges();
            this.stepper.selectedIndex = this.currentStep;
        }
    }

    private _previousStep(): void {
        if (this.currentStep > 0) {
            this.currentStep--;
            this._cd.detectChanges();
            this.stepper.selectedIndex = this.currentStep;
        }
    }
}
