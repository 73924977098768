import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ptkr-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    @Input()
    public breadcrumb: any[] = [];
    @Input()
    public displaysCurrent: boolean = false;
    @Input()
    // tslint:disable-next-line:semicolon
    public displayFn: (e: any) => string = (e: any) => e.name;
    // tslint:disable-next-line:member-ordering
    @Output()
    public select: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    public _clickable(i: number): boolean {
        return !this.displaysCurrent || i !== this.breadcrumb.length - 1;
    }
}
