import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';

@Injectable()
export class NotificationService {
    constructor(private _snackBar: MatSnackBar) {}

    public success(text: string): MatSnackBarRef<SimpleSnackBar> {
        return this.custom(text, 'Close', 3000);
    }

    public info(text: string): MatSnackBarRef<SimpleSnackBar> {
        return this.custom(text, 'Close', 3000);
    }

    public error(text: string | HttpErrorResponse): MatSnackBarRef<SimpleSnackBar> {
        text =
            typeof text === 'string'
                ? text
                : text.statusText === 'OK'
                ? text.message
                : text.statusText;
        return this.custom(text, 'Close', 4000);
    }

    public custom(
        text: string,
        action: string = 'Close',
        duration: number = 3000
    ): MatSnackBarRef<SimpleSnackBar> {
        return this._snackBar.open(text, action, {
            duration: duration,
            verticalPosition: 'top'
        });
    }

    /**
     * A utility function for easily prompting the user to reload the page and try again.
     * @param {any} err - An error to be logged to the console.
     * @returns {Observable<null>} - A null observable that can be passed back into catchError
     */
    public errorReloadAndTryAgain(err: any): Observable<null> {
        this.error('There was an error, please reload the page and try again.');
        return of(null);
    }
}
