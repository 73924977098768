<div mat-dialog-header class="header">
    <h3>Ip SNMP Dump</h3>
</div>
<div mat-dialog-content class="content">
    <p>
        Specify ip ranges you would like to check; we will create an SNMP dump for any ip that
        responds to our SNMP requests.
    </p>
    <form [formGroup]="formGroup">
        <!-- Include subnet input -->
        <section class="form-section">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Include Subnets</mat-label>
                <mat-chip-list
                    #includeIpChips
                >
                    <mat-chip
                        *ngFor="let net of ips"
                        color="accent"
                        (removed)="removeSubnet(net)"
                    >
                        {{ net }}
                        <mat-icon matChipRemove
                            >cancel</mat-icon
                        >
                    </mat-chip>
                    <input
                        #includeIp
                        [matChipInputFor]="includeIpChips"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addSubnet($event)"
                        autocomplete="off"
                    />
                </mat-chip-list>
                <mat-hint>We'll search these IP subnets</mat-hint>
            </mat-form-field>
        </section>
    </form>
</div>
<div class="action-buttons" style="float: right;" mat-dialog-actions>
    <button mat-button color="accent" (click)="close(false)">Cancel</button>
    <button mat-button color="accent" (click)="close(true)">Submit</button>
</div>
