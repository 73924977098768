import { BaseDoc, BaseDocModel } from '@libs/iso/core';
import { Permission } from '@libs/iso/core/enums';
import { UserContact, UserContactModel } from './UserContact';

export interface UserModel extends BaseDocModel {
    permissions: Permission[];
    firstName: string;
    lastName: string;
    roleName: string;
    contact: UserContactModel;
    email: string;
    commissionPlanKey: string;
    roles: Array<{
        roleKey: string;
        name: string;
    }>;
    entity: {
        entityKey: string;
        name: string;
    };
    preferredTimeZone: string;
}

interface UserConstructorParams extends UserModel {}

export class User extends BaseDoc implements UserModel {
    public permissions: Permission[] = [];
    public firstName: string = '';
    public lastName: string = '';
    public roleName: string = '';
    public contact: UserContact = new UserContact();
    public email: string = '';
    public commissionPlanKey: string = '';
    public roles: Array<{
        roleKey: string;
        name: string;
    }> = [];
    public entity: {
        entityKey: string;
        name: string;
    } = { entityKey: '', name: '' };
    public preferredTimeZone: string = '';

    constructor(params?: Partial<UserConstructorParams>) {
        super(params);
        if (!!params) {
            if (Array.isArray(params.permissions)) {
                this.permissions = params.permissions;
            }
            this.firstName = params.firstName || this.firstName;
            this.lastName = params.lastName || this.lastName;
            this.roleName = params.roleName || this.roleName;
            if (!!params.contact) {
                this.contact = new UserContact(params.contact);
            }
            this.email = params.email || this.email;
            this.entity = params.entity || this.entity;
            this.roles = params.roles || this.roles;
            this.commissionPlanKey = params.commissionPlanKey || this.commissionPlanKey;
            this.preferredTimeZone = params.preferredTimeZone || this.preferredTimeZone;
        }
    }
}
