import { CartStore, initCartState } from '../store';
import {
    CartActions,
    CartActionsType,
    ClearCartAction,
    SelectCartAction,
    SelectShippingAddressAction,
    SelectShippingMethodAction,
    UpdateCartItemAction,
    StartCheckoutAction,
    RecalculateOrderAction,
    PlaceOrderAction,
    OrderCompleteAction,
    UpdateEstimatedTaxAction,
    SelectPaymentAction,
    OrderFailureAction,
    InvalidTaxAction
} from '../actions/cart.actions';
import { Cart, CartItem } from '@libs/iso/core';
import { Order } from '@app/models';

class CartHandlers {
    public static handleClearAction(state: CartStore, action: ClearCartAction): CartStore {
        return initCartState;
    }

    public static handleSelectCartAction(state: CartStore, action: SelectCartAction): CartStore {
        const index = state.selectedCarts.indexOf(action.payload);
        if (index > -1) {
            return state;
        } else {
            const carts = [...state.selectedCarts, action.payload];
            return {
                ...state,
                selectedCarts: carts,
                order: new Order({
                    ...state.order,
                    entityName: action.payload.entityName,
                    isWarranty: carts.reduce((acc, next) => acc && next.isWarranty, true)
                })
            };
        }
    }

    public static handleUpdateCartItemAction(
        state: CartStore,
        action: UpdateCartItemAction
    ): CartStore {
        const index = state.selectedCarts.indexOf(action.payload.cart);
        if (index > -1) {
            const itemIndex = state.selectedCarts[index].items.indexOf(action.payload.event.item);
            if (itemIndex > -1) {
                const curCart = state.selectedCarts[index];
                let newCart: Cart;
                if (action.payload.event.qty > 0) {
                    newCart = new Cart({
                        ...curCart,
                        items: [
                            ...curCart.items.slice(0, itemIndex),
                            new CartItem({
                                ...curCart.items[itemIndex],
                                qty: action.payload.event.qty
                            }),
                            ...curCart.items.slice(itemIndex + 1)
                        ]
                    });
                } else {
                    newCart = new Cart({
                        ...curCart,
                        items: [
                            ...curCart.items.slice(0, itemIndex),
                            ...curCart.items.slice(itemIndex + 1)
                        ]
                    });
                }

                return {
                    ...state,
                    selectedCarts: [
                        ...state.selectedCarts.slice(0, index),
                        newCart,
                        ...state.selectedCarts.slice(index + 1)
                    ]
                };
            }
        }
        return state;
    }

    public static handleStartCheckoutAction(
        state: CartStore,
        action: StartCheckoutAction
    ): CartStore {
        return {
            ...state,
            inProgress: true
        };
    }

    public static handleSelectShippingAddressAction(
        state: CartStore,
        action: SelectShippingAddressAction
    ): CartStore {
        return {
            ...state,
            order: new Order({
                ...state.order,
                shippingAddress: action.payload
            })
        };
    }

    public static handleSelectShippingMethodAction(
        state: CartStore,
        action: SelectShippingMethodAction
    ): CartStore {
        return {
            ...state,
            order: new Order({
                ...state.order,
                shippingMethod: action.payload
            })
        };
    }

    public static handleSelectPaymentMethodAction(
        state: CartStore,
        action: SelectPaymentAction
    ): CartStore {
        return {
            ...state,
            order: new Order({
                ...state.order,
                paymentMethod: action.payload,
                paymentMethodId: action.payload.id
            })
        };
    }

    public static handleRecalculateOrderAction(
        state: CartStore,
        action: RecalculateOrderAction
    ): CartStore {
        return {
            ...state,
            order: new Order({
                ...state.order,
                subtotal: state.selectedCarts.reduce(
                    (acc, next) =>
                        !next.isWarranty
                            ? (acc += next.items.reduce(
                                  (acc2, next2) => (acc2 += next2.cost * next2.qty),
                                  0
                              ))
                            : (acc += 0),
                    0
                ),
                salesTax: 0,
                totalPrice: 0
            })
        };
    }

    public static handlePlaceOrderAction(state: CartStore, action: PlaceOrderAction): CartStore {
        return {
            ...state,
            inProgress: false
        };
    }

    public static handleOrderCompleteAction(
        state: CartStore,
        action: OrderCompleteAction
    ): CartStore {
        return initCartState;
    }

    public static handleOrderFailureAction(
        state: CartStore,
        action: OrderFailureAction
    ): CartStore {
        return {
            ...state,
            inProgress: true
        };
    }

    public static handleUpdateEstimatedTaxAction(
        state: CartStore,
        action: UpdateEstimatedTaxAction
    ): CartStore {
        return {
            ...state,
            order: new Order({
                ...state.order,
                salesTax: action.payload
            }),
            taxErrorMessage: ''
        };
    }

    public static handleInvalidTaxAction(state: CartStore, action: InvalidTaxAction): CartStore {
        return {
            ...state,
            order: new Order({
                ...state.order,
                salesTax: 0
            }),
            taxErrorMessage: action.payload
        };
    }
}

export function cartReducer(state: CartStore = initCartState, action: CartActionsType): CartStore {
    switch (action.type) {
        case CartActions.Clear:
            return CartHandlers.handleClearAction(state, action);
        case CartActions.SelectCart:
            return CartHandlers.handleSelectCartAction(state, action);
        case CartActions.UpdateCartItem:
            return CartHandlers.handleUpdateCartItemAction(state, action);
        case CartActions.StartCheckout:
            return CartHandlers.handleStartCheckoutAction(state, action);
        case CartActions.SelectShippingAddress:
            return CartHandlers.handleSelectShippingAddressAction(state, action);
        case CartActions.SelectShippingMethod:
            return CartHandlers.handleSelectShippingMethodAction(state, action);
        case CartActions.SelectPayment:
            return CartHandlers.handleSelectPaymentMethodAction(state, action);
        case CartActions.RecalculateOrder:
            return CartHandlers.handleRecalculateOrderAction(state, action);
        case CartActions.PlaceOrder:
            return CartHandlers.handlePlaceOrderAction(state, action);
        case CartActions.OrderComplete:
            return CartHandlers.handleOrderCompleteAction(state, action);
        case CartActions.OrderFailure:
            return CartHandlers.handleOrderFailureAction(state, action);
        case CartActions.UpdateEstimatedTax:
            return CartHandlers.handleUpdateEstimatedTaxAction(state, action);
        case CartActions.InvalidTax:
            return CartHandlers.handleInvalidTaxAction(state, action);
        default:
            return state;
    }
}
