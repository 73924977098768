<mat-form-field appearance="outline" floatLabel="always">
    <mat-chip-list #chips>
        <mat-chip *ngFor="let item of getItems()" color="accent" (removed)="remove(item)" [selected]="item.selected">
            {{ item.value }}
            <mat-icon matChipRemove *ngIf='!disabled'>cancel</mat-icon>
        </mat-chip>
        <input
                #stringArrayFieldInput
                [placeholder] = "placeholder"
                [matChipInputFor]="chips"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
                (blur)='add({ input: stringArrayFieldInput, value: stringArrayFieldInput.value })'
                autocomplete="off"
        />
    </mat-chip-list>
</mat-form-field>
