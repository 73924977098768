import { ItemList } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ItemService } from '@app/services';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { GlobalStore } from '@app/state/store';
import { Store } from '@ngrx/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class VisibleItemListResolver implements Resolve<ItemList> {
    constructor(private _itemService: ItemService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<ItemList> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        const initQParams = route.queryParams;
        const params = {
            entityKey: route.params.entityId,
            searchTerm: initQParams.searchTerm,
            sort: initQParams.sortBy,
            sortOrder: initQParams.sortOrder,
            page: coerceNumberProperty(initQParams.page),
            limit: coerceNumberProperty(initQParams.limit),
            includeHiddenItems: false
        };
        // console.log(params);
        return this._itemService.getItemList(
            params.entityKey,
            params.searchTerm,
            params.page,
            params.limit,
            params.sort,
            params.sortOrder,
            params.includeHiddenItems
        );
    }
}
