export interface IPaginationLinks {
    previous: string;
    current: string;
    next: string;
    last: string;
}

export class PaginationLinks implements IPaginationLinks {
    public previous: string = '';
    public current: string = '';
    public next: string = '';
    public last: string = '';

    constructor(params?: Partial<IPaginationLinks>) {
        if (!!params) {
            this.previous = params.previous || this.previous;
            this.current = params.current || this.current;
            this.next = params.next || this.next;
            this.last = params.last || this.last;
        }
    }

    public addQuery(key: string, value: string): PaginationLinks {
        const part: string = `&${key}=${value}`;
        this.previous += part;
        this.current += part;
        this.next += part;
        this.last += part;
        return this;
    }

    public addQueries(options: { [key: string]: string }): PaginationLinks {
        for (const key in options) {
            this.addQuery(key, options[key]);
        }
        return this;
    }
}
