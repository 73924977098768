<h1>Pick Columns</h1>
<div class="picker-menu">
    <div class="available-column-list">
        <div class="search-bar-wrapper">
            <h2>Available Columns</h2>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    placeholder="Filter Columns"
                    [(ngModel)]="filterString"
                    (keyup)="filter()"
                    autocomplete="off"
                />
            </mat-form-field>
        </div>
        <div class="table-wrapper">
            <table
                *ngIf="
                    availableColumns != null &&
                    availableColumns != undefined &&
                    availableColumns.length > 0
                "
                mat-table
                class="g-table"
                [dataSource]="availableColumns"
            >
                <ng-container matColumnDef="addColumnButton">
                    <th mat-header-cell class="add-box-header" *matHeaderCellDef></th>
                    <td mat-cell class="add-box-cell" *matCellDef="let column">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            [checked]="isChecked(column)"
                            (change)="updateSelection($event, column)"
                        ></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="columnName">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let column">
                        <span class="cell-text" [matTooltip]="column.description">
                            {{ column.name }}
                        </span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['addColumnButton', 'columnName']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['addColumnButton', 'columnName']"></tr>
            </table>

            <h2
                *ngIf="
                    availableColumns == null ||
                    availableColumns == undefined ||
                    availableColumns.length <= 0
                "
                class = 'nocols'
            >
                No columns to show
            </h2>
        </div>
    </div>
    <div class="spacer"></div>
    <div class="selected-column-list">
        <h2>Selected Columns</h2>
        <div cdkDropList class="drop-list" (cdkDropListDropped)="drop($event)">
            <div class="drop-list-box" *ngFor="let column of selectedColumns" cdkDrag>
                <div class="drop-list-box drop-list-placeholder" *cdkDragPlaceholder></div>
                <div class="left-side">
                    <mat-icon class=".icon-button drop-list-box-grip" cdkDragHandle
                        >drag_handle</mat-icon
                    >
                    <span
                        class="drop-list-box-text"
                        [matTooltip]="unfilteredColumns[column].description"
                    >
                        {{ unfilteredColumns[column].name }}
                    </span>
                </div>
                <div class="right-side">
                    <mat-icon
                        class="icon-button drop-list-box-button drop-list-box-clear"
                        (click)="remove(unfilteredColumns[column])"
                        >clear</mat-icon
                    >
                </div>
            </div>
        </div>
    </div>
</div>
<div class="action-buttons" mat-dialog-options>
    <button mat-button color="accent" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="accent" (click)="confirm()">Confirm</button>
</div>
