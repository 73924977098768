<div class="address-form" [formGroup]="addressForm">
    <ptkr-field-group label="Address line 1" i18n-label>
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput formControlName="address1">
            <mat-error [ptkrErrorControl]="addressForm.get('address1')"></mat-error>
        </mat-form-field>
    </ptkr-field-group> 
    <ptkr-field-group label="Address line 2" i18n-label>
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput formControlName="address2">
            <mat-error [ptkrErrorControl]="addressForm.get('address2')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Country" i18n-label>
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput [matAutocomplete]="auto" formControlName="country" autocomplete="found-issue-587466-know-what-we-are-doing-please-set-to-off-off">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="countryDisplayFn">
                <mat-option *ngFor="let c of countryList$ | async" [value]="c.value">
                    {{ c.label }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="City" i18n-label>
        <mat-form-field appearance="outline" floatLabel="never" class="city">
            <input matInput formControlName="city">
            <mat-error [ptkrErrorControl]="addressForm.get('city')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="State or province" i18n-label>
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput [matAutocomplete]="auto2" formControlName="stateOrProvince" autocomplete="autoState">
            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="stateDisplayFn">
                <mat-option *ngFor="let s of stateList$ | async" [value]="s.name">
                    {{ s.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Postal code" i18n-label>
        <mat-form-field appearance="outline" floatLabel="never" class="zip">
            <input matInput formControlName="zipOrPostCode">
            <mat-error [ptkrErrorControl]="addressForm.get('zipOrPostCode')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
</div>
