import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'ptkr-editable-field',
    templateUrl: './editable-field.component.html',
    styleUrls: ['./editable-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: EditableFieldComponent,
        multi: true
    }]
})
export class EditableFieldComponent implements ControlValueAccessor {
    @Input() public type: string;

    // Quick fix for issues involving SkipAlertControlComponent
    @Input()
    public set value(value: any) {
        this._setValue(value);
    }

    @Output() public changed$: EventEmitter<any> = new EventEmitter<any>();

    public active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public control: FormControl = new FormControl();

    private _value: any;

    private _onChange: (v: any) => void = (v: any) => {};
    private _onTouched: () => void = () => {};

    constructor() {
        this.active$.subscribe((next: boolean) => {
            if (next) {
                this.control.enable();
            } else {
                this.control.disable();
            }
        });
    }

    public onEdit(): void {
        this.active$.next(true);
    }

    public onCancel(): void {
        this.active$.next(false);
        this.control.setValue(this._value);
    }

    public onAccept(): void {
        this.active$.next(false);
        this._setValue(this.control.value, true);
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public writeValue(obj: any): void {
        this._setValue(obj);
    }

    private _setValue(obj: any, triggerOnChanges: boolean = false): void {
        this.control.setValue(obj);
        this._value = obj;
        if (triggerOnChanges) {
            this.changed$.emit(obj);
            this._onChange(this._value);
            this._onTouched();
        }
    }
}
