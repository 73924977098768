<table class="g-table" mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>Timestamp</th>
        <td mat-cell *matCellDef="let event">{{event.timestamp | date:'short'}}</td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let event">
            <mat-icon class="event-icon" [style]="{color: event.eventTypeToColor()}">
                {{event.eventTypeToIcon()}}
            </mat-icon>
        </td>
    </ng-container>
    <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let event">{{event.message}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableConfig.columns.getColumnNames()" class="bordered left right"></tr>
    <tr mat-row *matRowDef="let row; columns: tableConfig.columns.getColumnNames();"></tr>
</table>
