import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SettingsKeyMap, UpdateKeyMap } from '@libs/iso/core';
import { EmailArrayFormControl } from '@libs/web/forms';

/**
 * Abstract class that settings sections inherit from.
 */
@Component({ template: '' })
export abstract class AbstractSettingsSectionComponent<T extends FormGroup> {
    @Input() public formGroup: T;
    public UpdateKeyMap: typeof UpdateKeyMap = UpdateKeyMap;
    public SettingsKeyMap: typeof SettingsKeyMap = SettingsKeyMap;
    // TODO: Having both SettingsKeyMap and UpdateKeyMap is redundant, though
    //  may be worth keeping if it is easier to write code this way.

    /**
     * If true, inheritance reset buttons should have a dropdown that allows
     * for the bulk-reset op.
     * @type {boolean}
     */
    @Input() public showDropdown?: boolean = false;

    /**
     * Outputs to indicate we want to open a bulk reset modal.
     * @type {EventEmitter<string>}  The string passed in is the updateKey for the
     * setting to be reset in all children.
     */
    @Output() public runBulkReset: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Emits objects representing messages to be shown via a snackbar.
     * TODO: create interface to standardize output
     */
    @Output() public notifications: EventEmitter<any> = new EventEmitter<any>();

    public getField(control: string): AbstractControl {
        return this.formGroup?.controls?.[control];
    }

    public getFieldValue(control: string): any {
        return this.getField(control).value;
    }

    /**
     * Used by Email array controllers when inherited emails are removed.
     * Tells controller to set 'overwrite' to true.
     * @param {string} path  A UpdateKeyType pathway to an email string array, is used to find the
     *                       correct control to update. If overwrite is undefined nothing happens.
     * @returns {void}  nothing
     */
    public setOverwrite(path: string): void {
        const control: AbstractControl = this.getField(path);
        if ((control as EmailArrayFormControl)?.overwrite !== undefined) {
            (control as EmailArrayFormControl).overwrite = true;
        }
    }
}
