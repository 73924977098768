import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import * as moment from 'moment';
import { ColumnType } from '@libs/iso/core';

@Component({
    selector: 'ptkr-devices-reporting-chart',
    templateUrl: './devices-reporting-chart.component.html',
    styleUrls: ['./devices-reporting-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesReportingChartComponent implements OnInit {
    @Input()
    public set reportingDevices(value: any) {
        if (!!value) {
            this.totalDevices = value.totalDevices;
            this.devicesReporting = value.reportingDevices;
            this.percentReporting = coerceNumberProperty(value.percentage);
            // tslint:disable-next-line:no-magic-numbers
            const empty = 100 - this.percentReporting;
            this.chartData = [
                {
                    name: 'Reporting Devices',
                    value: this.percentReporting.toFixed(2)
                },
                {
                    name: 'Not Reported',
                    value: empty.toFixed(2)
                }
            ];
        }
    }

    private _monthName: string = moment().format('MMMM');
    @Input()
    public set month(value: string | number) {
        if (typeof value !== 'undefined') {
            if (isNaN(+value)) {
                this._monthName = value as string;
            } else {
                this._monthName = moment()
                    .month(value)
                    .format('MMMM');
            }
        } else {
            this._monthName = moment().format('MMMM');
        }
    }
    public get monthName(): string {
        return this._monthName;
    }

    // tslint:disable-next-line:no-magic-numbers
    public chartData: any = [{ name: 'Not Reported', value: 100 }];
    public chartOptions: any = {
        title: 'Devices Reporting',
        titleTextStyle: {
            color: 'transparent'
        },
        pieHole: 0.6,
        legend: 'none',
        pieSliceText: 'none',
        enableInteractivity: true,
        slices: {
            0: { color: '#d3d3d3' },
            1: { color: '#1ab292' }
        }
    };
    public chartType: string = 'PieChart';
    public chartColumns: { [key: string]: ColumnType } = { X: 'string', Y: 'number' };
    public totalDevices: number = 0;
    public devicesReporting: number = 0;
    public percentReporting: number = 0;

    constructor() {}

    ngOnInit(): void {}

    public customColors: any = (value): string => {
        if (value === 'Reporting Devices') {
            return '#1ab292';
        } else {
            return '#d3d3d3';
        }
        // tslint:disable-next-line:semicolon
    };

    public labelFormatter: any = (value): string =>
        // tslint:disable-next-line:semicolon
        [value.data.name, `${value.data.value}%`].join('\n');
}
