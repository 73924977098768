import { Component, OnInit, Input } from '@angular/core';
import {
    ItemType,
    Cart,
    ShippingMethod,
    UserPaymentMethod,
    ShippingAddress,
    PaymentMethodType
} from '@libs/iso/core';

@Component({
    selector: 'ptkr-checkout-confirm-step',
    templateUrl: './confirm-step.component.html',
    styleUrls: ['./confirm-step.component.scss']
})
export class ConfirmStepComponent implements OnInit {
    private _cart: Cart;
    @Input()
    public set cart(value: Cart) {
        this.subtotal = 0;
        if (!!value) {
            this._cart = value;
            this._cart.items.forEach(i => {
                this.subtotal += i.price * i.qty;
            });
        }
    }
    public get cart(): Cart {
        return this._cart;
    }
    @Input()
    public shippingMethod: ShippingMethod;
    @Input()
    public shippingAddress: ShippingAddress;
    @Input()
    public paymentMethod: UserPaymentMethod;
    @Input()
    public taxes: number = 0;

    public ItemType: typeof ItemType = ItemType;
    public PaymentMethodType: typeof PaymentMethodType = PaymentMethodType;
    public subtotal: number = 0;

    constructor() {}

    ngOnInit(): void {}
}
