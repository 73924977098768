<div class="flex flex-row items-center space-x-3" [ngClass]="{'has-tooltip': total > 0}">
    <div class="min-w-[20%]" *ngIf="showValues">
        {{total}}
    </div>
    <div class="distribution flex-grow">
        <div class="tooltip bg-white p-2 rounded shadow border border-gray-200">
            <div class="space-y-1">
                <div *ngFor="let element of data" class="flex items-center space-x-2">
                    <div class="w-3 h-3 {{element.class}} rounded"></div>
                    <div>{{element.value}} <span class="text-neutral-400" i18n>devices</span></div>
                    <div class="flex-1"></div>
                    <div class="text-right">{{element.category}}</div>
                </div>
            </div>
        </div>
        <ptkr-categorical-distribution-inline
            [elements]="data">
        </ptkr-categorical-distribution-inline>
    </div>
</div>
