import { PaginationLinks } from '@libs/iso/core';
import { DeviceLineItemSummary, DeviceLineItemSummaryModel } from './DeviceLineItemSummary';

export interface DeviceListModel {
    docs: Partial<DeviceLineItemSummaryModel & { entityName: string }>[];
    totalDocs: number;
    urls: PaginationLinks;
}

export class DeviceList implements DeviceListModel {
    public docs: Partial<DeviceLineItemSummaryModel & { entityName: string, formats: string[] }>[] = [];
    public totalDocs: number = 0;
    public urls: PaginationLinks = new PaginationLinks();

    constructor(params?: Partial<DeviceListModel>) {
        if (!!params) {
            if (Array.isArray(params.docs)) {
                this.docs = params.docs.map(d => new DeviceLineItemSummary(d));
            }
            this.totalDocs = params.totalDocs || this.totalDocs;
            this.urls = new PaginationLinks(params.urls);
        }
    }
}
