export interface IMetadata {
    user: {
        username: string;
        firstname: string;
        lastname: string;
        id: string;
    };
    entity: {
        name: string;
        id: string;
    };
}

export class Metadata implements IMetadata {
    public entity: { name: string, id: string };
    public user: { username: string; firstname: string; lastname: string, id: string };
    constructor(defaults: IMetadata) {
        this.user = defaults.user;
        this.entity = defaults.entity;
    }
}
