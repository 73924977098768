import { BaseModel, IBaseModel } from '@libs/iso/core/models/BaseModel';
import { ObjectId } from 'bson';
import { min, max } from 'lodash';
import { isMoment } from 'moment';
import * as moment from 'moment';

export interface IDeviceHealthCheck extends IBaseModel {
    timestamp?: Date;
    deviceKey: string | ObjectId;
    entityKey: string | ObjectId;
    installKey: string | ObjectId;
    icmp: IDeviceHealthCheckICMP;
    snmp: IDeviceHealthCheckSNMP;
    identification?: IDeviceHealthCheckIdentification;
    success: boolean;
}

export interface IDeviceHealthCheckICMP {
    ok: boolean;
    message?: string;
}

export interface IDeviceHealthCheckSNMP {
    ok: boolean;
    message?: string;
}

export interface IDeviceHealthCheckIdentification {
    serialNumber: {
        ok: boolean;
        message: string;
    };
}

enum DeviceHealthCheckType {
    SNMP,
    ICMP,
    SerialNumber
}

export class DeviceHealthCheck extends BaseModel implements IDeviceHealthCheck {
    public timestamp?: Date;
    public deviceKey: string | ObjectId;
    public entityKey: string | ObjectId;
    public installKey: string | ObjectId;
    public icmp: IDeviceHealthCheckICMP;
    public snmp: IDeviceHealthCheckSNMP;
    public identification?: IDeviceHealthCheckIdentification;
    public success: boolean = false;
    constructor(params?: IDeviceHealthCheck) {
        super();
        if (params) {
            this.createdDate = params.createdDate;
            this.modifiedDate = params.modifiedDate;
            if (params.timestamp) {
                this.timestamp = params.timestamp;
            }
            this.entityKey = params.entityKey;
            this.installKey = params.installKey;
            this.deviceKey = params.deviceKey;
            this.icmp = params.icmp;
            this.snmp = params.snmp;
            this.identification = params.identification;
            this.success = params.success;
        }
    }

    public getHealthy(type?: DeviceHealthCheckType): boolean | null {
        if (type == null) {
            return this.success;
        }
        let ok;
        switch (type) {
            case DeviceHealthCheckType.SNMP:
                ok = this.snmp?.ok;
                break;
            case DeviceHealthCheckType.ICMP:
                ok = this.icmp?.ok;
                break;
            case DeviceHealthCheckType.SerialNumber:
                ok = this.identification?.serialNumber?.ok;
                break;
            default:
                return null;
        }
        return ok == null ? null : ok;
    }

    public getMessage(type: DeviceHealthCheckType): string | null {
        switch (type) {
            case DeviceHealthCheckType.SNMP:
                return this.snmp?.message || null;
            case DeviceHealthCheckType.ICMP:
                return this.icmp?.message || null;
            case DeviceHealthCheckType.SerialNumber:
                return this.identification?.serialNumber?.message || null;
            default:
                return null;
        }
    }
}

interface SimpleRangeHealthCheck {
    index: number;
    start: Date;
    end: Date;
    healthy: boolean;
    message?: string;
}

export class DeviceHealthCheckGroup {
    public healthChecks: DeviceHealthCheck[] = [];
    constructor(healthChecks?: DeviceHealthCheck[]) {
        if (Array.isArray(healthChecks) && healthChecks.length > 0) {
            this.healthChecks = healthChecks.map(h => {
                h.timestamp = moment(h.createdDate)
                    .startOf('day')
                    .toDate();
                h.createdDate = isMoment(h.createdDate) ? h.createdDate.toDate() : h.createdDate;
                h.modifiedDate = isMoment(h.modifiedDate)
                    ? h.modifiedDate.toDate()
                    : h.modifiedDate;
                return h;
            });
            // Insert a "current health check" that represents the same state as the most recent health check.
            // const last = healthChecks[healthChecks.length - 1];
            // const today = moment()
            //     .startOf('day')
            //     .toDate();
            // if (!moment(last.timestamp).isSame(today)) {
            //     this.healthChecks.push(
            //         new DeviceHealthCheck({
            //             ...last,
            //             timestamp: today
            //         })
            //     );
            // }
        }
    }

    private static mapSimpleRangeHealthCheckToSeriesPoint(check: SimpleRangeHealthCheck): any {
        return {
            value: [
                check.index,
                +check.start,
                +check.end,
                +check.end - +check.start,
                check.message
            ],
            itemStyle: {
                normal: {
                    color: check.healthy === true ? '#6edd9d' : '#ff7474'
                }
            }
        };
    }

    private static objectIdToString(key: string | ObjectId): string {
        if (key instanceof ObjectId) {
            return key.toHexString();
        }
        return key;
    }

    public extent(): [Date, Date] {
        return [
            min(this.healthChecks.map(v => v.timestamp)),
            max(this.healthChecks.map(v => v.timestamp))
        ];
    }

    public getDataSeries(): any[] {
        return [
            ...this.snmp().map(DeviceHealthCheckGroup.mapSimpleRangeHealthCheckToSeriesPoint),
            ...this.icmp().map(DeviceHealthCheckGroup.mapSimpleRangeHealthCheckToSeriesPoint),
            ...this.serialNumber().map(
                DeviceHealthCheckGroup.mapSimpleRangeHealthCheckToSeriesPoint
            )
        ];
    }

    public snmp(): SimpleRangeHealthCheck[] {
        return this.calculateCategoricalTransitions(0, DeviceHealthCheckType.SNMP);
    }

    public icmp(): SimpleRangeHealthCheck[] {
        return this.calculateCategoricalTransitions(1, DeviceHealthCheckType.ICMP);
    }

    public serialNumber(): SimpleRangeHealthCheck[] {
        return this.calculateCategoricalTransitions(2, DeviceHealthCheckType.SerialNumber);
    }

    private calculateCategoricalTransitions(
        index: number,
        type: DeviceHealthCheckType
    ): SimpleRangeHealthCheck[] {
        const filled: SimpleRangeHealthCheck[] = [];
        if (this.healthChecks.length === 0) {
            return filled;
        }
        let start: DeviceHealthCheck = this.healthChecks[0];
        for (let i = 0; i < this.healthChecks.length; i++) {
            if (i === 0) {
                continue;
            }
            const current = this.healthChecks[i];
            const currentHealth = current.getHealthy(type);
            // skip the last item
            if (i === this.healthChecks.length - 1 && currentHealth != null) {
                filled.push({
                    index,
                    start: <Date>start.timestamp,
                    end: <Date>current.timestamp,
                    healthy: currentHealth,
                    message: current.getMessage(type)
                });
                break;
            }
            const startHealth = start.getHealthy(type);
            if (startHealth == null) {
                start = current;
                continue;
            }
            if (startHealth !== currentHealth) {
                filled.push({
                    index,
                    start: <Date>start.timestamp,
                    end: <Date>current.timestamp,
                    healthy: startHealth,
                    message: start.getMessage(type)
                });
                start = current;
            }
        }
        return filled;
    }
}
