import { BaseAddressModel, BaseAddress } from '../BaseAddress';

export interface IShippingAddressModel extends BaseAddressModel {
    companyName: string;
    attn: string;
}
interface ShippingAddressConstructorParams extends IShippingAddressModel {}

export class ShippingAddress extends BaseAddress implements IShippingAddressModel {
    public companyName: string = '';
    public attn: string = '';

    constructor(params?: Partial<ShippingAddressConstructorParams>) {
        super(params);
        if (!!params) {
            this.companyName = params.companyName || this.companyName;
            this.attn = params.attn || this.attn;
        }
    }
}
