import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UpdateKeyMap } from '@libs/iso/core';

@Component({
    selector: 'ptkr-device-data-gathering-settings-section',
    templateUrl: './device-data-gathering-settings-section.component.html',
    styleUrls: ['./device-data-gathering-settings-section.component.scss']
})
export class DeviceDataGatheringSettingsSectionComponent {
    @Input() public itemKey: string;

    @Input() public deviceDataGatheringSettingsForm: FormGroup;

    public UpdateKeyMap: typeof UpdateKeyMap = UpdateKeyMap;

    constructor() {}
}
