import { Component, Input, OnInit } from '@angular/core';

export enum BannerType {
    Info = 'info',
    Warning = 'warning',
    Error = 'error'
}

@Component({
    selector: 'ptkr-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    @Input() public type: BannerType = BannerType.Info;
    @Input() public noActions: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
