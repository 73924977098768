<form [formGroup]="userProfileForm" (ngSubmit)="saveUserForm(userProfileForm)">
    <ptkr-field-group label="Account status" i18n-label>
        <mat-form-field appearance="outline" floatLabel="never" class="status-select">
            <mat-select formControlName="status" disabled>
                <mat-option *ngFor="let s of Status.asArray()" [value]="s">{{ Status.toString(s) }}</mat-option>
            </mat-select>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="First name" i18n-label required="true">
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput formControlName="firstName">
            <mat-error [ptkrErrorControl]="userProfileForm.get('firstName')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Last name" i18n-label required="true">
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput formControlName="lastName">
            <mat-error [ptkrErrorControl]="userProfileForm.get('lastName')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Email" i18n-label="@@email" required="true">
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput type="email" formControlName="email">
            <mat-error [ptkrErrorControl]="userProfileForm.get('email')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Office number" i18n-label required="true">
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput type="tel" formControlName="officeNumber">
            <mat-error [ptkrErrorControl]="userProfileForm.get('officeNumber')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Cell number" i18n-label>
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput type="tel" formControlName="cellNumber">
            <mat-error [ptkrErrorControl]="userProfileForm.get('cellNumber')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Timezone" i18n-label>
        <ptkr-timezone-picker [defaultTimeZone]='user.preferredTimeZone' (timeZone)='onTimeZoneUpdate($event)'></ptkr-timezone-picker>
    </ptkr-field-group>
    <!-- <ptkr-field-group>
        <span class="spacer"></span>
        <div class="content-container">
            <div class="content">
                <label class="field-label">Do you want to receive communications via text message?</label>
                <br>
                <mat-radio-group formControlName="receiveSMS">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <br>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </ptkr-field-group> -->
    <hr>
    <div class="action-buttons">
        <button mat-flat-button color="accent">{{ buttonLabel }}</button>
    </div>
</form>
