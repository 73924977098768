enum Erp {
    DealerCommander,
    Eautomate,
    GreatPlains,
    MicrosoftDynamics,
    MiracleService,
    NextGen,
    OMD,
    SAP,
    Other
}

namespace Erp {
    export function toString(type: Erp): string {
        switch (type) {
            case Erp.DealerCommander:
                return 'Entity Commander';
            case Erp.Eautomate:
                return 'E-Automate';
            case Erp.GreatPlains:
                return 'Great Plains';
            case Erp.MicrosoftDynamics:
                return 'Microsoft Dynamics';
            case Erp.MiracleService:
                return 'Miracle Service';
            case Erp.NextGen:
                return 'NextGen';
            case Erp.OMD:
                return 'OMD';
            case Erp.SAP:
                return 'SAP';
            case Erp.Other:
                return 'Other';
            default:
                return '';
        }
    }

    export const asArray: Erp[] = [
        Erp.DealerCommander,
        Erp.Eautomate,
        Erp.GreatPlains,
        Erp.MicrosoftDynamics,
        Erp.MiracleService,
        Erp.NextGen,
        Erp.OMD,
        Erp.SAP,
        Erp.Other
    ];
}

export { Erp };
