<section>
    <div class="left-section">
        <div *ngIf="deviceListCount">{{deviceListCount}} device(s)</div>
    </div>
    <div class="right-section">
        <span i18n>Items per page:</span>
        <mat-form-field>
            <mat-select class="select"
                        [value]="startingPageSize"
                        (selectionChange)="onSelectionChange($event)">
                <mat-option *ngFor="let pageSize of pageSizeOptions"
                            [value]="pageSize">
                    {{pageSize}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span i18n>Viewing page {{currentPage}}</span>
        <div class="nav-buttons">
            <button aria-label="previous page"
                    matTooltip="Previous page"
                    i18n-matTooltip
                    [disabled]="!prevEnabled"
                    (click)="prevOnClick()">
                <i class="fas fa-chevron-left"></i>
            </button> 
            <button aria-label="next page"
                    matTooltip="Next page"
                    i18n-matTooltip
                    [disabled]="!nextEnabled"
                    (click)="nextOnClick()">
                <i class="fas fa-chevron-right"></i>
            </button>
        </div>
    </div>
</section>
