<div>
    <div>
        <div class="label">Starting page counts</div>
        <div class="label-subheading">
            Change the starting page count for this local device.
        </div>
    </div>
    <!-- Note: 'match-accordians' is class defined in device-starting-page-section.component.scss;
               it hacks the form-element-margin + toggle class combination to make it looks similar
               to a expanded accordian menu. It makes the menu match the others, however, this may
               not be the right way to go about it. At the very least we should record that this is
               here so if we end up needing a similar feature elsewhere we can refactor it. -->
    <div class="form-element-margin toggle match-accordions">
        <form [formGroup]="startingPageCountsForm">
            <div class="form-element-margin">
                <div class="label">
                    Total
                    <a matTooltip="Total pages printed for both color and black and white"><small>What's this?</small></a>
                </div>
                <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput type="number" disabled formControlName="total">
                    <mat-error ></mat-error>
                </mat-form-field>
            </div>

            <div class="form-element-margin">
                <div class="label">
                    Total black
                    <a matTooltip="Total black and white pages printed"><small>What's this?</small></a>
                </div>
                <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput type="number" formControlName="totalBlack">
                    <mat-error ></mat-error>
                </mat-form-field>
            </div>

            <div class="form-element-margin">
                <div class="label">
                    Total color
                    <a matTooltip="Total color pages printed"><small>What's this?</small></a>
                </div>
                <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput type="number" formControlName="totalColor">
                    <mat-error ></mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>
</div>
