import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ptkr-empty-table-indicator',
    templateUrl: './empty-table-indicator.component.html',
    styleUrls: ['./empty-table-indicator.component.scss']
})
export class EmptyTableIndicatorComponent implements OnInit {
    @Input() public nColumns: number;

    constructor() {}

    ngOnInit(): void {}
}
