<div class="body-wrapper">
    <h1>Delete Entity</h1>

    <section class='summary'>
        This action will permanently remove {{entity.name}}, along with all its descendants, including:
        <ul>
            <li><em>All child entites</em></li>
            <li><em>All users under this entity, or under any child entities</em></li>
            <li><em>All installs of this entity, or of any child entities</em></li>
        </ul>
        Before deleting this entity, any descendant entities you would like to keep can be moved from
        the <a (click)="gotoMoveEntities()">Move entities</a> page. Users registered under this entity
        can be reassigned to a different entity from the <a (click)="gotoManageUsers()">Manage users</a>
        page.
    </section>

    <em>If you're sure you want to delete this entity and all of its descendants, enter '{{entity.name}}' in the textbox below to enable the delete button.</em>

    <div class="confirm-deletion-wrapper">
        <ptkr-confirm-deletion [confirmationText]="entity.name" (isMatch)="setDelButDisabled($event)"></ptkr-confirm-deletion>
    </div>

    <div class="action-buttons">
        <button mat-button (click)="close()">Cancel</button>
        <button mat-flat-button
        class="delete-button"
        type="button"
        [disabled]="delButDisabled"
        (click)="onConfirmation()">
        Delete entity
    </button>
    </div>
</div>