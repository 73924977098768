import { Component, OnInit, Input } from '@angular/core';
import { User } from '@app/models';

@Component({
    selector: 'ptkr-sales-rep-info',
    templateUrl: './sales-rep-info.component.html',
    styleUrls: ['./sales-rep-info.component.scss']
})
export class SalesRepInfoComponent implements OnInit {
    private _user: User = new User();
    @Input()
    public set user(value: User) {
        if (!!value) {
            this._user = value;
            let phone: string;
            if (
                !!this._user.contact &&
                !!this._user.contact.phone &&
                this._user.contact.phone.length > 0
            ) {
                phone = this._user.contact.phone[0].number;
            }
            this.phone = phone;
        }
    }
    public get user(): User {
        return this._user;
    }

    public phone: string;

    constructor() {}

    ngOnInit(): void {}
}
