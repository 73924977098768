<form [formGroup]="deviceGroupForm">
    <div style="margin-bottom: 15px">
        <ptkr-banner>
            <span body i18n>Changes to these groups will be applied during the next device scan.</span>
        </ptkr-banner>
    </div>
    <div style="display: flex; flex-direction: row; align-items: baseline; gap: 10px">
        <mat-form-field floatLabel="never" appearance="outline" style="flex: 1">
            <mat-select formControlName="field">
                <mat-option [value]="0">Select a field</mat-option>
                <mat-option *ngFor="let field of availableFields" [value]="field.type">
                    {{ EntityDynamicDeviceGroupField.display(field.type) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <button
                mat-button
                (click)="addGroup()"
                [disabled]="deviceGroupForm.get('field').value == 0"
            >
                Add Group
            </button>
        </div>
    </div>
    <div formArrayName="groups">
        <div
            *ngFor="let group of groups().controls; let i = index"
            [formGroupName]="i"
            style="display: flex; flex-direction: row; align-items: baseline; gap: 10px"
        >
            <mat-form-field floatLabel="never" appearance="outline" style="flex: 1">
                <input matInput placeholder="Group Name" formControlName="name" />
            </mat-form-field>
            <div style="flex: 1">
                <ng-container *ngIf="getFieldComponent()">
                    <ng-container
                        ptkrDynamicFormControl
                        [control]="group.get('options').get('selector')"
                        [component]="getFieldComponent()"
                    ></ng-container>
                </ng-container>
            </div>
            <button mat-icon-button (click)="removeGroup(i)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</form>
