<form [formGroup]='controller'>
    <mat-form-field appearance='outline'>
        <mat-icon matPrefix style='opacity:0.24'>search</mat-icon>
        <mat-chip-list #chips>
            <mat-chip class='chip' *ngFor='let name of names' color='primary' (removed)='removeChip()'>
                {{ name }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <div class='inputwrapper' *ngIf='!locked'>
            <input matInput #tzInput [matAutocomplete]='auto' formControlName='tz'
                   [placeholder]="placeholder || defaultTimeZone || ''">
            <mat-autocomplete #auto='matAutocomplete'>
                <mat-option
                    class='hierarchyautofill'
                    *ngFor='let tz of this._suggestions'
                    (onSelectionChange)='onSuggestionSelected(tz)'
                    [value]="'aaaaaa'">
                    <div class='container'>
                        <div class='left'>
                            <span><b>{{ tz.name }}</b></span><br>
                        </div>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>
        <ng-content select='[mat-error]'></ng-content>
    </mat-form-field>
</form>
