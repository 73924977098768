import { Item } from './Item';

export interface IItemSearchAllResults {
    devices: Item[];
    components: Item[];
}

export class ItemSearchAllResults implements IItemSearchAllResults {
    public devices: Item[] = [];
    public components: Item[] = [];

    constructor(defaults?: Partial<IItemSearchAllResults>) {
        if (defaults) {
            this.devices = defaults.devices || this.devices;
            this.components = defaults.components || this.components;
        }
    }
}
