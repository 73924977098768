import { FormGroup } from '@angular/forms';
import { UpdateKeyType } from '@libs/iso/core';

export type OverwrittenValuesArgs = {
    settingsOnly: boolean;
};

/**
 * Extend this class to create reusable configurations and to pass more contextual data
 * to your form groups (i.e. for post-processing).
 * @see extractOverwrittenValues
 */
export abstract class AbstractSettingsFormGroup extends FormGroup {
    /**
     * All config panels, inherited settings and non-inherited, expect to be able to access this
     * function to gather a minimized update object containing only values that were changed by the
     * user. The value returned by this function should already contain any post-processing that
     * needed to be done between when the user clicked submit and sending the data up to the server,
     * at least as much as is possible.
     * @returns {any} the partial config object, containing only the dirty values that need to be
     * updated.
     */
    public abstract extractOverwrittenValues(args?: OverwrittenValuesArgs): { set: Partial<{ [key in UpdateKeyType]: any }>, unset: UpdateKeyType[] };
}
