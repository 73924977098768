import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Inject } from '@angular/core';
import { ComponentAutocompleteComponent } from '@app/shared/components/component-autocomplete/component-autocomplete.component';
import { ItemService } from '@app/core/services/item.service';
import { CartService } from '@app/core/services/cart.service';
import { NotificationService } from '@app/core/services/notification.service';
import {
    distinctUntilChanged,
    debounceTime,
    filter,
    switchMap,
    startWith,
    finalize,
    map,
    take
} from 'rxjs/operators';
import { ItemSearchAllResults, Item, PaginatedList, ItemType } from '@libs/iso/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { RouterService } from '@app/core/services/router.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ptkr-order-toner-overlay',
    templateUrl: './order-toner-overlay.component.html',
    styleUrls: ['./order-toner-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderTonerOverlayComponent implements OnInit {
    @ViewChild(ComponentAutocompleteComponent, {static: true})
    public autocomplete: ComponentAutocompleteComponent;

    public results$: Observable<ItemSearchAllResults>;
    public lookupInProgress: boolean = false;
    public selectedItem: Item;
    public compatibleItems$: Observable<Item[]>;
    public entityId: string = '';
    public defaultValue: string = '';
    public defaultItem: Item = null;

    constructor(
        public dialogRef: MatDialogRef<OrderTonerOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { defaultItem: Item },
        private _itemService: ItemService,
        private _cartService: CartService,
        private _store: Store<GlobalStore>,
        private _notificationService: NotificationService,
        private _routerService: RouterService
    ) {
        this.defaultItem = data.defaultItem;
        this.defaultValue = !!this.defaultItem ? this.defaultItem.model : this.defaultValue;
    }

    ngOnInit(): void {
        const searchDelay = 350;
        const minSearchLength = 2;
        this._store
            .pipe(
                select(fromEntity.entityId),
                take(1)
            )
            .subscribe(entityId => {
                this.entityId = entityId;
                this.results$ = this.autocomplete.change.pipe(
                    startWith(this.defaultItem || this.defaultValue),
                    distinctUntilChanged(),
                    debounceTime(searchDelay),
                    filter(v => typeof v === 'string' && v.length >= minSearchLength),
                    switchMap(v => this._itemService.searchAll(entityId, v as string))
                );
            });
        if (!!this.defaultItem) {
            this.itemSelected(this.defaultItem);
        }
    }

    public itemSelected(item: Item): void {
        this.selectedItem = item;
        this.lookupInProgress = true;
        this.compatibleItems$ = this._itemService
            .getCompatibleOrderItems(this.entityId, item._id, 0)
            .pipe(
                map(val => ((val as any) as PaginatedList<Item>).docs),
                map(val => {
                    if (item.type !== ItemType.Device) {
                        val.unshift(item);
                    }
                    return val;
                }),
                finalize(() => (this.lookupInProgress = false))
            );
    }

    public addItemToCart({ item, qty }: { item: Item; qty: number }): void {
        let entityId: string = '';

        if (qty < 1) {
            this._notificationService.error('Quantity must be greater than 1');
            return;
        }

        this._store
            .pipe(
                take(1),
                select(fromEntity.entityId)
            )
            .subscribe(eId => (entityId = eId));
        this._cartService.addToCart(entityId, item._id as string, qty).subscribe(
            res => {
                console.log('added to cart', res);
                this._notificationService.success('Item added to cart successfully!');
            },
            error => {
                console.error('unable to add to cart', error);
                this._notificationService.error(error);
            }
        );
    }

    public viewCart(): void {
        this._routerService.navigate('/order/carts');
        this.dialogRef.close();
    }
}
