import { Injectable } from '@angular/core';
import { Action, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import {
    AuthActions,
    ClearPermissionsAction,
    ClearUserAction,
    LoginAction,
    StartImpersonationAction,
    StopImpersonationAction,
    LoginUserAction,
    CompleteLoginAction,
    RestoreLoginAction,
    RestoreImpersonationAction,
    ClearEntityAction
} from '../actions';
import { StorageService, UserService, RouterService } from '@app/services';
import { GlobalStore } from '@app/state/store';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { fromAuth } from '@app/state/selectors';

@Injectable()
export class AuthEffects {
    constructor(
        private _actions$: Actions,
        private _storageService: StorageService,
        private _userService: UserService,
        private _routerService: RouterService,
        private _store: Store<GlobalStore>
    ) {}

    @Effect()
    public login$: Observable<Action> = this._actions$.pipe(
        ofType(AuthActions.Login, AuthActions.StartImpersonation, AuthActions.StopImpersonation),
        map(
            (action: LoginAction | StartImpersonationAction | StopImpersonationAction) =>
                action.payload
        ),
        tap(payload => (this._storageService.authToken = payload)),
        switchMap(payload => this._userService.initUser(`Bearer ${payload}`)),
        switchMap(user => of(new LoginUserAction(user)))
    );

    @Effect()
    public restoreLogin$: Observable<Action> = this._actions$.pipe(
        ofType(AuthActions.RestoreLogin, AuthActions.RestoreImpersonation),
        map((action: RestoreLoginAction | RestoreImpersonationAction) => action.payload),
        tap(payload => (this._storageService.authToken = payload.token)),
        switchMap(payload => this._userService.initUser(`Bearer ${payload.token}`)),
        switchMap(user => of(new LoginUserAction(user)))
    );

    @Effect({ dispatch: false })
    public completeLogin$: Observable<{}> = this._actions$.pipe(
        ofType(AuthActions.CompleteLogin),
        switchMap((action: CompleteLoginAction) => this._store.pipe(select(fromAuth.resolveFn))),
        switchMap(resolve => {
            if (!!resolve) {
                resolve(true);
            }
            this._routerService.fullNavigate(this._routerService.getLoginRedirect());
            return of();
        })
    );

    @Effect()
    public logout$: Observable<Action> = this._actions$.pipe(
        ofType(AuthActions.Logout),
        switchMap(action => {
            this._storageService.authToken = '';
            return [new ClearPermissionsAction(), new ClearUserAction(), new ClearEntityAction()];
        })
    );
}
