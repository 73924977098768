<div class="card-icons" *ngIf="icons">
    <div class="card-icons-wrapper">
        <i class="fab fa-cc-visa"></i>
        <i class="fab fa-cc-mastercard"></i>
        <i class="fab fa-cc-discover"></i>
        <i class="fab fa-cc-amex"></i>
    </div>
</div>
<form class="add-cc" [formGroup]="creditCardForm">
    <ptkr-field-group label="Card Number">
        <div id="card-element" class="field is-empty style-stripe" #cardNum></div>
        <!-- <mat-form-field appearance="outline" floatLabel="never">
            <input #cardNum matInput formControlName="name_on_card" placeholder="Name">
        </mat-form-field> -->
    </ptkr-field-group>
    <ptkr-field-group label="Name on card">
        <mat-form-field appearance="outline"floatLabel="never">
            <input matInput formControlName="name_on_card" placeholder="Name">
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Expiration date">
        <div id="card-element" class="field is-empty style-stripe" #cardExp></div>
        <!-- <mat-form-field appearance="outline"floatLabel="never">
            <input #cardExp matInput formControlName="name_on_card" placeholder="Name">
        </mat-form-field> -->
    </ptkr-field-group>
    <ptkr-field-group label="Card CVC">
        <div id="card-element" class="field is-empty style-stripe" #cardCvc></div>
        <!-- <mat-form-field appearance="outline" floatLabel="never">
            <input #cardCvc matInput formControlName="name_on_card" placeholder="Name">
        </mat-form-field> -->
    </ptkr-field-group>
</form>
