export enum ReportSharingMode {
    Private = 'Private',
    WithChildren = 'WithChildren',
    WithEntity = 'WithEntity'
}

export namespace ReportSharingMode {
    export const defaultMode = ReportSharingMode.WithChildren;

    export const allValues = [
        ReportSharingMode.Private,
        ReportSharingMode.WithChildren,
        ReportSharingMode.WithEntity
    ];

    export function fromString(value: string): ReportSharingMode {
        switch (value) {
            default:
            case ReportSharingMode.WithChildren:
                return ReportSharingMode.WithChildren;
            case ReportSharingMode.Private:
                return ReportSharingMode.Private;
            case ReportSharingMode.WithEntity:
                return ReportSharingMode.WithEntity;
        }
    }

    export function toContextDisplayName(value: ReportSharingMode, entityName: string): string {
        switch (value) {
            case ReportSharingMode.Private:
                return $localize`Only me`;
            case ReportSharingMode.WithChildren:
                return $localize`Children`;
            case ReportSharingMode.WithEntity:
                return entityName;
            default:
                return '';
        }
    }
}
