<ptkr-dropdown-filter-molecule
    [dropdownContent]="dropdownContentTemplate"
    [selectedContent]="selectedContentTemplate"
    [disabled]="disabled"
    [(showDropdown)]="showDropdown"
    [filterText]="filterText"
    (filterTextChange)="onFilterTextChange($event)"
></ptkr-dropdown-filter-molecule>
<ng-template #selectedContentTemplate>
    <div
        polymorpheus-outlet
        [content]="!!selection ? optionContent : noSelectionContent"
        [context]="selection"
    ></div>
</ng-template>
<ng-template #dropdownContentTemplate>
    <div
        *ngFor="let option of options"
        polymorpheus-outlet
        [content]="optionContent"
        [context]="option"
        (click)="onOptionClick(option)"
    ></div>
</ng-template>