import { BaseAddressModel, BaseAddress } from '../BaseAddress';
import { EntityAddressType } from './EntityAddressType';

export interface EntityAddressModel extends BaseAddressModel {
    name: string;
    type: EntityAddressType;
    address3: string;
    address4: string;
}

interface EntityAddressConstructorParams extends EntityAddressModel {}

export class EntityAddress extends BaseAddress implements EntityAddressModel {
    public name: string = '';
    public type: EntityAddressType;
    public address3: string = '';
    public address4: string = '';

    constructor(params?: Partial<EntityAddressConstructorParams>) {
        super(params);
        if (!!params) {
            this.name = params.name || this.name;
            this.type = params.type || this.type;
            this.address3 = params.address3 || this.address3;
            this.address4 = params.address4 || this.address4;
        }
    }
}
