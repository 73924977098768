import { CategoricalDistributionElement } from '@libs/iso/core';

export interface IReporting {
    percentage: number;
    reporting: number;
    total: number;
}

export class Reporting implements IReporting {
    public percentage: number = 0;
    public reporting: number = 0;
    public total: number = 0;

    constructor(defaults?: Partial<IReporting>) {
        if (defaults) {
            this.percentage = defaults.percentage || this.percentage;
            this.reporting = defaults.reporting || this.reporting;
            this.total = defaults.total || this.total;
        }
    }

    public toCategoricalDistributionElement(): CategoricalDistributionElement[] {
        return [
            {
                category: 'Reporting',
                value: this.reporting,
                color: '#6EDD9D'
            },
            {
                category: 'Not Reporting',
                value: this.total - this.reporting,
                color: '#FF7474'
            }
        ];
    }
}
