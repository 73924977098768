import { IBillingMeterPreference, BillingMeterPreference } from './BillingMeterPreference';
import {
    IBillingPreferencesSchedule,
    BillingPreferencesSchedule
} from './BillingPreferencesSchedule';

export type BillingPreferencesCounters = { [name: string]: IBillingMeterPreference };

export interface IBillingPreferences {
    schedule: IBillingPreferencesSchedule;
    counters: BillingPreferencesCounters;
}

export class BillingPreferences implements IBillingPreferences {
    public schedule: IBillingPreferencesSchedule = new BillingPreferencesSchedule();
    public counters: BillingPreferencesCounters = {};

    constructor(params?: Partial<IBillingPreferences>) {
        if (!!params) {
            if (!!params.schedule) {
                this.schedule = new BillingPreferencesSchedule(params.schedule);
            }
            if (!!params.counters) {
                Object.keys(params.counters).forEach(key => {
                    this.counters[key] = new BillingMeterPreference(params.counters[key]);
                });
            }
        }
    }

    /**
     * Determines the prioritized formats of these counter billing preferences. Originally, we allowed
     * customers to configure priorities for both formats and counters. We realized after consulting
     * with customers that they would rather prioritize formats, and have all the same counters in
     * each format. In order to be backwards compatible, this function will take its best guess at
     * how the formats in their current billing preferences should be prioritized.
     *
     * This function works using a custom scoring mechanism. Every counter preference array is traversed
     * and each format is added to a scoring data structure. The score is determine by taking the entire
     * length of the counter preferences array, and then subtracting the loop index of the preference
     * that the loop is currently on. For example, in a preferences array size of three, the format
     * at index 0 will get a score of 3, the format at index 1 will get a score of 2 and the format
     * at index 2 will get a score of 1.
     *
     * @param {Object} prefs billing preferences to re-order
     * @return {string[]} - An array of formats in order of priority from highest to lowest.
     */
    public static prioritizedFormats(prefs: { [key: string]: IBillingMeterPreference }): string[] {
        const scoredFormats = new Map<string, [string, number]>();
        for (const key of Object.keys(prefs)) {
            const counter = prefs[key];
            for (let i = 0; i < counter.preferences.length; i++) {
                const format = counter.preferences[i].format;
                if (!scoredFormats.has(format)) {
                    scoredFormats.set(format, [format, 0]);
                }
                const c = scoredFormats.get(format);
                scoredFormats.set(format, [format, (c[1] += counter.preferences.length - i)]);
            }
        }
        return [...scoredFormats.values()].sort((a, b) => (a[1] < b[1] ? 1 : -1)).map(a => a[0]);
    }
}
