// The SupplyType enum is used by the DCA service module
//    If you modify this, be sure we do not need to keep the changes in sync with the ItemType.ts
enum SupplyType {
    Toner = 'TONER',
    Transfer = 'TRANSFER',
    Fuser = 'FUSER',
    Drum = 'DRUM',
    MaintKit = 'MAINTKIT',
    Ink = 'INK',
    Micr = 'MICR',
    Misc = 'MISC'
}

namespace SupplyType {
    export function toString(type: SupplyType): string {
        switch (type) {
            case SupplyType.Toner:
                return 'toner';
            case SupplyType.Transfer:
                return 'transfer';
            case SupplyType.Fuser:
                return 'fuser';
            case SupplyType.Drum:
                return 'drum';
            case SupplyType.MaintKit:
                return 'maintkit';
            case SupplyType.Ink:
                return 'ink';
            case SupplyType.Micr:
                return 'MICR';
            default:
                return 'misc';
        }
    }
}

export { SupplyType };
