import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HighlightRuleGroup } from '@libs/iso/core';

@Pipe({
    name: 'logHighlight'
})
export class LogHighlightPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) {}

    public transform(value: unknown, ...args: HighlightRuleGroup[]): unknown {
        if (args.length === 0) {
            return value;
        }
        if (!(args[0] instanceof HighlightRuleGroup)) {
            return value;
        }
        const config = args[0];
        if (typeof value === 'string') {
            return this._sanitizer.bypassSecurityTrustHtml(config.apply(value));
        }
    }
}
