import { UserList } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '@app/services';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class UserListResolver implements Resolve<UserList> {
    constructor(private _userService: UserService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<UserList> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        const parentRoute = this._getEntityRouteSnapshot(route);
        const entityId: string = parentRoute.params['entityId'];
        const initQParams = route.queryParams;
        const params = {
            searchTerm: initQParams.searchTerm,
            sort: initQParams.sortBy,
            sortOrder: initQParams.sortOrder,
            page: coerceNumberProperty(initQParams.page),
            limit: coerceNumberProperty(initQParams.limit),
            includeChildren: coerceBooleanProperty(initQParams.includeChildren),
            showInactive: coerceBooleanProperty(initQParams.showInactive)
        };
        return this._userService.getUserList(
            entityId,
            params.searchTerm,
            params.page,
            params.limit,
            params.sort,
            params.sortOrder,
            params.includeChildren,
            params.showInactive
        );
    }

    private _getEntityRouteSnapshot(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
        const parent = route.parent;
        if (!!parent) {
            if (parent.routeConfig.path === ':entityId') {
                return parent;
            } else {
                return this._getEntityRouteSnapshot(parent);
            }
        } else {
            return route;
        }
    }
}
