import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { InstallService, RtsService, NotificationService } from '@app/services';
import { IModuleVersion, Job, JobDestinationTypes, Task, TaskTypes, Install } from '@libs/iso/core';
import { UserStore, EntityStore, GlobalStore } from '@app/state/store';
import { Store, select } from '@ngrx/store';
import { fromEntity, fromUser } from '@app/state/selectors';

@Component({
    selector: 'ptkr-dca-software-upgrade',
    templateUrl: './dca-software-upgrade.component.html',
    styleUrls: ['./dca-software-upgrade.component.scss']
})
export class DcaSoftwareUpgradeComponent implements OnInit {
    @Input('installSoftwareUpgradesForm')
    public updateInstallSoftwareUpgradesForm: FormGroup;

    @Input('id')
    public id: string;

    public user: UserStore;
    public entity: EntityStore;

    public dcaVersion: Array<IModuleVersion>;
    public ptpmonitorVersion: Array<IModuleVersion>;
    public ptpwatcherVersion: Array<IModuleVersion>;
    public webDgtVersion: Array<IModuleVersion>;

    public disabledUpgradeButton: boolean = true;
    public disabledUpgradeForm: boolean = false;

    constructor(
        private _installService: InstallService,
        private _store: Store<GlobalStore>,
        private _rtsService: RtsService,
        private _notificationService: NotificationService,
        private _cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this._installService
            .getModuleVersions('dca')
            .subscribe(res => (this.dcaVersion = res));
        this._installService
            .getModuleVersions('ptpmonitor')
            .subscribe(res => (this.ptpmonitorVersion = res));
        this._installService
            .getModuleVersions('ptpwatcher')
            .subscribe(res => (this.ptpwatcherVersion = res));
        this._installService
            .getModuleVersions('web-dgt')
            .subscribe(res => (this.webDgtVersion = res));
        this._store
            .pipe(select(fromEntity.currentEntity))
            .subscribe(entity => (this.entity = entity));
        this._store
            .pipe(select(fromUser.currentUser))
            .subscribe(user => (this.user = user));
    }

    public getFieldValue(type: any): any {
        return this.getField(type).value;
    }

    public getField(type: any): AbstractControl {
        return this.updateInstallSoftwareUpgradesForm.controls[type];
    }

    public changeModuleVersion(event: any, type: string): void {
        const version = this[type].find(element => element.url === event.value);
        this.updateInstallSoftwareUpgradesForm.controls[type].setValue(version);

        this.disabledUpgradeButton = false;
    }

    public upgradeSoftware(): void {
        let shouldWeUpgradeDca: string = '';
        const upgradeArray: Array<any> = [];
        const form = this.updateInstallSoftwareUpgradesForm;

        this.disabledUpgradeButton = true;
        this.disabledUpgradeForm = true;
        this._cd.detectChanges();

        for (const m in form.value) {
            const control = form.controls[m].value;
            if (control.value !== null) {
                control.modulePrefix === 'dca'
                    ? (shouldWeUpgradeDca = control.moduleName)
                    : (shouldWeUpgradeDca = shouldWeUpgradeDca);
                upgradeArray.push({
                    filename: control.moduleName,
                    url: control.url,
                    md5: control.md5
                });
            }
        }

        if (upgradeArray.length > 0) {
            const softwareUpgradeTask = new Task({
                type: TaskTypes.UpgradeSoftware,
                payload: {
                    deleteFiles: [],
                    downloadFiles: upgradeArray
                }
            });

            shouldWeUpgradeDca.length > 0
                ? (softwareUpgradeTask.payload.replaceDCAWith = shouldWeUpgradeDca)
                : (softwareUpgradeTask.payload.replaceDCAWith =
                      softwareUpgradeTask.payload.replaceDCAWith);

            const params = {
                entityKey: this.entity._id.toString(),
                installKey: [this.id],
                userKey: this.user._id,
                metadata: {
                    user: {
                        username: this.user.email,
                        firstname: this.user.firstName,
                        lastname: this.user.lastName
                    },
                    entity: {
                        name: this.entity.name
                    }
                },
                jobName: 'Upgrade Software',
                jobDescription:
                    'This is a request for the Data Collection Agent to upgrade its modules software versions',
                destination: JobDestinationTypes.Install,
                tasks: [softwareUpgradeTask]
            };

            this._rtsService
                .createJob(
                    params.entityKey,
                    params.installKey,
                    params.userKey,
                    params.metadata,
                    params.jobName,
                    params.jobDescription,
                    params.destination,
                    params.tasks
                )
                .subscribe(res => {
                    /** This does not imply a successful job, this means the job was issued to the broker */
                    this._notificationService.success(
                        $localize`Sending upgrade job to the Data Collection Agent`
                    );
                });
        }
    }
}
