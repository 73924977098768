<div class="healthcheck-header">
    <span class="h3" i18n>Healthcheck</span>
    <ptkr-btn-preloader *ngIf="refreshing; else refreshbutton"></ptkr-btn-preloader>
    <ng-template #refreshbutton>
        <button *ngIf="install?.resolvedSettings?.rts?.enabled || install.settings?.rts?.enabled" mat-icon-button (click)="onRefresh()">
            <mat-icon id="healthcheck-refresh-icon" style="vertical-align: text-top;">refresh</mat-icon>
        </button>
    </ng-template>
</div>
<div class="kv-list m-t-10">
    <div class="kv-list-item-group">
        <div class="kv-list-item">
            <div class="kv-list-key" i18n>Overall health</div>
            <div class="kv-list-values no-padding-top-bottom">
                <div class="kv-list-value">
                    <mat-icon *ngIf="healthState.isValid()" class="online pull-left">check_circle</mat-icon>
                    <mat-icon *ngIf="healthState.isError()" matTooltip="{{healthState.message}}" class="offline pull-left">error</mat-icon>
                    <mat-icon *ngIf="healthState.isWarning()" matTooltip="{{healthState.message}}" class="warning pull-left">warning</mat-icon>

                    <span *ngIf="healthState.timestamp">
                        {{healthState.timestamp | amTimeAgo}} ({{ healthState.timestamp | date: 'medium' }})
                    </span>
                    <span *ngIf="!healthState.timestamp" i18n>no data</span>
                </div>
            </div>
        </div>
        <div class="kv-list-item">
            <div class="kv-list-key" i18n>Registration</div>
            <div class="kv-list-values no-padding-top-bottom">
                <div class="kv-list-value">
                    <mat-icon *ngIf="registration.isValid()" class="online pull-left">check_circle</mat-icon>
                    <mat-icon *ngIf="registration.isError()" matTooltip="{{registration.message}}" class="offline pull-left">error</mat-icon>
                    <mat-icon *ngIf="registration.isWarning()" matTooltip="{{registration.message}}" class="warning pull-left">warning</mat-icon>

                    <span *ngIf="registration.timestamp">
                        {{registration.timestamp | amTimeAgo}} ({{ registration.timestamp | date: 'medium' }})
                    </span>
                    <span *ngIf="!registration.timestamp" i18n>no data</span>
                </div>
            </div>
        </div>
        <div class="kv-list-item" *ptkrPermission="Permission.DaemonOnly">
            <div class="kv-list-key" i18n>Task engine</div>
            <div class="kv-list-values no-padding-top-bottom">
                <div class="kv-list-value">
                    <mat-icon *ngIf="taskEngine.isValid()" class="online pull-left">check_circle</mat-icon>
                    <mat-icon *ngIf="taskEngine.isError()" matTooltip="{{taskEngine.message}}" class="offline pull-left">error</mat-icon>
                    <mat-icon *ngIf="taskEngine.isWarning()" matTooltip="{{taskEngine.message}}" class="warning pull-left">warning</mat-icon>

                    <span *ngIf="taskEngine.timestamp">
                        {{taskEngine.timestamp | amTimeAgo}} ({{ taskEngine.timestamp | date: 'medium' }})
                    </span>
                    <span *ngIf="!taskEngine.timestamp" i18n>no data</span>
                </div>
            </div>
        </div>
        <div class="kv-list-item">
            <div class="kv-list-key" i18n>Internet connection</div>
            <div class="kv-list-values no-padding-top-bottom">
                <div class="kv-list-value">
                    <mat-icon *ngIf="rpcClient.isValid()" class="online pull-left">check_circle</mat-icon>
                    <mat-icon *ngIf="rpcClient.isError()" matTooltip="{{rpcClient.message}}" class="offline pull-left">error</mat-icon>
                    <mat-icon *ngIf="rpcClient.isWarning()" matTooltip="{{rpcClient.message}}" class="warning pull-left">warning</mat-icon>

                    <span *ngIf="rpcClient.timestamp">
                        {{rpcClient.timestamp | amTimeAgo}} ({{ rpcClient.timestamp | date: 'medium' }})
                    </span>
                    <span *ngIf="!rpcClient.timestamp" i18n>no data</span>
                </div>
            </div>
        </div>
        <div class="kv-list-item">
            <div class="kv-list-key" i18n>Dependencies</div>
            <div class="kv-list-values no-padding-top-bottom">
                <div class="kv-list-value">
                    <mat-icon *ngIf="dependencies.isValid()" class="online pull-left">check_circle</mat-icon>
                    <mat-icon *ngIf="dependencies.isError()" matTooltip="{{dependencies.message}}" class="offline pull-left">error</mat-icon>
                    <mat-icon *ngIf="dependencies.isWarning()" matTooltip="{{dependencies.message}}" class="warning pull-left">warning</mat-icon>

                    <span *ngIf="dependencies.timestamp">
                        {{dependencies.timestamp | amTimeAgo}} ({{ dependencies.timestamp | date: 'medium' }})
                    </span>
                    <span *ngIf="!dependencies.timestamp" i18n>no data</span>
                </div>
            </div>
        </div>
    </div>
</div>
