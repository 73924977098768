<div mat-dialog-header class="header">
    <h3>Apply and Test DGI</h3>
</div>
<div mat-dialog-content class="content">
    <p>Sending this job will update all the data collection modules and test this DGI, creating a meter read. By default, a testing meter read is uploaded where it cannot be seen by the customer or affect the customer's environment and is only viewable using the DGI builder. You can optionally upload a real meter read.
    <br>
    <br>
    <mat-slide-toggle [(ngModel)]="useTempMeterRead">Temporary meter read</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="uploadTestbox">Upload testbox</mat-slide-toggle>
</div>
<div class="action-buttons" style="float: right;" mat-dialog-actions>
    <button mat-button color="accent" (click)="dialogRef.close({result: false})">Cancel</button>
    <button mat-button color="accent" (click)="dialogRef.close({result: true, useTempMeterRead: useTempMeterRead, uploadTestbox: uploadTestbox})">Apply and test</button>
</div>
