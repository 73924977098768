enum Day {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

namespace Day {
    export function toString(type: Day): string {
        switch (type) {
            case Day.Sunday:
                return 'Sunday';
            case Day.Monday:
                return 'Monday';
            case Day.Tuesday:
                return 'Tuesday';
            case Day.Wednesday:
                return 'Wednesday';
            case Day.Thursday:
                return 'Thursday';
            case Day.Friday:
                return 'Friday';
            case Day.Saturday:
                return 'Saturday';
            default:
                return '';
        }
    }

    export function asArray(): Day[] {
        return [
            Day.Sunday,
            Day.Monday,
            Day.Tuesday,
            Day.Wednesday,
            Day.Thursday,
            Day.Friday,
            Day.Saturday
        ];
    }
}

export { Day };
