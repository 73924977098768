<div mat-dialog-header class="header">
    <h3>{{ data.config.title }}</h3>
</div>
<div mat-dialog-content>
    <p>{{ data.config?.content }}</p>
    <p *ngIf="data.config.secondaryContent">{{ data.config?.secondaryContent }}</p>
    <ptkr-field-group [label]="data.placeholder">
        <mat-form-field floatLabel="never" appearance="outline">
            <textarea matInput [formControl]="inputControl" (keyup.enter)="confirmData(inputControl)"></textarea>
            <mat-error [ptkrErrorControl]="inputControl"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="default" (click)="dialogRef.close(undefined)">{{ data.config?.buttons?.cancel ?? 'Cancel'}}</button>
    <button mat-flat-button color="primary" (click)="confirmData(inputControl)">{{ data.config?.buttons?.confirm ?? 'Confirm'}}</button>
</div>
