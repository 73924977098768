import { AbsoluteUserResolver } from './absolute-user.resolver';
import { UserByIdResolver } from './user-by-id.resolver';
import { EntityByIdResolver } from './entity-by-id.resolver';
import { UserListResolver } from './user-list.resolver';
import { DeviceListResolver } from './device-list.resolver';
import { DeviceNewListResolver } from './device-new-list.resolver';
import { ItemListResolver } from './item-list.resolver';
import { VisibleItemListResolver } from './visible-item-list.resolver';
import { ItemByIdResolver } from './item-by-id.resolver';
import { ItemSummaryListResolver } from './item-summary-list.resolver';
import { CompatibleItemsResolver } from './compatible-items.resolver';
import { DeviceAlertsResolver } from './device-alerts.resolver';
import { AdminCommissionsResolver } from './admin-commissions.resolver';
import { UserCommissionsListResolver } from './user-commissions-list.resolver';
import { InvoiceListResolver } from './invoice-list.resolver';
import { EntityItemsPaginatedResolver } from './entity-items-paginated.resolver';
import { DeviceByIdResolver } from './device-by-id.resolver';
import { InstallsListResolver } from './installs-list.resolver';
import { InstallByIdResolver } from '@app/routes/resolvers/install-by-id.resolver';
import { SuperTableResolver } from './super-table.resolver';
import { MeterTypeNameMapResolver } from './meter-type-name-map.resolver';
import { JobBatchListResolver } from '@app/routes/resolvers/jobbatch-list-resolver';
import { ReportListResolver } from './report-list.resolver';
import { SuppliesListResolver } from './supplies-list.resolver';

export const resolvers = [
    AbsoluteUserResolver,
    UserByIdResolver,
    UserListResolver,
    DeviceListResolver,
    DeviceNewListResolver,
    DeviceAlertsResolver,
    EntityByIdResolver,
    ItemListResolver,
    ItemByIdResolver,
    ItemSummaryListResolver,
    CompatibleItemsResolver,
    AdminCommissionsResolver,
    UserCommissionsListResolver,
    InvoiceListResolver,
    EntityItemsPaginatedResolver,
    DeviceByIdResolver,
    VisibleItemListResolver,
    InstallsListResolver,
    InstallByIdResolver,
    SuperTableResolver,
    MeterTypeNameMapResolver,
    JobBatchListResolver,
    ReportListResolver,
    SuppliesListResolver
];

export {
    AbsoluteUserResolver,
    UserByIdResolver,
    UserListResolver,
    DeviceListResolver,
    DeviceNewListResolver,
    DeviceAlertsResolver,
    EntityByIdResolver,
    ItemListResolver,
    ItemByIdResolver,
    ItemSummaryListResolver,
    CompatibleItemsResolver,
    AdminCommissionsResolver,
    UserCommissionsListResolver,
    InvoiceListResolver,
    EntityItemsPaginatedResolver,
    DeviceByIdResolver,
    VisibleItemListResolver,
    InstallsListResolver,
    InstallByIdResolver,
    SuperTableResolver,
    MeterTypeNameMapResolver,
    JobBatchListResolver,
    ReportListResolver,
    SuppliesListResolver
};
