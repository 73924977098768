enum QualityLevel {
    OEM = 'oem',
    Premium = 'premium',
    Value = 'value'
}

namespace QualityLevel {
    export function toString(type: QualityLevel): string {
        switch (type) {
            case QualityLevel.OEM:
                return 'OEM';
            case QualityLevel.Premium:
                return 'Premium';
            case QualityLevel.Value:
                return 'Value';
            default:
                return '';
        }
    }

    export const asArray: QualityLevel[] = [
        QualityLevel.OEM,
        QualityLevel.Premium,
        QualityLevel.Value
    ];
}

export { QualityLevel };
