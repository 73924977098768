export enum SortDirection {
    Ascending = 1,
    Descending = -1
}

export namespace SortDirection {
    export function toString(direction: SortDirection): 'asc' | 'desc' {
        switch (direction) {
            case SortDirection.Ascending:
                return 'asc';
            case SortDirection.Descending:
                return 'desc';
            default:
                return 'asc';
        }
    }
}

export interface ISortConfiguration {
    order: SortDirection;
    by: string;
}

const defaultSortConfiguration: ISortConfiguration = {
    order: SortDirection.Ascending,
    by: ''
};

export class SortConfiguration implements ISortConfiguration {
    public by: string;
    public order: SortDirection;
    constructor(props?: Partial<ISortConfiguration>) {
        props = { ...defaultSortConfiguration, ...props };
        this.by = props.by;
        this.order = props.order;
    }

    public setSortBy(str: string): void {
        this.by = str;
    }

    public setSortDirection(direction: SortDirection): void {
        this.order = direction;
    }
}

export function toSortDirection(input: any): SortDirection {
    switch (input) {
        case 'asc':
            return SortDirection.Ascending;
        case 1:
            return SortDirection.Ascending;
        case 'desc':
            return SortDirection.Descending;
        case -1:
            return SortDirection.Descending;
        default:
            return SortDirection.Ascending;
    }
}
