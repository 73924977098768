<mat-expansion-panel>
    <mat-expansion-panel-header class="right-aligned-header">
        <mat-panel-title>
            <div class="status-container">
                <div class="label" [ngClass]="labelClass(job.jobStatus.type)">
                    {{ job.jobStatus.type | titlecase }}</div>
            </div>
            <span *ngIf="job.origin === 'webadmin'">{{ (job?.jobName?.length > 25)? (job?.jobName | slice:0:25)+'..':(job?.jobName) }}</span>
            <span *ngIf="job.origin === 'dca'">(Automatic scheduled job)</span>
        </mat-panel-title>
        <mat-panel-description>
            {{ job?.createdDate | date:'medium' }}
        </mat-panel-description>
    </mat-expansion-panel-header>

    <h3>Job</h3>
    <div class="details bordered">
        <div class="info-section">
            <h6>Job name</h6>
            <div>{{ job.jobName }}</div>
        </div>
        <div class="info-section">
            <h6>Job description</h6>
            <div>{{ job.jobDescription }}</div>
        </div>
        <div class="info-section" *ngIf="job.origin === 'webadmin'">
            <div class="spacer">
                <div class="clearfix">
                    <h6 style="float: left">Tasks</h6>
                    <a style="float: right" matTooltip="Sometimes, jobs need to execute multiple steps. Each one of these steps is called a task.">What's
                        a task?</a>
                </div>
            </div>
            <div>
                <div *ngFor="let task of job.tasks" class="task">
                    <div class="label default">{{ task?.type }}</div>
                    <div class="task-indent" *ngIf="task?.payload">
                        <div class="task-step" *ngFor="let key of objectKeys(task?.payload)"><span class="h6">{{ key }}:
                            </span><span class="task-value">{{task?.payload[key] || "not specified"}}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h3>Progress</h3>
    <div class="details bordered">
        <div style="padding: 10px">
            <span>
                <span class="h6">Status: </span>
                <span *ngIf="!job?.jobStatus?.payload?.message">No updates yet...</span>
                <span *ngIf="job?.jobStatus?.payload?.message">{{job?.jobStatus?.payload?.message}}</span>
                <!--            <span *ngIf="job?.jobStatus?.payload?.error" matTooltip="This is an error. Contact support for help">{{ job.jobStatus.payload.error }}</span>-->
            </span>
        </div>
        <!-- <mat-progress-bar mode="buffer"></mat-progress-bar> -->
    </div>

    <h3 *ptkrPermission="Permission.DaemonOnly">Logs</h3>
    <div *ptkrPermission="Permission.MeterFlags" class="details bordered console">
        <div style="padding: 10px">
            <span>
                <span class="log-entry" *ngFor="let log of job?.history" >
                    <span *ngIf="log != null">
                        <b>{{log?.timestamp | date:'mediumTime'}}</b>
                        {{ log?.message }}
                        <br>
                    </span>
                </span>
            </span>
        </div>
         <mat-progress-bar *ngIf="job.jobStatus.type === 'running'" mode="buffer"></mat-progress-bar>
    </div>

    <h3>Details</h3>
    <div class="details bordered">
        <div class="info-section" *ngIf="job.origin === 'webadmin'">
            <h6>Created by</h6>
            <div>{{ job.metadata?.user?.firstname }} {{ job.metadata?.user?.lastname }}
                <{{ job.metadata?.user?.username }}>
            </div>
        </div>
        <div class="info-section" *ngIf="job.origin === 'webadmin'">
            <h6>Customer name</h6>
            <div>{{ job.metadata?.entity?.name }}</div>
        </div>
        <div class="info-section">
            <h6>Date created</h6>
            <div>{{ job.createdDate | date:'medium' }}</div>
        </div>
        <div class="info-section">
            <h6>Last updated</h6>
            <div>{{ job.modifiedDate | date:'medium' || "No updates..."}}</div>
        </div>
        <div class="info-section">
            <h6>Created by</h6>
            <div *ngIf="job.origin === 'dca'">Data Collection Agent (DCA)</div>
            <div *ngIf="job.origin === 'webadmin'">Web Admin Portal</div>
        </div>
        <div class="info-section">
            <h6>Target DCA</h6>
            <div>{{ job.installKey }}</div>
        </div>
    </div>
    <div matTooltip="Only jobs that have sent and not been received, or jobs that have completed with a status of failed, stopped, or completed, can be deleted." matTooltipDisabled="!canDeleteJob(job.status.type)">
        <button mat-button [disabled]="canDeleteJob(job.jobStatus.type)" color="warn" (click)="deleteJob(job._id)">Dismiss job</button>
    </div>
    <!-- <span matTooltip="Coming soon!">
        <button mat-stroked-button disabled>Cancel Job</button>
    </span> -->
</mat-expansion-panel>
