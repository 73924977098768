import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromUser } from '@app/state/selectors';
import { RouterService, NotificationService } from '@app/services';
import { take } from 'rxjs/operators';
import { Permission } from '@libs/iso/core';

@Injectable()
export class PermissionGuard implements CanActivate {
    constructor(
        private _store: Store<GlobalStore>,
        private _router: Router,
        private _routerService: RouterService,
        private _notificationService: NotificationService
    ) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const reqPerm = next.data['permission'] || null;
        const requireAll: boolean = next.data['requireAll'] || false;
        const permissions: Permission[] = Array.isArray(reqPerm) ? reqPerm : [reqPerm];
        let _permissions: Permission[] = [];
        this._store
            .pipe(
                select(fromUser.permissions),
                take(1)
            )
            .subscribe(perms => (_permissions = perms));

        if (!permissions || permissions.length === 0) {
            return true;
        }
        if (Permission.hasPermission(permissions, _permissions, requireAll)) {
            return true;
        } else {
            this._notificationService.error('No Access');
            this._router.navigate([this._routerService.getHomePageRedirect()]);
            return false;
        }
    }
}
