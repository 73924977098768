export enum JobBatchState {
    Pending = 0,
    Active = 1,
    Retry = 3,
    Stalled = 32,
    Cancelled = 128,
    Failed = 160,
    Complete = 192,
    Unnecessary = 208
}

export namespace JobBatchStatus {
    export function toString(type: JobBatchState): string {
        switch (type) {
            case JobBatchState.Pending:
                return 'Pending';
            case JobBatchState.Active:
                return 'Active';
            case JobBatchState.Retry:
                return 'Retry';
            case JobBatchState.Stalled:
                return 'Stalled';
            case JobBatchState.Cancelled:
                return 'Cancelled';
            case JobBatchState.Failed:
                return 'Failed';
            case JobBatchState.Complete:
                return 'Complete';
            case JobBatchState.Unnecessary:
                return 'Unnecessary';
            default:
                return '';
        }
    }

    export function toColor(type: JobBatchState): string {
        switch (type) {
            case JobBatchState.Pending:
                return '#B4B4B4';
            case JobBatchState.Active:
                return '#FFC031';
            case JobBatchState.Retry:
                return '#FFC031';
            case JobBatchState.Stalled:
                return '#FFB7B7';
            case JobBatchState.Cancelled:
                return '#CBCBCB';
            case JobBatchState.Failed:
                return '#FF7474';
            case JobBatchState.Complete:
                return '#6EDD9D';
            case JobBatchState.Unnecessary:
                return '#6EDD9D';
            default:
                return '';
        }
    }

    export const asArray: JobBatchState[] = [
        JobBatchState.Pending,
        JobBatchState.Active,
        JobBatchState.Retry,
        JobBatchState.Stalled,
        JobBatchState.Cancelled,
        JobBatchState.Failed,
        JobBatchState.Complete,
        JobBatchState.Unnecessary
    ];
}
