export enum Permission {
    RoleManage = 'roleManage',
    RoleCreate = 'roleCreate',
    RoleEdit = 'roleEdit',
    RoleDelete = 'roleDelete',
    RoleAssign = 'roleAssign',
    RoleList = 'roleList',
    UserManage = 'userManage',
    UserCreate = 'userCreate',
    UserEdit = 'userEdit',
    UserDetails = 'userDetails',
    UserDelete = 'userDelete',
    UserProfile = 'userProfile',
    UserImpersonate = 'userImpersonate',
    UserList = 'userList',
    DeviceListIncludeChildren = 'deviceListIncludeChildren',
    DeviceBulkActions = 'deviceBulkAction',
    DeviceAdd = 'deviceAdd',
    DeviceManage = 'deviceManage',
    DeviceList = 'deviceList',
    NewDeviceList = 'newDeviceList',
    EntityAdminAlerts = 'entityAdminAlerts', // Lets you get alerts from any entity
    EntityManage = 'entityManage',
    ItemList = 'itemList',
    ItemSearch = 'itemSearch',
    DeviceAlertStatus = 'deviceAlertStatus',
    DeviceAlertBulkActions = 'deviceAlertBulkActions',
    UpdateDeviceSettings = 'deviceUpdateSettings',
    UpdateDeviceTrackingStatus = 'deviceUpdateTrackingStatus',
    AdvancedSettings = 'advancedSettings',
    DownloadPrintTracker = 'downloadPrintTracker',
    SendPTInstallationEmail = 'sendPTInstallationEmail',
    CreateInvoices = 'invoiceCreate',
    CreateEntity = 'entityCreate',
    UpdateAccountStatus = 'entityStatus',
    ViewPricingTab = 'entityPricing',
    TermsToggle = 'termsToggle',
    SalesTaxToggle = 'salesTaxToggle',
    PayShippingToggle = 'payShippingToggle',
    EntitySettings = 'entitySettings',
    DealerSettings = 'dealerSettings',
    EntityHierarchy = 'entityHierarchy',
    CommissionsSummary = 'commissionsSummary',
    ViewCommissions = 'commissionsView',
    ViewInvoices = 'invoiceView',
    ManageEntityItems = 'entityItemsManage',
    OrderToner = 'orderToner',
    ManageInstallations = 'installManage',
    MoveInstallations = 'installMove',
    UpgradeToReleaseCandidate = 'upgradeToReleaseCandidate',
    UpgradeToOfficialRelease = 'upgradeToOfficialRelease',
    ActivateTroubleMode = 'activateTroubleMode',
    UpdateDGI = 'updateDGI',
    UpdateTEC = 'updateTEC',
    PullMeters = 'pullMeters',
    UploadMeters = 'uploadMeters',
    UpdateDealerID = 'updateDealerID',
    Uninstall = 'uninstall',
    DeviceDelete = 'deviceDelete',
    NoAccess = 'noAccess',
    MeterFlags = 'meterFlags',
    ManualUpgradeInstallation = 'manualUpgradeInstallation',
    DaemonOnly = 'daemonOnly',
    ViewJobs = 'viewJobs',
    DeleteJobs = 'deleteJobs',
    CreateJobs = 'createJobs',
    ViewJobLogs = 'viewJobLogs',
    BulkDeviceImport = 'bulkDeviceImport',
    ViewMeterHistory = 'viewMeterHistory',
    ViewVolumeAnalysis = 'viewVolumeAnalysis',
    ListJobBatches = 'listJobBatches',
    ViewJobBatch = 'viewJobBatch',
    UseDeviceProxy = 'useDeviceProxy',
    ConfigureDeviceProxy = 'configureDeviceProxy',
    ViewAlerts = 'viewAlerts',
    ViewDeviceReports = 'viewDeviceReports',
    ManageReports = 'manageReports',
    ViewReports = 'viewReports',
    ManageQueryEngines = 'manageQueryEngines',
    ToggleDealer = 'ToggleDealer',
    ManageBilling = 'manageBilling'
}

export namespace Permission {
    export function hasPermission(
        permissionsToCheck: Permission | Permission[],
        currentPermissions: Permission | Permission[],
        requireAll: boolean = false
    ): boolean {
        if (!Array.isArray(permissionsToCheck)) {
            permissionsToCheck = [permissionsToCheck];
        }
        if (!Array.isArray(currentPermissions)) {
            currentPermissions = [currentPermissions];
        }
        let valid: boolean = true;
        if (requireAll) {
            for (const p of permissionsToCheck) {
                valid = hasPermission && currentPermissions.indexOf(p) > -1;
            }
        } else {
            valid = false;
            let pI = 0;
            do {
                valid = currentPermissions.indexOf(permissionsToCheck[pI]) > -1;
            } while (valid === false && permissionsToCheck[++pI]);
        }
        return valid;
    }
}
