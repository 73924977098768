<mat-chip-list>
    <ng-container *ngFor="let key of keys; let i = index">
        <mat-chip *ngIf="isVisible(i)"
                  [disableRipple]="true">
            <ng-container *ngIf="isTooLong(map[key])">
                <a class="kd-clickable"
                   (click)="openChipDialog(key, map[key])">{{key}}</a>
            </ng-container>

            <!-- beautify ignore:start -->
            <ng-container *ngIf="!isTooLong(map[key])">
                <b>{{key}}</b><ng-container *ngIf="map[key]">:&nbsp;</ng-container>
                <ng-container *ngIf="map[key]">
                    <ng-container *ngIf="isHref(map[key])">
                        <a [routerLink]="map[key]"
                           queryParamsHandling="preserve"
                           target="_blank">
                            {{map[key]}}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="!isHref(map[key])">{{map[key]}}</ng-container>
                </ng-container>
            </ng-container>
            <!-- beautify ignore:end -->

        </mat-chip>
    </ng-container>
    <div *ngIf="isAnythingHidden()"
         (click)="toggleView($event)"
         class="kd-chips-show-button">
        <ng-container *ngIf="isShowingAll"
                      i18n>
            <a>Show less</a>
        </ng-container>
        <ng-container *ngIf="!isShowingAll"
                      i18n>
            <a>Show all</a>
        </ng-container>
    </div>
    <ng-container *ngIf="keys.length === 0">-</ng-container>
</mat-chip-list>
