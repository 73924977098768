<ptkr-action-bar
    [config]="jobsActionBar"
    (refresh)="reload.emit()"
    (filterChanged)="tc.onFilterChanged($event)"
></ptkr-action-bar>
<table
    class="g-table"
    mat-table
    matSort
    (matSortChange)="tc.onSortChanged($event)"
    [dataSource]="dataSource"
    multiTemplateDataRows
>
    <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header="jobName" *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let job" class="hover">
            {{ job.jobName }}
        </td>
    </ng-container>
    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let job">
            <span *ngIf="job.metadata">{{job?.metadata?.user?.firstname}} {{job?.metadata?.user?.lastname}} <{{job?.metadata?.user?.username}}></span>
            <span *ngIf="!job.metadata">-</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="modifiedDate">
        <th class="align-right" mat-sort-header="modifiedDate" mat-header-cell *matHeaderCellDef>Modified date</th>
        <td class="align-right" mat-cell *matCellDef="let job">
            <span>{{ job.modifiedDate | date: 'short' }}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th class="align-right" mat-header-cell *matHeaderCellDef>Status</th>
        <td class="align-right" mat-cell *matCellDef="let job">
            <div class="label padding-mod" [ngClass]="job.jobStatus.type">{{ job.jobStatus.type | uppercase }}</div>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th class="align-right" mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="align-right" mat-cell *matCellDef="let job">
            <a (click)="viewLogs(job._id)">Logs</a>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let version; columns: displayedColumns;"></tr>
</table>
<div class="margins left-right m-t-10">
    <ptkr-pagination-panel
        [dataLength]="dataSource?.data?.length || 0"
        [startingPageSize]="tc.pagination.currentState.limit"
        [pageSizeOptions]="tc.pagination.options.pageSizes"
        [currentPage]="tc.pagination.currentState.pageIndex || 1"
        (currentPageChange)="tc.onPageChanged($event)"
        (pageSizeChange)="tc.onPageSizeChanged($event)"
    ></ptkr-pagination-panel>
</div>
