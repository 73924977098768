import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'ptkr-meter-read-value-card',
    templateUrl: './meter-read-value-card.component.html',
    styleUrls: ['./meter-read-value-card.component.scss']
})
export class MeterReadValueCardComponent implements OnInit {
    @Input()
    public format: string = '';

    @Input()
    public name: string = '';

    @Input()
    public value: string = '';

    constructor() {}

    ngOnInit(): void {}
}
