export interface ITimeSeriesValue {
    timestamp: Date;
    value?: any;
}

export class TimeSeriesValue implements ITimeSeriesValue {
    public timestamp: Date = new Date(0);
    public value?: any;
    constructor(params?: ITimeSeriesValue) {
        this.timestamp = params.timestamp;
        this.value = params.value;
    }
}
