import { Injectable } from '@angular/core';
import { HttpService, Get, ResponseType } from '@libs/web/http';
import { Observable } from 'rxjs';

@Injectable()
export class MeterTypeService extends HttpService {

    // Spencer 9/25/20 -
    // This resource will tell the browser it can request this resource asyncrhonously about every
    // 30 seconds to keep it up to date. The browser will cache the resource. So this way, any time
    // we need the meterNameMap, we can assume we have not gotten it yet and it should be fine,
    // like any other resource.
    @Get('/meterType/meterTypeNameMap')
    public getMeterTypeNameMap(): Observable<any> {
        return null;
    }

    @Get('/meterType/meterTypeNameListCSV', ResponseType.Blob)
    public getMeterTypeNameListCSV(): Observable<any> {
        return null;
    }
}
