export interface MeterTypeNameMapModel {
    map: {
        [key: string]: {
            [key: string]: { name: string; aka: Array<string> };
        };
    };
}

export class MeterTypeNameMap implements MeterTypeNameMapModel {
    public map: {
        [key: string]: {
            [key: string]: { name: string; aka: Array<string> };
        };
    } = {};

    constructor(params?: Partial<MeterTypeNameMapModel>) {
        if (!!params) {
            this.map = params.map ? params.map : this.map;
        }
    }

    private _getMeter(format: string, meter: string): { name: string; aka: Array<string> } {
        if (!!this.map[format]) {
            if (!!this.map[format][meter]) {
                return this.map[format][meter];
            }
        }
        return { name: `'${meter}'`, aka: [] };
    }

    public getName(format: string, meter: string): string {
        return this._getMeter(format, meter).name;
    }

    public getAka(format: string, meter: string): Array<string> {
        return this._getMeter(format, meter).aka;
    }
}
