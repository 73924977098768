import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { StepComponent } from '@app/shared/overlays/StepComponent';
import { ItemWithEntityPricing, ItemType } from '@libs/iso/core';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'ptkr-entity-items-step',
    templateUrl: './entity-items-step.component.html',
    styleUrls: ['./entity-items-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityItemsStepComponent implements OnInit, StepComponent {
    private _items: ItemWithEntityPricing[] = [];
    @Input()
    public set items(value: ItemWithEntityPricing[]) {
        this._items = value.map(v => {
            v['selectionItems'] = [
                {
                    itemId: v._id,
                    fieldToChange: 'customCost',
                    newValue: v.entityPrice
                },
                {
                    itemId: v._id,
                    fieldToChange: 'hide',
                    newValue: v.hide
                }
            ];
            return v;
        });
    }
    public get items(): ItemWithEntityPricing[] {
        return this._items;
    }

    public selection: SelectionModel<any> = new SelectionModel<any>(true);
    public ItemType: typeof ItemType = ItemType;

    constructor() {}

    ngOnInit(): void {}

    public validate(): boolean {
        console.log(this.selection.selected);
        if (this.selection.selected.length < 1) {
            return null;
        } else {
            return true;
        }
    }

    public getValue(): any {
        return this.selection.selected;
    }
}
