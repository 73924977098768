import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultHighlightRuleGroup, HighlightRuleGroup } from '@libs/iso/core';

@Component({
    selector: 'ptkr-job-log-viewer',
    templateUrl: './job-log-viewer.component.html',
    styleUrls: ['./job-log-viewer.component.scss']
})
export class JobLogViewerComponent implements OnInit {
    @Input() public logs$: Observable<Array<string>>;
    public highlightConfig: HighlightRuleGroup = DefaultHighlightRuleGroup;

    constructor() {}

    ngOnInit(): void {}
}
