import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ActionBarConfiguration } from '@app/shared/components/action-bar/action-bar.component';
import { fromEntity, fromUser } from '@app/state/selectors';
import { GlobalStore } from '@app/state/store';
import {
    ExportDelimiter,
    Permission,
    ReportSharingMode,
    QueryEngineOrderedResult,
    QueryEngineReportType,
    QueryEngineResult,
    QueryEngineResultPerformance,
    QueryEngineSchemaItem,
    ReportWithMetadata
} from '@libs/iso/core';
import { QueryEngineRequestWithHeaders } from '@libs/iso/core/models/queryEngine/QueryEngineRequest';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from '../../../core/services/notification.service';
import { QueryEngineService } from '../../../core/services/queryEngine.service';
import { ReportService } from '../../../core/services/report.service';

enum RunQueryAgainst {
    CurrentEntity,
    ReportEntity
}

// ReportConfigurationUsageType describes the use-case for this component.
export enum ReportConfigurationUsageMode {
    // CustomReports is selected when this component is used in the reports list to customize a custom
    // report and preview changes.
    CustomReports,
    // DeviceMeterHistory is selected when this component is used from the device page to view the
    // meter history for a specific device. When this mode it being used, the report type, name and
    // description properties are hidden and this component acts instead as an ad-hoc query-editor.
    DeviceMeterHistory
}

@Component({
    selector: 'ptkr-query-engine-interface',
    templateUrl: './query-engine-interface.component.html',
    styleUrls: ['./query-engine-interface.component.scss']
})
export class QueryEngineInterfaceComponent implements OnInit, OnDestroy {
    @ViewChild('schemaTablePaginator') public schemaTablePaginator: MatPaginator;

    /**
     * Sets the report that should be configured.
     * @param {ReportWithMetadata} report
     */
    @Input() public set report(report: ReportWithMetadata) {
        if (report == null) {
            return;
        }
        this._report = report;

        // todo: this is a total (but simple) hack to preserve changes to the runQueryAgainst property
        //  even when the user switches between tabs which destroys the state stored in this component.
        //  See todo item below.
        this.runQueryAgainst = (<any>this._report).runQueryAgainst ?? this.runQueryAgainst;
        this.initializeForm(
            report.name,
            report.description,
            report.type,
            report.includeChildren,
            report.query,
            report.delimiter,
            report.sharingMode,
            report.showAllForSerialNumber
        );
        this.runQuery();
    }
    private _report: ReportWithMetadata;

    /**
     * Determines the way that this component is being used by callers
     */
    @Input() public mode: ReportConfigurationUsageMode = ReportConfigurationUsageMode.CustomReports;
    public ReportConfigurationUsageMode: typeof ReportConfigurationUsageMode = ReportConfigurationUsageMode;

    /**
     * If the report configuration mode is ReportConfigurationUsageMode.DeviceMeterHistory then a device
     * id will be provided. This device ID is used to return specific device-related data.
     */
    @Input() public deviceId: string;

    /**
     * Goes with device ID
     * @type {string}
     */
    @Input() public serialNum: string;

    /**
     * Fired when the report is updated and when the updates should be reflected in the report list
     */
    @Output() public reportUpdated: EventEmitter<ReportWithMetadata> = new EventEmitter();

    /**
     * Fired when the page should be reloaded such as when a report is deleted
     */
    @Output() public reloadPage: EventEmitter<void> = new EventEmitter();

    /**
     * Holds any query errors that come back from the query-engine API
     */
    public queryError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    /**
     * The table datasource for the query results
     */
    public queryResults: MatTableDataSource<any>;

    /**
     * A dynamically configured array of columns based on the query results
     */
    public queryColumns: Array<{ name: string; key: string }> = [];

    /**
     * The results of a schema query request, this is used by whoever is writing the query to understand
     * what properties they have to work with.
     */
    public schemaResults: MatTableDataSource<QueryEngineSchemaItem>;

    /**
     * The columns that should be shown in the table schema viewer
     */
    public schemaColumns: Array<{ name: string; key: keyof QueryEngineSchemaItem, copiable?: boolean }> = [];

    /**
     * Everytime a query is run, performance statistics are sent to this BehaviorSubject.
     */
    public queryPerformance$: BehaviorSubject<QueryEngineResultPerformance> = new BehaviorSubject(
        null
    );

    /**
     * The form that allows users to configure, update, and test reports.
     */
    public reportOptions: FormGroup;

    /**
     * A reference to the report type enum
     */
    public QueryEngineReportType: typeof QueryEngineReportType = QueryEngineReportType;

    /**
     * A reference to the report sharing mode enum
     */
    public ReportSharingMode: typeof ReportSharingMode = ReportSharingMode;

    /**
     * A reference to the Permission enum
     */
    public Permission: typeof Permission = Permission;

    /**
     * Whether there is a query in progress
     */
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Configuration for the action bar contained in the table schema section of the UI
     */
    public schemaActionBar: ActionBarConfiguration = {
        showFilter: true,
        filterSuffix: 'column names'
    };

    /**
     * Determines whether the query engine is shut down to prevent someone from shutting down the
     * query engine twice in a row.
     */
    public isShutdown: boolean = true;

    public RunQueryAgainst: typeof RunQueryAgainst = RunQueryAgainst;
    public runQueryAgainst: RunQueryAgainst = RunQueryAgainst.CurrentEntity;

    private _ngUnsub: Subject<void> = new Subject<void>();

    constructor(
        private _fb: FormBuilder,
        private _cd: ChangeDetectorRef,
        private _store: Store<GlobalStore>,
        private _queryEngineService: QueryEngineService,
        private _notificationService: NotificationService,
        private _reportService: ReportService
    ) {
        this.initializeForm();
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._ngUnsub.next();
        this._ngUnsub.complete();
        this._ngUnsub = undefined;
    }

    private initializeSchemaColumns(includeTableColumn: boolean): void {
        this.schemaColumns = [];
        if (includeTableColumn) {
            this.schemaColumns.push({ name: 'Table', key: 'table' });
        }
        this.schemaColumns.push({ name: 'Column', key: 'column', copiable: true });
        this.schemaColumns.push({ name: 'Type', key: 'type' });
    }

    /**
     * Initializes the form with default values if they're not provided, or with real values from a
     * report.
     * @param {string} name - The name of the report
     * @param {string} description - The description of the report
     * @param {QueryEngineReportType} reportType - The report type
     * @param {boolean} includeChildren - Whether the input documents to the query-engine should include children
     * @param {string} query - The query to run against the query-engine
     * @param delimiter
     * @param sharingMode
     * @param {boolean} showAllForSerialNumber - Show All meters for serial number
     * @private
     * @returns {void}
     */
    private initializeForm(
        name: string = null,
        description: string = null,
        reportType: QueryEngineReportType = QueryEngineReportType.BillingPeriod,
        includeChildren: boolean = false,
        query: string = QueryEngineReportType.toDefaultQuery(QueryEngineReportType.BillingPeriod),
        delimiter: string = ExportDelimiter.Comma,
        sharingMode: ReportSharingMode = ReportSharingMode.defaultMode,
        showAllForSerialNumber: boolean = false
    ): void {
        this.reportOptions = this._fb.group({
            name: new FormControl(name, [Validators.minLength(3)]),
            description: new FormControl(description),
            reportType: new FormControl(reportType),
            startDate: new FormControl(moment()),
            endDate: new FormControl(moment()),
            billingDate: new FormControl(moment()),
            includeChildren: new FormControl(includeChildren),
            delimiter: new FormControl(delimiter),
            query: new FormControl(query),
            sharingMode: new FormControl(sharingMode),
            showAllForSerialNumber: new FormControl(showAllForSerialNumber),
            // Hardcoded sample size of 10 documents for testing queries
            sampleSize: new FormControl(18, [Validators.min(18), Validators.max(18)])
        });
        this.setStartDate(reportType);

        // When the toggle is changed, then re-run the query
        this.reportOptions.get('showAllForSerialNumber').valueChanges.subscribe(value => {
            this.reportTypeChanged({
                value: value
                    ? QueryEngineReportType.SerialNumberMeterHistory
                    : QueryEngineReportType.DeviceMeterHistory
            } as MatSelectChange);
            this.runQuery();
        });
    }

    /**
     * When the report type changes, we need to set the query to be the default query for the report
     * type and then execute that default query.
     * @param {MatSelectChange} event - The mat selection change event
     * @returns {void}
     */
    public reportTypeChanged(event: MatSelectChange): void {
        this.reportOptions.get('query').setValue(QueryEngineReportType.toDefaultQuery(event.value));
        this.setStartDate(event.value);
    }

    /**
     * Sets the start date of the date picked based on the report type. For estimated depletion reports
     * the start date should be 95 days in the past (we analyze 95 days of meter history to estimate a
     * cartridges' depletion), for all other report types, we do 30 days in the past, or it's not relevant
     * to the report.
     * @param {QueryEngineReportType} reportType - The currently selected report type.
     * @returns {void}
     */
    public setStartDate(reportType: QueryEngineReportType): void {
        switch (reportType) {
            case QueryEngineReportType.EstimatedDepletionOnDemand:
                this.reportOptions.get('startDate').setValue(moment().subtract(95, 'days'));
                break;
            case QueryEngineReportType.BillableDevices:
                this.reportOptions.get('startDate').setValue(moment().startOf('month'));
                this.reportOptions.get('endDate').setValue(moment().endOf('month'));
                break;
            default:
                this.reportOptions.get('startDate').setValue(moment().subtract(30, 'days'));
        }
    }

    /**
     * Builds a query-engine request based on the form values
     * @returns {Partial<QueryEngineRequestWithHeaders>} - A query-engine request
     */
    public buildRequestFromForm(): Omit<QueryEngineRequestWithHeaders, 'entityKey' | 'userKey'> {
        const tweakedStartDate: Date = this.reportOptions.get('startDate').value.toDate();
        tweakedStartDate.setHours(0, 0, 0, 0);
        const tweakedEndDate: Date = this.reportOptions.get('endDate').value.toDate();
        tweakedEndDate.setHours(23, 59, 59, 999);

        // If we're looking at device meter history AND the serial number toggle is on,
        // set type to serialNumber meter history. Otherwise, just use the type that is
        // selected.
        const reportType =
            this.mode === ReportConfigurationUsageMode.DeviceMeterHistory &&
            this.reportOptions.get('showAllForSerialNumber').value
                ? QueryEngineReportType.SerialNumberMeterHistory
                : this.reportOptions.get('reportType').value;

        // If the report type does not need to include children, then we always set it to
        // true just in case it joins a table which does require include children.
        const includeChildren = QueryEngineReportType.requiresIncludeChildren(reportType)
            ? this.reportOptions.get('includeChildren').value
            : true;

        return {
            ordered: true,
            reportType,
            includeChildren,
            query: this.reportOptions.get('query').value,
            // If we're looking at device meter history, don't sample the data, return the whole dataset
            sampleSize:
                this.mode === ReportConfigurationUsageMode.CustomReports
                    ? this.reportOptions.get('sampleSize').value
                    : 0,
            payload: {
                startDate: tweakedStartDate,
                endDate: tweakedEndDate,
                billingDate: this.reportOptions.get('billingDate').value,
                deviceId: this.deviceId,
                serialNum: this.serialNum
            }
        };
    }

    /**
     * Runs the query using the current state of the form and optionally re-runs the schema query as
     * well to update the schema table in the UI.
     * @param {boolean} reloadSchema - Whether the schema table should be re-queried and updated
     * @returns {void}
     */
    public runQuery(): void {
        this.loading$.next(true);
        this.queryError$.next(null);
        const request = this.buildRequestFromForm();
        this.processQueryRequest(<QueryEngineRequestWithHeaders>request)
            .pipe(
                catchError(err => {
                    console.log(err);
                    this.queryError$.next(err.error?.message?.error ?? err.error?.message ?? err.error);
                    this.queryPerformance$.next(null);
                    this._cd.detectChanges();
                    return of(null);
                }),
                tap(() => this.loading$.next(false)),
            )
            .subscribe(res => {
                this.isShutdown = false;
                if (res != null) {
                    if (res.recordset != null) {
                        this.handleQueryResult(
                            new QueryEngineOrderedResult<any>(<QueryEngineOrderedResult<any>>res)
                        );
                        this.handleSchemaResult(res);
                        const countTables = new Set(res.schema.map(s => s.table)).size;
                        this.initializeSchemaColumns(countTables > 1);
                    } else {
                        this.queryError$.next('Query returned an empty recordset');
                    }
                    this._cd.detectChanges();
                }
            });
    }

    /**
     * Depending on the runQueryAgainst configuration, this function will return an observable containing
     * the query results for either the current entity or the entity that the report belongs to.
     * @param {QueryEngineRequestWithHeaders} request - The query engine request
     * @returns {Observable<any>} - The query response
     */
    public processQueryRequest(request: QueryEngineRequestWithHeaders): Observable<any> {
        return this.runQueryAgainst === RunQueryAgainst.CurrentEntity
            ? this._queryEngineService.queryCurrent(request)
            : this.runQueryAgainst === RunQueryAgainst.ReportEntity
            ? this._store.pipe(
                  select(fromUser.userId),
                  take(1),
                  switchMap(userId =>
                      this._queryEngineService.query({
                          ...request,
                          userKey: userId,
                          entityKey: <string>this._report.entityKey
                      })
                  )
              )
            : null;
    }

    /**
     * Depending on the runQueryAgainst configuration, this function will return an observable containing
     * the download results for either the current entity or the entity that the report belongs to.
     * @param {QueryEngineRequestWithHeaders} request - The query engine request
     * @returns {Observable<any>} - The schema response
     */
    public processDownloadRequest(
        request: Omit<QueryEngineRequestWithHeaders, 'entityKey' | 'userKey'> & {
            delimiter: ExportDelimiter;
        }
    ): Observable<any> {
        return this.runQueryAgainst === RunQueryAgainst.CurrentEntity
            ? this._queryEngineService.downloadCurrent(request)
            : this.runQueryAgainst === RunQueryAgainst.ReportEntity
            ? this._store.pipe(
                  select(fromUser.userId),
                  take(1),
                  switchMap(userId =>
                      this._queryEngineService.download({
                          ...request,
                          userKey: userId,
                          entityKey: <string>this._report.entityKey
                      })
                  )
              )
            : null;
    }

    /**
     * Requests that the query engine that would be used for the query request as determined by the
     * current state of the form be shut down.
     * @param {string} message - The message to toDisplayName in a success notification on completion.
     * @returns {void}
     */
    public shutdown(message: string): void {
        const request = this.buildRequestFromForm();
        this._queryEngineService.shutdownCurrent(request).subscribe(res => {
            if (res == null) {
                this.isShutdown = true;
                this._notificationService.success(message);
                this.loading$.next(false);
                this._cd.detectChanges();
                this.runQuery();
            }
        });
    }

    /**
     * Saves the report
     * @returns {void}
     */
    public save(): void {
        const merged = this.mergeReportWithForm();
        this._reportService.updateReport(merged).subscribe(res => {
            if (res == null) {
                this._notificationService.success('Report updated successfully!');
                this.reportUpdated.emit(merged);
            }
        });
    }

    /**
     * Merges the report object with the updated values based on the current state of the form to
     * produce the 'new' and 'changed' report object.
     * @returns {ReportWithMetadata} - The updated report object
     */
    public mergeReportWithForm(): ReportWithMetadata {
        return new ReportWithMetadata({
            ...this._report,
            name: this.reportOptions.get('name').value,
            description: this.reportOptions.get('description').value,
            query: this.reportOptions.get('query').value,
            includeChildren: this.reportOptions.get('includeChildren').value,
            type: this.reportOptions.get('reportType').value,
            delimiter: this.reportOptions.get('delimiter').value,
            sharingMode: this.reportOptions.get('sharingMode').value,
        });
    }

    /**
     * Updates the query result table and its columns, updates the query performance statistics
     * and runs change detection/
     * @param {QueryEngineOrderedResult<any>} result - The query result from the query-engine
     * @returns {void}
     */
    public handleQueryResult(result: QueryEngineOrderedResult<any>): void {
        this.queryColumns = result.orderedFields().map(f => ({ name: f, key: f }));
        this.queryResults = new MatTableDataSource<any>(result.toObjects());
        this.queryPerformance$.next(result.performance);
        this._cd.detectChanges();
    }

    /**
     * Updates the schema result table and runs change detection
     * @param {QueryEngineResult<QueryEngineTableSchema>} result - The schema results
     * @returns {void}
     */
    public handleSchemaResult(result: QueryEngineResult<any>): void {
        this.schemaResults = new MatTableDataSource(result.schema);
        // Once the table paginator is initialized, connect it to the data source.
        if (this.schemaTablePaginator != null) {
            this.schemaResults.paginator = this.schemaTablePaginator;
        }
        this._cd.detectChanges();
    }

    /**
     * Downloads the full dataset based on the current report configuration as a CSV file.
     * @returns {void}
     */
    public download(): void {
        // Don't enforce a sample size when downloading the report
        this.processDownloadRequest({
            ...this.buildRequestFromForm(),
            delimiter: this.reportOptions.get('delimiter').value,
            sampleSize: 0,
        }).subscribe();
    }

    /**
     * Returns the currently selected report type
     * @returns {QueryEngineReportType} - The currently selected report type
     */
    public getReportType(): QueryEngineReportType {
        return <QueryEngineReportType>this.reportOptions.get('reportType').value;
    }

    /**
     * Used by the UI to determine whether to toDisplayName a date range picker.
     * @returns {boolean} - Returns whether the currently selected report type uses a date range
     */
    public reportTypeUsesDateRange(): boolean {
        return QueryEngineReportType.requiresDateRange(this.getReportType());
    }

    /**
     * Used by the UI to determine whether to toDisplayName a date range picker.
     * @returns {boolean} - Returns whether the currently selected report type uses a date range
     */
    public reportTypeUsesMonthlyDateRange(): boolean {
        return QueryEngineReportType.requiresMonthlyDateRange(this.getReportType());
    }

    /**
     * Returns true if this report uses the includeChildren filter
     */
    public reportTypeUsesIncludeChildren(): boolean {
        return QueryEngineReportType.requiresIncludeChildren(this.getReportType());
    }

    /**
     * Used by the UI to determine whether to toDisplayName a single date picker
     * @returns {boolean} - Returns whether the currently selected report type uses a single date
     */
    public reportTypeUsesBillingPeriodDate(): boolean {
        return QueryEngineReportType.requiresBillingDate(
            <QueryEngineReportType>this.reportOptions.get('reportType').value
        );
    }

    /**
     * Filters the schema table based on the provided string
     * @param {any} event - The filter string
     * @returns {void}
     */
    public filterSchemaTable(event: any): void {
        this.schemaResults.filter = event;
    }

    /**
     * Only the author of a report is allowed to edit the report. This function determines if the edit
     * buttons should be shown in the UI.
     * @returns {Observable<boolean>} - True if the user is allowed to edit this report
     */
    public userCanEdit(): Observable<boolean> {
        return this._store.pipe(
            select(fromUser.userId),
            takeUntil(this._ngUnsub),
            switchMap(id => of(id === this._report.userKey))
        );
    }

    public goToLink(url: string): void {
        window.open(url, '_blank');
    }

    /**
     * Returns the entity name that is attached to the report.
     * @returns {string} - Entity name of the report
     */
    public reportEntityName(): string {
        return this._report.entityName;
    }

    /**
     * Returns the currently selected entity name.
     * @returns {string} - Entity name of the current entity
     */
    public currentEntityName(): Observable<string> {
        return this._store.pipe(
            select(fromEntity.currentEntity),
            takeUntil(this._ngUnsub),
            map(e => e.name)
        );
    }

    /**
     * Sets the entity that the query should be executed against.
     * @param {RunQueryAgainst} v - The destination to run the query against
     * @returns {void}
     */
    public setRunQueryAgainst(v: RunQueryAgainst): void {
        this.runQueryAgainst = v;

        // todo: this is a total (but simple) hack to preserve changes to the runQueryAgainst property
        //  even when the user switches between tabs which destroys the state stored in this component.
        //  See todo item below.
        (<any>this._report).runQueryAgainst = v;
    }

    /**
     * Register keyboard combinations when the editor is rendered.
     * @param {any} editor - The editor event
     * @returns {void}
     */
    public onEditorInit(editor: any): void {
        editor.addAction({
            id: 'run-query',
            label: 'Run Query',
            // tslint:disable-next-line:no-bitwise
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
            run: (): void => this.runQuery()
        });
        editor.layout();
    }

    public startMonthSelected(event: Moment, dp, input): void {
        dp.close();
        const start = event.startOf('month');
        this.reportOptions.controls.startDate.setValue(start);
    }

    public endMonthSelected(event: Moment, dp, input): void {
        dp.close();
        const end = event.endOf('month');
        this.reportOptions.controls.endDate.setValue(end);
    }
}
