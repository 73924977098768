<ng-container *ngIf='!!formGroup; else noFormGroup'>
    <form [formGroup]='formGroup'>
        <mat-accordion>
            <mat-expansion-panel class='general-info-panel'>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        General information
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Asset ID and Notes
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ptkr-field-group label='Asset ID' hint='Add a proprietary name or serialized identifier.' i18n-label i18n-hint>
                    <mat-form-field appearance='outline' floatLabel='never'>
                        <input matInput type='text' [formControlName]='UpdateKeyMap.Device.AssetId' />
                        <mat-error></mat-error>
                    </mat-form-field>
                </ptkr-field-group>

                <ptkr-field-group label='Custom Serial Number'
                                  hint='If set, will overwrite the serial number detected by the DCA. For local devices, this is how you set the serial number.'
                                  i18n-label i18n-hint>
                    <mat-form-field appearance='outline' floatLabel='never'>
                        <input matInput type='text' [formControlName]='UpdateKeyMap.Device.CustomSerialNum' />
                        <mat-error></mat-error>
                    </mat-form-field>
                </ptkr-field-group>

                <ptkr-field-group label='Custom Location' hint='Devices have their own location fields that can
                        be configured at the printer level, if you would like to override the device location, use this
                        field.' i18n-label i18n-hint>
                    <mat-form-field appearance='outline' floatLabel='never'>
                        <input
                            matInput
                            type='text'
                            [formControlName]='UpdateKeyMap.Device.CustomLocation'
                            placeholder='i.e. first floor break-room'
                            i18n-placeholder
                        />
                        <mat-error></mat-error>
                    </mat-form-field>
                </ptkr-field-group>

                <ptkr-field-group label='Notes' hint='General notes field.' i18n-label i18n-hint>
                    <mat-form-field appearance='outline' class=''>
                                <textarea
                                    matInput
                                    placeholder='Device notes'
                                    i18n-placeholder
                                    [formControlName]='UpdateKeyMap.Device.Note'
                                ></textarea>
                    </mat-form-field>
                </ptkr-field-group>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Skip Alerts
                    </mat-panel-title>
                    <mat-panel-description i18n>
                        Ignore certain types of alerts temporarily
                    </mat-panel-description>
                </mat-expansion-panel-header>
                    <ptkr-skip-alert-control [formControlName]='UpdateKeyMap.Device.SkipAlerts'
                                             [supplies]='formGroup.supplies'>
                    </ptkr-skip-alert-control>
            </mat-expansion-panel>

        </mat-accordion>
    </form>
</ng-container>
<ng-template #noFormGroup>
    <div>
        <h2 style='color: gray;' i18n>Loading...</h2>
    </div>
</ng-template>
