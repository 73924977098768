import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromCart } from '@app/state/selectors';
import { RouterService, NotificationService } from '@app/services';

@Injectable()
export class CheckoutGuard implements CanActivate {
    private _checkoutActive: boolean = false;

    constructor(
        private _store: Store<GlobalStore>,
        private _routerService: RouterService,
        private _notificationService: NotificationService
    ) {
        this._store.pipe(select(fromCart.checkoutActive)).subscribe(active => {
            this._checkoutActive = active;
        });
    }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this._checkoutActive) {
            this._notificationService.error('No active checkout in progress');
            this._routerService.navigate('/order/carts');
            return false;
        }
        return true;
    }
}
