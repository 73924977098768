import { Component, Input, OnInit } from '@angular/core';
import {
    CategoricalDistributionElement, IReportingDeviceFacet,
    IReportingDevices,
    ReportingDevices
} from '@libs/iso/core';
import { InstallService } from '@app/services';

enum ReportingState {
    Reported = 'Reporting',
    NotReported = 'Not Reporting'
}

@Component({
    selector: 'ptkr-install-reporting-devices',
    templateUrl: './install-reporting-devices.component.html',
    styleUrls: ['./install-reporting-devices.component.scss']
})
export class InstallReportingDevicesComponent implements OnInit {
    @Input() public set reportingDevices(data: IReportingDeviceFacet[]) {
        const expectedOrder = [0, 3, 'Older'];

        this.data = data.sort((a, b) => {
            return expectedOrder.indexOf(a._id) - expectedOrder.indexOf(b._id);
        }).map(v => {
            this.total += v.count;
            switch (v._id) {
                case 0:
                    return {
                        category: $localize`Reported within 72 hours`,
                        value: v.count,
                        class: 'bg-green-400'
                    };
                case 3:
                    return {
                        category: $localize`Reported within 1 month`,
                        value: v.count,
                        class: 'bg-orange-300'
                    };
                default:
                    return {
                        category: $localize`Stale or not reported`,
                        value: v.count,
                        class: 'bg-red-400'
                    };
            }
        });
    }

    @Input() public showValues: boolean = true;

    public data: CategoricalDistributionElement[];
    public total: number = 0;

    constructor(private _installsService: InstallService) {}

    ngOnInit(): void {}
}
