import { BaseModel, IBaseModel } from '@libs/iso/core/models/BaseModel';
import { ObjectId } from 'bson';
import { QueryEngineReportType } from '@libs/iso/core/enums/QueryEngineReportType';
import { QueryEngineRequestWithHeaders } from '@libs/iso/core/models/queryEngine/QueryEngineRequest';
import { ExportDelimiter } from '@libs/iso/core/enums/ExportDelimiter';
import { ReportSharingMode } from '@libs/iso/core/enums/ReportSharingMode';

export interface IReport extends IBaseModel {
    userKey: string | ObjectId;
    entityKey: string | ObjectId;
    name: string;
    description: string;
    type: QueryEngineReportType;
    query: string;
    includeChildren: boolean;
    showAllForSerialNumber: boolean;
    delimiter: ExportDelimiter;
    sharingMode: ReportSharingMode;
}

export class Report extends BaseModel implements IReport {
    public userKey: string | ObjectId;
    public entityKey: string | ObjectId;
    public name: string = 'My New Report';
    public description: string = '...';
    public type: QueryEngineReportType = QueryEngineReportType.Device;
    public query: string = QueryEngineReportType.toDefaultQuery(QueryEngineReportType.Device);
    public includeChildren: boolean = false;
    public showAllForSerialNumber: boolean = false;
    public delimiter: ExportDelimiter = ExportDelimiter.Comma;
    public sharingMode: ReportSharingMode = ReportSharingMode.defaultMode;

    constructor(params?: Partial<IReport>) {
        super(params);
        if (params != null) {
            this.userKey = params.userKey ?? this.userKey;
            this.entityKey = params.entityKey ?? this.entityKey;
            this.name = params.name ?? this.name;
            this.description = params.description ?? this.name;
            this.type = params.type ?? this.type;
            this.query = params.query ?? this.query;
            this.includeChildren = params.includeChildren ?? this.includeChildren;
            this.showAllForSerialNumber =
                params.showAllForSerialNumber ?? this.showAllForSerialNumber;
            this.delimiter = ExportDelimiter.fromString(params.delimiter) ?? this.delimiter;
            this.sharingMode = ReportSharingMode.fromString(params.sharingMode) ?? this.sharingMode;
        }
    }

    /**
     * Returns the description property truncated to the provided maximum string length
     * @param {number} max - The maximum length of the description
     * @returns {string} - The truncated string
     */
    public truncatedDescription(max: number): string {
        if (this.description == null || this.description.length <= max) {
            return this.description;
        }
        return `${this.description.slice(0, max)}...`;
    }

    /**
     * Returns whether the description is truncated
     * @param {number} max - The max length of the string
     * @returns {boolean} - Whether the description is truncated
     */
    public isDescriptionTruncated(max: number): boolean {
        return !(this.description == null || this.description.length <= max);
    }

    /**
     * Determines if this report requires any custom payload.
     * @returns {boolean} - true if this report type doesn't require a payload;
     */
    public requiresNoPayload(): boolean {
        return QueryEngineReportType.requiresNoPayload(this.type);
    }

    /**
     * Determines if this report has date range stipulations.
     * @returns {boolean} - true if this report type requires a payload with a start and end date
     */
    public requiresDateRange(): boolean {
        return QueryEngineReportType.requiresDateRange(this.type);
    }

    /**
     * Determines if this report requires a billing date.
     * @returns {boolean} - true if this report type requires a payload with a billing date
     */
    public requiresBillingDate(): boolean {
        return QueryEngineReportType.requiresBillingDate(this.type);
    }

    /**
     * Converts this Report to a QueryEngineRequestWithHeaders
     * @param {string} entityKey - The entity key where the report should be executed
     * @param {string} userKey - The user key that is executing this report
     * @returns {QueryEngineRequestWithHeaders} A query engine request that can produce this report
     */
    public toQueryEngineRequest(
        entityKey: string,
        userKey: string
    ): Omit<QueryEngineRequestWithHeaders, 'sampleSize'> & {delimiter: ExportDelimiter} {
        return {
            delimiter: this.delimiter,
            entityKey: entityKey,
            userKey: userKey,
            reportType: this.type,
            includeChildren: this.includeChildren,
            ordered: true,
            query: this.query
        };
    }

    /**
     * Returns a QueryEngineRequestWithHeaders without an entity key or user key
     * @returns {Partial<QueryEngineRequestWithHeaders>} - a QueryEngineRequestWithHeaders without
     * an entity key or user key.
     */
    public toUnscopedQueryEngineRequest(): Omit<
        QueryEngineRequestWithHeaders,
        'entityKey' | 'userKey' | 'sampleSize'
    > & { delimiter: ExportDelimiter } {
        return {
            delimiter: this.delimiter,
            reportType: this.type,
            includeChildren: this.includeChildren,
            ordered: true,
            query: this.query
        };
    }

    public toDB(): {} {
        Object.keys(this).forEach(key => {
            if (key.indexOf('_') === 0) {
                delete this[key];
            }
        });
        return this;
    }
}

export class ReportWithMetadata extends Report {
    public entityName: string;
    public userFullName: string;
    constructor(params: Partial<ReportWithMetadata>) {
        super(params);
        this.entityName = params.entityName;
        this.userFullName = params.userFullName;
    }
}

export interface IDeviceHistoryReport extends IReport {
    deviceKey: string | ObjectId;
}

export const DeviceHistoryReport = new ReportWithMetadata({
    type: QueryEngineReportType.DeviceMeterHistory,
    query: QueryEngineReportType.toDefaultQuery(QueryEngineReportType.DeviceMeterHistory)
});
