import { CartItem } from '@libs/iso/core';

export interface CartItemChangeModel {
    item: CartItem;
    qty: number;
}

export class CartItemChange implements CartItemChangeModel {
    constructor(public item: CartItem, public qty: number) {}
}
