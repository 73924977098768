<div class="report">
    <form [formGroup]="reportOptions">
        <div class="query-container">
            <div class="sidebar">
                <div class="form">
                    <mat-form-field appearance="outline" floatLabel="always" *ngIf="this.mode === ReportConfigurationUsageMode.CustomReports">
                        <mat-label i18n="@@name">Name</mat-label>
                        <input matInput formControlName="name" placeholder="Ex. My volume analysis" i18n-placeholder>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" *ngIf="this.mode === ReportConfigurationUsageMode.CustomReports">
                        <mat-label i18n="@@description">Description</mat-label>
                        <input matInput formControlName="description" placeholder="Ex. Compares volumes for all devices" i18n-placeholder>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" *ngIf="this.mode === ReportConfigurationUsageMode.CustomReports">
                        <mat-label i18n>Report type</mat-label>
                        <mat-select [formControlName]="'reportType'" (selectionChange)="reportTypeChanged($event)">
                            <mat-option *ngFor="let option of QueryEngineReportType.selectableReportTypes" [value]="option">
                                {{QueryEngineReportType.toDisplayName(option)}}
                                <div *ngIf="QueryEngineReportType.isBeta(option)" class="label rescheduled tab-label">BETA</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" *ngIf="this.mode === ReportConfigurationUsageMode.CustomReports">
                        <mat-label i18n>Sharing mode</mat-label>
                        <mat-select [formControlName]="'sharingMode'">
                            <mat-option *ngFor="let option of ReportSharingMode.allValues" [value]="option">
                                {{ReportSharingMode.toContextDisplayName(option, this.reportEntityName())}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label i18n>Delimiter</mat-label>
                        <ptkr-export-separator-select formControlName="delimiter"></ptkr-export-separator-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" *ngIf="reportTypeUsesDateRange()">
                        <mat-label i18n>Date range</mat-label>
                        <mat-date-range-input [formGroup]="reportOptions" [rangePicker]="picker" ptkrDateRangeInput>
                            <input matStartDate formControlName="startDate" placeholder="Start date" i18n-placeholder="@@startDate">
                            <input matEndDate formControlName="endDate" placeholder="End date" i18n-placeholder="@@endDate">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <mat-error *ngIf="reportOptions.controls.startDate.hasError('matStartDateInvalid')" i18n>Invalid start date</mat-error>
                        <mat-error *ngIf="reportOptions.controls.endDate.hasError('matEndDateInvalid')" i18n>Invalid end date</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" floatLabel="always" *ngIf="reportTypeUsesMonthlyDateRange()">
                        <mat-label i18n="@@startDate">Start date</mat-label>
                        <input #startMonthPickerInput matInput [matDatepicker]="startMonthPicker" formControlName="startDate">
                        <mat-datepicker-toggle matSuffix [for]="startMonthPicker"></mat-datepicker-toggle>
                        <mat-datepicker #startMonthPicker startView="year" (monthSelected)="startMonthSelected($event, startMonthPicker, startMonthPickerInput)"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" *ngIf="reportTypeUsesMonthlyDateRange()">
                        <mat-label i18n="@@endDate">End date</mat-label>
                        <input matInput [matDatepicker]="endMonthPicker" formControlName="endDate">
                        <mat-datepicker-toggle matSuffix [for]="endMonthPicker"></mat-datepicker-toggle>
                        <mat-datepicker #endMonthPicker startView="year"  (monthSelected)="endMonthSelected($event, endMonthPicker, endMonthPicker)"></mat-datepicker>
                    </mat-form-field>

                    <!--This is an exact copy of the date range picker above. This picker allows us to customize the
                    input data range of the dataset used to calculate the estimated depletion date.-->
                    <ng-container *ptkrPermission="Permission.DaemonOnly">
                        <mat-form-field appearance="outline" floatLabel="always" *ngIf="getReportType() == QueryEngineReportType.EstimatedDepletionOnDemand">
                            <div style="margin-bottom: 10px;">
                                <ptkr-banner type="warning">
                                    <span body i18n>Only viewable by Print Tracker</span>
                                </ptkr-banner>
                            </div>
                            <mat-label i18n>Date range</mat-label>
                            <mat-date-range-input [formGroup]="reportOptions" [rangePicker]="picker" ptkrDateRangeInput>
                                <input matStartDate formControlName="startDate" placeholder="Start date" i18n-placeholder="@@startDate">
                                <input matEndDate formControlName="endDate" placeholder="End date" i18n-placeholder="@@endDate">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                            <mat-error *ngIf="reportOptions.controls.startDate.hasError('matStartDateInvalid')" i18n>Invalid start date</mat-error>
                            <mat-error *ngIf="reportOptions.controls.endDate.hasError('matEndDateInvalid')" i18n>Invalid end date</mat-error>
                        </mat-form-field>
                    </ng-container>
                    <mat-form-field appearance="outline" floatLabel="always" *ngIf="reportTypeUsesBillingPeriodDate() && this.mode === ReportConfigurationUsageMode.CustomReports">
                        <mat-label i18n>Billing date</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="billingDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" appearance="none"  *ngIf="this.mode === ReportConfigurationUsageMode.CustomReports && reportTypeUsesIncludeChildren()">
                        <mat-label i18n>Include children</mat-label>
                        <mat-slide-toggle [formControlName]="'includeChildren'"></mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" appearance="none"  *ngIf="this.mode === ReportConfigurationUsageMode.DeviceMeterHistory && getReportType() !== QueryEngineReportType.EstimatedDepletionOnDemand">
                        <mat-label i18n>Show all meters for serial number</mat-label>
                        <!-- when the value of the form control changed (which is triggered on a click) we also want to run the query again. -->
                        <mat-slide-toggle [formControlName]="'showAllForSerialNumber'"></mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                </div>

                <div class="chart-panel">
                    <div class="header no-bottom-border" i18n>Table Schema</div>
                    <div class="body no-padding schema-table">
                        <ptkr-action-bar
                            [config]="schemaActionBar"
                            (filterChanged)="filterSchemaTable($event)"
                        ></ptkr-action-bar>
                        <ptkr-data-list
                            *ngIf="schemaResults != null"
                            [dataSource]="schemaResults.connect() | async"
                            [rowSelectable]="false"
                            [columns]="schemaColumns"
                        ></ptkr-data-list>
                        <mat-paginator #schemaTablePaginator [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
                    </div>
                </div>
            </div>
            <div class="query-body width-fix">
                <div class="editor">
                    <mat-progress-spinner mode="indeterminate" diameter="20" *ngIf="loading$ | async"></mat-progress-spinner>
                    <ngx-monaco-editor
                        class="monaco-editor"
                        [options]="{ language: 'sql' }"
                        formControlName="query"
                        (onInit)="onEditorInit($event)"
                    ></ngx-monaco-editor>
                    <div class="query-actions" *ptkrPermission="Permission.ManageQueryEngines">
                        <button mat-raised-button
                                (click)="runQuery()"
                                [disabled]="loading$ | async"
                                matTooltip="Ctrl + Enter"
                                i18n
                        >Run</button>
                        <button mat-raised-button (click)="download()" [disabled]="loading$ | async" i18n="@@export">Export</button>
                        <button mat-raised-button [matMenuTriggerFor]="queryEngineMenu">
                            <mat-icon>settings</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="error">
                    <ptkr-banner *ngIf="(queryError$ | async) != null" [type]="'error'" style="flex: 1"><span body>{{queryError$ | async}}</span></ptkr-banner>
                </div>
                <div>
                    <div class="tab-content table-container">
                        <ptkr-banner *ngIf="this.mode === ReportConfigurationUsageMode.CustomReports">
                            <span body i18n>These results are sampled from the full dataset. To see the full dataset, download this report.</span>
                        </ptkr-banner>
                        <ptkr-data-list
                            *ngIf="queryResults != null"
                            [dataSource]="queryResults.data"
                            [rowSelectable]="false"
                            [columns]="queryColumns"
                            missingValue=""
                        ></ptkr-data-list>
                        <div class="performance">
                            <span *ngIf="queryPerformance$ | async" i18n>Returned
                                {{(queryPerformance$ | async).rowsReturned}} rows totaling
                                {{(queryPerformance$ | async).recordsetBytesStr}} in
                                {{(queryPerformance$ | async).totalDurationStr}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="report-actions" *ptkrPermission="Permission.ManageReports">
        <div class="buttons" *ngIf="this.mode === ReportConfigurationUsageMode.CustomReports">
            <button mat-flat-button color="accent" (click)="save()" [disabled]="!(userCanEdit() | async)">
                <span *ngIf="userCanEdit() | async" i18n="@@save">Save</span>
                <span *ngIf="!(userCanEdit() | async)" matTooltip="Only the creator of this report can edit it" i18n-matTooltip i18n="@@save">Save</span>
            </button>
        </div>
    </div>
</div>

<!--This should only be shown if the user is allowed to manage a query engine, we'll protect the button that opens this
instead of this itself-->
<mat-menu #queryEngineMenu="matMenu">
    <button
        mat-menu-item
        (click)="shutdown('Query engine restarted successfully!')"
        matTooltip="You may need to restart the query engine if the data gets corrupted or if a table gets dropped"
        i18n-matTooltip
    >
        <mat-icon>restart_alt</mat-icon>
        <span i18n>Restart query engine</span>
    </button>
    <button
        mat-menu-item
        (click)="shutdown('Re-sampled dataset successfully!')"
        matTooltip="Re-samples the data that the query window runs against"
        i18n-matTooltip
        *ngIf="this.mode === ReportConfigurationUsageMode.CustomReports"
    >
        <mat-icon>colorize</mat-icon>
        <span i18n>Re-sample dataset</span>
    </button>
    <!--    <div *ptkrPermission="Permission.DaemonOnly">-->
    <ng-container *ngIf="this.mode === ReportConfigurationUsageMode.CustomReports">
        <ptkr-named-mat-divider i18n>Run Query At</ptkr-named-mat-divider>
        <button mat-menu-item (click)="setRunQueryAgainst(RunQueryAgainst.ReportEntity); $event.stopImmediatePropagation()">
        <span>
            <mat-icon class="selected-icon" [ngClass]="runQueryAgainst != RunQueryAgainst.ReportEntity ? 'invisible-icon' : ''">done</mat-icon>
            <ng-container i18n>Report Entity ({{reportEntityName()}})</ng-container>
        </span>
        </button>
        <button mat-menu-item (click)="setRunQueryAgainst(RunQueryAgainst.CurrentEntity); $event.stopImmediatePropagation()">
        <span>
            <mat-icon class="selected-icon" [ngClass]="runQueryAgainst != RunQueryAgainst.CurrentEntity ? 'invisible-icon' : ''">done</mat-icon>
            <ng-container i18n>Current Entity ({{currentEntityName() | async}})</ng-container>
        </span>
        </button>
    </ng-container>
    <!--    </div>-->
    <ptkr-named-mat-divider i18n>Help</ptkr-named-mat-divider>
    <button mat-menu-item (click)="goToLink('https://www.sqlite.org/lang.html')" i18n>SQL Syntax Documentation</button>
</mat-menu>
