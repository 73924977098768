import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Color } from '@libs/iso/core';

@Component({
    selector: 'ptkr-part-color',
    templateUrl: './part-color.component.html',
    styleUrls: ['./part-color.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartColorComponent implements OnInit {
    @Input()
    public color: Color;

    public Color: typeof Color = Color;

    constructor() {}

    ngOnInit(): void {}
}
