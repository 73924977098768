import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalConfig } from '@app/models';

@Component({
    selector: 'ptkr-input-modal-with-content',
    templateUrl: './input-modal-with-content.component.html',
    styleUrls: ['./input-modal-with-content.component.scss']
})
export class InputModalWithContentComponent implements OnInit {
    public inputControl: FormControl;

    constructor(
        public dialogRef: MatDialogRef<InputModalWithContentComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { type: 'text' | 'email'; placeholder: string; config: ConfirmModalConfig }
    ) {}

    ngOnInit(): void {
        if (this.data.type === 'text') {
            this.inputControl = new FormControl('', Validators.required);
        } else if (this.data.type === 'email') {
            this.inputControl = new FormControl('', [Validators.required, CustomValidators.email]);
        } else {
            this.inputControl = new FormControl('');
        }
    }

    public confirmData(control: FormControl): void {
        if (!control.valid) {
            control.markAsTouched();
            return;
        } else {
            this.dialogRef.close(control.value);
        }
    }

    public something(): void {
        console.log('something');
    }
}
