import { Injectable } from '@angular/core';
import {
    HttpService,
    Get,
    Post,
    Put,
    Query,
    Body,
    ResponseType,
    Path,
    MapClass,
    Adapter
} from '@libs/web/http';
import { ItemList } from '@app/models';
import { Observable } from 'rxjs';
import {
    Item,
    ItemSearchAllResults,
    PaginatedList,
    ItemWithEntityPricing,
    ItemSummary
} from '@libs/iso/core/models';
import { ObjectID } from 'bson';
import { ItemType } from '@libs/iso/core';

@Injectable()
export class ItemService extends HttpService {
    @Get('/item/{entityKey}')
    public getItemList(
        @Path('entityKey') entityKey: string | ObjectID,
        @Query('searchTerm') query?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeHiddenItems') includeHiddenItems?: boolean
    ): Observable<ItemList> {
        return null;
    }

    @Get('/item/{id}')
    public getPaginatedEntityItemList(
        @Path('id') entityKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeHiddenItems') includeHiddenItems?: boolean
    ): Observable<PaginatedList<ItemWithEntityPricing>> {
        return null;
    }

    @Post('/item', ResponseType.Text)
    public createItem(@Body() params: Partial<Item>): Observable<string> {
        return null;
    }

    @Put('/item/{id}')
    public updateItem(
        @Path('id') id: string | ObjectID,
        @Body() params: Partial<Item>
    ): Observable<boolean> {
        return null;
    }

    @Put('/item/{id}/compatibility', ResponseType.Text)
    public updateItemCompatibility(
        @Path('id') id: string | ObjectID,
        @Body() params: Partial<Item>
    ): Observable<string> {
        return null;
    }

    // @Get('/item/{id}')
    // public getDetails(@Path('id') id: string): Observable<Item> {
    //     return null;
    // }

    @Get('/item')
    public getDetails(
        @Query('id') id: string,
        @Query('entityKey') entityKey: string
    ): Observable<Item> {
        return null;
    }

    @Get('/item/baseItem/{itemKey}')
    @MapClass(Item)
    public getBaseItemDetails(@Path('itemKey') itemKey: string): Observable<Item> {
        return null;
    }

    @Get('/item/{entityKey}/{id}/compatible')
    public getCompatibleItems(
        @Path('entityKey') entityKey: ObjectID | string,
        @Path('id') id: ObjectID | string,
        @Query('limit') limit?: number
    ): Observable<Item[]> {
        return null;
    }

    @Get('/item/{entityKey}/{id}/compatible-order')
    public getCompatibleOrderItems(
        @Path('entityKey') entityKey: ObjectID | string,
        @Path('id') id: ObjectID | string,
        @Query('limit') limit?: number
    ): Observable<Item[]> {
        return null;
    }

    @Get('/item/{entityKey}/{id}/incompatible')
    public getIncompatibleItems(
        @Path('entityKey') entityKey: ObjectID | string,
        @Path('id') id: ObjectID | string
    ): Observable<Item[]> {
        return null;
    }

    @Get('/item/recommended')
    public getRecommendedItems(
        @Query('id') id: string | ObjectID,
        @Query('type') type?: string
    ): Observable<Item[]> {
        return null;
    }

    @Get('/item/{entityKey}/searchAll')
    @MapClass(ItemSearchAllResults)
    public searchAll(
        @Path('entityKey') entityKey: string | ObjectID,
        @Query('searchTerm') searchTerm: string
    ): Observable<ItemSearchAllResults> {
        return null;
    }

    @Get('/item/baseItems/search')
    @MapClass(Item)
    public searchBaseItems(
        @Query('type') type?: ItemType,
        @Query('searchTerm') query?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<Item[]> {
        return null;
    }

    @Put('/item/bulk')
    public bulkEntityItemUpdates(
        @Body('where') entityIds: string[],
        @Body('updates') settingsToApply: any
    ): Observable<any> {
        return null;
    }

    @Post('/item/{itemKey}/markCompatible')
    public markItemCompatible(
        @Path('itemKey') itemKey: string,
        @Body('compatibleItemKey') compatibleItemKey: string
    ): Observable<any> {
        return null;
    }

    @Post('/item/{itemKey}/markIncompatible')
    public markItemIncompatible(
        @Path('itemKey') itemKey: string,
        @Body('compatibleItemKey') compatibleItemKey: string
    ): Observable<any> {
        return null;
    }

    @Get('/item/{itemKey}/compatibleItems')
    @MapClass(Item)
    public getItemCompatibleItems(@Path('itemKey') itemKey: string): Observable<Item[]> {
        return null;
    }

    @Get('/item/search')
    // tslint:disable-next-line:ter-prefer-arrow-callback
    @Adapter(function(res): PaginatedList<Item> {
        const list = new PaginatedList<Item>();
        list.totalDocs = res.totalDocs;
        if (Array.isArray(res.docs)) {
            for (let d of res.docs) {
                list.docs.push(new Item(d));
            }
        }
        return list;
    })
    public itemSearch(
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<PaginatedList<Item>> {
        return null;
    }

    @Get('/item/search/{itemKey}/compatible')
    @MapClass(Item)
    public itemSearchCompatible(
        @Path('itemKey') itemKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<Item[]> {
        return null;
    }

    @Get('/item/{itemKey}/aliases')
    public aliases(@Path('itemKey') itemKey: string): Observable<string[]> {
        return null;
    }

    @Get('/item/search/summary')
    // tslint:disable-next-line:ter-prefer-arrow-callback
    @Adapter(function(res): PaginatedList<Item> {
        const list = new PaginatedList<Item>();
        list.totalDocs = res.totalDocs;
        if (Array.isArray(res.docs)) {
            for (let d of res.docs) {
                list.docs.push(new Item(d));
            }
        }
        return list;
    })
    public itemSearchSummary(
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<PaginatedList<ItemSummary>> {
        return null;
    }

    @Get('/item/{itemKey}/summary')
    @MapClass(Item)
    public getItemSummary(@Path('itemKey') itemKey: string): Observable<ItemSummary> {
        return null;
    }

    @Post('/item/{itemKey}/deduplicate')
    public deduplicate(
        @Path('itemKey') itemKey: string,
        @Body('duplicates') duplicates: Array<string>
    ): Observable<void> {
        return null;
    }
}
