import { TimeSeriesBinType } from '@libs/iso/core/enums/TimeSeriesBinType';

export interface ITimeSeriesSelector {
    name: string;
    selector: string;
}

export class TimeSeriesSelector implements ITimeSeriesSelector {
    public name: string;
    public selector: string;

    constructor(params: ITimeSeriesSelector) {
        this.name = params.name;
        this.selector = params.selector;
    }

    /**
     * Creates a value selector query and inserts the correct MongoDB type conversion function into
     * the query if applicable. For example, if the selector is 'pctRemaining.value', then this would
     * return '$pctRemaining.value' however, if the valueType were to also be TimeSeriesValueType.Decimal,
     * then the returned value would be {$toDecimal: '$pctRemaining.value'}
     * @returns {string|Object} -
     */
    public toValueSelectorQuery(): string | Object {
        return `$${this.selector}`;
    }
}

/**
 * Creates a new instance of the TimeSeriesSelector for the provided supply key and automatically
 * returns a selector that can extract the percent remaining value for the supply from a meter read.
 * @param {string} supplyKey - The key that the supply can be found at in the supply object of the
 * meter read.
 * @returns {TimeSeriesSelector} - The selector to use in a TimeSeriesRequest
 */
export function newSupplyPercentRemainingTimeSeriesSelector(supplyKey: string): TimeSeriesSelector {
    return new TimeSeriesSelector({
        name: supplyKey,
        selector: `supplies.${supplyKey}.pctRemaining.value`
    });
}

/**
 * Creates a new instance of the TimeSeriesSelector for the provided format/counter combination
 * and returns a selector that can extract the page counts or page count volumes from a meter read.
 * @param {string} format - The meter format, i.e. default, life, equiv.
 * @param {string} counter - The meter counter, i.e. total, totalBlack, totalFaxes.
 * @param {boolean} volume - Whether or not the time-series data should be a volume calculation.
 * @param {TimeSeriesBinType} binType - Determines how the values should be binned, if null then they
 * won't be binned at all.
 * @returns {TimeSeriesSelector} - The selector to use.
 */
export function newPageCountTimeSeriesSelector(
    format: string,
    counter: string
): TimeSeriesSelector {
    return new TimeSeriesSelector({
        name: `${format}/${counter}`,
        selector: `pageCounts.${format}.${counter}.value`
    });
}
