import { Component, OnInit, Input } from '@angular/core';
import { ShippingAddress, ShippingMethod, Cart } from '@libs/iso/core';
import { StepComponent } from '@app/shared/overlays/StepComponent';
import { Observable } from 'rxjs';
import { CartService } from '@app/core/services/cart.service';

@Component({
    selector: 'ptkr-shipping-step',
    templateUrl: './shipping-step.component.html',
    styleUrls: ['./shipping-step.component.scss']
})
export class ShippingStepComponent implements StepComponent, OnInit {
    @Input()
    public cart: Cart;

    public addresses$: Observable<ShippingAddress[]>;
    public methods: ShippingMethod[] = [
        new ShippingMethod({
            type: 'standard',
            price: '10',
            deliveryDate: null
        }),
        new ShippingMethod({
            type: 'expedited',
            price: '42',
            deliveryDate: null
        })
    ];
    public selectedAddress: ShippingAddress;
    public selectedMethod: ShippingMethod;

    constructor(private _cartService: CartService) {}

    ngOnInit(): void {
        this.addresses$ = this._cartService.getShippingAddresses([this.cart._id] as string[]);
    }

    public validate(): boolean {
        console.log('validate', this.selectedAddress, this.selectedMethod);
        if (this.cart.isWarranty && !!this.selectedAddress) {
            return true;
        } else {
            return !!this.selectedAddress && !!this.selectedMethod;
        }
    }

    public getValue(): any {
        return {
            address: this.selectedAddress,
            method: this.selectedMethod
        };
    }

    public selectAddress(address: ShippingAddress): void {
        this.selectedAddress = address;
    }

    public selectMethod(method: ShippingMethod): void {
        this.selectedMethod = method;
    }
}
