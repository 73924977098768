import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ItemSearchAllResults, Item, ItemType } from '@libs/iso/core';

@Component({
    selector: 'ptkr-component-autocomplete',
    templateUrl: './component-autocomplete.component.html',
    styleUrls: ['./component-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentAutocompleteComponent implements OnInit {
    @Input()
    public placeholder: string = 'Search Components';
    @Input()
    public defaultValue: string = '';
    @Input()
    public selected: Item;
    private _results: ItemSearchAllResults = new ItemSearchAllResults();
    @Input()
    public get results(): ItemSearchAllResults {
        return this._results;
    }
    public set results(value: ItemSearchAllResults) {
        if (!!value) {
            this._results = value;
            this._options = [...this._results.components, ...this._results.devices];
            this._optionIds = this._options.reduce((acc, cv) => [...acc, cv._id as string], []);
        }
    }

    @Output()
    public change: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public select: EventEmitter<Item> = new EventEmitter<Item>();

    private _optionIds: string[] = [];
    private _options: Item[] = [];

    public searchControl: FormControl;

    constructor() {}

    ngOnInit(): void {
        this.searchControl = new FormControl(this.defaultValue);
        this.searchControl.valueChanges.subscribe(val => {
            if (this._optionIds.indexOf(val) < 0) {
                this.change.emit(val);
            }
        });
    }

    public selectOption(option: Item): void {
        this.selected = option;
        this.select.emit(option);
    }

    public displayWith(): (val: any) => string {
        return (value: Item | string): string => this.displayFn(value);
    }

    public displayFn(value: Item | string): string {
        if (typeof value === 'string') {
            const option: Item = this._options.find(i => i._id === value);
            if (!!option) {
                return this.displayFn(option);
            } else {
                return value;
            }
        } else if (value.type === ItemType.Device) {
            return `${value.make} ${value.model}`;
        } else {
            // value.type === ItemType.Toner does not work, there seem to be various types, like 'ink'
            return `${value.partNumbers.vendorPartNumber} ${value.printing.color}`;
        }
    }
}
