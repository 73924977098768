import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, Get, Query } from '@libs/web/http';
import { TimeFrame } from '@libs/iso/core';

@Injectable()
export class OrderService extends HttpService {
    @Get('/order/admin')
    public getAdminCommissions(
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('searchTerm') searchTerm?: string,
        @Query('timeframe') timeframe?: TimeFrame,
        @Query('startDate') startDate?: Date,
        @Query('endDate') endDate?: Date
    ): Observable<any> {
        return null;
    }

    @Get('/order/sales')
    public getUserComissions(
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('searchTerm') searchTerm?: string,
        @Query('timeframe') timeframe?: TimeFrame,
        @Query('startDate') startDate?: Date,
        @Query('endDate') endDate?: Date
    ): Observable<any> {
        return null;
    }
}
