import {
    IDeviceEventAcknowledgement,
    DeviceEventAcknowledgement
} from './DeviceEventAcknowledgement';

export interface DeviceEventModel {
    type: any;
    dtTm: Date;
    description: string;
    pageCount: number;
    status: string;
    acknowledged: IDeviceEventAcknowledgement;
}

interface DeviceEventConstructorParams extends DeviceEventModel {}

export class DeviceEvent implements DeviceEventModel {
    public type: any;
    public dtTm: Date = null;
    public description: string = '';
    public pageCount: number = 0;
    public status: string = '';
    public acknowledged: DeviceEventAcknowledgement = new DeviceEventAcknowledgement();

    constructor(params?: Partial<DeviceEventConstructorParams>) {
        if (!!params) {
            this.type = params.type || this.type;
            if (!!params.dtTm) {
                this.dtTm = new Date(params.dtTm);
            }
            this.description = params.description || this.description;
            this.pageCount = params.pageCount || this.pageCount;
            this.status = params.status || this.status;
            if (!!params.acknowledged) {
                this.acknowledged = new DeviceEventAcknowledgement(params.acknowledged);
            }
        }
    }
}
