import { Event } from './Event';
import { PaginationLinks } from '../PaginationLinks';

export interface EventListModel {
    docs: Event[];
    totalDocs: number;
    urls: PaginationLinks;
}

export class EventList implements EventListModel {
    public docs: Event[] = [];
    public totalDocs: number = 0;
    public urls: PaginationLinks = new PaginationLinks();

    constructor(params?: Partial<EventListModel>) {
        if (!!params) {
            if (Array.isArray(params.docs)) {
                for (const d of params.docs) {
                    this.docs.push(new Event(d));
                }
            }
            this.totalDocs = params.totalDocs || this.totalDocs;
            this.urls = new PaginationLinks(params.urls);
        }
    }

}
