import { DeviceEventModel as DEMBase, DeviceEvent as DEBase } from '@libs/iso/core';
import {
    IDeviceEventAcknowledgement,
    DeviceEventAcknowledgement
} from './DeviceEventAcknowledgement';

export interface IDeviceEvent extends DEMBase {
    acknowledged: IDeviceEventAcknowledgement;
}

export class DeviceEvent extends DEBase implements IDeviceEvent {
    public acknowledged: DeviceEventAcknowledgement = new DeviceEventAcknowledgement();

    constructor(params?: Partial<IDeviceEvent>) {
        super(params);
        if (!!params) {
            if (!!params.acknowledged) {
                this.acknowledged = new DeviceEventAcknowledgement(params.acknowledged);
            }
        }
    }
}
