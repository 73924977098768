import { Component, Input, OnInit } from '@angular/core';
import { PageCountMeterRead } from '@libs/iso/core/models/meterRead/PageCountMeterRead';
import { MeterReadSupply } from '@libs/iso/core';

@Component({
    selector: 'ptkr-meter-supplies-chips',
    templateUrl: './meter-supplies-chips.component.html',
    styleUrls: ['./meter-supplies-chips.component.scss']
})
export class MeterSuppliesChipsComponent implements OnInit {
    @Input() public supplies: MeterReadSupply;
    @Input() public minChipsVisible: number = 1;

    constructor() {}

    ngOnInit(): void {}

    public extrapolateMeterSupplies(supplies: MeterReadSupply): Object {
        const object: Object = {};
        for (const supply in supplies) {
            let displayName: string;
            let value: any;

            for (const property in supplies[supply]) {
                if (property === 'displayableName') {
                    displayName = <string> supplies[supply][property].value;
                }
                if (property === 'pctRemaining') {
                    value = supplies[supply][property].value;
                }
            }
            if (!!displayName && !!value) {
                object[displayName] = value + '%';
            }
        }
        return object;
    }
}
