import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { ShippingAddress } from '@libs/iso/core';
import { OverlayService, NotificationService } from '@app/services';

@Component({
    selector: 'ptkr-select-shipping-address',
    templateUrl: './select-shipping-address.component.html',
    styleUrls: ['./select-shipping-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectShippingAddressComponent implements OnInit {
    private _addresses: Array<ShippingAddress> = [];
    @Input()
    public set addresses(value: Array<ShippingAddress>) {
        if (!!value && Array.isArray(value)) {
            this._addresses = value;
            this.shippingAddressSelection = [];
            this._addresses.forEach(a => {
                this.shippingAddressSelection.push(false);
            });
            if (!!this.selected) {
                const i = this._addresses.findIndex(a => this._compareAddresses(a, this.selected));
                if (i > -1) {
                    this.shippingAddressSelection[i] = true;
                } else {
                    this._addresses.push(this.selected);
                    this.shippingAddressSelection.push(true);
                }
                this._cd.detectChanges();
            }
        }
    }
    public get addresses(): Array<ShippingAddress> {
        return this._addresses;
    }
    private _selected: ShippingAddress;
    @Input()
    public set selected(value: ShippingAddress) {
        if (!!value && !this._compareAddresses(value, this._selected)) {
            this._selected = value;
            const i = this._addresses.findIndex(a => this._compareAddresses(a, value));
            if (i > -1) {
                this.shippingAddressSelection[i] = true;
            } else {
                this._addresses.push(this.selected);
                this.shippingAddressSelection.push(true);
            }
            this._cd.detectChanges();
        }
    }
    public get selected(): ShippingAddress {
        return this._selected;
    }

    @Output()
    public selection: EventEmitter<ShippingAddress> = new EventEmitter<ShippingAddress>();
    public shippingAddressSelection: Array<boolean> = new Array<boolean>();

    constructor(
        private _overlayService: OverlayService,
        private _notificationService: NotificationService,
        private _cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    public selectAddress(a: ShippingAddress, i: number): void {
        this._selected = a;
        this.shippingAddressSelection.fill(false);
        this.shippingAddressSelection[i] = true;
        this.selection.emit(a);
    }
    public newAddress(): void {
        // Open modal to add new shipping address here!
        const ref = this._overlayService.openShippingAddressModal();
        ref.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                console.log(this._addresses);
                this._addresses.push(confirmed);
                console.log(this._addresses);
                this.shippingAddressSelection.push(false);
                this._notificationService.success('Address successfully added');
                this._cd.detectChanges();
                this.selectAddress(confirmed, this.shippingAddressSelection.length - 1);
            }
        });
    }

    private _compareAddresses(adr1: ShippingAddress, adr2: ShippingAddress): boolean {
        console.log('compare addr', adr1, adr2);
        if (!adr1 || !adr2) {
            return false;
        } else if (!adr1.isEqual(adr2)) {
            return false;
        } else if (adr1.attn !== adr2.attn || adr1.companyName !== adr2.companyName) {
            return false;
        }
        return true;
    }
}
