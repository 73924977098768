import * as moment from 'moment';

export interface IInstallHealthCheckMessage {
    message: string;
    timestamp: moment.Moment;
}

export class InstallHealthCheckMessage implements IInstallHealthCheckMessage {
    public message: string = '';
    public timestamp: moment.Moment;

    constructor(params: Partial<IInstallHealthCheck>, property: string) {
        if (!!params) {
            if (!!params[property]) {
                this.message = params[property].message;
                this.timestamp = params[property].timestamp;
            } else {
                this.message = 'no data';
            }
        }
    }

    public isValid(): boolean {
        if (!this.timestamp) {
            return false;
        }

        if (this.isExpired()) {
            // if its been too long since we've gotten an update
            return false;
        } else if (this.message !== '') {
            // if there's an error
            return false;
        } else {
            // if it has been updated recently and there's no error
            return true;
        }
    }

    public isWarning(): boolean {
        return this.message.includes('warning');
    }

    public isError(): boolean {
        if (this.isValid() === false && this.isWarning() === false) {
            return true;
        } else {
            return false;
        }
    }

    public isExpired(): boolean {
        const reported = moment(this.timestamp);
        const now = moment.utc();
        const diff = Math.abs(now.diff(reported, 'minutes'));

        if (diff > 30) {
            return true;
        } else {
            return false;
        }
    }
}

export interface IBoltStore {
    registration: {
        ApiKey: string;
        CreatedAt: string;
        EntityKey: string;
        ID: string;
        InstallKey: string;
        IssuingCa: string;
        ModifiedAt: string;
        X509Cert: string;
    };
}

export interface IInstallHealthCheck {
    healthState: IInstallHealthCheckMessage;
    registration: IInstallHealthCheckMessage;
    taskEngine: IInstallHealthCheckMessage;
    rpcClient: IInstallHealthCheckMessage;
    jobStream: IInstallHealthCheckMessage;
    dependencies: IInstallHealthCheckMessage;
    boltStore: IBoltStore;
}

export class InstallHealthCheck {
    public healthState: IInstallHealthCheckMessage;
    public registration: IInstallHealthCheckMessage;
    public taskEngine: IInstallHealthCheckMessage;
    public rpcClient: IInstallHealthCheckMessage;
    public jobStream: IInstallHealthCheckMessage;
    public dependencies: IInstallHealthCheckMessage;
    public boltStore: IBoltStore;

    constructor(params?: Partial<IInstallHealthCheck>) {
        if (!!params) {
            this.healthState = params.healthState || null;
            this.registration = params.registration || null;
            this.taskEngine = params.taskEngine || null;
            this.rpcClient = params.rpcClient || null;
            this.jobStream = params.jobStream || null;
            this.dependencies = params.dependencies || null;
            this.boltStore = params.boltStore || null;
        }
    }
}
