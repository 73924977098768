<form [formGroup]="passwordForm" (ngSubmit)="savePassword(passwordForm)">
    <ptkr-field-group label="Current password" i18n-label required="true" *ngIf="requireCurrentPassword">
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput type="password" formControlName="currentPassword">
            <mat-error [ptkrErrorControl]="passwordForm.get('currentPassword')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="New password" i18n-label required="true">
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput type="password" formControlName="newPassword">
            <mat-error [ptkrErrorControl]="passwordForm.get('newPassword')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Confirm password" i18n-label required="true">
        <mat-form-field appearance="outline" floatLabel="never">
            <input matInput type="password" formControlName="confirmPassword">
            <mat-error [ptkrErrorControl]="passwordForm.get('confirmPassword')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <hr>
    <div class="action-buttons">
        <button mat-flat-button color="accent">{{ buttonLabel }}</button>
    </div>
</form>
