import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'ptkr-device-starting-page-counts-section',
    templateUrl: './device-starting-page-counts-section.component.html',
    styleUrls: ['./device-starting-page-counts-section.component.scss']
})
export class DeviceStartingPageCountsSectionComponent implements OnInit {
    @Input('startingPageCountsForm')
    public startingPageCountsForm: FormGroup;

    constructor() {}

    ngOnInit(): void {}
}
