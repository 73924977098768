import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseAddress, BaseAddressModel } from '@libs/iso/core';

export interface AddressModel extends BaseAddressModel {}

export interface AddressConstructorParams extends AddressModel {}

export class Address extends BaseAddress implements AddressModel {
    constructor(params?: Partial<AddressConstructorParams>) {
        super(params);
    }

    public static toFormGroup(address: Address = null, required: boolean = true): FormGroup {
        // const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        // const maxZipCodeLength = 5;
        return new FormGroup({
            address1: new FormControl(
                address ? address.address1 : '',
                required ? [Validators.required] : []
            ),
            address2: new FormControl(address ? address.address2 : ''),
            city: new FormControl(
                address ? address.city : '',
                required ? [Validators.required] : []
            ),
            stateOrProvince: new FormControl(
                address ? address.stateOrProvince : null,
                required ? [Validators.required] : []
            ),
            // prettier-ignore
            zipOrPostCode: new FormControl(
                address ? address.zipOrPostCode : '',
                required
                    ? [
                        Validators.required,
                        // Validators.minLength(maxZipCodeLength),
                        // Validators.maxLength(maxZipCodeLength),
                        // Validators.pattern(regex)
                    ]
                    : [
                        // Validators.minLength(maxZipCodeLength),
                        // Validators.maxLength(maxZipCodeLength),
                        // Validators.pattern(regex)
                    ]
            ),
            country: new FormControl(
                address ? address.country : '',
                required ? [Validators.required] : []
            )
        });
    }
}
