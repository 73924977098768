export enum InstallPropertyType {
    DCAVersion,
    ChromiumVersion,
    OS,
    Antivirus,
    LastCheckInDtTm
}

export namespace InstallPropertyType {
    export function toDBProperty(type: InstallPropertyType): string {
        switch (type) {
            case InstallPropertyType.DCAVersion:
                return 'versionDCA';
            case InstallPropertyType.ChromiumVersion:
                return 'moduleVersions.chromium';
            case InstallPropertyType.OS:
                return 'os';
            case InstallPropertyType.Antivirus:
                return 'antivirus';
            case InstallPropertyType.LastCheckInDtTm:
                return 'lastCheckInDtTm';
            default:
                return '';
        }
    }
}
