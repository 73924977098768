import { HttpResponse } from '@angular/common/http';

export class HttpAdapter {
    private static _successCode: number = 200;

    public static baseAdapter(res: HttpResponse<any>, adapterFn?: Function): any {
        if (!res) {
            return;
        } else if (res.status === this._successCode) {
            try {
                return res.clone({
                    body: adapterFn ? adapterFn.call(undefined, res.body) : res.body
                });
            } catch (e) {
                return res;
            }
        }
    }

    public static baseClassMap<T>(res: HttpResponse<any>, classToMap: new (params: any) => T): any {
        if (!res) {
            return;
        }
        if (res.status === this._successCode) {
            try {
                if (Array.isArray(res.body)) {
                    const mapped = Array<T>();
                    for (const m of res.body) {
                        mapped.push(new classToMap(m));
                    }
                    return res.clone({ body: mapped });
                } else {
                    return res.clone({ body: new classToMap(res.body) });
                }
            } catch (e) {
                console.error('class map failed', e, res.body, classToMap);
                return res;
            }
        }
    }
}
