import { ContactPhoneType } from '@libs/iso/core';

export interface UserContactModel {
    phone: Array<{
        type: ContactPhoneType;
        number: string;
    }>;
    altEmail: string;
    receiveSMS: boolean;
}

interface UserContactConstructorParams extends UserContactModel {}

export class UserContact implements UserContactModel {
    public phone: Array<{
        type: ContactPhoneType;
        number: string;
    }> = [];
    public altEmail: string = '';
    public receiveSMS: boolean = false;

    constructor(params?: Partial<UserContactConstructorParams>) {
        if (!!params) {
            if (Array.isArray(params.phone)) {
                for (const p of params.phone) {
                    this.phone.push({
                        type: p.type,
                        number: p.number
                    });
                }
            }
            this.altEmail = params.altEmail || this.altEmail;
            this.receiveSMS = params.receiveSMS || this.receiveSMS;
        }
    }
}
