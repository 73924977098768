enum ScheduleType {
    Day = '[ScheduleType] day',
    Week = '[ScheduleType] week',
    Month = '[ScheduleType] month',
    Year = '[ScheduleType] year'
}
namespace ScheduleType {
    export function toString(type: ScheduleType): string {
        switch (type) {
            case ScheduleType.Day:
                return 'Daily';
            case ScheduleType.Week:
                return 'Weekly';
            case ScheduleType.Month:
                return 'Monthly';
            case ScheduleType.Year:
                return 'Yearly';
            default:
                return '';
        }
    }
    export const types: ScheduleType[] = [
        ScheduleType.Day,
        ScheduleType.Week,
        ScheduleType.Month,
        ScheduleType.Year
    ];
}
export { ScheduleType };
