import { ComponentRef, Directive, Input, OnDestroy, OnInit, Type, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[ptkrDynamicFormControl]'
})
export class DynamicFormControlDirective implements OnInit, OnDestroy {
    @Input() control: FormControl;
    @Input() component: Type<any>;
    private componentRef: ComponentRef<any>;

    constructor(private container: ViewContainerRef) {}

    ngOnInit() {
        this.loadComponent();
    }

    ngOnChanges() {
        this.loadComponent();
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    private loadComponent() {
        this.container.clear();

        this.componentRef = this.container.createComponent(this.component);
        this.componentRef.instance.control = this.control;
    }
}
