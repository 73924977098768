<div class="payment-method-modal">
    <span class="close" (click)="dialogRef.close()"><i class="fas fa-times"></i></span>
    <h1>Add payment method</h1>
    <div class="add-card">
        <p class="stripe-error" *ngIf="stripeError">{{ stripeError }}</p>
        <ptkr-add-credit-card [icons]="true"></ptkr-add-credit-card>
        <div class="actions">
            <button mat-flat-button color="accent" (click)="addCard()">Add payment method</button>
        </div>
    </div>
</div>
