<table class="g-table" mat-table matSort [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let job">{{ job.jobName }}</td>
    </ng-container>

    <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Started</th>
        <td mat-cell *matCellDef="let job">{{ job.createdDate | date: 'medium' }}</td>
    </ng-container>

    <ng-container matColumnDef="modifiedDate">
        <th mat-header-cell *matHeaderCellDef>Last updated</th>
        <td mat-cell *matCellDef="let job">{{ job.modifiedDate | date: 'medium' }}</td>
    </ng-container>

    <ng-container matColumnDef="jobDuration">
        <th mat-header-cell *matHeaderCellDef>Duration</th>
        <td mat-cell *matCellDef="let job">{{jobDuration(job)}}</td>
    </ng-container>

    <ng-container matColumnDef="tasks">
        <th mat-header-cell *matHeaderCellDef>Tasks</th>
        <td mat-cell *matCellDef="let job">
            <ptkr-chips [map]="jobTasks(job)"></ptkr-chips>
        </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let job">
            <div class="label padding-mod" [ngClass]="job.jobStatus.type">{{ job.jobStatus.type }}</div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
