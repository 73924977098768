enum DeviceReportType {
    Pages = 'pages',
    Toner = 'toner'
}

namespace DeviceReportType {
    export function toString(type: DeviceReportType): string {
        switch (type) {
            case DeviceReportType.Pages:
                return 'Pages';
            case DeviceReportType.Toner:
                return 'Toner';
            default:
                return '';
        }
    }
}

export { DeviceReportType };
