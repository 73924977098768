import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SkipAlertsHistoryModalConfig } from '@libs/web/forms';

@Component({
    selector: 'ptkr-skip-alerts-history-modal',
    templateUrl: './skip-alerts-history-modal.component.html',
    styleUrls: ['./skip-alerts-history-modal.component.scss']
})
export class SkipAlertsHistoryModalComponent {
    public dataSource: MatTableDataSource<any>;
    constructor(
        public dialogRef: MatDialogRef<SkipAlertsHistoryModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SkipAlertsHistoryModalConfig
    ) {
        this.dataSource = new MatTableDataSource<any>(this.data.skipAlert.history.reverse());
    }
}
