<table
    mat-table
    class="g-table"
    [dataSource]="dgiVersionData"
    *ngIf="dgiVersionData && dgiVersionData.length > 0; else noLabels"
>
    <ng-container matColumnDef="versionName">
        <th mat-header-cell *matHeaderCellDef i18n>
            Version name
        </th>
        <td mat-cell *matCellDef="let data" (click)="this.value = data.key">
            <span class="ellipsis" matTooltipShowDelay="500" [matTooltip]="data.versionName">
                <mat-radio-button
                    [checked]="isChecked(data.key)"
                    >{{ data.versionName || '' }}</mat-radio-button
                >
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="lastUpdated">
        <th mat-header-cell *matHeaderCellDef i18n>
            Last updated
        </th>
        <td mat-cell *matCellDef="let data">
            <span
                class="ellipsis"
                matTooltipShowDelay="500"
                [matTooltip]="data.lastUpdated"
                *ngIf="!!data.lastUpdated; else noDate"
                >{{ data.lastUpdated | amTimeAgo }} ({{ data.lastUpdated | date: 'medium' }})
            </span>
            <ng-template #noDate>
                <span class="ellipsis" matTooltipShowDelay="500" matTooltip="No date">--</span>
            </ng-template>
        </td>
    </ng-container>
    <ng-container matColumnDef="releaseNote">
        <th mat-header-cell *matHeaderCellDef i18n>
            Release Note
        </th>
        <td mat-cell *matCellDef="let data">
            <span
                class="ellipsis"
                matTooltipShowDelay="500"
                [matTooltip]="data.releaseNote"
                *ngIf="!!data.releaseNote; else noNote"
                >{{ data.releaseNote || '' }}</span
            >
            <ng-template #noNote>
                <span class="ellipsis" matTooltipShowDelay="500" matTooltip="No note"> -- </span>
            </ng-template>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['versionName', 'lastUpdated', 'releaseNote']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['versionName', 'lastUpdated', 'releaseNote']"></tr>
</table>

<ng-template #noLabels>
    <h2 class="empty-table" i18n>No Dgis Available.</h2>
</ng-template>
