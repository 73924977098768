import { Injectable } from '@angular/core';
import { SuppliesList } from '@app/models';
import {
    DeviceSupply
} from '@libs/iso/core';
import { DeviceSupplySettings } from '@libs/iso/core/models/device/DeviceSupply';
import {
    Body, Get, HttpService, MapClass, Path, Put, Query, ResponseType
} from '@libs/web/http';
import { Observable } from 'rxjs';

@Injectable()
export class SupplyService extends HttpService {

    @Put('/supply/{id}')
    public updateSupply(
        @Path('id') id: string,
        @Body('deviceKey') deviceKey: string,
        @Body('supply') supply: Partial<DeviceSupply>,
        @Body('settings') settings: DeviceSupplySettings
    ): Observable<any> {
        return null;
    }

    @Get('/supply/exportCsv', ResponseType.Blob)
    public exportSuppliesToCSV(
        @Query('entityId') entityId: string,
        @Query('deviceKey') deviceKey?: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('includeReplaced') includeReplaced?: boolean,
        @Query('replacedStartDate') replacedStartDate?: string,
        @Query('replacedEndDate') replacedEndDate?: string,
    ): Observable<any> {
        return null;
    }

    @Get('/supply')
    @MapClass(SuppliesList)
    public getSuppliesList(
        @Query('entityId') entityId: string,
        @Query('deviceKey') deviceKey?: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('includeReplaced') includeReplaced?: boolean,
        @Query('includeFromNonManagedDevices') includeFromNonManagedDevices?: boolean,
        @Query('replacedStartDate') replacedStartDate?: Date,
        @Query('replacedEndDate') replacedEndDate?: Date,
    ): Observable<SuppliesList> {
        return null;
    }
}
