import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { PageCountMeterRead } from '@libs/iso/core/models/meterRead/PageCountMeterRead';
import { NotificationService } from '@app/core/services/notification.service';
import { ENTER, COMMA, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { MeterTypeNameMap, MeterPath } from '@libs/iso/core';

/* See https://material.angular.io/components/tree/examples; look for 'Tree with checkboxes'.
   They recommend this component's structure there.
*/

export class TreeNode {
    public name: string;
    public value: any;
    public children: Array<TreeNode>;
}

export class TreeFlatNode {
    public name: string;
    public value: any;
    public level: number;
    public expandable: boolean;
}

@Component({
    selector: 'ptkr-edit-billing-meter-modal',
    templateUrl: './edit-billing-meter-modal.component.html',
    styleUrls: ['./edit-billing-meter-modal.component.scss']
})
export class EditBillingMeterModalComponent {
    public treeControl: FlatTreeControl<TreeFlatNode>;
    public treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
    public dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;

    public flatNodeMap: Map<TreeFlatNode, TreeNode> = new Map<TreeFlatNode, TreeNode>();
    public nestedNodeMap: Map<TreeNode, TreeFlatNode> = new Map<TreeNode, TreeFlatNode>();

    public checklistSelection: SelectionModel<TreeFlatNode> = new SelectionModel<TreeFlatNode>(
        true
    );

    public isCanon: boolean = false;
    public canonSelection: Array<string> = [];
    public separatorKeysCodes: any = [ENTER, COMMA, SEMICOLON, TAB];
    public nameIsValid: boolean = false; // Will not allow confirmation wihtout this.
    public showingMap: boolean = false;

    public hasChild = (_: number, _nodeData: TreeFlatNode): boolean => _nodeData.expandable;

    private _getLevel = (node: TreeFlatNode): number => node.level;
    private _isExpandable = (node: TreeFlatNode): boolean => node.expandable;
    private _getChildren = (node: TreeNode): Array<TreeNode> => node.children;

    private _transformer = (node: TreeNode, level: number): TreeFlatNode => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.value === node.value ? existingNode : new TreeFlatNode();
        flatNode.name = node.name;
        flatNode.value = node.value;
        flatNode.level = level;
        flatNode.expandable = !!node.children && node.children.length > 0;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    constructor(
        private dialogRef: MatDialogRef<EditBillingMeterModalComponent>,
        private _notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            meters: PageCountMeterRead | { [key: string]: { [key: string]: any } };
            meterTypeNameMap: MeterTypeNameMap;
            meterKey: string;
            selected: Array<MeterPath>;
            otherMeterKeys: Array<string>;
            title: string;
            isDevice: boolean;
        }
    ) {
        // If there are no meters, set it to meterTypeNameMap.
        // Spencer 12/22/2020 - filteredData is created to allow us to see if there are any
        // properties in data other than default or canon, which we don't include in our
        // available meter options. If there are only default and/or canon properties, that means
        // we should just show all options.
        const filteredData = { ...data.meters };
        delete filteredData.default;
        delete filteredData.canon;
        let options;
        if (Object.keys(filteredData).length > 0) {
            options = data.meters;
        } else {
            options = data.meterTypeNameMap.map;
            this.showingMap = true;
        }

        // We also want to extract canons from selected and put them in their own data object.
        this.canonSelection = !!data.selected
            ? data.selected
                  .filter((v: MeterPath, i: number, arr: Array<MeterPath>) => v.format === 'canon')
                  .map(e => e.meter)
            : [];

        // If there are canon meters, they probably need 'isCanon' set to true.
        if (this.canonSelection.length > 0) {
            this.isCanon = true;
        }

        this.treeControl = new FlatTreeControl<TreeFlatNode>(this._getLevel, this._isExpandable);
        this.treeFlattener = new MatTreeFlattener<TreeNode, TreeFlatNode>(
            this._transformer,
            this._getLevel,
            this._isExpandable,
            this._getChildren
        );
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.dataSource.data = this._generateTreeNodes(options);
        this._setSelection(data.selected);
    }

    public ngOnInit(): void {
        this.checklistSelection.changed.subscribe(selection => {
            const newSelection: Array<Array<MeterPath>> = [];
            selection.source.selected.forEach(node => {
                if (!!node.value) {
                    if (!newSelection[node.value['type']]) {
                        newSelection[node.value['type']] = [];
                    }
                    newSelection[node.value['type']].push(node.value['counter']);
                }
            });
        });
    }

    public drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.checklistSelection.selected, event.previousIndex, event.currentIndex);
    }

    public cancel(): void {
        this.dialogRef.close({
            do: 'nothing'
        });
    }

    public confirm(): void {
        this.dialogRef.close({
            do: 'update',
            meterKey: this.data.meterKey, // Which is updated reactively by meter key picker
            selection: this.isCanon
                ? this.canonSelection.map(e => ({ format: 'canon', meter: e }))
                : this.checklistSelection.selected.map(e => e.value)
        });
    }

    // Public tree funcs ---------------------
    public descendantsAllSelected(node: TreeFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every(child => this.checklistSelection.isSelected(child));
        return descAllSelected;
    }

    public descendantsPartiallySelected(node: TreeFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    public todoItemSelectionToggle(node: TreeFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);
        descendants.forEach(child => this.checklistSelection.isSelected(child));
    }

    public todoLeafItemSelectionToggle(node: TreeFlatNode): void {
        this.checklistSelection.toggle(node);
    }

    // Chip functions -------------------------
    public addChip(event: any): void {
        const element = event.input;
        const value = event.value;

        if (value.match(/^\d\d\d$/)) {
            if (value.trim() !== '' || null) {
                const newValue: Array<string> = [...this.canonSelection, value.trim()];
                this.canonSelection = newValue;
                element.value = '';
            }
        } else {
            this._notificationService.error('Please enter a valid 3-digit Canon meter code');
        }
    }

    public removeChip(event: string): void {
        const index = this.canonSelection.indexOf(event);

        if (index >= 0) {
            this.canonSelection.splice(index, 1);
        }
    }

    // Misc ----------------
    public removeSelection(selection: TreeFlatNode): void {
        this.checklistSelection.toggle(selection);
    }

    public displayBillingMeter(format: string, meter: string): string {
        return this.data.meterTypeNameMap.getName(format, meter);
    }

    // private functions ----------------
    private _generateTreeNodes(meters: any): Array<TreeNode> {
        function sortFunc(a: TreeNode, b: TreeNode): number {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        }

        const nodes: Array<TreeNode> = [];
        for (const key of Object.keys(meters)) {
            if (key === 'default' || key === 'canon') {
                continue;
            }

            const node: TreeNode = {
                name: key, // Spencer 9/23/20 TODO - Come up with way to gen name for types
                value: undefined,
                children: []
            };

            for (const meterKey of Object.keys(meters[key])) {
                node.children.push({
                    name: this.displayBillingMeter(key, meterKey),
                    value: { format: key, meter: meterKey },
                    children: undefined
                });
            }

            node.children.sort(sortFunc);

            nodes.push(node);
        }

        nodes.sort(sortFunc);

        return nodes;
    }

    private _setSelection(selection: Array<MeterPath>): void {
        // Add all nodes representing the selection to checklistSelection.
        if (!!selection) {
            // const nodes: Array<TreeFlatNode> = this.dataSource._flattenedData.getValue();
            const nodes = [];
            selection.forEach(e => {
                const node = nodes.find((value: TreeFlatNode, index: number, obj: TreeFlatNode[]) =>
                    !!value.value &&
                    value.value.format === e.format &&
                    value.value.meter === e.meter
                        ? true
                        : false
                );
                if (!!node) {
                    this.checklistSelection.select(node);
                }
            });
        }
    }
}
