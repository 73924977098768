import { PropertiesOf } from '@libs/iso/core';
import { z } from 'zod';
import { validateIpRange } from '@libs/iso/core/helpers/ip';

export enum EntityDynamicDeviceGroupField {
    IPAddress = 'ipAddress'
}

export namespace EntityDynamicDeviceGroupField {
    export function display(v: EntityDynamicDeviceGroupField): string {
        switch (v) {
            case EntityDynamicDeviceGroupField.IPAddress:
                return 'IP address';
            default:
                return '';
        }
    }

    export const asArray = Object.values(EntityDynamicDeviceGroupField);

    export function validator(type: EntityDynamicDeviceGroupField, v: any): Error | null {
        switch (type) {
            case EntityDynamicDeviceGroupField.IPAddress:
                const error = validateIpRange(v, 'include');
                if (error !== null) {
                    return new Error(error);
                }
                return null;
            default:
                return null;
        }
    }
}

const schema = z
    .object({
        field: z.enum(EntityDynamicDeviceGroupField.asArray),
        groups: z.array(z.object({ name: z.string().min(1), options: z.any() }))
    })
    .refine(data => {
        if (data.field === EntityDynamicDeviceGroupField.IPAddress) {
            for (const group of data.groups) {
                const output = ipAddressOptionsSchema.safeParse(group.options);
                if (output.success == false) {
                    return false;
                }
            }
        }
        return true;
    });

const ipAddressOptionsSchema = z.object({
    selector: z.string().superRefine(arg => {
        const error = EntityDynamicDeviceGroupField.validator(
            EntityDynamicDeviceGroupField.IPAddress,
            arg
        );
        if (error !== null) {
            throw error;
        }
    })
});

export class EntityDynamicDeviceGroups {
    public field: string | null;
    public groups: { name: string; options: any }[];

    constructor(params?: PropertiesOf<EntityDynamicDeviceGroups>) {
        if (params) {
            Object.assign(this, params);
        }
    }

    public static validate(
        value: Partial<PropertiesOf<EntityDynamicDeviceGroups>>
    ): ReturnType<typeof schema.safeParse> {
        return schema.safeParse(value);
    }
}
