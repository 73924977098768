import { Injectable } from '@angular/core';
import { HttpService, Post, Body } from '@libs/web/http';
import { Observable } from 'rxjs';
import { Task, JobStatusTypes } from '@libs/iso/core';
import { ObjectId } from 'bson';

@Injectable()
export class RtsService extends HttpService {
    @Post('/rts/job')
    public createJob(
        @Body('entityKey') entityKey: string | ObjectId,
        @Body('installKey') installKey: Array<string> | string,
        @Body('userKey') userKey: string,
        @Body('metadata') metadata: any,
        @Body('jobName') jobName: string,
        @Body('jobDescription') jobDescription: string,
        @Body('destination') destination: string,
        @Body('tasks') tasks: Array<Task>,
        @Body('hidden') hidden: boolean = false
    ): Observable<any> {
        return null;
    }
}
