import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnDestroy
} from '@angular/core';
import { User } from '@app/models';
import { Permission } from '@libs/iso/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
    OverlayService,
    EntityService,
    NotificationService,
    RouterService,
    WebadminV2Service,
    BrandingService
} from '@app/services';
import { GlobalStore } from '@app/state/store';
import { Store } from '@ngrx/store';
import { selectEntity } from '@app/state/store/global.store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface MenuItem {
    title: string;
    active: string;
    route: string;
    icon: string;
    permission: Permission;
    top?: boolean; // if the router link should not include /entity/{id} at its beginning
    handler?: () => void;
}

export interface MenuItemParent extends MenuItem {
    children: MenuItem[];
}

@Component({
    selector: 'ptkr-secured-sidebar',
    templateUrl: './new-secured-sidebar.component.html',
    styleUrls: ['./new-secured-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewSecuredSidebarComponent implements OnInit, OnDestroy {
    public expiresOn: Date;
    public daysRemaining: number;

    private _user: User;
    @Input()
    public set user(value: User) {
        this._user = value;
        this._store.select(selectEntity).subscribe(entity => {
            this._entityService.getExpirationDate(entity._id).subscribe(
                res => {
                    // Calculate time remaining until expiration occurs
                    if (!!res) {
                        this.expiresOn = new Date(res);
                        const oneDay = 24 * 60 * 60 * 1000; // hours * min * sec * millisec
                        this.expiresOn.setHours(0, 0, 0);
                        const currentDate = new Date();
                        currentDate.setHours(0, 0, 0);
                        this.daysRemaining = Math.ceil(
                            (this.expiresOn.getTime() - currentDate.getTime()) / oneDay
                        );
                    } else {
                        this.expiresOn = null;
                    }
                    this._cd.detectChanges();
                },
                err => {
                    this._notificationService.error(err);
                }
            );
        });

        this.menuItems = this.buildMenu(this._user);
        this.hasBillingPermission = Permission.hasPermission(Permission.ManageBilling, this._user.permissions);
    }
    private _activeLink: string;
    @Input()
    public set activeLink(value: string) {
        this._activeLink = '';
        this.activeSubLink = '';
        for (const item of this.menuItems) {
            if (value.indexOf(item.active) > 1) {
                this._activeLink = item.active;
                if (item.children.length > 0) {
                    const ignoreEntityUrlIndex = 2;
                    for (const child of item.children) {
                        if (
                            value.indexOf(
                                child.active,
                                child.active === 'entity' ? ignoreEntityUrlIndex : 0
                            ) > 1
                        ) {
                            this.activeSubLink = child.active;
                            break;
                        }
                    }
                }
                break;
            }
        }
    }

    public get activeLink(): string {
        return this._activeLink;
    }

    public hasBillingPermission: boolean;

    @Output()
    public navigate: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public toggleDrawer: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public expand: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public orderToner: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public openWarranty: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public openJobs: EventEmitter<void> = new EventEmitter<void>();

    public menuItems: MenuItemParent[] = [];
    public activeSubLink: string = '';
    public expanded: string = '';
    public Permission: typeof Permission = Permission;
    public dealerLogo?: string;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _overlayService: OverlayService,
        private _entityService: EntityService,
        private _routerService: RouterService,
        private _notificationService: NotificationService,
        private _webadminV2Service: WebadminV2Service,
        private _store: Store<GlobalStore>,
        private _brandingService: BrandingService,
        private _cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this._brandingService.dealerLogo.pipe(takeUntil(this.ngUnsubscribe)).subscribe(logo => {
            this.dealerLogo = logo;
            this._cd.detectChanges();
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public selectPage(urlFrag: string, expanded: string = '', top: boolean = false): void {
        if (urlFrag) {
            if (!top) {
                this.navigate.emit(urlFrag);
            } else {
                this._router.navigate([urlFrag]);
            }
        } else {
            this.expanded = this.expanded === expanded ? '' : expanded;
        }
        this.expand.emit(this.expanded !== '');
    }

    public buildMenu(user: User): MenuItemParent[] {
        const menu: MenuItemParent[] = [
            {
                title: $localize`Home`,
                active: 'home',
                route: '/home/dashboard',
                icon: 'fas fa-home',
                permission: null,
                children: []
            },
            {
                title: $localize`Installs`,
                active: 'installs',
                route: '',
                icon: 'fas fa-desktop',
                permission: null,
                children: [
                    {
                        title: $localize`View installs`,
                        active: 'list',
                        route: '/installs/list',
                        icon: '',
                        permission: Permission.ManageInstallations
                    },
                    {
                        title: $localize`Deploy install`,
                        active: 'add',
                        route: '/installs/add',
                        icon: '',
                        permission: Permission.DeviceAdd
                    }
                ]
            },
            {
                title: $localize`Devices`,
                active: 'devices',
                route: '',
                icon: 'fas fa-print',
                permission: null,
                children: [
                    {
                        title: $localize`View devices`,
                        active: 'list',
                        route: '/devices/list',
                        icon: '',
                        permission: null
                    },
                    {
                        title: $localize`New devices`,
                        active: 'new',
                        route: '/devices/new',
                        icon: '',
                        permission: Permission.NewDeviceList
                    },
                    {
                        title: $localize`View supplies`,
                        active: 'supplies',
                        route: '/supplies',
                        icon: '',
                        permission: null
                    },
                    {
                        title: $localize`Manage alerts`,
                        active: 'alerts',
                        route: '/devices/alerts',
                        icon: '',
                        permission: null
                    }
                    // {
                    //     title: 'Update settings',
                    //     active: 'settings',
                    //     route: '/devices/settings',
                    //     icon: '',
                    //     permission: null
                    // }
                ]
            },
            {
                title: $localize`Jobs`,
                active: 'jobs',
                route: '',
                icon: 'far fa-history',
                permission: null,
                children: [
                    {
                        title: $localize`View`,
                        active: 'view',
                        route: '/jobs/view',
                        icon: '',
                        permission: Permission.ViewJobs
                    },
                    {
                        title: $localize`Batches`,
                        active: 'batches',
                        route: '/jobs/batch/list',
                        icon: '',
                        permission: Permission.ListJobBatches
                    }
                ]
            },
            {
                title: $localize`Insights`,
                active: 'insights',
                route: '',
                icon: 'far fa-lightbulb',
                permission: null,
                children: [
                    {
                        title: $localize`Reports`,
                        active: 'reports',
                        route: '/insights/reports',
                        icon: '',
                        permission: Permission.ViewReports
                    },
                    // {
                    //     title: 'Migration',
                    //     active: 'migration',
                    //     route: '/insights/migration',
                    //     icon: '',
                    //     permission: Permission.ViewReports
                    // },
                    {
                        title: $localize`Pages lost`,
                        active: 'pages-lost',
                        route: '/insights/pages-lost',
                        icon: '',
                        permission: Permission.NoAccess
                    }
                ]
            },
            {
                title: $localize`Admin`,
                active: 'admin',
                route: '',
                icon: 'far fa-key',
                permission: null,
                children: [
                    // {
                    //     title: 'Create Invoices',
                    //     active: 'invoices',
                    //     route: '/admin/invoices/list',
                    //     icon: '',
                    //     permission: Permission.CreateInvoices
                    // },
                    {
                        title: $localize`Manage entity`,
                        active: 'entity',
                        route: '/admin/entity/manage',
                        icon: '',
                        permission: Permission.EntityManage
                    },
                    {
                        title: $localize`Manage users`,
                        active: 'users',
                        route: '/admin/users/list',
                        icon: '',
                        permission: Permission.UserManage
                    },
                    {
                        title: $localize`Move Entities`,
                        active: 'hierarchy',
                        route: '/admin/hierarchy/',
                        icon: '',
                        permission: Permission.EntityHierarchy
                    },
                    {
                        title: $localize`View commissions`,
                        active: 'order',
                        route: `/admin/order/commissions/${
                            !!user.commissionPlanKey ? 'user' : 'admin'
                        }`,
                        icon: '',
                        permission: Permission.NoAccess // Permission.ViewCommissions
                    },
                    {
                        title: $localize`View invoices`,
                        active: 'invoices',
                        route: '/admin/invoices/list',
                        icon: '',
                        permission: Permission.NoAccess // Permission.ViewInvoices
                    },
                    {
                        title: $localize`Entity items`,
                        active: 'eitems',
                        route: '/admin/eitems/list',
                        icon: '',
                        permission: Permission.NoAccess // Permission.ManageEntityItems
                    },
                    {
                        title: $localize`Manage items`,
                        active: 'item',
                        route: '/admin/item/list',
                        icon: '',
                        permission: Permission.NoAccess // Permission.ItemList
                    },
                    {
                        title: $localize`SuperTable`,
                        active: 'supertable',
                        route: '/admin/supertable',
                        icon: '',
                        permission: Permission.DaemonOnly
                    }
                ]
            },
            // {
            //     title: $localize`Tools`,
            //     active: 'pt',
            //     route: '',
            //     icon: 'far fa-wrench',
            //     permission: null,
            //     children: [
            //         {
            //             title: 'DGIs',
            //             active: 'dgi',
            //             route: '/pt/dgi',
            //             icon: '',
            //             permission: Permission.UpdateDGI,
            //             top: true
            //         },
            //         {
            //             title: 'TECs',
            //             active: 'tec',
            //             route: '/pt/tec',
            //             icon: '',
            //             permission: Permission.UpdateTEC,
            //             top: true
            //         }
            //     ]
            // },
            {
                title: $localize`Item`,
                active: 'item',
                route: '',
                icon: 'far fa-print',
                permission: null,
                children: [
                    {
                        title: $localize`Deduplicate`,
                        active: 'deduplicate',
                        route: '/entity/item/deduplicate',
                        icon: '',
                        permission: Permission.DaemonOnly,
                        top: true
                    }
                ]
            }
        ];

        const finalMenu: any[] = [];
        for (const mainItem of menu) {
            if (
                mainItem.permission !== null &&
                !Permission.hasPermission(mainItem.permission, user.permissions)
            ) {
                continue;
            }
            if (mainItem.children.length > 0) {
                const newChildItems: any[] = [];
                for (const childItem of mainItem.children) {
                    if (
                        childItem.permission === null ||
                        Permission.hasPermission(childItem.permission, user.permissions)
                    ) {
                        newChildItems.push(childItem);
                    }
                }
                mainItem.children = newChildItems;
            }
            if (mainItem.route !== '' || mainItem.children.length > 0) {
                finalMenu.push(mainItem);
            }
        }
        return finalMenu;
    }

    public openWelcomeWizard(): void {
        this._overlayService.openWelcomeDcaSetupOverlay();
    }

    public navigateBilling(): void {
        this._webadminV2Service.openBilling();
    }
}
