export interface IItemPartNumbers {
    vendorPartNumber: string;
    OEM: string;
    aliases: string[];
}

export class ItemPartNumbers implements IItemPartNumbers {
    public vendorPartNumber: string = '';
    public OEM: string = '';
    public aliases: string[] = [];

    constructor(defaults?: Partial<IItemPartNumbers>) {
        if (defaults) {
            this.vendorPartNumber = defaults.vendorPartNumber || this.vendorPartNumber;
            this.OEM = defaults.OEM || this.OEM;
            this.aliases = defaults.aliases || this.aliases;
        }
    }
}
