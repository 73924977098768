export interface ConfirmMultipleModalConfig {
    title?: string;
    content?: string;
    secondaryContent?: string;
    buttons?: {
        confirm1?: string;
        confirm2?: string;
        cancel?: string;
    };
}

export const confirmMultipleModalDefaultConfig: ConfirmMultipleModalConfig = {
    title: $localize`Confirm Action`,
    content: $localize`Please confirm your action.`,
    secondaryContent: $localize`Do you wish to continue?`,
    buttons: {
        confirm1: $localize`Do this thing`,
        confirm2: $localize`Do this AND that thing`,
        cancel: $localize`:@@cancel:Cancel`
    }
};
