enum SNMPPrivacyProtocol {
    DES = 'DES',
    AES = 'AES',
    AES192 = 'AES192',
    AES256 = 'AES256',
    AES192C = 'AES192C',
    AES256C = 'AES256C'
}

namespace SNMPPrivacyProtocol {
    export function toString(type: SNMPPrivacyProtocol): string {
        return String(type);
    }

    export const asArray: SNMPPrivacyProtocol[] = [
        SNMPPrivacyProtocol.DES,
        SNMPPrivacyProtocol.AES,
        SNMPPrivacyProtocol.AES192,
        SNMPPrivacyProtocol.AES256,
        SNMPPrivacyProtocol.AES192C,
        SNMPPrivacyProtocol.AES256C
    ];
}

export { SNMPPrivacyProtocol };
