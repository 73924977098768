<h1 id="title">{{ title }}</h1>
<p>{{ description }}</p>
<mat-radio-group aria-labelledby="title" class="radioGroup" [(ngModel)]="selection">
    <mat-radio-button
        class="radioGroup-button"
        *ngFor="let option of options"
        [value]="option"
    >
        <ptkr-inline-meter-name-card
            [name]="option.name"
            [format]="option.format"
        ></ptkr-inline-meter-name-card>
    </mat-radio-button>
</mat-radio-group>
<div class="actionButtons" mat-dialog-options>
    <button class="actionButtons-button" mat-button color="accent" (click)="onCancel()">
        Cancel
    </button>
    <button
        class="actionButtons-button"
        mat-flat-button
        color="accent"
        [disabled]="!selection"
        (click)="onConfirm()"
    >
        Confirm
    </button>
</div>