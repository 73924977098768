import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IDeviceListTableQueryParams } from '@app/core/models/TableConfiguration';
import { Item } from '@libs/iso/core';
import { select, Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { DeviceService } from '@app/src/app/core/services/device.service';
import { NotificationService } from '@app/src/app/core/services/notification.service';
import { Observable } from 'rxjs';
import { Device } from '@app/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Component({
    selector: 'ptkr-bulk-device-update',
    templateUrl: './bulk-device-update.component.html',
    styleUrls: ['./bulk-device-update.component.scss']
})
export class BulkDeviceUpdateComponent implements OnInit {
    public csvContent: any;
    public csvData: Array<Partial<Device>>;
    public state: any = {
        panel1Open: true,
        panel2Open: false
    };

    constructor(
        private _store: Store<GlobalStore>,
        private _deviceService: DeviceService,
        private _notificationService: NotificationService,
        public dialogRef: MatDialogRef<BulkDeviceUpdateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { tableParams: IDeviceListTableQueryParams },
    ) {}

    ngOnInit(): void {}

    public exportCsv(): void {
        this._store.pipe(select(fromEntity.currentEntity), take(1)).subscribe(entity =>
            this._deviceService.getDevicesCsvByEntity(
                entity._id,
                undefined,
                this.data.tableParams.searchTerm,
                0,
                0,
                this.data.tableParams.sortBy,
                this.data.tableParams.sortOrder,
                coerceBooleanProperty(this.data.tableParams.includeChildren),
                coerceBooleanProperty(this.data.tableParams.excludeDisabled),
                coerceBooleanProperty(this.data.tableParams.excludeDroppedOff),
                coerceBooleanProperty(this.data.tableParams.localOnly)
            ).subscribe(
                res => {
                    const blob = new Blob([res], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.setAttribute('href', url);
                    link.setAttribute(
                        'download',
                        `devices-${new Date().toLocaleDateString('en-us').replace('/', '_')}.csv`
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                },
                err => this._notificationService.error(err)
            )
        );
    }

    public importData(): void {
        this._deviceService.bulkDeviceUpdate(this.csvData).subscribe(
            res => this.dialogRef.close(),
            err => {
                this.dialogRef.close();
                this._notificationService.error(err);
            }
        );
    }

    public onFileSelect(input: HTMLInputElement): void {
        const files = input.files;
        const content = this.csvContent;

        if (files && files.length) {
            const fileToRead = files[0];

            const fileReader = new FileReader();
            fileReader.onload = (event: any): void => {
                this.csvContent = event.target.result.replace('\n', '').replace('\r', '\n');

                const lines = this.csvContent.split(/\n(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
                const result = [];
                let headers = lines[0].split(',');

                // clean up the headers and remove any whitespace
                headers = headers.map(h => h.trim());

                for (let i = 1; i < lines.length; i++) {
                    const obj = {};

                    // split on the commas but ignore commas that are inside double quotes
                    let currentline = lines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

                    // replace the surrounding quotes when we extract the final value
                    currentline = currentline.map(l => l.replace(/"/g, ''));

                    for (let j = 0; j < headers.length; j++) {
                        obj[headers[j]] = currentline[j];
                    }

                    if (!!obj['_id']) {
                        result.push(obj);
                    }
                }

                this._handleCsvData(result);
            };

            fileReader.readAsText(fileToRead, 'UTF-8');
        }
    }

    private _handleCsvData(data: Array<any>): void {
        this.state.panel1Open = false;
        this.state.panel2Open = true;
        this.csvData = data;
    }
}
