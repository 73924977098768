<div mat-dialog-header class="header">
    <h2>Add device manually</h2>
</div>
<div mat-dialog-content class="content">
    <form [formGroup]="newDeviceGroup" (ngSubmit)="submit(newDeviceGroup)" class="search-bar-wrapper">
        <mat-form-field floatLabel="never" appearance="outline">
            <input matInput placeholder="Search devices" formControlName="search" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectDevice($event)">
                <mat-option disabled="true"><b>Suggested Printers</b></mat-option>
                <mat-option *ngFor="let d of deviceList$ | async" [value]="d">
                    {{ d.make + ' ' + d.model }}
                </mat-option>
            </mat-autocomplete>
            <mat-error [ptkrErrorControl]="newDeviceGroup.get('selectedDevice')"></mat-error>
        </mat-form-field>
        <mat-form-field floatLabel="never" appearance="outline">
            <input matInput placeholder="Device Name" formControlName="deviceName">
            <mat-error [ptkrErrorControl]="newDeviceGroup.get('deviceName')"></mat-error>
        </mat-form-field>
        <button mat-flat-button type="submit" matSuffix color="primary">
            Add Device
        </button>
    </form>
</div>
