<h1>Select Installs</h1>
<section>
    <p>
        The installs you select will scan for devices in the subnets you specified.
    </p>
</section>

<section class='contains-list'>
    <ptkr-data-list
        [rowSelectable]='true'
        [dataSource]='data'
        [columns]='cols'
        [(selectedData)]='selectedData'
        (allBoxesCheckedChange)='everythingIsSelected = $event'
    >
    </ptkr-data-list>
</section>

<!-- paginator has its own padding, doesn't need a container -->
<ptkr-pagination-panel
    [pageSizeOptions]='paginatorSizeOptions'
    [startingPageSize]='paginatorSize'
    [dataLength]='paginatorLength'
    [currentPage]='paginatorPageIndex'
    (currentPageChange)='onCurrentPageChange($event)'
    (pageSizeChange)='onPageSizeChange($event)'
>
</ptkr-pagination-panel>

<section class='form-section checkbox-section'>
    <mat-checkbox (change)='onCheckboxChange($event)'>
        Watch these IP addresses for new devices
        <a matTooltip='This option allows these IP Addresses to be saved to a
        list to be scanned every week for new devices. If you only want to
        scan these IP Addresses one time, leave this checkbox unchecked.'>
            <small>What's this?</small></a>
    </mat-checkbox>
</section>

<section class='action-bar section-bottom'>
    <button mat-button (click)='closeModal()' color='default'>Cancel</button>
    <div
        class='action-button-wrapper'
        matTooltip='Please enter at least one install'
        [matTooltipDisabled]='selectedData.size > 0 || everythingIsSelected'
    >
        <button mat-flat-button (click)='openConfirmationPanel()' color='accent'
                [disabled]='selectedData.size == 0 && !everythingIsSelected'>
            Confirm
        </button>
    </div>
</section>
