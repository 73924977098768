import { Directive, HostListener, Inject } from '@angular/core';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDateRangeInput } from '@angular/material/datepicker';
import * as moment from 'moment';
@Directive({
  selector: '[ptkrDateRangeInput]'
})
export class DateRangeInputDirective {

  constructor(
    @Inject(MAT_DATE_FORMATS) private matDateFormats: MatDateFormats, 
    private dateRangeInput: MatDateRangeInput<any>) { }

  @HostListener('copy', ['$event'])
  handleCopy(event: ClipboardEvent) {
    const dateFormat = this.matDateFormats.display.dateInput;
    const separator = this.dateRangeInput.separator;
    const startDate = this.dateRangeInput.value.start;
    const endDate = this.dateRangeInput.value.end;

    const dataToSave = moment(startDate).format(dateFormat) + separator + moment(endDate).format(dateFormat);
    event.clipboardData.setData('text/plain', dataToSave);

    event.preventDefault();
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    const dateValue = event.clipboardData.getData('text/plain');
    if(dateValue) {
      const [startDate, endDate] = dateValue.split(this.dateRangeInput.separator);

      this.dateRangeInput._startInput.ngControl.control.setValue(moment(startDate, this.matDateFormats.display.dateInput));
      this.dateRangeInput._endInput.ngControl.control.setValue(moment(endDate, this.matDateFormats.display.dateInput));

      event.preventDefault();
    }
  }
}

