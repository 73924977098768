import { Action } from '@ngrx/store';
import { Permission } from '@libs/iso/core';

export enum AuthActions {
    Login = '[auth] login',
    RestoreLogin = '[auth] restore login',
    CompleteLogin = '[auth] complete login',
    Logout = '[auth] logout',
    LoadPermissions = '[auth] load permissions',
    ClearPermissions = '[auth] clear permissions',
    StartImpersonation = '[auth] start impersonation',
    RestoreImpersonation = '[auth] restore impersonation',
    StopImpersonation = '[auth] stop impersonation'
}

export class LoginAction implements Action {
    public readonly type: AuthActions.Login = AuthActions.Login;
    public payload: string = '';
    constructor(_authToken: string) {
        this.payload = _authToken;
    }
}

export class RestoreLoginAction implements Action {
    public readonly type: AuthActions.RestoreLogin = AuthActions.RestoreLogin;
    public payload: { token: string; resolveFn: (value: any) => void };
    constructor(_authToken: string, resolveFn: (value: any) => void) {
        this.payload = {
            token: _authToken,
            resolveFn: resolveFn
        };
    }
}

export class CompleteLoginAction implements Action {
    public readonly type: AuthActions.CompleteLogin = AuthActions.CompleteLogin;
}

export class LogoutAction implements Action {
    public readonly type: AuthActions.Logout = AuthActions.Logout;
}

export class LoadPermissionsAction implements Action {
    public readonly type: AuthActions.LoadPermissions = AuthActions.LoadPermissions;
    public payload: Array<Permission> = [];
    constructor(_permissions: Array<Permission>) {
        this.payload = _permissions;
    }
}

export class ClearPermissionsAction implements Action {
    public readonly type: AuthActions.ClearPermissions = AuthActions.ClearPermissions;
}

export class StartImpersonationAction implements Action {
    public readonly type: AuthActions.StartImpersonation = AuthActions.StartImpersonation;
    public payload: string = '';
    constructor(_authToken: string) {
        this.payload = _authToken;
    }
}

export class RestoreImpersonationAction implements Action {
    public readonly type: AuthActions.RestoreImpersonation = AuthActions.RestoreImpersonation;
    public payload: { token: string; resolveFn: (value: any) => void };
    constructor(_authToken: string, resolveFn: (value: any) => void) {
        this.payload = {
            token: _authToken,
            resolveFn: resolveFn
        };
    }
}

export class StopImpersonationAction implements Action {
    public readonly type: AuthActions.StopImpersonation = AuthActions.StopImpersonation;
    public payload: string = '';
    constructor(_authToken: string) {
        this.payload = _authToken;
    }
}

export type AuthActionsType =
    | LoginAction
    | RestoreLoginAction
    | CompleteLoginAction
    | LogoutAction
    | LoadPermissionsAction
    | ClearPermissionsAction
    | StartImpersonationAction
    | RestoreImpersonationAction
    | StopImpersonationAction;
