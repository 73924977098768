import { IBaseModel, BaseModel } from '@libs/iso/core/models/BaseModel';
import { ObjectID } from 'bson';
import { ItemType, Color, QualityLevel } from '@libs/iso/core/enums';
import { ItemWarranty } from '@libs/iso/core/models/item/ItemWarranty';

export interface ICartItem extends IBaseModel {
    itemKey: ObjectID | string;
    acuId: string;
    entityKey: ObjectID | string;
    qty: number;
    cost: number;
    price: number;
    partNumber: string;
    color?: Color;
    yield?: number;
    qualityLevel?: QualityLevel;
    salesTax: number;
    type: ItemType;
    make: string;
    model: string;
    description: string;
    warranty: ItemWarranty[];
}

export class CartItem extends BaseModel implements ICartItem {
    public itemKey: ObjectID | string;
    public acuId: string;
    public entityKey: ObjectID | string;
    public qty: number;
    public cost: number;
    public price: number;
    public partNumber: string;
    public color: Color;
    public yield: number;
    public qualityLevel: QualityLevel;
    public salesTax: number;
    public type: ItemType;
    public make: string;
    public model: string;
    public description: string;
    public warranty: ItemWarranty[] = [];

    constructor(defaults?: Partial<ICartItem>) {
        super(defaults);
        if (defaults) {
            this.itemKey = defaults.itemKey || this.itemKey;
            this.acuId = defaults.acuId || this.acuId;
            this.entityKey = defaults.entityKey || this.entityKey;
            this.qty = defaults.qty || this.qty;
            this.cost = defaults.cost || this.cost;
            this.price = defaults.price || this.price;
            this.partNumber = defaults.partNumber || this.partNumber;
            this.color = defaults.color || this.color;
            this.yield = defaults.yield || this.yield;
            this.qualityLevel = defaults.qualityLevel || this.qualityLevel;
            this.salesTax = defaults.salesTax || this.salesTax;
            this.type = defaults.type || this.type;
            this.make = defaults.make || this.make;
            this.model = defaults.model || this.model;
            this.description = defaults.description || this.description;
            if (Array.isArray(defaults.warranty)) {
                for (const w of defaults.warranty) {
                    this.warranty.push(new ItemWarranty(w));
                }
            }
        }
    }

    public toDB(): Object {
        return {
            itemKey: new ObjectID(this.itemKey),
            entityKey: new ObjectID(this.entityKey),
            acuId: this.acuId,
            qty: this.qty,
            cost: this.cost,
            price: this.price,
            salesTax: this.salesTax,
            type: this.type,
            make: this.make,
            model: this.model,
            description: this.description,
            warranty: this.warranty
        };
    }
}
