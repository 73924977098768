import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WindowSize } from '@app/models';

@Injectable()
export class WindowSizeService {
    private _windowSizeSubject: BehaviorSubject<WindowSize> = new BehaviorSubject<WindowSize>(null);
    private _initialWindowSize: WindowSize;

    constructor() {}

    public newWindowSize(size: WindowSize): void {
        if (!this._initialWindowSize) {
            this._initialWindowSize = size;
        }
        this._windowSizeSubject.next(size);
    }

    public get windowSize(): Observable<WindowSize> {
        return this._windowSizeSubject.asObservable();
    }

    public get initialWindowSize(): WindowSize {
        return this._initialWindowSize;
    }
}
