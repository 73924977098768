import * as moment from 'moment';

export enum PeriodOverPeriodTypes {
    Day = '[PeriodOverPeriod] Day',
    Week = '[PeriodOverPeriod] Week',
    Month = '[PeriodOverPeriod] Month',
    Quarter = '[PeriodOverPeriod] Quarter',
    Year = '[PeriodOverPeriod] Year'
}

export namespace PeriodOverPeriodTypes {
    export function toDayOffset(pop: PeriodOverPeriodTypes): number {
        switch (pop) {
            case PeriodOverPeriodTypes.Day:
                return 1;
            case PeriodOverPeriodTypes.Week:
                return 7;
            case PeriodOverPeriodTypes.Month:
                return 30;
            case PeriodOverPeriodTypes.Quarter:
                return 90;
            case PeriodOverPeriodTypes.Year:
                return 465;
            default:
                return 0;
        }
    }

    export function buildOffsetRangeFromRange(
        period: PeriodOverPeriodTypes,
        numPeriods: number,
        startDate: Date,
        endDate: Date
    ): [Date, Date] {
        const offsetDays = PeriodOverPeriodTypes.toDayOffset(period) * numPeriods;
        const newStartDate = moment(startDate)
            .subtract(offsetDays, 'day')
            .toDate();
        const newEndDate = moment(endDate)
            .subtract(offsetDays, 'day')
            .toDate();
        return [newStartDate, newEndDate];
    }
}
