import { Action } from '@ngrx/store';
import {
    Cart,
    ShippingAddress,
    ShippingMethod,
    OrderPlacedResponse,
    UserPaymentMethod
} from '@libs/iso/core';
import { CartItemChangeModel } from '@app/models';

export enum CartActions {
    Clear = '[cart] clear',
    SelectCart = '[cart] select cart',
    RemoveCart = '[cart] remove cart',
    SelectShippingAddress = '[cart] select shipping address',
    SelectShippingMethod = '[cart] select shipping method',
    CompleteShipping = '[cart] complete shipping',
    SelectPayment = '[cart] select payment',
    CompletePayment = '[cart] complete payment',
    UpdateCartItem = '[cart] update cart item',
    UpdateEstimatedTax = '[cart] update estimated tax',
    InvalidTax = '[cart] invalid tax',
    StartCheckout = '[cart] start checkout',
    RecalculateOrder = '[cart] recalculate order',
    PlaceOrder = '[cart] place order',
    OrderComplete = '[cart] order complete',
    OrderFailure = '[cart] order failure'
}

export class ClearCartAction implements Action {
    public readonly type: CartActions.Clear = CartActions.Clear;
}

export class SelectCartAction implements Action {
    public readonly type: CartActions.SelectCart = CartActions.SelectCart;
    public payload: Cart;
    constructor(cartToSelect: Cart) {
        this.payload = cartToSelect;
    }
}

export class RemoveCartAction implements Action {
    public readonly type: CartActions.RemoveCart = CartActions.RemoveCart;
    public payload: string;
    constructor(cartIdToRemove: string) {
        this.payload = cartIdToRemove;
    }
}

export class SelectShippingAddressAction implements Action {
    public readonly type: CartActions.SelectShippingAddress = CartActions.SelectShippingAddress;
    public payload: ShippingAddress;
    constructor(shippingType: ShippingAddress) {
        this.payload = shippingType;
    }
}

export class SelectShippingMethodAction implements Action {
    public readonly type: CartActions.SelectShippingMethod = CartActions.SelectShippingMethod;
    public payload: ShippingMethod;
    constructor(shippingMethod: ShippingMethod) {
        this.payload = shippingMethod;
    }
}

export class CompleteShippingAction implements Action {
    public readonly type: CartActions.CompleteShipping = CartActions.CompleteShipping;
}

export class SelectPaymentAction implements Action {
    public readonly type: CartActions.SelectPayment = CartActions.SelectPayment;
    public payload: UserPaymentMethod;
    constructor(paymentMethod: UserPaymentMethod) {
        this.payload = paymentMethod;
    }
}

export class CompletePaymentAction implements Action {
    public readonly type: CartActions.CompletePayment = CartActions.CompletePayment;
}

export class UpdateCartItemAction implements Action {
    public readonly type: CartActions.UpdateCartItem = CartActions.UpdateCartItem;
    public payload: {
        cart: Cart;
        event: CartItemChangeModel;
    };
    constructor(cart: Cart, itemChange: CartItemChangeModel) {
        this.payload = {
            cart: cart,
            event: itemChange
        };
    }
}

export class StartCheckoutAction implements Action {
    public readonly type: CartActions.StartCheckout = CartActions.StartCheckout;
}

export class RecalculateOrderAction implements Action {
    public readonly type: CartActions.RecalculateOrder = CartActions.RecalculateOrder;
}

export class PlaceOrderAction implements Action {
    public readonly type: CartActions.PlaceOrder = CartActions.PlaceOrder;
}

export class OrderCompleteAction implements Action {
    public readonly type: CartActions.OrderComplete = CartActions.OrderComplete;
    public payload: OrderPlacedResponse;
    constructor(response: OrderPlacedResponse) {
        this.payload = response;
    }
}

export class OrderFailureAction implements Action {
    public readonly type: CartActions.OrderFailure = CartActions.OrderFailure;
}

export class UpdateEstimatedTaxAction implements Action {
    public readonly type: CartActions.UpdateEstimatedTax = CartActions.UpdateEstimatedTax;
    public payload: number;
    constructor(estimatedTax: number) {
        this.payload = estimatedTax;
    }
}

export class InvalidTaxAction implements Action {
    public readonly type: CartActions.InvalidTax = CartActions.InvalidTax;
    public payload: string;
    constructor(errorMessage: string) {
        this.payload = errorMessage;
    }
}

export type CartActionsType =
    | ClearCartAction
    | SelectCartAction
    | RemoveCartAction
    | SelectShippingAddressAction
    | SelectShippingMethodAction
    | CompleteShippingAction
    | SelectPaymentAction
    | CompletePaymentAction
    | UpdateCartItemAction
    | UpdateEstimatedTaxAction
    | InvalidTaxAction
    | StartCheckoutAction
    | RecalculateOrderAction
    | PlaceOrderAction
    | OrderCompleteAction
    | OrderFailureAction;
