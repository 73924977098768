import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EditLabelModalComponent } from '@app/shared/overlays/edit-label-modal/edit-label-modal.component';
import { DeleteLabelModalComponent } from '@app/shared/overlays';

@Component({
    selector: 'ptkr-label-editor',
    templateUrl: './label-editor.component.html',
    styleUrls: ['./label-editor.component.scss']
})
export class LabelEditorComponent implements OnInit {
    @Input('labels')
    public set labels( value: { [key: string]: string }) {
        const newLabels = new Array<{ name: string, value: string }>();
        for (const k in value) {
            newLabels.push({ name: k, value: value[k] });
        }
        this.labelsValue = newLabels;
    }
    public get labels(): { [key: string]: string } {
        const output: { [key: string]: string } = {};
        this.labelsValue.forEach(e => output[e.name] = e.value);
        return output;
    }
    public labelsValue: Array<{ name: string; value: string }> = [];

    @Input('showCancelButton')
    public showCancelButton: Boolean = false;

    @Output()
    public saveChanges: EventEmitter<{ [key: string]: string }> =
        new EventEmitter<{ [key: string]: string }>();

    @Output()
    public cancel: EventEmitter<void> = new EventEmitter<void>();

    public form: FormGroup;

    constructor(
        private _fb: FormBuilder,
        private _dialog: MatDialog,
        private _cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.form = this._fb.group({
            textbox: ''
        });
    }

    public editLabel(index: number): void {
        const name: string = this.labelsValue[index].name;
        const value: string = this.labelsValue[index].value;
        this._dialog
            .open(EditLabelModalComponent, {
                data: { title: $localize`Edit label`, name: name, value: value }
            })
            .afterClosed()
            .subscribe(res => {
                if (!!res) {
                    this.labelsValue[index] = res;
                    this.labelsValue = new Array(...this.labelsValue); // Needed to trigger change detection.
                    this._cd.detectChanges();
                }
            });
    }

    public deleteLabel(index: number): void {
        const name: string = this.labelsValue[index].name;
        const value: string = this.labelsValue[index].value;
        this._dialog
            .open(DeleteLabelModalComponent, { data: { name: name, value: value } })
            .afterClosed()
            .subscribe(res => {
                // Spencer 9/3/20 - We want to completely remove the property from the object so it
                // will not be there in the DB when we update it.
                if (res) {
                    this.labelsValue.splice(index, 1);
                    this.labelsValue = new Array(...this.labelsValue); // Needed to trigger change detection.
                    this._cd.detectChanges();
                }
            });
    }

    public addLabel(): void {
        this._dialog
            .open(EditLabelModalComponent, {
                data: { title: $localize`Add label`, name: '', value: '' }
            })
            .afterClosed()
            .subscribe(res => {
                if (!!res) {
                    this.labelsValue.push(res);
                    this.labelsValue = new Array(...this.labelsValue); // Needed to trigger change detection.
                    this._cd.detectChanges();
                }
            });
    }

    public save(): void {
        this.saveChanges.emit(this.labels);
    }

    public close(): void {
        this.cancel.emit();
    }
}
