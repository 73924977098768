export enum SupplyTypeCategory {
    Toners = '[SupplyTypeCategory] Toners',
    Inks = '[SupplyTypeCategory] Inks',
    WasteAndCollection = '[SupplyTypeCategory] WasteAndCollection',
}

export namespace SupplyTypeCategory {
    export function toString(type: SupplyTypeCategory): string {
        switch (type) {
            case SupplyTypeCategory.Toners:
                return 'Toners';
            case SupplyTypeCategory.Inks:
                return 'Inks';
            case SupplyTypeCategory.WasteAndCollection:
                return 'Waste and Collection Units';
            default:
                return '';
        }
    }
    export const all: SupplyTypeCategory[] = [
        SupplyTypeCategory.Toners,
        SupplyTypeCategory.Inks,
        SupplyTypeCategory.WasteAndCollection
    ];
}
