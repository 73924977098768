import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewJob, PaginatedList } from '@libs/iso/core';
import { Body, Get, HttpService, Path, Post, Query } from '@libs/web/http';
import { ObjectId } from 'bson';
import { IJobHistory } from '@libs/iso/core/models/rts/NewJob';

@Injectable()
export class JobService extends HttpService {
    // constructor(protected _http: HttpClient, private _store: Store<GlobalStore>) {
    //     super(_http);
    // }

    @Get('/job/entity/{id}')
    public getActiveJobsByEntity(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Get('/job/getJobByInstallKeyAndJobName')
    public getJobByInstallKeyAndJobName(
        @Query('installKey') installKey: string,
        @Query('name') name: string
    ): Observable<NewJob> {
        return null;
    }

    @Get('/job/getJobsByInstall')
    public getJobsByInstall(
        @Query('installKey') installKey: string | ObjectId,
        @Query('limit') limit: number
    ): Observable<Array<NewJob>> {
        return null;
    }

    @Post('/job/getJobsByInstallList')
    public getJobsByInstallList(
        @Body('ids') installKeys: string[],
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<PaginatedList<NewJob>> {
        return null;
    }

    @Get('/job/getConcatinatedJobLogsByInstall')
    public getConcatinatedJobLogsByInstall(
        @Query('installKey') installKey: string | ObjectId,
        @Query('limit') limit: number
    ): Observable<Array<IJobHistory>> {
        return null;
    }

    @Get('/job/{id}')
    public getJobById(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Get('/job/{id}/logs')
    public getJobLogs(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Get('/job/{id}/proxyAuditLog')
    public getProxyAuditLog(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Post('/job/duplicate/{id}')
    public duplicateJobById(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Post('/job/delete')
    public deleteJobsById(@Body('ids') ids: Array<string>): Observable<any> {
        return null;
    }

    @Post('/job/entity/{id}/delete')
    public deleteJobsByEntity(@Path('id') id: string): Observable<any> {
        return null;
    }

    @Get('/job/getCollectAndUploadDGIsJobsByItemKey')
    public getCollectAndUploadDGIsJobsByItemKey(
        @Query('id') itemKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<PaginatedList<NewJob>> {
        return null;
    }

    @Get('/job/getUploadDeviceSNMPDumpBySimilarDevice')
    public getUploadDeviceSNMPDumpBySimilarDevice(
        @Query('id') deviceKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number
    ): Observable<PaginatedList<NewJob>> {
        return null;
    }

    @Get('/job/getMostRecentEvaluateAgainstDeviceJob')
    public getMostRecentEvaluateAgainstDeviceJob(
        @Query('id') installKey: string,
    ): Observable<NewJob | undefined> {
        return null;
    }
}
