import { InstallPropertyType } from '@libs/iso/core/enums/InstallPropertyType';

export interface IInstallPropertyWithOnlineOfflineCount {
    property: string;
    count: number;
    onlineCount: number;
    offlineCount: number;
}

export interface IInstallCountByProperty {
    property: IInstallPropertyWithOnlineOfflineCount[];
    type: InstallPropertyType;
}

export class InstallCountByProperty implements IInstallCountByProperty {
    public property: IInstallPropertyWithOnlineOfflineCount[] = [];
    public type: InstallPropertyType;

    constructor(params?: IInstallCountByProperty) {
        if (params) {
            this.property = params.property
                .map(p => {
                    if (p.property == null) {
                        p.property = 'Unknown';
                    }
                    return p;
                })
                .sort((a, b) => (a.count > b.count ? 1 : -1));
            this.type = params.type;
        }
    }

    public series(): Array<any> {
        switch (this.type) {
            case InstallPropertyType.OS:
                return [
                    {
                        name: 'Offline',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(0, 0, 0, 0.5)'
                        },
                        itemStyle: {
                            color: '#FF7474'
                        },
                        data: this.property.map(p => p.offlineCount)
                    },
                    {
                        name: 'Online',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        itemStyle: {
                            color: '#6EDD9D'
                        },
                        data: this.property.map(highlightUnsupportedOS(c => c.onlineCount))
                    }
                ];
            default:
                return [
                    {
                        name: 'Offline',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(0, 0, 0, 0.5)'
                        },
                        itemStyle: {
                            color: '#FF7474'
                        },
                        data: this.property.map(m => m.offlineCount)
                    },
                    {
                        name: 'Online',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        itemStyle: {
                            color: '#6EDD9D'
                        },
                        data: this.property.map(m => m.onlineCount)
                    }
                ];
        }
    }
}

const unsupportedOS = [
    'Windows 7',
    'Windows 8',
    'Windows Embedded 8',
    'Windows Embedded Standard 7',
    'Windows Server 2008',
    'Windows Server 2012',
    'Windows Small Business Server 2011 Standard Service Pack 1',
    'Windows Embedded Standard',
    'Windows Web Server 2008',
    'Windows Storage Server 2008'
];

function highlightUnsupportedOS(
    getCount: (count: IInstallPropertyWithOnlineOfflineCount) => number
): (count: IInstallPropertyWithOnlineOfflineCount) => any {
    return (count: IInstallPropertyWithOnlineOfflineCount): any => {
        const isUnsupported = unsupportedOS.some(os =>
            count.property.toLowerCase().includes(os.toLowerCase())
        );
        if (isUnsupported) {
            return {
                label: {
                    position: 'right',
                    color: '#ffffff',
                    show: true,
                    backgroundColor: '#f6ab5a',
                    borderRadius: 2,
                    padding: [1, 2],
                    formatter: (a): string => {
                        return 'Unsupported';
                    }
                },
                value: getCount(count)
            };
        }
        return getCount(count);
    };
}
