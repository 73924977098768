import { InjectionToken } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

export const NOTIFICATION_OVERLAY_DATA = new InjectionToken<any[]>('NOTIFICATION_OVERLAY_DATA');

export class NotificationOverlayRef {
    constructor(private _overlayRef: OverlayRef) {}

    public close(): void {
        this._overlayRef.dispose();
    }
}
