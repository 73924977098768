import * as _ from 'lodash';

interface EntityWithChildren {
    name: string;
    value?: any;
    children?: EntityWithChildren[];
}

interface HierarchyTraversalResult {
    _id: string;
    parentEntityKey: string;
    name: string;
}

export interface IEntityHierarchy {
    hierarchy: EntityWithChildren[];
}

export class EntityHierarchy implements IEntityHierarchy {
    public hierarchy: EntityWithChildren[];
    constructor(params?: IEntityHierarchy) {
        if (!!params) {
            this.hierarchy = params.hierarchy;
        }
    }

    /**
     * Creates a tree data structure from a flat array of entities based on the _id and parentEntityKey
     * of each entity.
     * @param {string} root - the entity key of the hierarchical root, all entities in the results
     * array should have a parent entity key that exists in the results array except for this root
     * @param {HierarchyTraversalResult[]} results - an array of entity names, _id's and parentEntityKey's
     * ordered by creation. It is critical that all parent entities occur before child entities in
     * this array.
     * @returns {EntityHierarchy} - a self reference
     */
    public fromHierarchyTraversal(
        root: string,
        results: HierarchyTraversalResult[]
    ): EntityHierarchy {
        this.hierarchy = this.listToTree(results, root);
        return this;
    }

    public withValues(): EntityWithChildren[] {
        return recursiveApplyValue(this.hierarchy);
    }

    /**
     * Fair warning, this breaks the rules of typescript but I'm too lazy to make it type safe.
     * @param {any} list - The flat array of entities
     * @param {any} root - The root entity ID
     * @returns {EntityWithChildren[]} a tree structure with the name of the entity and an array
     * of child entities.
     */
    public listToTree(list, root): EntityWithChildren[] {
        const map = {};
        const roots: EntityWithChildren[] = [];
        let node, i;
        let rootName: string;
        for (i = 0; i < list.length; i += 1) {
            map[list[i]._id] = i; // initialize the map
            list[i].children = []; // initialize the children
            if (list[i].value == null) {
                list[i].value = 0;
            }
            if (list[i]._id === root) {
                rootName = list[i].name;
            }
        }
        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node._id === root) {
                continue;
            }
            if (node.parentEntityKey !== root) {
                // if you have dangling branches check that map[node.parentId] exists
                if (list[map[node.parentEntityKey]] != null) {
                    list[map[node.parentEntityKey]].value += node.value;
                    list[map[node.parentEntityKey]].children.push({
                        name: node.name,
                        value: node.value,
                        children: []
                    });
                }
            } else {
                roots.push(node);
            }
        }

        return [
            {
                name: rootName,
                children: roots,
                value: roots.map(r => r.value).reduce((acc, next) => (acc += next), 0)
            }
        ];
    }
}

function recursiveApplyValue(hierarchy: EntityWithChildren[]): EntityWithChildren[] {
    return hierarchy.map(h => {
        h.value = 1;
        if (h.children.length > 0) {
            h.children = recursiveApplyValue(h.children);
        }
        return h;
    });
}
