<div class="header">
    <h2>Pages printed by {{ PagesByMonthReportType.toString(type) }}</h2>
    <mat-form-field appearance="outline" class="limit-control no-hint" floatLabel="never">
        <mat-select placeholder="Limit" [formControl]="limitControl">
            <mat-option *ngFor="let l of limitOptions" [value]="l"> Top {{ l }} </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="sub-header">
    <p>
        Total pages printed: <strong> {{ totalPages | number: '1.0-0' }} </strong>
    </p>
</div>
<div class="chart-container" *ngIf="!!chartData && chartData.length > 0">
    <ngx-charts-bar-vertical-stacked
        [results]="chartData"
        [customColors]="customColors"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="false"
        [showXAxisLabel]="false"
        [showYAxisLabel]="true"
        [xAxisLabel]="PagesByMonthReportType.toString(type)"
        yAxisLabel="Pages printed"
        (select)="itemSelected($event)"
    >
        <ng-template #tooltipTemplate let-model="model">
            <dl class='customTooltip'>
                <b>{{ model.name || 'n/a'}}:</b>  {{ model.value || 'n/a' }}<br>
                <ng-container *ngFor="let e of model.extra">
                    <b>{{ e.name }}:</b>  {{ e.value || '-' }}<br>
                </ng-container>
            </dl>
        </ng-template>
    </ngx-charts-bar-vertical-stacked>
</div>
<div *ngIf="!data || data.docs.length <= 0" class="no-data">
    <p>No data yet</p>
</div>
