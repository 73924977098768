import {
    IPaginationConfiguration,
    PaginationConfiguration
} from '@libs/iso/core/models/PaginationConfiguration';
import {
    ISortConfiguration,
    SortConfiguration,
    SortDirection,
    toSortDirection
} from '@libs/iso/core/models/SortConfiguration';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TableColumnSelector } from './TableColumnSelector';
import { Params } from '@angular/router';
import { Moment } from 'moment';
import * as moment from 'moment/moment';

export interface ITableConfiguration {
    pagination: IPaginationConfiguration;
    sort: ISortConfiguration;
    searchFilter: string;
    columns: TableColumnSelector;

    // Optionally subscribe to this subject to know when the data should be
    // updated. Events are fired whenever the parameters change.
    onChange$: Subject<void>;
}

export interface ITableQueryParams {
    searchTerm: string;
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: number;
    includeChildren?: string | boolean;
}

export const DefaultTableQueryParams: ITableQueryParams = {
    limit: 15,
    page: 1,
    searchTerm: '',
    sortBy: '',
    sortOrder: 0
};

export class TableConfiguration<T extends ITableQueryParams = ITableQueryParams>
    implements ITableConfiguration {
    public pagination: PaginationConfiguration = new PaginationConfiguration();
    public sort: SortConfiguration = new SortConfiguration();
    public searchFilter: string = '';
    public includeChildren?: boolean;
    public columns: TableColumnSelector = new TableColumnSelector();
    public onChange$: Subject<void> = new Subject();
    public changes$: Subject<T> = new Subject();
    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public storageId: string = null;
    public storage?: Storage;

    constructor(props?: Partial<ITableConfiguration>) {
        if (!!props) {
            this.pagination = new PaginationConfiguration(props.pagination) || this.pagination;
            this.sort = new SortConfiguration(props.sort) || this.sort;
            this.searchFilter = props.searchFilter || this.searchFilter;
        }
        this.includeChildren = coerceBooleanProperty(localStorage.getItem('globalIncludeChildren'));
    }

    public update(addins?: { [key: string]: any }): void {
        this.pagination.setPageIndex(1);
        this._update(addins);
    }

    public updateKeepPage(addins?: { [key: string]: any }): void {
        this._update(addins);
    }

    private _update(addins?: { [key: string]: any }): void {
        this.onChange$.next();
        this.changes$.next(this.toQueryParams(addins));
    }

    // onPageChange is a function that can be called directly from the (currentPageChange) view
    // bound function. It automatically calls a registered update handler.
    public onPageChanged(index: number): void {
        this.pagination.setPageIndex(index);
        this.updateKeepPage();
    }

    // onPageSizeChange is a function that can be called directly from the (pageSizeChange) view
    // bound function. It automatically calls a registered update handler.
    public onPageSizeChanged(size: number): void {
        this.pagination.setPageSize(size);
        this.update();
    }

    // onFilterChanged updates the table configuration filter and calls the update callback
    public onFilterChanged(filter: string): void {
        this.searchFilter = filter;
        this.update();
    }

    public onIncludeChildrenChanged(includeChildren: boolean): void {
        this.includeChildren = includeChildren;
        localStorage.setItem('globalIncludeChildren', includeChildren.toString());
        this.update();
    }

    // onSortChanged updates the table configuration sort and calls the update callback
    public onSortChanged(sort: { active: string; direction: SortDirection }): void {
        this.sort.setSortBy(sort.active);
        this.sort.setSortDirection(toSortDirection(sort.direction));
        this.update();
    }

    public onAddinChange(addins: { [key: string]: any }): void {
        this.update(addins);
    }

    /**
     * Returns and parses the configuration for this TableConfig. If a configuration cannot be found\
     * a null value is returned.
     * @returns {any | null} - The parsed configuration if available.
     */
    public getFromLocalStorage(): any | null {
        const configStr = this.storage.getItem(this.storageId);
        if (configStr == null || configStr === '') {
            return null;
        }
        return JSON.parse(configStr);
    }

    // tslint:disable-next-line:valid-jsdoc
    /**
     * Initializes the TableConfiguration from the local storage. This process is usually run before
     * initializing the TableConfiguration from query parameters -- i.e. we give priority to the query
     * parameters over the local storage parameters.
     * @param extender - The extender allows classes that extend this class to handle their own property
     * initialization and extraction from local storage config values. The extender is called after
     * the columns are loaded and before the reactive update is fired allowing a custom implementation
     * of the local storage initialization. This is especially useful in cases where the extending
     * class has additional properties not found on this class that need to be extracted from the
     * local storage and saved to the instance of the class.
     * @returns {void}
     */
    public initializeFromStorage(extender?: (config: any) => void): void {
        if (this.storage == null) {
            return;
        }
        const config = this.getFromLocalStorage();
        if (config == null) {
            return;
        }

        // If we found this property, this means that we've seen the table before and we at least
        // have a default configuration in the table. We start by disabling all the OPTIONAL
        // columns in the table, and then iterate over the columns that are supposed to be enabled
        // using this property. This fixes an issue where default enabled columns would not stay
        // disabled, but does so in a way that doesn't disable required columns.
        if (config.hasOwnProperty('columns')) {
            this.columns.getOptionalColumnNames().map(c => this.columns.disableColumn(c));
            for (const c of config.columns) {
                this.columns.enableColumn(c);
            }
        }
        if (config.hasOwnProperty('includeChildren')) {
            this.includeChildren = config.includeChildren;
        }
        if (extender != null) {
            extender(config);
        }
        this.update();
    }

    // tslint:disable-next-line:valid-jsdoc
    /**
     * Connects a storage implementation to this instance of the TableConfiguration.
     * @param {string} tableIdentifier - The storage key where the configuration for this table
     * can be found in the storage instance.
     * @param {Storage} storage - The storage instance to save and load configuration to and from.
     * @param onChanges - A callback to fire whenever a change to this instance of TableConfiguration
     * is fired. The callback should be used to save whatever properties you'd like to the storage
     * instance. The idea behind this callback is that classes that extend this class can use the
     * callback to save their own properties to the storage instance.
     * @returns {void}
     */
    public connectStorage(
        tableIdentifier: string,
        storage: Storage,
        onChanges: (config: T) => void = (config: T): void => {}
    ): void {
        this.storage = storage;
        this.storageId = tableIdentifier;
        this.initializeFromStorage();
        this.columns.changes$.subscribe(columns => {
            const config = this.getFromLocalStorage();
            this.storage.setItem(
                this.storageId,
                JSON.stringify({
                    ...config,
                    columns
                })
            );
        });
        this.changes$.subscribe(changes => {
            const config = this.getFromLocalStorage();
            this.storage.setItem(
                this.storageId,
                JSON.stringify({
                    ...config,
                    sortBy: changes.sortBy,
                    sortOrder: changes.sortOrder,
                    includeChildren: changes.includeChildren
                })
            );
        });
        this.changes$.subscribe(config => {
            if (onChanges != null) {
                onChanges(config);
            }
        });
    }

    /**
     * Connects this TableConfiguration to a query parameters provider and loads the defaults from
     * the query parameters.
     * @param {Observable<Params>} params$ - The observable to obtain query parameters from.
     * @returns {void}
     */
    public connectQueryParams(params$: Observable<Params>): void {
        params$.subscribe(params => this.fromQueryParams(params as T));
    }

    public toQueryParams(addins?: { [key: string]: any }): T {
        let params: any = {
            searchTerm: this.searchFilter,
            page: this.pagination.currentState.pageIndex,
            limit: this.pagination.currentState.limit,
            sortBy: this.sort.by,
            sortOrder: this.sort.order
        };
        if (this.includeChildren) {
            params['includeChildren'] = this.includeChildren;
        }
        if (addins) {
            params = {
                ...params,
                ...addins
            };
        }
        return params;
    }

    public fromQueryParams(params: T): void {
        if (params.sortBy) {
            this.sort.by = params.sortBy;
        }
        if (params.sortOrder) {
            this.sort.order = params.sortOrder;
        }
        if (params.page) {
            this.pagination.currentState.pageIndex = params.page;
        }
        if (params.limit) {
            this.pagination.currentState.limit = params.limit;
        }
        if (params.searchTerm) {
            this.searchFilter = params.searchTerm;
        }
        if (params.includeChildren) {
            this.includeChildren = coerceBooleanProperty(params.includeChildren);
        }
    }
}

export interface IInstallListTableQueryParams extends ITableQueryParams {
    excludeDisabled: string | boolean;
}

export class InstallListTableConfiguration extends TableConfiguration<
    IInstallListTableQueryParams
> {
    public excludeDisabled: boolean = true;

    constructor(props?: ITableConfiguration) {
        super(props);
    }

    public onExcludeDisabled(excludeDisabled: boolean): void {
        this.excludeDisabled = excludeDisabled;
        this.update();
    }

    public toQueryParams(addins?: { [key: string]: any }): IInstallListTableQueryParams {
        return super.toQueryParams({
            ...addins,
            // Append on our custom query parameters
            excludeDisabled: this.excludeDisabled
        });
    }

    public fromQueryParams(params: IInstallListTableQueryParams): void {
        super.fromQueryParams(params);
        if (params.excludeDisabled) {
            this.excludeDisabled = coerceBooleanProperty(params.excludeDisabled);
        }
    }

    public initializeFromStorage(extender?: (config: any) => void): void {
        // We're going to provide a custom extender here that will allow us to initialize properties on
        // this device list specific table configuration from the local storage configuration.
        super.initializeFromStorage((config): void => {
            if (config.excludeDisabled != null) {
                this.excludeDisabled = config.excludeDisabled;
            }
            if (extender != null) {
                extender(config);
            }
        });
    }

    public connectStorage(
        tableIdentifier: string,
        storage: Storage,
        extender?: (config: IInstallListTableQueryParams) => void
    ): void {
        super.connectStorage(
            tableIdentifier,
            storage,
            // Whenever this configuration changes, load the current state of the local storage
            // data for this configuration and extend it with the changes that fired this callback.
            (config: IInstallListTableQueryParams): void => {
                const current = this.getFromLocalStorage();
                this.storage.setItem(
                    this.storageId,
                    JSON.stringify({
                        ...current,
                        excludeDisabled: config.excludeDisabled
                    })
                );
                if (extender != null) {
                    extender(config);
                }
            }
        );
    }
}

export interface IDeviceListTableQueryParams extends ITableQueryParams {
    excludeDisabled: string | boolean;
    excludeDroppedOff: string | boolean;
    localOnly: string | boolean;
}

export class DeviceListTableConfiguration extends TableConfiguration<IDeviceListTableQueryParams> {
    public excludeDisabled: boolean = true;
    public excludeDroppedOff: boolean = true;
    public localOnly: boolean = false;

    constructor(props?: ITableConfiguration) {
        super(props);
    }

    public onExcludeDisabled(excludeDisabled: boolean): void {
        this.excludeDisabled = excludeDisabled;
        this.update();
    }

    public onExcludeDroppedOff(excludeDroppedOff: boolean): void {
        this.excludeDroppedOff = excludeDroppedOff;
        this.update();
    }

    public onLocalOnly(localOnly: boolean): void {
        this.localOnly = localOnly;
        this.update();
    }

    public toQueryParams(addins?: { [key: string]: any }): IDeviceListTableQueryParams {
        return super.toQueryParams({
            ...addins,
            // Append on our custom query parameters
            excludeDisabled: this.excludeDisabled,
            excludeDroppedOff: this.excludeDroppedOff,
            localOnly: this.localOnly
        });
    }

    public fromQueryParams(params: IDeviceListTableQueryParams): void {
        super.fromQueryParams(params);
        if (params.excludeDisabled) {
            this.excludeDisabled = coerceBooleanProperty(params.excludeDisabled);
        }
        if (params.excludeDroppedOff) {
            this.excludeDroppedOff = coerceBooleanProperty(params.excludeDroppedOff);
        }
        if (params.localOnly) {
            this.localOnly = coerceBooleanProperty(params.localOnly);
        }
    }

    public initializeFromStorage(extender?: (config: any) => void): void {
        // We're going to provide a custom extender here that will allow us initialize properties on
        // this device list specific table configuration from the local storage configuration.
        super.initializeFromStorage((config): void => {
            if (config.excludeDisabled != null) {
                this.excludeDisabled = config.excludeDisabled;
            }
            if (config.excludeDroppedOff != null) {
                this.excludeDroppedOff = config.excludeDroppedOff;
            }
            if (config.localOnly != null) {
                this.localOnly = config.localOnly;
            }
            if (extender != null) {
                extender(config);
            }
        });
    }

    public connectStorage(
        tableIdentifier: string,
        storage: Storage,
        extender?: (config: IDeviceListTableQueryParams) => void
    ): void {
        super.connectStorage(
            tableIdentifier,
            storage,
            // Whenever this configuration changes, load the current state of the local storage
            // data for this configuration and extend it with the changes that fired this callback.
            (config: IDeviceListTableQueryParams): void => {
                const current = this.getFromLocalStorage();
                this.storage.setItem(
                    this.storageId,
                    JSON.stringify({
                        ...current,
                        excludeDisabled: config.excludeDisabled,
                        excludeDroppedOff: config.excludeDroppedOff,
                        localOnly: config.localOnly
                    })
                );
                if (extender != null) {
                    extender(config);
                }
            }
        );
    }
}

export interface IDeviceAlertsListTableQueryParams extends ITableQueryParams {
    includeResolved: string | boolean;
    serviceAlertsOnly: boolean;
    startDate: Moment;
    endDate: Moment;
}

export class DeviceAlertsListTableConfiguration extends TableConfiguration<
    IDeviceAlertsListTableQueryParams
> {
    public includeResolved: boolean;
    public serviceAlertsOnly: boolean;
    public startDate: Moment = moment().subtract(90, 'days');
    public endDate: Moment = moment();

    constructor(props?: ITableConfiguration) {
        super(props);
    }

    public onIncludeResolved(includeResolved: boolean): void {
        this.includeResolved = includeResolved;
        this.update();
    }

    public onServiceAlertsOnly(serviceAlertsOnly: boolean): void {
        this.serviceAlertsOnly = serviceAlertsOnly;
        this.update();
    }

    public onSetDateRange(start: Moment, end: Moment): void {
        this.startDate = start;
        this.endDate = end;
        this.update();
    }

    public toQueryParams(addins?: { [key: string]: any }): IDeviceAlertsListTableQueryParams {
        return super.toQueryParams({
            ...addins,
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString(),
            includeResolved: this.includeResolved,
            serviceAlertsOnly: this.serviceAlertsOnly
        });
    }

    public fromQueryParams(params: IDeviceAlertsListTableQueryParams): void {
        super.fromQueryParams(params);
        if (params.includeResolved) {
            this.includeResolved = coerceBooleanProperty(params.includeResolved);
        }
        if (params.serviceAlertsOnly) {
            this.serviceAlertsOnly = coerceBooleanProperty(params.serviceAlertsOnly);
        }
        if (params.startDate) {
            this.startDate = moment(params.startDate);
        }
        if (params.endDate) {
            this.endDate = moment(params.endDate);
        }
    }

    public initializeFromStorage(extender?: (config: any) => void): void {
        // We're going to provide a custom extender here that will allow us initialize properties on
        // this device list specific table configuration from the local storage configuration.
        super.initializeFromStorage((config): void => {
            if (config.includeResolved != null) {
                this.includeResolved = config.includeResolved;
            }
            if (config.serviceAlertsOnly != null) {
                this.serviceAlertsOnly = config.serviceAlertsOnly;
            }
            if (extender != null) {
                extender(config);
            }
        });
    }

    public connectStorage(
        tableIdentifier: string,
        storage: Storage,
        extender?: (config: IDeviceAlertsListTableQueryParams) => void
    ): void {
        super.connectStorage(
            tableIdentifier,
            storage,
            // Whenever this configuration changes, load the current state of the local storage
            // data for this configuration and extend it with the changes that fired this callback.
            (config: IDeviceAlertsListTableQueryParams): void => {
                const current = this.getFromLocalStorage();
                this.storage.setItem(
                    this.storageId,
                    JSON.stringify({
                        ...current,
                        includeResolved: config.includeResolved,
                        serviceAlertsOnly: config.serviceAlertsOnly
                    })
                );
                if (extender != null) {
                    extender(config);
                }
            }
        );
    }

    public nFilters(): number {
        let n = 0;
        if (this.includeResolved) {
            n++;
        }
        if (this.serviceAlertsOnly) {
            n++;
        }
        return n;
    }
}

function coerceBooleanProperty(value: any): boolean {
    return value != null && `${value}` !== 'false';
}

export function getTableConfigurationFromStorage<T>(name: string): T {
    return JSON.parse(localStorage.getItem(name));
}

export interface ISuppliesListTableQueryParams extends ITableQueryParams {
    includeReplaced: string | boolean;
    includeFromNonManagedDevices: string | boolean;
    replacedStartDate?: Date;
    replacedEndDate?: Date;
}

export class SuppliesListTableConfiguration extends TableConfiguration<ISuppliesListTableQueryParams> {
    public includeReplaced: boolean = true;
    public includeFromNonManagedDevices: boolean = false;
    public replacedStartDate: Moment = moment().subtract(1, 'month');
    public replacedEndDate: Moment = moment();

    constructor(props?: ITableConfiguration) {
        super(props);
    }

    public onIncludeReplaced(includeReplaced: boolean): void {
        this.includeReplaced = includeReplaced;
        this.update();
    }

    public onIncludeFromNonManagedDevices(value: boolean): void {
        this.includeFromNonManagedDevices = value;
        this.update();
    }

    public onSetDateRange(start: Moment, end: Moment): void {
        this.replacedStartDate = start;
        this.replacedEndDate = end;
        this.update();
    }

    public toQueryParams(addins?: { [key: string]: any }): ISuppliesListTableQueryParams {
        return super.toQueryParams({
            ...addins,
            // Append on our custom query parameters
            includeReplaced: this.includeReplaced,
            includeFromNonManagedDevices: this.includeFromNonManagedDevices,
            replacedStartDate: this.replacedStartDate?.toISOString(),
            replacedEndDate: this.replacedEndDate?.toISOString(),
        });
    }

    public fromQueryParams(params: ISuppliesListTableQueryParams): void {
        super.fromQueryParams(params);
        if (params.includeReplaced) {
            this.includeReplaced = coerceBooleanProperty(params.includeReplaced);
        }
        if (params.includeFromNonManagedDevices) {
            this.includeFromNonManagedDevices = coerceBooleanProperty(params.includeFromNonManagedDevices);
        }
        if (params.replacedStartDate) {
            this.replacedStartDate = moment(params.replacedStartDate);
        }
        if (params.replacedEndDate) {
            this.replacedEndDate = moment(params.replacedEndDate);
        }
    }

    public initializeFromStorage(extender?: (config: any) => void): void {
        // We're going to provide a custom extender here that will allow us initialize properties on
        // this device list specific table configuration from the local storage configuration.
        super.initializeFromStorage((config): void => {
            if (config.includeReplaced != null) {
                this.includeReplaced = config.includeReplaced;
            }
            if (config.includeFromNonManagedDevices != null) {
                this.includeFromNonManagedDevices = config.includeFromNonManagedDevices;
            }
            if (config.replacedStartDate != null) {
                this.replacedStartDate = moment(config.replacedStartDate);
            }
            if (config.replacedEndDate != null) {
                this.replacedEndDate = moment(config.replacedEndDate);
            }
            if (extender != null) {
                extender(config);
            }
        });
    }

    public connectStorage(
        tableIdentifier: string,
        storage: Storage,
        extender?: (config: ISuppliesListTableQueryParams) => void
    ): void {
        super.connectStorage(
            tableIdentifier,
            storage,
            // Whenever this configuration changes, load the current state of the local storage
            // data for this configuration and extend it with the changes that fired this callback.
            (config: ISuppliesListTableQueryParams): void => {
                const current = this.getFromLocalStorage();
                this.storage.setItem(
                    this.storageId,
                    JSON.stringify({
                        ...current,
                        includeReplaced: config.includeReplaced,
                        includeFromNonManagedDevices: config.includeFromNonManagedDevices,
                        replacedStartDate: config.replacedStartDate,
                        replacedEndDate: config.replacedEndDate
                    })
                );
                if (extender != null) {
                    extender(config);
                }
            }
        );
    }
}
