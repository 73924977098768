import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PaginatedList, AdminCartListItem, ICommissions, TimeFrame } from '@libs/iso/core';
import { Injectable } from '@angular/core';
import { OrderService } from '@app/services';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { GlobalStore } from '@app/state/store';
import { Store } from '@ngrx/store';

@Injectable()
export class AdminCommissionsResolver
    implements Resolve<PaginatedList<AdminCartListItem> & ICommissions> {
    constructor(private _orderService: OrderService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<PaginatedList<AdminCartListItem> & ICommissions> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        const initQParams = route.queryParams;
        const defaultLimit = 10;
        const params = [
            coerceNumberProperty(initQParams.page) || 1,
            coerceNumberProperty(initQParams.limit) || defaultLimit,
            initQParams.sort,
            coerceNumberProperty(initQParams.sortOrder) as 1 | -1,
            initQParams.searchTerm,
            initQParams.timeframe || TimeFrame.CurrentPayPeriod
        ];

        if (
            initQParams.timeframe === TimeFrame.Custom &&
            initQParams.startDate &&
            initQParams.endDate
        ) {
            params.push(initQParams.startDate);
            params.push(initQParams.endDate);
        }

        console.log('resolve admin commissions');

        return this._orderService.getAdminCommissions(...params).pipe(
            catchError(err => {
                console.error(err);
                return of(new PaginatedList<AdminCartListItem>());
            })
        );
    }
}
