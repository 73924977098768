import { Component, Input, OnInit } from '@angular/core';
import { PageCountMeterRead } from '@libs/iso/core/models/meterRead/PageCountMeterRead';

@Component({
    selector: 'ptkr-meter-format-chips',
    templateUrl: './meter-format-chips.component.html',
    styleUrls: ['./meter-format-chips.component.scss']
})
export class MeterFormatChipsComponent implements OnInit {
    @Input() public pageCounts: PageCountMeterRead;
    @Input() public minChipsVisible: number = 1;

    constructor() {}

    ngOnInit(): void {}

    public extrapolateMeterFormats(pageCounts: PageCountMeterRead): Object {
        const object: Object = {};
        for (const format in pageCounts) {
            for (const property in pageCounts[format]) {
                object[
                    `${format.charAt(0).toUpperCase() + format.slice(1)} - ${
                        pageCounts[format][property].displayName
                    }`
                ] = pageCounts[format][property].value;
            }
        }
        return object;
    }
}
