<ptkr-block-header title="Select shipping address"></ptkr-block-header>

<div class="address-wrapper">
    <div class="address"
    *ngFor="let sa of addresses; let i = index;"
    (click)="selectAddress(sa, i)"
    [class.selected]="shippingAddressSelection[i]">
        {{ sa.companyName }}<br>
        Attn: {{ sa.attn }}<br>
        {{ sa.address1 }}<br>
        <span *ngIf="sa.address2">{{ sa.address2 }}<br></span>
        {{ sa.city }}, {{ sa.stateOrProvince }} {{ sa.zipOrPostCode }}<br>
        {{ sa.country }}
        <div class="checked" *ngIf="shippingAddressSelection[i]">
            <i class="fa fa-check" aria-hidden="true"></i>
        </div>
    </div>
    <div class="address add-new" (click)="newAddress()">
        <span>
            <i class="fas fa-plus-circle"></i><br>
            <h3>Add address</h3>
        </span>
    </div>
    <div class="address filler"></div>
    <div class="address filler"></div>
</div>
