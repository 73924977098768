import { DevicePropertyType } from '@libs/iso/core/enums/DevicePropertyType';

export interface IPropertyWithManagedCount {
    property: string;
    count: number;
    managedCount: number;
    nonManagedCount: number;
}

export interface IDeviceCountByProperty {
    property: IPropertyWithManagedCount[];
    type: DevicePropertyType;
}

export class ManagedDeviceCountByProperty implements IDeviceCountByProperty {
    public property: IPropertyWithManagedCount[] = [];
    public type: DevicePropertyType;
    constructor(params?: IDeviceCountByProperty) {
        if (params) {
            this.type = params.type;
            this.property = params.property.sort((a, b) => (a.count > b.count ? 1 : -1));
        }
    }

    public series(): Array<any> {
        switch (this.type) {
            case DevicePropertyType.Make:
                return [
                    {
                        name: 'Non-managed',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(0, 0, 0, 0.5)'
                        },
                        itemStyle: {
                            color: 'rgba(0, 0, 0, 0.3)'
                        },
                        data: this.property.map(m => m.nonManagedCount)
                    },
                    {
                        name: 'Managed',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(0, 0, 0, 0.5)'
                        },
                        itemStyle: {
                            color: '#80ACC7'
                        },
                        data: this.property.map(highlightMakesNeedReview(p => p.managedCount))
                    }
                ];
            case DevicePropertyType.Model:
                return [
                    {
                        name: 'Non-managed',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(0, 0, 0, 0.5)'
                        },
                        itemStyle: {
                            color: 'rgba(0, 0, 0, 0.3)'
                        },
                        data: this.property.map(m => m.nonManagedCount)
                    },
                    {
                        name: 'Managed',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        itemStyle: {
                            color: '#80ACC7'
                        },
                        data: this.property.map(highlightModelsNeedReview(p => p.managedCount))
                    }
                ];
            default:
                return [
                    {
                        name: 'Non-managed',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(0, 0, 0, 0.5)'
                        },
                        itemStyle: {
                            color: '#FF7474'
                        },
                        data: this.property.map(m => m.nonManagedCount)
                    },
                    {
                        name: 'Managed',
                        stack: 'default',
                        type: 'bar',
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        itemStyle: {
                            color: '#6EDD9D'
                        },
                        data: this.property.map(m => m.managedCount)
                    }
                ];
        }
    }
}

function highlightMakesNeedReview(
    getCount: (count: IPropertyWithManagedCount) => number
): (count: IPropertyWithManagedCount) => any {
    return (count: IPropertyWithManagedCount): any => {
        if (stringIsOneOf(count.property, 'printmib')) {
            return {
                label: {
                    position: 'right',
                    color: '#ffffff',
                    show: true,
                    backgroundColor: '#f6ab5a',
                    borderRadius: 2,
                    padding: [1, 2],
                    formatter: (a): string => {
                        return 'Needs Print Tracker review';
                    }
                },
                value: getCount(count)
            };
        }
        return getCount(count);
    };
}

function highlightModelsNeedReview(
    getCount: (count: IPropertyWithManagedCount) => number
): (count: IPropertyWithManagedCount) => any {
    return (count: IPropertyWithManagedCount): any => {
        if (stringIsOneOf(count.property, '')) {
            return {
                label: {
                    position: 'right',
                    color: '#ffffff',
                    show: true,
                    backgroundColor: '#f6ab5a',
                    borderRadius: 2,
                    padding: [1, 2],
                    formatter: (a): string => {
                        return 'Needs Print Tracker review';
                    }
                },
                value: getCount(count)
            };
        }
        return getCount(count);
    };
}

function stringIsOneOf(str: string, ...strings: string[]): boolean {
    for (const s of strings) {
        if (typeof s !== 'string') {
            continue;
        }
        if (s.toLowerCase().includes(str.toLowerCase())) {
            return true;
        }
    }
    return false;
}
