import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef,
    ViewChild
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromUser, fromEntity, fromApp, fromAuth } from '@app/state/selectors';
import { User, SelectEntityOverlayRef, Entity, WindowSize } from '@app/models';
import { Observable, Subject } from 'rxjs';
import {
    RouterService,
    OverlayService,
    UserService,
    EntityService,
    WindowSizeService
} from '@app/services';
import { EventType } from '@libs/iso/core';
import {
    ToggleGlobalSpinnerAction,
    StopImpersonationAction,
    RequestEntityChangeAction
} from '@app/state/actions';
import { finalize, takeUntil, filter, take, switchMap } from 'rxjs/operators';
import { ActivationEnd, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'ptkr-secured-container',
    templateUrl: './new-secured-container.component.html',
    styleUrls: ['./new-secured-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewSecuredContainerComponent implements OnInit, OnDestroy {
    @ViewChild('sidenav', { static: false }) public sidenav: MatSidenav;

    private _selectEntityOverlayRef: SelectEntityOverlayRef;
    private _ngUnsub: Subject<void> = new Subject<void>();

    public activeLink: string = '';
    public user$: Observable<User> = this._store.pipe(select(fromUser.currentUser));
    public entity$: Observable<Entity> = this._store.pipe(select(fromEntity.currentEntity));
    public showLoading$: Observable<boolean> = this._store.pipe(select(fromApp.showGlobalLoading));
    public isImpersonating$: Observable<boolean> = this._store.pipe(
        select(fromAuth.isImpersonating)
    );
    public windowSize$: Observable<WindowSize> = this._windowSizeService.windowSize;
    public WindowSize: typeof WindowSize = WindowSize;
    public alertCount: number = 0;
    public serviceCount: number = 0;

    constructor(
        private _store: Store<GlobalStore>,
        private _cd: ChangeDetectorRef,
        private _router: Router,
        private _routerService: RouterService,
        private _overlayService: OverlayService,
        private _userService: UserService,
        private _entityService: EntityService,
        private _windowSizeService: WindowSizeService
    ) {}

    ngOnInit(): void {
        this.activeLink = this._router.url;
        this._router.events
            .pipe(
                takeUntil(this._ngUnsub),
                filter(e => e instanceof ActivationEnd)
            )
            .subscribe(event => {
                this.activeLink = this._router.url;
            });
        // this.entity$
        //     .pipe(
        //         takeUntil(this._ngUnsub),
        //         switchMap(e =>
        //             forkJoin([
        //                 this._entityService.getTopAlerts(e._id, EventType.orderKey, 1, true),
        //                 this._entityService.getTopAlerts(e._id, EventType.serviceKey, 1, true)
        //             ])
        //         )
        //     )
        //     .subscribe(res => {
        //         this.alertCount = res[0].count;
        //         this.serviceCount = res[1].count;
        //         this._cd.detectChanges();
        //     });
    }

    ngOnDestroy(): void {
        this._ngUnsub.next();
        this._ngUnsub.complete();
        this._ngUnsub = undefined;
    }

    public navigate(urlFrag: string): void {
        this._routerService.navigate(urlFrag);
        if (!!this.sidenav) {
            this.sidenav.close();
        }
    }

    public changeEntity(entity: Entity): void {
        this._store.dispatch(new RequestEntityChangeAction(entity._id));
    }

    public toggleEntityDropdown(): void {
        if (this._selectEntityOverlayRef) {
            this._selectEntityOverlayRef.close();
            this._selectEntityOverlayRef = undefined;
        } else {
            this._selectEntityOverlayRef = this._overlayService.openSelectEntityOverlay({});
            this._selectEntityOverlayRef
                .afterClosed()
                .subscribe(() => (this._selectEntityOverlayRef = undefined));
        }
    }

    // public toggleAlertDropdown(connectedElement: ElementRef): void {
    //     const alertLimit = 3;
    //     let entityId = '';
    //     this.entity$.pipe(take(1)).subscribe(e => (entityId = e._id));
    //     this._entityService
    //         .getTopAlerts(entityId, EventType.orderKey, alertLimit, false)
    //         .subscribe(alerts => {
    //             this._overlayService.openNotificationOverlay(connectedElement, {
    //                 notifications: alerts.docs
    //             });
    //         });
    // }

    public toggleServiceDropdown(connectedElement: ElementRef): void {
        const alertLimit = 3;
        let entityId = '';
        this.entity$.pipe(take(1)).subscribe(e => (entityId = e._id));
        this._entityService
            .getTopAlerts(entityId, EventType.serviceKey, alertLimit, false)
            .subscribe(services => {
                this._overlayService.openNotificationOverlay(connectedElement, {
                    notifications: services.docs
                });
            });
    }

    public toggleViewProfileDropdown(connectedElement): void {
        this._overlayService.openProfileDropdownOverlay(connectedElement);
    }

    public toggleMenu(): void {
        console.log('toggle menu', this.sidenav);
        if (!!this.sidenav) {
            this.sidenav.toggle();
        }
    }

    public endImpersonation(): void {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        this._userService
            .endImpersonate()
            .pipe(finalize(() => this._store.dispatch(new ToggleGlobalSpinnerAction(false))))
            .subscribe(res => {
                this._store.dispatch(new StopImpersonationAction(res));
                this.navigate('/home/dashboard');
            });
    }

    public openJobs(): void {
        const ref = this._overlayService.openJobsOverlay();
    }
}
