import { IItem, Item } from './Item';

export interface IItemWithEntityPricing extends IItem {
    entityPrice: number;
    hide: boolean;
}

export class ItemWithEntityPricing extends Item implements IItemWithEntityPricing {
    public entityPrice: number = 0;
    public hide: boolean = false;

    constructor(defaults?: Partial<IItemWithEntityPricing>) {
        super(defaults);
        if (defaults) {
            this.entityPrice = defaults.entityPrice || this.entityPrice;
            this.hide = defaults.hide || this.hide;
        }
    }
}
