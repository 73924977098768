import { Injectable } from '@angular/core';
import { HttpService, Get, Header, Post, Body, ResponseType } from '@libs/web/http';
import { Observable } from 'rxjs';
import {
    LoginParams,
    SingleSignOnAction,
    SingleSignOnMicrosoft,
    SingleSignOnProvider
} from '@libs/iso/core';
import { PublicClientApplication } from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';
import { GlobalStore } from '@app/state/store';
import { Store } from '@ngrx/store';
import { LoginAction, ToggleGlobalSpinnerAction } from '@app/state/actions';
import { NotificationService } from '@app/core/services/notification.service';
import { RouterService } from '@app/core/services/router.service';

@Injectable()
export class AuthService extends HttpService {
    private _msalInstance: PublicClientApplication;

    constructor(
        protected _http: HttpClient,
        private _store: Store<GlobalStore>,
        private _notificationService: NotificationService,
        private _routerService: RouterService,
    ) {
        super(_http);
        this._msalInstance = new PublicClientApplication({
            auth: {
                clientId: SingleSignOnMicrosoft.OAuthPublicConfig.clientId,
                authority: 'https://login.microsoftonline.com/common/v2.0'
            }
        });
    }

    @Get('/auth/refresh')
    public refreshToken(
        @Header('Authorization') tokenToRefresh: string
    ): Observable<{ impersonating: boolean; token: string }> {
        return null;
    }

    @Post('/auth/login', ResponseType.Text)
    public login(@Body() loginParams: LoginParams): Observable<string> {
        return null;
    }

    public async oauthMicrosoft(action: SingleSignOnAction): Promise<void> {
        await this._msalInstance.initialize();
        const res = await this._msalInstance.loginPopup();
        return new Promise((resolve, reject) => {
            this.oauth(
                SingleSignOnProvider.Microsoft,
                action,
                res.accessToken,
                res.idToken
            ).subscribe(
                token => {
                    this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    this._store.dispatch(new LoginAction(token));
                    resolve();
                },
                err => {
                    // If the user either doesn't have an account (when they're trying to login)
                    // or if they have and account (but they're trying to register), then redirect
                    // them to the correct page.
                    //
                    // Note: stringifying here because for some reason when this is deployed, and
                    // the error is coming through our gateway, something is having issues reading
                    // out specific error messages...
                    const raw = JSON.stringify(err);
                    if (raw.includes('login')) {
                        this._routerService.fullNavigate('/auth/login');
                    } else if (raw.includes('register')) {
                        this._routerService.fullNavigate('/auth/register');
                    }
                    this._notificationService.error(err?.error ?? err);
                    this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    reject();
                }
            );
        });
    }

    @Post('/auth/requestPasswordReset')
    public requestPasswordReset(@Body('email') email: string): Observable<boolean> {
        return null;
    }

    @Post('/auth/resetPassword')
    public resetPassword(
        @Body('email') email: string,
        @Body('newPassword') newPassword: string,
        @Body('jwt') resetToken: string
    ): Observable<boolean> {
        return null;
    }

    @Post('/auth/setFirstPassword')
    public setFirstPassword(
        @Body('newPassword') newPassword: string,
        @Body('jwt') resetToken: string
    ): Observable<boolean> {
        return null;
    }

    @Post('/auth/register')
    public register(
        @Body('email') email: string,
        @Body('firstName') firstName: string,
        @Body('lastName') lastName: string,
        @Body('name') companyName: string,
        @Body('phoneNumber') phoneNumber: string
    ): Observable<boolean> {
        return null;
    }

    @Post('/auth/oauth', ResponseType.Text)
    public oauth(
        @Body('provider') provider: SingleSignOnProvider,
        @Body('action') action: SingleSignOnAction,
        @Body('accessToken') accessToken: string,
        @Body('idToken') idToken: string
    ): Observable<string> {
        return null;
    }
}
