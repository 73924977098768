import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { LogoutAction } from '@app/state/actions';
import { MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Permission } from '@libs/iso/core';
import { WebadminV2Service } from '../../../core/services/webadmin-v2.service';

@Component({
    selector: 'ptkr-profile-overlay',
    templateUrl: './profile-overlay.component.html',
    styleUrls: ['./profile-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileOverlayComponent implements OnInit {
    public Permission: typeof Permission = Permission;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public overlayRef: MatDialogRef<ProfileOverlayComponent>,
        private _router: Router,
        private _webadminV2Service: WebadminV2Service,
        private _store: Store<GlobalStore>
    ) {}

    ngOnInit(): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig();
        const rect = this.data.elementRef.getBoundingClientRect();
        console.log(rect);
        dialogConfig.position = { right: '32px', top: '64px' };
        this.overlayRef.updatePosition(dialogConfig.position);
    }

    public logout(): void {
        // Trigger logout functions
        this._store.dispatch(new LogoutAction());
        this.overlayRef.close();
        this._router.navigate(['/auth/login']);
    }

    public goToV2(): void {
        this._webadminV2Service.openHome();
    }
}
