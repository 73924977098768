import { Store } from '@ngrx/store';
import { AuthService, UserService, StorageService, StripeService } from '@app/services';
import { GlobalStore } from '@app/state/store';
import { RestoreLoginAction, RestoreImpersonationAction } from '@app/state/actions';

export function initializer(
    authService: AuthService,
    userService: UserService,
    store: Store<GlobalStore>,
    storageService: StorageService,
    stripeService: StripeService
): Function {
    return (): Promise<any> =>
        new Promise(
            (resolve, reject): void => {
                stripeService.initCards().then(() => {
                    let token: string = storageService.authToken;
                    // console.log('token', token);
                    if (typeof token === 'string' && token.length > 0) {
                        authService.refreshToken(`Bearer ${token}`).subscribe(
                            success => {
                                // console.log('refresh token success', success);
                                token = success.token;
                                const _impersonate = success.impersonating;
                                userService.initUser(`Bearer ${token}`).subscribe(
                                    user => {
                                        if (_impersonate) {
                                            store.dispatch(
                                                new RestoreImpersonationAction(token, resolve)
                                            );
                                        } else {
                                            store.dispatch(new RestoreLoginAction(token, resolve));
                                        }
                                    },
                                    error => {
                                        resolve(true);
                                    }
                                );
                            },
                            error => {
                                // console.error('refresh token error', error);
                                storageService.authToken = '';
                                resolve(true);
                            }
                        );
                    } else {
                        resolve(true);
                    }
                });
            }
        );
}
