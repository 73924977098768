enum ContactPhoneType {
    Office = '[ContactPhoneType] office',
    Cell = '[ContactPhoneType] cell'
}

namespace ContactPhoneType {
    export function toString(type: ContactPhoneType): string {
        switch (type) {
            case ContactPhoneType.Office:
                return 'Office';
            case ContactPhoneType.Cell:
                return 'Cell';
            default:
                return '';
        }
    }
}

export { ContactPhoneType };
