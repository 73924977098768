import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ptkr-inline-log-hint',
    templateUrl: './inline-log-hint.component.html',
    styleUrls: ['./inline-log-hint.component.scss']
})
export class InlineLogHintComponent implements OnInit {
    @Input() public hintHTML: string;

    constructor() {}

    ngOnInit(): void {}
}
