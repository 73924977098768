import { ObjectID } from 'bson';

export interface IItemVenderReference {
    key: ObjectID | string;
    name: string;
}

export class ItemVenderReference implements IItemVenderReference {
    public key: ObjectID | string = null;
    public name: string = '';

    constructor(params?: Partial<IItemVenderReference>) {
        if (params) {
            this.key = params.key || this.key;
            this.name = params.name || this.name;
        }
    }
}
