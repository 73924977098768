// CategoricalDistributionObject is how data is given to CategoricalDistributor
export interface CategoricalDistributionElement {
    category: string;
    value: number;
    // optional hex color for use in categorical distribution inline component
    color?: string;
    // optional class for use in categorical distribution inline component
    class?: string;
}

// CalculatedDistribution is the product of running calculateDistributions on CategoricalDistributionObject
export interface CalculatedDistribution {
    category: string;
    // Between 0 and 1
    value: number;
    // optional hex color for use in categorical distribution inline component
    color?: string;
    // optional class for use in categorical distribution inline component
    class?: string;
}

// CategoricalDistributor knows how to calculate categorical distributions, given an array of
// CategoricalDistributionElement which indicate a category and a value.
export class CategoricalDistributor {
    public elements: CategoricalDistributionElement[];
    constructor(elements: CategoricalDistributionElement[]) {
        this.elements = elements;
    }

    // calculateDistributions will return an array of CalculatedDistribution where the value of
    // each distribution is a proportional percentage of the whole that this categories value
    // constitutes.
    public calculateDistributions(): CalculatedDistribution[] {
        if (this.elements.length === 0) {
            return [];
        }
        let total = 0;
        for (const element of this.elements) {
            total = total + element.value;
        }
        const elements: CalculatedDistribution[] = [];
        for (const element of this.elements) {
            elements.push({
                ...element,
                value: element.value / total,
            });
        }
        return elements;
    }
}
