import { Injectable } from '@angular/core';
import {
    StripeService as StripeModuleService
} from 'ngx-stripe';
import { Observable } from 'rxjs';

type StripeElement = any;

@Injectable()
export class StripeService {
    private _lightCardNum: StripeElement;
    public get lightCardNum(): StripeElement {
        if (!!this._lightCardNum) {
            this._lightCardNum.unmount();
        }
        return this._lightCardNum;
    }
    private _lightCardExp: StripeElement;
    public get lightCardExp(): StripeElement {
        if (!!this._lightCardExp) {
            this._lightCardExp.unmount();
        }
        return this._lightCardExp;
    }
    private _lightCardCvc: StripeElement;
    public get lightCardCvc(): StripeElement {
        if (!!this._lightCardCvc) {
            this._lightCardCvc.unmount();
        }
        return this._lightCardCvc;
    }
    public cardOptionsLight: any = {
        style: {
            base: {
                iconColor: 'rgba(0, 0, 0, 0.54)',
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 400,
                fontFamily: '"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                fontSize: '13px',
                '::placeholder': {
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            },
            invalid: {
                iconColor: '#e85746',
                color: '#e85746'
            }
        },
        classes: {
            focus: 'is-focused',
            empty: 'is-empty'
        }
    };

    constructor(private _stripeModuleService: StripeModuleService) {}

    public initCards(): Promise<any> {
        return new Promise(
            (resolve, reject): void => {
                this._stripeModuleService.elements().subscribe(elems => {
                    this._lightCardNum = elems.create('cardNumber', this.cardOptionsLight as any);
                    this._lightCardExp = elems.create('cardExpiry', this.cardOptionsLight as any);
                    this._lightCardCvc = elems.create('cardCvc', this.cardOptionsLight as any);
                    resolve(true);
                });
            }
        );
    }

    public createToken(cardNumExpCvc: StripeElement, options: any): Observable<any> {
        return this._stripeModuleService.createToken(cardNumExpCvc, options);
    }

    public createBankToken(params: Partial<any>): Observable<any> {
        params.country = 'US';
        params.currency = 'usd';
        // return this._stripeModuleService.createToken('bank_account', params);
        return new Observable();
    }

    public getLightCardWithData(): Array<StripeElement> {
        return [this._lightCardNum, this._lightCardExp, this._lightCardCvc];
    }
}
