import { PaginationLinks } from '@libs/iso/core';
import { ISupplyItem, SupplyItem } from './SupplyItem';

export interface SuppliesListModel {
    docs: Partial<ISupplyItem>[];
    totalDocs: number;
    urls: PaginationLinks;
}

export class SuppliesList implements SuppliesListModel {
    public docs: Partial<ISupplyItem>[] = [];
    public totalDocs: number = 0;
    public urls: PaginationLinks = new PaginationLinks();

    constructor(params?: Partial<SuppliesListModel>) {
        if (!!params) {
            if (Array.isArray(params.docs)) {
                this.docs = params.docs.map(d => new SupplyItem(d));
            }
            this.totalDocs = params.totalDocs || this.totalDocs;
            this.urls = new PaginationLinks(params.urls);
        }
    }
}
