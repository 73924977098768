enum SNMPAlgorithm {
    MD5 = 'MD5',
    SHA = 'SHA',
}

namespace SNMPAlgorithm {
    export function toString(type: SNMPAlgorithm): string {
        return String(type);
    }

    export const asArray: SNMPAlgorithm[] = [SNMPAlgorithm.MD5, SNMPAlgorithm.SHA];
}

export { SNMPAlgorithm };
