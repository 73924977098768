import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportScheduledWithMetadata, ReportScheduled, Report, ExportDelimiter } from '@libs/iso/core';
import { GlobalStore } from '@app/state/store';
import { timezones } from '@libs/iso/core/constants';
import { Store } from '@ngrx/store';
import cronstrue from 'cronstrue';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import { CronOptions } from '@libs/web/cron/CronOptions';

@Component({
    selector: 'ptkr-edit-report-schedule-modal',
    templateUrl: './edit-report-schedule-modal.component.html',
    styleUrls: ['./edit-report-schedule-modal.component.scss']
})
export class EditReportScheduleModalComponent implements OnInit {
    public form: FormGroup;
    public cronOptions: CronOptions = {
        formInputClass: 'form-control cron-editor-input',
        formSelectClass: 'form-control cron-editor-select',
        formRadioClass: 'cron-editor-radio',
        formCheckboxClass: 'cron-editor-checkbox',

        defaultTime: '00:00:00',
        use24HourTime: false,

        hideMinutesTab: true,
        hideHourlyTab: true,
        hideDailyTab: false,
        hideWeeklyTab: false,
        hideMonthlyTab: false,
        hideYearlyTab: true,
        hideAdvancedTab: true,

        /** hides the Seconds UI form element */
        hideSeconds: true,

        /** removes Seconds from the Cron expression */
        removeSeconds: true,

        /** removes Years from the Cron expression */
        removeYears: true
    };

    public timezoneOptions: string[] = Object.keys(timezones);
    public filteredTimezoneOptions: Observable<string[]>;
    public ExportDelimiter: typeof ExportDelimiter = ExportDelimiter;

    constructor(
        public dialogRef: MatDialogRef<EditReportScheduleModalComponent>,
        private _fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: {
            report: Report;
            schedule: ReportScheduledWithMetadata;
        },
        private _store: Store<GlobalStore>
    ) {}

    ngOnInit(): void {
        this.initializeForm(this.dialogData.schedule);
    }

    public initializeForm(schedule: ReportScheduled = new ReportScheduled()): void {
        const timezoneControl = new FormControl(schedule.timezone ?? moment.tz.guess(), [
            Validators.required
        ]);
        this.form = this._fb.group({
            entityKey: new FormControl(<string>schedule.entityKey, [Validators.required]),
            enabled: new FormControl(schedule.enabled),
            schedule: new FormControl(schedule.schedule, [Validators.required]),
            timezone: timezoneControl,
            exportDelimiter: new FormControl(schedule.export.delimiter),
            exportEmailAddresses: new FormControl(schedule.export.email.addresses, [
                Validators.required
            ]),
            exportEmailPersonalizeSubject: new FormControl(
                schedule.export.email.personalize.subject
            ),
            exportEmailPersonalizeBody: new FormControl(schedule.export.email.personalize.body)
        });
        this.filteredTimezoneOptions = timezoneControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
    }

    private _filter(value: string): string[] {
        return this.timezoneOptions.filter(option =>
            option.toLowerCase().includes(value.toLowerCase())
        );
    }

    public onCronChange(event: any): void {
        this.form.get('schedule').setValue(event);
    }

    public onEntityChange(event: any): void {
        this.form.get('entityKey').setValue(event._id);
    }

    public prettyCron(expr: string): string {
        return cronstrue.toString(expr);
    }

    public mergeWithForm(): ReportScheduledWithMetadata {
        const merged = new ReportScheduledWithMetadata({
            ...this.dialogData.schedule,
            reportKey: this.dialogData.report?._id,
            entityKey: this.form.get('entityKey').value,
            enabled: this.form.get('enabled').value,
            schedule: this.form.get('schedule').value,
            timezone: this.form.get('timezone').value
        });
        merged.export.delimiter = this.form.get('exportDelimiter').value;
        merged.export.email.addresses = this.form.get('exportEmailAddresses').value;
        merged.export.email.personalize.subject = this.form.get(
            'exportEmailPersonalizeSubject'
        ).value;
        merged.export.email.personalize.body = this.form.get('exportEmailPersonalizeBody').value;
        return merged;
    }
}
