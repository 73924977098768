import { Permission } from '@libs/iso/core';

export interface AuthStore {
    token: string;
    permissions: Permission[];
    isImpersonating: boolean;
    initializerResolve: (value: any) => void;
}

export const initAuthState: AuthStore = {
    token: '',
    permissions: [],
    isImpersonating: false,
    initializerResolve: undefined
};
