import { Device } from '@libs/iso/core/models/device/Device';
import { MeterRead } from '@libs/iso/core/models/meterRead/MeterRead';
import { Column, colKeyOneProp } from '../../helpers/columns';
import { PageCountMeterRead } from '../meterRead/PageCountMeterRead';
import { MeterReadSupply } from '../meterRead/MeterReadSupply';

export interface IHistoricalMeterRead {
    first: MeterRead;
    last: MeterRead;
    device: Partial<Device>;
}

export class HistoricalMeterRead implements IHistoricalMeterRead {
    public static columns: Array<Column> = [
        {
            name: 'Device Make',
            keys: colKeyOneProp('device.make'),
            description: 'Brand-name of the device',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Device Model',
            keys: colKeyOneProp('device.model'),
            description: 'Model of the device',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Device Make & Model',
            keys: Column.ColumnDataAccessor.create([['device.make'], ['device.model']]),
            description: 'Both the Make and Model of the device, all in a single column',
            display: (v: any): string => `${v[0]} ${v[1]}`,
            sortable: true
        },
        {
            name: 'Device AssetId',
            keys: colKeyOneProp('device.assetId'),
            description: 'AssetId of the device',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Device Date Created',
            keys: colKeyOneProp('device.createdDate'),
            description: 'Date the device was created',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Device IP Address',
            keys: colKeyOneProp('device.ipAddr'),
            description: 'IP Address of the Device this meter was taken from',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Device Location',
            keys: colKeyOneProp('device.location'),
            description: 'Location of device as specified in the device\'s general info settings',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Device Notes',
            keys: colKeyOneProp('device.notes'),
            description: 'Notes of the device, set in the device\'s settings panel',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Device Serial Number',
            keys: colKeyOneProp('device.serialNum'),
            description: 'Device\'s serial number',
            display: Column.displayFunctions[''],
            sortable: true
        },
        ...Column.extrapolate(MeterRead.columns, [{ namePrefix: 'First', keyPrefix: 'first' }]),
        ...Column.extrapolate(MeterRead.columns, [{ namePrefix: 'Last', keyPrefix: 'last' }]),
        ...Column.subtractIdenticalSets(
            PageCountMeterRead.columns,
            'first.pageCounts',
            'last.pageCounts',
            '(Volume) '
        ),
        ...Column.subtractIdenticalSets(
            MeterReadSupply.columns,
            'first.supplies',
            'last.supplies',
            '(Volume) '
        ),
        {
            name: 'Device Firmware Version',
            keys: colKeyOneProp('device.firmware.device'),
            description: 'Device\'s firmware version',
            display: Column.displayFunctions[''],
            sortable: true
        },
        {
            name: 'Billed On',
            keys: colKeyOneProp('billed'),
            description: 'When the billing period was billed, if ever.',
            display: Column.displayFunctions['date'],
            sortable: true
        }
    ];

    public first: MeterRead = null;
    public last: MeterRead = null;
    public device: Partial<Device> = null;

    constructor(props: Partial<IHistoricalMeterRead>) {
        if (!!props) {
            this.first = props.first;
            this.last = props.last;
            this.device = props.device;
        }
    }
}
