import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { ItemWithEntityPricing, ItemType, QualityLevel, Permission } from '@libs/iso/core';
import { MatTableDataSource } from '@angular/material/table';
import { WindowSizeService, NotificationService } from '@app/services';
import { Observable } from 'rxjs';
import { WindowSize } from '@app/models';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromUser } from '@app/state/selectors';
import { take } from 'rxjs/operators';

@Component({
    selector: 'ptkr-compatible-order-table',
    templateUrl: './compatible-order-table.component.html',
    styleUrls: ['./compatible-order-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompatibleOrderTableComponent implements OnInit {
    @Input()
    public get items(): ItemWithEntityPricing[] {
        return !!this.dataSource ? this.dataSource.data : [];
    }
    public set items(value: ItemWithEntityPricing[]) {
        if (!!this.dataSource) {
            this.dataSource.data = value;
        } else {
            this.dataSource = new MatTableDataSource<ItemWithEntityPricing>(value);
        }
    }
    @Input()
    public sortEnabled: boolean = false;
    @Input()
    public replacement: boolean = false;

    @Output()
    public addToCart: EventEmitter<{
        item: ItemWithEntityPricing;
        qty: number;
    }> = new EventEmitter<{
        item: ItemWithEntityPricing;
        qty: number;
    }>();
    @Output()
    public viewCart: EventEmitter<void> = new EventEmitter<void>();
    // prettier-ignore
    @Output() public replaceToner: EventEmitter<ItemWithEntityPricing>
        = new EventEmitter<ItemWithEntityPricing>();

    public dataSource: MatTableDataSource<ItemWithEntityPricing>;
    public displayedColumns: string[] = [
        'partNumber',
        'make',
        'type',
        'color',
        'yield',
        'class',
        'price'
    ];
    public ItemType: typeof ItemType = ItemType;
    public QualityLevel: typeof QualityLevel = QualityLevel;
    public WindowSize: typeof WindowSize = WindowSize;
    public currentWindowSize$: Observable<WindowSize> = this._windowService.windowSize;

    constructor(
        private _windowService: WindowSizeService,
        private _notificationService: NotificationService,
        private _store: Store<GlobalStore>
    ) {}

    ngOnInit(): void {
        this._store
            .pipe(
                select(fromUser.permissions),
                take(1)
            )
            .subscribe(perms => {
                if (Permission.hasPermission(Permission.OrderToner, perms)) {
                    this.displayedColumns = [...this.displayedColumns, 'actions'];
                }
            });
    }

    public addItemToCart(item: ItemWithEntityPricing, qty: number): void {
        if (qty < 1) {
            this._notificationService.error('Quantity must be greater than 1');
            return;
        } else {
            item['addedToCart'] = true;
            this.addToCart.emit({
                item: item,
                qty: qty
            });
        }
    }
}
