import { CollectionName } from '../enums/CollectionName';
import * as moment from 'moment';
import { merge } from 'lodash';

/**
 * Column represents the relationship between a column users can display in the supertable system,
 * and the raw data stored in the corrosponding model or model-derivative (collectively known as
 * tabulatable objects).
 * @param {string} name
 *  Display name of column shown to users.
 * @param {Column.ColumnDataAccessor} keys
 *  An object containing a map to the properties gathered to generate this column, like a projection
 *  object.
 * @param {string} description
 *  User-friendly description of column.
 * @param {(v: any) => string | undefined} display
 *  Function that converts value to display string
 * @param {string} defaultValue
 *  Optional; will be used in absence of a real value.
 * @param {boolean} sortable
 *  Optional; if false, will disable sort button on header of column.
 * @param {boolean} amTimeAgo
 *  Optional flag that tells the dataTable to not use the display function, instead gathering the
 *  raw value and using the amTimeAgo pipe. Used exclusively for columns that represent dates, and
 *  typcally paired with a column that displays the absolute date for more precision.
 */
type Column = {
    name: string;
    keys: Column.ColumnDataAccessor;
    description: string;
    display: (v: any) => string | undefined;
    defaultValue?: string;
    sortable?: boolean;
    amTimeAgo?: boolean;
};

namespace Column {
    /**
     * Tabulatable objects represent how to pair their primary collection/data with data from other
     * collections using ForeignColumns objects. For a given collection a string representing the
     * key to access is given. This will be compared to _id's in the foreign collection.
     */
    export type ForeignKeys = Partial<{ [S in CollectionName]: string }>;

    /**
     * Anything that meets this specification may be used in tabulation services.
     */
    export type Tabulatable = { columns: Array<Column>; foreignKeys?: ForeignKeys };

    /**
     * To display a column, we need to, A, generate a projection object, B, retrieve a document we
     * can read from the database, and C, be ready to pull the column data out of that document.
     * This object provides tools to do that.
     * @param { Array<string> } nicknames
     *  The property keys of the column, in case you need to manually access it in the document.
     * @param { Array<string> } dbKeys
     *  Array of db keys corrosponding to the properties in this column
     * @param {{ [key: string]: { [key: string]: string } }} fromDb
     *  Represents the projection object that would retrieve the properties needed for this
     *  column. Extra formatting is needed to allow for 'add-ins' from other collections.
     * @param { (doc: any) => Array<any> } fromDoc
     *  This function maps a given document (hopefully one generated with the 'fromDb' property) to
     *  the array of values this column represents. It is a safer way to access the properties.
     * @param { Array<Array<string>> } raw
     *  This exists to make it easy to create derivatory columns (see 'column.extrapolate')
     */
    export type ColumnDataAccessor = {
        nicknames: Array<string>;
        dbKeys: Array<string>;
        fromDb: { [key: string]: { [key: string]: string } };
        fromDoc: (doc: any) => Array<any>;
        raw: Array<Array<string>>;
    };

    export namespace ColumnDataAccessor {
        /**
         * creates a new ColumnDataAccessor object.
         * @param {Array<Array<string>>} params
         *  The outer aray contains a set of arrays that each represent a property that the column
         *  that contains this cda references. For each of these sub arrays there are three things
         *  they can contain, in this order:
         *  0 - property key
         *  1 - (optional) collection key is from
         *  2 - (optional) name that property appears as in returned doc, to avoid name conflicts
         *
         * @returns {ColumnDataAccessor} a new Cda.
         */
        export function create(params: Array<Array<string>>): ColumnDataAccessor {
            const cda: ColumnDataAccessor = {
                nicknames: [],
                dbKeys: [],
                fromDb: undefined,
                fromDoc: undefined,
                raw: params
            };
            params.forEach(keyData => {
                if (!keyData || keyData.length < 1) {
                    // To make debugging easier if something is made wrong. Program is allowed to
                    // encounter the failure afterwards because there is no point in trying to
                    // recover if this happens.
                    console.error('Column key data formatted incorrectly.');
                }
                const path = keyData[0];
                const collection = keyData[1] || 'local';
                const nickname = keyData[2] || keyData[0].replace(/\./g, '_');
                cda.fromDb = merge(cda.fromDb, { [collection]: { [nickname]: '$' + path } });
                cda.nicknames.push(nickname);
                cda.dbKeys.push(path);
            });
            cda.fromDoc = (doc: any): Array<any> => {
                const results: Array<any> = [];
                cda.nicknames.forEach(propName => {
                    results.push(doc[propName]);
                });
                return results;
            };
            return cda;
        }

        /**
         * Creates a new ColumnDataAccessor that looks for the contents of the old Cda, but does so
         * within a subdirectory-- Useful when using the Column.extrapolate function.
         * @param {ColumnDataAccessor} oldCda the cda we are prepending.
         * @param {Array<Array<string>>} keyPrefix prefix that will be prepended to the cda.
         * @returns {ColumnDataAccessor} New Cda.
         */
        export function prepend(oldCda: ColumnDataAccessor, keyPrefix: string): ColumnDataAccessor {
            const newParams: Array<Array<string>> = [];
            for (const i in oldCda.raw) {
                const propParams: Array<string> = [];
                if (oldCda.raw[i][0]) {
                    propParams.push(keyPrefix + '.' + oldCda.raw[i][0]);
                }
                if (oldCda.raw[i][1]) {
                    propParams.push(oldCda.raw[i][1]);
                }
                if (oldCda.raw[i][2]) {
                    propParams.push(keyPrefix.replace(/\./g, '_') + '_' + oldCda[i][2]);
                }
                if (propParams.length > 0) {
                    newParams.push(propParams);
                }
            }
            return create(newParams);
        }

        export function union(
            left: ColumnDataAccessor,
            right: ColumnDataAccessor
        ): ColumnDataAccessor {
            const newParams: Array<Array<string>> = [];
            left.raw.forEach(e => newParams.push(e));
            right.raw.forEach(e => newParams.push(e));
            return create(newParams);
        }
    }

    /**
     * Functions that are commonly used by columns to convert raw data to display data.
     */
    export const displayFunctions: { [key: string]: (v: [any]) => string } = {
        '': (v: [any]): string => v[0] + '',
        tensPlace: (v: [any]): string =>
            typeof v[0] === 'string' ? Number.parseFloat(v[0]).toFixed(1) : v[0].toFixed(1),
        date: (v: [Date]): string => new Date(v[0]).toISOString(),
        amTimeAgo: (v: [Date | moment.Moment]): string => moment(v[0]).fromNow()
    };

    /**
     * Will attempt to pull property corrosponding to c from model, and return display string.
     * @param {any} doc
     *  A document that was generated with a tabulation service.
     * @param {Column} c
     *  A column that was used to generate the document.
     * @returns {string | undefined}
     *  The display value for that column, retrieved from the document, or the default value for
     *  that column if the value is not found, or undefined if neither a value or default is found.
     */
    export function display(doc: any, c: Column): string | undefined {
        const v = _retrieveRawValue(doc, c);
        // Calls display function once it knows the array isn't completely empty.
        // Checking here makes it so we don't have to check for every display func.
        // Multi-property functions might need to do additional checking in the display func.
        for (let i = 0; i < v.length; i++) {
            if (v[i] !== null && v[i] !== undefined) {
                return c.display(v);
            }
        }
        // Otherwise it tries to provide default values.
        if (c.defaultValue) {
            return c.defaultValue;
        }
        return undefined;
    }

    // gets raw data, in case you need it.
    export function rawData(model: any, c: Column): any {
        const v = _retrieveRawValue(model, c);
        if (v !== null && v !== undefined) {
            return v;
        }
        return undefined;
    }

    function _retrieveRawValue(model: any, c: Column): Array<any> {
        return c.keys.fromDoc(model);
        // const path = c.key.split('.');
        // for (const i of )
        // let v = model; // v is resolved to model's value for that column below.
        // for (const s of path) {
        //     if (v[s] !== null && v[s] !== undefined) {
        //         v = v[s];
        //     } else {
        //         v = undefined;
        //         break;
        //     }
        // }
        // return v;
    }

    // This will take a set of columns and generate duplicates of the set, with each of these new
    // sets containing the contents of the original set but with the name and key prepended
    // by the respective namePrefix and keyPrefix of the prepender, and with any values being
    // overwritten by the 'override' property of the prepender.

    // If the name or key of a column is overwritten by the override property, the prefixes will
    // still be prepended to these new values.

    // If this function seems oddly specific consider these cases:
    // 1) Meter Reads and many other collections contain large numbers of often varying properties
    //    that in-themselves contain very similar contents. This function saves time trying to
    //    write a duplicate piece of code for each column that could be derived from such properties
    //    the override would let you later specify different descriptions for each if you would like
    // 2) By utilizing the override property and filling the prefixes in with empty strings, we
    //    can describe several different columns without repeating what they have in common. This
    //    is handy when, as is often the case, a model is mostly composed of the same type of data
    //    and thus the data can be handled the same exact way.
    export function extrapolate(
        columns: Array<Column>,
        prepender: Array<{ namePrefix?: string; keyPrefix?: string; override?: Partial<Column> }>
    ): Array<Column> {
        const newCols: Array<Column> = [];
        for (const i in prepender) {
            for (const j in columns) {
                const newCol = { ...columns[j], ...prepender[i].override };
                if (prepender[i].namePrefix) {
                    newCol.name = prepender[i].namePrefix + ' ' + newCol.name;
                }
                if (prepender[i].keyPrefix) {
                    newCol.keys = ColumnDataAccessor.prepend(newCol.keys, prepender[i].keyPrefix);
                }
                newCols.push(newCol);
            }
        }
        return newCols;
    }

    /**
     * Creates a set of columns that represent, for two instances of the same tabulatable,
     * the difference between their columns. Assumes that subtraction works for the listed columns.
     * @param {Array<Column>} columns a set of columns.
     * @param {string} rightPrefix prefix representing right side of operation.
     * @param {string} leftPrefix prefix representing left side of operation.
     * @param {string} namePrefix prefix to add to name of columns to show they are a difference.
     * @returns {Array<Column>} columns to smear into your column lists.
     */
    export function subtractIdenticalSets(
        columns: Array<Column>,
        rightPrefix: string,
        leftPrefix: string,
        namePrefix: string
    ): Array<Column> {
        const newCols: Array<Column> = [];
        for (const i in columns) {
            newCols.push({
                name: namePrefix + columns[i].name,
                keys: ColumnDataAccessor.union(
                    ColumnDataAccessor.prepend(columns[i].keys, leftPrefix),
                    ColumnDataAccessor.prepend(columns[i].keys, rightPrefix)
                ),
                description: columns[i].description,
                display: (v: Array<any>): string =>
                    columns[i].display([
                        (v[0] != null ? Number(v[0]) : 0) - (v[1] != null ? Number(v[1]) : 0)
                    ]),
                defaultValue: columns[i].defaultValue || undefined,
                sortable: columns[i].sortable || false,
                amTimeAgo: columns[i].amTimeAgo || undefined
            });
        }
        return newCols;
    }

    // Essentially a type converter that is used to help generate add-in columns.
    export function unravelForeignData(d: {
        [key: string]: { [key: string]: any };
    }): { [key: string]: any } {
        const newD: { [key: string]: any } = {};
        for (const i in d) {
            for (const j in d[i]) {
                newD[j] = '$' + j;
            }
        }
        return newD;
    }
}

/**
 * Shorthand for calling Column.ColumnDataAccessor.create for a single property, since this happens
 * most of the time.
 * @param {string} prop
 *  property key
 * @param {string} collection
 *  (optional) collection key is from
 * @param {string} nickname
 *  (optional) name that property appears as in returned doc, in case there is a name conflict.
 * @returns {Column.ColumnDataAccessor} the dca for the given property
 */
export function colKeyOneProp(
    prop: string,
    collection?: string,
    nickname?: string
): Column.ColumnDataAccessor {
    return Column.ColumnDataAccessor.create([[prop, collection, nickname]]);
}

export { Column };
