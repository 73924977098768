import { IPageCountMeterReadType, PageCountMeterReadType } from './PageCountMeterReadType';
import { Column, colKeyOneProp } from '@libs/iso/core/helpers/columns';
import { MeterReadValue } from './MeterReadValue';

export interface IPageCountMeterRead {
    default?: IPageCountMeterReadType;
    [key: string]: IPageCountMeterReadType;
}

export class PageCountMeterRead implements IPageCountMeterRead {
    public static columns: Array<Column> = [
        ...Column.extrapolate(
            Column.extrapolate(
                [
                    {
                        name: '',
                        keys: colKeyOneProp(''),
                        description:
                            'Represents one of several metrics used to measure the number of' +
                            ' pages printed or scanned by the device; note that not all printers ' +
                            'can print every type of page, nor can every device scan or fax.',
                        display: Column.displayFunctions['tensPlace'],
                        sortable: false
                    }
                ],
                [
                    {
                        override: {
                            name: 'Total',
                            keys: colKeyOneProp('total.value')
                        }
                    },
                    {
                        override: {
                            name: 'Total Color',
                            keys: colKeyOneProp('totalColor.value')
                        }
                    },
                    {
                        override: {
                            name: 'Total Black',
                            keys: colKeyOneProp('totalBlack.value')
                        }
                    },
                    {
                        override: {
                            name: 'Total Prints',
                            keys: colKeyOneProp('totalPrints.value')
                        }
                    },
                    {
                        override: {
                            name: 'Prints Color',
                            keys: colKeyOneProp('totalPrintsColor.value')
                        }
                    },
                    {
                        override: {
                            name: 'Prints Black',
                            keys: colKeyOneProp('totalPrintsBlack.value')
                        }
                    },
                    {
                        override: {
                            name: 'Total Copies',
                            keys: colKeyOneProp('totalCopies.value')
                        }
                    },
                    {
                        override: {
                            name: 'Copies Color',
                            keys: colKeyOneProp('totalCopiesColor.value')
                        }
                    },
                    {
                        override: {
                            name: 'Copies Black',
                            keys: colKeyOneProp('totalCopiesBlack.value')
                        }
                    },
                    {
                        override: {
                            name: 'Total Faxes',
                            keys: colKeyOneProp('totalFaxes.value')
                        }
                    },
                    {
                        override: {
                            name: 'Total Scans',
                            keys: colKeyOneProp('totalScans.value')
                        }
                    },
                    {
                        override: {
                            name: 'A3',
                            keys: colKeyOneProp('a3.value')
                        }
                    },
                    {
                        override: {
                            name: 'A3 Black',
                            keys: colKeyOneProp('a3Black.value')
                        }
                    },
                    {
                        override: {
                            name: 'A3 Color',
                            keys: colKeyOneProp('a3Color.value')
                        }
                    },
                    {
                        override: {
                            name: 'A4',
                            keys: colKeyOneProp('a3.value')
                        }
                    },
                    {
                        override: {
                            name: 'A4 Black',
                            keys: colKeyOneProp('a3Black.value')
                        }
                    },
                    {
                        override: {
                            name: 'A4 Color',
                            keys: colKeyOneProp('a3Color.value')
                        }
                    }
                ]
            ),
            [
                {
                    namePrefix: 'Billing -',
                    keyPrefix: 'default'
                },
                {
                    namePrefix: 'Life -',
                    keyPrefix: 'life'
                },
                {
                    namePrefix: 'Equivalent -',
                    keyPrefix: 'equiv'
                },
                {
                    namePrefix: 'Non-Equivalent -',
                    keyPrefix: 'nonEquiv'
                },
                {
                    namePrefix: 'Square-feet -',
                    keyPrefix: 'sqFt'
                },
                {
                    namePrefix: 'Linear-feet -',
                    keyPrefix: 'lnFt'
                },
                {
                    namePrefix: 'Square-meter -',
                    keyPrefix: 'sqMt'
                },
                {
                    namePrefix: 'Linear-meter -',
                    keyPrefix: 'lnMt'
                },
                {
                    namePrefix: 'Pages -',
                    keyPrefix: 'pages'
                },
                {
                    namePrefix: 'Adjusted -',
                    keyPrefix: 'adjusted'
                },
                {
                    namePrefix: 'Centimeters -',
                    keyPrefix: 'centimeters'
                },
                {
                    namePrefix: 'Engine -',
                    keyPrefix: 'engine'
                },
                {
                    namePrefix: 'Develop -',
                    keyPrefix: 'dev'
                }
            ]
        ),
        {
            name: 'Canon 101 (Total 1)',
            keys: colKeyOneProp('canon.101.value'),
            description:
                'Canon meter that usually represents total, not accounting for oversize or duplex.',
            display: Column.displayFunctions['tensPlace'],
            sortable: false
        },
        {
            name: 'Canon 102 (Total 2)',
            keys: colKeyOneProp('canon.102.value'),
            description:
                'Canon meter that usually represents total, accounting for oversize and duplex.',
            display: Column.displayFunctions['tensPlace'],
            sortable: false
        },
        {
            name: 'Canon 108 (Total Black 1)',
            keys: colKeyOneProp('canon.108.value'),
            description:
                'Canon meter that usually represents total black, not accounting for oversize ' +
                'or duplex.',
            display: Column.displayFunctions['tensPlace'],
            sortable: false
        },
        {
            name: 'Canon 109 (Total Black 2)',
            keys: colKeyOneProp('canon.109.value'),
            description:
                'Canon meter that usually represents total black, accounting for oversize and ' +
                'duplex.',
            display: Column.displayFunctions['tensPlace'],
            sortable: false
        },
        {
            name: 'Canon 124 (Full Color + Single Color 2)',
            keys: colKeyOneProp('canon.124.value'),
            description: 'Canon meter that usually represents total color.',
            display: Column.displayFunctions['tensPlace'],
            sortable: false
        },
        {
            name: 'Canon 125 (Full Color + Single Color 1)',
            keys: colKeyOneProp('canon.125.value'),
            description: 'Canon meter that usually represents total color.',
            display: Column.displayFunctions['tensPlace'],
            sortable: false
        }
    ];

    public default: IPageCountMeterReadType = new PageCountMeterReadType();
    [key: string]: IPageCountMeterReadType;

    constructor(defaults?: Partial<IPageCountMeterRead>) {
        if (defaults) {
            for (const key in defaults) {
                this[key] = new PageCountMeterReadType(defaults[key]);
            }
        }
    }
}
