import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleChartDirective } from './directives/google-chart.directive';
import { GoogleCharts } from './services/google-charts.service';

@NgModule({
    imports: [CommonModule],
    exports: [GoogleChartDirective],
    declarations: [GoogleChartDirective],
    entryComponents: [],
    providers: [GoogleCharts]
})
export class RiafoxChartsModule {}
