enum Status {
    InActive = 0,
    Active = 1,
    Deleted = 2
}

namespace Status {
    export function toString(type: Status): string {
        switch (type) {
            case Status.InActive:
                return 'Inactive';
            case Status.Active:
                return 'Active';
            case Status.Deleted:
                return 'Deleted';
            default:
                return '';
        }
    }

    export function asArray(): Status[] {
        return [Status.InActive, Status.Active];
    }
}

export { Status };
