<ptkr-block-header title="Select shipping method"></ptkr-block-header>

<div class="method-wrapper">
    <div class="method" *ngFor="let m of shippingMethods; let i = index;" (click)="selectMethod(m, i)" [ngClass]="{'selected': shippingMethodSelection[i]}">
        <span class="method-type">{{ m.type }}</span><br>
        <span class="method-price">{{ m.price | currency }}</span><br>
        <strong>Estimated Delivery Date:</strong> {{ m.deliveryDate | date:"MM/dd/yyyy" }}
        <div class="checked" *ngIf="shippingMethodSelection[i]">
            <i class="fa fa-check" aria-hidden="true"></i>
        </div>
    </div>
    <div class="method filler"></div>
</div>
