import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'ptkr-include-children-toggle',
    templateUrl: './include-children-toggle.component.html',
    styleUrls: ['./include-children-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncludeChildrenToggleComponent implements OnInit {
    @Input()
    public checked: boolean = false;

    @Output()
    public change: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}

    public onChange(event: MatSlideToggleChange): void {
        this.checked = event.checked;
        this.change.emit(this.checked);
    }
}
