import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

@Component({
    selector: 'ptkr-verify-ach',
    templateUrl: './verify-ach.component.html',
    styleUrls: ['./verify-ach.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyAchComponent implements OnInit {
    public errorMessage: string = '';
    public amnt1: FormControl = new FormControl(undefined, [
        Validators.required,
        CustomValidators.digits
    ]);
    public amnt2: FormControl = new FormControl(undefined, [
        Validators.required,
        CustomValidators.digits
    ]);

    constructor(public dialogRef: MatDialogRef<VerifyAchComponent>) {}

    ngOnInit(): void {}

    public verify(amount1: number, amount2: number): void {
        this.errorMessage = '';
        if (amount1 >= 1 && amount2 >= 1) {
            this.dialogRef.close({
                amount1: amount1,
                amount2: amount2
            });
        } else {
            this.errorMessage = 'Invalid Amounts';
        }
    }
}
