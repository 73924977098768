import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ptkr-event-note',
    templateUrl: './event-note.component.html',
    styleUrls: ['./event-note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventNoteComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<EventNoteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { note: string; writeEnabled: boolean }
    ) {}

    ngOnInit(): void {}

    public saveNote(note: string): void {
        this.dialogRef.close(note);
    }
}
