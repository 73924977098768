import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { timezones } from '@libs/iso/core/constants';
import { SNMPAlgorithm } from '@libs/iso/core/enums/SNMPAlgorithm';
import { PageCountMeterRead } from '@libs/iso/core/models/meterRead/PageCountMeterRead';
import { CronOptions } from '@libs/web/cron/CronOptions';
import { DeviceDynamicSettingsSectionFormGroup } from '@libs/web/forms';
import cronstrue from 'cronstrue';
import { AbstractSettingsSectionComponent } from '../abstract-settings-section/abstract-settings-section.component';
import { SettingsKeyMap } from '@libs/iso/core';

@Component({
    selector: 'ptkr-device-dynamic-settings-section',
    templateUrl: './device-dynamic-settings-section.component.html',
    styleUrls: ['./device-dynamic-settings-section.component.scss']
})
export class DeviceDynamicSettingsSectionComponent
    extends AbstractSettingsSectionComponent<DeviceDynamicSettingsSectionFormGroup>
    implements OnInit {
    @Input() public isDevice: boolean = false;
    @Input('billingMeterOptions') public billingMeterOptions: PageCountMeterRead;
    @Input() public defaultTotalPageCounts: number;
    @Input() public lastReadDtTm: Date;

    public timezoneOptions: string[] = Object.keys(timezones);
    public SNMPAlgorithm: typeof SNMPAlgorithm = SNMPAlgorithm;
    public prettyCron: string;
    public separatorKeysCodes: any = [ENTER, COMMA, SEMICOLON, TAB];
    public cronOptions: CronOptions = {
        formInputClass: 'form-control cron-editor-input',
        formSelectClass: 'form-control cron-editor-select',
        formRadioClass: 'cron-editor-radio',
        formCheckboxClass: 'cron-editor-checkbox',

        defaultTime: '00:00:00',
        use24HourTime: false,

        hideMinutesTab: true,
        hideHourlyTab: true,
        hideDailyTab: false,
        hideWeeklyTab: false,
        hideMonthlyTab: false,
        hideYearlyTab: true,
        hideAdvancedTab: true,

        /** hides the Seconds UI form element */
        hideSeconds: true,

        /** removes Seconds from the Cron expression */
        removeSeconds: true,

        /** removes Years from the Cron expression */
        removeYears: true
    };

    constructor(private _cd: ChangeDetectorRef) {
        super();
    }

    /**
     * Initializes the cron editor human-readable preview and initializes volume frequency control
     * regulation such that frequency is only able to be modified when recurring is true.
     * @returns {void}
     */
    public ngOnInit(): void {
        this._updatePrettyCron(
            this.getFieldValue(this.SettingsKeyMap.BillingPreferences.Schedule.Expression) ?? 'no'
        );
        const recurringControl: AbstractControl = this.getField(
            this.SettingsKeyMap.Alerts.Volume.Recurring
        );
        this._updateVolumeFrequencyState(recurringControl.value);
        recurringControl.valueChanges.subscribe((value: boolean) => {
            this._updateVolumeFrequencyState(value);
        });
    }

    public onCronUpdate(event: string): void {
        const control = this.getField(this.SettingsKeyMap.BillingPreferences.Schedule.Expression);
        if (control !== null && control !== undefined) {
            this._updatePrettyCron(event);
        }
    }

    public onCronReverted(): void {
        const control = this.getField(this.SettingsKeyMap.BillingPreferences.Schedule.Expression);
        this._updatePrettyCron(control.value);
        this._cd.detectChanges();
    }

    public isVolumeFrequencyEnabled(): boolean {
        return !!this.getField(this.SettingsKeyMap.Alerts.Volume.Frequency)?.enabled;
    }

    public isSalesQuoteEnabled(): boolean {
        return this.getFieldValue(SettingsKeyMap.Integrations.EAutomate.SalesQuote.Enabled);
    }

    /**
     * Initializes the cron editor's human-readable preview text.
     * @param {string} expression  the cron expression
     * @returns {void}
     * @private
     */
    private _updatePrettyCron(expression: string): void {
        this.prettyCron = cronstrue.toString(expression);
    }

    private _updateVolumeFrequencyState(value: boolean): void {
        const frequencyControl: AbstractControl = this.getField(
            this.SettingsKeyMap.Alerts.Volume.Frequency
        );
        if (!!value) {
            if (!frequencyControl.enabled) {
                frequencyControl.enable({ onlySelf: true, emitEvent: false });
            }
        } else {
            if (!frequencyControl.disabled) {
                frequencyControl.disable({ onlySelf: true, emitEvent: false });
            }
        }
    }
}
