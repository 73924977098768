import { IBaseModel, BaseModel } from '../BaseModel';
import { Moment } from 'moment';
import { InvoiceStatus } from '../../enums';

export interface InvoiceLineItemModel extends IBaseModel {
    entityName: string;
    invoiceNumber: string;
    date: Date | Moment;
    dueDate: Date | Moment;
    invoiceStatus: InvoiceStatus | string;
    salesOrderNumber: string;
    poNumber: string;
    salesOrderTotal: number;
    invoiceTotal: number;
}

interface InvoiceLineItemConstructorParams extends InvoiceLineItemModel {}

export class InvoiceLineItem extends BaseModel implements InvoiceLineItemModel {
    public entityName: string = '';
    public invoiceNumber: string = '';
    public salesOrderNumber: string = '';
    public date: Date | Moment = null;
    public dueDate: Date | Moment = null;
    public invoiceStatus: InvoiceStatus | string = null;
    public poNumber: string;
    public salesOrderTotal: number;
    public invoiceTotal: number;

    constructor(params?: Partial<InvoiceLineItemConstructorParams>) {
        super(params);
        if (!!params) {
            this.entityName = params.entityName || this.entityName;
            this.invoiceNumber = params.invoiceNumber || this.invoiceNumber;
            this.date = params.date || this.date;
            this.dueDate = params.dueDate || this.dueDate;
            this.invoiceStatus = params.invoiceStatus || this.invoiceStatus;
            this.salesOrderNumber = params.salesOrderNumber || this.salesOrderNumber;
            this.poNumber = params.poNumber || this.poNumber;
            this.salesOrderTotal = params.salesOrderTotal || this.invoiceTotal;
            this.invoiceTotal = params.invoiceTotal || this.invoiceTotal;
        }
    }
}
