import { Component, OnInit, Input } from '@angular/core';
import { Device } from '@app/models';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Permission } from '@libs/iso/core';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromUser } from '@app/state/selectors';
import { take } from 'rxjs/operators';

@Component({
    selector: 'ptkr-shared-starting-page-counts',
    templateUrl: './device-starting-page-counts.component.html',
    styleUrls: ['./device-starting-page-counts.component.scss']
})
export class DeviceStartingPageCountsComponent implements OnInit {
    private _device: Device;
    @Input('device')
    public set device(value: Device) {
        this._device = value;
        this.createForm();
    }
    public get device(): Device {
        return this._device;
    }

    public startingPageCountsForm: FormGroup;

    constructor(private _fb: FormBuilder, private _store: Store<GlobalStore>) {}

    ngOnInit(): void {}

    public createForm(): void {
        if (!this.startingPageCountsForm) {
            this.startingPageCountsForm = this._fb.group({
                ['total']: 0,
                ['totalBlack']: 0,
                ['totalColor']: 0 // TODO
            });
        }

        let userPermissions = [];
        this._store
            .pipe(select(fromUser.permissions), take(1))
            .subscribe(perms => (userPermissions = perms));
        if (!Permission.hasPermission(Permission.UpdateDeviceSettings, userPermissions)) {
            this.startingPageCountsForm.disable();
        }
    }

    public validate(): any {
        this.startingPageCountsForm.updateValueAndValidity();
        const controlKeys = Object.keys(this.startingPageCountsForm.controls);
        let valid = true;
        for (const key of controlKeys) {
            const c = this.startingPageCountsForm.controls[key];
            if (!c.disabled) {
                c.updateValueAndValidity();
                if (!c.valid) {
                    c.markAsTouched();
                    valid = false;
                    break;
                }
                // Make sure we are passing in positive integer (or zero)
                if (!this._validateNumber(c.value)) {
                    valid = false;
                    break;
                }
            }
        }
        return valid && this.startingPageCountsForm.valid && !this.startingPageCountsForm.disabled;
    }

    private _validateNumber(num: string): boolean {
        /* tslint:disable-next-line:max-line-length ter-max-len */
        const re = /^\d+$/;
        return re.test(num);
    }
}
