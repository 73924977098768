import { DeviceModel as BaseDeviceModel, Device as BaseDevice } from '@libs/iso/core';
import { IDeviceEvent, DeviceEvent } from './DeviceEvent';
import { Status } from 'libs/rfx-server/db/models';
export interface DeviceModel extends BaseDeviceModel {
    _id: string;
    entityKey: string;
    itemKey: string;
    events: IDeviceEvent[];
    entityName?: string;
    isLocal: boolean;
}

export class Device extends BaseDevice implements DeviceModel {
    public _id: string = '';
    public entityKey: string = '';
    public itemKey: string = '';
    public events: DeviceEvent[] = [];
    public entityName?: string = '';
    public status: Status = Status.Active;
    public isLocal: boolean = false;

    constructor(params?: Partial<DeviceModel>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            this.entityKey = params.entityKey || this.entityKey;
            this.itemKey = params.itemKey || this.itemKey;
            if (Array.isArray(params.events)) {
                this.events = params.events.map(e => new DeviceEvent(e));
            }
            this.entityName = params.entityName || this.entityName;
            this.status = params.status || this.status;
            this.isLocal = params.isLocal || this.isLocal;
        }
    }
}
