<div>
    <form>
        <div>
            <div class="label section-title" i18n>Data Gathering Settings</div>
            <div class="label-subheading" i18n>
                Change settings for how data is collected from this device.
            </div>
        </div>

        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Data Gathering Settings
                    </mat-panel-title>
                </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <form [formGroup]="deviceDataGatheringSettingsForm">
                            <div class="form-element-margin toggle">
                                <div class="form-element-margin">
                                    <div class="label">
                                        <span class="m-r-5" i18n>Data Gathering Instruction</span>
                                        <a matTooltip="Here you can pick what version of this device's DGI to use." i18n-matTooltip><small i18n="@@whatsThis">What's this?</small></a>
                                    </div>
                                    <ptkr-dgi-version-selector [itemKey]="itemKey" [formControlName]="UpdateKeyMap.Settings.DataGathering.Dgi.Key"></ptkr-dgi-version-selector>
                                </div>
                            </div>
                        </form>
                    </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</div>
