<table
    mat-table
    matSort
    multiTemplateDataRows
    class="g-table"
    [dataSource]="rows"
    (matSortChange)="onUpdateSort($event)"
    #dataTable
>
    <!-- selectable column goes here -->
    <ng-container *ngFor="let col of colKeys" [matColumnDef]="col">
        <!-- HEADER : Nested ifs decide whether or not there should be sorting icon, as well as
        deciding if the arrow should be present currently (i.e. we are sorting by that header) or
        not. -->
        <ng-container *ngIf="!columns[col].sortable">
            <th mat-header-cell *matHeaderCellDef>
                {{ columns[col].name }}
            </th>
        </ng-container>
        <ng-container *ngIf="columns[col].sortable">
            <th mat-header-cell [mat-sort-header]="col" *matHeaderCellDef>
                {{ columns[col].name }}
            </th>
        </ng-container>

        <!-- CELLS -->
        <td mat-cell *matCellDef="let data">
            <ng-container *ngIf="columns[col].amTimeAgo == true; else displayTemplate">
                {{ (rawData(data, columns[col]) | amTimeAgo) || '--' }}
            </ng-container>
            <ng-template #displayTemplate>
                {{ display(data, columns[col]) }}
            </ng-template>
        </td>
    </ng-container>

    <!-- EXTRA COLUMNS: For custom components, buttons, links etc -->
    <ng-container matColumnDef="actions" stickyEnd>
        <th class="sticky-end-header" mat-header-cell *matHeaderCellDef></th>
        <td
            mat-cell
            class="sticky-end-cell"
            *matCellDef="let data"
            (click)="$event.stopPropagation(); selectedRow = data"
            [matMenuTriggerFor]="installMenu"
        >
            <span class="action-icon">
                <mat-icon>more_vert</mat-icon>
            </span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getColumnStringList()"></tr>
    <tr mat-row *matRowDef="let row; columns: getColumnStringList()"></tr>
</table>

<ng-container *ngIf="tableData.totalDocs <= 0">
    <h3 class="norows" i18n>No results to display</h3>
</ng-container>

<mat-menu #installMenu="matMenu" [overlapTrigger]="false">
    <button
        *ngFor="let action of actions"
        (click)="actionClicked.emit({ name: action, row: selectedRow })"
        mat-menu-item
    >
        {{ action }}
    </button>
</mat-menu>
