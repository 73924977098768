import { TimeFrame } from '@libs/iso/core';
import { Moment } from 'moment';

export class TimeFrameChange {
    constructor(
        public timeframe: TimeFrame,
        public startDate: Moment | null,
        public endDate: Moment | null
    ) {}
}
