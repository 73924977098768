import { EntityStore, initEntityState } from '../store';
import {
    EntityActions,
    EntityActionsType,
    // LoadEntityAction,
    ClearEntityAction,
    // UpdateEntityAction,
    SetEntityAction,
    LoginEntityAction,
    ChangeEntityAction
} from '../actions/entity.actions';
import { Entity } from '@app/models';

class EntityHandlers {
    // public static handleLoadEntity(state: EntityStore, action: LoadEntityAction): EntityStore {
    //     return new Entity(action.payload);
    // }

    public static handleSetEntity(state: EntityStore, action: SetEntityAction): EntityStore {
        return new Entity({
            ...state,
            ...action.payload
        });
    }

    public static handleLoginEntity(state: EntityStore, action: LoginEntityAction): EntityStore {
        return new Entity(action.payload);
    }

    public static handleChangeEntity(state: EntityStore, action: ChangeEntityAction): EntityStore {
        return new Entity(action.payload);
    }

    public static handleClearEntity(state: EntityStore, action: ClearEntityAction): EntityStore {
        return new Entity();
    }

    // public static handleUpdateEntity(state: EntityStore, action: UpdateEntityAction): EntityStore {
    //     return new Entity({
    //         ...state,
    //         ...action.payload
    //     });
    // }
}

export function entityReducer(
    state: EntityStore = initEntityState,
    action: EntityActionsType
): EntityStore {
    switch (action.type) {
        case EntityActions.SetEntity:
            return EntityHandlers.handleSetEntity(state, action);
        // case EntityActions.LoadEntity:
        //     return EntityHandlers.handleLoadEntity(state, action);
        case EntityActions.ClearEntity:
            return EntityHandlers.handleClearEntity(state, action);
        // case EntityActions.UpdateEntity:
        //     return EntityHandlers.handleUpdateEntity(state, action);
        case EntityActions.LoginEntity:
            return EntityHandlers.handleLoginEntity(state, action);
        case EntityActions.ChangeEntity:
            return EntityHandlers.handleChangeEntity(state, action);
        default:
            return state;
    }
}
