import { Component, forwardRef, Type } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import { AVAILABLE_FIELDS } from './components';
import {
    EntityDynamicDeviceGroupField,
    EntityDynamicDeviceGroups,
    PropertiesOf
} from '@libs/iso/core';

@Component({
    selector: 'ptkr-device-dynamic-groups-setting',
    templateUrl: './device-dynamic-groups-setting.component.html',
    styleUrls: ['./device-dynamic-groups-setting.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DeviceDynamicGroupsSettingComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DeviceDynamicGroupsSettingComponent),
            multi: true
        }
    ]
})
export class DeviceDynamicGroupsSettingComponent
    implements ControlValueAccessor, Validator {
    public deviceGroupForm: FormGroup;
    public availableFields = AVAILABLE_FIELDS;
    protected readonly EntityDynamicDeviceGroupField = EntityDynamicDeviceGroupField;
    public onChange: any = () => {};
    public onTouched: any = () => {};

    constructor(private fb: FormBuilder) {
        this.initForm();
    }

    private initForm() {
        this.deviceGroupForm = this.fb.group({
            field: new FormControl(0, Validators.required),
            groups: this.fb.array([])
        });
        this.deviceGroupForm.get('field').valueChanges.subscribe(
            value => {
                if (value === 0) {
                    this.initForm();
                }
            },
            error => console.error('Error:', error)
        );
        this.deviceGroupForm.valueChanges.subscribe(value => {
            // https://github.com/angular/components/issues/25120
            const field = this.deviceGroupForm.value.field;
            if (field === 0) {
                this.onChange(null);
                return;
            }
            this.onChange(value);
            this.onTouched();
        });
    }

    writeValue(value: PropertiesOf<EntityDynamicDeviceGroups>): void {
        if (value) {
            console.log(value);

            this.deviceGroupForm.get('field').setValue(value.field ?? null, { emitEvent: false });
            this.deviceGroupForm.setControl(
                'groups',
                this.fb.array(
                    value.groups.map(g =>
                        this.fb.group({
                            name: [g.name, Validators.required],
                            options: this.fb.group({
                                selector: [g.options.selector, Validators.required]
                            })
                        })
                    )
                )
            );
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.deviceGroupForm.disable();
        } else {
            this.deviceGroupForm.enable();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.deviceGroupForm.valid
            ? null
            : { invalidForm: { valid: false, message: 'Device groups are invalid' } };
    }

    groups(): FormArray {
        const groups = this.deviceGroupForm.get('groups') as FormArray;
        // console.log(groups)
        return groups;
    }

    addGroup() {
        const group = this.fb.group({
            name: ['', Validators.required],
            options: this.fb.group({
                selector: ['', Validators.required]
            })
        });
        this.groups().push(group);
    }

    removeGroup(index) {
        (this.deviceGroupForm.get('groups') as FormArray).removeAt(index);
    }

    getFieldComponent(): Type<any> {
        const field = this.deviceGroupForm.get('field').value;
        return this.availableFields.find(f => f.type === field)?.component;
    }
}
