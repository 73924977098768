import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormMessageService } from '@app/core/services/form-message.service';
import { NotificationService } from 'app/src/app/core/services/notification.service';
import { LocalStorageService } from '@app/core/services';

@Component({
    selector: 'ptkr-confirm-settings-modal',
    templateUrl: './confirm-settings-modal.component.html',
    styleUrls: ['./confirm-settings-modal.component.scss']
})
export class ConfirmSettingsModalComponent {
    public formGroup: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<ConfirmSettingsModalComponent>,
        private _notificationService: NotificationService,
        private _localStorageService: LocalStorageService,
        public formMessageService: FormMessageService,
        @Inject(MAT_DIALOG_DATA) public data: { userEmail: string }
    ) {
        const customBody = this._localStorageService.getItem(LocalStorageService.Key.InstallationCustomEmailBody);
        this.formGroup = new FormGroup({
            to: new FormControl([], Validators.required),
            doCc: new FormControl(true),
            cc: new FormControl(data?.userEmail ? [data?.userEmail] : []),
            customBody: new FormControl(customBody)
        });
    }

    public getControl(name: string): AbstractControl {
        return this.formGroup?.controls?.[name];
    }

    public getControlValue(name: string): any {
        return this.formGroup?.controls?.[name]?.value;
    }

    /* Actions */

    public cancel(): void {
        this.dialogRef.close({
            do: 'nothing'
        });
    }

    public sendEmail(): void {
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.valid) {
            this._localStorageService.setItem(LocalStorageService.Key.InstallationCustomEmailBody, this.getControlValue('customBody'));
            this.dialogRef.close({
                do: 'send',
                email: this.getControlValue('to'),
                cc: this.getControlValue('doCc')
                    ? this.getControlValue('cc')
                    : undefined,
                customBody: this.getControlValue('customBody')
            });
        } else {
            if (!!this.formGroup?.errors?.duplicateEmail) {
                this._notificationService.error('No duplicate emails allowed');
            }
        }
    }
}
