import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ptkr-shipping-address-modal',
    templateUrl: './shipping-address-modal.component.html',
    styleUrls: ['./shipping-address-modal.component.scss']
})
export class ShippingAddressModalComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ShippingAddressModalComponent>) {}

    ngOnInit(): void {}

    public newAddress(e): void {
        this.dialogRef.close(e);
    }
}
