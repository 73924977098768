import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ShippingAddress } from '@libs/iso/core';
import { AddressFormComponent } from '@libs/web/forms';
import { Subject } from 'rxjs';

interface FormValue {
    companyName: string;
    attn: string;
}

@Component({
    selector: 'ptkr-add-shipping-address',
    templateUrl: './add-shipping-address.component.html',
    styleUrls: ['./add-shipping-address.component.scss']
})
export class AddShippingAddressComponent implements OnInit, OnDestroy {
    public newShippingAddress: ShippingAddress;
    public addShippingAddressForm: FormGroup;
    @ViewChild('addressForm', {static: true})
    public addressForm: AddressFormComponent;
    @Output()
    public formComplete: EventEmitter<any> = new EventEmitter<any>();
    private _ngUnsub: Subject<void> = new Subject<void>();

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.addShippingAddressForm = this._fb.group({
            companyName: ['', Validators.required],
            attn: ['', Validators.required]
        });
    }

    ngOnDestroy(): void {
        this._ngUnsub.next();
        this._ngUnsub.complete();
        this._ngUnsub = undefined;
    }

    public saveShippingAddressForm({ valid, value }: { valid: boolean; value: FormValue }): void {
        const addressFormValue = this.addressForm.getValue();
        const addressFormValid: boolean = this.addressForm.validate();
        if (!valid || !addressFormValid) {
            return;
        }
        this.newShippingAddress = new ShippingAddress({
            companyName: value.companyName,
            attn: value.attn,
            address1: addressFormValue.address1,
            address2: addressFormValue.address2,
            city: addressFormValue.city,
            stateOrProvince: addressFormValue.stateOrProvince,
            zipOrPostCode: addressFormValue.zipOrPostCode,
            country: addressFormValue.country
        });
        this.formComplete.emit(this.newShippingAddress);
    }
}
