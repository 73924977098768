import { Device, DeviceModel, DeviceReport, Item, AuditLogItem, MeterRead } from '@libs/iso/core';

export interface DeviceLineItemModel extends DeviceModel {
    alerts: Event[];
    history: AuditLogItem[];
    pagesReportData: DeviceReport;
    tonerReportData: DeviceReport;
    selected: boolean;
    compatible: Item[];
    isLocal: boolean;
    entityName: string;
    currentMeterRead: MeterRead;
}

interface DeviceLineItemConstructorParams extends DeviceLineItemModel {}

export class DeviceLineItem extends Device implements DeviceLineItemModel {
    public alerts: Event[] = [];
    public history: AuditLogItem[] = [];
    public pagesReportData: DeviceReport = new DeviceReport();
    public tonerReportData: DeviceReport = new DeviceReport();
    public selected: boolean = false;
    public compatible: Item[] = [];
    public isLocal: boolean = false;
    public entityName: string;
    public currentMeterRead: MeterRead;

    constructor(params?: Partial<DeviceLineItemConstructorParams>) {
        super(params);
        if (!!params) {
            if (params.alerts) {
                this.alerts = [];
                this.history = params.history;
            }
            if (!!params.pagesReportData) {
                this.pagesReportData = new DeviceReport(params.pagesReportData);
            }
            if (!!params.tonerReportData) {
                this.tonerReportData = new DeviceReport(params.tonerReportData);
            }
            if (Array.isArray(params.compatible)) {
                this.compatible = params.compatible.map(c => new Item(c));
            }
            this.selected = params.selected || this.selected;
            this.isLocal = params.isLocal || this.isLocal;
            this.entityName = params.entityName || this.entityName;
            this.currentMeterRead = params.currentMeterRead;
        }
    }
}
