import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalConfig } from '@app/models';

@Component({
    selector: 'ptkr-restart-job-confirmation',
    templateUrl: './restart-job-confirmation.component.html',
    styleUrls: ['./restart-job-confirmation.component.css']
})
export class RestartJobConfirmationComponent implements OnInit {
    public standalone: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<RestartJobConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmModalConfig
    ) {}

    ngOnInit(): void {}
}
