<h2>{{ dialogData.title }}</h2>
<form [formGroup]="formGroup">
    <mat-form-field appearance="outline" floatLabel="never" class="left">
        <mat-label i18n="@@name">Name</mat-label>
        <input matInput placeholder="Name" i18n-placeholder="@@name" type="text" formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="never">
        <mat-label i18n="@@value">Value</mat-label>
        <input matInput placeholder="Value" i18n-placeholder="@@value" type="text" formControlName="value">
    </mat-form-field>
</form>
<div class="action-buttons" style="float: right;">
    <button mat-button color="accent" (click)="cancel()" i18n="@@cancel">Cancel</button>
    <button mat-button color="accent" (click)="confirm()" i18n="@@confirm">Confirm</button>
</div>