import { Type } from '@angular/core';
import { SingleIpRangeInputComponent } from './single-ip-range-input/single-ip-range-input.component';
import { EntityDynamicDeviceGroupField } from '@libs/iso/core';

interface FieldConfig {
    type: EntityDynamicDeviceGroupField;
    component: Type<any>;
}

export const AVAILABLE_FIELDS: FieldConfig[] = [
    { type: EntityDynamicDeviceGroupField.IPAddress, component: SingleIpRangeInputComponent },
];
