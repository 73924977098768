<h1>{{ title || 'SuperTable' }}</h1>
<mat-card>
    <!-- PARAMS -->
    <div>
        <div class="param-row">
            <!-- COLLECTION PICKER -->
            <div
                *ngIf="!collectionIsHardcoded"
                matTooltip="Which collection to load data from."
                i18n-matTooltip
                [matTooltipShowDelay]="500"
                class="param-wrapper neighbor-on-right"
            >
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label i18n>
                        Collection
                    </mat-label>
                    <mat-select
                        [(value)]="currentCollection"
                        (selectionChange)="changeCollection($event)"
                    >
                        <mat-option *ngFor="let col of collections" [value]="col">{{
                            getCollectionName(col, false, true)
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- COLUMN EDITOR -->
            <div class="param-wrapper neighbor-on-right">
                <button
                    mat-stroked-button
                    (click)="editColumns()"
                    matTooltip="Opens panel where you can drag and drop columns into a custom order."
                    i18n-matTooltip
                    [matTooltipShowDelay]="500"
                    i18n
                >
                    Edit Columns
                </button>
            </div>

            <!-- EXPORT TO CSV -->
            <div class="param-wrapper">
                <button
                    mat-flat-button
                    color="accent"
                    (click)="exportCSV()"
                    matTooltip="Downloads results of query (all pages) as a CSV file."
                    [matTooltipShowDelay]="500"
                    i18n
                >
                    Export to CSV
                </button>
            </div>

            <div class="spacer"></div>

            <!-- INCLUDE CHILDREN TOGGLE -->
            <div
                class="param-wrapper"
                matTooltip="Will show rows from child and descendant entitites."
                [matTooltipShowDelay]="500"
            >
                <ptkr-include-children-toggle
                    [checked]="tableParams?.includeChildren || false"
                    (change)="toggleIncludeChildren($event)"
                ></ptkr-include-children-toggle>
            </div>
        </div>

        <!-- DATE PICKER -->
        <div *ngIf="tableParams.collection == 'meterViewer'" class="param-row">
            <div class="param-wrapper">
                <!-- If we are viewing billing reports, only show a start date -->
                <ng-container *ngIf="getIsBillingReport() === true; else notBillingReport">
                    <mat-form-field class="date-picker-element" appearance="outline">
                        <input
                            matInput
                            [matDatepicker]="sd"
                            placeholder="Start date"
                            i18n-placeholder
                            [(ngModel)]="billingReportDate"
                        />
                        <mat-datepicker-toggle matSuffix [for]="sd"></mat-datepicker-toggle>
                        <mat-datepicker #sd disabled="false"></mat-datepicker>
                    </mat-form-field>
                </ng-container>
                <ng-template #notBillingReport>
                    <mat-form-field
                        class="date-picker-element"
                        appearance="outline"
                        matTooltip="Set date range to view"
                        i18n-matTooltip
                        [matTooltipShowDelay]="500"
                    >
                        <mat-date-range-input [rangePicker]="datePicker">
                            <input
                                matStartDate
                                [(ngModel)]="newStartDate"
                                placeholder="Start Date"
                                i18n-placeholder
                            />
                            <input matEndDate [(ngModel)]="newEndDate" placeholder="End Date" i18n-placeholder />
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #datePicker></mat-date-range-picker>
                        <mat-error></mat-error>
                    </mat-form-field>
                </ng-template>
                <section class='date-filter-section'>
                    <label class='date-filter-margin' i18n>Filter:</label>
                    <mat-radio-group [(ngModel)]="isBillingReport">
                        <mat-radio-button class='date-filter-margin' [value]="false" i18n>custom date-range</mat-radio-button>
                        <mat-radio-button class='date-filter-margin' [value]="true" i18n>billing date</mat-radio-button>
                    </mat-radio-group>
                    <mat-checkbox class='date-filter-margin' [disabled]="!isBillingReport" [(ngModel)]="hideBilled" i18n>hide billed</mat-checkbox>
                </section>
                <button
                    mat-flat-button
                    class="date-picker-element"
                    color="accent"
                    (click)="pickDates()"
                    i18n
                >
                    Set Dates
                </button>
                <button
                    *ngIf="tableParams.isBillingReport"
                    mat-flat-button
                    color="accent"
                    (click)="setAllAsBilled()"
                    matTooltip="Sets all billing reports matching the current query to 'billed',
                    all subsequent meter-reads will be added to the following billing report."
                    i18n-matTooltip
                    i18n
                >
                    Set as billed
                </button>
            </div>
        </div>
        <div class="param-row"></div>
        <!-- SEARCH BAR -->
        <div
            class="param-wrapper search-wrapper"
            matTooltip="Only show rows with the searched text in any of their columns."
            i18n-matTooltip
            [matTooltipShowDelay]="500"
        >
            <ptkr-search-bar
                placeholder="Search all columns"
                i18n-placeholder
                [defaultValue]="
                    !!tableParams && tableParams.searchTerm ? tableParams.searchTerm : ''
                "
                (textChanged)="filter = $event"
                (searchClicked)="search($event)"
            ></ptkr-search-bar>
        </div>
    </div>

    <!-- TABLE -->
    <ng-container
        *ngIf="
            !!tableData &&
                tableData.totalDocs > 0 &&
                tableParams.columns &&
                tableParams.columns.length > 0;
            else emptyTable
        "
    >
        <div class="table-container">
            <ptkr-data-table
                [tableData]="tableData"
                [actions]="getActionNames()"
                (updateSort)="onUpdateSort($event)"
                (actionClicked)="onActionClicked($event)"
            ></ptkr-data-table>
        </div>
    </ng-container>
    <ng-template #emptyTable>
        <div>
            <h2 class="empty-table" i18n>No data to show :(</h2>
        </div>
    </ng-template>

    <!-- PAGINATOR -->
    <ptkr-pagination-panel
        [dataLength]="(tableData && tableData.totalDocs) || 0"
        [startingPageSize]="!!tableParams ? tableParams.limit : defaultPageSize"
        [pageSizeOptions]="pageSizeOptions"
        [currentPage]="!!tableParams ? tableParams.page : 1"
        (currentPageChange)="onPageChange($event)"
        (pageSizeChange)="onPageSizeChange($event)"
    ></ptkr-pagination-panel>
</mat-card>
