<ptkr-select-shipping-address
    [addresses]="addresses$ | async"
    [selected]="selectedAddress"
    (selection)="selectAddress($event)">
    </ptkr-select-shipping-address>
<ptkr-select-shipping-method
    *ngIf="cart.isWarranty !== true"
    [selected]="selectedMethod"
    [shippingMethods]="methods"
    (selection)="selectMethod($event)">
</ptkr-select-shipping-method>
