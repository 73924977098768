import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import {
    takeUntil,
    distinctUntilChanged,
    debounceTime,
    switchMap,
    startWith
} from 'rxjs/operators';
import { DeviceAddManuallyModalConfig, Device } from '@app/models';
import { Item, ItemType } from '@libs/iso/core';
import { ItemService } from '@app/core/services/item.service';

@Component({
    selector: 'ptkr-device-add-manually-modal.component',
    templateUrl: './device-add-manually-modal.component.html',
    styleUrls: ['./device-add-manually-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceAddManuallyModalComponent implements OnInit {
    public deviceList$: Observable<Item[]> = new Observable<Item[]>();

    private _ngUnsub: Subject<void> = new Subject<void>();
    public newDeviceGroup: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<DeviceAddManuallyModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DeviceAddManuallyModalConfig,
        private _fb: FormBuilder,
        private _itemService: ItemService
    ) {}

    ngOnInit(): void {
        this.newDeviceGroup = this._fb.group({
            search: [''],
            selectedDevice: [null, Validators.required],
            deviceName: ['', Validators.required]
        });
        const debunceTime = 350;
        const listLimit = 10;
        this.deviceList$ = this.newDeviceGroup.get('search').valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(debunceTime),
            takeUntil(this._ngUnsub),
            switchMap(val => this._itemService.searchBaseItems(ItemType.Device, val, 1, listLimit))
        );
    }

    public selectDevice(event: MatAutocompleteSelectedEvent): void {
        this.newDeviceGroup.get('selectedDevice').setValue(event.option.value);
        this.newDeviceGroup.updateValueAndValidity();
    }

    public submit({
        value,
        valid
    }: {
        value: { search: string; selectedDevice: Item; deviceName: string };
        valid: boolean;
    }): void {
        if (!valid) {
            return;
        } else {
            this.dialogRef.close(
                new Device({
                    model: value.selectedDevice.model,
                    name: value.deviceName,
                    location: '',
                    serialNum: '',
                    itemKey: value.selectedDevice._id as string
                })
            );
        }
    }

    public displayFn(item: Item): string {
        if (!!item) {
            return item.make + ' ' + item.model;
        }
        return '';
    }
}
