import { Component, Input } from '@angular/core';
import { CategoricalDistributionElement, Reporting } from '@libs/iso/core';

enum ReportingState {
    Reported = 'Reporting',
    NotReported = 'Not Reporting'
}

@Component({
    selector: 'ptkr-reporting-devices-distribution',
    templateUrl: './reporting-devices-distribution.component.html',
    styleUrls: ['./reporting-devices-distribution.component.scss']
})
export class ReportingDevicesDistributionComponent {
    @Input() public set reportingDevices(data: Reporting) {
        if (data == null) {
            return;
        }
        this.total = data.total;
        this.reporting = data.reporting;
        this.data = new Reporting(data)
            .toCategoricalDistributionElement()
            .filter(category => category.value > 0)
            .sort((a, b) => (a.category === ReportingState.Reported ? -1 : 1));
    }
    public data: CategoricalDistributionElement[];
    public reporting: number = 0;
    public total: number = 0;
    constructor() {}
}
