export interface IMeterReadValue {
    displayName: string;
    value: string;
}

export class MeterReadValue implements IMeterReadValue {
    public displayName: string = '[default]';
    public value: string = '';

    constructor(defaults?: Partial<IMeterReadValue>) {
        if (!!defaults) {
            this.displayName = defaults.displayName || this.displayName;
            this.value =
                defaults.value !== undefined && defaults.value !== null
                    ? defaults.value
                    : this.value;
        }
    }
}
