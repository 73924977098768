import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, Post, Body, Get, MapClass, Query } from '@libs/web/http';
import {
    Cart,
    ShippingAddress,
    OrderPlacedResponse,
    ShippingMethod,
    ItemWithEntityPricing,
    WarrantyReasons
} from '@libs/iso/core';
import { Order } from '@app/models';

@Injectable()
export class CartService extends HttpService {
    @Post('/cart/addToCart')
    public addToCart(
        @Body('entityKey') entityKey: string,
        @Body('itemKey') itemKey: string,
        @Body('qty') quantity: number
    ): Observable<any> {
        return null;
    }

    @Post('/cart/setItemQty')
    public setItemQty(
        @Body('entityKey') entityKey: string,
        @Body('itemKey') itemKey: string,
        @Body('qty') quantity: number
    ): Observable<any> {
        return null;
    }

    @Get('/cart')
    @MapClass(Cart)
    public getCarts(): Observable<Cart[]> {
        return null;
    }

    @Get('/cart/canOrder')
    public canOrder(): Observable<boolean> {
        return null;
    }

    @Post('/cart/shippingAddresses')
    @MapClass(ShippingAddress)
    public getShippingAddresses(
        @Body('cartIds') selectedCartIds: string[]
    ): Observable<ShippingAddress[]> {
        return null;
    }

    @Post('/cart/estTax')
    public getEstimatedTax(
        @Body('cartIds') cartIds: string[],
        @Body('address') address: ShippingAddress,
        @Body('method') method: ShippingMethod
    ): Observable<{ estTax: number }> {
        return null;
    }

    @Post('/cart/checkout')
    public placeOrder(
        @Body('carts') selectedCarts: Cart[],
        @Body('order') order: Order
    ): Observable<OrderPlacedResponse> {
        return null;
    }

    @Get('/cart/warrantyItems')
    public getWarrantyEligibleItems(
        @Query('entityKey') entityKey: string,
        @Query('searchTerm') searchTerm?: string
    ): Observable<ItemWithEntityPricing[]> {
        return null;
    }

    @Post('/cart/warranty')
    public createWarrantyCart(
        @Body('itemId') itemId: string,
        @Body('entityKey') entityKey: string,
        @Body('warrantyCode') warrantyCode: string,
        @Body('warrantyReason') warrantyReason: WarrantyReasons
    ): Observable<Cart> {
        return null;
    }
}
