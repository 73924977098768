

<mat-menu #editPopup="matMenu" yPosition="above" xPosition="before" (closed)="onClose(true)">
    <mat-form-field
                class='inline-editable-field-form-field'
                appearance='outline'
                (click)="$event.stopPropagation()">
        <input 
            (keyup.enter)='onAccept(true)'
            (keydown.escape)='onCancel()'
            [formControl]='control'
            [type]='type'
            matInput
        />
    </mat-form-field>
</mat-menu>

<div class="toggle" [matMenuTriggerFor]="editPopup" matTooltip="Click to edit" i18n-matTooltip>
    <span [ngSwitch]="valueFormatType" *ngIf="(value); else noValue">
        <ng-container *ngSwitchDefault>{{ value }}</ng-container>
        <ng-container *ngSwitchCase="'number'">{{ value | number}}</ng-container>
        <ng-container *ngSwitchCase="'currency'">{{ value | currency}}</ng-container>
        <ng-container *ngSwitchCase="'percent'">{{ value | percent}}</ng-container>
    </span>
</div>


<ng-template #noValue>
    <div *ngIf="!value">
        &nbsp;
    </div>
</ng-template>