<ng-container *ngIf='!!formGroup; else noFormGroup'>
    <form [formGroup]='formGroup'>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                        Onsite-contact
                    </mat-panel-title>
                    <mat-panel-description>
                        <ng-container i18n *ngIf="isInstall">Set contact information for this install</ng-container>
                        <ng-container i18n *ngIf="!isInstall">Set contact information for this entity and its descendants</ng-container>
                        
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section>
                    <ptkr-field-group
                        label='Onsite-contact email'
                        hint='Email of your contact at this location'
                        i18n-label
                        i18n-hint
                        >
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.GeneralInformation.OnsiteContact.Email'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <input matInput [formControlName]='SettingsKeyMap.GeneralInformation.OnsiteContact.Email' />
                        </mat-form-field>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='Onsite-contact phone number'
                        hint='Phone number of your contact at this location'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.GeneralInformation.OnsiteContact.Phone'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <input matInput [formControlName]='SettingsKeyMap.GeneralInformation.OnsiteContact.Phone' />
                        </mat-form-field>
                    </ptkr-field-group>
                    <ptkr-field-group
                        label='Onsite-contact name'
                        hint='Name of your contact at this location'
                        i18n-label
                        i18n-hint>
                        <ptkr-inheritance-reset-button
                            [settingName]='SettingsKeyMap.GeneralInformation.OnsiteContact.Name'
                            [showDropdown]='showDropdown'
                            (bulkReset)='runBulkReset.emit($event)'>
                        </ptkr-inheritance-reset-button>
                        <mat-form-field appearance='outline'>
                            <input matInput [formControlName]='SettingsKeyMap.GeneralInformation.OnsiteContact.Name' />
                        </mat-form-field>
                    </ptkr-field-group>
                </section>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</ng-container>
<ng-template #noFormGroup>
    <div>
        <h2 style='color: gray;' i18n>Loading...</h2>
    </div>
</ng-template>
