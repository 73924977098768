import { ItemList } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MeterTypeService } from '@app/services';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { MeterTypeNameMap } from '@libs/iso/core';
import { GlobalStore } from '@app/state/store';
import { Store } from '@ngrx/store';

@Injectable()
export class MeterTypeNameMapResolver implements Resolve<MeterTypeNameMap> {
    constructor(private _store: Store<GlobalStore>, private _meterTypeService: MeterTypeService) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<MeterTypeNameMap> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        return this._meterTypeService.getMeterTypeNameMap();
    }
}
