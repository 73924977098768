import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalConfig } from '@app/models';

@Component({
    selector: 'ptkr-textarea-modal-with-content',
    templateUrl: './textarea-modal-with-content.component.html',
    styles: ['mat-form-field  { width: 100%}']
})
export class TextareaModalWithContentComponent implements OnInit {
    public inputControl: FormControl;

    constructor(
        public dialogRef: MatDialogRef<TextareaModalWithContentComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { placeholder: string; config: ConfirmModalConfig }
    ) {}

    ngOnInit(): void {
        this.inputControl = new FormControl('');
    }

    public confirmData(control: FormControl): void {
        if (!control.valid) {
            control.markAsTouched();
            return;
        } else {
            this.dialogRef.close(control.value);
        }
    }
}
