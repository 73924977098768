import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { selectEntity } from '@app/state/store/global.store';
import { Entity } from '@app/models';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {
    public dealerLogo: BehaviorSubject<string> = new BehaviorSubject(null);
    public dealerPrimaryColor: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(private _store: Store<GlobalStore>) {
        this._store.select(selectEntity).subscribe(entity => {

            // Kodak Alaris entity. Hardcoding this is a crime against humanity, but
            // Oberon needs this for a demo tomorrow, so here we are.
            if (this._isInEntityHierarchy(entity, '65c26852f8f49c761716f49d')) {
                this.dealerLogo.next(
                    'https://storage.googleapis.com/printtracker-assets-bucket/images/dealer-branding/kodak-alaris.png'
                );
                this.dealerPrimaryColor.next('#EC1D25');
            } else {
                this.dealerLogo.next(null);
                this.dealerPrimaryColor.next(null);
            }
        });
    }

    private _isInEntityHierarchy(entity: Entity, entityKey: string): boolean {
        return entity._id === entityKey || entity.breadcrumb.map(v => v._id).includes(entityKey);
    }
}
