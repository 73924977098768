import { User } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '@app/services';

@Injectable()
export class AbsoluteUserResolver implements Resolve<User> {
    constructor(private _userService: UserService) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
        return this._userService.getAbsoluteUserDetails();
    }
}
