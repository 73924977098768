import { AppStore, initAppState } from '../store';
import { AppActions, AppActionsType, ToggleGlobalSpinnerAction } from '../actions/app.actions';

class AppHandlers {
    public static handleToggleSpinnerAction(
        state: AppStore,
        action: ToggleGlobalSpinnerAction
    ): AppStore {
        return {
            ...state,
            globalSpinnerVisible: action.payload
        };
    }
}

export function appReducer(state: AppStore = initAppState, action: AppActionsType): AppStore {
    switch (action.type) {
        case AppActions.ToggleGlobalSpinner:
            return AppHandlers.handleToggleSpinnerAction(state, action);
        default:
            return state;
    }
}
