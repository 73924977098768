import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportConfigurationUsageMode } from '@app/shared/components/query-engine-interface/query-engine-interface.component';
import { QueryEngineReportType, ReportWithMetadata } from '@libs/iso/core';

@Component({
    selector: 'ptkr-device-meter-history-query-modal',
    templateUrl: './device-meter-history-query-modal.component.html',
    styleUrls: ['./device-meter-history-query-modal.component.scss']
})
export class DeviceMeterHistoryQueryModalComponent implements OnInit {
    public ReportConfigurationUsageMode: typeof ReportConfigurationUsageMode = ReportConfigurationUsageMode;
    public report: ReportWithMetadata = new ReportWithMetadata({
        type: QueryEngineReportType.DeviceMeterHistory,
        query: QueryEngineReportType.toDefaultQuery(QueryEngineReportType.DeviceMeterHistory)
    });

    constructor(
        public dialogRef: MatDialogRef<DeviceMeterHistoryQueryModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { deviceId: string, serialNum: string }
    ) {}

    ngOnInit(): void {}
}
