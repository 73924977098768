import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Address } from '../../models/Address';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
    selector: 'ptkr-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressFormComponent implements OnInit {

    private _isNew: boolean = false;
    @Input('isNew')
    public get isNew(): boolean {
        return this._isNew;
    }
    public set isNew(value: boolean) {
        this._isNew = value;
    }

    private _addressForm: FormGroup = Address.toFormGroup();
    @Input('addressForm')
    public get addressForm(): any {
        return this._addressForm;
    }
    public set addressForm(value: any) {
        this._addressForm = value;
    }

    private _countryList: { value: string; label: string }[] = [];
    private _stateList: { short: string; name: string; country: string }[] = [];
    private _nullState: { short: string; name: string; country: string } = {
        short: 'None',
        name: 'None',
        country: ''
    };
    // prettier-ignore
    public countryList$: Observable<{ value: string; label: string }[]>
        = new Observable<{ value: string; label: string }[]>();
    // prettier-ignore
    public stateList$: Observable<{ short: string, name: string, country: string }[]>
        = new Observable<{ short: string, name: string, country: string }[]>();

    constructor(private _cd: ChangeDetectorRef, private _http: HttpClient) {}

    ngOnInit(): void {
        const jsonData = [
            this._http.get<any>('assets/countries.json'),
            this._http.get<any>('assets/states.json')
        ];
        forkJoin(jsonData).subscribe(data => {
            this._countryList = data[0];
            this._stateList = data[1];
            const countryValue = this._addressForm.get('country').value;
            const countryChanges$ = this._addressForm
                .get('country')
                .valueChanges.pipe(startWith(countryValue));
            this.countryList$ = countryChanges$.pipe(
                map(country =>
                    country ? this._filterCountries(country) : this._countryList.slice()
                )
            );
            this.stateList$ = countryChanges$.pipe(
                map(country => (country ? this._filterStates(country) : [this._nullState]))
            );
            // Re-set the values after getting the JSON data so they display on load correctly
            this._addressForm.get('country').setValue(this._addressForm.get('country').value);
            this._addressForm.updateValueAndValidity();
            this._cd.detectChanges();
        });

        // If the parent form explicitly says this is a new address, we set the country param to US
        if (this._isNew === true) {
            this._addressForm.get('country').setValue('US');
        }
    }

    public validate(): boolean {
        this._addressForm.updateValueAndValidity();
        for (const controlKey in this._addressForm.controls) {
            this._addressForm.controls[controlKey].markAsTouched();
        }
        this._cd.detectChanges();
        return this._addressForm.valid;
    }

    public getValue(): Address {
        return new Address(this._addressForm.value);
    }

    public countryDisplayFn = (countryVal: string): string => {
        if (!!countryVal) {
            const country = this._countryList.find(c => c.value === countryVal);
            if (!!country) {
                return country.label;
            }
        }
        return undefined;
        // tslint:disable-next-line:semicolon
    };

    public stateDisplayFn = (stateVal: string): string => stateVal;

    private _filterCountries(selectedCountry: string): { value: string; label: string }[] {
        const reg = new RegExp(selectedCountry.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        // Handle dumb chrome auto-complete
        const softCountryMatch = this._countryList.find(
            c => c.label.toUpperCase() === selectedCountry.toUpperCase().trim()
        );
        if (!!softCountryMatch) {
            this._addressForm.get('country').setValue(softCountryMatch.value);
        }
        return this._countryList.filter(
            country => reg.test(country.label) || reg.test(country.value)
        );
    }

    private _filterStates(
        selectedCountry: string
    ): { short: string; name: string; country: string }[] {
        const reg = new RegExp(selectedCountry.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        let options = this._stateList.filter(state => reg.test(state.country));
        if (options.length > 0) {
            return options;
        } else {
            const country = this._countryList.find(
                c => c.label.toUpperCase() === selectedCountry.toUpperCase().trim()
            );
            if (!!country) {
                options = this._stateList.filter(state => state.country === country.value);
            }
            if (options.length > 0) {
                return options;
            }
            return [this._nullState];
        }
    }
}
