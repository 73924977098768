import { SupportedMeterTypeCategory } from '@libs/iso/core/enums/SupportedMeterTypeCategory';

export interface SupportedMeterType {
    key: string;
    name: string;
    category: SupportedMeterTypeCategory[];
}

/* tslint:disable:max-line-length */
// tslint:disable:ter-max-len
// prettier-ignore
export const supportedMeterTypes: SupportedMeterType[] = [
    {key: 'total',                  name: 'Total',                      category: [SupportedMeterTypeCategory.Totals]},
    {key: 'engineTotal',            name: 'Engine Total',               category: [SupportedMeterTypeCategory.Totals]},
    {key: 'totalBlack',             name: 'Total Black',                category: [SupportedMeterTypeCategory.BlackWhite]},
    {key: 'totalColor',             name: 'Total Color',                category: [SupportedMeterTypeCategory.Color]},
    {key: 'totalPrints',            name: 'Total Prints',               category: [SupportedMeterTypeCategory.Prints]},
    {key: 'totalCopies',            name: 'Total Copies',               category: [SupportedMeterTypeCategory.Copies]},
    {key: 'totalFaxes',             name: 'Total Faxes',                category: [SupportedMeterTypeCategory.Totals, SupportedMeterTypeCategory.Faxes]},
    {key: 'totalFaxesBlack',        name: 'Total Faxes Black',          category: [SupportedMeterTypeCategory.Faxes, SupportedMeterTypeCategory.BlackWhite]},
    {key: 'totalFaxesColor',        name: 'Total Faxes Color',          category: [SupportedMeterTypeCategory.Faxes, SupportedMeterTypeCategory.Color]},
    {key: 'totalScans',             name: 'Total Scans',                category: [SupportedMeterTypeCategory.Totals, SupportedMeterTypeCategory.Scans]},
    {key: 'duplex',                 name: 'Duplex',                     category: [SupportedMeterTypeCategory.Totals]},
    {key: 'totalPrintsBlack',       name: 'Total Prints Black',         category: [SupportedMeterTypeCategory.Prints, SupportedMeterTypeCategory.BlackWhite]},
    {key: 'totalPrintsColor',       name: 'Total Prints Color',         category: [SupportedMeterTypeCategory.Prints, SupportedMeterTypeCategory.Color]},
    {key: 'totalCopies',            name: 'Total Copies Black',         category: [SupportedMeterTypeCategory.Copies, SupportedMeterTypeCategory.BlackWhite]},
    {key: 'totalCopiesColor',       name: 'Total Copies Color',         category: [SupportedMeterTypeCategory.Copies, SupportedMeterTypeCategory.Color]},
    {key: 'tier1Total',             name: 'Tier 1 Total',               category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Totals, SupportedMeterTypeCategory.Color]},
    {key: 'tier2Total',             name: 'Tier 2 Total',               category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Totals, SupportedMeterTypeCategory.Color]},
    {key: 'tier3Total',             name: 'Tier 3 Total',               category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Totals, SupportedMeterTypeCategory.Color]},
    {key: 'tier1Copies',            name: 'Tier 1 Copies',              category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Copies, SupportedMeterTypeCategory.Color]},
    {key: 'tier2Copies',            name: 'Tier 2 Copies',              category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Copies, SupportedMeterTypeCategory.Color]},
    {key: 'tier3Copies',            name: 'Tier 3 Copies',              category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Copies, SupportedMeterTypeCategory.Color]},
    {key: 'tier1Prints',            name: 'Tier 1 Prints',              category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Prints, SupportedMeterTypeCategory.Color]},
    {key: 'tier2Prints',            name: 'Tier 2 Prints',              category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Prints, SupportedMeterTypeCategory.Color]},
    {key: 'tier3Prints',            name: 'Tier 3 Prints',              category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Prints, SupportedMeterTypeCategory.Color]},
    {key: 'tier3Faxes',             name: 'Tier 3 Faxes',               category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Faxes, SupportedMeterTypeCategory.Color]},
    {key: 'a3',                     name: 'A3 Total',                   category: [SupportedMeterTypeCategory.Totals, SupportedMeterTypeCategory.A3]},
    {key: 'a3Black',                name: 'A3 Black',                   category: [SupportedMeterTypeCategory.BlackWhite, SupportedMeterTypeCategory.A3]},
    {key: 'a3Color',                name: 'A3 Color',                   category: [SupportedMeterTypeCategory.Color, SupportedMeterTypeCategory.A3]},
    {key: 'a4',                     name: 'A4 Total',                   category: [SupportedMeterTypeCategory.Totals, SupportedMeterTypeCategory.A4]},
    {key: 'a4Black',                name: 'A4 Black',                   category: [SupportedMeterTypeCategory.BlackWhite, SupportedMeterTypeCategory.A4]},
    {key: 'a4Color',                name: 'A4 Color',                   category: [SupportedMeterTypeCategory.Color, SupportedMeterTypeCategory.A4]},
    {key: 'tier3Faxes',             name: 'Tier 3 Faxes',               category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Faxes, SupportedMeterTypeCategory.Color]},
    {key: 'tier3Faxes',             name: 'Tier 3 Faxes',               category: [SupportedMeterTypeCategory.ThreeTier, SupportedMeterTypeCategory.Faxes, SupportedMeterTypeCategory.Color]},
];

export const supportedMeterTypesByCategory: SupportedMeterTypeByCategory[] = toMeterTypesByCategory(
    supportedMeterTypes
);

export const supportedFormats: string[] = [];

export interface SupportedMeterTypeByCategory {
    category: SupportedMeterTypeCategory;
    meterTypes: SupportedMeterType[];
}

export function toMeterTypesByCategory(
    meterTypes: SupportedMeterType[]
): SupportedMeterTypeByCategory[] {
    const mapped: Map<SupportedMeterTypeCategory, SupportedMeterType[]> = new Map();
    for (const meterType of meterTypes) {
        for (const category of meterType.category) {
            if (!mapped.has(category)) {
                mapped.set(category, []);
            }
            mapped.set(category, [...mapped.get(category), meterType]);
        }
    }
    return [...mapped.entries()].map(([k, v]) => ({ category: k, meterTypes: v }));
}
