import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Moment } from 'moment';
import { TimeFrame } from '@libs/iso/core';
import { MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { TimeFrameChange } from '@app/models';

@Component({
    selector: 'ptkr-time-frame',
    templateUrl: './time-frame.component.html',
    styleUrls: ['./time-frame.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeFrameComponent implements OnInit {
    private _options: TimeFrame[] = [];
    @Input()
    public set options(value: TimeFrame[]) {
        if (Array.isArray(value)) {
            this._options = value;

            if (this.selected === null || typeof this.selected === 'undefined') {
                this.selected = this._options[0];
            }
        }
    }
    public get options(): TimeFrame[] {
        return this._options;
    }

    @Input()
    public selected: TimeFrame = this.options[0] || null;
    @Input()
    public allowCustom: boolean = true;

    @Output()
    public tfChange: EventEmitter<TimeFrameChange> = new EventEmitter<TimeFrameChange>();

    @ViewChild(MatButtonToggleGroup, { static: true })
    public toggleGroup: MatButtonToggleGroup;
    @ViewChildren(MatInput)
    public inputList: QueryList<MatInput>;

    public TimeFrame: typeof TimeFrame = TimeFrame;
    public customStartDate: Moment;
    public customEndDate: Moment;

    constructor() {}

    ngOnInit(): void {}

    public selectorChange(event: MatButtonToggleChange): void {
        this.tfChange.emit(new TimeFrameChange(event.value, null, null));
        this.inputList.forEach(i => {
            i.value = undefined;
        });
    }

    public startDateChange(event: MatDatepickerInputEvent<Moment>): void {
        this.customStartDate = event.value;
        if (!!this.customStartDate && !!this.customEndDate) {
            this.tfChange.emit(
                new TimeFrameChange(TimeFrame.Custom, this.customStartDate, this.customEndDate)
            );
            // this.toggleGroup.writeValue(undefined);
        }
    }

    public endDateChange(event: MatDatepickerInputEvent<Moment>): void {
        this.customEndDate = event.value;
        if (!!this.customStartDate && this.customEndDate) {
            this.tfChange.emit(
                new TimeFrameChange(TimeFrame.Custom, this.customStartDate, this.customEndDate)
            );
            // this.toggleGroup.writeValue(undefined);
        }
    }
}
