import { DeviceSupply } from '@libs/iso/core';
import { IDeviceSupply } from '@libs/iso/core/models/device/DeviceSupply';


export interface ISupplyItem extends IDeviceSupply {
    entity: { name: string};
    device: { name: string; serialNum: string};
}


export class SupplyItem extends DeviceSupply implements ISupplyItem {
    public entity: { name: string} = null;
    public device: { name: string; serialNum: string} = null;

    constructor(params?: Partial<ISupplyItem>) {
        super(params);

        if (!!params) {
            this.entity = params?.entity ?? this.entity;
            this.device = params?.device ?? this.device;
        }
    }
}
