import { TableParams } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ItemService } from '@app/services';
import { catchError, take } from 'rxjs/operators';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { PaginatedList, ItemWithEntityPricing } from '@libs/iso/core';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class EntityItemsPaginatedResolver implements Resolve<PaginatedList<ItemWithEntityPricing>> {
    constructor(private _itemService: ItemService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<PaginatedList<ItemWithEntityPricing>> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let entityId: string = '';
        this._store
            .pipe(
                select(fromEntity.entityId),
                take(1)
            )
            .subscribe(id => (entityId = id));
        const initQParams = route.queryParams;
        const defaultLimit = 10;
        const params: TableParams = {
            searchTerm: initQParams.searchTerm,
            sort: initQParams.sort,
            sortOrder: coerceNumberProperty(initQParams.sortOrder) as 1 | -1,
            page: coerceNumberProperty(initQParams.page) || 1,
            limit: coerceNumberProperty(initQParams.limit) || defaultLimit,
            includeChildren: coerceBooleanProperty(initQParams.includeChildren) || false,
            includeResolved: coerceBooleanProperty(initQParams.includeResolved) || false
        };
        console.log('params', params);
        return this._itemService
            .getPaginatedEntityItemList(
                entityId,
                params.searchTerm,
                params.page,
                params.limit,
                params.sort,
                params.sortOrder as number,
                true // Include hidden items
            )
            .pipe(
                catchError(err => {
                    console.error('caught error', err);
                    return of(new PaginatedList<ItemWithEntityPricing>());
                })
            );
    }
}
