import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ptkr-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnInit, OnDestroy {
    @Input()
    public placeholder: string = '';
    @Input()
    public defaultValue: string = '';
    @Input()
    public includeButton: boolean = true;

    @Output()
    public textChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public searchClicked: EventEmitter<string> = new EventEmitter<string>();

    private _ngUnsub: Subject<void> = new Subject<void>();

    public searchFormGroup: FormGroup;

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.searchFormGroup = this._fb.group({
            search: [this.defaultValue]
        });
        this.searchFormGroup
            .get('search')
            .valueChanges.pipe(takeUntil(this._ngUnsub))
            .subscribe(val => this.textChanged.emit(val));
    }

    ngOnDestroy(): void {
        this._ngUnsub.next();
        this._ngUnsub.complete();
        this._ngUnsub = undefined;
    }

    public submit({ value }: { value: { search: string } }): void {
        // console.log('submit', value);
        this.searchClicked.emit(value.search);
    }

    public reset(): void {
        if (!!this.searchFormGroup) {
            this.searchFormGroup.reset();
        }
    }

    public value(): string {
        if (!!this.searchFormGroup) {
            return this.searchFormGroup.get('search').value;
        } else {
            return '';
        }
    }
}
