import { Directive, ElementRef, Input, AfterViewChecked } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
    selector: '[ptkrErrorControl]'
})
export class ErrorMessageDirective implements AfterViewChecked {
    @Input('ptkrErrorControl') public control: AbstractControl;
    @Input('error-required') public requiredMessage: string = $localize`Required`;
    @Input('error-minlength') public minLengthMessage: string = $localize`Too short`;
    @Input('error-maxlength') public maxLengthMessage: string = $localize`Too long`;
    @Input('error-email') public emailMessage: string = $localize`Invalid email`;
    @Input('error-equal-to') public equalToMessage: string = $localize`Fields don't match`;
    @Input('error-range-length') public rangeLengthMessage: string = $localize`Invalid length`;
    @Input('error-digits') public digitsMessage: string = $localize`Must contain only digits`;
    @Input('error-equal') public equalMessage: string = $localize`Invalid Value`;
    @Input('error-custom') public custom: any = {};

    private _el: ElementRef;

    constructor(el: ElementRef) {
        this._el = el;
    }

    ngAfterViewChecked(): void {
        const elem: HTMLElement = this._el.nativeElement;
        let content = '';
        if (typeof this.control.errors !== 'undefined' && this.control.errors !== null) {
            for (const eKey in this.control.errors) {
                switch (eKey) {
                    case 'required':
                        content = this.requiredMessage;
                        break;
                    case 'minlength':
                        content = this.minLengthMessage
                            ? this.minLengthMessage
                            : $localize`Requires minimum length of ${this.control.errors[eKey]['requiredLength']}`;
                        break;
                    case 'maxlength':
                        content = this.maxLengthMessage
                            ? this.maxLengthMessage
                            : $localize`Exceeds maximum length of ${this.control.errors[eKey]['requiredLength']}`;
                        break;
                    case 'email':
                        content = this.emailMessage;
                        break;
                    case 'equalTo':
                        content = this.equalToMessage;
                        break;
                    case 'rangeLength':
                        content = this.rangeLengthMessage;
                        break;
                    case 'digits':
                        content = this.digitsMessage;
                        break;
                    case 'equal':
                        content = this.equalMessage;
                        break;
                    case 'uppercase':
                        content = $localize`An uppercase character is required`;
                        break;
                    case 'lowercase':
                        content = $localize`A lowercase character is required`;
                        break;
                    case 'containsNumber':
                        content = $localize`A number is required`;
                        break;
                    case 'containsSpecialChar':
                        content = $localize`A special character is required`;
                        break;
                    case 'emailUnique':
                        content = $localize`Email is already in use`;
                        break;
                    default:
                        if (typeof this.custom[eKey] === 'string') {
                            content = this.custom[eKey];
                        } else {
                            console.log('eKey', eKey, this.control.errors[eKey]);
                            content = $localize`Invalid`;
                        }
                }
            }
        }
        elem.textContent = content;
    }
}
