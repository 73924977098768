import {
    IDeviceEventAcknowledgement as IDAckBase,
    DeviceEventAcknowledgement as DAckBase
} from '@libs/iso/core';

export interface IDeviceEventAcknowledgement extends IDAckBase {
    userKey: string;
}

export class DeviceEventAcknowledgement extends DAckBase implements IDeviceEventAcknowledgement {
    public userKey: string = null;

    constructor(params?: Partial<IDeviceEventAcknowledgement>) {
        super(params);
    }
}
