import { UserStore, initUserState } from '../store';
import {
    UserActions,
    UserActionsType,
    LoadUserAction,
    ClearUserAction,
    UpdateUserAction,
    LoginUserAction
} from '../actions/user.actions';
import { User } from '@app/models';

class UserHandlers {
    public static handleLoadUser(state: UserStore, action: LoadUserAction): UserStore {
        // console.log('new user');
        return new User(action.payload);
    }

    public static handleLoginUser(state: UserStore, action: LoginUserAction): UserStore {
        return new User(action.payload);
    }

    public static handleClearUser(state: UserStore, action: ClearUserAction): UserStore {
        console.log('clear user');
        return new User();
    }

    public static handleUpdateUser(state: UserStore, action: UpdateUserAction): UserStore {
        // console.log('new user');
        return new User({
            ...state,
            ...action.payload
        });
    }
}

export function userReducer(state: UserStore = initUserState, action: UserActionsType): UserStore {
    switch (action.type) {
        case UserActions.LoadUser:
            return UserHandlers.handleLoadUser(state, action);
        case UserActions.ClearUser:
            return UserHandlers.handleClearUser(state, action);
        case UserActions.UpdateUser:
            return UserHandlers.handleUpdateUser(state, action);
        case UserActions.LoginUser:
            return UserHandlers.handleLoginUser(state, action);
        default:
            return state;
    }
}
