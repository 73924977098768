export interface IReportScheduledExportEmailPersonalize {
    subject: string;
    body: string;
}

export class ReportScheduledExportEmailPersonalize
    implements IReportScheduledExportEmailPersonalize {
    public subject: any = 'A new {{.ReportName}} report is available';
    public body: any =
        'A new {{.ReportName}} report is available and has been attached to this email.';
    constructor(params?: Partial<IReportScheduledExportEmailPersonalize>) {
        if (params != null) {
            this.subject = params.subject ?? this.subject;
            this.body = params.body ?? this.body;
        }
    }
}
