import { TimeSeriesValue } from '@libs/iso/core/models/meterRead/TimeSeriesValue';
import { TimeSeriesBinType } from '@libs/iso/core/enums/TimeSeriesBinType';

export interface ITimeSeries {
    series: Array<TimeSeriesValue>;
}

export class TimeSeries implements ITimeSeries {
    public series: Array<TimeSeriesValue>;
    constructor(params?: Partial<ITimeSeries>) {
        if (params.series) {
            this.series = params.series.map(s => new TimeSeriesValue(s));
        }
    }

    /**
     * Returns a new time-series dataset where each value is the difference between it's original value
     * and the proceeding value. In other words, the returned time-series data is volume-based data
     * calculated from the input time-series dataset.
     * @param {Array<TimeSeriesValue>} series - The input time-series dataset.
     * @returns {Array<TimeSeriesValue>} - The volume time-series data.
     */
    public static volume(series: Array<TimeSeriesValue>): Array<TimeSeriesValue> {
        let previous: number = null;
        return series.map(s => {
            const result = new TimeSeriesValue({
                timestamp: s.timestamp,
                value: 0
            });
            if (previous == null) {
                previous = s.value;
                return result;
            }
            result.value = s.value - previous;
            previous = s.value;
            return result;
        });
    }

    /**
     * Bins the provided data based on the bin type. For more information see https://observablehq.com/@d3/d3-bin.
     * @param {TimeSeriesBinType} bin - The bin type to use
     * @param {Array<TimeSeriesValue>} series - The time series data to bin
     * @param {boolean} isVolume - Whether the time series data is volume data. If it is not volume
     * data, then we bin using the last value in the time series data. If it is volume data, then we
     * aggregate all the values together into a single value.
     * @returns {Array<TimeSeriesValue>} - A new time-series dataset where each X-value is a bin
     */
    public static bin(
        bin: TimeSeriesBinType,
        series: Array<TimeSeriesValue>,
        isVolume: boolean
    ): Array<TimeSeriesValue> {
        return TimeSeriesBinType.binTimeSeries(bin, series, isVolume);
    }

    /**
     * Converts the provided time-series data to data that can be parsed by echarts.
     * @param {Array<TimeSeriesValue>} series - The time-series data to parse.
     * @param {function (Date): any} timestampFormatter - An optional timestamp formatter function
     * @returns {Array<[any, any]>} - An array of tuples where the first and second values are the
     * x and y values respectively.
     */
    public static chartable(
        series: Array<TimeSeriesValue>,
        timestampFormatter?: (Date) => any
    ): Array<[any, any]> {
        return series.map(s => [
            !!timestampFormatter ? timestampFormatter(s.timestamp) : s.timestamp,
            s.value
        ]);
    }
}
