<div mat-dialog-header class="header">
    <h3>{{ data.title }}</h3>
</div>
<div mat-dialog-content class="content">
    <p>{{ data.content }}</p>
    <p *ngIf="data.secondaryContent">{{ data.secondaryContent }}</p>
</div>
<div class="action-buttons" style="float: right;" mat-dialog-actions>
    <button mat-button color="accent" (click)="dialogRef.close(false)">{{ data.buttons.cancel }}</button>
    <button mat-button color="accent" (click)="dialogRef.close(true)">{{ data.buttons.confirm }}</button>
</div>
