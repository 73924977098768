import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { WarrantyReasons, ItemWithEntityPricing } from '@libs/iso/core';
import { CartService } from '@app/core/services/cart.service';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchBarComponent } from '../../components/search-bar/search-bar.component';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { RouterService } from '@app/core/services/router.service';

@Component({
    selector: 'ptkr-warranty-overlay',
    templateUrl: './warranty-overlay.component.html',
    styleUrls: ['./warranty-overlay.component.scss']
})
export class WarrantyOverlayComponent implements OnInit, OnDestroy {
    @ViewChild(SearchBarComponent, {static: true})
    public searchBar: SearchBarComponent;

    private _ngUnsub: Subject<void> = new Subject<void>();
    private _page: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    // prettier-ignore
    private _selectedItem: BehaviorSubject<ItemWithEntityPricing>
        = new BehaviorSubject<ItemWithEntityPricing>(undefined);
    private _entityId: string;

    public page$: Observable<number> = this._page.asObservable();
    public selectedItem$: Observable<ItemWithEntityPricing> = this._selectedItem.asObservable();
    public items$: Observable<ItemWithEntityPricing[]>;
    public WarrantyReasons: typeof WarrantyReasons = WarrantyReasons;
    public warrantyCode: FormControl;
    public warrantyReason: FormControl;

    constructor(
        public dialogRef: MatDialogRef<WarrantyOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _cartService: CartService,
        private _store: Store<GlobalStore>,
        private _routerService: RouterService
    ) {}

    ngOnInit(): void {
        this._store.pipe(select(fromEntity.currentEntity)).subscribe(e => (this._entityId = e._id));
        this._refreshItemList(this._entityId);
        this.warrantyCode = new FormControl('', Validators.required);
        this.warrantyReason = new FormControl('', Validators.required);
        this.searchBar.textChanged
            .pipe(
                debounceTime(350),
                distinctUntilChanged(),
                takeUntil(this._ngUnsub)
            )
            .subscribe(newVal => {
                this.searchItems(newVal);
            });
    }

    ngOnDestroy(): void {
        this._ngUnsub.next();
        this._ngUnsub.complete();
        this._ngUnsub = undefined;
    }

    public searchItems(term: string): void {
        this._refreshItemList(this._entityId, term);
    }

    public selectToner(item: ItemWithEntityPricing): void {
        console.log('select a toner', item);
        this._selectedItem.next(item);
        this._page.next(this._page.getValue() + 1);
    }

    public tempNext(): void {
        this.selectToner(
            new ItemWithEntityPricing({
                make: 'some make',
                model: 'some model'
            })
        );
    }

    public proceedToCheckout(): void {
        this._cartService
            .createWarrantyCart(
                this._selectedItem.getValue()._id as string,
                this._entityId,
                this.warrantyCode.value,
                this.warrantyReason.value
            )
            .subscribe(res => {
                this.dialogRef.close(res);
            });
    }

    private _refreshItemList(entityId: string, searchTerm?: string): void {
        this.items$ = this._cartService.getWarrantyEligibleItems(entityId, searchTerm);
    }

    public viewCart(): void {
        this._routerService.navigate('/order/carts');
        this.dialogRef.close();
    }
}
