<h1>{{ data.title }}</h1>
<ptkr-meter-key-picker
    [meterTypeNameMap]="data.meterTypeNameMap"
    [(key)]="data.meterKey"
    [otherKeys]="data.otherMeterKeys"
    (valid)="nameIsValid = $event"
></ptkr-meter-key-picker>
<mat-slide-toggle [checked]="isCanon" (change)="isCanon = $event.checked">
    This is for a Canon billing meter
</mat-slide-toggle>
<ng-container *ngIf="!isCanon; else canonMeters">
    <p>
        A device will populate a meter (e.g., Total Black) with the first preference selected (e.g.,
        equivalent). If a device doesn't report that preference, the next one will be used.
    </p>
    <div *ngIf="data.isDevice && showingMap" class="notification-banner-container">
        <div class="notification-banner">
            <span>
                <i class="fas fa-info-circle"></i>
                <!-- <mat-icon>info</mat-icon> -->
            </span>
            <span>
                It doesn't seem like this device has detected any non-canon meters recently, so
                we're showing all possible meters.
            </span>
        </div>
    </div>
    <div class="picker-menu">
        <div class="available-column-list">
            <div class="search-bar-wrapper">
                <h2>Available Meters</h2>
                <!-- <mat-form-field appearance="outline">
                <input
                    matInput
                    placeholder="Filter Columns"
                    [(ngModel)]="filterString"
                    (keyup)="filter()"
                    autocomplete="off"
                />
            </mat-form-field> -->
            </div>
            <div class="table-wrapper">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <!-- Leaf node -->
                    <mat-tree-node
                        class="treeitem"
                        *matTreeNodeDef="let node"
                        matTreeNodeToggle
                        matTreeNodePadding
                    >
                        <button mat-icon-button disabled></button>
                        <mat-checkbox
                            [checked]="checklistSelection.isSelected(node)"
                            (change)="todoLeafItemSelectionToggle(node)"
                            >{{ node.name }}</mat-checkbox
                        >
                    </mat-tree-node>

                    <!-- Expandable Node -->
                    <mat-tree-node
                        class="treeitem"
                        *matTreeNodeDef="let node; when: hasChild"
                        matTreeNodePadding
                    >
                        <button
                            mat-icon-button
                            matTreeNodeToggle
                            [attr.aria-label]="'toggle' + node.name"
                        >
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                            </mat-icon>
                        </button>
                        <ptkr-meter-format-pill
                            matTreeNodeToggle
                            [format]="node.name"
                        ></ptkr-meter-format-pill>
                    </mat-tree-node>
                </mat-tree>

                <h2
                    *ngIf="
                        dataSource == null ||
                        dataSource.data == undefined ||
                        dataSource.data.length <= 0
                    "
                    class="nocols"
                >
                    No Columns to Show :(
                </h2>
            </div>
        </div>
        <div class="spacer"></div>
        <div class="selected-column-list">
            <h2>Meter Preferences</h2>
            <div cdkDropList class="drop-list" (cdkDropListDropped)="drop($event)">
                <div
                    class="drop-list-box"
                    *ngFor="let selection of checklistSelection.selected"
                    cdkDrag
                >
                    <div class="drop-list-box drop-list-placeholder" *cdkDragPlaceholder></div>
                    <div class="left-side">
                        <mat-icon class=".icon-button drop-list-box-grip" cdkDragHandle
                            >drag_handle</mat-icon
                        >
                        <span
                            class="drop-list-box-text"
                            [matTooltip]="selection.value.format + ': ' + selection.name"
                        >
                            <ptkr-inline-meter-name-card
                                [index]="checklistSelection.selected.indexOf(selection)"
                                [format]="selection.value.format"
                                [name]="
                                    displayBillingMeter(
                                        selection.value.format,
                                        selection.value.meter
                                    )
                                "
                            ></ptkr-inline-meter-name-card>
                        </span>
                    </div>
                    <div class="right-side">
                        <mat-icon
                            class="icon-button drop-list-box-button drop-list-box-clear"
                            (click)="removeSelection(selection)"
                        >
                            clear
                        </mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #canonMeters>
    <p>
        Canon printers organize their meters by 3-digit number codes; these numbers can be listed
        here.
    </p>
    <mat-form-field appearance="outline" class="canon-list">
        <mat-chip-list #canonChipList ngDefaultControl>
            <mat-chip
                *ngFor="let meter of canonSelection"
                color="primary"
                (removed)="removeChip(meter)"
            >
                {{ meter }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
                [matChipInputFor]="canonChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addChipOnBlur"
                (matChipInputTokenEnd)="addChip($event)"
            />
        </mat-chip-list>
    </mat-form-field>
</ng-template>
<div class="action-buttons" mat-dialog-options>
    <button mat-button color="accent" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="accent" [disabled]="!nameIsValid" (click)="confirm()">
        Confirm
    </button>
</div>
