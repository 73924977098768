import { Injectable } from '@angular/core';
import {
    HttpService,
    Get,
    Post,
    Put,
    Query,
    Body,
    ResponseType,
    Path,
    MapClass,
    Adapter,
    Delete
} from '@libs/web/http';
import { ItemList } from '@app/models';
import { Observable } from 'rxjs';
import {
    Item,
    ItemSearchAllResults,
    PaginatedList,
    ItemWithEntityPricing,
    SNMPDumpFile,
    SNMPDumpFileWithMetadata
} from '@libs/iso/core/models';
import { ObjectID } from 'bson';
import { ItemType } from '@libs/iso/core';

@Injectable()
export class FileStorageService extends HttpService {
    @Get('/storage/snmp/dumps/byItem')
    public getSNMPDumpsForItemKey(
        @Query('id') itemKey: string
    ): Observable<Array<SNMPDumpFileWithMetadata>> {
        return null;
    }

    @Delete('/storage/snmp/dumps')
    public deleteSNMPDump(@Query('str') name: string): Observable<void> {
        return null;
    }
}
