import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EntitiesActions, SearchedEntitiesAction, SearchEntityAction } from '../actions';
import { GlobalStore } from '@app/state/store';
import { EntityList, Entity } from '@app/models';
import { Store } from '@ngrx/store';
import { StorageService, EntityService, NotificationService, RouterService } from '@app/services';
import { of } from 'rxjs';

@Injectable()
export class EntitiesEffects {
    constructor(
        private _actions$: Actions,
        private _storageService: StorageService,
        private _entityService: EntityService,
        private _notificationService: NotificationService,
        private _store: Store<GlobalStore>,
        private _routerService: RouterService
    ) {}

    @Effect()
    public searchEntity$: Observable<{}> = this._actions$.pipe(
        ofType(EntitiesActions.SearchEntity),
        switchMap((action: SearchEntityAction) => {
            const entity = action.payload;
            this._store.dispatch(new SearchedEntitiesAction(new EntityList()));
            this._entityService.searchChildren(entity._id, entity.name).subscribe(
                res => {
                    const cleaned: Entity[] = [];
                    for (let i = 0; i < res.length; i++) {
                        const found: Entity = cleaned.find(a => a._id === res[i]._id);
                        if (!found) {
                            cleaned.push(res[i]);
                        }
                    }
                    this._store.dispatch(
                        new SearchedEntitiesAction(new EntityList({ entityList: cleaned }))
                    );
                },
                err => {
                    console.log(err);
                    this._notificationService.error(err);
                }
            );
            return of();
        })
    );
}
