import { UpdateKeyType, SettingDetails } from '@libs/iso/core/enums';
import * as _ from 'lodash';

export abstract class SettingUpdate<T = any> {
    public key: UpdateKeyType = null;
    public value: T = undefined;
    protected _valid: boolean = true;
    protected _errorMessages: string[] = [];

    public isValid(): boolean {
        return this._valid;
    }
    public getErrors(): string[] {
        return this._errorMessages;
    }
    public getDBUpdate(): { [key: string]: any } {
        return { [this.key]: this.value };
    }

    /**
     * Function returns whether or not the new value is different than the old value.
     * @param {T} oldValue  value prior to modification.
     * @returns {boolean}  Whether or not the new value is different than the old value.
     */
    public isChanged(oldValue: T): boolean {
        return !_.isEqual(this.value, oldValue);
    }
}
