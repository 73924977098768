<div mat-dialog-content>
    <mat-vertical-stepper [linear]="true" #stepper (selectionChange)="selectionChange($event)">
        <mat-step [completed]="steps[0].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>{{ steps[0].label }}</ng-template>
            <ptkr-review-step
                #reviewStep
                [cart]="cart"
                (removeItem)="removeItem($event)"
                (updateItem)="updateItem($event)"
            >
            </ptkr-review-step>
        </mat-step>
        <mat-step [completed]="steps[1].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>{{ steps[1].label }}</ng-template>
            <ptkr-shipping-step #shippingStep [cart]="cart"></ptkr-shipping-step>
        </mat-step>
        <mat-step [completed]="steps[2].isComplete" [editable]="canEdit()" *ngIf="!cart.isWarranty">
            <ng-template matStepLabel>{{ steps[2].label }}</ng-template>
            <ptkr-payment-step #paymentStep [cart]="cart" [order]="order"></ptkr-payment-step>
        </mat-step>
        <mat-step [completed]="steps[3].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>{{ steps[3].label }}</ng-template>
            <ptkr-checkout-confirm-step
                #confirmStep
                [cart]="cart"
                [shippingMethod]="shippingMethod"
                [shippingAddress]="shippingAddress"
                [paymentMethod]="paymentMethod"
                [taxes]="estTax$ | async"
            >
            </ptkr-checkout-confirm-step>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>{{ steps[4].label }}</ng-template>
            <div class="complete">
                <h2>Checkout Complete</h2>
                <span class="check-icon"> <i class="far fa-check-circle"></i> </span>
                <p>
                    An order confirmation receipt has been emailed to {{ userEmail$ | async }}. An
                    email with tracking information will be sent when the order has shipped.
                </p>
                <h3>Order Summary</h3>
                <div class="group">
                    <label>Order Number</label> <span>{{ orderRes?.orderNumber }}</span>
                </div>
                <div class="group">
                    <label>Number of items</label> <span>{{ orderRes?.totalItems }}</span>
                </div>
                <div class="group">
                    <label>Order Total</label> <span>{{ orderRes?.total | currency: 'USD' }}</span>
                </div>
            </div>
        </mat-step>
    </mat-vertical-stepper>
</div>
<div class="actions" mat-dialog-actions>
    <button
        *ngIf="!!steps[currentStep].cancelText"
        class="cancel"
        mat-flat-button
        (click)="steps[currentStep].cancel()"
    >
        {{ steps[currentStep].cancelText }}
    </button>
    <button
        *ngIf="!!steps[currentStep].nextText"
        mat-flat-button
        color="accent"
        (click)="steps[currentStep].next()"
    >
        {{ steps[currentStep].nextText }}
    </button>
</div>
<div class="spinner" *ngIf="(spinnerActive$ | async)"><mat-spinner></mat-spinner></div>
