import { Component, OnInit, forwardRef, Input, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DgiService } from '@app/core/services/dgi.service';
import * as moment from 'moment';
import { ObjectId } from 'mongodb';

@Component({
    selector: 'ptkr-dgi-version-selector',
    templateUrl: './dgi-version-selector.component.html',
    styleUrls: ['./dgi-version-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DgiVersionSelectorComponent),
            multi: true
        }
    ]
})
export class DgiVersionSelectorComponent implements OnInit, ControlValueAccessor {
    private static _noneData: {
        versionName: string;
        lastUpdated: Date | moment.Moment | string;
        releaseNote: string;
        key: ObjectId | string;
    } = {
        versionName: 'None',
        lastUpdated: null,
        releaseNote: '',
        key: null
    };

    @Input() private itemKey: string = '';

    public dgiVersionData: Array<{
        versionName: string;
        lastUpdated: Date | moment.Moment | string;
        releaseNote: string;
        key: ObjectId | string;
    }> = null;

    public get value(): any {
        return this._value;
    }
    public set value(val: any) {
        this._value = val;
        this._onChange(val);
        this._onTouched();
    }
    private _value: any = null;

    private _onChange: any = () => {};
    private _onTouched: any = () => {};

    constructor(private _dgiService: DgiService, private _cd: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this._dgiService
            .findDgisByItem(this.itemKey || this['itemDeviceAliasKey'])
            .subscribe(res => {
                this.dgiVersionData = [
                    DgiVersionSelectorComponent._noneData,
                    ...res.map(e => {
                        const release: {
                            modifiedBy: string;
                            modifiedOn: Date | moment.Moment | string;
                            notes: string;
                        } = e.metadata.releases.pop();
                        return {
                            versionName: e.version.name,
                            lastUpdated: release.modifiedOn,
                            releaseNote: release.notes,
                            key: e._id
                        };
                    })
                ];
            });
    }

    public writeValue(obj: any): void {
        this.value = obj;
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    public isChecked(key: ObjectId | string | null | undefined ): boolean {
        const k = !!key ? key.toString() : key;
        const v = !!this.value ? this.value.toString() : this.value;
        return k === v;
    }
}
