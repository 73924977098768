<mat-form-field
    floatLabel="never"
    appearance='outline'
    class='notification-chip-list'>
    <mat-chip-list #emails ngDefaultControl>
        <ng-container *ngIf='!!value'>
            <mat-chip *ngFor='let email of value'
                color='primary'
                (removed)='removeChip(email)'>
                {{ email }}
                <mat-icon matChipRemove *ngIf='!disabled'>
                    cancel
                </mat-icon>
            </mat-chip>
        </ng-container>
        <input
            matInput
            type='email'
            [placeholder]='label'
            [disabled]='disabled'
            [matChipInputFor]='emails'
            [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
            [matChipInputAddOnBlur]='true'
            (matChipInputTokenEnd)='addChip($event)'
            (blur)='touch()'
        />
    </mat-chip-list>
    <ng-content select="[mat-error]"></ng-content>
</mat-form-field>
