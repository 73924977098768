// The following types help organize and configure default payloads for tasks
import { TaskTypes } from '../../enums/TaskTypes';

export class BaseTask {
    public type: TaskTypes;
    constructor(type: TaskTypes) {
        this.type = type;
    }
}

// CollectAndUploadDGIs
export interface ITaskCollectAndUploadDGIsPayload {
    DeviceKeys: string[];
}
export namespace DefaultCollectAndUploadDGIsPayload {
    export const asObject: ITaskCollectAndUploadDGIsPayload = {
        DeviceKeys: []
    };
}

// UpdateStarlarkModules
export interface ITaskUpdateStarlarkModulesPayload {}
export namespace DefaultUpdateStarlarkModulesPayload {
    export const asObject: ITaskUpdateStarlarkModulesPayload = {};
}

// UpdateJavascriptModules
export interface ITaskUpdateJavascriptModulesPayload {
    modules?: string[];
}
export namespace DefaultUpdateJavascriptModulesPayload {
    export const asObject: ITaskUpdateJavascriptModulesPayload = {
        modules: []
    };
}

// UpdateGenericOIDs
export interface ITaskUpdateGenericOIDsPayload {
    version: number;
}
export namespace DefaultUpdateGenericOIDsPayload {
    export const asObject: ITaskUpdateGenericOIDsPayload = {
        version: 0
    };
}

// UpdateMakeDefinitions
export interface ITaskUpdateMakeDefinitionsPayload {
    makes: string[];
    all: boolean;
}
export namespace DefaultUpdateMakeDefinitionsPayload {
    export const asObject: ITaskUpdateMakeDefinitionsPayload = {
        makes: [],
        all: true
    };
}

// UpdateDGIs
export interface ITaskUpdateDGIsPayload {
    DeviceKeys: string[];
}
export namespace DefaultUpdateDGIsPayload {
    export const asObject: ITaskUpdateDGIsPayload = {
        DeviceKeys: []
    };
}

// UpdateTECs
export interface ITaskUpdateTECsPayload {}
export namespace DefaultUpdateTECsPayload {
    export const asObject: ITaskUpdateTECsPayload = {};
}

// UploadDataGatheringDump
export interface ITaskUploadDataGatheringDumpPayload {
    ipAddresses: string[];
    uploadUrl: string;
}
export namespace DefaultUploadDataGatheringDumpPayload {
    export const asObject: ITaskUploadDataGatheringDumpPayload = {
        ipAddresses: [],
        uploadUrl: 'https://api.printtrackerpro.com/endpoint/dca/dgt-file'
    };
}

// UploadMeterReads
export interface ITaskUploadMeterReadsPayload {
    deviceKeys: string[];
}
export namespace DefaultUploadMeterReadsPayload {
    export const asObject: ITaskUploadMeterReadsPayload = {
        deviceKeys: []
    };
}

// CollectAndUploadTempMeterReads
export interface ITaskCollectAndUploadTempMeterReadsPayload {
    deviceKey: string;
    dgiKey: string;
    collectTestbox: boolean;
    testboxGroupKey?: string;
}
export namespace DefaultCollectAndUploadTempMeterReadsPayload {
    export const asObject: ITaskCollectAndUploadTempMeterReadsPayload = {
        deviceKey: '',
        dgiKey: '',
        collectTestbox: false,
        testboxGroupKey: null,
    };
}

// UpdateSettings
export interface ITaskUpdateSettingsPayload {
    AllDevices?: boolean;
    Devices?: string[];
    IncludeInstallSettings?: boolean;
}
export namespace DefaultUpdateSettingsPayload {
    export const asObject: ITaskUpdateSettingsPayload = {
        AllDevices: false,
        Devices: [],
        IncludeInstallSettings: true
    };
}

// Restart
export interface ITaskRestartPayload {
    standalone?: boolean;
}
export namespace DefaultRestartPayload {
    export const asObject: ITaskRestartPayload = {
        standalone: false
    };
}

// RequireVersion
export interface ITaskRequireVersionPayload {
    constraint: string;
}
export namespace DefaultRequireVersionPayload {
    export const asObject: ITaskRequireVersionPayload = {
        constraint: '>0.0.0'
    };
}

// MoveEntity
export interface ITaskMoveToEntityPayload {
    entityKey: string;
}
export namespace DefaultMoveToEntityPayload {
    export const asObject: ITaskMoveToEntityPayload = {
        entityKey: null
    };
}

// MoveEntity
export interface ITaskUploadDeviceSNMPDumpPayload {
    deviceKey: string;
}
export namespace DefaultUploadDeviceSNMPDumpPayload {
    export const asObject: ITaskUploadDeviceSNMPDumpPayload = {
        deviceKey: null
    };
}

// AdvertiseRoutes
export interface ITaskAdvertiseRoutesPayload {
    add?: string[];
    remove?: string[];
}
export namespace DefaultAdvertiseRoutesPayload {
    export const asObject: ITaskAdvertiseRoutesPayload = {
        add: [],
        remove: []
    };
}

// UpgradeChromium
export interface ITaskUpgradeChromiumPayload {
    downloadUrl: string;
    waitForLocksDuration: string;
}
export namespace DefaultUpgradeChromiumPayload {
    export const asObject: ITaskUpgradeChromiumPayload = {
        downloadUrl: null,
        waitForLocksDuration: '10s'
    };
}

// UploadFingerprint
export interface ITaskUploadFingerprintPayload {}
export namespace DefaultUploadFingerprintPayload {
    export const asObject: ITaskUploadFingerprintPayload = {};
}

// UploadFingerprint
export interface ITaskNetworkTopologyScanPayload {}
export namespace DefaultNetworkTopologyScanPayload {
    export const asObject: ITaskNetworkTopologyScanPayload = {
        networks: [],
        exclude: [],
    };
}

// CheckSoftwareVersion
export interface ITaskCheckSoftwareVersionPayload {}
export namespace DefaultCheckSoftwareVersionPayload {
    export const asObject: ITaskCheckSoftwareVersionPayload = {};
}

// StateCheckSyncDevices
export interface ITaskStateCheckSyncDevicesPayload {}
export namespace DefaultStateCheckSyncDevicesPayload {
    export const asObject: ITaskStateCheckSyncDevicesPayload = {};
}

// Default Settings
export namespace DefaultTaskPayload {
    export const CollectAndUploadDGIs = DefaultCollectAndUploadDGIsPayload;
    export const UpdateStarlarkModules = DefaultUpdateStarlarkModulesPayload;
    export const UpdateJavascriptModules = DefaultUpdateJavascriptModulesPayload;
    export const UpdateGenericOIDs = DefaultUpdateGenericOIDsPayload;
    export const UpdateMakeDefinitions = DefaultUpdateMakeDefinitionsPayload;
    export const UpdateDGIs = DefaultUpdateDGIsPayload;
    export const UpdateTECs = DefaultUpdateTECsPayload;
    export const UploadDataGatheringDump = DefaultUploadDataGatheringDumpPayload;
    export const UploadMeterReads = DefaultUploadMeterReadsPayload;
    export const CollectAndUploadTempMeterReads = DefaultCollectAndUploadTempMeterReadsPayload;
    export const UpdateSettings = DefaultUpdateSettingsPayload;
    export const Restart = DefaultRestartPayload;
    export const RequireVersion = DefaultRequireVersionPayload;
    export const MoveToEntity = DefaultMoveToEntityPayload;
    export const UploadDeviceSNMPDump = DefaultUploadDeviceSNMPDumpPayload;
    export const UpgradeChromium = DefaultUpgradeChromiumPayload;
    export const UploadFingerprint = DefaultUploadFingerprintPayload;
    export const NetworkTopologyScan = DefaultNetworkTopologyScanPayload;
    export const CheckSoftwareVersion = DefaultCheckSoftwareVersionPayload;
    export const StateCheckSyncDevices = DefaultStateCheckSyncDevicesPayload;
    export const AdvertiseRoutes = DefaultAdvertiseRoutesPayload;
}

// CollectAndUploadDGIs Task
export class CollectAndUploadDGIsTask extends BaseTask {
    public payload: ITaskCollectAndUploadDGIsPayload;
    constructor(payload: ITaskCollectAndUploadDGIsPayload) {
        super(TaskTypes.CollectAndUploadDGIs);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.CollectAndUploadDGIs.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.CollectAndUploadDGIs.asObject;
        }
    }
}

// UpdateStarlarkModules Task
export class UpdateStarlarkModulesTask extends BaseTask {
    public payload: ITaskUpdateStarlarkModulesPayload;
    constructor(payload?: ITaskUpdateStarlarkModulesPayload) {
        super(TaskTypes.UpdateStarlarkModules);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UpdateStarlarkModules.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UpdateStarlarkModules.asObject;
        }
    }
}

// UpdateJavascriptModules Task
export class UpdateJavascriptModulesTask extends BaseTask {
    public payload: ITaskUpdateJavascriptModulesPayload;
    constructor(payload?: ITaskUpdateJavascriptModulesPayload) {
        super(TaskTypes.UpdateJavascriptModules);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UpdateJavascriptModules.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UpdateJavascriptModules.asObject;
        }
    }
}

// UpdateGenericOIDs Task
export class UpdateGenericOIDsTask extends BaseTask {
    public payload: ITaskUpdateStarlarkModulesPayload;
    constructor(payload?: ITaskUpdateStarlarkModulesPayload) {
        super(TaskTypes.UpdateGenericOIDs);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UpdateGenericOIDs.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UpdateGenericOIDs.asObject;
        }
    }
}

// UpdateMakeDefinitions Task
export class UpdateMakeDefinitionsTask extends BaseTask {
    public payload: ITaskUpdateMakeDefinitionsPayload;
    constructor(payload?: ITaskUpdateMakeDefinitionsPayload) {
        super(TaskTypes.UpdateMakeDefinitions);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UpdateMakeDefinitions.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UpdateMakeDefinitions.asObject;
        }
    }
}

// UpdateDGIs Task
export class UpdateDGIsTask extends BaseTask {
    public payload: ITaskUpdateDGIsPayload;
    constructor(payload?: ITaskUpdateDGIsPayload) {
        super(TaskTypes.UpdateDGIs);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UpdateDGIs.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UpdateDGIs.asObject;
        }
    }
}

// UpdateTECs Task
export class UpdateTECsTask extends BaseTask {
    public payload: ITaskUpdateTECsPayload;
    constructor(payload?: ITaskUpdateTECsPayload) {
        super(TaskTypes.UpdateTECs);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UpdateTECs.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UpdateTECs.asObject;
        }
    }
}

// UploadDataGatheringDump Task
export class UploadDataGatheringDumpTask extends BaseTask {
    public payload: ITaskUploadDataGatheringDumpPayload;
    constructor(payload?: ITaskUploadDataGatheringDumpPayload) {
        super(TaskTypes.UploadDataGatheringDump);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UploadDataGatheringDump.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UploadDataGatheringDump.asObject;
        }
    }

    public withIPAddresses(ips: string[]): this {
        this.payload.ipAddresses = ips;
        return this;
    }
}

// UploadMeterReads Task
export class UploadMeterReadsTask extends BaseTask {
    public payload: ITaskUploadMeterReadsPayload;
    constructor(payload?: ITaskUploadMeterReadsPayload) {
        super(TaskTypes.UploadMeterReads);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UploadMeterReads.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UploadMeterReads.asObject;
        }
    }
}

// UploadMeterReads Task
export class CollectAndUploadTempMeterReadsTask extends BaseTask {
    public payload: ITaskCollectAndUploadTempMeterReadsPayload;
    constructor(payload?: ITaskCollectAndUploadTempMeterReadsPayload) {
        super(TaskTypes.CollectAndUploadTempMeterReads);
        if (!!payload) {
            this.payload = {
                ...DefaultTaskPayload.CollectAndUploadTempMeterReads.asObject,
                ...payload
            };
        } else {
            this.payload = DefaultTaskPayload.CollectAndUploadTempMeterReads.asObject;
        }
    }
}

// UpdateSettingsTask Task
export class UpdateSettingsTask extends BaseTask {
    public payload: ITaskUpdateSettingsPayload;
    constructor(payload?: ITaskUpdateSettingsPayload) {
        super(TaskTypes.UpdateSettings);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UpdateSettings.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UpdateSettings.asObject;
        }
    }
}

// Restart Task
export class RestartTask extends BaseTask {
    public payload: ITaskRestartPayload;
    constructor(payload?: ITaskRestartPayload) {
        super(TaskTypes.RestartDCA);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.Restart.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.Restart.asObject;
        }
    }
}

// RequireVersion Task
export class RequireVersionTask extends BaseTask {
    public payload: ITaskRequireVersionPayload;
    constructor(payload?: ITaskRequireVersionPayload) {
        super(TaskTypes.RequireVersion);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.RequireVersion.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.RequireVersion.asObject;
        }
    }
}

// MoveToEntity Task
export class MoveToEntityTask extends BaseTask {
    public payload: ITaskMoveToEntityPayload;
    constructor(payload?: ITaskMoveToEntityPayload) {
        super(TaskTypes.MoveToEntity);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.MoveToEntity.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.MoveToEntity.asObject;
        }
    }
}

// UploadDeviceSNMPDump Task
export class UploadDeviceSNMPDumpTask extends BaseTask {
    public payload: ITaskUploadDeviceSNMPDumpPayload;
    constructor(payload?: ITaskUploadDeviceSNMPDumpPayload) {
        super(TaskTypes.UploadDeviceSNMPDump);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UploadDeviceSNMPDump.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UploadDeviceSNMPDump.asObject;
        }
    }
}

// UpgradeChromium Task
export class UpgradeChromiumTask extends BaseTask {
    public payload: ITaskUpgradeChromiumPayload;
    constructor(payload?: ITaskUpgradeChromiumPayload) {
        super(TaskTypes.UpgradeChromium);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UpgradeChromium.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UpgradeChromium.asObject;
        }
    }
}

// UpgradeChromium Task
export class UploadFingerprintTask extends BaseTask {
    public payload: ITaskUploadFingerprintPayload;
    constructor(payload?: ITaskUploadFingerprintPayload) {
        super(TaskTypes.UploadFingerprint);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.UploadFingerprint.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.UploadFingerprint.asObject;
        }
    }
}

// NetworkTopologyScan Task
export class NetworkTopologyScanTask extends BaseTask {
    public payload: ITaskNetworkTopologyScanPayload;
    constructor(payload?: ITaskNetworkTopologyScanPayload) {
        super(TaskTypes.NetworkTopologyScan);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.NetworkTopologyScan.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.NetworkTopologyScan.asObject;
        }
    }
}

// CheckSoftwareVersion Task
export class CheckSoftwareVersionTask extends BaseTask {
    constructor() {
        super(TaskTypes.CheckSoftwareVersion);
    }
}

export class StateCheckSyncDevicesTask extends BaseTask {
    public payload: ITaskStateCheckSyncDevicesPayload;
    constructor() {
        super(TaskTypes.StateCheckSyncDevices);
    }
}

// AdvertiseRoutes Task
export class AdvertiseRoutesTask extends BaseTask {
    public payload: ITaskAdvertiseRoutesPayload;
    constructor(payload?: ITaskAdvertiseRoutesPayload) {
        super(TaskTypes.AdvertiseRoutes);
        if (!!payload) {
            this.payload = { ...DefaultTaskPayload.AdvertiseRoutes.asObject, ...payload };
        } else {
            this.payload = DefaultTaskPayload.AdvertiseRoutes.asObject;
        }
    }
}
