import {
    IReportScheduledExportEmailPersonalize,
    ReportScheduledExportEmailPersonalize
} from '@libs/iso/core/models/reportScheduled/ReportScheduledExportEmailPersonalize';

export interface IReportScheduledExportEmail {
    enabled: boolean;
    addresses: string[];
    personalize: IReportScheduledExportEmailPersonalize;
}

export class ReportScheduledExportEmail implements IReportScheduledExportEmail {
    public enabled: boolean = true;
    public addresses: string[] = [];
    public personalize: IReportScheduledExportEmailPersonalize = new ReportScheduledExportEmailPersonalize();
    constructor(params?: Partial<IReportScheduledExportEmail>) {
        if (params != null) {
            this.enabled = params.enabled ?? this.enabled;
            this.addresses = params.addresses ?? this.addresses;
            this.personalize =
                new ReportScheduledExportEmailPersonalize(params.personalize) ?? this.personalize;
        }
    }
}
