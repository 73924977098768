import { EntitiesStore, initEntitiesState } from '../store';
import {
    EntitiesActions,
    EntitiesActionsType,
    SearchEntityAction,
    SearchedEntitiesAction
} from '../actions/entities.actions';
import { EntityList } from '@app/models';

class EntitiesHandlers {
    public static handleSearchEntity(
        state: EntitiesStore,
        action: SearchEntityAction
    ): EntitiesStore {
        return new EntityList({
            parent: action.payload
        });
    }

    public static handleSearchedEntities(
        state: EntitiesStore,
        action: SearchedEntitiesAction
    ): EntitiesStore {
        return new EntityList({
            ...state,
            ...action.payload
        });
    }
}

export function entitiesReducer(
    state: EntitiesStore = initEntitiesState,
    action: EntitiesActionsType
): EntitiesStore {
    switch (action.type) {
        case EntitiesActions.SearchEntity:
            return EntitiesHandlers.handleSearchEntity(state, action);
        case EntitiesActions.SearchedEntities:
            return EntitiesHandlers.handleSearchedEntities(state, action);
        default:
            return state;
    }
}
