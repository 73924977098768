<div class="items-list">
    <div class="item" *ngFor="let item of items">
        <div class="item-details">
            <div class="detail">
                <label>Make</label> <span class="value">{{ item.make }}</span>
            </div>
            <div class="detail">
                <label>Part Number</label> <span class="value">{{ item.partNumbers.vendorPartNumber }}</span>
            </div>
            <div class="detail">
                <label>Item Type</label>
                <span class="value">{{ ItemType.toString(item.type) }}</span>
            </div>
            <div class="detail">
                <label>Color</label>
                <span class="value"
                    ><ptkr-part-color
                        [color]="!!item.printing ? item.printing.color : null"
                    ></ptkr-part-color
                ></span>
            </div>
            <div class="detail">
                <label>Yield</label>
                <span class="value">{{
                    item.type === ItemType.Toner ? item.printing.yield : 'N/A'
                }}</span>
            </div>
        </div>
        <div class="item-fields">
            <div class="field">
                <mat-checkbox
                    class="price"
                    #priceCheckbox
                    [checked]="selection.isSelected(item['selectionItems'][0])"
                    (change)="selection.toggle(item['selectionItems'][0])"
                >
                    Price
                </mat-checkbox>
                <ptkr-field-group label="">
                    <mat-form-field floatLabel="never">
                        <input
                            matInput
                            [(ngModel)]="item['selectionItems'][0]['newValue']"
                            [disabled]="!selection.isSelected(item['selectionItems'][0])"
                        />
                    </mat-form-field>
                </ptkr-field-group>
            </div>
            <div class="field">
                <mat-checkbox
                    class="visibility"
                    #visibleCheckbox
                    [checked]="selection.isSelected(item['selectionItems'][1])"
                    (change)="selection.toggle(item['selectionItems'][1])"
                >
                    Visibility
                </mat-checkbox>
                <ptkr-field-group label="">
                    <mat-slide-toggle
                        [checked]="!item.hide"
                        [disabled]="!selection.isSelected(item['selectionItems'][1])"
                        >Visible</mat-slide-toggle
                    >
                </ptkr-field-group>
            </div>
        </div>
    </div>
</div>
