export interface IQueryEngineStatus {
    running: boolean;
    startTime: Date;
    lastActivity: Date;
}

export class QueryEngineStatus implements IQueryEngineStatus {
    public lastActivity: Date;
    public running: boolean;
    public startTime: Date;
    constructor(params: IQueryEngineStatus) {
        this.lastActivity = new Date(params.lastActivity);
        this.startTime = new Date(params.startTime);
        this.running = params.running ?? false;
    }
}
