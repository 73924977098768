<div
    class="formFieldControl"
    [class.formFieldControl--active]="!disabled && showDropdown"
    [class.formFieldControl--disabled]="disabled"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    #selectControl
>
    <div polymorpheus-outlet [content]="selectedContent" (click)="openIfClosed($event)"></div>
</div>
<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="showDropdown"
    [cdkConnectedOverlayMinWidth]="originRect?.width"
>
    <div class="dropdown" (focus)="(proc)" (ptkrClickOutside)="closeIfOpened()">
        <div class="formFieldControl">
            <input
                [placeholder]="filterTextPlaceholder"
                class="formFieldControl-input"
                type="text"
                [value]="filterText"
                (input)="onFilterInput(searchBar.value)"
                #searchBar
            />
        </div>
        <div polymorpheus-outlet [content]="dropdownContent"></div>
    </div>
</ng-template>
