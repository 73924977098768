import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'ptkr-field-group',
    templateUrl: './field-group.component.html',
    styleUrls: ['./field-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldGroupComponent implements OnInit {
    @Input() public label: string = '';
    @Input() public hint: string = '';
    @Input() public required: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
