import { BaseModel, IBaseModel } from '@libs/iso/core/models/BaseModel';
import * as Moment from 'moment';
import { ObjectId } from 'bson';
import { MeterRead } from '../meterRead/MeterRead';
import { DeviceSupplyEvents } from '@libs/iso/core/models/device/DeviceSupplyEvent';
import { SalesQuoteStatus } from '@libs/iso/core/models/device/DeviceSupplyEventSalesQuote';
export { DeviceSupplyEvents, SalesQuoteStatus };

interface IDeviceSupplySettings {
    expectedFillRate?: number;
    expectedYield?: number;
    partNumber?: string;
    supplyCost?: number;
}

export class DeviceSupplySettings implements IDeviceSupplySettings {
    public expectedFillRate: number;
    public expectedYield: number;
    public partNumber: string;
    public supplyCost: number;

    constructor(defaults?: Partial<IDeviceSupplySettings>) {
        if (defaults) {
            this.expectedFillRate = defaults?.expectedFillRate || this.expectedFillRate;
            this.expectedYield = defaults?.expectedYield || this.expectedYield;
            this.partNumber = defaults?.partNumber || this.partNumber;
            this.supplyCost = defaults?.supplyCost || this.supplyCost;
        }
    }
}

export type IVolumeByFormat = { [key: string]: number };

export enum DeviceSupplyEstimatedDepletionSource {
    Device = 'Device',
    Calculated = 'Calculated'
}

interface IDeviceSupplyStatistics {
    estimatedDepletionDate?: Date | Moment.Moment;
    estimatedDepletionDateSource?: DeviceSupplyEstimatedDepletionSource;
    estimatedDepletionDates: any[];
    fillRate?: number;
    actualCostPerPage?: number;
    lostPages?: number;
    volumeByFormat?: IVolumeByFormat;
    firstEstimatedVolumeByFormat?: IVolumeByFormat;
    lastEstimatedVolumeByFormat?: IVolumeByFormat;
}

export class DeviceSupplyStatistics implements IDeviceSupplyStatistics {
    public actualCostPerPage: number;
    public estimatedDepletionDate: Date | moment.Moment;
    public estimatedDepletionDateSource: DeviceSupplyEstimatedDepletionSource;
    public estimatedDepletionDates: any[];
    public fillRate: number;
    public firstEstimatedVolumeByFormat: IVolumeByFormat;
    public lastEstimatedVolumeByFormat: IVolumeByFormat;
    public lostPages: number;
    public volumeByFormat: IVolumeByFormat;

    constructor(defaults?: Partial<IDeviceSupplyStatistics>) {
        this.actualCostPerPage = defaults?.actualCostPerPage ?? this.actualCostPerPage;
        this.estimatedDepletionDate =
            defaults?.estimatedDepletionDate ?? this.estimatedDepletionDate;
        this.estimatedDepletionDateSource =
            defaults?.estimatedDepletionDateSource ?? this.estimatedDepletionDateSource;
        this.estimatedDepletionDates =
            defaults?.estimatedDepletionDates ?? this.estimatedDepletionDates;
        this.fillRate = defaults?.fillRate ?? this.fillRate;
        this.firstEstimatedVolumeByFormat =
            defaults?.firstEstimatedVolumeByFormat ?? this.firstEstimatedVolumeByFormat;
        this.lastEstimatedVolumeByFormat =
            defaults?.lastEstimatedVolumeByFormat ?? this.lastEstimatedVolumeByFormat;
        this.lostPages = defaults?.lostPages ?? this.lostPages;
        this.volumeByFormat = defaults?.volumeByFormat ?? this.volumeByFormat;
    }
}

export interface IDeviceSupply extends IBaseModel {
    deviceKey: ObjectId | string;
    installedDate: Date | Moment.Moment;
    replacedDate: Date | Moment.Moment;
    depletionDate: Date | Moment.Moment;
    confirmedReplacedDate: Date | Moment.Moment;
    replacedByDisable?: boolean;
    notes?: string;
    previousSupplyKey?: ObjectId | string;
    supply: string;
    isColor: boolean;
    firstMeterRead: MeterRead;
    lastMeterRead: MeterRead;
    attributes: { [key: string]: { displayName: string; value: any } };
    settings: IDeviceSupplySettings;
    statistics: IDeviceSupplyStatistics;
    pagesPrinted?: number;
    ratedYield?: number;
    partNumber?: string;
    events: DeviceSupplyEvents;
}

export class DeviceSupply extends BaseModel implements IDeviceSupply {
    public deviceKey: ObjectId | string;
    public installedDate: Date | Moment.Moment = new Date();
    public replacedDate: Date | Moment.Moment = null;
    public depletionDate: Date | Moment.Moment = null;
    public confirmedReplacedDate: Date | Moment.Moment = null;
    public replacedByDisable?: boolean;
    public notes?: string;
    public previousSupplyKey?: ObjectId | string;
    public supply: string;
    public isColor: boolean;
    public firstMeterRead: MeterRead = new MeterRead();
    public lastMeterRead: MeterRead = new MeterRead();
    public attributes: { [key: string]: { displayName: string; value: any } } = {};
    public settings: DeviceSupplySettings = new DeviceSupplySettings();
    public statistics: DeviceSupplyStatistics = new DeviceSupplyStatistics();
    public pagesPrinted?: number = null;
    public ratedYield?: number = null;
    public partNumber?: string;
    public events: DeviceSupplyEvents = new DeviceSupplyEvents();

    constructor(defaults?: Partial<IDeviceSupply>) {
        super(defaults);
        if (defaults) {
            this.deviceKey = defaults?.deviceKey ?? this.deviceKey;
            this.installedDate = defaults?.installedDate || this.installedDate;
            this.replacedDate = defaults?.replacedDate || this.replacedDate;
            this.depletionDate = defaults?.depletionDate || this.depletionDate;
            this.confirmedReplacedDate =
                defaults?.confirmedReplacedDate || this.confirmedReplacedDate;
            this.replacedByDisable = defaults?.replacedByDisable || this.replacedByDisable;
            this.notes = defaults?.notes || this.notes;
            this.previousSupplyKey = defaults?.previousSupplyKey || this.previousSupplyKey;
            this.supply = defaults?.supply || this.supply;
            this.isColor = defaults?.isColor || this.isColor;
            this.firstMeterRead = defaults?.firstMeterRead || this.firstMeterRead;
            this.lastMeterRead = defaults?.lastMeterRead || this.lastMeterRead;
            this.attributes = defaults?.attributes || this.attributes;
            this.settings = new DeviceSupplySettings(defaults?.settings) || this.settings;
            this.statistics = new DeviceSupplyStatistics(defaults?.statistics) || this.statistics;
            this.pagesPrinted = defaults?.pagesPrinted || this.pagesPrinted;
            this.ratedYield = defaults?.ratedYield || this.ratedYield;
            this.partNumber = defaults?.partNumber || this.partNumber;
            this.events = new DeviceSupplyEvents(defaults?.events) || this.events;
        }
    }
}
