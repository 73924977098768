import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Device } from '@libs/iso/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'ptkr-device-list-table-viewer',
    templateUrl: './device-list-table-viewer.component.html',
    styleUrls: ['./device-list-table-viewer.component.scss']
})
export class DeviceListTableViewerComponent implements OnInit {
    @Input() public devices$: Observable<Array<Device>>;
    @Input() public displayedColumns: Array<string> = [
        'make',
        'model',
        'serialNum',
        'ipAddr',
        'assetId'
    ];

    public dataSource: MatTableDataSource<Device>;

    constructor(private _cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.devices$.subscribe(data => {
            this.dataSource = new MatTableDataSource<Device>(data);
            this._cd.detectChanges();
        });
    }

    // Returns the value or a hyphen if the value is null or empty
    public valueOrEmpty(value: any): any {
        if (value === '' || value === null || value === undefined) {
            return '-';
        }
        return value;
    }
}
