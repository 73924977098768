import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard, PermissionGuard } from '@app/routes/guards';
import { SecuredContainerComponent } from '@app/shared/containers';
import { Permission } from '@libs/iso/core';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'invoice',
        loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
        path: 'user',
        canActivate: [LoginGuard],
        component: SecuredContainerComponent,
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },
    {
        path: 'pt',
        canActivate: [LoginGuard, PermissionGuard],
        component: SecuredContainerComponent,
        data: {
            permission: [Permission.UpdateDGI, Permission.UpdateTEC]
        },

        loadChildren: () => import('./print-tracker-tools/print-tracker-tools.module').then(m => m.PrintTrackerToolsModule)
    },
    {
        path: 'entity',
        canActivate: [LoginGuard],
        component: SecuredContainerComponent,
        children: [
            {
                path: 'item',
                canActivate: [LoginGuard, PermissionGuard],
                data: {
                    permission: [Permission.DaemonOnly]
                },
                loadChildren: () => import('./item/item.module').then(m => m.ItemModule)
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'devices',
                loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule)
            },
            {
                path: 'supplies',
                loadChildren: () => import('./supplies/supplies.module').then(m => m.SuppliesModule)
            },
            {
                path: 'home',
                loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'insights',
                loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule)
            },
            {
                path: 'order',
                loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
            },
            {
                path: ':entityId',
                children: [
                    {
                        path: 'admin',
                        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
                    },
                    {
                        path: 'installs',
                        loadChildren: () => import('./installs/installs.module').then(m => m.InstallsModule)
                    },
                    {
                        path: 'devices',
                        loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule)
                    },
                    {
                        path: 'supplies',
                        loadChildren: () => import('./supplies/supplies.module').then(m => m.SuppliesModule)
                    },
                    {
                        path: 'home',
                        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
                    },
                    {
                        path: 'insights',
                        loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule)
                    },
                    {
                        path: 'order',
                        loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
                    },
                    {
                        path: 'jobs',
                        loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule)
                    },
                    {
                        path: '**',
                        redirectTo: '/entity/home/dashboard'
                    }
                ]
            },
            {
                path: '**',
                redirectTo: '/entity/home/dashboard'
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/auth/login'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
