import { HttpRequest, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../services/http.service';
import { Observable } from 'rxjs';

export const Post = requestBuilder('POST');
export const Get = requestBuilder('GET');
export const Put = requestBuilder('PUT');
export const Patch = requestBuilder('PATCH');
export const Delete = requestBuilder('DELETE');

export const Body = requestParamBuilder('BODY');
export const Path = requestParamBuilder('PATH');
export const Query = requestParamBuilder('QUERY');
export const Header = requestParamBuilder('HEADER');

export enum ResponseType {
    ArrayBuffer = 'arraybuffer',
    Blob = 'blob',
    Json = 'json',
    Text = 'text'
}

export function Adapter(adapterFn: Function, start: boolean = true): any {
    return (target: HttpService, propertyKey: string, descriptor: any): any => {
        if (start) {
            descriptor.preAdapter = adapterFn || null;
        } else {
            descriptor.postAdapter = adapterFn || null;
        }
        return descriptor;
    };
}

export function MapClass<T>(constructorFn: new (params: any) => T): any {
    return (target: HttpService, propertyKey: string, descriptor: any): any => {
        descriptor.classToMap = constructorFn || null;
        return descriptor;
    };
}

export function requestBuilder(type: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'): any {
    return (
        url: string,
        responseType: ResponseType = ResponseType.Json,
        customHeaders: { [key: string]: string } = {}
    ): any => (target: HttpService, propertyKey: string, descriptor: any): any => {
        descriptor.value = function(...args: any[]): any {
            const rPath = target[`${propertyKey}_PATH_parameters`];
            const rParams = target[`${propertyKey}_BODY_parameters`];
            const rQuery = target[`${propertyKey}_QUERY_parameters`];
            const rHeader = target[`${propertyKey}_HEADER_parameters`];

            // Path
            const oldUrl = url;
            let updatedUrl = url;
            if (Array.isArray(rPath)) {
                for (const p of rPath) {
                    updatedUrl = updatedUrl.replace(
                        '{' + p['key'] + '}',
                        args[p['parameterIndex']]
                    );
                }
            }
            url = updatedUrl;

            // Body
            let body = {};
            if (Array.isArray(rParams)) {
                if (rParams.findIndex(p => p.key === '') > -1) {
                    const p = rParams.find(rP => rP.key === '');
                    body = args[p['parameterIndex']];
                } else {
                    for (const p of rParams) {
                        body[p['key']] = args[p['parameterIndex']];
                    }
                }
            }

            // Params
            let params = new HttpParams();
            if (Array.isArray(rQuery)) {
                let key, value;
                for (const q of rQuery) {
                    key = q['key'];
                    value = args[q['parameterIndex']];

                    if (typeof value !== 'string') {
                        value = JSON.stringify(value);
                    }
                    // console.log('adding query param', key, value);
                    if (typeof value !== 'undefined') {
                        params = params.append(key, value);
                    }
                }
            }

            let request: HttpRequest<any>;
            let headers: HttpHeaders = new HttpHeaders();

            if (!!customHeaders) {
                for (const key in customHeaders) {
                    // console.log('applying custom header', key, customHeaders[key]);
                    headers = headers.set(key, customHeaders[key]);
                }
            }
            if (!!rHeader) {
                for (const h of rHeader) {
                    // console.log('applying param header', [h['key']][0], args[h['parameterIndex']]);
                    headers = headers.set([h['key']][0], args[h['parameterIndex']]);
                }
            }

            if (type === 'POST' || type === 'PATCH' || type === 'PUT') {
                request = new HttpRequest<any>(type, url, body, {
                    responseType: responseType,
                    headers: headers,
                    params: params
                });
            } else if (type === 'GET' || type === 'DELETE') {
                request = new HttpRequest<any>(type, url, {
                    params: params,
                    responseType: responseType,
                    headers: headers
                });
            }

            let observ: Observable<HttpResponse<any>> = this._http.request(request);

            observ = this.responseInterceptor(
                observ,
                descriptor.preAdapter,
                descriptor.classToMap,
                descriptor.postAdapter
            );
            // Allows for params to be updated if the request is called with different params
            url = oldUrl;
            return observ;
        };
        return descriptor;
    };
}

export function requestParamBuilder(type: string): any {
    return (key: string = ''): any => (
        target: HttpService,
        propertyKey: string,
        parameterIndex: number
    ): any => {
        const mKey = `${propertyKey}_${type}_parameters`;
        const paramObj: any = {
            key: key,
            parameterIndex: parameterIndex
        };

        if (Array.isArray(target[mKey])) {
            target[mKey].push(paramObj);
        } else {
            target[mKey] = [paramObj];
        }
    };
}
