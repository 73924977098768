/**
 * These define all the tasks that we can currently send to
 * a Data Collection Agent
 *
 * @export
 * @enum {number}
 */
export enum TaskTypes {
    UploadMeterReads = 'uploadMeterReads',
    CollectAndUploadTempMeterReads = 'collectAndUploadTempMeterReads',
    ProcessEvents = 'processEvents',
    ScanNetworks = 'scanNetworks',
    ScanDevices = 'scanDevices',
    UpdateSettings = 'updateSettings',
    UpdateTECs = 'updateTECs',
    UpgradeSoftware = 'upgradeSoftware',
    UploadTroubleReport = 'uploadTroubleReport',
    CleanDBs = 'cleanDBs',
    UploadDataGatheringDump = 'uploadDataGatheringDump',
    Healthcheck = 'healthcheck',
    UploadFingerprint = 'uploadFingerprint',
    RestartDCA = 'restart',
    UpdateMakeDefinitions = 'updateMakeDefinitions',
    UpdateGenericOIDs = 'updateGenericOIDs',
    CreateProxy = 'createProxy',
    UpdateDGIs = 'updateDGIs',
    CollectAndUploadDGIs = 'collectAndUploadDGIs',
    UpdateStarlarkModules = 'updateStarlarkModules',
    UpdateJavascriptModules = 'updateJavascriptModules',
    RequireVersion = 'requireVersion',
    MoveToEntity = 'moveToEntity',
    UploadDeviceSNMPDump = 'uploadDeviceSNMPDump',
    UpgradeChromium = 'upgradeChromium',
    NetworkTopologyScan = 'networkTopologyScan',
    CheckSoftwareVersion = 'checkSoftwareVersion',
    EvaluateAgainstDevice = 'evaluateAgainstDevice',
    StateCheckSyncDevices = 'stateCheckSyncDevices',
    AdvertiseRoutes = 'advertiseRoutes',
}

/**
 * Specific to TaskSoftware
 * @enum {number}
 */
export enum OperationTypes {
    Install = 'install',
    Uninstall = 'uninstall'
}
