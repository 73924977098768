enum SettingsTimeFrame {
    MidnightSixAM = '12AM-6AM',
    SixAMNoon = '6AM-12PM',
    NoonSixPM = '12PM-6PM',
    SixPMMidnight = '6PM-12AM'
}

namespace SettingsTimeFrame {
    export function toString(type: SettingsTimeFrame): string {
        switch (type) {
            case SettingsTimeFrame.MidnightSixAM:
                return '12AM - 6AM';
            case SettingsTimeFrame.SixAMNoon:
                return '6AM - 12PM';
            case SettingsTimeFrame.NoonSixPM:
                return '12PM - 6PM';
            case SettingsTimeFrame.SixPMMidnight:
                return '6PM - 12AM';
            default:
                return '';
        }
    }

    export const timesAsArray: SettingsTimeFrame[] = [
        SettingsTimeFrame.MidnightSixAM,
        SettingsTimeFrame.SixAMNoon,
        SettingsTimeFrame.NoonSixPM,
        SettingsTimeFrame.SixPMMidnight
    ];
}

export { SettingsTimeFrame };
