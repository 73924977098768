import { ObjectID } from 'bson';
import { Moment } from 'moment';

export interface IEventActionLog {
    dtTm: Date;
    userKey: string | ObjectID;
    note: string;
    userName: string;
}

export class EventActionLog implements IEventActionLog {
    public dtTm: Date;
    public userKey: string | ObjectID;
    public note: string;
    public userName: string;

    constructor(params?: Partial<IEventActionLog>) {
        if (params) {
            this.dtTm = params.dtTm || this.dtTm;
            this.userKey = params.userKey || this.userKey;
            this.note = params.note || this.note;
            this.userName = params.userName || this.userName;
        }
    }
}
