<div class="review">
    <div class="item" *ngFor="let item of cart.items">
        <div class="group">
            <label>Part Number</label>
            <span>{{ item.partNumber ? item.partNumber : '--' }}</span>
        </div>
        <div class="group">
            <label>Type</label>
            <span>{{ item.type ? ItemType.toString(item.type) : '--' }}</span>
        </div>
        <div class="group">
            <label>Color</label>
            <span>
                <ptkr-part-color [color]="item.color"></ptkr-part-color>
            </span>
        </div>
        <div class="group">
            <label>Yield</label>
            <span>{{ item.yield ? item.yield : '--' }}</span>
        </div>
        <div class="group">
            <label>Class</label>
            <span>{{ item.class ? item.class : '--' }}</span>
        </div>
        <div class="group">
            <label>Price</label>
            <span *ngIf="!cart.isWarranty">{{ item.cost | currency:'USD'}}</span>
            <span *ngIf="cart.isWarranty === true">{{ 0 | currency:'USD'}}</span>
        </div>
        <div class="group qty" *ngIf="!cart.isWarranty">
            <label>Qty</label>
            <mat-form-field floatLabel="never" appearance="outline">
                <input #qty matInput type="number" min="0" step="1" [value]="item.qty" (blur)="updateItemQty(qty.value, item)">
            </mat-form-field>
        </div>
        <div class="group" *ngIf="cart.isWarranty === true">
                <label>Qty</label>
                <span>{{ item.qty }}</span>
            </div>
        <div class="action">
            <button mat-flat-button color="primary" type="button" class="remove-button" (click)="removeItem.emit(item)" *ngIf="cart.isWarranty !== true">
                Remove Item
                <i class="far fa-times"></i>
            </button>
        </div>
    </div>
</div>
