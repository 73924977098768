<div mat-dialog-header class='header'>
    <h3>History for "{{ data.displayName }}"</h3>
</div>
<div mat-dialog-content class='content'>
    <table mat-table [dataSource]='dataSource' class='g-table' style='width: 100%;'>
        <ng-container matColumnDef='timestamp'>
            <th mat-header-cell *matHeaderCellDef>Timestamp</th>
            <td mat-cell *matCellDef='let event'>
                {{ event.timestamp | date:'short' }}
            </td>
        </ng-container>
        <ng-container matColumnDef='skipsRemaining'>
            <th mat-header-cell *matHeaderCellDef>Skips remaining</th>
            <td mat-cell *matCellDef='let event'>
                {{ event.skipsRemaining }}
            </td>
        </ng-container>
        <ng-container matColumnDef='comment'>
            <th mat-header-cell *matHeaderCellDef>Comment</th>
            <td mat-cell *matCellDef='let event'>
                {{ event.comment || '--' }}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef='["timestamp", "skipsRemaining", "comment"]' class="bordered left right"></tr>
        <tr mat-row *matRowDef='let row; columns: ["timestamp", "skipsRemaining", "comment"]'></tr>
    </table>
</div>
<div class='action-buttons' style='float: right;' mat-dialog-actions>
    <button mat-button color='accent' (click)='dialogRef.close()'>Okay</button>
</div>
