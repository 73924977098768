import { DeviceSkipAlertHistoryItem, IDeviceSkipAlertHistoryItem } from '@libs/iso/core/models/device/DeviceSkipAlertHistoryItem';

/**
 * This is the data structure representing the skip alerts feature. Skip alerts allow a user to
 * indicate that they would like to skip n trigger alerts. Trigger alerts indicate that something
 * happened vs that something was resolved, an example might be a low supply alert vs. a supply
 * replacement alert.
 */
export interface IDeviceSkipAlert {
    path: string;

    /**
     * This is the number of skips as configured by the user, this value never changes after being
     * configured by the user.
     */
    skips: number;

    /**
     * This is the number of skips left. It starts out matching skips (what the user provided) but
     * as trigger alerts come in and are skipped, this value will de-increment
     */
    skipsRemaining: number;

    /**
     * This is an array of objects that indicate when the trigger alert was skipped and how many
     * skips remain.
     */
    history: IDeviceSkipAlertHistoryItem[];

    /**
     * Number represents index of last skip event in the history.
     * -1 is interpreted as 'no last skip', which may be the case if the skip alert has never been
     * used for a given supply.
     */
    lastSkip: number;
}

export class DeviceSkipAlert implements IDeviceSkipAlert {
    public path: string = '';
    public skips: number = 0;
    public skipsRemaining: number = 0;
    public history: IDeviceSkipAlertHistoryItem[] = [];
    public lastSkip: number = -1;

    constructor(params?: Partial<IDeviceSkipAlert>) {
        if (!!params) {
            this.path = params.path ?? this.path;
            this.skips = params.skips ?? this.skips;
            this.skipsRemaining = params.skipsRemaining ?? this.skipsRemaining;
            this.lastSkip = params.lastSkip ?? this.lastSkip;

            if (!!params.history) {
                params.history.forEach(item => {
                    this.history.push(new DeviceSkipAlertHistoryItem(item));
                });
            }
        }
    }
}
