import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

@Component({
    selector: 'ptkr-input-prompt',
    templateUrl: './input-prompt.component.html',
    styleUrls: ['./input-prompt.component.scss']
})
export class InputPromptComponent implements OnInit {
    public inputControl: FormControl;

    constructor(
        public dialogRef: MatDialogRef<InputPromptComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { type: 'text' | 'email'; placeholder: string }
    ) {}

    ngOnInit(): void {
        if (this.data.type === 'text') {
            this.inputControl = new FormControl('', Validators.required);
        } else if (this.data.type === 'email') {
            this.inputControl = new FormControl('', [Validators.required, CustomValidators.email]);
        } else {
            this.inputControl = new FormControl('');
        }
    }

    public confirmData(control: FormControl): void {
        if (!control.valid) {
            control.markAsTouched();
            return;
        } else {
            this.dialogRef.close(control.value);
        }
    }
}
