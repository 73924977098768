import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    EditBillingMeterModalComponent,
    ConfirmModalComponent,
    RadioSelectorModalComponent
} from '@app/shared/overlays';
import { PageCountMeterRead } from '@libs/iso/core/models/meterRead/PageCountMeterRead';
import { ActivatedRoute } from '@angular/router';
import { MeterTypeNameMap } from '@libs/iso/core';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import {
    BillingMeterPreference,
    IBillingMeterPreference
} from '@libs/iso/core/models/settings/BillingMeterPreference';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { take } from 'rxjs/operators';

@Component({
    selector: 'ptkr-counter-picker',
    templateUrl: './counter-picker.component.html',
    styleUrls: ['./counter-picker.component.scss']
})
export class CounterPickerComponent implements OnInit {
    @Input()
    public availableMeters: PageCountMeterRead;

    @Input()
    public get currentSelection(): { [name: string]: IBillingMeterPreference } {
        return this.currentSelectionValue;
    }
    public set currentSelection(value: { [name: string]: IBillingMeterPreference }) {
        if (!!value) {
            this.currentSelectionValue = value;
        } else {
            this.currentSelectionValue = {};
        }
    }
    public currentSelectionValue: { [name: string]: IBillingMeterPreference };

    // When true, it means we are using this to modify settings at the device level.
    // Behavior is slightly different: Choices are based on the device.
    @Input()
    public isDevice: boolean = false;

    // Will try to get it from activated route if it can.
    @Input()
    public meterTypeNameMap: MeterTypeNameMap;

    @Output()
    public currentSelectionChange: EventEmitter<{
        [name: string]: IBillingMeterPreference;
    }> = new EventEmitter<{ [name: string]: IBillingMeterPreference }>();

    constructor(
        private _dialog: MatDialog,
        private _activatedRoute: ActivatedRoute,
        private _store: Store<GlobalStore>,
        private _cd: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        if (!this.meterTypeNameMap) {
            this._activatedRoute.data.subscribe(data => {
                this.meterTypeNameMap = new MeterTypeNameMap(data.meterTypeNameMap);
                this._store.dispatch(new ToggleGlobalSpinnerAction(false));
            });
        }
    }

    public addMeter(): void {
        this._dialog
            .open(EditBillingMeterModalComponent, {
                data: {
                    meters: this.availableMeters,
                    meterTypeNameMap: this.meterTypeNameMap,
                    meterKey: undefined,
                    selected: undefined,
                    otherMeterKeys: Object.keys(this.currentSelection),
                    title: 'Add Billing Meter',
                    isDevice: this.isDevice
                }
            })
            .afterClosed()
            .subscribe(res => {
                if (!!res && res.do === 'update') {
                    this.currentSelectionChange.emit({
                        ...this.currentSelection,
                        [res.meterKey]: {
                            displayName: this.meterTypeNameMap.getName('default', res.meterKey),
                            preferences: res.selection
                        }
                    });
                }
            });
    }

    public editMeter(counterKey: string): void {
        if (counterKey === undefined) {
            return;
        }
        this._dialog
            .open(EditBillingMeterModalComponent, {
                data: {
                    meters: this.availableMeters,
                    meterTypeNameMap: this.meterTypeNameMap,
                    meterKey: counterKey,
                    selected: this.currentSelection[counterKey].preferences,
                    otherMeterKeys: Object.keys(this.currentSelection).filter(
                        (value: string) => value !== counterKey
                    ),
                    title: 'Edit Billing Meter',
                    isDevice: this.isDevice
                }
            })
            .afterClosed()
            .subscribe(res => {
                if (!!res && res.do === 'update') {
                    this.currentSelectionChange.emit({
                        ...this._removeMeter(this.currentSelection, counterKey),
                        [res.meterKey]: {
                            displayName: this.meterTypeNameMap.getName('default', res.meterKey),
                            preferences: res.selection
                        }
                    });
                }
            });
    }

    public deleteMeter(counterKey: string): void {
        if (counterKey === undefined) {
            return;
        }
        this._dialog
            .open(ConfirmModalComponent, {
                data: {
                    title: 'Remove Billing Meter',
                    content: 'This will remove this billing meter from the billing meter list.',
                    secondaryContent: 'Do you wish to continue?',
                    buttons: {
                        confirm: 'Remove',
                        cancel: 'Cancel'
                    }
                }
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(res => {
                if (res) {
                    this.currentSelectionChange.emit({
                        ...this._removeMeter(this.currentSelection, counterKey)
                    });
                }
            });
    }

    public manuallySetPreference(counterKey: string): void {
        if (counterKey === undefined) {
            return;
        }
        this._dialog
            .open(RadioSelectorModalComponent, {
                data: {
                    title: 'Pick a meter',
                    description: `Select one of the options below. Once you press 'confirm', this device will
                    begin to report the billing meter named
                    '${this.currentSelection[counterKey].displayName}' with the data from the meter
                    you select.`,
                    options: this.currentSelection[counterKey].preferences.map(e => ({
                        name: this.meterTypeNameMap.getName(e.format, e.meter),
                        meter: e.meter,
                        format: e.format
                    }))
                }
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(res => {
                if (!!res && !!res.format && !!res.meter) {
                    this.currentSelection[counterKey].preferences = [
                        { format: res.format, meter: res.meter }
                    ];
                    this._cd.detectChanges();
                }
            });
    }

    public displayBillingMeter(format: string, meter: string): string {
        return this.meterTypeNameMap.getName(format, meter);
    }

    public findCounterPrefs(target: object, prefs: { [key: string]: any }): string {
        const resolution = Object.keys(prefs).find(key => prefs[key] === target);
        return resolution;
    }

    public showTable(): boolean {
        return (
            !!this.currentSelection &&
            this.currentSelection &&
            Object.keys(this.currentSelection).length > 0
        );
    }

    public getCounterData(): Array<BillingMeterPreference> {
        return Object.values(this.currentSelection);
    }

    private _removeMeter(
        counters: { [key: string]: IBillingMeterPreference },
        key: string
    ): { [key: string]: IBillingMeterPreference } {
        const { [key]: deleteMe, ...rest }: any = counters;
        return rest;
    }
}
