export interface IAcuItem {
    type: string;
    description: string;
}

export class AcuItem implements IAcuItem {
    public type: string = '';
    public description: string = '';

    constructor(params: Partial<IAcuItem>) {
        if (!!params) {
            this.type = params.type || this.type;
            this.description = params.description || this.description;
        }
    }
}
