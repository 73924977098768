enum WarrantyReasons {
    BgScatter,
    BlackPage,
    BlankPageAll,
    BlankPagePartial,
    BlankPAgeOccasional,
    BlankSpots,
    BubblePrint,
    CompressedPrint,
    ContaminationOnBlack,
    Creases,
    Curl,
    DistortedImage,
    Dropouts,
    FadingPrint,
    FadingPrintWithBubbles,
    GrayBackground,
    HorizontalBlackLines,
    HorizontalSmudges,
    HorizontalWhiteLines,
    LightPrint,
    DarkPrint,
    FadedPrint,
    LooseToner,
    MishapenImages,
    ParallelPerpendicularBlackLines,
    RepetitiveDefect,
    Skew,
    TonerSmear,
    TonerSpecks,
    VerticalBlackLines,
    VerticalDots,
    VerticalWhiteDots,
    VerticalWhiteLines,
    WhiteStripes
}

namespace WarrantyReasons {
    export function toString(type: WarrantyReasons): string {
        switch (type) {
            case WarrantyReasons.BgScatter:
                return 'Background scatter, contamination';
            case WarrantyReasons.BlackPage:
                return 'Black page';
            case WarrantyReasons.BlankPageAll:
                return 'Blank page-All';
            case WarrantyReasons.BlankPagePartial:
                return 'Blank page-Partial';
            case WarrantyReasons.BlankPAgeOccasional:
                return 'BlankPage-Occasional';
            case WarrantyReasons.BlankSpots:
                return 'Blank spots';
            case WarrantyReasons.BubblePrint:
                return 'Bubble print';
            case WarrantyReasons.CompressedPrint:
                return 'Compressed print';
            case WarrantyReasons.ContaminationOnBlack:
                return 'Contamination on black';
            case WarrantyReasons.Creases:
                return 'Creases';
            case WarrantyReasons.Curl:
                return 'Curl';
            case WarrantyReasons.DistortedImage:
                return 'Distorted image';
            case WarrantyReasons.Dropouts:
                return 'Dropouts-missing parts in image';
            case WarrantyReasons.FadingPrint:
                return 'Fading print';
            case WarrantyReasons.FadingPrintWithBubbles:
                return 'Fading print with bubbles';
            case WarrantyReasons.GrayBackground:
                return 'Gray background';
            case WarrantyReasons.HorizontalBlackLines:
                return 'Horizontal black lines';
            case WarrantyReasons.HorizontalSmudges:
                return 'Horizontal smudges';
            case WarrantyReasons.HorizontalWhiteLines:
                return 'Horizontal white lines';
            case WarrantyReasons.LightPrint:
                return 'Light print';
            case WarrantyReasons.DarkPrint:
                return 'Dark print';
            case WarrantyReasons.FadedPrint:
                return 'Faded print';
            case WarrantyReasons.LooseToner:
                return 'Loose toner on paper';
            case WarrantyReasons.MishapenImages:
                return 'Mishapen images';
            case WarrantyReasons.ParallelPerpendicularBlackLines:
                return 'Parallel or perpendicular black lines';
            case WarrantyReasons.RepetitiveDefect:
                return 'Repetitive defect';
            case WarrantyReasons.Skew:
                return 'Skew';
            case WarrantyReasons.TonerSmear:
                return 'Toner smear';
            case WarrantyReasons.TonerSpecks:
                return 'Toner specks';
            case WarrantyReasons.VerticalBlackLines:
                return 'Vertical black lines';
            case WarrantyReasons.VerticalDots:
                return 'Vertical dots';
            case WarrantyReasons.VerticalWhiteDots:
                return 'Vertical white dots';
            case WarrantyReasons.VerticalWhiteLines:
                return 'Vertical white lines';
            case WarrantyReasons.WhiteStripes:
                return 'White Stripes';
            default:
                return '';
        }
    }

    export const asArray: WarrantyReasons[] = [
        WarrantyReasons.BgScatter,
        WarrantyReasons.BlackPage,
        WarrantyReasons.BlankPageAll,
        WarrantyReasons.BlankPagePartial,
        WarrantyReasons.BlankPAgeOccasional,
        WarrantyReasons.BlankSpots,
        WarrantyReasons.BubblePrint,
        WarrantyReasons.CompressedPrint,
        WarrantyReasons.ContaminationOnBlack,
        WarrantyReasons.Creases,
        WarrantyReasons.Curl,
        WarrantyReasons.DistortedImage,
        WarrantyReasons.Dropouts,
        WarrantyReasons.FadingPrint,
        WarrantyReasons.FadingPrintWithBubbles,
        WarrantyReasons.GrayBackground,
        WarrantyReasons.HorizontalBlackLines,
        WarrantyReasons.HorizontalSmudges,
        WarrantyReasons.HorizontalWhiteLines,
        WarrantyReasons.LightPrint,
        WarrantyReasons.DarkPrint,
        WarrantyReasons.FadedPrint,
        WarrantyReasons.LooseToner,
        WarrantyReasons.MishapenImages,
        WarrantyReasons.ParallelPerpendicularBlackLines,
        WarrantyReasons.RepetitiveDefect,
        WarrantyReasons.Skew,
        WarrantyReasons.TonerSmear,
        WarrantyReasons.TonerSpecks,
        WarrantyReasons.VerticalBlackLines,
        WarrantyReasons.VerticalDots,
        WarrantyReasons.VerticalWhiteDots,
        WarrantyReasons.VerticalWhiteLines,
        WarrantyReasons.WhiteStripes
    ];
}

export { WarrantyReasons };
