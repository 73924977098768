import { ObjectId } from 'mongodb';

export enum SalesQuoteStatus {
    Unknown,
    Pending,
    Created,
    Failed,
}

abstract class BaseStatus {
    public timestamp: Date;
    public dataProcessorKey: ObjectId | string;

    constructor(props?: { [K in keyof BaseStatus]: BaseStatus[K] | string }) {
        if (props) {
            this.timestamp = new Date(props.timestamp);
            this.dataProcessorKey = props.dataProcessorKey;
        }
    }
}

class SalesQuoteFailedStatus extends BaseStatus {
    public error: string;

    public constructor(props?: { [K in keyof SalesQuoteFailedStatus]: SalesQuoteFailedStatus[K] }) {
        super(props);
        if (props) {
            this.error = props.error;
        }
    }
}

class SalesQuotePendingStatus extends BaseStatus {}
class SalesQuoteCreatedStatus extends BaseStatus {
    public salesQuoteNumber: string;

    public constructor(props?: { [K in keyof SalesQuoteCreatedStatus]: SalesQuoteCreatedStatus[K] }) {
        super(props);
        if (props) {
            this.salesQuoteNumber = props.salesQuoteNumber;
        }
    }
}

export class SalesQuoteEventStatus {
    public pending: SalesQuotePendingStatus;
    public created?: SalesQuoteCreatedStatus;
    public failed?: SalesQuoteFailedStatus;

    constructor(props?: { [K in keyof SalesQuoteEventStatus]: SalesQuoteEventStatus[K] }) {
        if (props) {
            this.pending = new SalesQuotePendingStatus(props.pending);
            if (props.created) {
                this.created = new SalesQuoteCreatedStatus(props.created);
            }
            if (props.failed) {
                this.failed = new SalesQuoteFailedStatus(props.failed);
            }
        }
    }

    public status(): SalesQuoteStatus {
        if (!this.created && !this.failed) {
            return SalesQuoteStatus.Pending;
        } else if (this.created) {
            return SalesQuoteStatus.Created;
        } else if (this.failed) {
            return SalesQuoteStatus.Failed;
        } else {
            return SalesQuoteStatus.Unknown;
        }
    }

    public description(): string {
        switch (this.status()) {
            case SalesQuoteStatus.Pending:
                return 'Pending';
            case SalesQuoteStatus.Created:
                return this.created.salesQuoteNumber;
            case SalesQuoteStatus.Failed:
                return 'Failed';
            default:
                return '';
        }
    }
}
