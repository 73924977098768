<div class="secured-sidebar">
<!--    <div class="order-button" *ptkrPermission="Permission.DaemonOnly">-->
<!--        <button mat-flat-button color="accent" (click)="openWelcomeWizard()">-->
<!--            <i class="fas fa-shopping-cart"></i>-->
<!--            Setup-->
<!--        </button>-->
<!--    </div>-->
    <!-- <div class="order-button" *ptkrPermission="Permission.OrderToner">
        <button mat-flat-button color="accent" (click)="orderToner.emit()">
                <i class="fas fa-shopping-cart"></i>
                Order toner
        </button>
    </div>
    <div class="warranty-button" *ptkrPermission="Permission.OrderToner">
        <button mat-button color="primary" (click)="openWarranty.emit()">
            Got a defective toner?
        </button>
    </div> -->
    <div *ngIf="dealerLogo" class="container mx-auto">
        <img class="w-full h-auto" [src]="dealerLogo"/>
    </div>
    <div class="menu-elements">
        <mat-list>
            <ng-container *ngFor="let mainItem of menuItems">
                <a mat-list-item class="navigation-item" [class.expandable]="mainItem.children.length > 0" [class.active]="activeLink === mainItem.active"
                    [class.expanded]="expanded === mainItem.active" (click)="selectPage(mainItem.route, mainItem.children.length > 0 ? mainItem.active : '')">
                    <span *ngIf="mainItem.icon" class="sidenav-icon">
                        <i class="{{ mainItem.icon }}"></i>
                    </span>
                    <span class="title">{{ mainItem.title }}</span>
                    <span *ngIf="mainItem.children.length > 0" class="expandable-icon">
                        <i class="far fa-chevron-left"></i>
                    </span>
                </a>
                <span *ngIf="mainItem.children.length > 0" class="sub-items items-{{ mainItem.children.length }}">
                    <a *ngFor="let childItem of mainItem.children" mat-list-item class="navigation-item" [class.active]="activeLink === mainItem.active && activeSubLink === childItem.active"
                        (click)="selectPage(childItem.route, childItem.active, childItem.top)">
                        <span class="title">{{ childItem.title }}</span>
                    </a>
                </span>
            </ng-container>
            <ng-container *ngIf="hasBillingPermission">
                <a mat-list-item class="navigation-item" (click)="navigateBilling()">
                    <span class="sidenav-icon">
                        <i class="far fa-credit-card"></i>
                    </span>
                    <span class="title" i18n>Billing</span>
                    <span class="sidenav-icon" style="padding-right: 0">
                        <i class="far fa-external-link"></i>
                    </span>
                </a>
            </ng-container>
            <div class="expirationCounter">
                <ng-container *ngIf="!!expiresOn">
                    <ng-container *ngIf="daysRemaining > 0; else isExpired">
                        <a mat-list-item class="navigation-item expiration-counter normal-counter">
                            <span class="sidenav-icon">
                                <i class="fas fa-clock"></i>
                            </span>
                            <span class="title" i18n>Days remaining: {{ daysRemaining }}</span>
                        </a>
                    </ng-container>

                    <ng-template #isExpired>
                        <a mat-list-item class="navigation-item expiration-counter expired-counter">
                            <span class="sidenav-icon">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="title" i18n>Account is expired</span>
                        </a>
                    </ng-template>
                </ng-container>
            </div>
        </mat-list>
    </div>
<!--    <div class="jobs-button">-->
<!--        <button mat-button color="accent" (click)="openJobs.emit()">-->
<!--			<mat-icon>dynamic_feed</mat-icon> Jobs-->
<!--        </button>-->
<!--    </div>-->
</div>
