export enum SupportedMeterTypeCategory {
    Totals = 'Totals',
    Prints = 'Prints',
    Copies = 'Copies',
    BlackWhite = 'Black/White',
    Color = 'Color',
    Scans = 'Scans',
    Faxes = 'Faxes',
    ThreeTier = 'Three Tier Colors',
    A3 = 'A3',
    A4 = 'A4'
}
