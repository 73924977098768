import { Log } from '@libs/iso/core/models/rts/Log';
import { Task } from '@libs/iso/core/models/rts/Task';
import { JobOriginTypes, JobDestinationTypes, JobStatusTypes } from '@libs/iso/core/enums';
import * as moment from 'moment';
import * as AWS from 'aws-sdk';

/** AWS user: rts */
AWS.config.update({
    accessKeyId: 'AKIARWQQ5PTAQKULI4F6',
    secretAccessKey: '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'
});

let iotData: AWS.IotData;

export interface IJobStatus {
    type: JobStatusTypes;
    payload?: any;
}

export class JobStatus {
    public type: JobStatusTypes;
    public payload?: any;

    constructor(params: IJobStatus) {
        this.type = params.type || JobStatusTypes.Sent;
        this.payload = params.payload || this.payload;
    }
}
export interface IJob {
    _id?: string;
    installId: Array<string>;
    entityId: string;
    userId: string;
    metadata: any;
    jobName?: string;
    jobDescription?: string;
    createdDate: number;
    modifiedDate: number;
    destination: JobDestinationTypes;
    status: JobStatus;
    origin: JobOriginTypes;
    tasks?: Array<Task>;
    logs?: Array<Log>;
    hidden?: boolean;
}

export class Job implements IJob {
    public _id?: string;
    public installId: Array<string>;
    public entityId: string;
    public userId: string;
    public metadata: any;
    public jobName: string;
    public jobDescription: string;
    public createdDate: number;
    public modifiedDate: number;
    public destination: JobDestinationTypes;
    public status: JobStatus;
    public origin: JobOriginTypes;
    public tasks: Array<Task>;
    public logs: Array<Log>;
    public hidden: boolean;

    constructor(params: Partial<IJob>) {
        this.installId = params.installId || this.installId;
        this.entityId = params.entityId || this.entityId;
        this.userId = params.userId || this.userId;
        this.metadata = params.metadata || this.metadata;
        this.jobName = params.jobName || this.jobName;
        this.jobDescription = params.jobDescription || this.jobDescription;
        this.createdDate = moment().unix();
        this.modifiedDate = params.modifiedDate || moment().unix();
        this.destination = params.destination || this.destination;
        this.status = new JobStatus({
            type: JobStatusTypes.Sent
        });
        this.origin = params.origin || JobOriginTypes.WebAdmin;
        this.tasks = [];
        this.logs = [];
        this.hidden = params.hidden || this.hidden;

        if (!!params.tasks) {
            params.tasks.forEach(
                (task): void => {
                    this.tasks.push(
                        new Task({
                            type: task.type,
                            payload: task.payload
                        })
                    );
                }
            );
        }

        if (!!params.logs) {
            if (params.logs) {
                params.logs.forEach(
                    (log): void => {
                        this.logs.push(
                            new Log({
                                name: log.name,
                                payload: log.payload
                            })
                        );
                    }
                );
            } else {
                this.logs.push(
                    new Log({
                        name: 'init',
                        payload: 'Job was initilized'
                    })
                );
            }
        }

        iotData = new AWS.IotData({
            endpoint: 'a2qg2go8w28t1u-ats.iot.us-west-2.amazonaws.com',
            region: 'us-west-2'
        });
    }

    public async publish(): Promise<any> {
        return new Promise(
            (resolve, reject): void => {
                this.installId.map(installId => {
                    const thisInstall = {
                        ...this,
                        installId: installId
                    };

                    const params = {
                        topic: `@pt/broker/dca/${installId}/command/job`,
                        payload: JSON.stringify(thisInstall),
                        qos: 1
                    };

                    iotData.publish(
                        params,
                        (err): void => {
                            if (err) {
                                reject(err);
                            } else {
                                resolve(this);
                            }
                        }
                    );
                });
            }
        );
    }
}
