import { Injectable } from '@angular/core';
import { validateIpRange } from '@libs/iso/core/helpers/ip';

@Injectable()
export class ValidationService {
    constructor() {}

    public static ipAddress(input: string, type: 'include' | 'exclude'): any {
        return validateIpRange(input, type);
    }
}
