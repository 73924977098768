import * as _moment from 'moment';
import { Moment } from 'moment';
import { Status } from './Status';

const moment = _moment;

export interface BaseDocModel {
    _id: string;
    id: string;
    status: Status;
    createdDate: Moment;
    modifiedDate: Moment;
}

export interface BaseDocConstructorParams extends BaseDocModel {
    _id: string;
    createdDate: any; // Moment OR { milliseconds: number }
    modifiedDate: any; // Moment OR { milliseconds: number }
}

export class BaseDoc implements BaseDocModel {
    public _id: string = '';
    public id: string = '';
    public status: Status = null;
    public createdDate: Moment = null;
    public modifiedDate: Moment = null;

    constructor(params?: Partial<BaseDocConstructorParams>) {
        if (!!params) {
            this._id = params._id || params.id || this._id;
            this.id = params._id || params.id || this.id;
            this.status =
                Status.asArray().indexOf(params.status) > -1 ? params.status : this.status;
            if (!!params.createdDate) {
                if (params.createdDate instanceof moment) {
                    this.createdDate = moment(params.createdDate.valueOf());
                } else if (!!params.createdDate.milliseconds) {
                    this.createdDate = moment(params.createdDate.milliseconds);
                }
            }
            if (!!params.modifiedDate) {
                if (params.modifiedDate instanceof moment) {
                    this.modifiedDate = moment(params.modifiedDate.valueOf());
                } else if (!!params.modifiedDate.milliseconds) {
                    this.modifiedDate = moment(params.modifiedDate.milliseconds);
                }
            }
        }
    }
}
