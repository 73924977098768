import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'ptkr-inline-editable-field',
    templateUrl: './inline-editable-field.component.html',
    styleUrls: ['./inline-editable-field.component.scss']
})
export class InlineEditableFieldComponent implements AfterViewInit {
    @ViewChild(MatMenuTrigger) protected trigger: MatMenuTrigger;

    @Input() public type: string;

    /*
     * Used for formatting the display of the value.
     * We can take into consideration content projection so this will be delegated to the parent
    */
    @Input() public valueFormatType: 'number' | 'currency' | 'percent';

    // Quick fix for issues involving SkipAlertControlComponent
    @Input()
    public set value(value: any) {
        this._setValue(value, false);
    }

    public get value(): any {
        return this._value;
    }

    @Output() public changed$: EventEmitter<any> = new EventEmitter<any>();

    public control: FormControl = new FormControl('');

    private _value: any;

    constructor() {
    }

    public ngAfterViewInit(): void {
        this.trigger.menuOpened.subscribe({
            next: () => {
                this.onOpen();
            }
        });
    }

    public onCancel(): void {
        if (this.valueFormatType === 'percent' && !isNaN(parseFloat(this._value))) {
            this.control.setValue(this._value * 100);
        } else {
            this.control.setValue(this._value);
        }
    }

    public onAccept(closeMenu?: boolean): void {
        this._setValue(this.control.value, true);

        if (closeMenu) {
            this.trigger.closeMenu();
        }
    }

    public onOpen(): void {
        if (this.valueFormatType === 'percent' && !isNaN(parseFloat(this._value))) {
            this.control.setValue(this._value * 100);
        }
    }

    public onClose(closeWithSave?: boolean): void {
        if (closeWithSave) {
            this.onAccept(false);
        }
    }

    private _setValue(obj: any, triggerOnChanges: boolean = false): void {
        this.control.setValue(obj);
        this._value = obj;

        if (triggerOnChanges) {
            if (this.type === 'number' && !isNaN(parseFloat(obj))) {
                obj = parseFloat(obj);
                if (this.valueFormatType === 'percent') {
                    obj = obj / 100;
                }
                this._value = obj;
            }
            this.changed$.emit(obj);
        }
    }
}
