import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import {
    EntityActions,
    LoginEntityAction,
    CompleteLoginAction,
    RequestEntityChangeAction,
    ChangeEntityAction
} from '../actions';
import { GlobalStore } from '@app/state/store';
import { Store, Action } from '@ngrx/store';
import { StorageService, EntityService, NotificationService, RouterService } from '@app/services';

@Injectable()
export class EntityEffects {
    constructor(
        private _actions$: Actions,
        private _storageService: StorageService,
        private _entityService: EntityService,
        private _notificationService: NotificationService,
        private _store: Store<GlobalStore>,
        private _routerService: RouterService
    ) {}

    @Effect()
    public loginEntity$: Observable<Action> = this._actions$.pipe(
        ofType(EntityActions.LoginEntity),
        switchMap((action: LoginEntityAction) => of(new CompleteLoginAction()))
    );

    @Effect()
    public requestEntityChange$: Observable<Action> = this._actions$.pipe(
        ofType(EntityActions.RequestEntityChange),
        map((action: RequestEntityChangeAction) => action.payload),
        switchMap(entityId => this._entityService.getEntityDetails(entityId)),
        switchMap(entity => of(new ChangeEntityAction(entity)))
    );

    @Effect({ dispatch: false })
    public changeEntity$: Observable<{}> = this._actions$.pipe(
        ofType(EntityActions.ChangeEntity),
        map((action: ChangeEntityAction) => action.payload),
        tap(entity => this._routerService.entityChanged()),
        switchMap(() => of())
    );
}
