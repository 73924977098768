import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Install, NewJob } from '@libs/iso/core';
import { MatTableDataSource } from '@angular/material/table';
import { JobService } from '@app/services';
import * as moment from 'moment';

@Component({
    selector: 'ptkr-job-list-table',
    templateUrl: './job-list-table.component.html',
    styleUrls: ['./job-list-table.component.scss']
})
export class JobListTableComponent implements OnInit {
    @Input('install') public install: Install;
    @Input('limit') public limit: number;
    public dataSource: MatTableDataSource<NewJob>;
    public displayedColumns: Array<string> = [
        'name',
        'createdDate',
        'modifiedDate',
        'jobDuration',
        'tasks',
        'status'
    ];

    constructor(private _jobService: JobService, private _cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.loadJobs();
    }

    public loadJobs(): void {
        this._jobService
            .getJobsByInstall(this.install._id, this.limit)
            .subscribe(res => {
                this.dataSource = new MatTableDataSource<NewJob>(res);
                this._cd.detectChanges();
            });
    }

    public jobDuration(job: NewJob): string {
        if (!!job.createdDate && !!job.modifiedDate) {
            const start = moment(job.createdDate);
            const end = moment(job.modifiedDate);
            const diff = end.diff(start, 'minutes');

            if (diff <= 0) {
                return 'Less than a minute';
            }
            if (diff === 1) {
                return '1 minute';
            }
            return `${diff} minutes`;
        } else {
            return 'Unknown';
        }
    }

    public jobTasks(job: NewJob): Array<string> {
        if (!!job.tasks) {
            return job.tasks.map(task => !!task['type'] ? task['type'] : 'dca-origin');
        }
    }
}
