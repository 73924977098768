import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenu } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User, WindowSize } from '@app/models';
import { InstallService, UserService, WindowSizeService } from '@app/services';
import { AuditLogItem, AuditLogRequesterType, AuditLogType, IAuditLogItem, Install, IWithUserFullName } from '@libs/iso/core';
import { Observable, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Component({
    selector: 'ptkr-history-tab',
    templateUrl: './history-tab.component.html',
    styleUrls: ['./history-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryTabComponent implements OnInit {
    @ViewChild(MatSort, { static: true })
    public matSort: MatSort;
    @ViewChild('resolutionStatusMenu', { static: true })
    public resolutionMenu: MatMenu;

    private _currentTableParams: {
        page: number;
        limit: number;
        sort: string;
        sortOrder: string | number;
    };

    private msInDay: number = 24 * 60 * 60 * 1000;
    private initialDays: number = 30;
    private startDate: Date = new Date(Date.now() - this.msInDay * this.initialDays);
    private endDate: Date = new Date();
    private includeAncestors: boolean = false;
    public tableParamsFormGroup: FormGroup = new FormGroup({
        startDate: new FormControl(this.startDate),
        endDate: new FormControl(this.endDate),
        includeAncestors: new FormControl(this.includeAncestors)
    });

    public dataSource: MatTableDataSource<IAuditLogItem> = new MatTableDataSource<IAuditLogItem>([]);
    @Input('audits')
    public set audits(value: IAuditLogItem[]) {
        this.dataSource.data = value;
    }
    public get audits(): IAuditLogItem[] {
        return this.dataSource.data;
    }

    private _id: string = null;
    @Input('id')
    public set id(value: string) {
        this._id = value;
    }
    public get id(): string {
        return this._id;
    }

    @Output()
    public tableParamsChanged: EventEmitter<{
        startDate: string;
        endDate: string;
        includeAncestors: boolean;
    }> = new EventEmitter<{
        startDate: string;
        endDate: string;
        includeAncestors: boolean;
    }>();

    @Output()
    public exportCsv: EventEmitter<{
        sort: string;
        sortOrder: string | number;
    }> = new EventEmitter<{
        sort: string;
        sortOrder: string | number;
    }>();

    // tslint:disable-next-line:no-magic-numbers
    public pageSizeOptions: number[] = [5, 10, 20];
    public displayedColumns: string[] = [
        // 'select',
        'createdDate',
        'source',
        'type',
        'message',
        'requestedby'
    ];
    public AuditLogType: typeof AuditLogType = AuditLogType;
    public WindowSize: typeof WindowSize = WindowSize;
    public currentWindowSize$: Observable<WindowSize> = this._windowService.windowSize;
    public loadingResults: Subject<boolean>;

    constructor(
        private _windowService: WindowSizeService,
        private _userService: UserService,
        private _installService: InstallService
    ) {
        this.tableParamsFormGroup.get('startDate').disable();
        this.tableParamsFormGroup.get('endDate').disable();
    }

    ngOnInit(): void {
        this._currentTableParams = {
            page: 0,
            limit: 10,
            sort: '',
            sortOrder: ''
        };

        this.loadingResults = new Subject<boolean>();
        this.loadingResults.next(false);

        // A sort of hacky fix, but the lifecycle functions (like ngOnInit) aren't giving me a useful timeframe to call this.
        // This will make sure its called after everything's loaded, but still soon enough that it feels instant.
        setTimeout(() => {
            this.updatePageQueryParams();
        }, 0);

        this.tableParamsFormGroup.valueChanges.subscribe({
            next: (): void => {
                this.updatePageQueryParams();
            }
        });
    }

    // private getFormattedDate(date: Date): string {
    //     const month = date.getMonth() + 1;
    //     const day = date.getDate();
    //     const year = date.getFullYear();
    //     return month + '/' + day + '/' + year;
    // }

    public updatePageQueryParams(): void {
        const tableConfig: any = this.tableParamsFormGroup.getRawValue();
        const start: Date = new Date(tableConfig?.startDate);
        const end: Date = new Date(tableConfig?.endDate);
        end.setDate(end.getDate() + 1);
        this.tableParamsChanged.emit({
            startDate: start.toISOString(),
            endDate: end.toISOString(),
            includeAncestors: tableConfig?.includeAncestors
        });
    }

    public export(): void {
        this.exportCsv.emit({
            sort: this._currentTableParams.sort,
            sortOrder: this._currentTableParams.sortOrder
        });
    }

    /**
     * Returns promise representing a request to the api to retrieve the full name
     * Note that your IDE may call the Observable.create function deprecated, but no
     * online documentation suggests this is the case. I think it is a bug.
     * @param {AuditLogItem} audit  audit log of concern
     * @returns {Promise<string>}  a promise to return the full name of the user
     */
    public requesterName(audit: IAuditLogItem & IWithUserFullName): Observable<string> {
        if (!!audit.userFullName) {
            return Observable.create(observer => {
                observer.next(audit.userFullName);
                observer.complete();
            });
        } else {
            switch (audit.requester.type) {
                case AuditLogRequesterType.User:
                    return this._userService.getName(audit.requester.key.toString()).pipe(
                        take(1),
                        map((v: User) => v.firstName + ' ' + v.lastName + ' (User)'),
                        tap((v: string): void => {
                            audit.userFullName = v;
                        }));
                case AuditLogRequesterType.Install:
                    return this._installService.getInstall(audit.requester.key.toString()).pipe(
                        take(1),
                        map((v: Install) => v.machine),
                        tap((v: string): void => {
                            audit.userFullName = v  + ' (Install)';
                        }));
                default:
                    return Observable.create(observer => {
                        observer.next('--');
                        observer.complete();
                    });
            }
        }
    }

    public isArray<T>(obj: any): obj is Array<T> {
        return Array.isArray(obj);
    }
}
