import { Log } from '@libs/iso/core/models/rts/Log';
import { TaskTypes, TaskStatusTypes } from '@libs/iso/core/enums';
import * as moment from 'moment';

export interface ITask {
    type: TaskTypes;
    status?: ITaskStatus;
    payload?: any;
    logs?: Array<Log>;
}

export interface ITaskStatus {
    type: TaskStatusTypes;
    payload?: any;
}

export class TaskStatus {
    public type: TaskStatusTypes;
    public payload: any;

    constructor(params?: Partial<ITaskStatus>) {
        if (!!params) {
            this.type = params.type || TaskStatusTypes.NotStarted;
            this.payload = params.payload || this.payload;
        }
    }
}

export class Task {
    public _id?: string;
    public type: TaskTypes;
    public status?: ITaskStatus;
    public payload?: any;
    public logs?: Array<Log>;

    constructor(params: Partial<ITask>) {
        this._id = moment()
            .unix()
            .toString();
        if (!!params) {
            this.type = params.type;
            this.status =
                params.status ||
                new TaskStatus({
                    type: TaskStatusTypes.NotStarted
                });
            this.payload = params.payload || this.payload;
            // this.logs = [];
        }
    }
}
