// import { ItemList } from '@app/models';
import { PaginatedList, CartListItem, ICommissions, TimeFrame } from '@libs/iso/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OrderService } from '@app/services';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class UserCommissionsListResolver
    implements Resolve<PaginatedList<CartListItem> & ICommissions> {
    constructor(private _orderService: OrderService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot
    ): Observable<PaginatedList<CartListItem> & ICommissions> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        const initQParams = route.queryParams;
        const defaultLimit = 10;
        const params = [
            coerceNumberProperty(initQParams.page) || 1,
            coerceNumberProperty(initQParams.limit) || defaultLimit,
            initQParams.sort,
            coerceNumberProperty(initQParams.sortOrder) as 1 | -1,
            initQParams.searchTerm,
            initQParams.timeframe || TimeFrame.CurrentPayPeriod
        ];

        if (
            initQParams.timeframe === TimeFrame.Custom &&
            initQParams.startDate &&
            initQParams.endDate
        ) {
            params.push(initQParams.startDate);
            params.push(initQParams.endDate);
        }

        console.log('resolve admin commissions');

        return this._orderService.getUserComissions(...params).pipe(
            catchError(err => {
                console.error(err);
                return of(new PaginatedList<CartListItem>());
            })
        );
    }
}
