import { InvoiceTableParams } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { InvoiceService } from '@app/services';
import { catchError, take } from 'rxjs/operators';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { PaginatedList, InvoiceLineItem } from '@libs/iso/core';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Injectable()
export class InvoiceListResolver implements Resolve<PaginatedList<InvoiceLineItem>> {
    constructor(private _invoiceService: InvoiceService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<PaginatedList<InvoiceLineItem>> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let entityId: string = '';
        this._store
            .pipe(
                select(fromEntity.entityId),
                take(1)
            )
            .subscribe(id => (entityId = id));
        const initQParams = route.queryParams;
        const defaultLimit = 10;
        const params: InvoiceTableParams = {
            searchTerm: initQParams.searchTerm,
            invoiceStatus: initQParams.invoiceStatus,
            sort: initQParams.sort,
            sortOrder: coerceNumberProperty(initQParams.sortOrder) as 1 | -1,
            page: coerceNumberProperty(initQParams.page) || 1,
            limit: coerceNumberProperty(initQParams.limit) || defaultLimit,
            includeChildren: coerceBooleanProperty(initQParams.includeChildren) || false
        };
        return this._invoiceService
            .getInvoiceList(
                params.searchTerm,
                params.invoiceStatus,
                params.page,
                params.limit,
                params.sort,
                params.sortOrder as number,
                params.includeChildren,
                entityId
            )
            .pipe(
                catchError(error => {
                    console.error('error', error);
                    return of(new PaginatedList<InvoiceLineItem>());
                })
            );
    }
}
