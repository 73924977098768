// Spencer 6/10/2020 - Having a bunch of strings denoting collection names
// is bound to cause trouble-- this is merely a way to tie references to collections
// to a single place.
// Normal format for enums is not followed because the string needs to be accruate
// to the name of the collection and this enum does NOT need to be in the database
// anyways.
enum CollectionName {
    auditLog = 'auditLog',
    billing = 'billing',
    cart = 'cart',
    device = 'device',
    deviceBillingPeriod = 'deviceBillingPeriod',
    devicePagesLostStats = 'devicePagesLostStats',
    deviceStats = 'deviceStats',
    deviceSupply = 'deviceSupply',
    entity = 'entity',
    entityItem = 'entityItem',
    entityPagesLostStats = 'entityPagesLostStats',
    entityReportingDevices = 'entityReportingDevices',
    entityStats = 'entityStats',
    event = 'event',
    hook = 'hook',
    install = 'install',
    item = 'item',
    job = 'job',
    meterRead = 'meterRead',
    permission = 'permission',
    report = 'report',
    reportScheduled = 'reportScheduled',
    role = 'role',
    settingChange = 'settingChange',
    settingChangeGroup = 'settingChangeGroup',
    user = 'user'
    // More can be added as needed.
}

const nameTable: { [key in CollectionName]: Array<string> } = {
    auditLog: ['audit-log', 'Audit-log', 'audit-logs', 'Audit-logs'],
    billing: ['bill', 'Bill', 'billing', 'Billing'],
    cart: ['cart', 'Cart', 'carts', 'Carts'],
    device: ['device', 'Device', 'devices', 'Devices'],
    deviceBillingPeriod: [
        'device-billing-period',
        'Device-billing-period',
        'device-billing-periods',
        'Device-billing-periods'
    ],
    devicePagesLostStats: [
        'device-pages-lost-stats',
        'Device-pages-lost-stats',
        'device-pages-lost-stats',
        'Device-pages-lost-stats'
    ],
    deviceStats: ['device-stats', 'Device-stats', 'device-stats', 'Device-stats'],
    deviceSupply: ['device-supply', 'Device-supply', 'device-supplies', 'Device-supplies'],
    entity: ['entity', 'Entity', 'entities', 'Entities'],
    entityItem: ['entity-item', 'Entity-item', 'entity-items', 'Entity-items'],
    entityPagesLostStats: [
        'entity-pages-lost-stats',
        'Entity-pages-lost-stats',
        'entity-pages-lost-stats',
        'Entity-pages-lost-stats'
    ],
    entityReportingDevices: [
        'entity-reporting-devices',
        'Entity-reporting-devices',
        'entity-reporting-devices',
        'Entity-reporting-devices'
    ],
    entityStats: ['entity-stats', 'Entity-stats', 'entity-stats', 'Entity-stats'],
    event: ['event', 'Event', 'events', 'Events'],
    hook: ['hook', 'Hook', 'hooks', 'Hooks'],
    install: ['install', 'Install', 'installs', 'Installs'],
    item: ['item', 'Item', 'items', 'Items'],
    job: ['job', 'Job', 'jobs', 'Jobs'],
    meterRead: ['meter-read', 'Meter-read', 'meter-reads', 'Meter-reads'],
    permission: ['permission', 'Permission', 'permissions', 'Permissions'],
    role: ['role', 'Role', 'roles', 'Roles'],
    report: ['report', 'Report', 'reports', 'Reports'],
    reportScheduled: [
        'reportScheduled',
        'Report Scheduled',
        'reportsScheduled',
        'Reports Scheduled'
    ],
    settingChange: ['setting-change', 'Setting-change', 'setting-changes', 'Setting-changes'],
    settingChangeGroup: [
        'setting-change-group',
        'Setting-change-group',
        'setting-change-groups',
        'Setting-change-groups'
    ],
    user: ['user', 'User', 'users', 'Users']
};

namespace CollectionName {
    export function toString(
        value: CollectionName,
        plural: boolean = false,
        capitalized: boolean = false
    ): string {
        const index = plural && capitalized ? 3 : plural ? 2 : capitalized ? 1 : 0;
        return nameTable[value][index];
    }

    // If we actually do use this it will need to be in a seperate file to prevent dependency issues.
    // export function toClass(value: CollectionName): any {
    //     switch(value) {
    //         case 'auditLog': return AuditLogItem
    //         case 'billing': return undefined;
    //         case 'cart': return Cart
    //         case 'device': return Device
    //         case 'devicePagesLostStats': return DevicePagesLostStats
    //         case 'deviceStats': return DeviceStats
    //         case 'entity': return Entity
    //         case 'entityItem': return EntityItem
    //         case 'entityPagesLostStats': return EntityPagesLostStats
    //         case 'entityReportingDevices': return undefined;
    //         case 'entityStats': return EntityStats
    //         case 'event': return Event
    //         case 'hook': return Hook
    //         case 'install': return Install
    //         case 'item': return Item
    //         case 'job': return Job
    //         case 'meterRead': return MeterRead
    //         case 'permission': return Permission
    //         case 'role': return Role
    //         case 'settingChange': return SettingChange
    //         case 'settingChangeGroup': return SettingChangeGroup
    //         case 'user': return User
    //     }
    // }
}

export { CollectionName };
