import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ptkr-radio-meter-selector-modal',
    templateUrl: './radio-meter-selector-modal.component.html',
    styleUrls: ['./radio-meter-selector-modal.component.scss']
})
export class RadioMeterSelectorModalComponent {
    public title: string = 'Pick One';
    public description: string = '';
    public options: Array<{ name: string, meter: string, format: string }> = [];

    public selection: { name: string, meter: string, format: string } = undefined;

    constructor(
        public dialogRef: MatDialogRef<RadioMeterSelectorModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            description: string;
            options: Array<{ name: string; meter: string, format: string }>;
        }
    ) {
        this.title = data.title;
        this.description = data.description;
        this.options = data.options;
    }

    public onCancel(): void {
        this.dialogRef.close(undefined);
    }

    public onConfirm(): void {
        this.dialogRef.close({ format: this.selection.format, meter: this.selection.meter });
    }
}
