import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    OnDestroy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { ColumnType, PagesByMonthReportType, PagesThisMonthData } from '@libs/iso/core';
import { Subject } from 'rxjs';
import { Entity } from '@app/models';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ptkr-pages-this-month',
    templateUrl: './pages-this-month.component.html',
    styleUrls: ['./pages-this-month.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagesThisMonthComponent implements OnInit, OnDestroy {
    @Input()
    public type: PagesByMonthReportType;
    @Input()
    public entity: Entity;
    // private _data: PagesThisMonthData[] = [];
    private _data: { fullTotal: number; docs: PagesThisMonthData[] } = {
        fullTotal: 0,
        docs: []
    };
    @Input()
    public set data(value: { fullTotal: number; docs: PagesThisMonthData[] }) {
        if (value && Array.isArray(value.docs)) {
            this._data = value;
            this.totalPages = this._data.fullTotal;
            // this.totalPages = value.reduce((acc, next) => acc + next.total, 0);
            if (this._data.docs.length > 0) {
                const min = this._data.docs[0];
                const max = this._data.docs[this._data.docs.length - 1];
                const maxVal = +max.blackWhite + +max.color;
                const minVal = +min.blackWhite + +min.color;
                this.chartOptions.vAxis.viewWindow.max = maxVal * 1.2;
                this.chartOptions.vAxis.viewWindow.min = minVal * 8 >= maxVal ? minVal * 0.5 : 0;
            }
            this.chartData = [];
            let data = this._data.docs;
            if (data.length > this.limitControl.value) {
                data = data.slice(0, 25);
            }
            for (const d of data) {
                const extras: { name: string, value: string }[] = [];
                if (!!d.extraData) {
                    for (const extra in d.extraData) {
                        extras.push({ name: extra, value: d.extraData[extra] });
                    }
                }
                const ngxData = {
                    id: d._id,
                    name: d.name,
                    series: [
                        {
                            name: 'Black and White',
                            value: d.blackWhite,
                            extra: extras
                        },
                        {
                            name: 'Color',
                            value: d.color,
                            extra: extras
                        },
                    ],
                };

                this.chartData.push(ngxData);
            }
        }
    }
    public get data(): { fullTotal: number; docs: PagesThisMonthData[] } {
        return this._data;
    }
    @Input()
    public currentLimit: number = 25;
    // tslint:disable-next-line:no-magic-numbers
    @Input()
    public limitOptions: number[] = [10, 25];

    // prettier-ignore
    @Output()
    public drilldown: EventEmitter<{ id: string, name: string }> =
        new EventEmitter<{ id: string, name: string }>();
    @Output()
    public limit: EventEmitter<number> = new EventEmitter<number>();

    private _ngUnsub: Subject<void> = new Subject<void>();

    public PagesByMonthReportType: typeof PagesByMonthReportType = PagesByMonthReportType;
    public limitControl: FormControl;
    public totalPages: number = 0;
    public chartType: string = 'ColumnChart';
    public chartData: any[] = [];
    public chartColumns: { [key: string]: ColumnType } = {
        Entity: 'string',
        Black: 'number',
        Color: 'number',
        Id: 'string'
    };
    // tslint:disable-next-line:no-magic-numbers
    public chartVisibleColumns: number[] = [0, 1, 2];
    public chartOptions: any = {
        height: 300,
        // chartArea: { width: '75%', height: '75%' },
        hAxis: {
            minValue: 0,
            slantedText: true,
            direction: -1,
            slantedTextAngle: 90
        },
        vAxis: {
            viewWindowMode: 'explicit',
            viewWindow: {
                max: 0,
                min: 0
            }
        },
        isStacked: true,
        enableInteractivity: true,
        tooltip: { isHtml: true },
        colors: ['#BBB', '#1AB394']
    };
    public chartEvents: { [key: string]: (chart) => any } = {
        select: (chart): any => {
            if (chart.getSelection()[0]) {
                const selectedItem: any = this.chartData[chart.getSelection()[0].row];
                const idIndex = 3;
                this.drilldown.emit(selectedItem[idIndex]);
            }
        }
    };

    constructor() {}

    ngOnInit(): void {
        this.limitControl = new FormControl(this.currentLimit);
        this.limitControl.valueChanges.pipe(takeUntil(this._ngUnsub)).subscribe(limit => {
            this.limit.emit(limit);
        });
    }

    ngOnDestroy(): void {
        this._ngUnsub.next();
        this._ngUnsub.complete();
        this._ngUnsub = undefined;
    }

    public customColors: any = (value): string => {
        if (value === 'Color') {
            return '#1ab292';
        } else {
            return '#d3d3d3';
        }
        // tslint:disable-next-line:semicolon
    };

    public itemSelected(event): void {
        const data = this.chartData.find(c => c.name === event.series);
        if (!!data) {
            this.drilldown.emit({
                id: data.id,
                name: data.name
            });
        }
    }
}
