enum ScheduleMonthType {
    Day = 'day',
    Relative = 'relative'
}

namespace ScheduleMonthType {
    export function toString(type: ScheduleMonthType): string {
        switch (type) {
            case ScheduleMonthType.Day:
                return 'Day';
            case ScheduleMonthType.Relative:
                return 'Relative';
            default:
                return '';
        }
    }

    export const asArray: ScheduleMonthType[] = [ScheduleMonthType.Day, ScheduleMonthType.Relative];
}

export { ScheduleMonthType };
