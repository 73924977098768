import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatMenu } from '@angular/material/menu';
import {
    DateRange,
} from '@angular/material/datepicker';
import { Moment } from 'moment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment/moment';

export interface ActionBarConfiguration {
    showRefresh?: boolean;
    showFilter?: boolean;
    showIncludeChildren?: boolean;
    showFilterOptionsIcon?: boolean;
    showOptionsIcon?: boolean;
    initialFilter?: string;
    initialIncludeChildren?: boolean;
    showSearchButton?: boolean;
    filterSuffix?: string;

    showToggle?: boolean;
    toggleLabel?: string;

    showLeftButton?: boolean;
    leftButtonLabel?: string;

    showDateRangePicker?: boolean;
    dateRangePickerLabel?: string;
}

@Component({
    selector: 'ptkr-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit {
    @Input() public config: ActionBarConfiguration;
    @Input() public setFilter: EventEmitter<string> = new EventEmitter<string>();

    public dateForm: FormGroup = new FormGroup({
        startDate: new FormControl(),
        endDate: new FormControl()
    });

    // The value of the badge that should be shown in the filter options icon. If the value is null or
    // 0 the badge is hidden completely.
    @Input() public filterOptionsIconBadge?: any;

    // A reference to the mat menu that should be triggered when the filter options icon is clicked,
    // if this is null, then the filter options icon will be hidden even if the configuration setting
    // for the icon is enabled.
    @Input() public filterOptionsMenu?: MatMenu;

    // A reference to the mat menu that should be triggered when the options menu icon (the gear) is
    // clicked. If this is null, then the options icon will be hidden even if the configuration setting
    // for the icon is enabled.
    @Input() public optionsMenu?: MatMenu;

    @Output() public refresh: EventEmitter<any> = new EventEmitter<any>();

    // This subject provides an observable stream into the filter;
    private _filterSubject$: Subject<string> = new Subject<string>();

    // Allows parent components to have access to a debounced stream of filter values;
    @Output() public filterChanged: EventEmitter<string> = new EventEmitter<string>();

    // Allows parent components to have access to boolean value representing the include children;
    @Output() public includeChildrenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Allows parent components to have access to boolean value representing the abstract toggle;
    @Output() public toggleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Allows parent components to know when the left button is clicked
    @Output() public leftButtonClicked: EventEmitter<void> = new EventEmitter<void>();

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.setFilter.subscribe(str => {
            this.config.initialFilter = str;
            this.filterChanged.emit(str);
        });
        this._filterSubject$
            .pipe(debounceTime(400))
            .subscribe(data => this.filterChanged.emit(data));
    }

    // Returns the filter placeholder based on the configuration's filter suffix if it's provided
    public filterPlaceholder(): string {
        const str = this.config.filterSuffix;
        if (str && str.length > 0) {
            return $localize`Filter ${this.config.filterSuffix}`;
        }
        return $localize`Filter`;
    }

    // Called by the filter input to provide the input's current value state to the filter subject
    public filterValueChanged(event: any): void {
        const value = (event.target as any).value;
        if (event.key === 'Enter' || event.keyCode === 13) {
            // skip the de-bouncer if they do an enter key
            this.filterChanged.emit(value);
            return;
        }
        if (!this.config.showSearchButton) {
            this._filterSubject$.next(value);
        }
    }

    public search(): void {
        this.filterChanged.emit(this.config.initialFilter);
    }

    public includeChildrenToggleChanged(event: MatSlideToggleChange): void {
        this.includeChildrenChanged.emit(event.checked);
    }

    /**
     * Allows a caller to subscribe to changes made to the date range picker
     * @returns {Observable<DateRange<Moment>>} - The new date range
     */
    public dateRangeChanges(): Observable<DateRange<Moment>> {
        return this.dateForm
            .get('endDate')
            .valueChanges.pipe(
                // Only send an update if the end date is not null. When using the date range picker
                // it makes you select the start date first. Once the start date is selected, the end
                // date is null until you select the end date. Once we have an end date, we know we
                // have both a start and an end date.
                filter(v => v != null),
                map(
                    () =>
                        new DateRange(
                            moment(this.dateForm.get('startDate').value),
                            moment(this.dateForm.get('endDate').value)
                        )
                )
            );
    }

    /**
     * Allows callers to set the date range picker in this component.
     * @param {Moment} start - The starting date
     * @param {Moment} end - The ending date
     * @returns {void}
     */
    public setDateRange(start: Moment, end: Moment): void {
        this.dateForm.get('startDate').setValue(start);
        this.dateForm.get('endDate').setValue(end);
    }
}
