import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SNMPDumpFile, SNMPDumpFileWithMetadata } from '@libs/iso/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActionBarConfiguration } from '@app/shared/components/action-bar/action-bar.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'ptkr-snmpdump-file-list',
    templateUrl: './snmpdump-file-list.component.html',
    styleUrls: ['./snmpdump-file-list.component.scss']
})
export class SNMPDumpFileListComponent implements OnInit {
    @Input() public files$: Observable<
        Array<SNMPDumpFile | SNMPDumpFileWithMetadata>
    > = new BehaviorSubject([]);

    @Input() public displayedColumns: Array<string> = [
        'entityName',
        'installMachineName',
        'deviceKey',
        // 'itemMakeModel',
        'createdDate',
        'size',
        'actions'
    ];

    // Used to refresh the files$ observable
    @Output() public refresh: EventEmitter<void> = new EventEmitter<void>();

    // Deletes the SNMP dump with the provided name
    @Output() public delete: EventEmitter<string> = new EventEmitter<string>();

    public actionBar: ActionBarConfiguration = {
        showRefresh: true
    };

    public dataSource: MatTableDataSource<SNMPDumpFile | SNMPDumpFileWithMetadata>;

    constructor(private _cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.files$.subscribe(res => {
            if (res) {
                this.dataSource = new MatTableDataSource<SNMPDumpFile | SNMPDumpFileWithMetadata>(
                    res
                );
                this._cd.detectChanges();
            }
        });
    }

    public formatFileSize(v: number): string {
        if (v >= 1000 && v < 10000) {
            return `${v / 1000} KB`;
        }
        if (v >= 10000 && v < 100000) {
            return `${v / 10000} MB`;
        }
        if (v >= 100000 && v < 1000000) {
            return `${v / 10000} GB`;
        }
    }

    public download(url: string): void {
        window.open(url, '_blank');
    }
}
