enum BillingPlan {
    Aarons,
    ABRSpecial,
    ABSSpecial,
    Apex,
    APSC,
    CPT,
    CPTMonthly,
    CPTMonthly2,
    CPTYearly,
    EOJohnson,
    GlobalImaging,
    LMIMonthly,
    LMIMonthly2,
    LMIMonthly5,
    Loyalty,
    P100,
    PHPA,
    Platinum,
    PPG,
    Preferred,
    PTMonthly,
    PTMonthly150,
    PTMonthly160,
    PTMonthly40,
    PTMonthly40b,
    PTMonthly40c,
    PTMonthly45,
    PTMonthly50,
    PTMonthly5040,
    PTMonthly6,
    PTMonthly80,
    Rodin,
    SmartOffice,
    Think,
    ToshibaKY,
    USBank,
    USBankSpecial,
    USBankSpecial3,
    USBank2,
    WideFormat,
    Yearly7
}

namespace BillingPlan {
    export function toString(type: BillingPlan): string {
        switch (type) {
            case BillingPlan.Aarons:
                return 'Aarons';
            case BillingPlan.ABRSpecial:
                return 'ABR Special';
            case BillingPlan.ABSSpecial:
                return 'ABS Special';
            case BillingPlan.Apex:
                return 'Apex';
            case BillingPlan.APSC:
                return 'APSC';
            case BillingPlan.CPT:
                return 'CPT';
            case BillingPlan.CPTMonthly:
                return 'CPT Monthly';
            case BillingPlan.CPTMonthly2:
                return 'CPT Monthly2';
            case BillingPlan.CPTYearly:
                return 'CPT Yearly';
            case BillingPlan.EOJohnson:
                return 'EOJohnson';
            case BillingPlan.GlobalImaging:
                return 'Global Imaging';
            case BillingPlan.LMIMonthly:
                return 'LMI Monthly';
            case BillingPlan.LMIMonthly2:
                return 'LMI Monthly2';
            case BillingPlan.LMIMonthly5:
                return 'LMI Monthly-5';
            case BillingPlan.Loyalty:
                return 'Loyalty';
            case BillingPlan.P100:
                return 'P100';
            case BillingPlan.PHPA:
                return 'PHPA';
            case BillingPlan.Platinum:
                return 'Platinum';
            case BillingPlan.PPG:
                return 'PPG';
            case BillingPlan.Preferred:
                return 'Preferred';
            case BillingPlan.PTMonthly:
                return 'PT Monthly';
            case BillingPlan.PTMonthly150:
                return 'PT Monthly-150';
            case BillingPlan.PTMonthly160:
                return 'PT Monthly-160';
            case BillingPlan.PTMonthly40:
                return 'PT Monthly-40';
            case BillingPlan.PTMonthly40b:
                return 'PT Monthly-40b';
            case BillingPlan.PTMonthly40c:
                return 'PT Monthly-40c';
            case BillingPlan.PTMonthly45:
                return 'PT Monthly-45';
            case BillingPlan.PTMonthly50:
                return 'PT Monthly-50';
            case BillingPlan.PTMonthly5040:
                return 'PT Monthly-5040';
            case BillingPlan.PTMonthly6:
                return 'PT Monthly6';
            case BillingPlan.PTMonthly80:
                return 'PT Monthly-80';
            case BillingPlan.Rodin:
                return 'Rodin';
            case BillingPlan.SmartOffice:
                return 'Smart Office';
            case BillingPlan.Think:
                return 'Think';
            case BillingPlan.ToshibaKY:
                return 'Toshiba KY';
            case BillingPlan.USBank:
                return 'USBank';
            case BillingPlan.USBankSpecial:
                return 'USBank Special';
            case BillingPlan.USBankSpecial3:
                return 'USBank Special3';
            case BillingPlan.USBank2:
                return 'USBank 2';
            case BillingPlan.WideFormat:
                return 'WideFormat';
            case BillingPlan.Yearly7:
                return 'Yearly7';
            default:
                return '';
        }
    }

    export const asArray: BillingPlan[] = [
        BillingPlan.Aarons,
        BillingPlan.ABRSpecial,
        BillingPlan.ABSSpecial,
        BillingPlan.Apex,
        BillingPlan.APSC,
        BillingPlan.CPT,
        BillingPlan.CPTMonthly,
        BillingPlan.CPTMonthly2,
        BillingPlan.CPTYearly,
        BillingPlan.EOJohnson,
        BillingPlan.GlobalImaging,
        BillingPlan.LMIMonthly,
        BillingPlan.LMIMonthly2,
        BillingPlan.LMIMonthly5,
        BillingPlan.Loyalty,
        BillingPlan.P100,
        BillingPlan.PHPA,
        BillingPlan.Platinum,
        BillingPlan.PPG,
        BillingPlan.Preferred,
        BillingPlan.PTMonthly,
        BillingPlan.PTMonthly150,
        BillingPlan.PTMonthly160,
        BillingPlan.PTMonthly40,
        BillingPlan.PTMonthly40b,
        BillingPlan.PTMonthly40c,
        BillingPlan.PTMonthly45,
        BillingPlan.PTMonthly50,
        BillingPlan.PTMonthly5040,
        BillingPlan.PTMonthly6,
        BillingPlan.PTMonthly80,
        BillingPlan.Rodin,
        BillingPlan.SmartOffice,
        BillingPlan.Think,
        BillingPlan.ToshibaKY,
        BillingPlan.USBank,
        BillingPlan.USBankSpecial,
        BillingPlan.USBankSpecial3,
        BillingPlan.USBank2,
        BillingPlan.WideFormat,
        BillingPlan.Yearly7
    ];
}

export { BillingPlan };
