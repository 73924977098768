import { BaseModel, IBaseModel } from '@libs/iso/core/models/BaseModel';
import { InstallNetworkTopologyNetworkKind } from '@libs/iso/core/enums/InstallNetworkTopologyNetworkKind';
import { getIPRange } from '../../helpers/ip';

interface IInstallNetworkTopologyNetwork {
    network: string;
    kind: InstallNetworkTopologyNetworkKind;
}

/**
 * InstallNetworkTopologyEndpoint store their results in a single byte where each bit represents the
 * state of a flag. In the following enum, each flag is assigned a number corresponding to the value
 * position in binary of the flag. For example Ping is 1 which refers to 00000001. SNMP would be
 * 00000010 and so on. We can then use bit masking to determine which flags are set. The idea here
 * is there could potentially be tons of flags, but they only take up 1-2 bytes each and circumvent
 * storing additional key names. MongoDB also has the added benefit of querying on bit fields.
 */
enum InstallNetworkTopologyEndpointFlags {
    Ping = 1,
    SnmpConnected = 2,
    Webserver = 4,
    Printer = 8,
}

interface IInstallNetworkTopologyEndpoint {
    addr: string;

    // The state of a network endpoint is stored as 1-2 bytes in this field.
    readonly flags: number;
    names: string[];

    // On initialization, these properties are set from the flags property so that the consumer
    // of the API doesn't have to worry about dealing with any of the nitty-gritties.
    ping: boolean;
    snmpConnected: boolean;
    webserver: boolean;
}

interface IInstallNetworkTopology extends IBaseModel {
    networks: IInstallNetworkTopologyNetwork[];
    endpoints: IInstallNetworkTopologyEndpoint[];
    exclude: string[];
    scanId: string;
}

export class InstallNetworkTopologyEndpoint implements IInstallNetworkTopologyEndpoint {
    public addr: string;
    public flags: number = 0;
    public names: string[] = [];

    public ping: boolean;
    public snmpConnected: boolean;
    public snmpEnabled: boolean;
    public webserver: boolean;
    public printer: boolean;

    constructor(props?: Partial<IInstallNetworkTopologyEndpoint>) {
        if (props != null) {
            this.addr = props.addr ?? this.addr;
            this.flags = props.flags ?? this.flags;
            this.names = props.names ?? this.names;

            // Parse the other properties from the flags bit.
            this.ping = (this.flags & InstallNetworkTopologyEndpointFlags.Ping) !== 0;
            this.snmpConnected = (this.flags & InstallNetworkTopologyEndpointFlags.SnmpConnected) !== 0;
            this.webserver = (this.flags & InstallNetworkTopologyEndpointFlags.Webserver) !== 0;
            this.printer = (this.flags & InstallNetworkTopologyEndpointFlags.Printer) !== 0;
        }
    }
}

export class InstallNetworkTopology extends BaseModel implements IInstallNetworkTopology {
    public endpoints: InstallNetworkTopologyEndpoint[] = [];
    public networks: IInstallNetworkTopologyNetwork[] = [];
    public exclude: string[] = [];
    public scanId: string;

    constructor(props?: Partial<IInstallNetworkTopology>) {
        super(props);
        if (props != null) {
            this.endpoints =
                props.endpoints != null
                    ? props.endpoints.map(e => new InstallNetworkTopologyEndpoint(e))
                    : this.endpoints;
            this.networks = props.networks ?? this.networks;
            this.exclude = props.exclude ?? this.exclude;
            this.scanId = props.scanId ?? this.scanId;
        }
    }

    /**
     * Returns a list of network endpoints that were scanned by the DCA. We don't actually store all
     * the endpoints that we didn't get a response from (for MongoDB storage reasons), and instead
     * we store the networks that should have been scanned.
     *
     * To extrapolate the endpoints, we calculate all the IP addresses that could have been scanned
     * based on the networks (for example if we have a CIDR network of 10.0.0.0/24, then we add 255
     * IP addresses (10.0.0.1-10.0.0.255) to the endpoints array which already contains the endpoints
     * that did contain scan results.
     *
     * @returns {InstallNetworkTopologyEndpoint[]} - An array of network endpoints that were scanned
     * and the results for each one.
     */
    public endpointsFromNetworks(): InstallNetworkTopologyEndpoint[] {
        const endpoints = this.endpoints.slice();
        const endpointLookup = new Set(this.endpoints.map(e => e.addr));
        const excludeLookup = new Set(this.exclude);
        for (const network of this.networks) {
            let addresses = [];
            switch (network.kind) {
                case InstallNetworkTopologyNetworkKind.Wildcard:
                    if (!network.network.includes('*')) {
                        throw new Error(
                            `invalid kind: network (${network.network}) does not contain a wildcard, but is a wildcard kind`
                        );
                    }
                    const parts = network.network.split('.');
                    if (parts.length !== 4) {
                        throw new Error(
                            `invalid format: network (${network.network}) should contain four octets`
                        );
                    }
                    if (parts[3] !== '*') {
                        throw new Error(
                            `invalid format: network (${network.network}) should have wildcard in last octet`
                        );
                    }
                    const cidr = `${parts.slice(0, 3).join('.')}.0/24`;
                    addresses = getIPRange(cidr);
                    break;
                case InstallNetworkTopologyNetworkKind.CIDR:
                    addresses = getIPRange(network.network);
                    break;
                case InstallNetworkTopologyNetworkKind.Hyphen:
                    // Check if the range is formatted like 10.0.0.1-100 and convert it to 10.0.0.1-10.0.0.100
                    let range = network.network;
                    const sides = range.split('-');
                    if (sides.length !== 2) {
                        throw new Error(`invalid format: expected network (${range}) to have hyphenated range`);
                    }
                    if (sides[1].split('.').length === 1) {
                        range = sides[0] + '-' + sides[0].split('.').slice(0, 3).join('.') + '.' + sides[1];
                    }
                    addresses = getIPRange(range);
                    break;
                case InstallNetworkTopologyNetworkKind.Single:
                    addresses.push(network.network);
                    break;
                default:
            }
            addresses
                .filter(a => !endpointLookup.has(a) && !excludeLookup.has(a))
                .map(a => new InstallNetworkTopologyEndpoint({ addr: a }))
                .forEach(e => endpoints.push(e));
        }
        return endpoints;
    }
}
