enum PagesLostReportType {
    HighLow = 'highLow',
    EarlyReplacement = 'earlyReplacement'
}

namespace PagesLostReportType {
    export function toString(type: PagesLostReportType): string {
        switch (type) {
            case PagesLostReportType.HighLow:
                return 'High/Low';
            case PagesLostReportType.EarlyReplacement:
                return 'Early Replacement';
            default:
                return '';
        }
    }
}

export { PagesLostReportType };
