import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
    selector: 'ptkr-edit-label-modal',
    templateUrl: './edit-label-modal.component.html',
    styleUrls: ['./edit-label-modal.component.scss']
})
export class EditLabelModalComponent implements OnInit {
    public formGroup: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<EditLabelModalComponent>,
        private _fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: {
            title: string;
            name: string;
            value: string;
        }
    ) {}

    ngOnInit(): void {
        this.formGroup = this._fb.group({
            name: [this.dialogData.name],
            value: [this.dialogData.value]
        });
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public confirm(): void {
        this.dialogRef.close({
            name: this.formGroup.get('name').value,
            value: this.formGroup.get('value').value
        });
    }
}
