import { createSelector } from '@ngrx/store';
import { selectEntity } from '../store/global.store';
import { EntityStore } from '../store/entity.store';

export const fromEntity = {
    currentEntity: createSelector(selectEntity, (store: EntityStore) => store),
    currentEntityStatsList: createSelector(
        selectEntity,
        (store: EntityStore) => store.entityStatsList
    ),
    entityId: createSelector(selectEntity, (store: EntityStore) => store._id),
    salesRep: createSelector(selectEntity, (store: EntityStore) => store.salesRep)
};
