import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChipDialogComponent } from './chip-dialog/chip-dialog.component';

export interface Chip {
    key: string;
    value: string;
}

interface StringMap {
    [key: string]: string;
}

/**
 * Regular expression for URL validation created by @dperini.
 * https://gist.github.com/dperini/729294
 */
const URL_REGEXP = new RegExp(
    '^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!' +
        '(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1' +
        ',3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])' +
        '){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*' +
        '[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]' +
        '+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$',
    'i'
);

const MAX_CHIP_VALUE_LENGTH = 63;

@Component({
    selector: 'ptkr-chips',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {
    @Input()
    public get map(): StringMap | string[] {
        return this._map;
    }
    public set map(value: StringMap | string[]) {
        this._map = value;
        if (Array.isArray(this.map)) {
            this.keys = this.map as string[];
        } else {
            this.keys = Object.keys(this.map ?? {});
        }
    }
    private _map: StringMap | string[];
    @Input() public minChipsVisible: number = 2;
    public keys: string[];
    public isShowingAll: boolean = false;

    constructor(private readonly _dialog: MatDialog) {}

    ngOnInit(): void {
        if (!this.map) {
            this.map = [];
            this.keys = [];
        }
    }

    public isVisible(index: number): boolean {
        return this.isShowingAll || index < this.minChipsVisible;
    }

    public isAnythingHidden(): boolean {
        return this.keys.length > this.minChipsVisible;
    }

    public toggleView(event: any): void {
        event.stopPropagation();
        this.isShowingAll = !this.isShowingAll;
    }

    public isTooLong(value: string): boolean {
        return value != null && value.length > MAX_CHIP_VALUE_LENGTH;
    }

    public isHref(value: string): boolean {
        if (typeof value === 'string') {
            return URL_REGEXP.test(value.trim());
        } else {
            return false;
        }
    }

    public openChipDialog(key: string, value: string): void {
        const dialogConfig: MatDialogConfig<Chip> = {
            width: '630px',
            data: {
                key,
                value
            }
        };
        this._dialog.open(ChipDialogComponent, dialogConfig);
    }
}
