import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ptkr-label-editor-modal',
    templateUrl: './label-editor-modal.component.html',
    styleUrls: ['./label-editor-modal.component.scss']
})
export class LabelEditorModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<LabelEditorModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: {
            labels: { [key: string]: string };
        }
    ) {}

    ngOnInit(): void {
        this.dialogRef.updateSize('40%');
    }

    public saveChanges(event: { [key: string]: string }): void {
        this.dialogRef.close(event);
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
