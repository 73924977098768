<mat-form-field floatLabel="never" color="accent" appearance="outline">
    <input
        type="text"
        matInput
        [formControl]="searchControl"
        [placeholder]="placeholder"
        [matAutocomplete]="autoGroup">
    <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="displayWith()">
        <mat-optgroup label="Suggested Printers" [disabled]="!results || results.devices.length < 1">
            <mat-option *ngIf="!results || results.devices.length < 1">
                No Suggested Printers Found
            </mat-option>
            <mat-option *ngFor="let d of results?.devices" [value]="d._id" (click)="selectOption(d)">
                {{ displayFn(d) }}
            </mat-option>
        </mat-optgroup>
        <mat-optgroup label="Suggested Toners" [disabled]="!results || results.components.length < 1">
            <mat-option *ngIf="!results || results.components.length < 1">
                No Suggested Toners Found
            </mat-option>
            <mat-option *ngFor="let c of results?.components" [value]="c._id"  (click)="selectOption(c)">
                {{ displayFn(c) }}
            </mat-option>
        </mat-optgroup>
    </mat-autocomplete>
    <button mat-flat-button matSuffix color="accent">
        <i class="far fa-search"></i>
        Search
    </button>
</mat-form-field>
