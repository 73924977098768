<table
    mat-table
    multiTemplateDataRows
    class="g-table"
    [dataSource]="dataSource"
    #dataTable
>
    <ng-container matColumnDef="selectable">
        <th class="multi-selection-header" mat-header-cell *matHeaderCellDef>
            <mat-checkbox
                *ngIf="allBoxesChecked || someBoxesChecked"
                (click)="$event.stopPropagation()"
                [checked]="allBoxesChecked"
                [indeterminate]="someBoxesChecked"
                (change)="checkAllLineItems($event)"
            ></mat-checkbox>
            <mat-checkbox
                *ngIf="!allBoxesChecked && !someBoxesChecked"
                (click)="$event.stopPropagation()"
                [checked]="allBoxesChecked"
                [indeterminate]="someBoxesChecked"
                (change)="checkAllLineItems($event)"
            ></mat-checkbox>
        </th>
        <td mat-cell class="multi-selection-cell" *matCellDef="let data">
            <span
                class="multi-select"
                [class.visible]="selectedData.has(data._id)"
                (click)="$event.stopPropagation()"
            >
                <mat-checkbox
                    [disabled]="allBoxesChecked"
                    [checked]="allBoxesChecked || selectedData.has(data._id)"
                    (change)="checkLineItem(data)"
                ></mat-checkbox>
            </span>
        </td>
    </ng-container>
    <ng-container *ngFor="let col of columns" [matColumnDef]="col.name">
        <th mat-header-cell *matHeaderCellDef>{{ col.name }}</th>
        <td mat-cell *matCellDef="let data">
            <mat-icon
                *ngIf="col.copiable"
                class="copy-icon"
                (click)="copy(display(col.key, data[col.key]))"
            >content_copy</mat-icon>
            {{ display(col.key, data[col.key]) }}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
