import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { HttpAdapter } from './http.adapter';

@Injectable()
export class HttpService {
    constructor(protected _http: HttpClient) {}

    protected responseInterceptor<T>(
        observableRes: Observable<any>,
        preAdapterFn?: Function,
        classToMap?: new (params) => T,
        postAdapterFn?: Function
    ): Observable<any> {
        // console.log('for testing purposes');
        return observableRes.pipe(
            filter(res => res.type === HttpEventType.Response),
            map(res =>
                preAdapterFn !== null && typeof preAdapterFn !== 'undefined'
                    ? HttpAdapter.baseAdapter(res, preAdapterFn)
                    : res
            ),
            map(res =>
                classToMap !== null && typeof classToMap !== 'undefined'
                    ? HttpAdapter.baseClassMap(res, classToMap)
                    : res
            ),
            map(res =>
                postAdapterFn !== null && typeof postAdapterFn !== 'undefined'
                    ? HttpAdapter.baseAdapter(res, postAdapterFn)
                    : res
            ),
            map(res => (!!res && typeof res.body !== 'undefined' ? res.body : res))
        );
    }
}
