import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf
} from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { InheritanceFormControl } from '@libs/web/forms/models';

@Component({
    selector: 'ptkr-inheritance-reset-button',
    templateUrl: './inheritance-reset-button.component.html',
    styleUrls: [ './inheritance-reset-button.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InheritanceResetButtonComponent implements OnInit, OnDestroy {
    @Input()
    public settingName: string;

    @Input()
    public showDropdown: boolean = false;

    /**
     * Emits signal indicating that we should open the bulk update modal
     * @type {EventEmitter<string>}
     */
    @Output()
    public bulkReset: EventEmitter<string> = new EventEmitter<string>();

    /*
     It may be better to have our custom controls implement ControlValueAccessor than need this
     utility output. Still better than having to manually set up reversion events for
     each control, though.
     */
    @Output()
    public reverted: EventEmitter<void> = new EventEmitter<void>();


    public inheritedString: string = $localize`Inherited`;
    public notInheritedString: string = $localize`Reset`;
    public isInherited: boolean = true;

    private _$ngUnsub: EventEmitter<void> = new EventEmitter<void>();

    private _control: InheritanceFormControl;

    constructor(
        @Optional() @Host() @SkipSelf() private _parent: ControlContainer,
        private _cd: ChangeDetectorRef,
        private _dialog: MatDialog
    ) {
    }

    public ngOnInit(): void {
        const c: AbstractControl = (
            this._parent?.control as FormGroup
        )?.controls[this.settingName] as AbstractControl;
        // Else we just ignore it and the button remains disabled.
        if (InheritanceFormControl.isInheritanceFormControl(c)) {
            this._control = c;
            this._control.$inherited.pipe(takeUntil(this._$ngUnsub)).subscribe({
                next: (isInherited: boolean): void => {
                    this.isInherited = isInherited;
                    this._cd.markForCheck();
                }
            });
        }
    }

    public ngOnDestroy(): void {
        this._$ngUnsub.next();
        this._$ngUnsub.complete();
    }

    /**
     * Since we are already grabbing our control with injection, we might as
     * well clean up the form-creation API by handling the reverting of the
     * control here too, instead of creating an entire event for it, and
     * having to handle that event on every form.
     * @returns {void} nothing
     */
    public onClick(): void {
        this._control?.revert();
        this.reverted.emit();
        this._cd.markForCheck();
    }
}
