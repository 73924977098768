import { SalesQuoteEventStatus } from '@libs/iso/core/models/device/DeviceSupplyEventSalesQuote';

export class DeviceSupplyEvents {
    public salesQuote?: SalesQuoteEventStatus = null;

    constructor(props?: { [K in keyof DeviceSupplyEvents]: DeviceSupplyEvents[K] }) {
        if (props) {
            if (props.salesQuote) {
                this.salesQuote = new SalesQuoteEventStatus(props.salesQuote);
            }
        }
    }
}
