import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
    RIAFOX_HTTP_WEB_SERVER_URL,
    RIAFOX_HTTP_URL_IGNORE_CASES
} from './interceptors/url.interceptor';

@NgModule({
    imports: [CommonModule, HttpClientModule],
    providers: [
        {
            provide: RIAFOX_HTTP_WEB_SERVER_URL,
            useValue: ''
        },
        {
            provide: RIAFOX_HTTP_URL_IGNORE_CASES,
            useValue: null
        }
    ]
})
export class RiafoxHttpModule {}
