import {
    Component,
    Input,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    OnInit
} from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'ptkr-pagination-panel',
    templateUrl: './pagination-panel.component.html',
    styleUrls: ['./pagination-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationPanelComponent implements OnInit {
    @Input() deviceListCount: number | null;

    @Input()
    public pageSizeOptions: Array<number> = [];

    private _startingPageSize: number;
    @Input('startingPageSize')
    public get startingPageSize(): number {
        return this._startingPageSize;
    }
    public set startingPageSize(value: number) {
        value = coerceNumberProperty(value);
        this._startingPageSize = value;
        this._cd.detectChanges();
    }

    public dataLengthValue: number;
    @Input() // This is used to calculate if next page should be enabled or not.
    public get dataLength(): number {
        return this.dataLengthValue;
    }
    public set dataLength(value: number) {
        this.dataLengthValue = value;
        this._updateNextButton();
    }

    public currentPageValue: number;
    @Input()
    public get currentPage(): number {
        return this.currentPageValue;
    }
    public set currentPage(value: number) {
        this.currentPageValue = value;
        this.prevEnabled = this.currentPageValue > 1;
    }
    @Output() public currentPageChange: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

    public prevEnabled: boolean = false;
    public nextEnabled: boolean = false;

    constructor(private _cd: ChangeDetectorRef) { }

    public ngOnInit(): void {
        this._updateNextButton();
    }

    public onSelectionChange(event: any): void {
        this.pageSizeChange.emit(event.value);
        this._updateNextButton();
    }

    public prevOnClick(): void {
        this.currentPageChange.emit(--this.currentPage);
        if (this.currentPage <= 1) {
            this.prevEnabled = false;
        }
    }

    public nextOnClick(): void {
        this.currentPageChange.emit(++this.currentPage);
        this.prevEnabled = true;
    }

    private _updateNextButton(): void {
        this.nextEnabled = this.dataLength >= this._startingPageSize;
        this._cd.detectChanges();
    }
}
