import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RiafoxFormsModule } from '@libs/web/forms';
import { RiafoxChartsModule } from '@libs/web/charts';
import { components, BulkAddInstallIprangeComponent } from './components';
import { containers } from './containers';
import { directives } from './directives';
import { overlays } from './overlays';
import { pipes } from './pipes';
import { ThemeModule } from './theme/theme.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgDragDropModule } from 'ng-drag-drop';
import { TextMaskModule } from 'angular2-text-mask';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MomentModule } from 'ngx-moment';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MeterFormatPillComponent } from './components/meter-format-pill/meter-format-pill.component';
import { PolymorpheusModule, POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { OverlayModule } from '@angular/cdk/overlay';
import { CronEditorModule } from '../../../../libs/web/cron/cron-editor.module';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    imports: [
        CommonModule,
        ThemeModule,
        RouterModule,
        RiafoxFormsModule,
        RiafoxChartsModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatExpansionModule,
        NgDragDropModule,
        TextMaskModule,
        ScrollingModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MomentModule,
        DragDropModule,
        OverlayModule,
        PolymorpheusModule,
        CronEditorModule,
        MonacoEditorModule,
        LayoutModule,
        MatSelectModule,
        NgOptimizedImage
    ],
    exports: [
        ThemeModule,
        RiafoxFormsModule,
        RiafoxChartsModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MonacoEditorModule,
        LayoutModule,
        ...components,
        ...directives,
        ...pipes
    ],
    declarations: [...components, ...containers, ...directives, ...overlays, ...pipes],
    entryComponents: [...overlays, BulkAddInstallIprangeComponent, MeterFormatPillComponent],
    providers: [
        { provide: POLYMORPHEUS_CONTEXT, useValue: null } // Default injection, in case none is provided by component.
    ]
})
export class SharedModule {}
