import { AbstractControl, FormControl } from '@angular/forms';
import { DeviceModel } from '@libs/iso/core/models/device/Device';
import { UpdateKeyMap } from '@libs/iso/core/enums/UpdateKeyMap';
import { IMeterReadSupply } from '@libs/iso/core/models/meterRead/MeterReadSupply';
import { AbstractSettingsFormGroup } from '@libs/web/forms/models/AbstractSettingsFormGroup';
import { SettingsUpdateType } from '@libs/iso/core/models/SettingsFormSubmission';

export class DeviceNonInheritedSettingsFormGroup extends AbstractSettingsFormGroup {
    public get supplies(): any {
        return this._supplies;
    }

    private readonly _supplies: IMeterReadSupply;

    constructor(device: Partial<DeviceModel>) {
        super({
            [UpdateKeyMap.Device.AssetId]: new FormControl(device?.assetId),
            [UpdateKeyMap.Device.CustomSerialNum]: new FormControl(device?.customSerialNum ?? ''),
            [UpdateKeyMap.Device.CustomLocation]: new FormControl(device?.customLocation),
            [UpdateKeyMap.Device.Location]: new FormControl(device?.location),
            [UpdateKeyMap.Device.Note]: new FormControl(device?.note),
            [UpdateKeyMap.Device.SkipAlerts]: new FormControl(device?.skipAlerts)
        });
        this._supplies = device?.currentMeterRead?.supplies;

        // If  custom serial number is null or undefined it will be set to an
        // empty string for the controller.
    }

    public extractOverwrittenValues(): SettingsUpdateType {
        const res: SettingsUpdateType = { set: {}, unset: [] };
        for (const controlsKey in this.controls) {
            const control: AbstractControl = this.controls[controlsKey];
            if (control.dirty) {
                res.set[controlsKey] = control.value;
                // If control in question is the custom serial number and its value
                // is an empty string, set it to null.
                if (controlsKey === UpdateKeyMap.Device.CustomSerialNum) {
                    if (control.value === '' || control.value === undefined) {
                        res.set[controlsKey] = null;
                    }
                }
            }

        }

        // If the control left any 'tempHistory' items, append them to the history
        // This is so the control didn't have to keep adding them itself.
        if (!!res.set && !!res.set[UpdateKeyMap.Device.SkipAlerts]) {
            for (const alert of res.set[UpdateKeyMap.Device.SkipAlerts]) {
                if (!!alert['tempHistory']) {
                    alert.history.push(alert.tempHistory);
                    delete alert.tempHistory;
                }
            }
        }

        return res;
    }
}
