import { ObjectId } from 'bson';
import { BaseModel, IBaseModel } from '../BaseModel';
import { ISettings, Settings } from '../settings/Settings';
import { EntityAddress } from '@libs/iso/core/models/entity/EntityAddress';
import { EntityDynamicDeviceGroups } from '@libs/iso/core/models/entity/EntityDynamicDeviceGroups';
import { PropertiesOf } from '@libs/iso/core';

export interface EntityModel extends IBaseModel {
    acuId: string;
    legacyId: number;
    name: string;
    parentEntityKey: ObjectId | string;
    genealogy: (ObjectId | string)[];
    globalAdminKey: ObjectId | string;
    salesRepKey: ObjectId | string;
    addresses: EntityAddress[];
    isDealer: boolean;
    dealerKey: ObjectId | string;
    dealerName: string;
    settings: Partial<ISettings>;
    expiresOn: Date;
    dealer: {
        notes: string;
    };
    dynamicDeviceGroups: PropertiesOf<EntityDynamicDeviceGroups> | null;
}

interface EntityConstructorParams extends EntityModel {}

export class Entity extends BaseModel implements EntityModel {
    // public static columns: Array<Column> = [
    //     ...BaseModel.columns,
    //     {
    //         name: 'Name',
    //         key: 'name',
    //         description: '',
    //         display: Column.displayFunctions['']
    //     },
    //     {
    //         name: 'Parent Entity Key',
    //         key: 'parentEntityKey',
    //         description: '',
    //         display: Column.displayFunctions['']
    //     },
    //     {
    //         name: 'Expiration Date',
    //         key: 'expiresOn',
    //         description: '',
    //         display: Column.displayFunctions['']
    //     }
    // ];

    public acuId: string = '';
    public legacyId: number = null;
    public name: string = '';
    public parentEntityKey: ObjectId | string = null;
    public genealogy: (ObjectId | string)[] = [];
    public globalAdminKey: ObjectId | string = null;
    public salesRepKey: ObjectId | string = null;
    public addresses: EntityAddress[] = [];
    public isDealer: boolean = false;
    public dealerKey: ObjectId | string = null;
    public dealerName: string = '';
    public settings: Partial<Settings> = {};
    public expiresOn: Date = null;
    public dealer: { notes: string } = { notes: '' };
    public dynamicDeviceGroups: EntityDynamicDeviceGroups = null;

    constructor(params?: Partial<EntityConstructorParams>) {
        super(params);
        if (!!params) {
            this.acuId = params.acuId || this.acuId;
            this.legacyId = params.legacyId || this.legacyId;
            this.name = params.name || this.name;
            this.parentEntityKey = params.parentEntityKey || this.parentEntityKey;
            if (Array.isArray(params.genealogy)) {
                this.genealogy = params.genealogy;
            }
            this.globalAdminKey = params.globalAdminKey || this.globalAdminKey;
            this.salesRepKey = params.salesRepKey || this.salesRepKey;
            if (Array.isArray(params.addresses)) {
                for (const a of params.addresses) {
                    this.addresses = [...this.addresses, new EntityAddress(a)];
                }
            }
            this.isDealer = params.isDealer || this.isDealer;
            this.dealerKey = params.dealerKey ?? this.dealerKey;
            this.dealerName = params.dealerName ?? this.dealerName;
            if (!!params.settings) {
                this.settings = params.settings;
            }
            this.expiresOn = params.expiresOn || this.expiresOn;
            this.dealer = { notes: params.dealer?.notes || this.dealer.notes };
            this.dynamicDeviceGroups =
                params.dynamicDeviceGroups != null
                    ? new EntityDynamicDeviceGroups(params.dynamicDeviceGroups)
                    : null;
        }
    }
}
