import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Cart, UserPaymentMethod } from '@libs/iso/core';
import { Observable, Subject, of } from 'rxjs';
import { UserService } from '@app/core/services/user.service';
import { NotificationService } from '@app/core/services/notification.service';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { User, Order } from '@app/models';
import { fromUser } from '@app/state/selectors';
import { take, tap } from 'rxjs/operators';
import { StepComponent } from '@app/shared/overlays/StepComponent';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';

@Component({
    selector: 'ptkr-payment-step',
    templateUrl: './payment-step.component.html',
    styleUrls: ['./payment-step.component.scss']
})
export class PaymentStepComponent implements StepComponent, OnInit, OnDestroy {
    @Input()
    public cart: Cart;

    private _order: Order;
    @Input()
    public set order(value: Order) {
        this._order = value;
        if (!!this._order.shippingAddress && !!this._order.shippingMethod) {
            this._refreshPaymentMethods();
        }
    }
    public get order(): Order {
        return this._order;
    }

    private _ngUnsub: Subject<void> = new Subject<void>();
    private _user: User;

    public paymentMethods$: Observable<UserPaymentMethod[]> = of([]);
    public selectedPayment: any;

    constructor(
        private _userService: UserService,
        private _store: Store<GlobalStore>,
        private _cd: ChangeDetectorRef,
        private _notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this._store
            .pipe(
                select(fromUser.currentUser),
                take(1)
            )
            .subscribe(u => (this._user = u));
    }

    ngOnDestroy(): void {
        this._ngUnsub.next();
        this._ngUnsub.complete();
        this._ngUnsub = undefined;
    }

    public validate(): boolean {
        return !!this.selectedPayment;
    }

    public getValue(): any {
        return this.selectedPayment;
    }

    public newPaymentMethod($event): void {
        this._userService.addNewPaymentMethod(this._user._id, $event).subscribe(
            res => {
                this._notificationService.success('Payment method successfully added');
                this._refreshPaymentMethods();
            },
            error => {
                this._notificationService.error(error);
            }
        );
    }

    public selectPaymentMethod(method: UserPaymentMethod): void {
        this.selectedPayment = method;
    }

    private _refreshPaymentMethods(): void {
        setTimeout(() => {
            this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        });
        this.paymentMethods$ = this._userService
            .getUserSavedPaymentsForCheckout(this._user._id, 'all', [this.cart], this._order)
            .pipe(tap(() => this._store.dispatch(new ToggleGlobalSpinnerAction(false))));
        this._cd.detectChanges();
    }
}
