<div class="formFieldHeader">
    <span class="formFieldHeader-element formFieldHeader-element--label">
        {{header}}
    </span>
    <a class="formFieldHeader-element formFieldHeader-element--hint" [matTooltip]="tip">
        What's this?
    </a>
</div>
<div polymorpheus-outlet [content]="controllerContent" [context]="controllerContext"></div>
<ul class="formFieldActions">
    <ng-container *ngIf="!!error">
        <li class="formFieldActions-action formFieldText-error --error">
            <div polymorpheus-outlet [content]="error"></div>
        </li>
    </ng-container>
    <ng-container *ngFor="let action of actions">
        <li class="formFieldActions-action">
            <div polymorpheus-outlet [content]="action">
                <ng-template let-label>
                    <a class="formFieldActions-action-label">{{label}}</a>
                </ng-template>
            </div>
        </li> 
    </ng-container>
</ul>