<ptkr-form-field
    header="Meter Name"
    tip="
        The meter name is the name of a meter that is used for billing. Examples include Total,
        Total Black, and Total Color.
    "
    [controllerContent]="controllerContent"
    [error]="getErrorMessage()"
></ptkr-form-field>
<ng-template #controllerContent>
    <ptkr-filter-select-control
        [formControl]="controller"
        [optionContent]="dropdownOpts"
        [noSelectionContent]="noContent"
        [options]="meterOptions"
        (filterTextChange)="onFilterTextChange($event)"
    ></ptkr-filter-select-control>
</ng-template>
<ng-template #dropdownOpts let-displayName="displayName">
    <div tabindex="0" class="formFieldControl-select">
        <div class="formFieldControl-select-element formFieldControl-select-element--label">
            {{ displayName }}
        </div>
    </div>
</ng-template>
<ng-template #noContent>
    <div class="formFieldControl-select">
        <div class="formFieldControl-select-element formFieldControl-select-element--hint">
            Choose one
        </div>
    </div>
</ng-template>
