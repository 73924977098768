import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { Observable } from 'rxjs';
import { fromApp } from '@app/state/selectors';

@Component({
    selector: 'ptkr-public-container',
    templateUrl: './public-container.component.html',
    styleUrls: ['./public-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicContainerComponent implements OnInit {
    public spinnerActive$: Observable<boolean> = this._store.pipe(
        select(fromApp.showGlobalLoading)
    );

    constructor(private _store: Store<GlobalStore>) {}

    public ngOnInit(): void {}
}
