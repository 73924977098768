import { AbstractControl } from '@angular/forms';
import { DynamicSettingsKeyType, UpdateKeyMap } from '@libs/iso/core';
import { SettingsUpdateType } from '@libs/iso/core/models/SettingsFormSubmission';
import { InheritanceFormControl } from '@libs/web/forms/models/form-controls/InheritanceFormControl';
import { AbstractSettingsFormGroup } from '@libs/web/forms/models/AbstractSettingsFormGroup';

export class AbstractDynamicSettingsSectionFormGroup extends AbstractSettingsFormGroup {
    /**
     * Note that this assumes that any inheritance form control is used to control something in the
     * dynamic settings object and thus something within the DynamicSettingsKeyType.
     * @see DynamicSettingsKeyType
     * @returns {SettingsUpdateType}  Set and unset properties denote what to change in the settings object
     */
    public extractOverwrittenValues(): SettingsUpdateType {
        const res: SettingsUpdateType = { set: {}, unset: [] };
        for (const controlsKey in this.controls) {
            const control: AbstractControl = this.controls[controlsKey];
            // Ignore controls that don't match an update key
            if (!!UpdateKeyMap.asArrayWithChildren.find(v => v === controlsKey) && control) {
                if (control.dirty) {
                    if (InheritanceFormControl.isInheritanceFormControl(control)) {
                        if (control.$inherited.getValue()) {
                            res.unset.push(controlsKey as DynamicSettingsKeyType);
                        } else {
                            res.set[controlsKey] = control.childValue;
                        }
                    } else {
                        res.set[controlsKey] = control.value;
                    }
                }
            }
        }
        return res;
    }
}
