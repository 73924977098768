import '@angular/localize/init';

enum EventType {
    DaysToDepletion = 'daysToDepletion',
    PercentRemaining = 'pctRemaining',
    SupplyAlert = 'supplyAlert',
    ServiceAlert = 'serviceAlert',
    MaintenanceSupplyAlert = 'maintenanceSupplyAlert',

    Jammed = 'jammed',
    DoorOpen = 'doorOpen',
    Offline = 'offline',
    OutOfPaper = 'outOfPaper',
    LowToner = 'lowToner',
    NoToner = 'noToner',
    ServiceRequested = 'serviceRequested',
    SupplyMissing = 'supplyMissing',
    NotReachable = 'notReachable',
    OutputFull = 'outputFull',
    InputTrayMissing = 'inputTrayMissing',

    PrematureToggle = 'prematureToggle',
    ReplacementToggle = 'replacementToggle',
    Ready = 'ready',

    VolumeAlert = 'volumeAlert'
}

namespace EventType {
    export const orderKey: string = 'EventTypeOrder';
    export const serviceKey: string = 'EventTypeService';
    export const criticalKey: string = 'EventTypeCritical';
    export const volumeKey: string = 'EventTypeVolume';

    export const criticalAlerts: EventType[] = [
        EventType.DaysToDepletion,
        EventType.PercentRemaining,
        EventType.LowToner,
        EventType.SupplyAlert,
        EventType.ServiceAlert,
        EventType.MaintenanceSupplyAlert,
    ];

    export const orderAlerts: EventType[] = [
        EventType.DaysToDepletion,
        EventType.PercentRemaining,
        EventType.SupplyAlert,
        EventType.MaintenanceSupplyAlert,
    ];

    export const serviceAlerts: EventType[] = [
        EventType.Jammed,
        EventType.DoorOpen,
        EventType.Offline,
        EventType.OutOfPaper,
        EventType.LowToner,
        EventType.NoToner,
        EventType.ServiceRequested,
        EventType.SupplyMissing,
        EventType.NotReachable,
        EventType.OutputFull,
        EventType.InputTrayMissing,
        EventType.ServiceAlert,
    ];

    export const VolumeAlerts: EventType[] = [
        EventType.VolumeAlert
    ];

    export const asArray: EventType[] = [
        // Critical alerts are included in order and service alert types
        ...EventType.orderAlerts,
        ...EventType.serviceAlerts,
        EventType.PrematureToggle,
        EventType.ReplacementToggle,
        EventType.Ready
    ];
    export function toString(type: EventType): string {
        switch (type) {
            case EventType.DaysToDepletion:
                return $localize`Days to depletion`;
            case EventType.PercentRemaining:
                return $localize`Percent remaining`;
            case EventType.Jammed:
                return $localize`Jammed`;
            case EventType.DoorOpen:
                return $localize`Door open`;
            case EventType.Offline:
                return $localize`Offline`;
            case EventType.OutOfPaper:
                return $localize`Out of paper`;
            case EventType.LowToner:
                return $localize`Low toner`;
            case EventType.NoToner:
                return $localize`No toner`;
            case EventType.ServiceRequested:
                return $localize`Service requested`;
            case EventType.SupplyMissing:
                return $localize`Supply missing`;
            case EventType.NotReachable:
                return $localize`Not reachable`;
            case EventType.OutputFull:
                return $localize`Output full`;
            case EventType.InputTrayMissing:
                return $localize`Input tray missing`;
            case EventType.PrematureToggle:
                return $localize`Premature replacement`;
            case EventType.SupplyAlert:
                return $localize`Supply alert`;
            case EventType.MaintenanceSupplyAlert:
                return $localize`Maintenance supply alert`;
            case EventType.ServiceAlert:
                return $localize`Service alert`;
            case EventType.ReplacementToggle:
                return $localize`Supply replacement`;
            case EventType.Ready:
                return $localize`Ready`;
            case EventType.VolumeAlert:
                return $localize`Volume alert`;
            default:
                return '';
        }
    }

    export function notificationTitle(type: EventType): string {
        switch (type) {
            // Critical alerts are included in order and service alert types
            case EventType.DaysToDepletion:
            case EventType.PercentRemaining:
            case EventType.SupplyAlert:
            case EventType.MaintenanceSupplyAlert:
                return 'Order';
            case EventType.Jammed:
            case EventType.DoorOpen:
            case EventType.Offline:
            case EventType.OutOfPaper:
            case EventType.LowToner:
            case EventType.NoToner:
            case EventType.ServiceRequested:
            case EventType.SupplyMissing:
            case EventType.NotReachable:
            case EventType.OutputFull:
            case EventType.InputTrayMissing:
            case EventType.ServiceAlert:
                return 'Service';
            case EventType.VolumeAlert:
                return 'Volume';
            default:
                return '';
        }
    }
}

export { EventType };
