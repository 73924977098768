<table class="g-table" mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="make">
        <th mat-header-cell *matHeaderCellDef>Make</th>
        <td mat-cell *matCellDef="let device">{{valueOrEmpty(device.make)}}</td>
    </ng-container>
    <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef>Model</th>
        <td mat-cell *matCellDef="let device">{{valueOrEmpty(device.model)}}</td>
    </ng-container>
    <ng-container matColumnDef="serialNum">
        <th mat-header-cell *matHeaderCellDef>Serial #</th>
        <td mat-cell *matCellDef="let device">{{valueOrEmpty(device.serialNum)}}</td>
    </ng-container>
    <ng-container matColumnDef="ipAddr">
        <th mat-header-cell *matHeaderCellDef>IP Address</th>
        <td mat-cell *matCellDef="let device">{{valueOrEmpty(device.ipAddr)}}</td>
    </ng-container>
    <ng-container matColumnDef="assetId">
        <th mat-header-cell *matHeaderCellDef>Asset ID</th>
        <td mat-cell *matCellDef="let device">{{valueOrEmpty(device.assetId)}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let install; columns: displayedColumns;"></tr>
</table>
