import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    OnDestroy
} from '@angular/core';
import { EntityNode } from '@app/shared/components/tree-node/EntityNode';
import { DropEvent } from 'ng-drag-drop';
import { NotificationService } from '@app/services';

@Component({
    selector: 'ptkr-tree-node',
    templateUrl: './tree-node.component.html',
    styleUrls: ['./tree-node.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeNodeComponent implements OnInit, OnDestroy {
    @Input()
    public node: EntityNode = null;

    @Output()
    public nodeClick: EventEmitter<EntityNode> = new EventEmitter<EntityNode>();
    @Output()
    public nodeExpand: EventEmitter<EntityNode> = new EventEmitter<EntityNode>();
    @Output()
    public nodeMove: EventEmitter<{ moved: EntityNode; newParent: EntityNode }> = new EventEmitter<{
        moved: EntityNode;
        newParent: EntityNode;
    }>();

    public okayToDrop2: boolean = true;

    constructor(private _notificationService: NotificationService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    public click(): void {
        if (!this.node.expanded) {
            this.node.expanded = true;
        }
        this.nodeClick.emit(this.node);
    }

    public expand(): void {
        this.node.expanded = !this.node.expanded;
        this.nodeExpand.emit(this.node);
    }

    public childClick($event): void {
        this.nodeClick.emit($event);
    }

    public childExpand($event): void {
        this.nodeExpand.emit($event);
    }

    public okayToDrop($event): boolean {
        // console.log($event);
        // console.log(this);
        return true;
    }

    public onItemDrop($event: DropEvent): void {
        const dragged: EntityNode = $event.dragData;
        const dropTarget: EntityNode = this.node;
        // console.log(dragged);
        // console.log(dropTarget);
        if (dropTarget.genealogy.includes(dragged._id)) {
            this._notificationService.error(
                dropTarget.label + ' is a descendant of ' + dragged.label + '.'
            );
        } else if (dropTarget._id !== dragged._id) {
            this.nodeMove.emit({ moved: dragged, newParent: dropTarget });
        }
    }

    public childMove($event): void {
        this.nodeMove.emit($event);
    }

    public onDragStart($event): void {
        console.log('start');
        console.log($event);
    }

    public onDragEnd($event): void {
        console.log('stop');
    }
}
