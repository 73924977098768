<div class="health-header"><h3 i18n>Install Health (current)</h3>
    <mat-icon matTooltip="The latest healthcheck from the install this job was sent to. Displayed here for quick reference." i18n-matTooltip>
        help_outline
    </mat-icon>
</div>
<div class="details bordered">
    <div class="info-section">
        <div class="clearfix">
            <mat-icon *ngIf="healthState?.isValid() === true" class="online pull-left">check_circle</mat-icon>
            <mat-icon *ngIf="healthState?.isError() === true" class="offline pull-left">error</mat-icon>
            <mat-icon *ngIf="healthState?.isWarning() === true" class="warning pull-left">warning</mat-icon>
            <div class="pull-left health-label">
                <b i18n>Overall State </b>
            </div>
            <div class="pull-right health-label">
                <span *ngIf="healthState?.timestamp; else healthStateElse">
                    <small>{{healthState?.timestamp | amTimeAgo}} ({{healthState?.timestamp | date: "medium"}})</small>
                </span>
                <ng-template #healthStateElse i18n>no data</ng-template>
            </div>
        </div>
        <div class="details console" *ngIf="healthState?.isValid() === false">
            <div class="js-check-step-line CheckStep-line d-flex log-line-plain">
                <span class="CheckStep-line-content text-mono d-inline-block flex-auto ml-3 js-check-line-content">
                    {{healthState?.message}}
                    <span *ngIf="healthState?.isExpired() === true" i18n><br>dca hasn't updated this check recently</span>
                </span>
            </div>
        </div>
    </div>
    <div class="info-section">
        <div class="clearfix">
            <mat-icon *ngIf="registration?.isValid() === true" class="online pull-left">check_circle</mat-icon>
            <mat-icon *ngIf="registration?.isError() === true" class="offline pull-left">error</mat-icon>
            <mat-icon *ngIf="registration?.isWarning() === true" class="warning pull-left">warning</mat-icon>
            <div class="pull-left health-label">
                <b i18n>Registration </b>
            </div>
            <div class="pull-right health-label">
                <span *ngIf="registration?.timestamp; else registrationElse">
                    <small>{{registration?.timestamp | amTimeAgo}} ({{registration?.timestamp | date: "medium"}})</small>
                </span>
                <ng-template #registrationElse i18n>no data</ng-template>
            </div>
        </div>
        <div class="details console" *ngIf="registration?.isValid() === false">
            <div class="js-check-step-line CheckStep-line d-flex log-line-plain">
                <span class="CheckStep-line-content text-mono d-inline-block flex-auto ml-3 js-check-line-content">
                    {{registration?.message}}
                    <span *ngIf="registration?.isExpired() === true" i18n><br>dca hasn't updated this check recently</span>
                </span>
            </div>
        </div>
    </div>
    <div class="info-section" *ptkrPermission="Permission?.DaemonOnly">
        <div class="clearfix">
            <mat-icon *ngIf="taskEngine?.isValid() === true" class="online pull-left">check_circle</mat-icon>
            <mat-icon *ngIf="taskEngine?.isError() === true" class="offline pull-left">error</mat-icon>
            <mat-icon *ngIf="taskEngine?.isWarning() === true" class="warning pull-left">warning</mat-icon>
            <div class="pull-left health-label">
                <b i18n>Task Engine </b>
            </div>
            <div class="pull-right health-label">
                <span *ngIf="taskEngine?.timestamp; else taskEngineElse">
                    <small>{{taskEngine?.timestamp | amTimeAgo}} ({{taskEngine?.timestamp | date: "medium"}})</small>
                </span>
                <ng-template #taskEngineElse i18n>no data</ng-template>
            </div>
        </div>
        <div class="details console" *ngIf="taskEngine?.isValid() === false">
            <div class="js-check-step-line CheckStep-line d-flex log-line-plain">
                <span class="CheckStep-line-content text-mono d-inline-block flex-auto ml-3 js-check-line-content">
                    {{taskEngine?.message}}
                    <span *ngIf="taskEngine?.isExpired() === true" i18n><br>dca hasn't updated this check recently</span>
                </span>
            </div>
        </div>
    </div>
    <div class="info-section">
        <div class="clearfix">
            <mat-icon *ngIf="rpcClient?.isValid() === true" class="online pull-left">check_circle</mat-icon>
            <mat-icon *ngIf="rpcClient?.isError() === true" class="offline pull-left">error</mat-icon>
            <mat-icon *ngIf="rpcClient?.isWarning() === true" class="warning pull-left">warning</mat-icon>
            <div class="pull-left health-label">
                <b i18n>Internet Connection </b>
            </div>
            <div class="pull-right health-label">
                <span *ngIf="rpcClient?.timestamp; else rpcClientElse">
                    <small>{{rpcClient?.timestamp | amTimeAgo}} ({{rpcClient?.timestamp | date: "medium"}})</small>
                </span>
                <ng-template #rpcClientElse i18n>no data</ng-template>
            </div>
        </div>
        <div class="details console" *ngIf="rpcClient?.isValid() === false">
            <div class="js-check-step-line CheckStep-line d-flex log-line-plain">
                <span class="CheckStep-line-content text-mono d-inline-block flex-auto ml-3 js-check-line-content">
                    {{rpcClient?.message}}
                    <span *ngIf="rpcClient?.isExpired() === true" i18n><br>dca hasn't updated this check recently</span>
                </span>
            </div>
        </div>
    </div>
    <div class="info-section">
        <div class="clearfix">
            <mat-icon *ngIf="dependencies?.isValid() === true" class="online pull-left">check_circle</mat-icon>
            <mat-icon *ngIf="dependencies?.isError() === true" class="offline pull-left">error</mat-icon>
            <mat-icon *ngIf="dependencies?.isWarning() === true" class="warning pull-left">warning</mat-icon>
            <div class="pull-left health-label">
                <b i18n>Dependencies </b>
            </div>
            <div class="pull-right health-label">
                <span *ngIf="dependencies?.timestamp; else dependenciesElse">
                    <small>{{dependencies?.timestamp | amTimeAgo}} ({{dependencies?.timestamp | date: "medium"}})</small>
                </span>
                <ng-template #dependenciesElse i18n>no data</ng-template>
            </div>
           </div>
        <div class="details console" *ngIf="dependencies?.isValid() === false">
            <div class="js-check-step-line CheckStep-line d-flex log-line-plain">
                <span class="CheckStep-line-content text-mono d-inline-block flex-auto ml-3 js-check-line-content">
                    {{dependencies?.message}}
                    <span *ngIf="dependencies?.isExpired() === true" i18n><br>dca hasn't updated this check recently</span>
                </span>
            </div>
        </div>
    </div>

<!--    DEPRECATED: Using internet connection check to determine the health of the rpc connection-->
<!--    <div class="info-section">-->
<!--        <div class="clearfix">-->
<!--            <mat-icon *ngIf="jobStream.isValid() === true" class="online pull-left">check_circle</mat-icon>-->
<!--            <mat-icon *ngIf="jobStream.isValid() === false" class="offline pull-left">error</mat-icon>-->
<!--            <div class="pull-left health-label">-->
<!--                <b>Job Stream </b>-->
<!--            </div>-->
<!--            <div class="pull-right health-label">-->
<!--                <span><small>{{jobStream.timestamp | amTimeAgo}} ({{jobStream.timestamp | date: "medium"}})</small></span>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="details console" *ngIf="jobStream.isValid() === false">-->
<!--            <div class="js-check-step-line CheckStep-line d-flex log-line-plain">-->
<!--                <span class="CheckStep-line-content text-mono d-inline-block flex-auto ml-3 js-check-line-content">-->
<!--                    {{jobStream.message}}-->
<!--                    <span *ngIf="jobStream.isExpired() === true">dca hasn't updated this check recently</span>-->
<!--                </span>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</div>
