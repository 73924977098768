import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    Input
} from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StripeService } from '@app/core/services/stripe.service';

@Component({
    selector: 'ptkr-add-credit-card',
    templateUrl: './add-credit-card.component.html',
    styleUrls: ['./add-credit-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddCreditCardComponent implements OnInit {
    @ViewChild('nameOnCard', {static: true})
    public nameOnCardRef: ElementRef;
    @ViewChild('cardNum', {static: true})
    public cardNumRef: ElementRef;
    @ViewChild('cardExp', {static: true})
    public cardExpRef: ElementRef;
    @ViewChild('cardCvc', {static: true})
    public cardCvcRef: ElementRef;
    public creditCardForm: FormGroup;
    @Input()
    public icons: boolean = false;

    constructor(private _stripeService: StripeService, private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.creditCardForm = this._fb.group({
            name_on_card: ['', Validators.required]
        });

        this._stripeService.lightCardNum.mount(this.cardNumRef.nativeElement);
        this._stripeService.lightCardExp.mount(this.cardExpRef.nativeElement);
        this._stripeService.lightCardCvc.mount(this.cardCvcRef.nativeElement);
    }

    public getToken(): Observable<any> {
        const card = this._stripeService.getLightCardWithData();
        return this._stripeService.createToken(card[0], {
            name: this.creditCardForm.value.name_on_card
                ? this.creditCardForm.value.name_on_card
                : undefined
        });
    }

    public reset(): void {
        this._stripeService.lightCardNum.unmount();
        this._stripeService.lightCardExp.unmount();
        this._stripeService.lightCardCvc.unmount();
        this._stripeService.lightCardNum.mount(this.cardNumRef.nativeElement);
        this._stripeService.lightCardExp.mount(this.cardExpRef.nativeElement);
        this._stripeService.lightCardCvc.mount(this.cardCvcRef.nativeElement);
    }
}
