import {
    DeviceList,
    DeviceTableParams,
    getTableConfigurationFromStorage
} from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DeviceService } from '@app/services';
import { catchError, take } from 'rxjs/operators';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { IDeviceListTableQueryParams } from '@app/core/models/TableConfiguration';

@Injectable()
export class DeviceListResolver implements Resolve<DeviceList> {
    constructor(private _deviceService: DeviceService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<DeviceList> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let entityId: string = '';
        this._store.pipe(select(fromEntity.entityId), take(1)).subscribe(id => (entityId = id));

        // When the resolver first runs without any query parameters, we want to default to the locally
        // saved table config parameters. We can get these from the local storage.
        const {
            includeChildren,
            excludeDisabled,
            excludeDroppedOff,
            localOnly
        }: any = getTableConfigurationFromStorage<IDeviceListTableQueryParams>('deviceList') ?? {
            includeChildren: false,
            excludeDisabled: false,
            excludeDroppedOff: false,
            localOnly: false
        };

        // Then we give priority to what's already in the query parameters
        const initQParams = route.queryParams;
        const defaultLimit = 30;
        const params: DeviceTableParams = {
            deviceId: initQParams.deviceId || undefined,
            tab: initQParams.tab || undefined,
            searchTerm: initQParams.searchTerm || '',
            sort: initQParams.sortBy || '',
            sortOrder: initQParams.sortOrder || '',
            page: coerceNumberProperty(initQParams.page) || 1,
            limit: coerceNumberProperty(initQParams.limit) || defaultLimit,
            includeChildren: initQParams.includeChildren
                ? coerceBooleanProperty(initQParams.includeChildren)
                : coerceBooleanProperty(includeChildren),
            excludeDisabled: coerceBooleanProperty(excludeDisabled),
            excludeDroppedOff: coerceBooleanProperty(excludeDroppedOff),
            localOnly: coerceBooleanProperty(localOnly)
        };
        // We have to set the parameters this way because 'false' boolean values are 'falsy' values
        if (initQParams.excludeDisabled != null) {
            params.excludeDisabled = coerceBooleanProperty(initQParams.excludeDisabled);
        }
        if (initQParams.excludeDroppedOff != null) {
            params.excludeDroppedOff = coerceBooleanProperty(initQParams.excludeDroppedOff);
        }
        if (initQParams.localOnly != null) {
            params.localOnly = coerceBooleanProperty(initQParams.localOnly);
        }
        return this._deviceService
            .getDeviceList(
                entityId,
                params.deviceId,
                params.searchTerm,
                params.page,
                params.limit,
                params.sort,
                params.sortOrder as number,
                params.includeChildren,
                params.excludeDisabled,
                params.excludeDroppedOff,
                params.localOnly
            )
            .pipe(
                catchError(err => {
                    console.error('caught error', err);
                    return of(new DeviceList());
                })
            );
    }
}
