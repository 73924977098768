import { Injectable } from '@angular/core';
import { HttpService, Get, Query, Post, Body, Put, Delete, Path } from '@libs/web/http';
import { Observable } from 'rxjs';
import { DGI, IDGIMetadata, IDGIVersion } from '@libs/iso/core';

@Injectable()
export class DgiService extends HttpService {
    @Get('/dgi/byitem')
    public findDgisByItem(@Query('id') itemKey: string): Observable<Array<DGI>> {
        return null;
    }

    @Get('/dgi/byItemFull')
    public getFullDGIByItem(@Query('id') itemKey: string): Observable<Array<DGI>> {
        return null;
    }

    @Post('/dgi')
    public create(
        @Body('itemKey') itemKey: string,
        @Body('version') version: IDGIVersion,
        @Body('metadata') metadata: IDGIMetadata,
        @Body('dgi') dgi: any,
    ): Observable<DGI> {
        return null;
    }

    @Put('/dgi/{_id}')
    public update(
        @Path('_id') id: string,
        @Body('itemKey') itemKey: string,
        @Body('metadata') metadata: IDGIMetadata,
        @Body('dgi') dgi: any,
    ): Observable<void> {
        return null;
    }

    @Delete('/dgi/{id}')
    public delete(
        @Path('id') id: string,
    ): Observable<void> {
        return null;
    }

    @Post('/dgi/{id}/applyToSimilarDevices')
    public applyToSimilarDevices(
        @Path('id') id: string,
        @Query('itemKey') itemKey: string,
    ): Observable<void> {
        return null;
    }
}
