import {
    Component,
    DoCheck,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    Optional,
    Self,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ExportDelimiter } from '@libs/iso/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'ptkr-export-separator-select',
    templateUrl: './export-separator-select.component.html',
    styleUrls: ['./export-separator-select.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: ExportSeparatorSelectComponent }],
})
export class ExportSeparatorSelectComponent
    implements ControlValueAccessor, OnDestroy, DoCheck, MatFormFieldControl<ExportDelimiter> {
    public ExportDelimiter: typeof ExportDelimiter = ExportDelimiter;

    public innerValue: any;

    // Function to call when the value changes.
    onChange: (value: any) => void = () => {};

    // Function to call when the input is touched.
    onTouched: () => void = () => {};

    get value(): any {
        return this.innerValue;
    }

    set value(v: any) {
        console.log(v);
        this.stateChanges.next();
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChange(v);
        }
    }

    onValueChange(event: any) {
        this.value = ExportDelimiter.fromString(event);
    }

    writeValue(value: any): void {
        // This method will be called with the initial formControl value
        // and whenever the formControl value changes.
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() private _parentForm: NgForm,
        @Optional() private _parentFormGroup: FormGroupDirective,
        private _elementRef: ElementRef,
        private fm: FocusMonitor
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        fm.monitor(_elementRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }

    public stateChanges: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this._elementRef.nativeElement);
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public errorState: boolean;
    private updateErrorState() {
        const parent = this._parentFormGroup || this._parentForm;

        const oldState = this.errorState;
        const newState =
            (this.ngControl?.invalid || this.value == null) && (this.touched || parent.submitted);

        if (oldState !== newState) {
            this.errorState = newState;
            this.stateChanges.next();
        }
    }

    static nextId = 0;

    @HostBinding()
    public readonly id: string = `ptkr-export-separator-select-${ExportSeparatorSelectComponent.nextId++}`;

    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    private _placeholder: string;

    public focused: boolean;
    public onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    public onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    readonly autofilled: boolean;
    readonly controlType: string = 'ptkr-export-separator-select';

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _disabled = false;

    get empty(): boolean {
        return !this.value;
    }

    public touched: boolean;

    public onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this._elementRef.nativeElement.focus();
        }
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    @Input('aria-describedby') public userAriaDescribedBy: string;

    public setDescribedByIds(ids: string[]) {
        // const controlElement = this._elementRef.nativeElement.querySelector(
        //     '.example-select-separator-container'
        // )!;
        // controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    protected readonly console = console;
}
