import { Action } from '@ngrx/store';
import { Entity, EntityList } from '@app/models';

export enum EntitiesActions {
    SearchEntity = '[entity] search entity',
    SearchedEntities = '[entity] searched entities'
}

export class SearchEntityAction implements Action {
    public readonly type: EntitiesActions.SearchEntity = EntitiesActions.SearchEntity;
    public payload: Entity = null;
    constructor(_entity: Entity) {
        // console.log(_entity);
        this.payload = _entity;
    }
}

// Sets the search results.
export class SearchedEntitiesAction implements Action {
    public readonly type: EntitiesActions.SearchedEntities = EntitiesActions.SearchedEntities;
    public payload: EntityList = null;
    constructor(_entities: EntityList) {
        // console.log(_entities);
        this.payload = _entities;
    }
}

export type EntitiesActionsType = SearchEntityAction | SearchedEntitiesAction;
