import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import {
    BillingMeterPreference,
    BillingPreferences,
    BillingPreferencesCounters,
    IBillingMeterPreference,
    PageCountType
} from '@libs/iso/core';
import { CanonNumberedMeterMapping, supportedMeterTypesByCategory } from '@libs/iso/core/constants';
import { ActivatedRoute } from '@angular/router';
import { PageCountMeterRead } from '@libs/iso/core/models/meterRead/PageCountMeterRead';
import { SupportedMeterTypeByCategory } from '@libs/iso/core/constants/SupportedMeterTypes';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Counter } from '@libs/web/forms/components/bmp-chip/bmp-counter-chip.component';

@Component({
    selector: 'ptkr-bmp-editor',
    templateUrl: './bmp-editor.component.html',
    styleUrls: ['./bmp-editor.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BMPEditorComponent,
        multi: true
    }]
})
export class BMPEditorComponent implements OnInit, ControlValueAccessor {
    @Input() public offsetEnabled: boolean = false;

    /**
     * Allows a caller to register page counts from a device's meter read with this component so that
     * the counter options are derived from the meter read rather then all counters supported by print
     * tracker.
     *
     * This function in particular is responsible for converting the counters found on a page counts
     * object and converting them into something the auto complete input can understand.
     *
     * @param {PageCountMeterRead} pageCounts
     */
    @Input()
    public set devicePageCounts(pageCounts: PageCountMeterRead) {
        if (pageCounts == null) {
            return;
        }
        // Create a map where the unique identifier is the counter key, i.e. 'totalBlack'
        const counters = new Map<string, string>();

        // Exclude the raw Canon numbered meters from being selectable since they don't provide
        // any nice display names. If a user wants to select a Canon numbered format, it will be
        // from one of the mapped formats (life, equiv, etc).
        const formats = Object.keys(pageCounts).filter(
            format => format !== PageCountType.toDbString(PageCountType.canon)
        );
        for (const format of formats) {
            for (const meter of Object.keys(pageCounts[format])) {
                const m = pageCounts[format][meter];
                counters.set(meter, m.displayName);
            }
        }
        // For each unique key, push the key and its display name to the device counter options map.
        [...counters.entries()]
            .sort((a, b) => (a[1].toLowerCase() < b[1].toLowerCase() ? 1 : -1))
            .map(([key, displayName]) =>
                this.deviceCounterOptions.push({
                    key,
                    displayName
                })
            );
    }

    /**
     * The events fired here inform callers that the billing preferences were updated. The entire billing
     * preferences document is returned here, however it is highly recommended that callers pluck the
     * 'counters' property from the returned object and merge it with other properties in billing preferences.
     */
    @Output() public billingPreferencesCountersUpdated: EventEmitter<
        BillingPreferencesCounters
    > = new EventEmitter();

    /**
     * This form group manages the auto-complete input form controls for the format and counter input
     * elements. These form controls are subscribed to and their values are used to populate the auto
     * complete dialog.
     */
    public formGroup: FormGroup = this._formBuilder.group({
        formatInput: '',
        counterInput: ''
    });

    /**
     * Holds an array of counters based on the counters from the provided page counts object. These
     * counters are counters that are supported based on the page counts object for a meter read. In
     * other words, if there are objects in this array, then the counter auto-complete will draw from
     * these counters rather then the full load of counters that we support.
     */
    public deviceCounterOptions: Counter[] = [];

    /**
     * Holds a deep-copied array of all supported counters broken down by category. This organization
     * allows the counter auto-complete box to show counters based on which categories they belong to.
     */
    public allCounterOptions: SupportedMeterTypeByCategory[] = supportedMeterTypesByCategory.slice();

    /**
     * These are the only formats we'll allow a customer to configure billing preferences for. This
     * array may need to be expanded upon in the future as we add support for more types of formats.
     */
    public allFormatOptions: PageCountType[] = [
        PageCountType.life,
        PageCountType.equiv,
        PageCountType.lnFt,
        PageCountType.lnMt,
        PageCountType.sqFt,
        PageCountType.sqMt,
        PageCountType.lnIn,
        PageCountType.lnCm,
        PageCountType.centimeters,
        PageCountType.engine,
        PageCountType.dev,
    ];

    public pageCountType: typeof PageCountType = PageCountType;

    /**
     * This property is true if no device-specific counter options have been provided (by providing
     * a valid meter read from a device). The view uses this property to determine how to display the
     * auto-complete results (all the supported counters or just the counters from the device).
     */
    public usingAllSupportedMeterTypes: boolean = true;

    /**
     * This property is two-way bound to a toggle in the view. The toggle (and by extension, this
     * property) is used to determine whether the counter chips should display the mapped Canon
     * numbered meters for each named meter.
     */
    public showCanonNumberedMeters: boolean = false;

    /**
     * The authoritative mapping between Canon numbered meters and our proprietary format/counter
     * combination naming scheme.
     */
    public canonMeterMapping: CanonNumberedMeterMapping = new CanonNumberedMeterMapping();

    /**
     * This array represents the ordered format preferences as they exist in the view. Changes to the
     * format preferences in the view are reflected and maintained in this array.
     */
    public formats: PageCountType[] = [];

    /**
     * This mapping represent all the counters that are configured in the view. Format preferences are
     * applied to each one of these counter preferences to produce the full billing preferences object.
     */
    public counters: Map<string, Counter> = new Map<string, Counter>();

    /**
     * An observable stream that is subscribed to by the view to show what format preferences are selected.
     */
    public formats$: BehaviorSubject<PageCountType[]> = new BehaviorSubject<PageCountType[]>([]);

    /**
     * An observable stream that is subscribed to by the view to show what counters are selected.
     */
    public counters$: BehaviorSubject<Counter[]> = new BehaviorSubject<Counter[]>([]);

    /**
     * An observable stream that is produced based on the search filter provided in the counter input
     * form control. The result of this stream is then displayed in the auto-complete component.
     */
    public filteredCounters: Observable<SupportedMeterTypeByCategory[] | Counter[]>;

    /**
     * An observable stream that is produced based on the search filter provided in the format input
     * form control. The result of this stream is then displayed in the auto-complete component.
     */
    public filteredFormats: Observable<PageCountType[]>;

    private _onChange: (v: any) => void = () => {};
    private _onTouched: () => void = () => {};

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _cd: ChangeDetectorRef,
        private _formBuilder: FormBuilder
    ) {
        // Set up subscriptions for the counter and format auto-complete components.
        this.filteredCounters = this.counterInputFormControl().valueChanges.pipe(
            startWith(''),
            debounceTime(350),
            map(search =>
                search
                    ? this._filterCounters(search)
                    : this.usingAllSupportedMeterTypes
                    ? this.allCounterOptions.slice()
                    : this.deviceCounterOptions.slice()
            )
        );
        this.filteredFormats = this.formatInputFormControl().valueChanges.pipe(
            startWith(''),
            debounceTime(50),
            map(search => (search ? this._filterFormats(search) : this.allFormatOptions.slice()))
        );
    }

    ngOnInit(): void {
        // If we didn't get any device counter options (from the current meter read) then show all
        // possible counter options that we could support.
        if (this.deviceCounterOptions.length > 0) {
            // Otherwise set the counter options equal to the counter options we found on the device's
            // current meter read.
            this.usingAllSupportedMeterTypes = false;
        }

        // After we've initialized everything, make our changes reflected in the view.
        this.reloadCounters();
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public writeValue(obj: any): void {
        this._initializeCounters(obj);
        this.reloadCounters(true);
    }

    /**
     * Initialize the incoming billing preferences and convert the billing preferences data structure
     * into something this component understands and can work with.
     * @param {BillingPreferencesCounters} billingMeterPreferences  preferences used to set the state of the control
     * @private
     * @return {void}
     */
    private _initializeCounters(billingMeterPreferences: BillingPreferencesCounters): void {
        // Reset formats and counters so externally assigned values aren't continually appended to
        // the original value instead of overwriting it.
        this.formats = [];
        this.counters = new Map<string, Counter>();
        const formats = BillingPreferences.prioritizedFormats(billingMeterPreferences ?? {});
        for (const key of Object.keys(billingMeterPreferences ?? {})) {
            const counter = billingMeterPreferences[key];
            const c: Counter = {
                key,
                displayName: counter.displayName,
                canonNumberedMeters: this.canonMeterMapping.getCanonMetersForFormats(
                    formats.map(f => PageCountType.fromString(f)),
                    key
                )
            };
            if (counter.offset?.value != null) {
                c.offset = +counter.offset.value;
            }
            this.counters.set(key, c);
        }
        for (const format of formats) {
            this.formats.push(PageCountType.fromString(format));
        }
    }

    /**
     * Uses the provided value to filter the counters. If we're using device-specific counters, then
     * the filter will only be applied to the device, otherwise we'll filter through all supported
     * meter types.
     * @param {string} value - The search term used to filter.
     * @return {SupportedMeterTypeByCategory[] | Counter[]} - If usingAllSupportedMeterTypes is true
     * then we return an array of SupportedMeterTypeByCategory which are essentially just meter types
     * broken down by category. Otherwise, an array of Counter is returned.
     * @private
     */
    private _filterCounters(value: string): SupportedMeterTypeByCategory[] | Counter[] {
        if (this.usingAllSupportedMeterTypes) {
            // For some reason I couldn't map/filter/sort the allCounterOptions variable here even
            // after copying it. I can only assume that I wasn't deep copying far enough. In any case
            // this method of constructing a new array and pushing to it appears to deep copy the
            // counter options array.
            const out: SupportedMeterTypeByCategory[] = [];
            for (const category of this.allCounterOptions.slice()) {
                out.push({
                    ...category,
                    meterTypes: category.meterTypes
                        .slice()
                        .filter(c => c.name.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                        .sort((a, b) => (a.name.length < b.name.length ? -1 : 1))
                });
            }
            return out.filter(category => category.meterTypes.length > 0);
        } else {
            return this.deviceCounterOptions
                .filter(
                    counter => counter.displayName.toLowerCase().indexOf(value.toLowerCase()) >= 0
                )
                .sort((a, b) => (a.displayName.length < b.displayName.length ? -1 : 1));
        }
    }

    /**
     * Uses the provided value to filter the formats.
     * @param {string} value - The search term used to filter.
     * @returns {PageCountType[]} - An array of formats.
     * @private
     */
    private _filterFormats(value: string): PageCountType[] {
        return this.allFormatOptions
            .filter(
                format =>
                    PageCountType.toDisplayString(format)
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) === 0
            )
            .sort((a, b) =>
                PageCountType.toDisplayString(a).length < PageCountType.toDisplayString(b).length
                    ? -1
                    : 1
            );
    }

    /**
     * A helper function for getting a hold of the format input form control.
     * @returns {AbstractControl} - the form control.
     */
    public formatInputFormControl(): AbstractControl {
        return this.formGroup.get('formatInput');
    }

    /**
     * A helper function for getting a hold of the counter input form control.
     * @returns {AbstractControl} - the form control.
     */
    public counterInputFormControl(): AbstractControl {
        return this.formGroup.get('counterInput');
    }

    /**
     * Reads the formats and counters from the current state of the component and sends those formats
     * and counters down the observable streams to be displayed by the view.
     * @returns {void}
     * @param {boolean} external  optional; if true, the instance won't run it's onChange function
     *                            to prevent a feedback loop.
     */
    public reloadCounters(external?: boolean): void {
        this.formats$.next(this.formats);
        this.counters$.next([...this.counters.values()]);
        this.updateBillingPreferencesCounters(external);
    }

    /**
     * Adds the provided format to the billing preferences if it doesn't already exist. If it does,
     * this operation is a no-op.
     * @param {PageCountType} pageCountType - A page count format.
     * @returns {void}
     */
    public addFormat(pageCountType: PageCountType): void {
        this.formatInputFormControl().setValue('');
        if (this.formats.indexOf(pageCountType) >= 0) {
            return;
        }
        this.formats.push(pageCountType);
        this.reevaluateCanonNumberedMeters();
        this.reloadCounters();
    }

    /**
     * Removes the provided format from the billing preferences if it already exists. If it doesn't,
     * this operation is a no-op.
     * @param {PageCountType} pageCountType - A page count format.
     * @returns {void}
     */
    public removeFormat(pageCountType: PageCountType): void {
        const index = this.formats.indexOf(pageCountType);
        if (index < 0) {
            return;
        }
        this.formats.splice(index, 1);
        this.reevaluateCanonNumberedMeters();
        this.reloadCounters();
    }

    /**
     * Removes the provided counter from the billing preferences. If the counter existed and was deleted
     * then the counters are reloaded into the view.
     * @param {string} key - the counter key, i.e. 'totalBlack'
     * @returns {void}
     */
    public removeCounter(key: string): void {
        const deleted = this.counters.delete(key);
        if (deleted) {
            this.reloadCounters();
        }
    }

    /**
     * Adds the provided counter to the billing preferences. If the counter did not exist and was
     * successfully added, then the counters are reloaded into the view.
     * @param {Counter} counter - the counter object that should be added to the billing preferences.
     * @returns {void}
     */
    public addCounter(counter: Counter): void {
        this.counterInputFormControl().setValue('');
        const ca = this.canonMeterMapping.getCanonMetersForFormats(
            [...this.formats.values()],
            counter.key
        );
        this.counters.set(counter.key, {
            ...counter,
            canonNumberedMeters: ca
        });
        this.reloadCounters();
    }

    /**
     * Iterates through all of the counters in the view, then iterates through all the formats in
     * the view and re-configures the Canon numbered meter mappings based on the formats that are
     * currently selected, for each counter. This function ensures that the Canon numbered meters
     * that are assigned to each counter chronologically match the order of the formats array.
     * @returns {void}
     */
    public reevaluateCanonNumberedMeters(): void {
        this.counters.forEach(counter => {
            counter.canonNumberedMeters = this.canonMeterMapping.getCanonMetersForFormats(
                this.formats,
                counter.key
            );
        });
    }

    /**
     * Converts all of the internal data structures of this component back into a billing preferences
     * object. The magic of this function which is the biggest adjustment to the previous iteration of
     * billing preference configuration is that formats and counters are picked independently of each
     * other rather than picking formats and counters for each preference.
     *
     * This function is then responsible for melding the two together. It works by creating a preference
     * for every counter and format combination. In essence we perform a cartesian product between the
     * formats and the counters to produce the billing preference. For example, given this data:
     *
     *  formats: [equiv, life]
     *  counters: [total, totalBlack, totalColor]
     *
     * This function would produce the following billing preferences:
     *
     * @example
     * {
     *     total: {
     *         preferences: [
     *             {format: 'equiv', meter: 'total'},
     *             {format: 'life', meter: 'total'},
     *         ]
     *     },
     *     totalBlack: {
     *         preferences: [
     *             {format: 'equiv', meter: 'totalBlack'},
     *             {format: 'life', meter: 'totalBlack'},
     *         ]
     *     },
     *     totalColor: {
     *         preferences: [
     *             {format: 'equiv', meter: 'totalColor'},
     *             {format: 'life', meter: 'totalColor'},
     *         ]
     *     }
     * }
     * @returns {void}
     * @param {boolean} external  optional; if true, this instance's onChanged will not be called.
     */
    public updateBillingPreferencesCounters(external?: boolean): void {
        const counters: BillingPreferencesCounters = {};
        [...this.counters.entries()].forEach(([k, v]) => {
            const preference: IBillingMeterPreference = {
                displayName: v.displayName,
                preferences: this.formats.map(f => ({
                    format: PageCountType.toDbString(f),
                    meter: k
                }))
            };
            if (v.offset != null) {
                preference.offset = { value: +v.offset };
            }
            counters[k] = new BillingMeterPreference(preference);
        });
        if (!external) {
            this._onChange({ ...counters });
        }
        this.billingPreferencesCountersUpdated.emit(counters);
    }

    /**
     * Determines the numbered Canon meters that should be used based on the provided counter and the
     * current format preference order and constructs a comma-separated string of Canon numbered meters.
     * @param {string} counter - the counter key, i.e. 'totalBlack'
     * @return {string} - a comma-separated string of Canon numbered meters.
     */
    public getPrintableCanonMeters(counter: string): string {
        let str = '';
        const meters = this.canonMeterMapping.getCanonMetersForFormats(this.formats, counter);
        if (!meters) {
            return;
        }
        for (let i = 0; i < meters.length; i++) {
            // if we're on the last canon meter
            if (i === meters.length - 1) {
                str = str + meters[i];
            } else {
                str = str + meters[i] + ', ';
            }
        }
        return str;
    }
}
