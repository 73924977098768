import { Injectable } from '@angular/core';
import {
    HttpService,
    Post,
    Body,
    MapClass,
    Get,
    Query,
    Adapter,
    Path,
    Delete
} from '@libs/web/http';
import { combineLatest, Observable, of } from 'rxjs';
import { PaginatedList, QueryEngineReportType, Report, ReportSharingMode, ReportWithMetadata } from '@libs/iso/core';
import { GlobalStore } from '@app/state/store';
import { select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { fromEntity, fromUser } from '@app/state/selectors';
import { catchError, switchMap, take } from 'rxjs/operators';
import { NotificationService } from '@app/core/services/notification.service';

@Injectable()
export class ReportService extends HttpService {
    constructor(
        protected _http: HttpClient,
        private _store: Store<GlobalStore>,
        private _notificationService: NotificationService
    ) {
        super(_http);
    }

    @Get('/report/list')
    @Adapter(
        (res): PaginatedList<ReportWithMetadata> => {
            const list = new PaginatedList<ReportWithMetadata>();
            const docs = Array.isArray(res.docs) ? res.docs : [];
            list.docs = docs.map(d => new ReportWithMetadata(d));
            list.totalDocs = res.totalDocs || 0;
            return list;
        }
    )
    @Adapter(ReportWithMetadata)
    public getReportList(
        @Query('entityKey') entityKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean
    ): Observable<PaginatedList<ReportWithMetadata>> {
        return null;
    }

    @Post('/report/{id}/update')
    private _updateReport(
        @Path('id') id: string,
        @Body('name') name: string,
        @Body('description') description: string,
        @Body('query') query: string,
        @Body('type') type: QueryEngineReportType,
        @Body('includeChildren') includeChildren: boolean,
        @Body('sharingMode') sharingMode: ReportSharingMode
    ): Observable<void> {
        return null;
    }

    public updateReport(report: Report): Observable<void> {
        return this._updateReport(
            <string>report._id,
            report.name,
            report.description,
            report.query,
            report.type,
            report.includeChildren,
            report.sharingMode,
        ).pipe(
            catchError(err => {
                console.log(err);
                this._notificationService.error(err.statusText);
                return of(err);
            })
        );
    }

    @Post('/report/create')
    private _create(
        @Body('entityKey') entityKey: string,
        @Body('userKey') userKey: string,
        @Body('name') name: string,
        @Body('description') description: string,
        @Body('query') query: string,
        @Body('type') type: QueryEngineReportType,
        @Body('includeChildren') includeChildren: boolean
    ): Observable<void> {
        return null;
    }

    public create(report: Report): Observable<void> {
        return combineLatest([
            this._store.pipe(select(fromEntity.entityId), take(1)),
            this._store.pipe(select(fromUser.userId), take(1))
        ]).pipe(
            switchMap(([entityId, userId]) =>
                this._create(
                    <string>report.entityKey ?? entityId,
                    <string>report.userKey ?? userId,
                    report.name,
                    report.description,
                    report.query,
                    report.type,
                    report.includeChildren
                )
            )
        );
    }

    @Delete('/report/{id}')
    public delete(@Path('id') id: string): Observable<void> {
        return null;
    }
}
