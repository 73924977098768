<form [formGroup]="roleForm" (ngSubmit)="saveRoles()">
    <ptkr-field-group label="Roles" i18n-label required="true">
        <mat-form-field appearance="outline" floatLabel="never">
            <mat-select formControlName="roleKeys" multiple>
                <mat-option *ngFor="let r of availableRoles" [value]="r._id">
                    {{ r.name }}
                </mat-option>
            </mat-select>
            <mat-error [ptkrErrorControl]="roleForm.get('roleKeys')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="Parent entity" i18n-label>
        <ptkr-entity-picker [defaultEntity]="userEntity" (entity)="onEntityChange($event)"></ptkr-entity-picker>
    </ptkr-field-group>
    <hr>
    <div class="action-buttons">
        <button mat-flat-button color="accent">{{ buttonLabel }}</button>
    </div>
</form>
