<mat-form-field appearance="outline" class="mat-form-field">
    <mat-select [value]="currentLanguage" (selectionChange)="changeLanguage($event.value)">
        <mat-option 
        *ngFor="let language of availableLanguages" 
        [value]="language.code"
        >
            {{ language.name }}
        </mat-option>
    </mat-select>
</mat-form-field>
