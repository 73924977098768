import { createFeatureSelector } from '@ngrx/store';
import { AppStore, initAppState } from './app.store';
import { AuthStore, initAuthState } from './auth.store';
import { UserStore, initUserState } from './user.store';
import { EntityStore, initEntityState } from './entity.store';
import { EntitiesStore, initEntitiesState } from './entities.store';
import { CartStore, initCartState } from './cart.store';

export interface GlobalStore {
    app: AppStore;
    auth: AuthStore;
    cart: CartStore;
    user: UserStore;
    entity: EntityStore;
    entities: EntitiesStore;
}

export const initGlobalState: GlobalStore = {
    app: initAppState,
    auth: initAuthState,
    cart: initCartState,
    user: initUserState,
    entity: initEntityState,
    entities: initEntitiesState
};

export const selectApp = createFeatureSelector<AppStore>('app');
export const selectAuth = createFeatureSelector<AuthStore>('auth');
export const selectCart = createFeatureSelector<CartStore>('cart');
export const selectUser = createFeatureSelector<UserStore>('user');
export const selectEntity = createFeatureSelector<EntityStore>('entity');
export const selectEntities = createFeatureSelector<EntitiesStore>('entities');
