<h1 i18n>Edit scheduled report</h1>
<div class="form">
    <form [formGroup]="form">
        <div>
            <div class="label">
                <mat-label i18n="@@entity">Entity</mat-label>
                <a matTooltip="The report will contain data from this entity and its children (if include children is enabled in the report)" i18n-matTooltip>
                    <small i18n="@@whatsThis">What's this?</small>
                </a>
            </div>
            <ptkr-entity-picker
                [defaultEntity]="{id: dialogData.schedule?.entityKey, name: dialogData.schedule?.entityName}"
                (entity)="onEntityChange($event)"
            >
                <mat-error mat-error *ngIf="form.get('entityKey').hasError('required')" i18n>
                    Entity is <strong>required</strong>
                </mat-error>
            </ptkr-entity-picker>
        </div>
        <div class="m-b-20">
            <div class="label">
                <mat-label i18n="@@enabled">Enabled</mat-label>
                <a matTooltip="You can optionally disable this scheduled report from being exported without deleting the report" i18n-matTooltip>
                    <small i18n="@@whatsThis">What's this?</small>
                </a>
            </div>
            <mat-slide-toggle formControlName="enabled"></mat-slide-toggle>
        </div>
        <div class="m-b-20"> <!--for some reason the cron editor formats differently, add explicit spacing here-->
            <div class="label">
                <mat-label i18n>Report schedule</mat-label>
                <a matTooltip="This is the scheduled used to determine when this report will be sent" i18n-matTooltip>
                    <small i18n="@@whatsThis">What's this?</small>
                </a>
            </div>
            <ptkr-cron-editor [options]="cronOptions" [cron]="form.get('schedule').value" (cronChange)="onCronChange($event)"></ptkr-cron-editor>
            <mat-error *ngIf="form.get('schedule').hasError('required')" i18n>
                Schedule is <strong>required</strong>
            </mat-error>
        </div>
        <div class="m-b-20">
            <div class="label">
                <mat-label i18n>Timezone</mat-label>
                <a matTooltip="This is the scheduled used to determine when this report will be sent" i18n-matTooltip>
                    <small i18n="@@whatsThis">What's this?</small>
                </a>
            </div>
            <mat-form-field appearance="outline">
                <mat-select formControlName="timezone">
                    <mat-option *ngFor="let timezone of timezoneOptions" [value]="timezone">
                        {{timezone}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error *ngIf="form.get('timezone').hasError('required')" i18n>
                Timezone is <strong>required</strong>
            </mat-error>
        </div>
        <div>
            <div class="label" style="margin-bottom: 20px !important;">
                <mat-label i18n>Export options</mat-label>
                <a matTooltip="">
                    <small i18n="@@whatsThis">What's this?</small>
                </a>
            </div>
            <div>
                <div class="label">
                    <mat-label i18n="@@separator">Separator</mat-label>
                    <a matTooltip="Choose the separator that should be used in the generated report (comma, semicolon, tab, etc...)" i18n-matTooltip>
                        <small i18n="@@whatsThis">What's this?</small>
                    </a>
                </div>

                <!-- Select box with options from CsvSeparators.all -->
                <mat-form-field appearance="outline">
                    <ptkr-export-separator-select formControlName="exportDelimiter"></ptkr-export-separator-select>
                </mat-form-field>
            </div>
            <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title i18n="@@email">Email</mat-panel-title>
                        <mat-panel-description i18n>
                            Receive reports via emailed attachments
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <ptkr-email-array-chip-input formControlName="exportEmailAddresses">
                            <mat-error mat-error *ngIf="form.get('exportEmailAddresses').hasError('required')" i18n>
                                At least one email address is <strong>required</strong>
                            </mat-error>
                        </ptkr-email-array-chip-input>
                        <div class="m-b-25">
                            <ptkr-banner [noActions]="true">
                                <span body i18n>The email subject and body supports template variables which allow you to dynamically customize the emails based on when or who they're being sent to.</span>
                            </ptkr-banner>
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label i18n>Subject</mat-label>
                            <input formControlName="exportEmailPersonalizeSubject" matInput placeholder="Customize the subject of the email" i18n-placeholder>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label i18n>Body</mat-label>
                            <textarea formControlName="exportEmailPersonalizeBody" matInput placeholder="Customize the body of the email" i18n-placeholder></textarea>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </form>
    <div class="actions">
        <button mat-flat-button color="accent" (click)="dialogRef.close(mergeWithForm())" [disabled]="!form.valid" i18n="@@save">Save</button>
        <button mat-button (click)="dialogRef.close(null)" i18n="@@cancel">Cancel</button>
    </div>
</div>
