<form [formGroup]="addShippingAddressForm" (ngSubmit)="saveShippingAddressForm(addShippingAddressForm)">
    <ptkr-field-group label="Company Name" required="true">
        <mat-form-field floatLabel="never" appearance="outline">
            <input matInput formControlName="companyName">
            <mat-error [ptkrErrorControl]="addShippingAddressForm.get('companyName')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>
    <ptkr-field-group label="ATTN" required="true">
        <mat-form-field floatLabel="never" appearance="outline">
            <input matInput formControlName="attn">
            <mat-error [ptkrErrorControl]="addShippingAddressForm.get('attn')"></mat-error>
        </mat-form-field>
    </ptkr-field-group>

    <ptkr-address-form #addressForm></ptkr-address-form>

    <div class="actions">
        <span class="spacer"></span>
        <div class="button-container">
            <div class="button-wrapper">
                <button mat-flat-button color="accent">Add address</button>
            </div>
        </div>
    </div>
</form>
