import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Chip } from '@app/shared/components/chips/chips.component';

@Component({
    selector: 'ptkr-chip-dialog',
    templateUrl: './chip-dialog.component.html',
    styleUrls: ['./chip-dialog.component.scss']
})
export class ChipDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ChipDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Chip
    ) {
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

}
