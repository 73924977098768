import { ISupplyMeterReadType, SupplyMeterReadType } from './MeterReadSupplyType';
import { Column, colKeyOneProp } from '../../helpers/columns';
import { MeterReadValue } from './MeterReadValue';

export interface IMeterReadSupply {
    [type: string]: ISupplyMeterReadType;
}

export class MeterReadSupply implements IMeterReadSupply {
    public static columns: Array<Column> = Column.extrapolate(
        Column.extrapolate(
            [
                {
                    name: '',
                    keys: colKeyOneProp(''),
                    description:
                        'Represents one of several metrics used to measure the supply level of a' +
                        'device. Note that not all devices contain every kind of supply.',
                    display: Column.displayFunctions['tensPlace'],
                    sortable: false
                }
            ],
            [
                {
                    override: {
                        name: 'Percent Remaining',
                        keys: colKeyOneProp('pctRemaining.value')
                    }
                },
                {
                    override: {
                        name: 'Serial Number',
                        keys: colKeyOneProp('serialNumber.value')
                    }
                },
                {
                    override: {
                        name: 'Level Remaining',
                        keys: colKeyOneProp('levelRemaining.value')
                    }
                },
                {
                    override: {
                        name: 'Current Level',
                        keys: colKeyOneProp('currentLevel.value')
                    }
                },
                {
                    override: {
                        name: 'Expected Yield',
                        keys: colKeyOneProp('expectedYield.value')
                    }
                }
            ]
        ),
        [
            {
                namePrefix: 'Black Toner -',
                keyPrefix: 'blackToner'
            },
            {
                namePrefix: 'Cyan Toner -',
                keyPrefix: 'cyanToner'
            },
            {
                namePrefix: 'Magenta Toner -',
                keyPrefix: 'magentaToner'
            },
            {
                namePrefix: 'Yellow Toner -',
                keyPrefix: 'yellowToner'
            },
            {
                namePrefix: 'Black Ink -',
                keyPrefix: 'blackInk'
            },
            {
                namePrefix: 'Cyan Ink -',
                keyPrefix: 'cyanInk'
            },
            {
                namePrefix: 'Magenta Ink -',
                keyPrefix: 'magentaInk'
            },
            {
                namePrefix: 'Yellow Ink -',
                keyPrefix: 'keyPrefix'
            }
        ]
    );

    [type: string]: ISupplyMeterReadType;

    constructor(defaults?: Partial<IMeterReadSupply>) {
        if (defaults) {
            for (const type in defaults) {
                this[type] = new SupplyMeterReadType(defaults[type]);
            }
        }
    }
}
