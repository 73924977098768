export enum ExportDelimiter {
    Comma = ',',
    Semicolon = ';',
    Tab = '\t'
}

export namespace ExportDelimiter {
    export const options = [
        ExportDelimiter.Comma,
        ExportDelimiter.Semicolon,
        ExportDelimiter.Tab
    ];

    export function toDisplayName(separator: ExportDelimiter): string {
        switch (separator) {
            case ExportDelimiter.Comma:
                return $localize`Comma (,)`;
            case ExportDelimiter.Semicolon:
                return $localize`Semicolon (;)`;
            case ExportDelimiter.Tab:
                return $localize`Tab`;
            default:
                return '';
        }
    }

    export function toString(separator: ExportDelimiter): string {
        switch (separator) {
            case ExportDelimiter.Comma:
                return ',';
            case ExportDelimiter.Semicolon:
                return ';';
            case ExportDelimiter.Tab:
                return '\t';
            default:
                return '';
        }
    }

    export function fromString(str: string): ExportDelimiter | null {
        switch (str) {
            case ',':
                return ExportDelimiter.Comma;
            case ';':
                return ExportDelimiter.Semicolon;
            case '\t':
                return ExportDelimiter.Tab;
            default:
                return null;
        }
    }
}
