import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ptkr-named-mat-divider',
    templateUrl: './named-mat-divider.component.html',
    styleUrls: ['./named-mat-divider.component.scss']
})
export class NamedMatDividerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
