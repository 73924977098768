export enum QueryEngineSchemaColumnType {
    Unknown = 'UNKNOWN',
    Text = 'TEXT',
    Datetime = 'datetime',
    Boolean = 'boolean',
    Integer = 'INTEGER',
    Varchar = 'varchar',
    Numeric = 'numeric',
    Real = 'REAL',
}

export namespace QueryEngineSchemaColumnType {
    export function fromString(str: string): QueryEngineSchemaColumnType {
        switch (str.toLowerCase()) {
            case 'text':
                return QueryEngineSchemaColumnType.Text;
            case 'datetime':
            case 'timestamp':
                return QueryEngineSchemaColumnType.Datetime;
            case 'boolean':
            case 'bool':
                return QueryEngineSchemaColumnType.Boolean;
            case 'integer':
                return QueryEngineSchemaColumnType.Integer;
            case 'numeric':
                return QueryEngineSchemaColumnType.Numeric;
            case 'real':
                return QueryEngineSchemaColumnType.Real;
            default:
                if (str.toLowerCase().startsWith('varchar')) {
                    return QueryEngineSchemaColumnType.Varchar;
                }
                throw new Error(`Unknown column type: ${str}`);
        }
    }

    export function toDisplay(type: QueryEngineSchemaColumnType): string {
        switch (type) {
            case QueryEngineSchemaColumnType.Text:
            case QueryEngineSchemaColumnType.Varchar:
                return 'Text';
            case QueryEngineSchemaColumnType.Datetime:
                return 'Datetime';
            case QueryEngineSchemaColumnType.Boolean:
                return 'Boolean';
            case QueryEngineSchemaColumnType.Integer:
            case QueryEngineSchemaColumnType.Numeric:
            case QueryEngineSchemaColumnType.Real:
                return 'Number';
            default:
                return 'Unknown';
        }
    }
}
