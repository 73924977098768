import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'ptkr-overwritable-string-array',
    templateUrl: './overwritable-string-array.component.html',
    styleUrls: [ './overwritable-string-array.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: OverwritableStringArrayComponent,
        multi: true
    }]
})
export class OverwritableStringArrayComponent implements ControlValueAccessor {
    @Input() public inheritedStrings: string[];
    @Input() public isOverwriting: boolean;
    @Input() public notifications: any;
    @Input() public inputElementType: string = 'text';

    @Output() public overwrite: EventEmitter<void> = new EventEmitter<void>();

    public disabled: boolean;
    public value: string[];

    public separatorKeysCodes: any = [ENTER, COMMA, SEMICOLON, TAB];

    private _onChange: any = () => {};
    private _onTouched: any = () => {};

    constructor() {
    }

    public writeValue(obj: unknown): void {
        // Every time it is updated, a new array is recorded. This makes change detection easy as
        // well as making the inheritance reset button work more effectively, as this update will
        // make the controller 'dirty'.
        if (!!obj && Array.isArray(obj)) {
            this.value = [...(obj as any[])];
        } else {
            this.value = undefined;
        }
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public addChip(event: any): void {
        const element = event.input;
        const value = event.value;

        if (event.input.validity.valid) {
            if (value.trim() !== '') {
                this.writeValue([...(this.value ?? []), event.value]);
                this._onChange(this.value);
                element.value = '';
            }
        } else {
            this.notifications.next({ type: 'error', message: 'Please enter a valid string' });
        }
    }

    public removeChip(event: string): void {
        if (!this.value) {
            this.writeValue([]);
            this._onChange([]);
        }
        const i = this.value.findIndex(
            (v: string): boolean => event === v);
        if (i !== -1) {
            this.value.splice(i, 1);
            this.writeValue([...this.value]);
            this._onChange(this.value);
        } else {
            this.writeValue([...this.value]);
            this._onChange(this.value);
        }
        this.touch();
    }

    public touch(): void {
        this._onTouched();
    }
}
