import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ptkr-id-quick-copy-button',
    templateUrl: './id-quick-copy-button.component.html',
    styleUrls: ['./id-quick-copy-button.component.scss']
})
export class IdQuickCopyButtonComponent {
    @Input() public prefix: string;
    @Input() public value: string;
    @Input() public display: string;

    constructor(private _cd: ChangeDetectorRef) {}

    public copy(value: string): void {
        if (value !== undefined && value !== null) {
            this._copy(value);
            const actualDisplay = this.display;
            this.display = 'Copied';
            setTimeout(() => {
                this.display = actualDisplay;
                this._cd.detectChanges();
            }, 2000);
        }
    }

    public showPrefix(): boolean {
        return this.prefix.length > 0;
    }

    private _copy(value: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
