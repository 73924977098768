import { IBaseModel, BaseModel } from '@libs/iso/core/models/BaseModel';
import { ObjectID } from 'bson';
import { CartStatus } from '@libs/iso/core/enums';
import { CartItem } from '@libs/iso/core/models/cart/CartItem';
import { CartShipment } from '@libs/iso/core/models/cart/CartShipment';
import { ChargeAuthorization } from '@libs/iso/core/models/cart/ChargeAuthorization';
import { Moment } from 'moment';

export interface ICart extends IBaseModel {
    acuId: string;
    entityKey: ObjectID | string;
    entityName?: string;
    genealogy?: string[];
    userKey: ObjectID | string;
    createdByEntityKey: ObjectID | string;
    salesRepKey: ObjectID | string;
    cartStatus: CartStatus;
    invoiceToken: string;
    subtotal: number;
    salesTax: number;
    payShipping: boolean;
    shipping: number;
    totalPrice: number;
    saleDate: Date | Moment;
    PONumber: string;
    paymentMethod: any; // don't know {}?
    items: CartItem[];
    shipments: CartShipment[];
    isWarranty: boolean;
    stripeChargeAuthorizations: ChargeAuthorization[];
}

export class Cart extends BaseModel implements ICart {
    public acuId: string;
    public entityKey: ObjectID | string;
    public entityName: string;
    public genealogy: string[];
    public userKey: ObjectID | string;
    public createdByEntityKey: ObjectID | string;
    public salesRepKey: ObjectID | string;
    public cartStatus: CartStatus = CartStatus.Active;
    public invoiceToken: string;
    public subtotal: number;
    public salesTax: number;
    public payShipping: boolean;
    public shipping: number;
    public totalPrice: number;
    public saleDate: Date | Moment;
    public PONumber: string;
    public paymentMethod: any; // update later
    public items: CartItem[] = [];
    public shipments: CartShipment[] = [];
    public isWarranty: boolean = false;
    public stripeChargeAuthorizations: ChargeAuthorization[] = [];

    constructor(defaults?: Partial<ICart>) {
        super(defaults);
        if (defaults) {
            this.acuId = defaults.acuId || this.acuId;
            this.entityKey = defaults.entityKey || this.entityKey;
            this.userKey = defaults.userKey || this.userKey;
            this.createdByEntityKey = defaults.createdByEntityKey || this.createdByEntityKey;
            this.salesRepKey = defaults.salesRepKey || this.salesRepKey;
            this.cartStatus = defaults.cartStatus || this.cartStatus;
            this.invoiceToken = defaults.invoiceToken || this.invoiceToken;
            this.subtotal = defaults.subtotal || this.subtotal;
            this.salesTax = defaults.salesTax || this.salesTax;
            this.payShipping = defaults.payShipping || this.payShipping;
            this.shipping = defaults.shipping || this.shipping;
            this.totalPrice = defaults.totalPrice || this.totalPrice;
            this.saleDate = defaults.saleDate || this.saleDate;
            this.PONumber = defaults.PONumber || this.PONumber;
            this.paymentMethod = defaults.paymentMethod || this.paymentMethod;
            this.isWarranty = defaults.isWarranty || this.isWarranty;
            if (Array.isArray(defaults.items)) {
                for (const i of defaults.items) {
                    this.items.push(new CartItem(i));
                }
            }
            if (Array.isArray(defaults.shipments)) {
                for (const s of defaults.shipments) {
                    this.shipments.push(new CartShipment(s));
                }
            }
            this.entityName = defaults.entityName || this.entityName;
            if (Array.isArray(defaults.genealogy)) {
                this.genealogy = defaults.genealogy;
            }
            this.stripeChargeAuthorizations =
                defaults.stripeChargeAuthorizations || this.stripeChargeAuthorizations;
        }
    }

    public toDB(): Object {
        const items = [];
        for (const item of this.items) {
            items.push(item.toDB());
        }
        return {
            acuId: this.acuId,
            entityKey: new ObjectID(this.entityKey),
            userKey: new ObjectID(this.userKey),
            createdByEntityKey: new ObjectID(this.createdByEntityKey),
            salesRepKey: new ObjectID(this.salesRepKey),
            cartStatus: this.cartStatus,
            invoiceToken: this.invoiceToken,
            subtotal: this.subtotal,
            salesTax: this.salesTax,
            payShipping: this.payShipping,
            shipping: this.shipping,
            totalPrice: this.totalPrice,
            saleDate: this.saleDate,
            PONumber: this.PONumber,
            paymentMethod: this.paymentMethod,
            items: items,
            shipments: this.shipments,
            isWarranty: this.isWarranty,
            stripeChargeAuthorizations: this.stripeChargeAuthorizations
        };
    }
}
