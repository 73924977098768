<div mat-dialog-content>
    <mat-horizontal-stepper
        [linear]="true"
        #stepper
        (selectionChange)="selectionChange($event)"
        *ngIf="(windowSize$ | async) !== WindowSize.Mobile"
    >
        <mat-step [completed]="steps[0].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>Select Items</ng-template>
            <ptkr-entity-items-step [items]="itemsToUpdate" #itemsStep></ptkr-entity-items-step>
        </mat-step>
        <mat-step [completed]="steps[1].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>Select Entities</ng-template>
            <ptkr-tree-step #treeStep [treeNode]="treeNode$ | async"></ptkr-tree-step>
        </mat-step>
        <mat-step [completed]="steps[2].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>Confirm</ng-template>
            <ptkr-confirm-step #confirmStep></ptkr-confirm-step>
        </mat-step>
        <mat-step [completed]="steps[3].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>Applying Changes</ng-template>
            <ptkr-apply-changes-step></ptkr-apply-changes-step>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <div class="complete" *ngIf="changesSuccessful">
                <h2>Changes Applied</h2>
                <span> <i class="far fa-check-circle"></i> </span>
            </div>
            <div class="error" *ngIf="!changesSuccessful">
                <h2>Changes Unsuccessful</h2>
                <span> <i class="fas fa-exclamation-triangle"></i> </span>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
    <mat-vertical-stepper
        [linear]="true"
        #stepper
        (selectionChange)="selectionChange($event)"
        *ngIf="(windowSize$ | async) === WindowSize.Mobile"
    >
        <mat-step [completed]="steps[0].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>Select Items</ng-template>
            <ptkr-entity-items-step [items]="itemsToUpdate" #itemsStep></ptkr-entity-items-step>
        </mat-step>
        <mat-step [completed]="steps[1].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>Select Entities</ng-template>
            <ptkr-tree-step #treeStep [treeNode]="treeNode$ | async"></ptkr-tree-step>
        </mat-step>
        <mat-step [completed]="steps[2].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>Confirm</ng-template>
            <ptkr-confirm-step #confirmStep></ptkr-confirm-step>
        </mat-step>
        <mat-step [completed]="steps[3].isComplete" [editable]="canEdit()">
            <ng-template matStepLabel>Applying Changes</ng-template>
            <ptkr-apply-changes-step></ptkr-apply-changes-step>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <div class="complete" *ngIf="changesSuccessful">
                <h2>Changes Applied</h2>
                <span> <i class="far fa-check-circle"></i> </span>
            </div>
            <div class="complete" *ngIf="!changesSuccessful">
                <h2>Changes Unsuccessful</h2>
                <span class="error"> <i class="fas fa-exclamation-triangle"></i> </span>
            </div>
        </mat-step>
    </mat-vertical-stepper>
</div>
<div class="actions" mat-dialog-actions>
    <button
        *ngIf="!!steps[currentStep].cancelText"
        class="cancel"
        mat-flat-button
        (click)="steps[currentStep].cancel()"
    >
        {{ steps[currentStep].cancelText }}
    </button>
    <button
        *ngIf="!!steps[currentStep].nextText"
        mat-flat-button
        color="accent"
        (click)="steps[currentStep].next()"
    >
        {{ steps[currentStep].nextText }}
    </button>
</div>
