import {
    IReportScheduledExportEmail,
    ReportScheduledExportEmail
} from '@libs/iso/core/models/reportScheduled/ReportScheduledExportEmail';
import { ExportDelimiter } from '@libs/iso/core';

export interface IReportScheduledExport {
    email: IReportScheduledExportEmail;
    delimiter: ExportDelimiter;
}

export class ReportScheduledExport implements IReportScheduledExport {
    public email: IReportScheduledExportEmail = new ReportScheduledExportEmail();
    public delimiter: ExportDelimiter = ExportDelimiter.Comma;
    constructor(params?: Partial<IReportScheduledExport>) {
        if (params != null) {
            this.email = new ReportScheduledExportEmail(params.email) ?? this.email;
            this.delimiter = ExportDelimiter.fromString(params.delimiter) ?? this.delimiter;
        }
    }
}
