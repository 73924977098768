import { Action } from '@ngrx/store';
import { Entity } from '@app/models';

export enum EntityActions {
    SetEntity = '[entity] set entity',
    LoginEntity = '[entity] login entity',
    ClearEntity = '[entity] clear entity',
    RequestEntityChange = '[entity] request entity change',
    ChangeEntity = '[entity] change entity'
    // UpdateEntity = '[entity] update entity'
}

// Sets the currentEntity.
export class SetEntityAction implements Action {
    public readonly type: EntityActions.SetEntity = EntityActions.SetEntity;
    public payload: Entity = null;
    constructor(_entity: Entity) {
        // console.log(_entity);
        this.payload = _entity;
    }
}

export class LoginEntityAction implements Action {
    public readonly type: EntityActions.LoginEntity = EntityActions.LoginEntity;
    public payload: Entity = null;
    constructor(_entity: Entity) {
        this.payload = _entity;
    }
}

export class ClearEntityAction implements Action {
    public readonly type: EntityActions.ClearEntity = EntityActions.ClearEntity;
}

export class RequestEntityChangeAction implements Action {
    public readonly type: EntityActions.RequestEntityChange = EntityActions.RequestEntityChange;
    public payload: string = '';
    constructor(_entityId: string) {
        this.payload = _entityId;
    }
}

export class ChangeEntityAction implements Action {
    public readonly type: EntityActions.ChangeEntity = EntityActions.ChangeEntity;
    public payload: Entity = null;
    constructor(_entity: Entity) {
        this.payload = _entity;
    }
}

// Applies changes to the currentEntity.
// Not for updating the db, but just for updating currentEntity in the store.
// export class UpdateEntityAction implements Action {
//     public readonly type: EntityActions = EntityActions.UpdateEntity;
//     public payload: Entity | Partial<EntityModel> = null;
//     constructor(_entity: Entity | Partial<EntityModel>) {
//         this.payload = _entity;
//     }
// }

export type EntityActionsType =
    | SetEntityAction
    | ClearEntityAction
    | LoginEntityAction
    | RequestEntityChangeAction
    | ChangeEntityAction; // | UpdateEntityAction;
