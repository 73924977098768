enum Color {
    Black = 'BLACK',
    BlackColor = 'BLACK/COLOR',
    Blue = 'BLUE',
    ChromaOptimizer = 'CHROMAOPTIMIZER',
    Color = 'COLOR',
    Cyan = 'CYAN',
    DyeBlack = 'DYEBLACK',
    GlossOptimizer = 'GLOSSOPTIMIZER',
    Gray = 'GRAY',
    Green = 'GREEN',
    KCMY = 'KCMY',
    LightBlack = 'LIGHTBLACK',
    LightCyan = 'LIGHTCYAN',
    LightGray = 'LIGHTGRAY',
    LightLightBlack = 'LIGHTLIGHTBLACK',
    LightMagenta = 'LIGHTMAGENTA',
    Magenta = 'MAGENTA',
    MatteBlack = 'MATTEBLACK',
    NotApplicable = 'N/A',
    Orange = 'ORANGE',
    PHOTO = 'PHOTO',
    PhotoBlack = 'PHOTOBLACK',
    PhotoCOLOR = 'PHOTOCOLOR',
    PhotoCyan = 'PHOTOCYAN',
    PhotoMagenta = 'PHOTOMAGENTA',
    PigmentBlack = 'PIGMENTBLACK',
    Red = 'RED',
    VividLightMagenta = 'VIVIDLIGHTMAGENTA',
    VividMagenta = 'VIVIDMAGENTA',
    Yellow = 'YELLOW'
    // BlackColor = 'black/color',
    // Blue = 'blue',
    // ChromaOptimizer = 'chromaOptimizer',
    // Color = 'color',
    // Cyan = 'cyan',
    // DyeBlack = 'dyeBlack',
    // GlossOptimizer = 'gloassOptimizer',
    // Gray = 'gray',
    // Green = 'green',
    // KCMY = 'KCMY',
    // LightBlack = 'lightBlack',
    // LightCyan = 'lightCyan',
    // LightGray = 'lightGray',
    // LightLightBlack = 'lightlightBlack',
    // LightMagenta = 'lightMagenta',
    // Magenta = 'magenta',
    // MatteBlack = 'matteBlack',
    // NotApplicable = 'N/A',
    // Orange = 'orange',
    // PHOTO = 'PHOTO',
    // PhotoBlack = 'photoBlack',
    // PhotoCOLOR = 'photoCOLOR',
    // PhotoCyan = 'photoCyan',
    // PhotoMagenta = 'photoMagenta',
    // PigmentBlack = 'pigmentBlack',
    // Red = 'red',
    // VividLightMagenta = 'vividLightMagenta',
    // VividMagenta = 'vividMagenta',
    // Yellow = 'yellow'
}

namespace Color {
    export function toString(type: Color): string {
        switch (`${type}`.toUpperCase().trim()) {
            case Color.Black:
                return 'Black';
            case Color.BlackColor:
                return 'Black / Color';
            case Color.Blue:
                return 'Blue';
            case Color.ChromaOptimizer:
                return 'Chroma Optimizer';
            case Color.Color:
                return 'Color';
            case Color.Cyan:
                return 'Cyan';
            case Color.DyeBlack:
                return 'Dye Black';
            case Color.GlossOptimizer:
                return 'Gloss Optimizer';
            case Color.Gray:
                return 'Gray';
            case Color.Green:
                return 'Green';
            case Color.KCMY:
                return 'KCMY';
            case Color.LightBlack:
                return 'Light Black';
            case Color.LightCyan:
                return 'Light Cyan';
            case Color.LightGray:
                return 'Light Gray';
            case Color.LightLightBlack:
                return 'Light Light Black';
            case Color.LightMagenta:
                return 'Light Magenta';
            case Color.Magenta:
                return 'Magenta';
            case Color.MatteBlack:
                return 'Matte Black';
            case Color.NotApplicable:
                return 'N/A';
            case Color.Orange:
                return 'Orange';
            case Color.PHOTO:
                return 'PHOTO';
            case Color.PhotoBlack:
                return 'Photo Black';
            case Color.PhotoCOLOR:
                return 'Photo COLOR';
            case Color.PhotoCyan:
                return 'Photo Cyan';
            case Color.PhotoMagenta:
                return 'Photo Magenta';
            case Color.PigmentBlack:
                return 'Pigment Black';
            case Color.Red:
                return 'Red';
            case Color.VividLightMagenta:
                return 'Vivid Light Magenta';
            case Color.VividMagenta:
                return 'Vivid Magenta';
            case Color.Yellow:
                return 'Yellow';
            default:
                return 'N/A';
        }
    }

    export function bgColorHex(type: Color): string {
        switch (`${type}`.toUpperCase().trim()) {
            case Color.Black:
                return '#000000';
            case Color.BlackColor:
                return '#000000';
            case Color.Blue:
                return '#0000FF';
            case Color.ChromaOptimizer:
                return '';
            case Color.Color:
                return '';
            case Color.Cyan:
                return '#1f84c8';
            case Color.DyeBlack:
                return '#000000';
            case Color.GlossOptimizer:
                return '';
            case Color.Gray:
                return '#808080';
            case Color.Green:
                return '008000';
            case Color.KCMY:
                return '';
            case Color.LightBlack:
                return '#000000';
            case Color.LightCyan:
                return '#1f84c8';
            case Color.LightGray:
                return '#808080';
            case Color.LightLightBlack:
                return '#000000';
            case Color.LightMagenta:
                return '#ec5665';
            case Color.Magenta:
                return '#ec5665';
            case Color.MatteBlack:
                return '#000000';
            case Color.NotApplicable:
                return '';
            case Color.Orange:
                return '#FFA500';
            case Color.PHOTO:
                return '';
            case Color.PhotoBlack:
                return '#000000';
            case Color.PhotoCOLOR:
                return '';
            case Color.PhotoCyan:
                return '#1f84c8';
            case Color.PhotoMagenta:
                return '#ec5665';
            case Color.PigmentBlack:
                return '#000000';
            case Color.Red:
                return '#FF0000';
            case Color.VividLightMagenta:
                return '#ec5665';
            case Color.VividMagenta:
                return '#ec5665';
            case Color.Yellow:
                return '#f5ab5a';
            default:
                return '';
        }
    }

    export function textColorHex(type: Color): string {
        switch (`${type}`.toUpperCase().trim()) {
            case Color.Black:
            case Color.BlackColor:
            case Color.Blue:
            case Color.Cyan:
            case Color.DyeBlack:
            case Color.Gray:
            case Color.Green:
            case Color.LightBlack:
            case Color.LightCyan:
            case Color.LightGray:
            case Color.LightLightBlack:
            case Color.LightMagenta:
            case Color.Magenta:
            case Color.MatteBlack:
            case Color.Orange:
            case Color.PhotoBlack:
            case Color.PhotoCyan:
            case Color.PhotoMagenta:
            case Color.PigmentBlack:
            case Color.Red:
            case Color.VividLightMagenta:
            case Color.VividMagenta:
            case Color.Yellow:
                return '#FFFFFF';
            default:
                return '#000000';
        }
    }

    export const asArray: Color[] = [
        Color.BlackColor,
        Color.Blue,
        Color.ChromaOptimizer,
        Color.Cyan,
        Color.DyeBlack,
        Color.GlossOptimizer,
        Color.Gray,
        Color.Green,
        Color.KCMY,
        Color.LightBlack,
        Color.LightCyan,
        Color.LightGray,
        Color.LightLightBlack,
        Color.LightMagenta,
        Color.Magenta,
        Color.MatteBlack,
        Color.NotApplicable,
        Color.Orange,
        Color.PHOTO,
        Color.PhotoBlack,
        Color.PhotoCOLOR,
        Color.PhotoCyan,
        Color.PhotoMagenta,
        Color.PigmentBlack,
        Color.Red,
        Color.VividLightMagenta,
        Color.VividMagenta,
        Color.Yellow
    ];
}

export { Color };
