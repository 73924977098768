import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { Entity } from '@app/models';
import { select, Store } from '@ngrx/store';
import { fromEntity } from '@app/state/selectors';
import { GlobalStore } from '@app/state/store';
import {
    filter,
    map,
    switchMap,
    takeLast,
    takeUntil,
    takeWhile,
    tap
} from 'rxjs/operators';
import { Device, Install, JobStatusTypes, NewJob } from '@libs/iso/core';
import { DeviceService, InstallService, JobService, LocalStorageService, WebadminV2Service } from '@app/services';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'ptkr-welcome-dca-setup',
    templateUrl: './welcome-dca-setup.component.html',
    styleUrls: ['./welcome-dca-setup.component.scss']
})
export class WelcomeDcaSetupComponent implements OnInit, OnDestroy {
    public entity$: Observable<Entity> = this._store.pipe(select(fromEntity.currentEntity));
    public install: Install;
    public initJob: NewJob;
    public discoveredDevices$: Observable<Array<Device>>;
    public discoveredDevicesSubscription$: Subscription;
    public discoveredDevices: Array<Device> = [];
    public jobStatusTypes: typeof JobStatusTypes = JobStatusTypes;
    public timeOfDownload: Date = new Date();
    public dataSource: MatTableDataSource<Device> = new MatTableDataSource<Device>();
    public displayedColumns: string[] = ['model', 'ipAddr'];

    @Output()
    public installRegistered: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _store: Store<GlobalStore>,
        private _localStorageService: LocalStorageService,
        private _installService: InstallService,
        private _jobService: JobService,
        private _deviceService: DeviceService,
        private _webadminV2Service: WebadminV2Service,
    ) {}

    ngOnInit(): void {
        this.timeOfDownload.setMinutes(this.timeOfDownload.getMinutes() - 20);
        this._pollInstall();
    }

    ngOnDestroy(): void {
        this.discoveredDevicesSubscription$.unsubscribe();
    }

    public downloadNow(): void {
        this._webadminV2Service.openDownload();
        this.timeOfDownload = new Date();
    }

    private _pollInstall(): void {
        // every five seconds, poll for a new installation at this entity that was created since the welcome wizard was opened
        this.discoveredDevices$ = interval(5000)
            .pipe(
                switchMap(e =>
                    // get the entity key
                    this.entity$.pipe(
                        map(entity => entity._id),
                        // make a request to see if there are any new installs yet
                        switchMap(entityKey =>
                            this._installService.getInstallByEntityAndDate(
                                entityKey,
                                this.timeOfDownload.toISOString()
                            )
                        ),
                        // hide the welcome wizard once we've gotten a successful registration
                        tap(val => {
                            if (!!val) {
                                this._localStorageService
                                    .setItem(LocalStorageService.Key.HideWelcomeWizard, 'true');
                            }
                        })
                    )
                ),
                // save the response to a class member
                tap(val => {
                    this.install = val;
                }),
                // continue to poll as long as the install comes back as null
                takeWhile(install => install === null, true),
                // take the last value emitted from the http observable
                takeLast(1),
            )
            // take this install that was discovered and pipe it into the next phase of the pipeline
            .pipe(
                tap((install) => {
                    if (install) {
                        this.installRegistered.emit();
                    }
                }),
                switchMap(install =>
                    interval(5000).pipe(
                        // every five seconds, check and see if the DCA Initialization job has started yet
                        switchMap(() =>
                            this._jobService.getJobByInstallKeyAndJobName(
                                install._id.toString(),
                                'DCA Initialization'
                            )
                        ),
                        // save the response to a class member
                        tap(val => (this.initJob = val)),
                        // continue to poll as long as the job comes back as null
                        takeWhile(job => !job, true),
                        // take the last value emitted from the http observable
                        takeLast(1)
                    )
                ),
                // after a job has been found
                switchMap(job =>
                    interval(5000).pipe(
                        // every five seconds check to see if any devices have been discovered yet
                        switchMap(() =>
                            this._deviceService.getDevicesByInstallKey(
                                (job.installKey as unknown) as string
                            )
                        ),
                        // save the response to a class member and the device mat-table
                        tap(val => {
                            this.discoveredDevices = val;
                            this.dataSource = new MatTableDataSource<Device>(val);
                        }),
                        // continue to poll until the dca initialization job completes or fails
                        takeUntil(
                            interval(5000).pipe(
                                switchMap(() =>
                                    this._jobService.getJobByInstallKeyAndJobName(
                                        (job.installKey as unknown) as string,
                                        'DCA Initialization'
                                    )
                                ),
                                tap(val => (this.initJob = val)),
                                filter(
                                    j =>
                                        j.jobStatus.type === JobStatusTypes.Completed ||
                                        j.jobStatus.type === JobStatusTypes.Failed
                                )
                            )
                        )
                    )
                )
            );
        this.discoveredDevicesSubscription$ = this.discoveredDevices$.subscribe();
    }

    public openDocumentation(): void {
        window.open('https://docs.printtrackerpro.com/docs/guides/discovery', '_blank');
    }
}
