enum StatusDcaType {
    Active = '[StatusDcaType] active',
    Inactive = '[StatusDcaType] inactive',
    Expired = '[StatusDcaType] expired',
    Evaluation = '[StatusDcaType] evaluation'
}
namespace StatusDcaType {
    export function toString(type: StatusDcaType): string {
        switch (type) {
            case StatusDcaType.Active:
                return 'Active';
            case StatusDcaType.Inactive:
                return 'Inactive';
            case StatusDcaType.Expired:
                return 'Expired';
            case StatusDcaType.Evaluation:
                return 'Evaluation';
            default:
                return '';
        }
    }
    export const types: StatusDcaType[] = [
        StatusDcaType.Active,
        StatusDcaType.Inactive,
        StatusDcaType.Expired,
        StatusDcaType.Evaluation
    ];
}
export { StatusDcaType };
