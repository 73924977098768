import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AddCreditCardComponent } from '@app/shared/components/add-credit-card/add-credit-card.component';

@Component({
    selector: 'ptkr-payment-method-modal',
    templateUrl: './payment-method-modal.component.html',
    styleUrls: ['./payment-method-modal.component.scss']
})
export class PaymentMethodModalComponent implements OnInit {
    @ViewChild(AddCreditCardComponent, {static: true})
    public addCardComponent: AddCreditCardComponent;
    @Output()
    public newCard: EventEmitter<string> = new EventEmitter<string>();
    public stripeError: string = '';
    public paymentMethodName: string;

    constructor(public dialogRef: MatDialogRef<PaymentMethodModalComponent>) {}

    ngOnInit(): void {}

    public addCard(): void {
        this.stripeError = '';
        this.addCardComponent.getToken().subscribe(
            tokenRes => {
                if (!!tokenRes.error) {
                    this.stripeError = tokenRes.error.message;
                } else if (!this.addCardComponent.creditCardForm.valid) {
                    this.stripeError = 'Name on card is invalid';
                } else {
                    this.addCardComponent.reset();
                    this.dialogRef.close(tokenRes.token.id);
                }
            },
            error => {
                console.error(error);
            }
        );
    }
}
