import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ObjectId } from 'mongodb';
import { NotificationService } from '@app/services';
import * as moment from 'moment';

@Component({
    selector: 'ptkr-data-list',
    templateUrl: './data-list.component.html',
    styleUrls: ['./data-list.component.scss']
})
export class DataListComponent<T extends { _id: string | ObjectId }> {
    /**
     * If true, the selection column will be rendered.
     * Otherwise, it will not be rendered and can be ignored.
     */
    @Input()
    public rowSelectable: boolean = false;

    /**
     * Determines what gets shown in table cells that don't have any values
     */
    @Input() public missingValue: any = 'Undefined';

    /**
     * Array representing column information. 'name' is the column header label,
     * and 'key' is the name of the property that will be displayed in the rows
     * of that column.
     */
    @Input('columns')
    public get columns(): Array<{ name: string; key: keyof T; copiable?: boolean }> {
        return this.columnsValue;
    }
    public set columns(
        value: Array<{ name: string; key: keyof T; copiable?: boolean; display?: (string) => string }>
    ) {
        this.columnsValue = value;
        this._updateDisplayedColumns();
    }
    public columnsValue: Array<{ name: string; key: keyof T; display?: (string) => string }> = null;

    /**
     * Array of objects that will be used to populate the array.
     */
    @Input()
    public dataSource: Array<T> = [];

    /**
     * Array of keys correlating to objects that should be selected. Designed so
     * it can be two-way bound.
     */
    @Input('selectedData')
    public get selectedData(): Set<string> {
        return this._selectedData;
    }
    public set selectedData(value: Set<string>) {
        this._selectedData = value;

        // Update localSelectedData
        this._localSelecedData.clear();

        if (this.dataSource) {
            this.dataSource.forEach(e => {
                const str = e._id.toString();
                if (this._selectedData.has(str)) {
                    this._localSelecedData.add(str);
                }
            });
        }

        // emit Update (two-way bind)
        // this.selectedDataChange.emit(this._selectedData);
    }
    private _selectedData: Set<string> = new Set<string>();
    @Output()
    public selectedDataChange: EventEmitter<Set<string>> = new EventEmitter<Set<string>>();

    // If this is checked, then you assume all the data, w/o search filter, is selected. Do take
    // into account the include parent if there is one though.
    public allBoxesChecked: boolean = false;
    @Output()
    public allBoxesCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public displayedColumns: Array<string> = []; // see '_updateDisplayedColumns'.
    public someBoxesChecked: boolean = false;

    private _localSelecedData: Set<string> = new Set<string>(); // Used for some & allBoxesChecked (below).

    constructor(private _notificationService: NotificationService) {}

    /**
     * Checks (or unchecks) a chosen item in the list.
     * @param {T} item - Item to be checked for
     * @return {void} nothing
     */
    public checkLineItem(item: T): void {
        if (this.selectedData.has(item._id.toString())) {
            this._removeFromSelection(item);
        } else {
            this._addToSelection(item);
        }
        this._someBoxesChecked();
    }

    /**
     * checks (or unchecks) all items in list.
     * @param {MatCheckBoxChange} event Event from the MatCheckBox UI element
     * @return {void} absolutely nothing
     */
    public checkAllLineItems(event: MatCheckboxChange): void {
        if (event.checked) {
            this.allBoxesChecked = true;
        } else {
            this.allBoxesChecked = false;
            this.selectedData = new Set([]);
        }
        this.allBoxesCheckedChange.emit(this.allBoxesChecked);
        this._someBoxesChecked();
    }

    /**
     * Displays the provided value or the missing value if the provided value is null or undefined
     * since the angular view does not support the nullish coalescing operator.
     * @param column
     * @param {any} value - The value to display if available
     * @returns {any} The value to be displayed in the view
     */
    public display(column: string, value: any): any {
        const col = this.columnsValue.find(c => c.key === column);
        if (col.display != null) {
            return col.display(value);
        }
        return value ?? this.missingValue;
    }

    /**
     * Returns the value to copy as it was passed in, but fires a side effect style notification
     * @param {any} value - the value that should be copied
     * @returns {void}
     */
    public copy(value: any): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this._notificationService.success('Copied!');
    }

    /**
     * @param {T} item - Item that contains _id field, uset do keep track of what's selected.
     * @return {void} The Void
     */
    private _addToSelection(item: T): void {
        this.selectedData = this.selectedData.add(item._id.toString());
        this._emitSelectedData();
    }

    /**
     * @param {T} item - Item that contains _id field, used do keep track of what's selected.
     * @return {void} nothing
     */
    private _removeFromSelection(item: T): void {
        this.selectedData.delete(item._id.toString());
        this.selectedData = new Set(this.selectedData); // To trigger change detection & setter code
        this._emitSelectedData();
    }

    /**
     * Use the variable instead unless you suspect the answer has changed since last checked.
     * @return {boolean} whether or not at least one item is selected.
     */
    private _someBoxesChecked(): boolean {
        this.someBoxesChecked = this._localSelecedData.size > 0;
        return this.someBoxesChecked;
    }

    /**
     * When called, performs work to convert input column info into format readable
     * by the table.
     * @return {void} nothing.
     */
    private _updateDisplayedColumns(): void {
        this.displayedColumns = this.columnsValue.map(e => e.name);
        if (this.rowSelectable) {
            this.displayedColumns = ['selectable', ...this.displayedColumns];
        }
    }

    private _emitSelectedData(): void {
        this.selectedDataChange.emit(this.selectedData);
    }
}
