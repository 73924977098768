import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Entity, User } from '@app/models';
import { EntityPickerComponent } from '@app/shared/components/entity-picker/entity-picker.component';
import { Permission } from '@libs/iso/core';
import {
    BrandingService,
    LocalStorageService,
    OverlayService,
    UserService
} from '@app/services';
import { GlobalStore } from '@app/state/store';
import { select, Store } from '@ngrx/store';
import { fromUser } from '@app/state/selectors';
import { Observable } from 'rxjs';
import { selectEntity } from '@app/state/store/global.store';
import { map, take } from 'rxjs/operators';

@Component({
    selector: 'ptkr-secured-header',
    templateUrl: './new-secured-header.component.html',
    styleUrls: ['./new-secured-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewSecuredHeaderComponent implements OnInit {
    @ViewChild(EntityPickerComponent, { static: false })
    private _entityPicker: EntityPickerComponent;
    @HostBinding('class.is-impersonating')
    @Input()
    public isImpersonating: boolean = false;
    @Input()
    public entity: Entity;
    @Input()
    public user: User;
    @Input()
    public alertNotificationCount: number = 0;
    @Input()
    public serviceNotificationCount: number = 0;
    @Input()
    public cartNotificationCount: number = 0;

    @Output()
    public changeEntity: EventEmitter<Entity> = new EventEmitter<Entity>();
    @Output()
    public toggleEntityDropdown: EventEmitter<void> = new EventEmitter<void>();
    // prettier-ignore
    @Output()
    public toggleAlertNotificationsDropdown: EventEmitter<ElementRef>
        = new EventEmitter<ElementRef>();
    // prettier-ignore
    @Output()
    public toggleServiceNotificationsDropdown: EventEmitter<ElementRef>
        = new EventEmitter<ElementRef>();
    @Output()
    public toggleProfileDropdown: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
    @Output()
    public viewCart: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public toggleMenu: EventEmitter<void> = new EventEmitter<void>();
    public user$: Observable<User> = this._store.pipe(select(fromUser.currentUser));
    public Permission: typeof Permission = Permission;

    public defaultDealerPrimaryColor = '#D3D3D3';
    public headerBorderColor$: Observable<
        string
    > = this._brandingService.dealerPrimaryColor
        .asObservable()
        .pipe(map(v => v ?? this.defaultDealerPrimaryColor));

    constructor(
        private _overlayService: OverlayService,
        private _userService: UserService,
        private _cd: ChangeDetectorRef,
        private _store: Store<GlobalStore>,
        private _localStorageService: LocalStorageService,
        private _brandingService: BrandingService
    ) {}

    ngOnInit(): void {
        // If this is an evaluation entity and they have not opened the welcome wizard, open it
        // automatically so they don't even have to click on the banner.
        this.isEvaluationEntity().subscribe(isEvaluation => {
            const hasSeenWizard = this._localStorageService.getBoolean(
                LocalStorageService.Key.OpenedWelcomeWizard
            );
            if (isEvaluation && !hasSeenWizard) {
                this._overlayService.openWelcomeDcaSetupOverlay();
                this._localStorageService.setItem(
                    LocalStorageService.Key.OpenedWelcomeWizard,
                    'true'
                );
            }
        });
    }

    private isEvaluationEntity(): Observable<boolean> {
        return this._store.select(selectEntity).pipe(
            take(1),
            map(entity => entity.expiresOn != null)
        );
    }

    public truncateNumber(num: number): string {
        // Change numeric factor to keep only 3 characters in badge (including suffix, not including decimal)
        if (num >= 100000000) {
            // Totally unnecessary
            return `${(Math.floor(num / 100000000) * 100000000) / 1000000000}b`;
        } else if (num >= 100000) {
            return `${(Math.floor(num / 100000) * 100000) / 1000000}m`;
        } else if (num >= 1000) {
            return `${Math.floor(num / 1000)}k`;
        } else {
            return num.toString();
        }
    }

    public onEntitySearchbarSelection(event: any): void {
        if (event !== this.entity) {
            this.changeEntity.emit(event);
            this._entityPicker.names = [];
            this._entityPicker.locked = false;
            this._cd.detectChanges();
        }
    }

    public openWelcomeWizard(): void {
        this._overlayService.openWelcomeDcaSetupOverlay();
    }

    public showWelcomeWizardPrompt(): Observable<boolean> {
        return this.isEvaluationEntity().pipe(
            map(
                isEvaluation =>
                    isEvaluation &&
                    !this._localStorageService.getItem(LocalStorageService.Key.HideWelcomeWizard)
            )
        );
    }

    public dismissWelcomeWizardPrompt(): void {
        this._localStorageService.setItem(LocalStorageService.Key.HideWelcomeWizard, 'true');
    }
}
