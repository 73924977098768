import { createSelector } from '@ngrx/store';
import { selectCart } from '../store/global.store';
import { CartStore } from '../store/cart.store';

export const fromCart = {
    self: createSelector(selectCart, (cart: CartStore) => cart),
    salesTax: createSelector(selectCart, (cart: CartStore) => cart.salesTax),
    shippingAddress: createSelector(selectCart, (cart: CartStore) => cart.order.shippingAddress),
    shippingMethod: createSelector(selectCart, (cart: CartStore) => cart.order.shippingMethod),
    paymentMethod: createSelector(selectCart, (cart: CartStore) => cart.order.paymentMethod),
    selectedCarts: createSelector(selectCart, (cart: CartStore) => cart.selectedCarts),
    checkoutActive: createSelector(
        selectCart,
        (cart: CartStore) => cart.selectedCarts.length > 0 && cart.inProgress
    ),
    order: createSelector(selectCart, (cart: CartStore) => cart.order)
};
