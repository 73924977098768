<div class="invoice-header">
    <!-- <div class="invoice-utility">
        <div class="sent-invoice">
            <b>SENT</b> This invoice was sent by email on {{ invoice['issuedDate'] | date }}.
        </div>
    </div> -->
    <div class="invoice-actions">
        <h1 class="invoice-num">Sales Order {{ invoice['salesOrderNumber'] }}</h1>
        <button *ngIf="!isPrinting" mat-flat-button color="accent" class="print-invoice" (click)="printInvoice()">
            Print <i class="fas fa-print"></i>
        </button>
        <!-- <button mat-flat-button class="send-invoice" (click)="sendInvoice()">
            Resend <i class="fas fa-paper-plane"></i>
        </button> -->
        <div *ngIf="!isPrinting" class="mobile-only print-invoice" (click)="printInvoice()">
            <i class="fas fa-print"></i>
        </div>
        <!-- <div class="mobile-only send-invoice" (click)="sendInvoice()">
            <i class="fas fa-paper-plane"></i>
        </div> -->
    </div>
</div>
<div class="invoice-body">
    <div class="head">
        <div class="printtracker-logo">
            <img src="/assets/logo_PrintTracker.png">
        </div>
        <div class="printtracker-info">
            <p class="address">
                Print Tracker<br>
                1119 E. State St, Suite 110<br>
                Eagle, Idaho 83616
            </p>
            <p class="contact">
                Jack Smith<br>
                (208) 987-6543
            </p>
        </div>
    </div>
    <div class="info-wrapper">
        <div class="info">
            <div class="billed-to">
                <h4>Billed To</h4>
                {{ invoice['entityName'] }}<br>
                {{ invoice['entityAddress']['address1'] }}<br>
                {{ invoice['entityAddress']['city'] }}, {{ invoice['entityAddress']['stateOrProvince'] }} {{ invoice['entityAddress']['zipOrPostCode'] }}<br><br>

                {{ invoice['entityContact']['name'] }}<br>
                {{ invoice['entityContact']['phone'] }}

                <h4>On Behalf Of</h4>
                {{ invoice['onBehalfOf'] }}
            </div>
            <div class="nums-dates">
                <div class="dates">
                    <div class="issue-date">
                        <h4>Date of Issue</h4>
                        {{ invoice['date'] | date:'MM/dd/yyyy'}}
                    </div>
                    <!-- <div class="due-date">
                        <h4>Due Date</h4>
                        {{ invoice['dueDate'] | date:'MM/dd/yyyy'}}
                    </div> -->
                </div>
                <div class="nums">
                    <div class="invoice-num">
                        <h4>Sales Order Number</h4>
                        {{ invoice['salesOrderNumber'] }}
                    </div>
                    <div class="po-num">
                        <h4>PO Number</h4>
                        {{ invoice['poNumber'] ? invoice['poNumber'] : 'None' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="amount">
            <h4>Order Total</h4>
            <h1>{{ invoice['total'] | currency : 'USD' }}</h1>
        </div>
    </div>
    <div class="invoice-items">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef><h4>Description</h4></th>
                <td mat-cell *matCellDef="let data">
                    <strong>{{ data.item.type }}</strong><br>
                    {{ data.item.description }}<br>
                    <span class="mobile-only">{{ data.quantity }} at {{ data.price | currency : 'USD' }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="cost">
                <th mat-header-cell *matHeaderCellDef><h4>Cost</h4></th>
                <td mat-cell *matCellDef="let data">
                    {{ data.price | currency : 'USD' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="qty">
                <th mat-header-cell *matHeaderCellDef><h4>Qty</h4></th>
                <td mat-cell *matCellDef="let data">
                    {{ data.quantity }}
                </td>
            </ng-container>
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef><h4>Line Total</h4></th>
                <td mat-cell *matCellDef="let data">
                    {{ data.total | currency : 'USD' }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="invoice-pricing">
            <div class="price-row">
                <span class="description">Subtotal</span><span class="price">{{ invoice['subtotal'] | currency : 'USD' }}</span>
            </div>
            <div class="price-row">
                <span class="description">Tax</span><span class="price">{{ invoice['tax'] | currency : 'USD' }}</span>
            </div>
            <hr>
            <div class="price-row">
                <span class="description">Total</span><span class="price">{{ invoice['total'] | currency : 'USD' }}</span>
            </div>
            <!-- <div class="price-row">
                <span class="description">Amount Paid</span><span class="price">{{ invoice['amountPaid'] | currency : 'USD' }}</span>
            </div>
            <hr>
            <div class="price-row">
                <span class="description">Amount Due</span><span class="price">{{ invoice['amountDue'] | currency : 'USD' }}</span>
            </div> -->
        </div>
    </div>
</div>
