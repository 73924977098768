import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Status } from '@libs/iso/core';

@Component({
    selector: 'ptkr-status-indicator',
    templateUrl: './status-indicator.component.html',
    styleUrls: ['./status-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusIndicatorComponent implements OnInit {
    @Input()
    public status: Status = Status.Active;

    @Input()
    public canEdit: boolean = true;

    public StatusType: typeof Status = Status;

    constructor() {}

    ngOnInit(): void {}
}
