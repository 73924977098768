import { PaymentMethodType } from '../../enums/PaymentMethodType';

export class UserPaymentMethod {
    public id: string;
    public type: PaymentMethodType;
    public last4: string;
    public default: boolean;
    public verified: boolean;
    public expMonth: number;
    public expYear: number;
    public accountName: string;
    public brand: string;
    public bankName: string;

    // public availableBalance: number; // For NET10
    // public totalBalance: number; // For NET10
    // public firstDueDate: Date; // For NET10
    // public creditDaysPastDue: number; // For NET10

    constructor(stripeData: any, isDefault: boolean) {
        if (!!stripeData) {
            this.id = stripeData.id || undefined;
            this.type = PaymentMethodType.convertStripeType(stripeData.object);
            this.last4 = stripeData.last4 || undefined;
            this.verified = this.type === PaymentMethodType.ACH ? false : null;
            this.default = isDefault;
            this.expMonth = stripeData.exp_month || null;
            this.expYear = stripeData.exp_year || null;
            this.accountName = stripeData.metadata ? stripeData.metadata.name : undefined;
            this.brand = stripeData.brand || undefined;
            this.bankName = stripeData.bank_name || null;
        } else {
            this.default = isDefault;
        }
    }

    public static fromExisting(params: Partial<UserPaymentMethod>): UserPaymentMethod {
        const pm = new UserPaymentMethod(undefined, false);
        if (!!params) {
            pm.id = params.id || pm.id;
            pm.type = params.type || pm.type;
            pm.last4 = params.last4 || pm.last4;
            pm.verified = params.verified || pm.verified;
            pm.default = params.default || pm.default;
            pm.expMonth = params.expMonth || pm.expMonth;
            pm.expYear = params.expYear || pm.expYear;
            pm.accountName = params.accountName || pm.accountName;
            pm.brand = params.brand || pm.brand;
            pm.bankName = params.bankName || pm.bankName;
        }
        return pm;
    }
}
