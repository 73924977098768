import { BehaviorSubject, Subject } from 'rxjs';

interface IColumn {
    name: string;
    enabled: boolean;
    required: boolean;
}

/**
 * TableColumnSelector provides an easy interface for configuring columns used in a MatTable. The
 * MatTable consumes an array of column names that should be displayed and is often hard to work
 * with directly.
 *
 * This class provides utilities for working with column names rather than column indexes and also
 * provides a reactive API for subscribing to column changes.
 */
export class TableColumnSelector {
    public changes$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public onChanges$: Subject<void> = new Subject<void>();
    private _columns: IColumn[] = [];

    constructor() {
        this._columns = [];
    }

    /**
     * Resets the column selector and removes all the columns.
     * @returns {void}
     */
    public reset(): void {
        this._columns = [];
    }

    /**
     * Adds a column to the end of the column selector array. The order in which this function is called
     * determines the order in which the columns will show up in the table.
     * @param {string} name - The name of the column.
     * @param {boolean} enabled - Whether the column should be enabled by default.
     * @param {boolean} required - Whether the column should always be enabled no matter what the settings say.
     * @returns {void}
     */
    public addColumn(name: string, enabled: boolean = true, required = false): void {
        this._columns.push({
            name,
            enabled,
            required
        });
        this._update();
    }

    /**
     * Adds an array of columns with the same enabled status.
     * @param {string[]} names - An array of column names.
     * @param {boolean} enabled - Whether the columns should start enabled or disabled.
     * @param {boolean} required - Whether the columns should always be enabled no matter the
     *   setting.
     * @returns {void}
     */
    public addColumns(names: string[], enabled: boolean = true, required: boolean = false): void {
        names.forEach(n => this.addColumn(n, enabled, required));
    }

    /**
     * Returns an array of all enabled or required column names in the order in which they were
     * added to the column selector class.
     * @returns {string[]} - An array of enabled or required column names
     */
    public getColumnNames(): string[] {
        return this._columns.filter(c => c.enabled === true || c.required === true).map(c => c.name);
    }

    /**
     * Returns an array of all columns that are enabled and not required, sorted in the order in
     * which they were added to the column selector class.
     * @return {string[]} - An array of columns that are both enabled and not required
     */
    public getOptionalColumnNames(): string[] {
        return this._columns.filter(c => c.enabled === true && c.required === false).map(c => c.name);
    }

    /**
     * Enables a column and triggers a reactive update. If the column cannot be found or is already
     * enabled, this function acts as a no-op.
     * @param {string} name - The name of the column to enable.
     * @returns {void}
     */
    public enableColumn(name: string): void {
        const index = this._columns.findIndex(e => e.name === name);
        if (index >= 0) {
            this._columns[index].enabled = true;
            this._update();
        }
    }

    /**
     * Disables a column and triggers a reactive update. If the column cannot be found or is already
     * disabled, this function acts as a no-op.
     * @param {string} name - The name of the column to disable.
     * @returns {void}
     */
    public disableColumn(name: string): void {
        const index = this._columns.findIndex(e => e.name === name);
        if (index >= 0) {
            this._columns[index].enabled = false;
            this._update();
        }
    }

    /**
     * Sets the status of a column and triggers a reactive update. This function is most useful in
     * the html view code where a toggle or checkbox can access this function and set the status of
     * a column directly.
     * @param {string} name - The name of the column to set the status of.
     * @param {boolean} enabled - Whether the column should be enabled or not.
     * @returns {void}
     */
    public setColumn(name: string, enabled: boolean): void {
        switch (enabled) {
            case true:
                this.enableColumn(name);
                break;
            default:
                this.disableColumn(name);
        }
    }

    /**
     * Returns true if the specified column is enabled. If the column cannot be found, this function
     * returns false.
     * @param {string} name - The name of the column to lookup.
     * @returns {boolean} - Whether the column is enabled.
     */
    public isColumnEnabled(name: string): boolean {
        const index = this._columns.findIndex(e => e.name === name);
        return index > 0 ? this._columns[index].enabled : false;
    }

    /**
     * Triggers an internal reactive update.
     * @private
     * @returns {void}
     */
    private _update(): void {
        this.changes$.next(this.getColumnNames());
        this.onChanges$.next();
    }
}
