import { Directive, Output, EventEmitter, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
    selector: '[ptkrClickOutside]'
})
export class ClickOutsideDirective {
    @Output() public ptkrClickOutside: EventEmitter<void> = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {
        fromEvent(document, 'click')
            .pipe(
                filter<MouseEvent>(
                    (value: MouseEvent) => !this.elementRef.nativeElement.contains(value.target)
                )
            )
            .subscribe(() => {
                this.ptkrClickOutside.emit();
            });
    }
}
