<form [formGroup]="formGroup">
    <div>
        <!--Formats editor-->
        <mat-form-field appearance="outline" class="counters-form-field" floatLabel="always">
            <mat-label i18n>Formats</mat-label>
            <div class="format-chip-container">
                <mat-chip
                    *ngFor="let format of formats$ | async"
                    [removable]="true"
                    [disableRipple]="true"
                    (removed)="removeFormat(format)"
                >
                    {{ pageCountType.toDisplayString(format)}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </div>
            <mat-chip-list>
                <input
                    matInput
                    placeholder="Click to see available formats"
                    i18n-placeholder
                    formControlName="formatInput"
                    [matAutocomplete]="formatAutoComplete"
                />

                <mat-autocomplete #formatAutoComplete="matAutocomplete">
                    <mat-option
                        *ngFor="let format of filteredFormats | async"
                        [value]="format"
                        (change)="$event.stopPropagation()"
                        (onSelectionChange)="addFormat(format)"
                    >
                        <span>{{ pageCountType.toDisplayString(format) }}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-chip-list>
            <mat-hint i18n>Format preferences are evaluated in the order they appear here</mat-hint>
        </mat-form-field>
    </div>

    <div>
        <!--This toggle determines whether the canon numbered meters should be shown along side the default meter names-->
        <div style="float: right; margin-bottom: 5px;">
            <mat-slide-toggle [(ngModel)]="showCanonNumberedMeters" [ngModelOptions]="{standalone: true}" i18n>Show Canon meters</mat-slide-toggle>
        </div>
        <!--Counters editor-->
        <mat-form-field appearance="outline" class="counters-form-field" floatLabel="always">
            <mat-label i18n>Counters</mat-label>
            <mat-chip-list>
                <!--These are custom chips that allow for showing canon numbered meter equivalents, and configuring counter
                offsets using a special pop-up menu-->
                <ptkr-bmp-counter-chip
                    *ngFor="let counter of counters$ | async"
                    [counter]="counter"
                    [showCanonMappings]="showCanonNumberedMeters"
                    [offsetEnabled]="offsetEnabled"
                    (removed)="removeCounter($event)"
                    (offsetUpdated)="updateBillingPreferencesCounters()"
                ></ptkr-bmp-counter-chip>

                <!--An auto-completing input for picking which counters to add to your preferences-->
                <input
                    matInput
                    placeholder="Click to see available counters"
                    i18n-placeholder
                    formControlName="counterInput"
                    [matAutocomplete]="counterAutoComplete"
                />

                <mat-autocomplete #counterAutoComplete="matAutocomplete">
                    <ng-container [ngSwitch]="usingAllSupportedMeterTypes">
                        <!--This auto complete is used to provide auto completion for the full list of meter types by
                        breaking them down into categories that are easier to navigate.-->
                        <ng-container *ngSwitchCase="true">
                            <mat-optgroup *ngFor="let category of filteredCounters | async" [label]="category.category">
                                <mat-option
                                    *ngFor="let counter of category.meterTypes"
                                    [value]="counter.key"
                                    (onSelectionChange)="addCounter({displayName: counter.name, key: counter.key})"
                                >
                                    {{ counter.name }}
                                    <span class="canon-numbered-meter">  {{getPrintableCanonMeters(counter.key)}}</span>
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                        <!--This auto complete is used to provide auto completion for meter types that exist on the device
                        rather then all meter types available. As such, they're not broken down into categories.-->
                        <ng-container *ngSwitchDefault>
                            <mat-option
                                *ngFor="let counter of filteredCounters | async"
                                [value]="counter.key"
                                (onSelectionChange)="addCounter(counter)"
                            >
                                {{ counter.displayName }}
                            </mat-option>
                        </ng-container>
                    </ng-container>
                </mat-autocomplete>
            </mat-chip-list>
            <mat-hint i18n>Offsets are highlighted in <span class="orange">orange</span></mat-hint>
        </mat-form-field>
    </div>
</form>
