import { Injectable } from '@angular/core';
import { HttpService, Put, Path, Body, Query, Get, Adapter, MapClass } from '@libs/web/http';
import { EventResolutionStatus, EventDetailed, EventActionLog } from '@libs/iso/core';
import { Observable } from 'rxjs';

@Injectable()
export class AlertService extends HttpService {
    @Put('/alert/{id}/resolutionStatus')
    @MapClass(EventActionLog)
    public updateAlertResolutionStatusById(
        @Path('id') alertId: string,
        @Body('newStatus') status: EventResolutionStatus,
        @Body('note') note?: string
    ): Observable<any> {
        return null;
    }

    // Spencer 7/9/2020 - Service made specifically for the supertable.
    @Get('/alert/list')
    public getNewList(
        @Query('entityId') entityKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('columns') columns?: Array<number>
    ): Observable<any> {
        return null;
    }

    @Put('/alert/bulk/resolutionStatus')
    @MapClass(EventActionLog)
    public bulkUpdateAlertResolutionStatusById(
        @Body('ids') alertIds: string[],
        @Body('newStatus') status: EventResolutionStatus,
        @Body('note') note?: string
    ): Observable<any> {
        return null;
    }

    @Get('/entity/{id}/topAlertsDetailed?details=true')
    // tslint:disable-next-line:ter-prefer-arrow-callback
    @Adapter(function(res): EventDetailed[] {
        if (Array.isArray(res.docs)) {
            return res.docs.map(d => new EventDetailed(d));
        } else {
            return [];
        }
    })
    public getTopAlerts(
        @Path('id') entityId: string,
        @Query('limit') limit: number,
        @Query('type') eventTypeKey: string,
        @Query('resolutionStatus') resolutionStatus: EventResolutionStatus,
        @Query('includeChildren') includeChildren?: boolean
    ): Observable<EventDetailed[]> {
        return null;
    }
}
