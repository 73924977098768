import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy
} from '@angular/core';
import { OverlayService } from '@app/services';
import { UserPaymentMethod, PaymentMethodType, UserPaymentMethodNet10 } from '@libs/iso/core';

@Component({
    selector: 'ptkr-select-payment-method',
    templateUrl: './select-payment-method.component.html',
    styleUrls: ['./select-payment-method.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectPaymentMethodComponent implements OnInit {
    private _paymentMethods: UserPaymentMethodNet10[] = [];
    @Input()
    public set paymentMethods(value: UserPaymentMethodNet10[]) {
        if (Array.isArray(value)) {
            this._paymentMethods = value;
        }
    }
    public get paymentMethods(): UserPaymentMethodNet10[] {
        return this._paymentMethods;
    }

    @Input()
    public set selected(value: UserPaymentMethod) {
        if (!!value) {
            this.selectedPayment = value;
        }
    }
    public get selected(): UserPaymentMethod {
        return this.selectedPayment;
    }
    @Output()
    public selection: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    public stripeToken: EventEmitter<any> = new EventEmitter<any>();
    public paymentMethodSelection: Array<boolean> = new Array<boolean>();
    public selectedPayment: UserPaymentMethod;
    public PaymentMethodType: typeof PaymentMethodType = PaymentMethodType;

    constructor(private _overlayService: OverlayService) {}

    ngOnInit(): void {}

    public selectPaymentMethod(pm: UserPaymentMethodNet10): void {
        if (typeof pm.disableNet10 !== 'undefined' && pm.disableNet10 === true) {
            return;
        }
        this.selectedPayment = pm;
        this.selection.emit(pm);
    }

    public newPaymentMethod(): void {
        const ref = this._overlayService.openPaymentMethodModal();
        ref.afterClosed().subscribe(token => {
            if (token) {
                this.stripeToken.emit(token);
            }
        });
    }
}
