enum TimeFrame {
    CurrentWeek = '[TimeFrame] current week',
    PreviousWeek = '[TimeFrame] previous week',
    CurrentPayPeriod = '[TimeFrame] current pay period',
    CurrentMonth = '[TimeFrame] current month',
    PreviousPayPeriod = '[TimeFrame] previous pay period',
    PreviousMonth = '[TimeFrame] previous month',
    CurrentQuarter = '[TimeFrame] current quarter',
    PreviousQuarter = '[TimeFrame] previous quarter',
    Last12Months = '[TimeFrame] last 12 months',
    Custom = '[TimeFrame] custom'
}

namespace TimeFrame {
    export function toDateRange(type: TimeFrame): [Date, Date] {
        const ref = new Date();

        const qmultiple = 3;
        const quarter = Math.floor(ref.getMonth() / qmultiple);
        const startOfQuarter = new Date(ref.getFullYear(), quarter * qmultiple, 1);
        const startOfPrevQuarter = new Date(ref.getFullYear(), quarter * qmultiple - qmultiple, 1);
        const startOfMonth = new Date(ref.getFullYear(), ref.getMonth());
        const startOfPreviousMonth = new Date(ref.getFullYear(), ref.getMonth() - 1);

        const cycleAsMilliseconds = 1209600000;
        const seven = 7;
        const initPay = new Date('2018-06-23');
        const currentPay = new Date(
            initPay.getTime() +
                Math.floor((ref.getTime() - initPay.getTime()) / cycleAsMilliseconds) *
                    cycleAsMilliseconds
        );

        switch (type) {
            case TimeFrame.CurrentWeek:
                return [
                    new Date(ref.getFullYear(), ref.getMonth(), ref.getDate() - ref.getDay()),
                    new Date()
                ];
            case TimeFrame.PreviousWeek:
                return [
                    new Date(
                        ref.getFullYear(),
                        ref.getMonth(),
                        ref.getDate() - ref.getDay() - seven
                    ),
                    new Date(ref.getFullYear(), ref.getMonth(), ref.getDate() - ref.getDay())
                ];
            case TimeFrame.Last12Months:
                return [new Date(ref.getFullYear() - 1, ref.getMonth(), ref.getDay()), new Date()];
            case TimeFrame.PreviousQuarter:
                return [startOfPrevQuarter, startOfQuarter];
            case TimeFrame.CurrentQuarter:
                return [startOfQuarter, new Date()];
            case TimeFrame.PreviousMonth:
                return [startOfPreviousMonth, startOfMonth];
            case TimeFrame.CurrentMonth:
                return [startOfMonth, new Date()];
            case TimeFrame.PreviousPayPeriod:
                return [new Date(currentPay.getTime() - cycleAsMilliseconds), currentPay];
            case TimeFrame.CurrentPayPeriod:
            default:
                return [currentPay, ref];
        }
    }

    export function toString(type: TimeFrame): string {
        switch (type) {
            case TimeFrame.CurrentWeek:
                return 'Current week';
            case TimeFrame.PreviousWeek:
                return 'Previous week';
            case TimeFrame.CurrentPayPeriod:
                return 'Current pay period';
            case TimeFrame.CurrentMonth:
                return 'Current month';
            case TimeFrame.PreviousPayPeriod:
                return 'Previous pay period';
            case TimeFrame.PreviousMonth:
                return 'Previous month';
            case TimeFrame.CurrentQuarter:
                return 'Current quarter';
            case TimeFrame.PreviousQuarter:
                return 'Previous quarter';
            case TimeFrame.Last12Months:
                return 'Last 12 months';
            case TimeFrame.Custom:
                return 'Custom';
            default:
                return '';
        }
    }

    export const types: TimeFrame[] = [
        TimeFrame.CurrentPayPeriod,
        TimeFrame.PreviousPayPeriod,
        TimeFrame.CurrentQuarter,
        TimeFrame.PreviousQuarter,
        TimeFrame.Last12Months,
        TimeFrame.Custom
    ];
}

export { TimeFrame };
