import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ValidationService } from '@app/services';

@Component({
    selector: 'ptkr-single-ip-range-input',
    template: `
        <mat-form-field appearance="outline">
            <input matInput placeholder="e.g. 10.0.0.1, 10.0.0.*, 10.0.0.1-254, or 10.0.0.0/24" [formControl]="control" />
        </mat-form-field>
    `
})
export class SingleIpRangeInputComponent implements OnInit {
    @Input() control: FormControl;

    ngOnInit() {
        this.control.setValidators([
            Validators.required,
            (control: FormControl) => {
                return ValidationService.ipAddress(control.value, 'include');
            }
        ]);
    }
}
