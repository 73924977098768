import { ObjectId } from 'bson';

export interface IDeviceEventAcknowledgement {
    dtTm: Date;
    userKey: ObjectId | string;
    note: string;
}

export class DeviceEventAcknowledgement implements IDeviceEventAcknowledgement {
    public dtTm: Date = new Date();
    public userKey: ObjectId | string = null;
    public note: string = '';

    constructor(defaults?: Partial<IDeviceEventAcknowledgement>) {
        if (defaults) {
            this.dtTm = defaults.dtTm || this.dtTm;
            this.userKey = defaults.userKey || this.userKey;
            this.note = defaults.note || this.note;
        }
    }
}
