<mat-card>
    <button
        *ptkrPermission="Permission.CreateEntity"
        mat-flat-button
        color="accent"
        (click)="overlayRef.addNewEntity()" i18n>
        Add entity
    </button>
    <span class="spacer"></span>
    <button id="cy-select-entity-overlay-component-close-btn" mat-button (click)="overlayRef.close()">
        <i class="fas fa-angle-up m-r-5"></i>
        <ng-container i18n="@@close">Close</ng-container>
    </button>
    <div *ngIf="!!currentEntity.children || currentEntity.children.length !== 0">
        <div class="search">
            <ptkr-search-bar
                placeholder="Search Entities"
                i18n-placeholder
                (searchClicked)="search($event)"
            ></ptkr-search-bar>
        </div>
        <div class="entity-list">
            <div
                class="entity-item"
                *ngIf="!searched && currentEntity.breadcrumb.length > 0"
                (click)="selectParent(currentEntity)" >
                <span class="name"><b i18n>Go to parent entity</b></span>
                <button mat-button class="arrow-icon"><i class="far fa-chevron-up"></i></button>
            </div>
            <cdk-virtual-scroll-viewport
                itemSize="44"
                class="view-port"
                [class.has-parent]="!searched && currentEntity.breadcrumb.length > 0">
                <div *cdkVirtualFor="let e of (entityResults$ | async)">
                    <div class="entity-item" (click)="setFocus(e)">
                        <span class="name">
                            <span *ngIf="e.isDealer">
                                <!-- <img
                                    height="16px"
                                    src="https://cultofthepartyparrot.com/parrots/hd/parrot.gif"
                                /> -->
                                <i class="fas fa-user-tie"></i>
                            </span>
                            {{ e.name }}
                            <span *ngIf="e.expiresOn" class="label">
                                <small i18n>Expires: {{e.expiresOn | date:'M/d/yy'}} ({{e.expiresOn | amTimeAgo}})</small>
                            </span>
                        </span>
                        <button mat-button class="arrow-icon">
                            <i class="far fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
    <div *ngIf="!currentEntity.children || currentEntity.children.length === 0">
        <p i18n>There are no children of {{ currentEntity.name }}</p>
    </div>
</mat-card>
