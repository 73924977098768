import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RouterService, NotificationService } from '@app/services';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    private _unauthorizedCode: number = 401;
    private _forbiddenCode: number = 403;

    constructor(
        private _router: Router,
        private _routerService: RouterService,
        private _notificationService: NotificationService
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('unauth intercept');
        return next.handle(req).pipe(
            catchError(err => {
                if (err.status === this._unauthorizedCode) {
                    this._notificationService.error('Un-Authorized');
                    this._routerService.setLoginRedirect(this._router.url);
                    this._router.navigate(['/auth/login']);
                }
                if (err.status === this._forbiddenCode) {
                    this._notificationService.error('Not Authorized to access resource');
                }
                return throwError(err);
            })
        );
    }
}
