import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedList, JobBatch } from '@libs/iso/core';
import { Adapter, Get, HttpService, Path, Query } from '@libs/web/http';

@Injectable()
export class JobBatchService extends HttpService {
    @Get('/jobbatch')
    @Adapter((res): PaginatedList<JobBatch> => {
        const list = new PaginatedList<JobBatch>();
        const docs = Array.isArray(res.docs) ? res.docs : [];
        list.docs = docs.map(d => new JobBatch(d));
        list.totalDocs = res.totalDocs || 0;
        return list;
    })
    public getJobBatches(
        @Query('entityKey') entityKey: string,
        @Query('searchTerm') searchTerm?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sort?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean
    ): Observable<PaginatedList<JobBatch>> {
        return null;
    }

    @Get('/jobbatch/{id}')
    public getJobBatch(@Path('id') installId: string): Observable<JobBatch> {
        return null;
    }
}
