import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ShippingMethod } from '@libs/iso/core';

@Component({
    selector: 'ptkr-select-shipping-method',
    templateUrl: './select-shipping-method.component.html',
    styleUrls: ['./select-shipping-method.component.scss']
})
export class SelectShippingMethodComponent implements OnInit {
    private _methods: Array<ShippingMethod>;
    @Input()
    public set shippingMethods(value: Array<ShippingMethod>) {
        if (!!value) {
            this._methods = value;
            this.shippingMethodSelection = [];
            this._methods.forEach(m => {
                this.shippingMethodSelection.push(false);
            });
            if (!!this.selected) {
                const i = this._methods.findIndex(m => m === this.selected);
                if (i > -1) {
                    this.shippingMethodSelection[i] = true;
                }
            }
        }
    }
    public get shippingMethods(): Array<ShippingMethod> {
        return this._methods;
    }

    private _selected: ShippingMethod;
    @Input()
    public set selected(value: ShippingMethod) {
        if (!!value && !this._compareMethods(value, this._selected)) {
            this._selected = value;
            const i = this._methods.findIndex(a => this._compareMethods(a, value));
            if (i > -1) {
                this.shippingMethodSelection[i] = true;
            }
        }
    }
    public get selected(): ShippingMethod {
        return this._selected;
    }

    @Output()
    public selection: EventEmitter<ShippingMethod> = new EventEmitter<ShippingMethod>();
    public shippingMethodSelection: Array<boolean> = new Array<boolean>();

    constructor() {}

    ngOnInit(): void {}

    public selectMethod(method: ShippingMethod, i: number): void {
        this.shippingMethodSelection.fill(false);
        this.shippingMethodSelection[i] = true;
        this.selection.emit(method);
    }

    private _compareMethods(m1: ShippingMethod, m2: ShippingMethod): boolean {
        if (!m1 || !m2) {
            return false;
        } else if (m1.type !== m2.type) {
            return false;
        } else {
            return true;
        }
    }
}
