import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractSettingsSectionComponent } from '@libs/web/forms/components/settings-sections/abstract-settings-section/abstract-settings-section.component';
import { GeneralInfoDynamicSettingsSectionFormGroup } from '@libs/web/forms/models';

/**
 * GeneralInfoDynamicSettingsSection contains the general info settings for installs and entities.
 * It does NOT include the general info used for devices; that is in
 * 'device-non-inherited-settings-section'.
 * @see DeviceNonInheritedSettingsSection
 */
@Component({
    selector: 'ptkr-general-info-settings-section',
    templateUrl: './general-info-dynamic-settings-section.component.html',
    styleUrls: ['./general-info-dynamic-settings-section.component.scss']
})
export class GeneralInfoDynamicSettingsSectionComponent extends AbstractSettingsSectionComponent<
    GeneralInfoDynamicSettingsSectionFormGroup
> {
    @Input() public isInstall: boolean = false;
    public separatorKeysCodes: any = [ENTER, COMMA, SEMICOLON, TAB];

    constructor(private _cd: ChangeDetectorRef) {
        super();
    }
}
