/**
 * Used to distinguish between different kinds of audit logs; different audit log types store
 * different kinds of details.
 */
enum AuditLogType {
    SettingsChange = '[AuditLogType] Settings change',
    NewMeterRead = '[AuditLogType] New meter read',
    NewDevice = '[AuditLogType] New Device',
    EntityMoved = '[AuditLogType] Entity Moved',
    EntityDeleted = '[AuditLogType] Entity Deleted',
    DocumentRemoved = '[AuditLogType] Document Removed',
    SerialNumberChanged = '[AuditLogType] Serial number changed',
    SettingBulkReset = '[AuditLogType] Setting bulk reset',
    ChildOfBulkReset = '[AuditLogType] Child of bulk reset',
    NewEntity = '[AuditLogType] New entity created',
    DeploymentEmailSent = '[AuditLogType] Deployment Email Sent',
    StatusChanged = '[AuditLogType] Status Changed',
    DataSyncConfigCreated = '[AuditLogType] Data Sync Config Created',
    DataSyncConfigUpdated = '[AuditLogType] Data Sync Config Updated',
    DataSyncConfigDeleted = '[AuditLogType] Data Sync Config Deleted',
}

/**
 * Specifies where in the architecture the audit log item came from
 */
export enum AuditLogRequesterType {
    Install = '[AuditLogRequesterType] Install',
    User = '[AuditLogRequesterType] User',
}

/**
 * The namespace paired with the AuditLogType enum
 * Used to distinguish between different kinds of audit logs; different audit log types store
 * different kinds of details.
 */
namespace AuditLogType {
    /**
     * This function accepts audit log types and returns user-friendly names for them. These names
     * are written in past-tense so that, in the web-admin, references to an audit log's type will
     * read as, "this is the type of event that happened".
     * which is more natural for users who aren't familiar with our abstractions.
     * @param {AuditLogType} type  the type of audit log item we are interested in
     * @returns {string}           Label describing the type of audit log in a user-friendly way
     */
    export function toString(type: AuditLogType): string {
        switch (type) {
            case AuditLogType.SettingsChange:
                return 'Settings changed';
            case AuditLogType.NewMeterRead:
                return 'New meter read';
            case AuditLogType.NewDevice:
                return 'New device discovered';
            case AuditLogType.EntityMoved:
                return 'Entity moved';
            case AuditLogType.EntityDeleted:
                return 'Entity deleted';
            case AuditLogType.DocumentRemoved:
                return 'Document removed';
            case AuditLogType.SerialNumberChanged:
                return 'Serial number changed';
            case AuditLogType.SettingBulkReset:
                return 'Bulk setting reset';
            case AuditLogType.ChildOfBulkReset:
                return 'Bulk setting reset';
            case AuditLogType.NewEntity:
                return 'New entity created';
            case AuditLogType.DeploymentEmailSent:
                return 'Deployment email sent';
            case AuditLogType.StatusChanged:
                return 'Status changed';
            case AuditLogType.DataSyncConfigCreated:
                return 'Integration created';
            case AuditLogType.DataSyncConfigUpdated:
                return 'Integration updated';
            case AuditLogType.DataSyncConfigDeleted:
                return 'Integration deleted';
            default:
                return 'Misc';
        }
    }

}

export { AuditLogType };
