<ptkr-block-header title="Select payment method"></ptkr-block-header>

<div class="payment-wrapper">
    <ng-container *ngFor="let method of paymentMethods">
        <div class="payment-method credit-card"
            *ngIf="method.type === PaymentMethodType.Card"
            (click)="selectPaymentMethod(method)"
            [class.selected]="selectedPayment?.id === method.id">
            <div class="payment-method-icon">
                <!-- <i class="fas fa-calendar-alt"></i> -->
                <i class="fab fa-cc-visa"></i>
            </div>
            <div class="payment-method-number">
                <h2>**** **** **** {{ method.last4 }}</h2>
            </div>
            <div class="payment-method-details">
                <div class="expire-date detail">
                    <span class="title">Expiry date</span>
                    <span class="data">{{ method.expMonth }}/{{ method.expYear.toString() | slice:2:4 }}</span>
                </div>
                <!-- <div class="name detail">
                    <span class="title">Name</span>
                    <span class="data">David Williams</span>
                </div> -->
            </div>
            <div class="checked" *ngIf="selectedPayment?.id === method.id">
                <i class="fa fa-check" aria-hidden="true"></i>
            </div>
        </div>
        <div class="payment-method ach"
            *ngIf="method.type === PaymentMethodType.ACH"
            (click)="selectPaymentMethod(method)"
            [class.selected]="selectedPayment?.id === method.id">
            <div class="payment-method-icon">
                <i class="fas fa-university"></i>
            </div>
            <div class="payment-method-number">
                <h2>XXXXXX{{ method.last4 }}</h2>
            </div>
            <div class="payment-method-details">
                <div class="expire-date detail">
                    <span class="title">Bank</span>
                    <span class="data">{{ method.bankName }}</span>
                </div>
                <!-- <div class="name detail">
                    <span class="title">Name</span>
                    <span class="data">USC Business College</span>
                </div> -->
            </div>
            <div class="checked" *ngIf="selectedPayment?.id === method.id">
                <i class="fa fa-check" aria-hidden="true"></i>
            </div>
        </div>
        <div class="payment-method net10"
            *ngIf="method.type === PaymentMethodType.NET10"
            (click)="selectPaymentMethod(method)"
            [class.selected]="selectedPayment?.id === method.id">
            <div class="payment-method-icon">
                <i class="fas fa-calendar-alt"></i>
            </div>
            <div class="payment-method-number">
                <h2>Net 10 Credit Terms</h2>
            </div>
            <div class="payment-method-details">
                <div class="expire-date detail" *ngIf="!method.disableNet10">
                    <span class="title">Credit Available</span>
                    <span class="data">{{ method.availableBalance | currency:'USD':'symbol-narrow' }}</span>
                </div>
                <div class="expire-date detail">
                    <span class="title">{{ method.disableReason }}</span>
                </div>
            </div>
            <div class="checked" *ngIf="selectedPayment?.id === method.id">
                <i class="fa fa-check" aria-hidden="true"></i>
            </div>
        </div>
    </ng-container>
    <div class="payment-method add-new" (click)="newPaymentMethod()">
        <span>
            <i class="fas fa-plus-circle"></i><br>
            <h3>Add payment method</h3>
        </span>
    </div>
    <div class="payment-method filler"></div>
    <div class="payment-method filler"></div>
</div>
