import {
    DeviceList,
    DeviceTableParams,
    getTableConfigurationFromStorage,
    SuppliesList
} from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DeviceService, SupplyService } from '@app/services';
import { catchError, take } from 'rxjs/operators';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { ISuppliesListTableQueryParams } from '@app/core/models/TableConfiguration';
import * as moment from 'moment/moment';

@Injectable()
export class SuppliesListResolver implements Resolve<SuppliesList> {
    constructor(private _supplyService: SupplyService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<SuppliesList> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let entityId: string = '';
        this._store.pipe(select(fromEntity.entityId), take(1)).subscribe(id => (entityId = id));

        // When the resolver first runs without any query parameters, we want to default to the locally
        // saved table config parameters. We can get these from the local storage.
        const {
            includeChildren,
            includeReplaced,
            includeFromNonManagedDevices,
            replacedStartDate,
            replacedEndDate
        }: any = getTableConfigurationFromStorage<ISuppliesListTableQueryParams>('suppliesList') ?? {
            includeChildren: false,
            includeReplaced: true,
            includeFromNonManagedDevices: false,
            replacedStartDate: moment()
                .subtract(1, 'month')
                .toISOString(),
            replacedEndDate: moment().toISOString()
        };

        // Then we give priority to what's already in the query parameters
        const initQParams = route.queryParams;
        const defaultLimit = 10;
        const params: ISuppliesListTableQueryParams = {
            searchTerm: initQParams.searchTerm || '',
            sortBy: initQParams.sortBy || '',
            sortOrder: initQParams.sortOrder || '',
            page: coerceNumberProperty(initQParams.page) || 1,
            limit: coerceNumberProperty(initQParams.limit) || defaultLimit,
            includeChildren: initQParams.includeChildren
                ? coerceBooleanProperty(initQParams.includeChildren)
                : coerceBooleanProperty(includeChildren),
            includeReplaced: coerceBooleanProperty(includeReplaced),
            includeFromNonManagedDevices: coerceBooleanProperty(includeFromNonManagedDevices),
            replacedStartDate: initQParams.replacedStartDate ? initQParams.replacedStartDate : replacedStartDate,
            replacedEndDate: initQParams.replacedEndDate ? initQParams.replacedEndDate : replacedEndDate
        };

        // We have to set the parameters this way because 'false' boolean values are 'falsy' values
        if (initQParams.includeReplaced != null) {
            params.includeReplaced = coerceBooleanProperty(initQParams.includeReplaced);
        }

        return this._supplyService
            .getSuppliesList(
                entityId,
                undefined,
                params.searchTerm,
                params.page,
                params.limit,
                params.sortBy,
                params.sortOrder as number,
                params.includeChildren as boolean,
                params.includeReplaced as boolean,
                params.includeFromNonManagedDevices as boolean,
                params.replacedStartDate,
                params.replacedEndDate
            )
            .pipe(
                catchError(err => {
                    console.error('caught error', err);
                    return of(new SuppliesList());
                })
            );
    }
}
