import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PolymorpheusComponent, PolymorpheusContent } from '@tinkoff/ng-polymorpheus';

@Component({
    selector: 'ptkr-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent {
    @Input() public header: string = '';
    @Input() public tip: string = '';

    // Controller - Implements Data Access Control
    @Input() public controllerContent: PolymorpheusContent<any> | null = null;
    @Input() public controllerContext: any = null;

    // Errors - If not null, will show error message not unlike mat-error.
    @Input() public error: PolymorpheusContent<never> | null = null;

    // Actions - Will be converted into a set of buttons below the field.
    @Input() public actions: Array<PolymorpheusContent<never>>;

    @Output() public actionClicked: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    public onActionClicked(event: any): void {
        this.actionClicked.emit(event);
    }
}
