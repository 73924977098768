enum ScheduleTypeDayPart {
    Beginning = '[ScheduleTypeDayPart] beginning',
    End = '[ScheduleTypeDayPart] end',
    AFterHours = '[ScheduleTypeDayPart] afterhours',
    Anytime = '[ScheduleTypeDayPart] anytime'
}
namespace ScheduleTypeDayPart {
    export function toString(type: ScheduleTypeDayPart): string {
        switch (type) {
            case ScheduleTypeDayPart.Beginning:
                return 'Beginning of the day';
            case ScheduleTypeDayPart.End:
                return 'End of the day';
            case ScheduleTypeDayPart.AFterHours:
                return 'After working hours';
            case ScheduleTypeDayPart.Anytime:
                return 'Anytime';
            default:
                return '';
        }
    }
    export const types: ScheduleTypeDayPart[] = [
        ScheduleTypeDayPart.Beginning,
        ScheduleTypeDayPart.End,
        ScheduleTypeDayPart.AFterHours,
        ScheduleTypeDayPart.Anytime
    ];
}
export { ScheduleTypeDayPart };
