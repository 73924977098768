import { Component, OnInit } from '@angular/core';

enum Browser {
    Chrome,
    Firefox,
    Edge,
    Safari
}

@Component({
    selector: 'ptkr-download-installer-image',
    templateUrl: './download-installer-image.component.html',
    styleUrls: ['./download-installer-image.component.scss']
})
export class DownloadInstallerImageComponent implements OnInit {
    public browser: Browser;

    public Browser: typeof Browser = Browser;

    constructor() {}

    ngOnInit(): void {
        this.browser = this.detectBrowser();
    }

    private detectBrowser(): Browser {
        const agent = window.navigator.userAgent;
        console.log(agent);
        if (agent.indexOf('Chrome') > -1) {
            return Browser.Chrome;
        }
        if (agent.indexOf('Firefox') > -1) {
            return Browser.Firefox;
        }
        if (agent.indexOf('Edge') > -1) {
            return Browser.Edge;
        }
        if (agent.indexOf('Safari') > -1) {
            return Browser.Safari;
        }
        return Browser.Chrome;
    }
}
