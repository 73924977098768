<div class='border-group'>
    <button mat-button class='resetButton'
            [class.reset-change]='showDropdown'
            type='button'
            [disabled]='isInherited'
            (click)='onClick()'>
        {{ isInherited ? inheritedString : notInheritedString }}
        <ng-container *ngIf="!isInherited">
            <mat-icon color='warn'>restart_alt</mat-icon>
        </ng-container>
    </button>
    <button mat-button class='resetButton button-dropdown'
            *ngIf='showDropdown'
            [matMenuTriggerFor]='inheritanceDropDownMenu'>
        <mat-icon>arrow_drop_down</mat-icon>
    </button>
</div>

<mat-menu #inheritanceDropDownMenu="matMenu">
    <button mat-menu-item (click)='bulkReset.emit(settingName)' i18n>Reset for all children</button>
</mat-menu>
