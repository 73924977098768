import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import { TableDataList } from '@app/core/models/TableDataList';
import { Column, colKeyOneProp } from '@libs/iso/core/helpers/columns';
import { Sort } from 'aws-sdk/clients/cloudsearchdomain';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'ptkr-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableComponent implements OnInit {
    @Input()
    public get tableData(): TableDataList {
        return this.tableDataValue;
    }
    public set tableData(value: TableDataList) {
        this.tableDataValue = value;
        this.columns = [];
        this.colKeys = [];
        this.tableDataValue.columns.forEach(c => {
            this.colKeys.push(c + '');
            this.columns[c] = this.tableDataValue.model.columns[c];
        });
        this.rows = this.tableDataValue.docs;
    }

    @Input()
    public actions: Array<string> = [];

    @Output()
    public updateSort: EventEmitter<Sort> = new EventEmitter<Sort>();

    @Output()
    public actionClicked: EventEmitter<{ name: string; row: any }> = new EventEmitter<{
        name: string;
        row: any;
    }>();

    public tableDataValue: TableDataList = new TableDataList();
    public columns: Array<Column> = [
        { name: '', keys: colKeyOneProp(''), description: '', display: Column.displayFunctions[''] }
    ];
    public colKeys: Array<string> = ['nodata'];
    public rows: Array<object> = [{ nodata: $localize`No data here` }];
    public selectedRow: any;

    constructor(private _dialog: MatDialog) {}
    ngOnInit(): void {}

    public onUpdateSort(event: Sort): void {
        this.updateSort.emit(event);
    }

    public display(m: any, c: any): string {
        return Column.display(m, c) || '--';
    }

    public rawData(m: any, c: any): any {
        return Column.rawData(m, c);
    }

    public getColumnStringList(): Array<string> {
        const cols = [...this.colKeys];
        if (this.actions.length > 0) {
            cols.push('actions');
        }
        return cols;
    }
}
