import { TimeSeriesValue } from '@libs/iso/core/models/meterRead/TimeSeriesValue';
import * as moment from 'moment';
import { groups, bin } from 'd3-array';
import { min, max } from 'lodash';
import { timeDay, timeDays, timeMonth, timeMonths, timeYear, timeYears } from 'd3-time';
import { format } from 'echarts';
import '@angular/localize/init';

export enum TimeSeriesBinType {
    None = '[TimeSeriesBinType] None',
    Day = '[TimeSeriesBinType] Day',
    Month = '[TimeSeriesBinType] Month',
    // Quarter = '[TimeSeriesBinType] Quarter',
    Year = '[TimeSeriesBinType] Year'
}

export namespace TimeSeriesBinType {
    export function toDisplayName(type: TimeSeriesBinType): string {
        switch (type) {
            case TimeSeriesBinType.None:
                return $localize`None`;
            case TimeSeriesBinType.Day:
                return $localize`Day`;
            case TimeSeriesBinType.Month:
                return $localize`Month`;
            // case TimeSeriesBinType.Quarter:
            //     return $localize`Quarter`;
            case TimeSeriesBinType.Year:
                return $localize`Year`;
            default:
                return $localize`Unknown`;
        }
    }

    export const asArray: TimeSeriesBinType[] = [
        TimeSeriesBinType.None,
        TimeSeriesBinType.Day,
        TimeSeriesBinType.Month,
        // TimeSeriesBinType.Quarter,
        TimeSeriesBinType.Year
    ];

    export function binTimeSeries(
        type: TimeSeriesBinType,
        series: Array<TimeSeriesValue>,
        isVolume: boolean = false
    ): Array<TimeSeriesValue> {
        if (type === TimeSeriesBinType.None) {
            return series;
        }

        const extent: [Date, Date] = [
            min(series.map(v => new Date(v.timestamp))),
            max(series.map(v => new Date(v.timestamp)))
        ];
        const generateBins = (e: [Date, Date]): Array<Date> => {
            switch (type) {
                case TimeSeriesBinType.Month:
                    return timeMonths(timeMonth.offset(e[0], -1), timeMonth.offset(e[1], 1));
                case TimeSeriesBinType.Year:
                    return timeYears(timeYear.offset(e[0], -1), timeYear.offset(e[1], 1));
                case TimeSeriesBinType.Day:
                default:
                    return timeDays(timeDay.offset(e[0], -1), timeDay.offset(e[1], 1));
            }
        };
        const formatAndTruncate = (date: Date): string =>
            TimeSeriesBinType.formatDateToString(type, TimeSeriesBinType.truncateDate(type, date));

        const bins = generateBins(extent);
        const mapped: Map<Date, Array<TimeSeriesValue>> = new Map();
        const binned = bin<TimeSeriesValue, Date>()
            .thresholds(generateBins(extent))
            .domain(extent)
            .value(d => new Date(d.timestamp))(series);
        for (let i = 0; i < binned.length; i++) {
            // tslint:disable
            const contents =
                Array.isArray(binned[i]) && binned[i].length > 0
                    ? binned[i]
                    : [
                          new TimeSeriesValue({
                              timestamp: bins[i]
                          })
                      ];
            // tslint:enable
            mapped.set(bins[i], contents);
        }

        // tslint:disable
        return <Array<TimeSeriesValue>>(<unknown>[...mapped.values()].map(b =>
            isVolume
                ? {
                      timestamp: formatAndTruncate(b[0].timestamp),
                      value: b.map(a => a.value).reduce((acc, next) => +acc + +next)
                  }
                : {
                      timestamp: formatAndTruncate(b[0].timestamp),
                      value: +b[b.length - 1].value
                  }
        ));
        // tslint:enable
    }

    export function truncateDate(type: TimeSeriesBinType, value: Date): Date {
        let startOf;
        switch (type) {
            case TimeSeriesBinType.Day:
                startOf = 'day';
                break;
            case TimeSeriesBinType.Month:
                startOf = 'month';
                break;
            // case TimeSeriesBinType.Quarter:
            //     startOf = 'quarter';
            //     break;
            case TimeSeriesBinType.Year:
                startOf = 'year';
                break;
            default:
                startOf = 'day';
        }
        return moment(value)
            .startOf(startOf)
            .toDate();
    }

    export function formatDateToString(type: TimeSeriesBinType, value: Date): string {
        switch (type) {
            case TimeSeriesBinType.Month:
                // case TimeSeriesBinType.Quarter:
                return format.formatTime('yyyy-MM', value, false);
            case TimeSeriesBinType.Year:
                return format.formatTime('yyyy', value, false);
            case TimeSeriesBinType.Day:
            default:
                return format.formatTime('yyyy-MM-dd', value, false);
        }
    }

    export function binnedTimestampString(bin: TimeSeriesBinType, timestamp: Date): string {
        return TimeSeriesBinType.formatDateToString(
            bin,
            TimeSeriesBinType.truncateDate(bin, timestamp)
        );
    }

    export function determineBinningThreshold(
        type: TimeSeriesBinType,
        series: Array<TimeSeriesValue>
    ): number {
        const formatAndTruncate = (date: Date): string =>
            TimeSeriesBinType.formatDateToString(type, TimeSeriesBinType.truncateDate(type, date));
        return groups(series, s => formatAndTruncate(s.timestamp)).length;
    }
}
