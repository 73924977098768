import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Install, NewJob } from '@libs/iso/core';
import { MatTableDataSource } from '@angular/material/table';
import { IJobHistory } from '@libs/iso/core/models/rts/NewJob';
import { JobService } from '@app/services';

@Component({
    selector: 'ptkr-concatinated-job-logs-table',
    templateUrl: './concatinated-job-logs-table.component.html',
    styleUrls: ['./concatinated-job-logs-table.component.scss']
})
export class ConcatinatedJobLogsTableComponent implements OnInit {
    @Input('install') public install: Install;
    @Input('limit') public limit: number;
    public dataSource: MatTableDataSource<IJobHistory>;
    public displayedColumns: Array<string> = [
        'timestamp',
        'message',
    ];

    constructor(
        private _jobService: JobService,
        private _cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.loadLogs();
    }

    public loadLogs(): void {
        this._jobService
            .getConcatinatedJobLogsByInstall(this.install._id, this.limit)
            .subscribe(res => {
                this.dataSource = new MatTableDataSource<IJobHistory>(res);
                this._cd.detectChanges();
            });
    }
}
