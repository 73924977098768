import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { DefaultMeterTypeService } from '@app/core/services/defaultMeterType.service';
import { FormGroup, FormControl, ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { MeterTypeNameMap } from '@libs/iso/core';

@Component({
    selector: 'ptkr-meter-key-picker',
    templateUrl: './meter-key-picker.component.html',
    styleUrls: ['./meter-key-picker.component.scss']
})
export class MeterKeyPickerComponent implements OnInit {
    @Input()
    public key: string = null;

    @Input()
    public meterTypeNameMap: MeterTypeNameMap = new MeterTypeNameMap();

    @Input()
    public otherKeys: Array<string> = [];

    @Output()
    public keyChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public valid: EventEmitter<boolean> = new EventEmitter<boolean>();

    public controller: FormControl = new FormControl();

    public meterOptions: Array<{ displayName: string; meterKey: string }> = [];
    public locked: boolean = false;

    public ngUnsub: Subject<void> = new Subject();

    constructor(
        private _defaultMeterTypeService: DefaultMeterTypeService,
        private _cd: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.controller.setValidators([
            Validators.required,
            this._meterKeyUniqueValidator(this.otherKeys)
        ]);
        if (!!this.key) {
            this.controller.setValue({
                displayName: this.meterTypeNameMap.getName('default', this.key),
                meterKey: this.key
            });
        }
        this.controller.valueChanges.subscribe(res => {
            this.keyChange.emit(!!res ? res.meterKey : undefined);
            this.valid.emit(this.controller.valid);
        });
        this.controller.updateValueAndValidity();
    }

    public ngOnDestroy(): void {
        this.ngUnsub.next();
        this.ngUnsub.complete();
    }

    public onFilterTextChange(event: string): void {
        this._defaultMeterTypeService
            .searchDefaultMeterType(event, 5)
            .pipe(take(1))
            .subscribe(res => {
                this.meterOptions = res;
                this._cd.markForCheck();
            });
    }

    public getErrorMessage(): string {
        let msg: string = '';
        switch (true) {
            case !this.controller.touched: {
                break;
            }
            case this.controller.hasError('required'): {
                msg = 'Required';
                break;
            }
            case this.controller.hasError('keyMustBeUnique'): {
                msg = "It looks like you've already added this meter. Try adding a new one.";
                break;
            }
            default: {
                break;
            }
        }
        return msg;
    }

    private _meterKeyUniqueValidator(keys: Array<string>): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null =>
            keys.findIndex(
                (value: string) =>
                    control.value && control.value.meterKey && control.value.meterKey === value
            ) === -1
                ? null
                : { keyMustBeUnique: {} };
    }
}
