import { ObjectId } from 'mongodb';
import { Device, DeviceModel } from './Device';

export interface IDeviceCollectionResult {
    timestamp: Date;
    attempted: boolean;
    message?: string;
    error?: string;
    hasError: boolean;
    durationMilliseconds?: number;
    durationString?: string;
}

export class DeviceCollectionResult implements IDeviceCollectionResult {
    public timestamp: Date;
    public attempted: boolean = true;
    public message: string;
    public error: string;
    public hasError: boolean;
    public durationMilliseconds: number;
    public durationString: string;

    constructor(props?: Partial<IDeviceCollectionResult>) {
        if (props != null) {
            if (props.timestamp != null) {
                this.timestamp = new Date(props.timestamp) ?? this.timestamp;
            }
            this.attempted = props.attempted ?? this.attempted;
            this.message = props.message ?? this.message;
            this.error = props.error ?? this.error;
            this.hasError = props.hasError ?? this.hasError;
            this.durationMilliseconds = props.durationMilliseconds ?? this.durationMilliseconds;
            this.durationString = props.durationString ?? this.durationString;
        }
    }

    public skipped(): boolean {
        return !this.attempted;
    }

    public hasMessage(): boolean {
        return this.message != null && this.message.length > 0;
    }

    public truncatedError(): string {
        if (this.error.length > 28) {
            return `${this.error.substr(0, 28)}...`;
        } else {
            return this.error;
        }
    }
}

export interface IDeviceCollectionResults {
    deviceKey: string | ObjectId;
    timestamp: Date;
    healthcheck: IDeviceCollectionResult;
    ping: IDeviceCollectionResult;
    snmp: IDeviceCollectionResult;
    meterCollection: IDeviceCollectionResult;
    processEvents: IDeviceCollectionResult;
    meterSave: IDeviceCollectionResult;
    meterValid: IDeviceCollectionResult;
    meterUploaded: IDeviceCollectionResult;
    meterPurging: IDeviceCollectionResult;
}

export class DeviceCollectionResults implements IDeviceCollectionResults {
    public deviceKey: string | ObjectId;
    public timestamp: Date;
    public healthcheck: IDeviceCollectionResult;
    public ping: IDeviceCollectionResult;
    public snmp: IDeviceCollectionResult;
    public meterCollection: IDeviceCollectionResult;
    public processEvents: IDeviceCollectionResult;
    public meterSave: IDeviceCollectionResult;
    public meterValid: IDeviceCollectionResult;
    public meterUploaded: IDeviceCollectionResult;
    public meterPurging: IDeviceCollectionResult;

    constructor(props?: Partial<IDeviceCollectionResults>) {
        this.deviceKey = props.deviceKey ?? this.deviceKey;
        if (props.timestamp != null) {
            this.timestamp = new Date(props.timestamp) ?? this.timestamp;
        }
        this.healthcheck = new DeviceCollectionResult(props.healthcheck) ?? this.healthcheck;
        this.ping = new DeviceCollectionResult(props.ping) ?? this.ping;
        this.snmp = new DeviceCollectionResult(props.snmp) ?? this.snmp;
        this.meterCollection =
            new DeviceCollectionResult(props.meterCollection) ?? this.meterCollection;
        this.processEvents = new DeviceCollectionResult(props.processEvents) ?? this.processEvents;
        this.meterSave = new DeviceCollectionResult(props.meterSave) ?? this.meterSave;
        this.meterValid = new DeviceCollectionResult(props.meterValid) ?? this.meterValid;
        this.meterUploaded = new DeviceCollectionResult(props.meterUploaded) ?? this.meterUploaded;
        this.meterPurging = new DeviceCollectionResult(props.meterPurging) ?? this.meterPurging;
    }
}

export type DeviceCollectionResultWithDevice = DeviceCollectionResults & Device;
export type IDeviceCollectionResultWithDevice = IDeviceCollectionResults & DeviceModel;
