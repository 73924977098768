import { Component, HostListener, OnInit } from '@angular/core';
import { WindowSizeService, RouterService } from '@app/services';
import { WindowSize } from '@app/models';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { fromEntity } from '@app/state/selectors';
import { RequestEntityChangeAction } from '@app/state/actions';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'ptkr-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _entityId: string;

    constructor(
        private _windowSizeService: WindowSizeService,
        private _breakpointObserver: BreakpointObserver,
        private _routerService: RouterService,
        private _store: Store<GlobalStore>,
        private _router: Router
    ) {
        this._store.pipe(select(fromEntity.currentEntity)).subscribe(entity => {
            if (!!entity) {
                this._entityId = entity._id;
            } else {
                this._entityId = '';
            }
        });

        this._breakpointObserver.observe([
            Breakpoints.Handset,
            Breakpoints.Tablet,
            Breakpoints.Web
        ]).subscribe(() => {
            this.breakpointChanged();
        });
    }

    ngOnInit(): void {
        this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
            setTimeout(() => {
                // console.log('scroll to called!');
                const cont = document.querySelector('.content-container');
                if (!!cont) {
                    cont.scrollTo(0, 0);
                }
            }, 20);
        });
    }

    @HostListener('window:popstate', ['$event'])
    public onPopState(event): void {
        const newTarget: string = location.pathname;
        const targetEntityId = this._routerService.getEntityIdFromUrl(newTarget);
        if (targetEntityId !== this._entityId) {
            this._routerService.setOneTimeEntityChangedRedirect(newTarget);
            this._store.dispatch(new RequestEntityChangeAction(targetEntityId));
        }
    }

    private breakpointChanged(): void {
        if (this._breakpointObserver.isMatched(Breakpoints.Handset)) {
            this._windowSizeService.newWindowSize(WindowSize.Mobile);
        } else if (this._breakpointObserver.isMatched([Breakpoints.Tablet, Breakpoints.WebPortrait])) {
            this._windowSizeService.newWindowSize(WindowSize.Tablet);
        } else if (this._breakpointObserver.isMatched(Breakpoints.WebLandscape)) {
            this._windowSizeService.newWindowSize(WindowSize.Desktop);
        }
    }
}
