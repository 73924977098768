import { Injectable } from '@angular/core';
import {
    HttpService,
    Get,
    Header,
    MapClass,
    Post,
    Body,
    Query,
    Put,
    ResponseType,
    Path,
    Delete
} from '@libs/web/http';
import { User, AddUser, UserList, Order } from '@app/models';
import { Observable } from 'rxjs';
import { Cart } from '@libs/iso/core';

@Injectable()
export class UserService extends HttpService {
    @Get('/user/details')
    @MapClass(User)
    public initUser(@Header('Authorization') userId: string): Observable<User> {
        return null;
    }

    @Post('/user/create')
    public createUser(@Body() params: AddUser): Observable<any> {
        return null;
    }

    @Get('/user/list')
    public getUserList(
        @Query('entityId') entityId?: string,
        @Query('searchTerm') query?: string,
        @Query('page') page?: number,
        @Query('limit') limit?: number,
        @Query('sortBy') sortBy?: string,
        @Query('sortOrder') sortOrder?: number,
        @Query('includeChildren') includeChildren?: boolean,
        @Query('showInactive') showInactive?: boolean
    ): Observable<UserList> {
        return null;
    }

    @Post('/user/startImpersonate', ResponseType.Text)
    public startImpersonate(@Body('userId') userId: string): Observable<string> {
        return null;
    }

    @Post('/user/endImpersonate', ResponseType.Text)
    public endImpersonate(): Observable<string> {
        return null;
    }

    @Get('/user/details')
    @MapClass(User)
    public getDetails(@Query('userId') userId: string): Observable<User> {
        return null;
    }

    @Get('/user/name')
    public getName(@Query('id') id: string): Observable<any> {
        return null;
    }

    @Get('/user/details?absolute=true')
    @MapClass(User)
    public getAbsoluteUserDetails(): Observable<User> {
        return null;
    }

    @Put('/user/update')
    public updateUser(@Body() params: Partial<AddUser>): Observable<any> {
        return null;
    }

    @Post('/user/updatePassword')
    public updatePassword(
        @Body('currentPassword') currentPassword: string,
        @Body('newPassword') newPassword: string,
        @Body('userId') userId: string
    ): Observable<any> {
        return null;
    }

    @Post('/user/resendPasswordEmail', ResponseType.Text)
    public resendPasswordEmail(@Query('id') userId: string): Observable<string> {
        return null;
    }

    @Get('/user/{id}/payment')
    public getUserSavedPayments(
        @Path('id') userId: string,
        @Query('type') type: 'card' | 'ACH' | 'all'
    ): Observable<any> {
        return null;
    }

    @Post('/user/{id}/getCheckoutPaymentMethods')
    public getUserSavedPaymentsForCheckout(
        @Path('id') userId: string,
        @Query('type') type: 'card' | 'ACH' | 'all',
        @Body('carts') selectedCarts: Cart[],
        @Body('order') order: Order
    ): Observable<any> {
        return;
    }

    @Post('/user/{id}/payment')
    public addNewPaymentMethod(
        @Path('id') userId: string,
        @Body('token') token: string
    ): Observable<any> {
        return null;
    }

    @Post('/user/{id}/verifyACH')
    public verifyACH(
        @Path('id') userId: string,
        @Body('amount1') amount1: number,
        @Body('amount2') amount2: number,
        @Body('sourceId') sourceId: string
    ): Observable<any> {
        return null;
    }

    @Delete('/user/{id}/payment/{paymentId}')
    public deletePaymentMethod(
        @Path('id') userId: string,
        @Path('paymentId') paymentId: string
    ): Observable<any> {
        return null;
    }

    @Put('/user/{id}/payment/{paymentId}')
    public setDefaultPaymentMethod(
        @Path('id') userId: string,
        @Path('paymentId') paymentId: string
    ): Observable<any> {
        return null;
    }
}
