export enum SingleSignOnAction {
    Login,
    Register
}

export enum SingleSignOnProvider {
    Microsoft = '[SingleSignOnProvider] Microsoft'
}

export namespace SingleSignOnProvider {
    export function fromString(input: string): SingleSignOnProvider {
        switch (input) {
            case SingleSignOnProvider.Microsoft:
                return SingleSignOnProvider.Microsoft;
            default:
                throw new Error(`Unsupported SSO provider: ${input}`);
        }
    }
}

// The OAuth public client config must be hardcoded because
// it's not secret, it's exposed on the client and the server
// and env variables aren't available on the client.

export namespace SingleSignOnMicrosoft {
    export const OAuthPublicConfig = {
        clientId: '2662e81d-6db0-4fe7-8e75-d8ee5c21c8b4'
    };
}
