import { Injectable } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Injectable({
    providedIn: 'root'
})
class LocalStorageService {

    constructor() { }

    public setItem(key: LocalStorageService.Key, value: string): void {
        localStorage.setItem(key, value);
    }

    public getItem(key: LocalStorageService.Key): string {
        return localStorage.getItem(key);
    }

    public getBoolean(key: LocalStorageService.Key): boolean {
        return coerceBooleanProperty(this.getItem(key));
    }
}

namespace LocalStorageService {
    export enum Key {
        GlobalIncludeChildren = 'globalIncludeChildren',
        Language = 'lang',
        HideWelcomeWizard = 'hideWelcomeWizard',
        OpenedWelcomeWizard = 'openedWelcomeWizard',
        InstallationCustomEmailBody = 'installationCustomEmailBody'
    }
}

export default LocalStorageService;
