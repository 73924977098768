import {
    DeviceNonInheritedSettingsKeyType,
    EntityNonInheritedSettingsKeyType,
    InstallNonInheritedSettingsKeyType,
    DynamicSettingsKeyType, UpdateKeyType
} from '@libs/iso/core';

/**
 * This wasn't going to be a named type, but there was utility in guaranteeing
 * consistency across multiple files.
 */
export type SettingsUpdateType = {
    set: Partial<{ [key in UpdateKeyType]: any }>,
    unset: DynamicSettingsKeyType[]
};

/**
 * Every panel that updates many values for a device can organize its data into this format to allow
 * for a simpler time while passing the data to the API. This is the format used by the class
 * 'SettingsFormGroups' when 'value' is called to retrieve FormGroup values when a submission button
 * is clicked.
 * Data is organized like this instead of in a single update property for improved typing and
 * compatibility with the front end's organization.
 * @see SettingsFormGroups`
 */
export interface ISettingsFormSubmission {
    device?: Partial<{ [K in DeviceNonInheritedSettingsKeyType]: any }>;
    install?: Partial<{ [K in InstallNonInheritedSettingsKeyType]: any }>;
    entity?: Partial<{ [K in EntityNonInheritedSettingsKeyType]: any }>;
    settings?: Partial<{ [K in DynamicSettingsKeyType]: any }>;
    unset?: DynamicSettingsKeyType[];
}

export class SettingsFormSubmission implements ISettingsFormSubmission {
    public device: Partial<{ [K in DeviceNonInheritedSettingsKeyType]: any }>;
    public install: Partial<{ [K in InstallNonInheritedSettingsKeyType]: any }>;
    public entity: Partial<{ [K in EntityNonInheritedSettingsKeyType]: any }>;
    public settings: Partial<{ [K in DynamicSettingsKeyType]: any }>;
    public unset: DynamicSettingsKeyType[] = [];

    public static isEmpty(submission: ISettingsFormSubmission): boolean {
        let isEmpty = true;
        if (submission !== undefined) {
            [submission.device, submission.install, submission.entity, submission.settings].forEach(
                (updates: any): void => {
                    if (updates && Object.keys(updates).length > 0) {
                        isEmpty = false;
                    }
                }
            );
            if (submission.unset && submission.unset.length > 0) {
                isEmpty = false;
            }
        }
        return isEmpty;
    }

    public add(
        data: SettingsUpdateType,
        to: 'device' | 'install' | 'entity' | 'settings'
    ): void {
        if (!!data?.set) {
            if (!this[to]) {
                this[to] = {};
            }
            Object.assign(this[to], data.set);
        }
        if (!!data?.unset && !!data.unset.length) {
            this.unset.push(...data.unset);
        }
    }
}
