import { Component, Input, Inject } from '@angular/core';
import { PageCountType } from '@libs/iso/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
    selector: 'ptkr-meter-format-pill',
    templateUrl: './meter-format-pill.component.html',
    styleUrls: ['./meter-format-pill.component.scss']
})
export class MeterFormatPillComponent {
    @Input()
    public showDefaultAsBilling: boolean;

    @Input()
    public get format(): string {
        return this.formatValue;
    }
    public set format(value: string) {
        // An exception for the overlay
        if (this.showDefaultAsBilling && value === 'default') {
            this.formatShorthand = 'BILLING METER';
        } else {
            this.formatShorthand = PageCountType.toShorthand(PageCountType.fromString(value));
        }
        this.formatValue = value;
    }
    public formatValue: string;
    public formatShorthand: string;

    constructor(@Inject(POLYMORPHEUS_CONTEXT) private _context) {
        if (!!_context) {
            this.format = _context.format ? _context.format : this.format;
        }
    }

    public getStyle(): { [key: string]: any } {
        let color: string;
        switch (this.formatValue) {
            case 'default':
                break;
            case 'life':
                color = '#F1948A';
                break;
            case 'equiv':
                color = '#5499C7';
                break;
            case 'non-equiv':
                color = '#76D7C4';
                break;
            case 'sqFt':
                color = '#F0B27A';
                break;
            case 'lnFt':
                color = '#E59866';
                break;
            case 'sqMt':
                color = '#7DCEA0';
                break;
            case 'lnMt':
                color = '#52BE80';
                break;
            case 'pages':
                color = '#95A5A6';
                break;
            case 'adjusted':
                color = '#AF7AC5';
                break;
            case 'centimeters':
                color = '#E1E17B';
                break;
            case 'scans':
                color = '#E17BE1';
                break;
            case 'faxes':
                color = '#AF7AC5';
                break;
            case 'canon':
                color = '#AF7AC5';
                break;
            default:
                break;
        }
        return { 'background-color': color };
    }
}
