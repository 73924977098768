export interface BaseAddressModel {
    address1: string;
    address2: string;
    city: string;
    stateOrProvince: string;
    zipOrPostCode: string;
    country: string;
}

export interface BaseAddressConstructorParams extends BaseAddressModel {}

export class BaseAddress implements BaseAddressModel {
    public address1: string = '';
    public address2: string = '';
    public city: string = '';
    public stateOrProvince: string = '';
    public zipOrPostCode: string = '';
    public country: string = '';

    constructor(params?: Partial<BaseAddressConstructorParams>) {
        if (!!params) {
            this.address1 = params.address1 || this.address1;
            this.address2 = params.address2 || this.address2;
            this.city = params.city || this.city;
            this.stateOrProvince = params.stateOrProvince || this.stateOrProvince;
            this.zipOrPostCode = params.zipOrPostCode || this.zipOrPostCode;
            this.country = params.country || this.country;
        }
    }

    public isEqual(compare: BaseAddress): boolean {
        if (
            compare.address1 !== this.address1 ||
            compare.address2 !== this.address2 ||
            compare.city !== this.city ||
            compare.stateOrProvince !== this.stateOrProvince ||
            compare.zipOrPostCode !== this.zipOrPostCode ||
            compare.country !== this.country
        ) {
            return false;
        }
        return true;
    }
}
