import {
    Component,
    Input,
    OnChanges,
    OnInit,
    EventEmitter,
    Output,
    ChangeDetectorRef
} from '@angular/core';
import {
    Install,
    IJob,
    JobDestinationTypes,
    TaskTypes,
    Task,
    NewJob,
    JobStatusTypes
} from '@libs/iso/core';
import {
    InstallHealthCheckMessage,
    InstallHealthCheck
} from '@libs/iso/core/models/install/InstallHealthCheck';
import { Permission } from '@libs/iso/core';
import { Subject, timer, Subscription } from 'rxjs';
import { RtsService, NotificationService, JobService, InstallService } from '@app/services';
import { User } from '@app/models';
import {
    takeUntil,
    mergeMap,
    map,
    filter,
    delayWhen,
    finalize,
    delay,
    tap,
    take
} from 'rxjs/operators';
import { fromEntity, fromUser } from '@app/state/selectors';
import { GlobalStore, EntityStore, UserStore } from '@app/state/store';
import { Store, select } from '@ngrx/store';

@Component({
    selector: 'ptkr-install-healthcheck-v2',
    templateUrl: './install-healthcheck-v2.component.html',
    styleUrls: ['./install-healthcheck-v2.component.scss']
})
export class InstallHealthcheckV2Component implements OnChanges {
    @Input('install') private _install: Install;
    public get install(): Install {
        return this._install;
    }
    public set install(value: Install) {
        this._install = value;
    }

    public healthState: InstallHealthCheckMessage;
    public registration: InstallHealthCheckMessage;
    public taskEngine: InstallHealthCheckMessage;
    public rpcClient: InstallHealthCheckMessage;
    public dependencies: InstallHealthCheckMessage;

    public Permission: typeof Permission = Permission;

    public refreshing: boolean;

    constructor(
        private _rtsService: RtsService,
        private _jobService: JobService,
        private _notificationService: NotificationService,
        private _cd: ChangeDetectorRef,
        private _installsService: InstallService,
        private _store: Store<GlobalStore>
    ) {
        this.refreshing = false;
    }

    ngOnChanges(): void {
        this.healthState = new InstallHealthCheckMessage(this._install.healthcheck, 'healthState');
        this.registration = new InstallHealthCheckMessage(
            this._install.healthcheck,
            'registration'
        );
        this.taskEngine = new InstallHealthCheckMessage(this._install.healthcheck, 'taskEngine');
        this.rpcClient = new InstallHealthCheckMessage(this._install.healthcheck, 'rpcClient');
        this.dependencies = new InstallHealthCheckMessage(
            this._install.healthcheck,
            'dependencies'
        );
    }

    public isGoodIcon(a: InstallHealthCheckMessage): string {
        if (a.isValid() === true) {
            return 'check_circle';
        } else {
            return 'error';
        }
    }

    public onRefresh(): void {
        this.refreshing = true;
        this._cd.detectChanges();

        let entity: EntityStore;
        let user: UserStore;

        this._store.pipe(take(1), select(fromEntity.currentEntity)).subscribe(e => {
            entity = e;
        });

        this._store.pipe(take(1), select(fromUser.currentUser)).subscribe(u => {
            user = u;
        });

        const params: Partial<IJob> = {
            entityId: entity._id,
            installId: new Array(
                this._install._id.toString()
            ) /** Sending this because it's going to go to all installations */,
            userId: user._id,
            metadata: {
                user: {
                    username: user.email,
                    firstname: user.firstName,
                    lastname: user.lastName
                },
                entity: {
                    name: entity.name
                }
            },
            jobName: 'Healthcheck',
            jobDescription: 'Gathers health information about an installation',
            destination: JobDestinationTypes.Install,
            tasks: [
                new Task({
                    type: TaskTypes.Healthcheck,
                    payload: {}
                })
            ],
            hidden: true
        };

        this._rtsService
            .createJob(
                params.entityId,
                params.installId,
                params.userId,
                params.metadata,
                params.jobName,
                params.jobDescription,
                params.destination,
                params.tasks,
                params.hidden
            )
            .subscribe((res): any => {
                /** This does not imply a successful job, this means the job was issued to the broker */
                this._notificationService.info(
                    $localize`Checking install health (may take a few seconds)...`
                );

                const jobId = !!res[0] && !!res[0].toString ? res[0].toString() : null;

                if (jobId) {
                    const poll$ = timer(0, 6000).pipe(
                        mergeMap(() => this._jobService.getJobLogs(jobId))
                    );

                    let sub: Subscription = null;
                    let success: Boolean = false;

                    const until$ = poll$.pipe(
                        map((j: Array<Partial<NewJob>>) => (!!j[0] ? j[0].jobStatus.type : null)),
                        filter(
                            status =>
                                status === JobStatusTypes.Completed ||
                                status === JobStatusTypes.Failed
                        ),
                        delayWhen(() => poll$),
                        tap(status =>
                            status === JobStatusTypes.Completed
                                ? (success = true)
                                : (success = false)
                        ),
                        delay(1000),
                        finalize(() => {
                            if (success) {
                                this._installsService
                                    .getInstall(this._install._id.toString())
                                    .subscribe(
                                        install => {
                                            this.healthState = new InstallHealthCheckMessage(
                                                install.healthcheck,
                                                'healthState'
                                            );
                                            this.registration = new InstallHealthCheckMessage(
                                                install.healthcheck,
                                                'registration'
                                            );
                                            this.taskEngine = new InstallHealthCheckMessage(
                                                install.healthcheck,
                                                'taskEngine'
                                            );
                                            this.rpcClient = new InstallHealthCheckMessage(
                                                install.healthcheck,
                                                'rpcClient'
                                            );
                                            // this.jobStream = new InstallHealthCheckMessage(this.install.healthcheck.jobStream);
                                            this.dependencies = new InstallHealthCheckMessage(
                                                install.healthcheck,
                                                'dependencies'
                                            );
                                            this.refreshing = false;
                                            this._cd.detectChanges();
                                            sub.unsubscribe();
                                        },
                                        err => {
                                            this._notificationService.error(
                                                $localize`Something went wrong while fetching new healthcheck info.`
                                            );
                                            this.refreshing = false;
                                            this._cd.detectChanges();
                                        }
                                    );
                            } else {
                                this._notificationService.error($localize`Healthcheck job failed.`);
                            }
                        })
                    );

                    sub = poll$.pipe(takeUntil(until$)).subscribe(
                        () => {},
                        err => {
                            this._notificationService.error(
                                $localize`Something went wrong with job polling.`
                            );
                        }
                    );
                } else {
                    this._notificationService.error($localize`Something went wrong with job submission.`);
                }
            });
    }
}
