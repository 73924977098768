export enum PageCountTypes {
    Total = 'total',
    TotalBlack = 'totalBlack',
    TotalColor = 'totalColor',
    TotalPrints = 'totalPrints',
    TotalCopies = 'totalCopies',
    TotalFaxes = 'totalFaxes',
    TotalScans = 'totalScans'
}

export enum PageCountTypeGroups {
    Total = 'total',
    Type = 'type',
    Color = 'color',
    Extras = 'extras'
}

export namespace PageCountTypes {
    export const totalGroup: PageCountTypes[] = [PageCountTypes.Total];
    export const colorGroup: PageCountTypes[] = [
        PageCountTypes.TotalBlack,
        PageCountTypes.TotalColor
    ];
    export const typeGroup: PageCountTypes[] = [
        PageCountTypes.TotalPrints,
        PageCountTypes.TotalCopies,
        PageCountTypes.TotalFaxes
    ];
    export const extrasGroup: PageCountTypes[] = [PageCountTypes.TotalScans];

    export function toGroupName(type: PageCountTypes): string {
        switch (type) {
            case PageCountTypes.Total:
                return PageCountTypeGroups.Total;
            case PageCountTypes.TotalBlack:
            case PageCountTypes.TotalColor:
                return PageCountTypeGroups.Color;
            case PageCountTypes.TotalPrints:
            case PageCountTypes.TotalCopies:
            case PageCountTypes.TotalFaxes:
                return PageCountTypeGroups.Type;
            case PageCountTypes.TotalScans:
                return PageCountTypeGroups.Extras;
            default:
                return '';
        }
    }

    export function getGroup(group: PageCountTypeGroups): PageCountTypes[] {
        switch (group) {
            case PageCountTypeGroups.Total:
                return totalGroup;
            case PageCountTypeGroups.Color:
                return colorGroup;
            case PageCountTypeGroups.Type:
                return typeGroup;
            case PageCountTypeGroups.Extras:
                return extrasGroup;
            default:
                return [];
        }
    }

    export function toChartableColor(type: PageCountTypes): string {
        switch (type) {
            case PageCountTypes.TotalBlack:
                return 'rgba(51,51,51,0.73)';
            case PageCountTypes.TotalColor:
                return 'rgb(66,212,248)';
            case PageCountTypes.TotalPrints:
                return 'rgb(248,223,97)';
            case PageCountTypes.TotalCopies:
                return 'rgb(248,167,97)';
            case PageCountTypes.TotalScans:
                return 'rgb(111,174,255)';
            case PageCountTypes.TotalFaxes:
                return 'rgb(178,111,255)';
            default:
                return '#333';
        }
    }

    // export function toTimeSeriesSelectors(
    //     volume: boolean,
    //     ...types: PageCountTypes[]
    // ): TimeSeriesSelector[] {
    //     const out: Array<TimeSeriesSelector> = [];
    //     for (const type of types) {
    //         newPageCountTimeSeriesSelector('default', type, volume);
    //     }
    // }
}
