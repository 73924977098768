import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { Role } from '@app/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'ptkr-user-role',
    templateUrl: './user-role.component.html',
    styleUrls: ['./user-role.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRoleComponent implements OnInit {
    @Input()
    public buttonLabel: string = $localize`Save Changes`;
    @Input()
    public userEntity: { id: string, name: string, breadcrumb?: string } = null;
    @Input()
    public availableRoles: Role[] = [];
    @Input()
    public defaultRoleKeys: string[] = [];
    @Output()
    public formComplete: EventEmitter<{ roles: string[]; entityKey: string }> = new EventEmitter<{
        roles: string[];
        entityKey: string;
    }>();

    public roleForm: FormGroup;

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.roleForm = this._fb.group({
            roleKeys: [this.defaultRoleKeys, Validators.required],
            entity: [this.userEntity]
        });
    }

    public onEntityChange(val: any): void {
        this.roleForm.get('entity').setValue(!!val ? val._id : this.userEntity);
    }

    public saveRoles(): void {
        if (!this.roleForm.valid) {
            return;
        }
        this.formComplete.emit({
            roles: this.roleForm.get('roleKeys').value,
            entityKey: this.roleForm.get('entity').value ?? undefined
        });
    }
}
