// Ported from https://www.npmjs.com/package/get-ip-range

import { toLong, fromLong } from 'ip';
// @ts-ignore
import { Address4, Address6 } from 'ip-address';

// Set default max range
let maxRange = 10000;

const getIPv4 = (ip: string): Address4 | null => {
    try {
        return new Address4(ip);
    } catch (err) {
        return null;
    }
};

const getIPv6 = (ip: string): Address6 | null => {
    try {
        return new Address6(ip);
    } catch (err) {
        return null;
    }
};

const getRangev4 = (ip1: string, ip2: string) => {
    const ips = [];

    let firstAddressLong = toLong(ip1);
    const lastAddressLong = toLong(ip2);

    const totalIPs = lastAddressLong - firstAddressLong;

    // Prevent DoS
    if (totalIPs > maxRange) {
        throw new Error(
            `Too many IPs in range. Total number: ${totalIPs}. Max count is ${maxRange}, to increase, set the limit with the MAX_RANGE environment variable`
        );
    }

    for (firstAddressLong; firstAddressLong <= lastAddressLong; firstAddressLong++) {
        ips.push(fromLong(firstAddressLong));
    }
    return ips;
};

const getRangev6 = (ip1: string, ip2: string) => {
    const ips = [];

    const firstAddress = new Address6(ip1);
    const lastAddress = new Address6(ip2);

    for (let i = firstAddress.bigInteger(); i <= lastAddress.bigInteger(); i++) {
        ips.push(Address6.fromBigInteger(i).correctForm());
    }

    return ips;
};

const isCIDR = (ipCIDR: Address4 | Address6): boolean => Boolean(ipCIDR.parsedSubnet);

const isRange = (ipRange: string): boolean => ipRange.indexOf('-') !== -1;

export const getIPRange = (ip1: string, ip2?: string): string[] => {
    const ip1v4 = getIPv4(ip1);
    const ip1v6 = getIPv6(ip1);

    //
    // Two IPs
    //
    if (ip2) {
        // IPv4
        const ip2v4 = getIPv4(ip2);
        if (ip1v4.valid && ip2v4.valid && !ip1v4.parsedSubnet && !ip2v4.parsedSubnet) {
            return getRangev4(ip1v4.correctForm(), ip2v4.correctForm());
        }

        // IPv6
        const ip2v6 = getIPv6(ip2);
        if (ip1v6.valid && ip2v6.valid && !ip1v6.parsedSubnet && !ip2v6.parsedSubnet) {
            return getRangev6(ip1v6.correctForm(), ip2v6.correctForm());
        }

        // IPs do not match version, or are invalid
        throw new Error(
            'Cannot get range of two IPs if they are not both valid and the same version'
        );
    }

    //
    // CIDR
    //
    if (isCIDR(ip1v4)) {
        return getRangev4(ip1v4.startAddress().correctForm(), ip1v4.endAddress().correctForm());
    }

    if (isCIDR(ip1v6)) {
        return getRangev6(ip1v6.startAddress().correctForm(), ip1v6.endAddress().correctForm());
    }

    //
    // Hyphenated Range
    //
    if (isRange(ip1)) {
        const [firstAddress, lastAddress] = ip1.split('-');
        return getIPRange(firstAddress, lastAddress);
    }

    // Did not match any of the above
    throw new Error('IP supplied is not valid');
};

export function validateIpRange(input: string, type: 'include' | 'exclude'): string | null {
    const cidr: boolean = input.includes('/');
    const arr: string[] = input.split('/');

    // There should only be 1 '/' separator at most.
    if (arr.length > 2) {
        return 'CIDR IP addresses can only have one "/"';
    }
    if (cidr && type === 'exclude') {
        return 'Exclude IP addresses may not include wildcards, ranges or CIDR notation';
    }
    // Truthy separator has to be nonempty and match the following regex.
    // Regex essentially says it can be a number between 0 and 32, with no leading zeroes.
    if (cidr && (!arr[1] || arr[1] === '' || !/^([0-9]|[1-2][0-9]|3[0-2])$/.test(arr[1]))) {
        return 'CIDR bit length is invalid';
    }

    // Separate octets for analysis.
    const tmpArr: string[] = arr[0].split('.');

    // tests for 4 octets
    if (tmpArr.length !== 4) {
        return 'IP address can only have four octets, i.e. 10.0.0.1';
    }

    // Prevents '...' or '0.0.0.', etc.
    for (const octet of tmpArr) {
        if (octet.length === 0) {
            return 'Octets cannot be empty';
        }
    }

    // Tests the first octet
    if (isNaN(+tmpArr[0])) {
        return 'The first three octets can only contain numbers';
    }
    if (+tmpArr[0] > 255) {
        return 'Octets can only be values 0 through 255';
    }
    if (+tmpArr[0] < 0) {
        return 'Octets can only be values 0 through 255';
    }

    switch (type) {
        case 'include':
            // Tests the middle two octets
            for (let i = 1; i <= 2; i++) {
                if (isNaN(+tmpArr[i])) {
                    return 'The first three octets can only contain numbers, i.e. 10.0.0.*';
                }
                if (+tmpArr[i] > 255) {
                    return 'Octets can only be values 0 through 255';
                }
                if (+tmpArr[i] < 0) {
                    return 'Octets can only be values 0 through 255';
                }
            }
            // tests the final octet
            if (tmpArr[3].indexOf('-') !== -1) {
                const howManyHyphens = (tmpArr[3].match(/-/g) || []).length;
                if (howManyHyphens === 1) {
                    // Contains range
                    const octArr4 = tmpArr[3].split('-');
                    for (let i = 0; i < 2; i++) {
                        if (isNaN(+octArr4[i])) {
                            return 'The last octet range must be between two numbers, i.e. 10.0.0.1-254';
                        }
                        if (+octArr4[i] > 255) {
                            return 'Last octet can only define ranges using values 1 through 255.';
                        }
                        if (+octArr4[i] <= 0) {
                            return 'Last octet can only define ranges using values 1 through 255.';
                        }
                    }
                } else {
                    return 'Ranges can only contain one hyphen';
                }
            } else if (tmpArr[3].indexOf('*') !== -1) {
                // wildcard
                if (tmpArr[3] !== '*') {
                    return 'If an octet contains a wildcard, it cannot contain anything else';
                }
            } else {
                if (+tmpArr[3] > 255) {
                    return 'Last octet can only be values 0 through 255, a range or a wildcard';
                }
                if (+tmpArr[3] < 0) {
                    return 'Last octet can only be values 0 through 255, a range or a wildcard';
                }
                if (isNaN(+tmpArr[3])) {
                    return 'The last octet must contain a number, range or wildcard, i.e. 10.0.0.1, 10.0.0.1-10 or 10.0.0.*';
                }
            }
            break;
        case 'exclude':
            for (let i = 1; i < 2; i++) {
                if (isNaN(+tmpArr[i])) {
                    return 'Exclude IP addresses may not have wildcards, ranges or CIDR notation';
                }
                if (+tmpArr[i] > 255) {
                    return 'The middle two octets must be between 0 and 255';
                }
                if (+tmpArr[i] < 0) {
                    return 'The middle two octets must be between 0 and 255';
                }
            }
            if (isNaN(+tmpArr[3])) {
                return 'Exclude IP addresses may not have wildcards, ranges or CIDR notation';
            }
            if (+tmpArr[3] > 255) {
                return 'The last octet can only be values 0 through 255';
            }
            if (+tmpArr[3] < 0) {
                return 'The last octet can only be values 0 through 255';
            }
            break;
        default:
            break;
    }
    return null;
}
