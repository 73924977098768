export interface SelectEntityOverlayConfig {
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
    // availableEntities: any[];
}

export const selectEntityOverlayDefaultConfig: SelectEntityOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'select-entity-backdrop',
    panelClass: 'select-entity-overlay-panel'
    // availableEntities: []
};
