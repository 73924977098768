<div mat-dialog-content>
    <h1>Which toner was defective?</h1>
    <div class="select-toner" [class.active]="(page$ | async) === 1">
        <p>
            Select the toner you need to replace and we'll send a FREE replacement via NEXT DAY AIR.
        </p>
        <ptkr-search-bar
            placeholder="Filter"
            (searchClicked)="searchItems($event)"
        ></ptkr-search-bar>
        <ptkr-compatible-order-table
            [items]="items$ | async"
            [replacement]="true"
            (viewCart)="viewCart()"
            (replaceToner)="selectToner($event)"
        >
        </ptkr-compatible-order-table>
    </div>
    <div class="info" [class.active]="(page$ | async) === 2">
        <p>
            You have selected
            <strong
                >{{ (selectedItem$ | async)?.make }} {{ (selectedItem$ | async)?.model }}</strong
            >
        </p>
        <div class="field-group">
            <label>
                <strong>Please enter warranty code</strong> (the warranty code is printed on a white
                label located on the physical toner)
            </label>
            <mat-form-field floatLabel="never" appearance="outline">
                <input matInput type="text" [formControl]="warrantyCode" />
                <mat-error [ptkrErrorControl]="warrantyCode"></mat-error>
            </mat-form-field>
        </div>
        <div class="field-group">
            <label> <strong>Please tell us why this toner was defective?</strong> </label>
            <mat-form-field floatLabel="never" appearance="outline">
                <mat-select [formControl]="warrantyReason">
                    <mat-option
                        *ngFor="let w of WarrantyReasons.asArray"
                        [value]="WarrantyReasons.toString(w)"
                    >
                        {{ WarrantyReasons.toString(w) }}
                    </mat-option>
                </mat-select>
                <mat-error [ptkrErrorControl]="warrantyReason"></mat-error>
            </mat-form-field>
        </div>
        <button mat-flat-button color="accent" (click)="proceedToCheckout()">
            Proceed to FREE checkout
        </button>
    </div>
</div>
