import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Entity } from '@app/models';
import { fromEntity } from '@app/state/selectors';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ptkr-post-download-modal',
    templateUrl: './post-download-modal.component.html',
    styleUrls: ['./post-download-modal.component.scss']
})
export class PostDownloadModalComponent implements OnInit {
    public entity$: Observable<Entity> = this._store.pipe(select(fromEntity.currentEntity));
    public entityKey: string;
    public entityName: string;
    public buttonDisabled: boolean = true;

    constructor(
        private _store: Store<GlobalStore>,
        private _dialogRef: MatDialogRef<PostDownloadModalComponent>
    ) { }

    public ngOnInit(): void {
        this.entity$.subscribe((e: Entity) => {
            this.entityKey = e._id;
            this.entityName = e.name;
        });

        setTimeout(() => this.buttonDisabled = false, 10000);
    }

    public close(): void {
        this._dialogRef.close();
    }

}
