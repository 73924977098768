import {
    Component,
    OnInit,
    ChangeDetectionStrategy
} from '@angular/core';
import { LanguageSelector } from '@app/core/models/language-selector';
import { LanguageSelectorService, LocalStorageService } from '@app/services';

@Component({
    selector: 'ptkr-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectorComponent implements OnInit {
    public currentLanguage: string;
    public availableLanguages: LanguageSelector[] = LanguageSelectorService.availableLanguages;

    constructor(
        private _localStorage: LocalStorageService
    ) {}

    ngOnInit(): void {
        const localLanguage = this._localStorage.getItem(LocalStorageService.Key.Language);
        this.currentLanguage = localLanguage ?? this.availableLanguages[0].code;
    }

    public changeLanguage(language: string): void {
        if (language) {
            localStorage.setItem(LocalStorageService.Key.Language, language);
            location.reload();
        }
    }
}
