enum ScheduleTypeDayOfWeek {
    Sun = '[ScheduleTypeDayOfWeek] sun',
    Mon = '[ScheduleTypeDayOfWeek] mon',
    Tues = '[ScheduleTypeDayOfWeek] tues',
    Wed = '[ScheduleTypeDayOfWeek] wed',
    Thur = '[ScheduleTypeDayOfWeek] thur',
    Fri = '[ScheduleTypeDayOfWeek] fri',
    Sat = '[ScheduleTypeDayOfWeek] sat',
    WeekDay = '[ScheduleTypeDayOfWeek] weekday',
    WeekendDay = '[ScheduleTypeDayOfWeek] weekend day',
    Day = '[ScheduleTypeDayOfWeek] day'
}
namespace ScheduleTypeDayOfWeek {
    export function toString(type: ScheduleTypeDayOfWeek): string {
        switch (type) {
            case ScheduleTypeDayOfWeek.Sun:
                return 'Sunday';
            case ScheduleTypeDayOfWeek.Mon:
                return 'Monday';
            case ScheduleTypeDayOfWeek.Tues:
                return 'Tuesday';
            case ScheduleTypeDayOfWeek.Wed:
                return 'Wednesday';
            case ScheduleTypeDayOfWeek.Thur:
                return 'Thursday';
            case ScheduleTypeDayOfWeek.Fri:
                return 'Friday';
            case ScheduleTypeDayOfWeek.Sat:
                return 'Saturday';
            case ScheduleTypeDayOfWeek.WeekDay:
                return 'Weekday';
            case ScheduleTypeDayOfWeek.WeekendDay:
                return 'Weekend Day';
            case ScheduleTypeDayOfWeek.Day:
                return 'Day';
            default:
                return '';
        }
    }
    export const types: ScheduleTypeDayOfWeek[] = [
        ScheduleTypeDayOfWeek.Sun,
        ScheduleTypeDayOfWeek.Mon,
        ScheduleTypeDayOfWeek.Tues,
        ScheduleTypeDayOfWeek.Wed,
        ScheduleTypeDayOfWeek.Thur,
        ScheduleTypeDayOfWeek.Fri,
        ScheduleTypeDayOfWeek.Sat,
        ScheduleTypeDayOfWeek.Day,
        ScheduleTypeDayOfWeek.WeekDay,
        ScheduleTypeDayOfWeek.WeekendDay
    ];
}
export { ScheduleTypeDayOfWeek };
