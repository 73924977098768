import { createSelector } from '@ngrx/store';
import { selectUser } from '../store/global.store';
import { UserStore } from '../store/user.store';

export const fromUser = {
    currentUser: createSelector(selectUser, (store: UserStore) => store),
    userId: createSelector(selectUser, (store: UserStore) => store.id),
    permissions: createSelector(selectUser, (store: UserStore) => store.permissions),
    entityId: createSelector(selectUser, (store: UserStore) => store.entity.entityKey),
};
