import { EntityStatsList, EntityStatsListModel } from './EntityStatsList';
import { User } from '../user/User';

import { EntityModel as BaseEntityModel, Entity as BaseEntity } from '@libs/iso/core';
import * as moment from 'moment';

export interface EntityModel extends BaseEntityModel {
    _id: string;
    createdDate: moment.Moment;
    parentEntityKey: string;
    genealogy: string[];
    globalAdminKey: string;
    salesRepKey: string;
    breadcrumb: { _id: string; name: string }[];
    children: { _id: string; name: string }[];
    salesRep: User;
    entityStatsList: EntityStatsListModel;
    expiresOn: Date;
}

export class Entity extends BaseEntity implements EntityModel {
    public _id: string = '';
    public createdDate: moment.Moment = null;
    public parentEntityKey: string = '';
    public genealogy: string[] = [];
    public globalAdminKey: string = '';
    public salesRepKey: string = '';
    public breadcrumb: { _id: string; name: string }[] = [];
    public children: { _id: string; name: string }[] = [];
    public salesRep: User = new User();
    public entityStatsList: EntityStatsList = new EntityStatsList();
    public expiresOn: Date = null;

    constructor(params?: Partial<EntityModel>) {
        super(params);
        if (!!params) {
            this._id = params._id || this._id;
            if (!!params.createdDate) {
                this.createdDate = moment(params.createdDate);
            }
            this.parentEntityKey = params.parentEntityKey || this.parentEntityKey;
            if (Array.isArray(params.genealogy)) {
                this.genealogy = params.genealogy;
            }
            this.globalAdminKey = params.globalAdminKey || this.globalAdminKey;
            this.salesRepKey = params.salesRepKey || this.salesRepKey;
            if (Array.isArray(params.breadcrumb)) {
                this.breadcrumb = params.breadcrumb;
            }
            if (Array.isArray(params.children) || Number.isInteger(params.children)) {
                this.children = params.children;
            }
            if (!!params.salesRep) {
                this.salesRep = new User(params.salesRep);
            }
            if (!!params.entityStatsList) {
                this.entityStatsList = new EntityStatsList(params.entityStatsList);
            }
            this.expiresOn = params.expiresOn !== null ? moment(params.expiresOn).toDate() : this.expiresOn;
        }
    }
}
