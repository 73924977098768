import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NewJob } from '@libs/iso/core/models';
import { MatDialogRef } from '@angular/material/dialog';
import { RtsService } from '@app/core/services/rts.service';
import { JobService } from '@app/core/services/job.service';
import { GlobalStore } from '@app/state/store';
import { Store, select } from '@ngrx/store';
import { fromEntity } from '@app/state/selectors';
import { JobStatusTypes } from '@libs/iso/core';

@Component({
    selector: 'ptkr-jobs-modal',
    templateUrl: './jobs-modal.component.html',
    styleUrls: ['./jobs-modal.component.scss'],
    providers: [JobService]
})
export class JobsModalComponent implements OnInit, AfterViewInit {
    public jobs: Array<NewJob>;
    public entityId: string;

    constructor(
        public dialogRef: MatDialogRef<JobsModalComponent>,
        private _rtsService: RtsService,
        private _jobService: JobService,
        private _store: Store<GlobalStore>
    ) {
    }

    ngOnInit(): void {
        this._store.pipe(select(fromEntity.entityId)).subscribe(id => {
            this.entityId = id;
        });
    }

    ngAfterViewInit(): void {
        this.refreshData();
    }

    public refreshData(): void {
        this._jobService.getActiveJobsByEntity(this.entityId).subscribe(jobs => {
            console.log('jobs', jobs);
            this.jobs = jobs;
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public jobsExist(): boolean {
        return true;
        // return this.yourJobs.length > 0 || this.automaticJobs.length > 0;
    }

    public deleteJobs(jobs: Array<NewJob>): void {
        const ids: Array<any> = jobs.filter(
                job =>
                    job.jobStatus.type === JobStatusTypes.Sent ||
                    job.jobStatus.type === JobStatusTypes.Completed ||
                    job.jobStatus.type === JobStatusTypes.Failed ||
                    job.jobStatus.type === JobStatusTypes.Rescheduled ||
                    job.jobStatus.type === JobStatusTypes.Stopped
            )
            .map(deletableJobs => deletableJobs._id);
        this._jobService.deleteJobsById(ids).subscribe(res => {
            this.refreshData();
        });
    }
}
