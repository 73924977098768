import { IAcuItem, AcuItem } from '../item/AcuItem';

export interface InvoiceItemModel {
    item: IAcuItem;
    price: number;
    quantity: number;
    total: number;
    itemStatus: string;
}

interface InvoiceItemConstructorParams extends InvoiceItemModel {}

export class InvoiceItem implements InvoiceItemModel {
    public item: AcuItem = null;
    public price: number = 0;
    public quantity: number = 0;
    public total: number = 0;
    public itemStatus: string = '';

    constructor(params?: Partial<InvoiceItemConstructorParams>) {
        if (!!params) {
            if (!!params.item) {
                this.item = new AcuItem(params.item);
            }
            this.price = params.price || this.price;
            this.quantity = params.quantity || this.quantity;
            this.total = params.total || this.total;
            this.itemStatus = params.itemStatus || this.itemStatus;
        }
    }
}
