<div class="time-frame-selector">
    <mat-button-toggle-group
        color="primary"
        (change)="selectorChange($event)"
        name="timeFrame"
        aria-label="Time Frame"
    >
        <mat-button-toggle *ngFor="let o of options" [value]="o" [checked]="selected === o">{{
            TimeFrame.toString(o)
        }}</mat-button-toggle>
        <mat-button-toggle
            *ngIf="allowCustom"
            [value]="TimeFrame.Custom"
            [checked]="selected === TimeFrame.Custom"
            >Custom</mat-button-toggle
        >
    </mat-button-toggle-group>
    <br />
    <div *ngIf="allowCustom && selected === TimeFrame.Custom">
        <mat-form-field appearance="outline" floatLabel="always">
            <input
                matInput
                [matDatepicker]="startDate"
                [max]="customEndDate"
                placeholder="Start Date (MM/DD/YYYY)"
                (dateChange)="startDateChange($event)"
                (keypress.enter)="$event.preventDefault()"
            />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always">
            <input
                matInput
                [matDatepicker]="endDate"
                [min]="customStartDate"
                placeholder="End Date (MM/DD/YYYY)"
                (dateChange)="endDateChange($event)"
                (keypress.enter)="$event.stopPropagation()"
            />
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
    </div>
</div>
