<div class="container">
    <section>
        <div class="heading">Bulk Device Update</div>
        <div class="subheading">
            Use this tool to export your device list and import changes in bulk.
            By default, only changes to the <code>location</code>,
            <code>assetId</code>, <code>customSerialNum</code> and
            <code>note</code> columns will be applied. If the device is local
            however, changes to the <code>total</code>, <code>totalColor</code>
            and <code>totalBlack</code> columns will also be applied. Note that
            these changes are <em>Permanent and Irreversible</em>.
        </div>
    </section>

    <section>
        <mat-accordion>
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Step 1: Export CSV
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <button mat-flat-button color="primary" (click)="exportCsv()">
                    Export devices
                </button>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Step 2: Import changes
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="csv-import">
                    <div class="form-group">
                        <input type="file" (change)="onFileSelect($event.target)" name="myfile" />
                    </div>
                </div>
                <button
                    mat-flat-button
                    class="m-t-10"
                    color="warn"
                    (click)="importData()"
                    matTooltip="Caution, this action is irreversible"
                >
                    Import changes
                </button>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>
