import { InstallsTableParams } from '@app/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { InstallService } from '@app/services';
import { catchError, take } from 'rxjs/operators';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { PaginatedList, Install, IWithEntityName, WithReportingDeviceFacets } from '@libs/iso/core';
import { Store, select } from '@ngrx/store';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { fromEntity } from '@app/state/selectors';

@Injectable()
export class InstallsListResolver implements Resolve<PaginatedList<Install & IWithEntityName & WithReportingDeviceFacets>> {
    constructor(private _installService: InstallService, private _store: Store<GlobalStore>) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<PaginatedList<Install & IWithEntityName & WithReportingDeviceFacets>> {
        this._store.dispatch(new ToggleGlobalSpinnerAction(true));
        let entityKey: string = '';
        this._store
            .pipe(select(fromEntity.currentEntity), take(1))
            .subscribe(e => (entityKey = e._id));
        const initQParams = route.queryParams;
        const defaultLimit = 10;
        const params: InstallsTableParams = {
            searchTerm: initQParams.searchTerm,
            sort: initQParams.sortBy,
            sortOrder: coerceNumberProperty(initQParams.sortOrder) as 1 | -1,
            page: coerceNumberProperty(initQParams.page) || 1,
            limit: coerceNumberProperty(initQParams.limit) || defaultLimit,
            includeChildren: coerceBooleanProperty(initQParams.includeChildren) || false,
            excludeDisabled: coerceBooleanProperty(initQParams.excludeDisabled) || false
        };
        return this._installService
            .getInstalls(
                entityKey,
                params.searchTerm,
                params.page,
                params.limit,
                params.sort,
                params.sortOrder as 1 | -1,
                params.includeChildren,
                params.excludeDisabled,
            )
            .pipe(
                catchError(error => {
                    console.error('error', error);
                    return of(
                        new PaginatedList<Install & IWithEntityName & WithReportingDeviceFacets>()
                    );
                })
            );
    }
}
