import { Directive, ElementRef, ViewContainerRef, TemplateRef, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Permission } from '@libs/iso/core';
import { GlobalStore } from '@app/state/store';
import { fromUser } from '@app/state/selectors';

@Directive({
    selector: '[ptkrPermission]'
})
export class PermissionDirective {
    private _permissions: Permission[] = [];
    @Input('ptkrPermission')
    public get permission(): Permission | Permission[] {
        return this._permissions;
    }
    public set permission(value: Permission | Permission[]) {
        if (Array.isArray(value)) {
            this._permissions = value;
        } else {
            this._permissions = [value];
        }
        this._updateView();
    }

    @Input('requireAll')
    public requireAll: boolean = false;

    private _el: ElementRef;
    private _userPermissions: Permission[] = [];

    constructor(
        public el: ElementRef,
        private _store: Store<GlobalStore>,
        private _viewContainer: ViewContainerRef,
        private _templateRef: TemplateRef<any>
    ) {
        this._el = el;
        this._store
            .pipe(
                select(fromUser.permissions),
                take(1)
            )
            .subscribe(perms => (this._userPermissions = perms));
    }

    private _updateView(): void {
        if (!this._permissions || this._permissions.length === 0) {
            this._viewContainer.createEmbeddedView(this._templateRef);
            return;
        }
        if (Permission.hasPermission(this._permissions, this._userPermissions, this.requireAll)) {
            this._viewContainer.createEmbeddedView(this._templateRef);
        } else {
            this._viewContainer.clear();
        }
    }
}
