import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    RiafoxHttpModule,
    UrlInterceptor,
    AuthInterceptor,
    RIAFOX_HTTP_WEB_SERVER_URL,
    RIAFOX_HTTP_AUTH_TOKEN_STREAM
} from '@libs/web/http';
import { StoreModule, Store, select } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@app/env/environment';
import { reducers } from '@app/state/reducers';
import { effects } from '@app/state/effects';
import { GlobalStore } from '@app/state/store';
import { fromAuth } from '@app/state/selectors';
import { services } from '@app/services';
import { guards } from '@app/routes/guards';
import { resolvers } from '@app/routes/resolvers';
import { UnauthorizedInterceptor } from './interceptors';
import { SharedModule } from '@app/shared/shared.module';
import { Observable } from 'rxjs';
import { NgDragDropModule } from 'ng-drag-drop';

export function authToken(store: Store<GlobalStore>): Observable<string> {
    return store.pipe(select(fromAuth.token));
}

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: true
        }),
        RiafoxHttpModule,
        SharedModule,
        NgDragDropModule.forRoot()
    ],
    declarations: [],
    providers: [
        ...services,
        ...resolvers,
        ...guards,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UrlInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: RIAFOX_HTTP_WEB_SERVER_URL,
            useValue: environment.webServer
        },
        {
            provide: RIAFOX_HTTP_AUTH_TOKEN_STREAM,
            useFactory: authToken,
            deps: [Store]
        }
    ]
})
export class CoreModule {}
