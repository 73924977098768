import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { RouterService, NotificationService, CartService } from '@app/services';
import {
    CartActions,
    RecalculateOrderAction,
    SelectShippingAddressAction,
    UpdateEstimatedTaxAction,
    PlaceOrderAction,
    OrderFailureAction,
    OrderCompleteAction,
    SelectShippingMethodAction,
    ToggleGlobalSpinnerAction,
    InvalidTaxAction
} from '@app/state/actions';
import { tap, switchMap, withLatestFrom, catchError, map } from 'rxjs/operators';
import { GlobalStore, CartStore } from '@app/state/store';
import { fromCart } from '@app/state/selectors';

@Injectable()
export class CartEffects {
    constructor(
        private _actions$: Actions,
        private _routerService: RouterService,
        private _notificationService: NotificationService,
        private _cartService: CartService,
        private _store: Store<GlobalStore>
    ) {}

    @Effect({ dispatch: false })
    public startCheckout$: Observable<Action> = this._actions$.pipe(
        ofType(CartActions.StartCheckout),
        withLatestFrom(this._store.pipe(select(fromCart.self))),
        tap(([action, cartState]) => {
            if (cartState.selectedCarts.length > 0) {
                this._routerService.navigate('/order/carts/shipping');
            } else {
                this._notificationService.error('No carts are currently selected!');
            }
        }),
        switchMap(() => of(null))
    );

    @Effect()
    public recalculationsRequired: Observable<Action> = this._actions$.pipe(
        ofType(
            CartActions.SelectCart,
            CartActions.RemoveCart,
            CartActions.Clear,
            CartActions.SelectShippingMethod,
            CartActions.UpdateCartItem
        ),
        switchMap(() => of(new RecalculateOrderAction()))
    );

    @Effect()
    public selectShippingAddress$: Observable<Action> = this._actions$.pipe(
        ofType(CartActions.SelectShippingAddress, CartActions.SelectShippingMethod),
        withLatestFrom(this._store.pipe(select(fromCart.self))),
        switchMap(
            ([action, cartState]: [
                SelectShippingAddressAction | SelectShippingMethodAction,
                CartStore
            ]) => {
                if (!!cartState.order.shippingAddress && !!cartState.order.shippingMethod) {
                    return this._cartService
                        .getEstimatedTax(
                            cartState.selectedCarts.reduce((acc, next) => [...acc, next._id], []),
                            cartState.order.shippingAddress,
                            cartState.order.shippingMethod
                        )
                        .pipe(
                            map(res => new UpdateEstimatedTaxAction(res.estTax)),
                            catchError(err => {
                                this._notificationService.error(err);
                                const message =
                                    typeof err === 'string'
                                        ? err
                                        : err.statusText === 'OK'
                                            ? err.message
                                            : err.statusText;
                                return of(new InvalidTaxAction(message));
                            })
                        );
                } else {
                    return of(new UpdateEstimatedTaxAction(0));
                }
            }
        ),
        catchError(err => {
            this._notificationService.error(err);
            const message =
                typeof err === 'string'
                    ? err
                    : err.statusText === 'OK'
                        ? err.message
                        : err.statusText;
            return of(new InvalidTaxAction(message));
        })
    );

    @Effect({ dispatch: false })
    public completeShipping$: Observable<Action> = this._actions$.pipe(
        ofType(CartActions.CompleteShipping),
        withLatestFrom(this._store.pipe(select(fromCart.self))),
        tap(([action, cartState]) => {
            if (cartState.order.isWarranty === true && cartState.order.shippingAddress) {
                this._routerService.navigate('/order/carts/review');
            } else if (!!cartState.order.shippingAddress && !!cartState.order.shippingMethod) {
                if (!!cartState.taxErrorMessage) {
                    this._notificationService.error(cartState.taxErrorMessage);
                } else {
                    this._routerService.navigate('/order/carts/payment');
                }
            } else {
                this._notificationService.error('Missing shipping selections');
            }
        }),
        switchMap(() => of(null))
    );

    @Effect({ dispatch: false })
    public completePayment$: Observable<Action> = this._actions$.pipe(
        ofType(CartActions.CompletePayment),
        withLatestFrom(this._store.pipe(select(fromCart.self))),
        tap(([action, cartState]) => {
            if (!!cartState.order.paymentMethodId) {
                this._routerService.navigate('/order/carts/review');
            } else {
                this._notificationService.error('Select a payment method to continue');
            }
        }),
        switchMap(() => of(null))
    );

    @Effect()
    public placeOrder$: Observable<any> = this._actions$.pipe(
        ofType(CartActions.PlaceOrder),
        tap(() => console.log('place order effect')),
        withLatestFrom(this._store.pipe(select(fromCart.self))),
        switchMap(([action, cartState]: [PlaceOrderAction, CartStore]) =>
            this._cartService.placeOrder(cartState.selectedCarts, cartState.order).pipe(
                switchMap(res => {
                    if (!!res.orderNumber) {
                        this._notificationService.success('Order placed successfully!');
                        return of(this._store.dispatch(new OrderCompleteAction(res)));
                    } else {
                        this._notificationService.error('Unable to place order');
                        return of(this._store.dispatch(new OrderFailureAction()));
                    }
                }),
                catchError(err => {
                    this._notificationService.error(err);
                    return of(new OrderFailureAction());
                })
            )
        )
    );

    @Effect()
    public orderComplete$: Observable<Action> = this._actions$.pipe(
        ofType(CartActions.OrderComplete),
        tap((action: OrderCompleteAction) => {
            this._routerService.navigate('/order/confirm', {
                queryParams: action.payload
            });
        }),
        switchMap(() => of(new ToggleGlobalSpinnerAction(false)))
    );

    @Effect()
    public orderFailure$: Observable<Action> = this._actions$.pipe(
        ofType(CartActions.OrderFailure),
        switchMap(() => of(new ToggleGlobalSpinnerAction(false)))
    );
}
