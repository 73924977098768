import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportScheduledEvent } from '@libs/iso/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableConfiguration } from '@app/models';

@Component({
    selector: 'ptkr-report-scheduled-events-modal',
    templateUrl: './report-scheduled-events-modal.component.html',
    styleUrls: ['./report-scheduled-events-modal.component.scss']
})
export class ReportScheduledEventsModalComponent implements OnInit {
    public dataSource: MatTableDataSource<ReportScheduledEvent> = new MatTableDataSource();
    public tableConfig: TableConfiguration = new TableConfiguration();

    constructor(
        public dialogRef: MatDialogRef<ReportScheduledEventsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            events: ReportScheduledEvent[];
        }
    ) {}

    ngOnInit(): void {
        this.tableConfig.columns.addColumns(['timestamp', 'type', 'message']);
        this.dataSource = new MatTableDataSource(this.data.events);
    }
}
