import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import { OverlayService } from './overlay.service';
import { RoleService } from './role.service';
import { RouterService } from './router.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { EntityService } from './entity.service';
import { WindowSizeService } from './window-size.service';
import { DeviceService } from './device.service';
import { ItemService } from './item.service';
import { OrderService } from './order.service';
import { InvoiceService } from './invoice.service';
import { StripeService } from './stripe.service';
import { CartService } from './cart.service';
import { InstallService } from './install.service';
import { RtsService } from './rts.service';
import { ValidationService } from './validation.service';
import { JobService } from '@app/core/services/job.service';
import { JobRunnerService } from '@app/core/services/job-runner.service';
import { DgiService } from './dgi.service';
import { DGICandidateService } from './dgiCandidate.service';
import { MeterTypeService } from '@app/core/services/meterType.service';
import { DefaultMeterTypeService } from './defaultMeterType.service';
import { JobBatchService } from './jobbatch.service';
import { ProxyService } from './proxy.service';
import { FileStorageService } from './file-storage.service';
import LocalStorageService from './local-storage.service';
import { QueryEngineService } from './queryEngine.service';
import { ReportService } from './report.service';
import { ReportScheduledService } from './reportScheduled.service';
import { InstallNetworkTopologyService } from './install-network-topology.service';
import { WebadminV2Service } from './webadmin-v2.service';
import { SupplyService } from './supply.service';
import { LanguageSelectorService } from './language-selector.service';
import { BrandingService } from '@app/core/services/branding.service';

export const services = [
    AlertService,
    AuthService,
    NotificationService,
    OverlayService,
    RoleService,
    RouterService,
    StorageService,
    UserService,
    WindowSizeService,
    EntityService,
    DeviceService,
    ItemService,
    CartService,
    InvoiceService,
    StripeService,
    OrderService,
    InstallService,
    RtsService,
    ValidationService,
    JobService,
    JobRunnerService,
    DgiService,
    DGICandidateService,
    MeterTypeService,
    DefaultMeterTypeService,
    JobBatchService,
    ProxyService,
    FileStorageService,
    LocalStorageService,
    QueryEngineService,
    ReportService,
    ReportScheduledService,
    InstallNetworkTopologyService,
    WebadminV2Service,
    SupplyService,
    BrandingService,
];

export {
    AlertService,
    AuthService,
    NotificationService,
    OverlayService,
    RoleService,
    RouterService,
    StorageService,
    UserService,
    WindowSizeService,
    EntityService,
    DeviceService,
    ItemService,
    OrderService,
    InvoiceService,
    StripeService,
    CartService,
    InstallService,
    RtsService,
    ValidationService,
    JobService,
    JobRunnerService,
    DgiService,
    DGICandidateService,
    MeterTypeService,
    DefaultMeterTypeService,
    JobBatchService,
    ProxyService,
    FileStorageService,
    LocalStorageService,
    QueryEngineService,
    ReportService,
    ReportScheduledService,
    InstallNetworkTopologyService,
    WebadminV2Service,
    SupplyService,
    LanguageSelectorService,
    BrandingService,
};
