import { Injectable } from '@angular/core';
import { HttpService, Get, Query } from '@libs/web/http';
import { Observable } from 'rxjs';

@Injectable()
export class DefaultMeterTypeService extends HttpService {
    // Spencer 9/25/20 -
    // This resource will tell the browser it can request this resource asyncrhonously about every
    // 30 seconds to keep it up to date. The browser will cache the resource. So this way, any time
    // we need the default meter tyepe map, we can assume we have not gotten it yet and it should be fine,
    // like any other resource.
    @Get('/defaultMeterType/searchDefaultMeterType')
    public searchDefaultMeterType(
        @Query('searchTerm') searchTerm: string,
        @Query('limit') limit: number
    ): Observable<any> {
        return null;
    }
}
