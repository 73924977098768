import { BaseDoc, BaseDocModel } from '@libs/iso/core';
import { Entity } from '@app/models';

export interface EntityListModel extends BaseDocModel {
    parent: Entity;
    entityList: Entity[];
}

interface EntityListConstructorParams extends EntityListModel {}

export class EntityList extends BaseDoc implements EntityListModel {
    public parent: Entity = null;
    public entityList: Entity[] = [];

    constructor(params?: Partial<EntityListConstructorParams>) {
        // console.log(params);
        super();
        if (!!params) {
            this.parent = params.parent || this.parent;
            this.entityList = params.entityList || this.entityList;
        }
    }
}
