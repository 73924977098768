// The SupplyColorant enum is used by the DCA service module
enum SupplyColorant {
    Black = 'black',
    Black2 = 'black2',
    Cyan = 'cyan',
    Magenta = 'magenta',
    Yellow = 'yellow',
    BA = 'bondingAgent',
    NotApplicable = 'N/A',
    Blue = 'blue',
    Gray = 'gray',
    Green = 'green',
    LightBlack = 'lightBlack',
    LightCyan = 'lightCyan',
    LightGray = 'lightGray',
    LightMagenta = 'lightMagenta',
    MatteBlack = 'matteBlack',
    Orange = 'orange',
    PhotoBlack = 'photoBlack',
    PhotoCyan = 'photoCyan',
    PhotoMagenta = 'photoMagenta',
    Red = 'red',
    TriColor = 'triColor',
    VividMagenta = 'vividMagenta',
    VividLightMagenta = 'vividLightMagenta',
    DarkGray = 'darkGray',
    Clear = 'clear',
    White = 'white',
    Silver = 'silver',
    Gold = 'gold',
    Violet = 'violet',
}

namespace SupplyColorant {
    export function toString(type: SupplyColorant): string {
        switch (type) {
            case SupplyColorant.Black:
                return 'black';
            case SupplyColorant.Black2:
                return 'black2';
            case SupplyColorant.Cyan:
                return 'cyan';
            case SupplyColorant.Magenta:
                return 'magenta';
            case SupplyColorant.Yellow:
                return 'yellow';
            case SupplyColorant.BA:
                return 'bondingAgent';
            case SupplyColorant.NotApplicable:
                return 'n/a';
            case SupplyColorant.Blue:
                return 'blue';
            case SupplyColorant.Gray:
                return 'gray';
            case SupplyColorant.Green:
                return 'green';
            case SupplyColorant.LightBlack:
                return 'light black';
            case SupplyColorant.LightCyan:
                return 'light cyan';
            case SupplyColorant.LightGray:
                return 'light gray';
            case SupplyColorant.LightMagenta:
                return 'light magenta';
            case SupplyColorant.MatteBlack:
                return 'matte black';
            case SupplyColorant.Orange:
                return 'orange';
            case SupplyColorant.PhotoBlack:
                return 'photo black';
            case SupplyColorant.PhotoCyan:
                return 'photo cyan';
            case SupplyColorant.PhotoMagenta:
                return 'photo magenta';
            case SupplyColorant.Red:
                return 'red';
            case SupplyColorant.TriColor:
                return 'tri-color';
            case SupplyColorant.VividMagenta:
                return 'vivid magenta';
            case SupplyColorant.VividLightMagenta:
                return 'vivid light magenta';
            case SupplyColorant.DarkGray:
                return 'dark gray';
            case SupplyColorant.Clear:
                return 'clear';
            case SupplyColorant.White:
                return 'white';
            case SupplyColorant.Silver:
                return 'silver';
            case SupplyColorant.Gold:
                return 'gold';
            case SupplyColorant.Violet:
                return 'violet';
            default:
                return 'n/a';
        }
    }

    export function bgColorHex(type: SupplyColorant): string {
        switch (type) {
            case SupplyColorant.Black:
                return '#363636';
            case SupplyColorant.Black2:
                return '#363636';
            case SupplyColorant.PhotoBlack:
                return '#363636';
            case SupplyColorant.MatteBlack:
                return '#363636';
            case SupplyColorant.Blue:
                return '#0000FF';
            case SupplyColorant.Cyan:
                return '#1f84c8';
            case SupplyColorant.Gray:
                return '#808080';
            case SupplyColorant.Green:
                return '#48c20e';
            case SupplyColorant.LightBlack:
                return '#363636';
            case SupplyColorant.LightCyan:
                return '#1f84c8';
            case SupplyColorant.LightGray:
                return '#808080';
            case SupplyColorant.LightMagenta:
                return '#ec5665';
            case SupplyColorant.Magenta:
                return '#ec5665';
            case SupplyColorant.NotApplicable:
                return '#666666';
            case SupplyColorant.Orange:
                return '#FFA500';
            case SupplyColorant.PhotoCyan:
                return '#1f84c8';
            case SupplyColorant.PhotoMagenta:
                return '#ec5665';
            case SupplyColorant.Red:
                return '#FF0000';
            case SupplyColorant.Yellow:
                return '#f5ab5a';
            case SupplyColorant.TriColor:
                return '#FFA500';
            case SupplyColorant.VividMagenta:
                return '#ec5665';
            case SupplyColorant.VividLightMagenta:
                return '#ec5665';
            case SupplyColorant.DarkGray:
                return '#808080';
            case SupplyColorant.Clear:
                return '#CCCCCC';
            case SupplyColorant.White:
                return '#CCCCCC';
            case SupplyColorant.Silver:
                return '#CCCCCC';
            case SupplyColorant.Gold:
                return '#FFD700';
            case SupplyColorant.Violet:
                return '#8A2BE2';
            default:
                return '#666666';
        }
    }
}

export { SupplyColorant };
