import { ReportScheduledEventType } from '@libs/iso/core/enums/ReportScheduledEventType';

export interface IReportScheduledEvent {
    timestamp: Date;
    type: ReportScheduledEventType;
    message: string;
}

export class ReportScheduledEvent implements IReportScheduledEvent {
    public message: string;
    public timestamp: Date;
    public type: ReportScheduledEventType;

    constructor(props: IReportScheduledEvent) {
        this.message = props.message;
        this.timestamp = new Date(props.timestamp);
        this.type = props.type;
    }

    public eventTypeToIcon(): string {
        switch (this.type) {
            case ReportScheduledEventType.Info:
                return 'info';
            case ReportScheduledEventType.Warning:
                return 'warning';
            case ReportScheduledEventType.Error:
                return 'error';
            default:
                return null;
        }
    }

    public eventTypeToColor(): string {
        switch (this.type) {
            case ReportScheduledEventType.Info:
                return 'lightskyblue';
            case ReportScheduledEventType.Warning:
                return '#f6ab5a';
            case ReportScheduledEventType.Error:
                return '#FF7474';
            default:
                return null;
        }
    }
}
