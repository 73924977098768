import { ObjectId } from 'bson';
import { EventType } from './EventType';
import { EventActionLog } from './EventActionLog';
import { EventResolutionStatus } from './EventResolutionStatus';
import { IBaseModel, BaseModel } from '../BaseModel';
import { Column } from '../../helpers/columns';
import { CollectionName } from '../../enums/CollectionName';
import { EventEmailedStatus } from './EventEmailedStatus';

export interface IEvent extends IBaseModel {
    entityKey: string | ObjectId;
    installKey: string | ObjectId;
    deviceKey: string | ObjectId;
    type: EventType;
    dtTm: Date;
    description: string;
    pageCount: number;
    colorPageCount: number;
    resolutionStatus: EventResolutionStatus; // not in model, was status, not sure will keep
    acknowledged: EventActionLog;
    resolved: EventActionLog;
    entityName?: string;
    device?: any;
    compatibleItemDetails?: any[];
    supplies: any[];
    eventData: any;
    emailedStatus: EventEmailedStatus;
}

export class Event extends BaseModel implements IEvent {
    public static columns: Array<Column> = [
        ...BaseModel.columns,
        // entityKey: {
        //     name: 'Entity Key',
        //     toReadable: (m: Event): string =>
        //         m.entityKey !== null && m.entityKey !== undefined
        //             ? m.entityKey.toString()
        //             : 'Unspecified',
        //     sortable: true
        // },
        // InstallKey: {
        //     name: 'Install Key',
        //     toReadable: (m: Event): string =>
        //         m.entityKey !== null && m.entityKey !== undefined
        //             ? m.entityKey.toString()
        //             : 'Unspecified',
        //     sortable: true
        // },
        // deviceKey: {
        //     name: 'Device Key',
        //     toReadable: (m: Event): string =>
        //         m.entityKey !== null && m.entityKey !== undefined
        //             ? m.entityKey.toString()
        //             : 'Unspecified',
        //     sortable: true
        // },
        // type: {
        //     name: 'Alert Type',
        //     toReadable: (m: Event): string =>
        //         m.type !== null && m.type !== undefined ? EventType.toString(m.type) : 'Unspecified'
        // },
        // dtTm: {
        //     name: 'Date & Time',
        //     toReadable: (m: Event): string =>
        //         m.dtTm !== null && m.dtTm !== undefined ? m.dtTm.toLocaleString() : 'Unspecified',
        //     sortable: true
        // },
        // description: {
        //     name: 'Description',
        //     toReadable: (m: Event): string =>
        //         m.description !== null && m.description !== undefined
        //             ? m.description
        //             : 'Unspecified'
        // },
        // pageCount: {
        //     name: 'Page Count',
        //     toReadable: (m: Event): string =>
        //         m.pageCount !== null && m.pageCount !== undefined
        //             ? m.pageCount.toString()
        //             : 'Unspecified'
        // },
        // colorPageCount: {
        //     name: 'Color Page Count',
        //     toReadable: (m: Event): string =>
        //         m.colorPageCount !== null && m.colorPageCount !== undefined
        //             ? m.colorPageCount.toString()
        //             : 'Unspecified'
        // },
        // resolutionStatus: {
        //     name: 'Resolution Status',
        //     toReadable: (m: Event): string =>
        //         m.resolutionStatus !== null && m.resolutionStatus !== undefined
        //             ? EventResolutionStatus.toString(m.resolutionStatus)
        //             : 'Unspecified'
        // },
        // 'acknowledged.dtTm': {
        //     name: 'Acknowledged On',
        //     toReadable: (m: Event): string =>
        //         !!m.acknowledged &&
        //         m.acknowledged.dtTm !== null &&
        //         m.acknowledged.dtTm !== undefined
        //             ? m.acknowledged.dtTm.toLocaleString()
        //             : 'Unspecified'
        // },
        // 'acknowledged.userKey': {
        //     name: 'Acknowledged By (id)',
        //     toReadable: (m: Event): string =>
        //         !!m.acknowledged &&
        //         m.acknowledged.userKey !== null &&
        //         m.acknowledged.userKey !== undefined
        //             ? m.acknowledged.userKey.toString()
        //             : 'Unspecified'
        // },
        // 'acknowledged.note': {
        //     name: 'Acknowledgement Note',
        //     toReadable: (m: Event): string =>
        //         !!m.acknowledged &&
        //         m.acknowledged.note !== null &&
        //         m.acknowledged.note !== undefined
        //             ? m.acknowledged.note
        //             : 'Unspecified'
        // },
        // 'acknowledged.userName': {
        //     name: 'Acknowledged By (name)',
        //     toReadable: (m: Event): string =>
        //         !!m.acknowledged &&
        //         m.acknowledged.userName !== null &&
        //         m.acknowledged.userName !== undefined
        //             ? m.acknowledged.userName
        //             : 'Unspecified'
        // },
        // 'resolved.dtTm': {
        //     name: 'Resolved On',
        //     toReadable: (m: Event): string =>
        //         !!m.resolved && m.resolved.dtTm !== null && m.resolved.dtTm !== undefined
        //             ? m.resolved.dtTm.toLocaleString()
        //             : 'Unspecified'
        // },
        // 'resolved.userKey': {
        //     name: 'Resolved By (id)',
        //     toReadable: (m: Event): string =>
        //         !!m.resolved && m.resolved.userKey !== null && m.resolved.userKey !== undefined
        //             ? m.resolved.userKey.toString()
        //             : 'Unspecified'
        // },
        // 'resolved.note': {
        //     name: 'Resolved Note',
        //     toReadable: (m: Event): string =>
        //         !!m.resolved && m.resolved.note !== null && m.resolved.note !== undefined
        //             ? m.resolved.note
        //             : 'Unspecified'
        // },
        // 'resolved.userName': {
        //     name: 'Resolved By (name)',
        //     toReadable: (m: Event): string =>
        //         !!m.resolved && m.resolved.userName !== null && m.resolved.userName !== undefined
        //             ? m.resolved.userName
        //             : 'Unspecified'
        // },
        // entityName: {
        //     name: 'Entity Name',
        //     toReadable: (m: Event): string =>
        //         m.entityName !== null && m.entityName !== undefined ? m.entityName : 'Unspecified'
        // }
    ];

    public static foreignKeys: Column.ForeignKeys = {
        [CollectionName.entity]: 'entityKey'
    };

    public entityKey: string | ObjectId;
    public installKey: string | ObjectId;
    public deviceKey: string | ObjectId;
    public type: EventType;
    public dtTm: Date;
    public description: string;
    public pageCount: number;
    public colorPageCount: number;
    public resolutionStatus: EventResolutionStatus;
    public acknowledged: EventActionLog;
    public resolved: EventActionLog;
    public entityName?: string;
    public device?: any;
    public compatibleItemDetails?: any[] = [];
    public supplies: any[];
    public eventData: any;
    public emailedStatus: EventEmailedStatus;

    constructor(params?: Partial<IEvent>) {
        super(params);
        if (params) {
            this.entityKey = params.entityKey || this.entityKey;
            this.installKey = params.installKey || this.installKey;
            this.deviceKey = params.deviceKey || this.deviceKey;
            this.type = params.type || this.type;
            this.dtTm = params.dtTm || this.dtTm;
            this.description = params.description || this.description;
            this.pageCount = params.pageCount || this.pageCount;
            this.colorPageCount = params.colorPageCount || this.colorPageCount;
            this.status = params.status || this.status;
            this.resolutionStatus = params.resolutionStatus || this.resolutionStatus;
            this.entityName = params.entityName || this.entityName;
            this.device = params.device || this.device;
            this.supplies = params.supplies || this.supplies;
            this.eventData = params.eventData || this.eventData;

            if (params.emailedStatus) {
                this.emailedStatus = new EventEmailedStatus(params.emailedStatus);
            }

            if (params.acknowledged) {
                this.acknowledged = new EventActionLog(params.acknowledged);
            }
            if (params.resolved) {
                this.resolved = new EventActionLog(params.resolved);
            }
            if (Array.isArray(params.compatibleItemDetails)) {
                this.compatibleItemDetails = params.compatibleItemDetails;
            }
        }
    }

    public static isCritical(instance: Event): boolean {
        return (
            instance.resolutionStatus === EventResolutionStatus.New &&
            EventType.criticalAlerts.indexOf(instance.type) !== -1
        );
    }
}
