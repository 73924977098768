import { Validators } from '@angular/forms';
import { ISettings, SettingsKeyMap } from '@libs/iso/core';
import { EmailArrayFormControl } from '@libs/web/forms/models/form-controls/EmailArrayFormControl';
import { InheritanceFormControl } from '@libs/web/forms/models/form-controls/InheritanceFormControl';
import { AbstractDynamicSettingsSectionFormGroup } from '@libs/web/forms/models/form-groups/AbstractDynamicSettingsSectionFormGroup';

/**
 * A pre-configured FormGroup for inherited install settings
 * @see FormGroup
 */
export class InstallDynamicSettingsSectionFormGroup extends AbstractDynamicSettingsSectionFormGroup {
    /**
     * @param {ISettings} settings current state of form
     * @param {ISettings} parentSettings fills empty form fields with inherited default values
     * @returns {InstallDynamicSettingsSectionFormGroup} new instance
     * @see FormGroup.constructor
     */
    constructor(settings: Partial<ISettings>, parentSettings: ISettings) {
        super({
            // Install Alerts
            [SettingsKeyMap.Alerts.Install.New.Enabled]: new InheritanceFormControl(
                settings?.alerts?.install?.new?.enabled,
                parentSettings.alerts.install.new.enabled
            ),
            [SettingsKeyMap.Alerts.Install.New.Emails]: new EmailArrayFormControl(
                settings?.alerts?.install?.new?.emails,
                parentSettings.alerts.install.new.emails
            ),
            [SettingsKeyMap.Alerts.Install.StaleBackOnline.Enabled]: new InheritanceFormControl(
                settings?.alerts?.install?.staleBackOnline?.enabled,
                parentSettings.alerts.install.staleBackOnline.enabled
            ),
            [SettingsKeyMap.Alerts.Install.StaleBackOnline.Emails]: new EmailArrayFormControl(
                settings?.alerts?.install?.staleBackOnline?.emails,
                parentSettings.alerts.install.staleBackOnline.emails
            ),
            [SettingsKeyMap.Alerts.Install.StaleBackOnline.StaleDays]: new InheritanceFormControl(
                settings?.alerts?.install?.staleBackOnline?.staleDays,
                parentSettings.alerts.install.staleBackOnline.staleDays
            ),
            [SettingsKeyMap.Alerts.Install.Stale.Enabled]: new InheritanceFormControl(
                settings?.alerts?.install?.stale?.enabled,
                parentSettings.alerts.install.stale.enabled
            ),
            [SettingsKeyMap.Alerts.Install.Stale.Emails]: new EmailArrayFormControl(
                settings?.alerts?.install?.stale?.emails,
                parentSettings.alerts.install.stale.emails
            ),
            [SettingsKeyMap.Alerts.Install.Stale.StaleDays]: new InheritanceFormControl(
                settings?.alerts?.install?.stale?.staleDays,
                parentSettings.alerts.install.stale.staleDays
            ),

            // Data Collection
            [SettingsKeyMap.UploadTime
                .DayPart]: new InheritanceFormControl(
                settings?.uploadTime?.dayPart,
                parentSettings.uploadTime.dayPart,
                [Validators.required]
            ),
            [SettingsKeyMap.DeviceDiscovery.AutoScanForNewDevices
                .DaysOfWeek]: new InheritanceFormControl(
                settings?.deviceDiscovery?.autoScanForNewDevices?.daysOfWeek,
                parentSettings.deviceDiscovery.autoScanForNewDevices?.daysOfWeek
            ),
            [SettingsKeyMap.Rts.Enabled]: new InheritanceFormControl(
                settings?.rts?.enabled,
                parentSettings.rts.enabled
            ),
            [SettingsKeyMap.Alerts.Toner
                .CheckFrequencyMinutes]: new InheritanceFormControl(
                settings?.alerts?.toner?.checkFrequencyMinutes,
                parentSettings.alerts.toner.checkFrequencyMinutes,
                [Validators.required, Validators.min(60)]
            ),
            [SettingsKeyMap.Upgrade.Auto.Disabled]: new InheritanceFormControl(
                settings?.upgrade?.auto?.disabled,
                parentSettings.upgrade.auto.disabled ?? false
            ),
            [SettingsKeyMap.DataGathering
                .Concurrency]: new InheritanceFormControl(
                settings?.dataGathering?.concurrency,
                parentSettings.dataGathering.concurrency,
                [Validators.min(1), Validators.max(50)]
            ),
            [SettingsKeyMap.FeatureFlags]: new EmailArrayFormControl(
                settings?.featureFlags,
                parentSettings.featureFlags
            ),

            // Network Discovery
            [SettingsKeyMap.DeviceDiscovery.AutoScan.Hops]: new InheritanceFormControl(
                settings?.deviceDiscovery?.autoScan?.hops,
                parentSettings.deviceDiscovery.autoScan.hops
            ),
            [SettingsKeyMap.DeviceDiscovery
                .IPAddress]: new InheritanceFormControl(
                settings?.deviceDiscovery?.ipaddress,
                parentSettings.deviceDiscovery.ipaddress,
                [Validators.required]
            ),
            [SettingsKeyMap.DeviceDiscovery.ExcludeIPAddress]: new InheritanceFormControl(
                settings?.deviceDiscovery?.excludeIPAddress,
                parentSettings.deviceDiscovery.excludeIPAddress
            ),
            [SettingsKeyMap.DeviceDiscovery
                .AdvancedDiscovery.PingFirst]: new InheritanceFormControl(
                settings?.deviceDiscovery?.advancedDiscovery?.pingFirst,
                parentSettings.deviceDiscovery.advancedDiscovery.pingFirst,
            ),
            [SettingsKeyMap.DeviceDiscovery
                .AdvancedDiscovery.Workers]: new InheritanceFormControl(
                settings?.deviceDiscovery?.advancedDiscovery?.workers,
                parentSettings.deviceDiscovery.advancedDiscovery.workers,
            ),

            // Remote Technician
            [SettingsKeyMap.RemoteTechnician.Enabled]: new InheritanceFormControl(
                settings?.remoteTechnician?.enabled,
                parentSettings.remoteTechnician.enabled
             ),

            // SDS
            [SettingsKeyMap.Sds.Enabled]: new InheritanceFormControl(
                settings?.sds?.enabled,
                parentSettings.sds.enabled
            )
        });
    }
}
