export const SUPPLIES_TYPE_ORDER = ['toner', 'tonerBottle', 'ink', 'drum', 'waste', 'collection'];
export const SUPPLIES_COLOR_ORDER = [
    'black',
    'matteBlack',
    'photoBlack',
    'lightBlack',
    'lightLightBlack',
    'gray',
    'lightGray',
    'darkGray',
    'cyan',
    'lightCyan',
    'magenta',
    'lightMagenta',
    'vividMagenta',
    'vividLightMagenta',
    'yellow',
    'orange',
    'red',
    'white',
    'silver',
    'gold'
];

export const sortBySuppliesColor = (a: unknown, b: unknown, key: string = 'color') => {
    return sortByArrayOrder(SUPPLIES_COLOR_ORDER)(a, b, key);
};

export const sortBySuppliesType = (a: unknown, b: unknown, key: string = 'type') => {
    return sortByArrayOrder(SUPPLIES_TYPE_ORDER)(a, b, key);
};

export const sortByArrayOrder = (array: Array<string>) => (
    a: unknown,
    b: unknown,
    key: string
): number => {
    if (a == null || b == null) {
        return 0;
    }
    const indexA = array.findIndex(value => a[key] === value);
    const indexB = array.findIndex(value => b[key] === value);

    if (indexA === -1 && indexB === -1) {
        if (typeof a[key] === 'string') {
            return (a[key] as string).localeCompare(b[key]);
        }

        if (typeof a[key] === 'number') {
            return a[key] - b[key];
        }

        return 0;
    }

    if (indexA === -1) {
        return 1;
    }

    if (indexB === -1) {
        return -1;
    }

    return indexA - indexB;
};
